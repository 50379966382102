import React, {createContext, useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {StoreState, Proposal} from '../../../util/types';
import {ProposalType, GovernanceType} from '../../../util/enums';
import {useCounter} from '../../../hooks';
import {useProposalPeriodInfo} from '../../../hooks';
import CurrentVotes from './vote/CurrentVotes';
import ProcessVotes from './vote/ProcessVotes';
import SubmitVoteYesNo from './vote/SubmitVoteYesNo';

export type VoteContextValue = {
  endPeriod: Date | null;
  gracePeriod: Date | null;
  endingPeriod: number;
  isVotingReady: boolean;
  molochProposal: Proposal | Record<string, any>;
  startPeriod: Date | null;
  vote: number;
  hasVoted: boolean;
  hasExpired: boolean;
  setRefreshCurrentVotes: (refresh: boolean) => void;
  getMemberProposalVote: () => void;
};

export const VoteContext = createContext<VoteContextValue>(
  {} as VoteContextValue
);

type VotingProps = {
  molochProposal: Proposal | Record<string, any>;
  onVoteProcess?: (txHash: string) => void;
  onVoteProcessed?: (processProposalReturnValues: Record<string, any>) => void;
  proposalType: ProposalType | GovernanceType;
};

export default function Voting(props: VotingProps) {
  const {onVoteProcess, onVoteProcessed, proposalType, molochProposal} = props;
  const {proposalId, proposalIndex, endingPeriod} = molochProposal;

  /**
   * Use these as the moloch proposal id and index could be -1 from the server.
   *
   * @todo Look into making the db entry `null` by default.
   */
  const molochProposalIdSafe =
    proposalId !== undefined && proposalId !== null
      ? Number(proposalId)
      : undefined;
  const molochProposalIndexSafe =
    proposalIndex !== undefined && proposalIndex !== null
      ? Number(proposalIndex)
      : undefined;

  /**
   * Selectors
   */

  const VentureMoloch = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.VentureMoloch
  );
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );

  /**
   * External Hooks
   */

  const proposalPeriodDates = useProposalPeriodInfo(molochProposal as Proposal);

  /**
   * State
   */

  const [startPeriod, setStartPeriod] = useState<Date | null>(null);
  const [endPeriod, setEndPeriod] = useState<Date | null>(null);
  const [gracePeriod, setGracePeriod] = useState<Date | null>(null);
  const [vote, setVote] = useState<number>(0);
  const [isVotingReady, setIsVotingReady] = useState<boolean>(false);
  const [hasVoted, setHasVoted] = useState<boolean>(true);
  const [hasExpired, setHasExpired] = useState<boolean>(true);
  const [gqlProposal, setGQLProposal] = useState<
    Proposal | Record<string, any>
  >(molochProposal);

  /**
   * External hooks state
   */

  // Used to arbitrarily increment a number and refresh the votes.
  const [refreshCount, counterDispatch] = useCounter();

  /**
   * Callbacks
   */

  const getProposalPeriodsCached = useCallback(getProposalPeriods, [
    proposalPeriodDates,
  ]);

  const getLatestVotesCached = useCallback(getLatestVotes, [
    VentureMoloch,
    connectedAddress,
    molochProposalIdSafe,
  ]);

  /**
   * Hooks
   */

  useEffect(() => {
    let isMounted = true;
    if (
      VentureMoloch &&
      molochProposalIdSafe &&
      proposalPeriodDates &&
      isMounted
    ) {
      getProposalPeriodsCached();
    }

    return function cleanup() {
      isMounted = false;
    };
  }, [
    VentureMoloch,
    molochProposalIdSafe,
    proposalPeriodDates,
    getProposalPeriodsCached,
  ]);

  /**
   * Fallback, get latest votes. Should trigger after a vote is casted.
   * Watches for `increment`
   */
  useEffect(() => {
    try {
      if (refreshCount > 0) {
        getLatestVotesCached();
      }
    } catch (error) {}
  }, [getLatestVotesCached, refreshCount]);

  async function getLatestVotes() {
    if (!VentureMoloch || !molochProposalIdSafe) return;

    try {
      const {methods} = (VentureMoloch && VentureMoloch.instance) || {};

      const {yesVotes, noVotes} = await methods
        .proposals(molochProposalIdSafe)
        .call({from: connectedAddress}, (result: any) => {
          return result;
        });

      setGQLProposal((s) => ({
        ...s,
        yesShares: yesVotes,
        noShares: noVotes,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  async function getMemberProposalVote() {
    if (!VentureMoloch || !molochProposalIndexSafe) return;

    try {
      const {methods} = (VentureMoloch && VentureMoloch.instance) || {};
      let maybeMemberAddress = connectedAddress;

      /**
       * @note we need to check for a delegation, if the addresses don't
       * match, this means a likely delegation
       */
      const memberAddressByDelegateKey: string = await methods
        .memberAddressByDelegateKey(connectedAddress)
        .call({from: connectedAddress});

      if (
        memberAddressByDelegateKey.toLowerCase() !== connectedAddress &&
        !maybeMemberAddress?.toLowerCase().startsWith('0x00000')
      ) {
        maybeMemberAddress = memberAddressByDelegateKey;
      }

      const memberProposalVote = await methods
        .getMemberProposalVote(maybeMemberAddress, molochProposalIndexSafe)
        .call({from: connectedAddress}, (result: any) => {
          return result;
        });

      const hasCastedVote: boolean =
        Number(memberProposalVote) === 1 || Number(memberProposalVote) === 2;

      setVote(Number(memberProposalVote));
      setHasVoted(hasCastedVote);
    } catch (error) {
      setVote(0);
      setHasVoted(false);
    }
  }

  async function getProposalPeriods() {
    try {
      const {startPeriod, endPeriod, gracePeriod, isVotingReady} =
        proposalPeriodDates;
      const now = new Date();

      setStartPeriod(startPeriod);
      setEndPeriod(endPeriod);
      setGracePeriod(gracePeriod);
      setIsVotingReady(isVotingReady);

      if (endPeriod) {
        setHasExpired(now > endPeriod);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function setRefreshCurrentVotes() {
    counterDispatch({type: 'increment'});
  }

  return (
    <VoteContext.Provider
      value={{
        endingPeriod, // epoch format
        endPeriod, // new Date format
        getMemberProposalVote,
        gracePeriod,
        hasExpired,
        hasVoted,
        isVotingReady,
        molochProposal: gqlProposal || molochProposal,
        setRefreshCurrentVotes,
        startPeriod,
        vote,
      }}>
      {/* VOTING STOPWATCH, PERIOD & PROGRESS BAR */}
      <CurrentVotes />
      {/* CAST A VOTE */}
      <SubmitVoteYesNo />
      {/* PROCESS A PROPOSAL */}
      <ProcessVotes
        onProcess={onVoteProcess}
        onProcessed={onVoteProcessed}
        proposalType={proposalType}
      />
    </VoteContext.Provider>
  );
}
