import React, {useEffect, useState} from 'react';

import {useSaleInfo} from '../../hooks';
import {FetchStatus} from '../../util/enums';

import s from '../../assets/scss/modules/saleunicornchart.module.scss';

// Rainbow Reference: https://codepen.io/jackw/pen/FxBky
export default function SaleUnicornChart() {
  const [percentOfRemainingShares, setPercentOfRemainingShares] =
    useState<string>('100'); // start at 100% full, for correct rotation of rainbow mask

  const {
    saleInfo: {amountSharesSold, totalShares},
    saleInfoStatus,
  } = useSaleInfo();

  useEffect(() => {
    if (saleInfoStatus === FetchStatus.FULFILLED) {
      const percentOfRemainingShares = Number(
        100 - (amountSharesSold / totalShares) * 100
      ).toFixed(2);

      setPercentOfRemainingShares(percentOfRemainingShares);
    }
  }, [saleInfoStatus, amountSharesSold, totalShares]);

  return (
    <>
      <div className={`${s['sale-unicorn-chart-container']}`}>
        <div className={`${s['rainbow-container']}`}>
          <div className={`${s['rainbow-stripe']}`}></div>
          <div className={`${s['rainbow-stripe']}`}></div>
          <div className={`${s['rainbow-stripe']}`}></div>
          <div
            className={`${s['mask-segment']}`}
            style={{
              transform: `rotate(-${
                (Number(percentOfRemainingShares) / 100) * 180
              }deg)`,
            }}></div>
        </div>
      </div>
      <p className={`${s['remaining']}`}>
        {percentOfRemainingShares}% remaining
      </p>
    </>
  );
}
