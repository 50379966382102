import {backOff} from 'exponential-backoff';
import {toWei} from 'web3-utils';
import {useCallback, useEffect, useState} from 'react';

import {ENVIRONMENT} from '../util/config';
import {FetchStatus} from '../util/enums';
import {useAbortController} from './useAbortController';
import {useWeb3Modal} from '../components/web3/Web3ModalManager';

type GasTrackerSuccessResult = {
  LastBlock: string;
  SafeGasPrice: string;
  ProposeGasPrice: string;
  FastGasPrice: string;
  suggestBaseFee: string;
  gasUsedRatio: string;
};

type GasTrackerResponse = {
  status: string;
  message: string;
  result: GasTrackerSuccessResult | string;
};

type GasPrices = {
  average: string | undefined;
  fast: string | undefined;
  safe: string | undefined;
};

type UseETHGasPriceReturn = GasPrices & {
  gasPriceError: Error | undefined;
  gasPriceStatus: FetchStatus;
};

const GAS_PRICE_URL =
  'https://api.etherscan.io/api?module=gastracker&action=gasoracle';

// Convert an Etherscan Gas Tracker response value from Gwei to Wei
function convertGasPriceToWei(gasTrackerPrice: string): string {
  return toWei(gasTrackerPrice, 'Gwei');
}

const INITIAL_GAS_PRICES: GasPrices = {
  average: undefined,
  fast: undefined,
  safe: undefined,
};

/**
 * useETHGasPrice
 *
 * Returns the latest mainnet gas prices, converted to Wei string from Etherscan
 * Gas Tracker.
 *
 * @returns {UseETHGasPriceReturn}
 * @see https://docs.etherscan.io/api-endpoints/gas-tracker
 */
export default function useETHGasPrice(props?: {
  disableRetries?: boolean;
  ignoreEnvironment?: boolean;
  /**
   * The network is EIP-1559 compatible and we do not want to use the gas price.
   *
   * The gas tracker prices can still be used for EIP-1559.
   * For example, to set `maxFeePerGas` (base fee plus tip).
   */
  noRunIfEIP1559?: boolean;
}): UseETHGasPriceReturn {
  const {
    disableRetries = false,
    ignoreEnvironment = false,
    noRunIfEIP1559 = false,
  } = props || {};

  /**
   * State
   */

  const [gasPrices, setGasPrices] = useState<GasPrices>(INITIAL_GAS_PRICES);
  const [gasPriceError, setGasPriceError] = useState<Error>();

  const [gasPriceStatus, setGasPriceStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );

  /**
   * Our hooks
   */

  const {account, web3Instance} = useWeb3Modal();
  const {abortController, isMountedRef} = useAbortController();

  /**
   * Variables
   */

  // Sometimes using mainnet gas prices for testnets won't work well with wallets.
  const shouldExitIfNotProduction: boolean =
    ignoreEnvironment === false && ENVIRONMENT !== 'production';

  /**
   * Cached callbacks
   */

  const handleGetGasPricesCached = useCallback(handleGetGasPrices, [
    abortController?.signal,
    account,
    disableRetries,
    isMountedRef,
    noRunIfEIP1559,
    shouldExitIfNotProduction,
    web3Instance,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    handleGetGasPricesCached();
  }, [handleGetGasPricesCached]);

  // Cleanup async processes
  useEffect(() => {
    return function cleanup() {
      abortController?.abort();
    };
  }, [abortController]);

  /**
   * Functions
   */

  async function handleGetGasPrices() {
    if (!abortController?.signal || !web3Instance || !account) return;

    setGasPriceError(undefined);

    if (shouldExitIfNotProduction) {
      setGasPriceStatus(FetchStatus.FULFILLED);

      return;
    }

    if (noRunIfEIP1559) {
      // Check EIP-1559 support
      const {isEIP1559Compatible} = await import(
        '../components/web3/helpers/isEIP1559Compatible'
      );

      if (await isEIP1559Compatible(web3Instance)) {
        setGasPriceStatus(FetchStatus.FULFILLED);

        return;
      }
    }

    setGasPriceStatus(FetchStatus.PENDING);

    try {
      const request = async () => {
        const response = await fetch(GAS_PRICE_URL, {
          signal: abortController.signal,
        });

        if (!response.ok) {
          throw new Error(
            'Something went wrong while getting the latest gas price.'
          );
        }

        const responseJSON: GasTrackerResponse = await response.json();

        if (!isMountedRef.current) return;

        const {result, status} = responseJSON;

        /**
         * Need to throw error for result with `status: "0"` (e.g., "Max rate
         * limit reached...") so it can trigger retry because it gets returned
         * in a `200` status successful response.
         *
         * @see https://docs.etherscan.io/support/common-error-messages
         */
        if (status === '0') {
          throw new Error(result as string);
        }

        // Successful result will be `GasTrackerSuccessResult` type.
        const {SafeGasPrice, ProposeGasPrice, FastGasPrice} =
          result as GasTrackerSuccessResult;

        if (!SafeGasPrice || !ProposeGasPrice || !FastGasPrice) {
          throw new Error(
            'Something went wrong while getting the latest gas price.'
          );
        }

        setGasPrices({
          average: convertGasPriceToWei(ProposeGasPrice),
          fast: convertGasPriceToWei(FastGasPrice),
          safe: convertGasPriceToWei(SafeGasPrice),
        });

        setGasPriceStatus(FetchStatus.FULFILLED);
      };

      if (disableRetries) {
        await request();
      } else {
        backOff(request, {startingDelay: 1000});
      }
    } catch (error) {
      if (!isMountedRef.current) return;

      console.error(error);
      setGasPriceError(error);
      setGasPrices(INITIAL_GAS_PRICES);
      setGasPriceStatus(FetchStatus.REJECTED);
    }
  }

  /**
   * Result
   */

  return {...gasPrices, gasPriceError, gasPriceStatus};
}
