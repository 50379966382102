import l from '../../assets/scss/modules/logo.module.scss';

interface LogoUnicornProps {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoUnicorn(props: LogoUnicornProps) {
  const classNameSizes = {
    unicorn: props.size ? `${l[`unicorn--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.unicorn} ${classNameSizes.unicorn}`}>UnicornDAO</div>
  );
}
