import l from '../../assets/scss/modules/logo.module.scss';

interface LogoScienceProps {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoScience(props: LogoScienceProps) {
  const classNameSizes = {
    science: props.size ? `${l[`science--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.science} ${classNameSizes.science} org-logo`}>
      Beaker
    </div>
  );
}
