import React from 'react';

import {useLatestETHPrice} from '../../hooks';

type ETHPriceProps = {
  render: (g: ReturnType<typeof useLatestETHPrice>) => React.ReactElement;
};

export default function ETHPrice(props: ETHPriceProps) {
  const ethPrice = useLatestETHPrice();

  return props.render(ethPrice);
}
