// 👀 https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
// 👀 https://blog.sethcorker.com/harnessing-the-page-visibility-api-with-react

enum VisibilityChange {
  VISIBILITYCHANGE = 'visibilitychange',
  MS_VISIBILITYCHANGE = 'msvisibilitychange',
  WEBKIT_VISIBILITYCHANGE = 'webkitvisibilitychange',
}

export type BrowserVisibilityType =
  | VisibilityChange.VISIBILITYCHANGE
  | VisibilityChange.MS_VISIBILITYCHANGE
  | VisibilityChange.WEBKIT_VISIBILITYCHANGE
  | undefined;

export function getBrowserVisibilityProp(): BrowserVisibilityType {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return VisibilityChange.VISIBILITYCHANGE;
  } else if (typeof document.msHidden !== 'undefined') {
    return VisibilityChange.MS_VISIBILITYCHANGE;
  } else if (typeof document.webkitHidden !== 'undefined') {
    return VisibilityChange.WEBKIT_VISIBILITYCHANGE;
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof document?.msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof document?.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
  // default
  return 'hidden';
}

export function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()];
}
