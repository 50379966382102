import {
  CONNECTED_ADDRESS,
  BLOCKCHAIN_SIGNUP_SIGNATURE,
  BLOCKCHAIN_WALLET_AUTHENTICATED,
  BLOCKCHAIN_WEB3_STATE,
  BLOCKCHAIN_WEB3_INSTANCE,
  BLOCKCHAIN_CONTRACTS,
  BLOCKCHAIN_DEFAULT_CHAIN,
  BLOCKCHAIN_MOLOCH_CONSTANTS,
} from '../actions';

import {BlockchainState} from '../../util/types';

const initialState = {
  defaultChain: 1,
  walletAuthActive: false,
  walletAuthenticated: false,
};

export default function reducer(
  state: BlockchainState = initialState,
  action: any
) {
  const {type, ...payload} = action;

  switch (type) {
    case BLOCKCHAIN_SIGNUP_SIGNATURE:
      return web3Signature(state, payload);
    case BLOCKCHAIN_WALLET_AUTHENTICATED:
      return walletAuthenticated(state, payload);
    case BLOCKCHAIN_WEB3_STATE:
      return web3State(state, payload);
    case BLOCKCHAIN_WEB3_INSTANCE:
      return web3Instance(state, payload);
    case CONNECTED_ADDRESS:
      return connectedAddress(state, payload);
    case BLOCKCHAIN_CONTRACTS:
      return smartContracts(state, payload);
    case BLOCKCHAIN_DEFAULT_CHAIN:
      return defaultChain(state, payload);
    case BLOCKCHAIN_MOLOCH_CONSTANTS:
      return molochConstants(state, payload);
    default:
      return state;
  }
}

function connectedAddress(state: BlockchainState, {connectedAddress}: any) {
  return {...state, connectedAddress};
}

function walletAuthenticated(
  state: BlockchainState,
  {walletAuthenticated}: any
) {
  return {...state, walletAuthenticated};
}

function web3Signature(state: BlockchainState, {web3Signature}: any) {
  return {...state, web3Signature};
}

function web3State(state: BlockchainState, {web3State}: any) {
  return {...state, web3State};
}

function web3Instance(state: BlockchainState, {web3Instance}: any) {
  return {...state, web3Instance};
}

function smartContracts(state: BlockchainState, payload: any) {
  return {...state, contracts: {...state.contracts, ...payload.contracts}};
}

function defaultChain(state: BlockchainState, {defaultChain}: any) {
  return {...state, defaultChain};
}

function molochConstants(state: BlockchainState, {molochConstants}: any) {
  return {...state, molochConstants};
}
