import {
  FETCH_STATUS_GET_MEMBER_FROM_SMART_CONTRACT,
  GET_MEMBER_FROM_SMART_CONTRACT,
} from './actions';
import {ConnectedMemberState, MemberMolochResponse} from '../../util/types';
import {FetchStatus} from '../../util/enums';

export default function reducer(
  state: Partial<ConnectedMemberState> = {fetchStatus: FetchStatus.STANDBY},
  action: any
) {
  const {type, ...payload} = action;

  switch (type) {
    case GET_MEMBER_FROM_SMART_CONTRACT:
      return getMemberFromSmartContractReducer(state, payload);
    case FETCH_STATUS_GET_MEMBER_FROM_SMART_CONTRACT:
      return getMemberFromSmartContractFetchStatusReducer(state, payload);
    default:
      return state;
  }
}

function getMemberFromSmartContractReducer(
  state: Partial<ConnectedMemberState>,
  payload: MemberMolochResponse
) {
  return Object.keys(payload).length
    ? {...state, ...payload}
    : {fetchStatus: state.fetchStatus};
}

function getMemberFromSmartContractFetchStatusReducer(
  state: Partial<ConnectedMemberState>,
  payload: Record<'fetchStatus', FetchStatus>
) {
  return {...state, ...payload};
}
