import React, {createContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {useGuildBalance, useLatestETHPrice} from '../../../hooks';
import {FetchStatus} from '../../../util/enums';
import {formatNumber} from '../../../util/helpers';
import {StoreState, OrgResponse, OrgState} from '../../../util/types';

import ChainLinkContract from './ChainLinkContract';
import EasyApplyContract from './EasyApplyContract';
import KYCDBChecks from './KYCDBChecks';
import LAODBChecks from './LAODBChecks';
import MolochContract from './MolochContract';
import SnapshotChecks from './SnapshotChecks';
import SubgraphChecks from './SubgraphChecks';

export type HealthcheckContextValue = {
  processReadyMap: Record<HealthcheckStatusName, FetchStatus>;
  setProcessReadyMapState: any;
};

export const HealthcheckContext = createContext<HealthcheckContextValue>(
  {} as HealthcheckContextValue
);

type HealthcheckStatusName =
  | 'statusChainLink'
  | 'statusEasyApply'
  | 'statusKYCDB'
  | 'statusLAODB'
  | 'statusMoloch'
  | 'statusSnapshot'
  | 'statusSubgraph';

export default function Healthcheck() {
  const [, /*isInitComplete*/ setIsInitComplete] = useState<boolean>(false);
  const [processReadyMap, setProcessReadyMap] = useState<
    Record<HealthcheckStatusName, FetchStatus>
  >({
    statusChainLink: FetchStatus.STANDBY,
    statusEasyApply: FetchStatus.STANDBY,
    statusKYCDB: FetchStatus.STANDBY,
    statusLAODB: FetchStatus.STANDBY,
    statusMoloch: FetchStatus.STANDBY,
    statusSnapshot: FetchStatus.STANDBY,
    statusSubgraph: FetchStatus.STANDBY,
  });
  const [orgData, setOrgData] = useState<Record<string, any>>();
  const [corsOriginsData, setCorsOriginsData] = useState<Record<string, any>>();
  const [adminEmailData, setAdminEmailData] = useState<Record<string, any>>();
  const [featuresSwitches, setFeaturesSwitches] =
    useState<Record<string, any>>();
  const [ETHGuildBalance, setETHGuildBalance] = useState<string>('0');
  const [USDGuildBalance, setUSDGuildBalance] = useState<string>('0');

  const org = useSelector((s: StoreState) => s.org);
  const web3Instance = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.web3Instance
  );

  const {guildBalance, guildBalanceStatus} = useGuildBalance();
  const latestETHPrice = useLatestETHPrice();

  // Check to see if the checks has completed.
  useEffect(() => {
    setIsInitComplete(
      Object.values(processReadyMap).every((fs) => fs === FetchStatus.FULFILLED)
    );
  }, [processReadyMap]);

  useEffect(() => {
    if (!web3Instance) return;
    if (!latestETHPrice) return;

    try {
      if (guildBalance && guildBalanceStatus === FetchStatus.FULFILLED) {
        const guildBalanceToBN = web3Instance.utils.toBN(guildBalance);
        const guildBalanceToETH = web3Instance.utils.fromWei(guildBalanceToBN);
        const formattedETHBalance = Number(guildBalanceToETH).toFixed(2);
        const usdBalance = latestETHPrice * Number(formattedETHBalance);

        setETHGuildBalance(formattedETHBalance);
        setUSDGuildBalance(`$${Number(usdBalance).toFixed(2)}`);
      }
    } catch (error) {
      error && console.error(error);
    }
  }, [guildBalance, guildBalanceStatus, web3Instance, latestETHPrice]);

  useEffect(() => {
    /**
     * @note VS Code error with the 'delete' operator.
     * Use // @ts-ignore for now
     * https://github.com/microsoft/vscode/issues/96022
     */

    const orgState = {...org};
    // delete the following props; don't need to display
    // @ts-ignore
    delete orgState.text;
    // @ts-ignore
    delete orgState.uuid;
    // @ts-ignore
    delete orgState.globalCSS;
    // @ts-ignore
    delete orgState.creationDate;
    // @ts-ignore
    delete orgState.saleSettings;

    const {adminEmail, corsOrigins, features, ...orgData} =
      orgState as OrgState & OrgResponse;

    setOrgData(orgData);
    setCorsOriginsData(corsOrigins);
    setAdminEmailData(adminEmail);
    setFeaturesSwitches(features);
  }, [org]);

  function setProcessReadyMapState(
    processReadyMapName: HealthcheckStatusName,
    processReadyMapStatus: FetchStatus
  ) {
    setProcessReadyMap((state) => ({
      ...state,
      [processReadyMapName]: processReadyMapStatus,
    }));
  }

  return (
    <HealthcheckContext.Provider
      value={{
        processReadyMap,
        setProcessReadyMapState,
      }}>
      <h1>Health checks</h1>
      <h2>Configuration Overview</h2>
      {/* <div>
        <div>All operational [green]</div>
        <div>Some failures [red]</div>
      </div> */}

      <pre>
        <p>
          GUILD BALANCE: {formatNumber(Number(ETHGuildBalance).toFixed(2))} ETH
          ({formatNumber(USDGuildBalance)})
        </p>
        <ul>
          {/** MAIN ORG INFO */}
          {orgData &&
            Object.values(orgData).map((val) => typeof val !== 'object') &&
            Object.entries(orgData).map(([key, val]) => (
              <li key={`orgData-${key}`} style={{marginBottom: '0'}}>
                {key}: {val}
              </li>
            ))}
          {/** adminEmail ORG INFO */}
          <li key={'adminEmails'} style={{marginTop: '1rem'}}>
            Admin Email(s):
          </li>
          {adminEmailData &&
            adminEmailData.map((val: string, index: number) => (
              <li key={index} style={{marginBottom: '0'}}>
                {val}
              </li>
            ))}
          {/** corsOrigin ORG INFO */}
          <li key={'corsOrigin'} style={{marginTop: '1rem'}}>
            CORs Origin Config:
          </li>
          {corsOriginsData &&
            corsOriginsData.map((val: string, idx: number) => (
              <li key={`corsOriginsData-${idx}`} style={{marginBottom: '0'}}>
                {val}
              </li>
            ))}
          {/** features ORG INFO */}
          <li key={'featureSwitches'} style={{marginTop: '1rem'}}>
            Feature Switches:
          </li>
          {featuresSwitches &&
            Object.entries(featuresSwitches).map(([key, val]) => (
              <li key={`${key}-featuresSwitches`} style={{marginBottom: '0'}}>
                {key}: {String(val)}
              </li>
            ))}
        </ul>
      </pre>

      <hr />
      <MolochContract />
      <hr />
      <SnapshotChecks />
      <hr />
      <SubgraphChecks />
      <hr />
      <EasyApplyContract />
      <hr />
      <LAODBChecks />
      <hr />
      <KYCDBChecks />
      <hr />
      <ChainLinkContract />
    </HealthcheckContext.Provider>
  );
}

type StatusMonitorProps = {
  status: FetchStatus;
};

export function StatusMonitor({status}: StatusMonitorProps) {
  return (
    <div
      className={`status-monitor status-monitor-${status
        .toString()
        .toLowerCase()}`}>
      {status.toString().toLowerCase()}
    </div>
  );
}

type CheckStatusProps = {
  checkStatus: boolean;
};

export function CheckStatus({checkStatus}: CheckStatusProps) {
  return (
    <span role="img" aria-label="Status">
      {checkStatus ? '✅' : '⛔️'}
    </span>
  );
}
