import React, {useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {dontCloseWindowWarning, contractSend} from '../../util/helpers';
import {ETHERSCAN_URLS} from '../../util/config';
import {StoreState} from '../../util/types';
import {useETHGasPrice} from '../../hooks';
import {Web3TxStatus} from '../../util/enums';

type UnwrapWETHChildrenProps = {
  balance: string;
  error?: Error;
  etherscanURL: string;
  txStatus: Web3TxStatus;
  handleSubmit: () => void;
};

type UnwrapWETHProps = {
  amountToUnwrapInWEI?: string;
  onError?: (e: Error) => void;
  onProcess?: () => void;
  onSuccess?: () => void;
  render: (props: UnwrapWETHChildrenProps) => React.ReactElement;
};

/**
 * UnwrapWETH
 *
 * Unwraps WETH into ETH.
 *
 * Returns a rendered component via a render prop.
 * UnwrapWETHChildrenProps are passed to the render prop.
 *
 * @param {UnwrapWETHProps} props
 * @returns {FunctionComponent}
 */
export default function UnwrapWETH(props: UnwrapWETHProps) {
  const {amountToUnwrapInWEI, onError, onProcess, onSuccess, render} = props;

  /**
   * State
   */

  const [balance, setBalance] = useState<string>('');
  const [error, setError] = useState<Error>();
  const [etherscanURL, setEtherscanURL] = useState<string>('');
  const [txStatus, setTxStatus] = useState<Web3TxStatus>(Web3TxStatus.STANDBY);
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );

  /**
   * Selector
   */

  const WrapETHContract = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.WrapETH
  );
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );

  /**
   * Callbacks
   */

  const getWETHBalanceCached = useCallback(getWETHBalance, [
    connectedAddress,
    WrapETHContract,
  ]);

  /**
   * Use custom hooks
   */

  const {average: gasPrice} = useETHGasPrice();

  /**
   * Effects
   */

  useEffect(() => {
    getWETHBalanceCached().then((b) => b && setBalance(b));
  }, [getWETHBalanceCached]);

  /**
   * Functions
   */

  async function getWETHBalance() {
    if (!WrapETHContract || !connectedAddress) return;

    try {
      const updatedBalance: string = await WrapETHContract.instance.methods
        .balanceOf(connectedAddress)
        .call();

      return updatedBalance;
    } catch (error) {
      setError(error);
    }
  }

  async function handleOpenUnwrapWETHPrompt() {
    if (!connectedAddress) throw new Error('No connected wallet address.');
    if (!WrapETHContract) throw new Error('No WrapETH contract.');
    if (!balance) throw new Error('No WETH balance to unwrap.');

    const txArguments = {
      from: connectedAddress,
      to: WrapETHContract.contractAddress,
      // set proposed gas price
      ...(gasPrice ? {gasPrice} : null),
    };

    // activate "don't close window" warning
    const unsubscribeDontCloseWindow = dontCloseWindowWarning();

    const handleProcessingTx = (txHash: string) => {
      if (!txHash) return;

      setTxStatus(Web3TxStatus.PENDING);
      setEtherscanURL(`${ETHERSCAN_URLS[chainId]}/tx/${txHash}`);
    };

    const unwrapArgs = [amountToUnwrapInWEI || balance];

    try {
      setError(undefined);
      setEtherscanURL('');
      setTxStatus(Web3TxStatus.AWAITING_CONFIRM);

      // Set processing when wallet opens
      // e.g. UI modals may want to prevent close.
      onProcess && onProcess();

      /**
       * WrapETHContract - withdraw
       *
       * @param {object} unwrapArgs - unwrap balances to withdraw
       */
      contractSend(
        'withdraw',
        WrapETHContract.instance.methods,
        unwrapArgs,
        txArguments,
        handleProcessingTx
      )
        .then(async ({txStatus, receipt, error}) => {
          if (txStatus === Web3TxStatus.FULFILLED) {
            if (!receipt) return;

            setTxStatus(Web3TxStatus.FULFILLED);

            unsubscribeDontCloseWindow();

            const updatedBalance = await getWETHBalance();

            updatedBalance && setBalance(balance);

            onSuccess && onSuccess();
          }

          if (txStatus === Web3TxStatus.REJECTED) {
            setError(error);
            setTxStatus(Web3TxStatus.REJECTED);

            unsubscribeDontCloseWindow();

            onError && error && onError(error);

            // if user closed modal (MetaMask error code 4001)
            // or via WalletConnect, which only provides a message and no code
            setTxStatus(Web3TxStatus.REJECTED);
          }
        })
        .catch(({error}) => {
          setError(error);
          setTxStatus(Web3TxStatus.REJECTED);

          unsubscribeDontCloseWindow();

          onError && onError(error);

          // if user closed modal (MetaMask error code 4001)
          // or via WalletConnect, which only provides a message and no code
          setTxStatus(Web3TxStatus.REJECTED);
        });
    } catch (error) {
      setError(error);
      setTxStatus(Web3TxStatus.REJECTED);

      unsubscribeDontCloseWindow();

      onError && onError(error);
    }
  }

  return render({
    balance,
    error,
    etherscanURL,
    handleSubmit: handleOpenUnwrapWETHPrompt,
    txStatus,
  });
}
