import React, {useCallback, useEffect, useState} from 'react';

import {SNAPSHOT_HUB_URL} from '../../../components/snapshot/helpers';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';

import {CheckStatus} from './index';

export default function SnapshotChecks() {
  const [error, setError] = useState<Error | undefined>();
  const [snapshotJSON, setSnapshotJSON] = useState<
    Record<any, string> | undefined
  >(undefined);

  const checkSnapshotStatusCached = useCallback(checkSnapshotStatus, []);

  useEffect(() => {
    checkSnapshotStatusCached();
  }, [checkSnapshotStatusCached]);

  function reRunChecks() {
    // @todo loader
    checkSnapshotStatusCached();
  }

  async function checkSnapshotStatus() {
    setSnapshotJSON(undefined);
    setError(undefined);

    try {
      const response = await fetch(`${SNAPSHOT_HUB_URL}/api`, {
        method: 'GET',
      });

      if (!response.ok) {
        const errorJSON = await response.json();
        throw new Error(JSON.stringify(errorJSON));
      }

      const responseJSON = await response.json();
      setSnapshotJSON(responseJSON);
    } catch (error) {
      setError(error);
    }
  }

  return (
    <div>
      <h2>Snapshot</h2>
      <button onClick={reRunChecks}>Run check</button>
      <div>
        <p>
          <CheckStatus checkStatus={snapshotJSON !== undefined} /> Snapshot is
          available: {String(snapshotJSON !== undefined)}
        </p>
        {snapshotJSON && (
          <pre>
            <ul>
              {Object.entries(snapshotJSON).map(([key, val]) => (
                <li key={`snapshotJSON-${key}`}>
                  {key}: {val}
                </li>
              ))}
            </ul>
          </pre>
        )}
      </div>
      <div
        style={{
          textAlign: 'center',
          maxWidth: 600,
          display: 'block',
          margin: '0 auto',
        }}>
        <ErrorMessageWithDetails error={error} renderText="" />
      </div>
    </div>
  );
}
