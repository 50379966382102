import i18n from 'roddeh-i18n';

import {ORG_CLEAR_DATA, ORG_SET_DATA, ORG_SET_TEXT} from '../actions';
import {OrgState, OrgResponse} from '../../util/types';
import {ORG_SET_FEATURES} from './actions';

type OrgActionData = {
  type:
    | typeof ORG_CLEAR_DATA
    | typeof ORG_SET_DATA
    | typeof ORG_SET_FEATURES
    | typeof ORG_SET_TEXT;
} & Record<string, any>;

const ORG_INITIAL_STATE = null;

export default function reducer(
  state: Partial<OrgState> = ORG_INITIAL_STATE,
  action: OrgActionData
) {
  const {type, ...payload} = action;

  switch (type) {
    case ORG_CLEAR_DATA:
      return clearOrgData();
    case ORG_SET_DATA:
      return setOrgData(state, payload as OrgResponse);
    case ORG_SET_FEATURES:
      return setOrgFeatures(
        state,
        payload as {features: Record<string, string>}
      );
    case ORG_SET_TEXT:
      return setOrgText(state, payload as {text: Record<string, string>});
    default:
      return state;
  }
}

function clearOrgData(): OrgState {
  return null;
}

function setOrgData(
  state: Partial<OrgState>,
  org: OrgResponse
): Partial<OrgState> {
  return {
    ...state,
    ...org,
  };
}

function setOrgFeatures(
  state: Partial<OrgState>,
  orgFeatures: {features: Record<string, string>}
): Partial<OrgState> {
  return {
    ...state,
    features: orgFeatures.features,
  };
}

/**
 * setOrgText
 *
 * The org `text` property will actually be an instance
 * of i18n, not serialized key/values.
 *
 * @see https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state
 */
function setOrgText(
  state: Partial<OrgState>,
  orgText: {text: Record<string, string>}
): Partial<OrgState> {
  /**
   * Create a new i18n instance and load it with
   * our org's text object.
   *
   * @note Functions cannot be serialised,
   *   so it will not show in Redux developer tools, most likely.
   *
   * @see http://i18njs.com/#multiple_languages
   */
  const i18nInstance = i18n.create(orgText.text);

  return {
    ...state,
    text: i18nInstance,
  };
}
