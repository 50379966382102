import React from 'react';
import {SnapshotProposalSubType} from '../../components/snapshot/enums';

import s from '../../assets/scss/modules/voteindicatorbadge.module.scss';

type AlignmentStyle = 'left' | 'center' | 'right';

type VoteIndicatorBadgeProps = {
  subType: SnapshotProposalSubType;
  align?: AlignmentStyle;
};

export default function VoteIndicatorBadge({
  subType,
  align = 'center',
}: VoteIndicatorBadgeProps) {
  function renderSubTypeText(subType: SnapshotProposalSubType) {
    const governance = <span>Majority</span>;

    switch (subType) {
      case SnapshotProposalSubType.General:
        return <span>yes &#8805; no req</span>;
      case SnapshotProposalSubType.Governance:
        return governance;
      // @note If no subType was detected (early Snapshot proposal, then use "Governance").
      default:
        return governance;
    }
  }

  return (
    <div className={`${s['vote-indicator-badge-wrapper']} ${s[align]}`}>
      <div className={`${s['vote-indicator-badge']} org-vote-indicator-badge`}>
        {renderSubTypeText(subType)}
      </div>
    </div>
  );
}
