import React, {useEffect, useState, useRef, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {FormContext, useForm, Controller} from 'react-hook-form';

import {
  ACCEPTED_DOC_MIME_TYPES,
  ERROR_FILE_TOO_LARGE,
  ERROR_REQUIRED_FIELD,
  MAX_FILE_SIZE,
} from './helper';
import {
  getValidationError,
  formatNumber,
  getOrgText,
} from '../../../util/helpers';
import {StoreState} from '../../../util/types';
import {Forms, OnFormChangeCallback} from './types';
import FileDropzone from '../../../components/common/FileDropzone';
import InputError from '../../../components/common/InputError';
import RadioList from '../../../components/common/RadioList';

import c from '../../../assets/scss/modules/checkbox.module.scss';
import i from '../../../assets/scss/modules/input.module.scss';
import s from '../../../assets/scss/modules/memberverify.module.scss';

enum Fields {
  basisForAccreditation = 'basisForAccreditation',
  incomePreviousTaxYear = 'incomePreviousTaxYear',
  incomePreviousTaxYearProof = 'incomePreviousTaxYearProof',
  incomeRecentTaxYear = 'incomeRecentTaxYear',
  incomeRecentTaxYearProof = 'incomeRecentTaxYearProof',
  isCreditReportAuthorized = 'isCreditReportAuthorized',
  isCurrentYearMinIncomeExpected = 'isCurrentYearMinIncomeExpected',
  personEthAddress = 'personEthAddress',
  personHasSocialSecurityNumber = 'personHasSocialSecurityNumber',
  personNetWorth = 'personNetWorth',
  personNetWorthProof = 'personNetWorthProof',
  recentDebtsProof = 'recentDebtsProof',
}

enum BasisForAccreditation {
  over200K = 'Income of $200K ($300K with spouse) in each of the last 2 years',
  over1M = 'Net worth over $1M',
}

enum HasSocialSecurityNumber {
  Yes = 'I have a social security number',
  No = 'I do not have a social security number',
}

type Values = {
  [Fields.basisForAccreditation]: BasisForAccreditation;
  [Fields.incomePreviousTaxYear]: string;
  [Fields.incomePreviousTaxYearProof]: File;
  [Fields.incomeRecentTaxYear]: string;
  [Fields.incomeRecentTaxYearProof]: File;
  [Fields.isCreditReportAuthorized]: boolean;
  [Fields.isCurrentYearMinIncomeExpected]: boolean;
  [Fields.personEthAddress]: string;
  [Fields.personHasSocialSecurityNumber]: HasSocialSecurityNumber;
  [Fields.personNetWorth]: string;
  [Fields.personNetWorthProof]: File;
  [Fields.recentDebtsProof]: File;
};

export type FundsProofBaseValues = Values;

type Conditions = {
  [l in Fields]: boolean[];
};

type FundsProofPersonFormProps = {
  onFormChange: OnFormChangeCallback;
};

const INITIAL_VALUES: Partial<Values> = {
  [Fields.basisForAccreditation]: BasisForAccreditation.over200K,
  [Fields.personHasSocialSecurityNumber]: HasSocialSecurityNumber.Yes,
};

// validation configuration for react-hook-form
const ruleRequired = {required: ERROR_REQUIRED_FIELD};
const filePDFValidate = {
  validate: (file: File) => {
    return !file
      ? ERROR_REQUIRED_FIELD
      : (file.size > MAX_FILE_SIZE ? true : false)
      ? ERROR_FILE_TOO_LARGE
      : !ACCEPTED_DOC_MIME_TYPES.includes(file.type)
      ? 'The file is not the correct type. Please provide a .pdf, .doc, .docx file.'
      : true;
  },
};

const numberAmountValidate = (amount: number) => ({
  validate: (numberString: string) => {
    const parsedNumber = Number(numberString.replace(/,/g, ''));

    return !numberString
      ? ERROR_REQUIRED_FIELD
      : !parsedNumber
      ? 'Please provide a valid number'
      : parsedNumber < amount
      ? `The number is less than $${formatNumber(amount)}`
      : true;
  },
});

const validateConfig: Partial<Record<Fields, Record<string, any>>> = {
  basisForAccreditation: ruleRequired,
  incomeRecentTaxYear: numberAmountValidate(200000),
  incomeRecentTaxYearProof: filePDFValidate,
  incomePreviousTaxYear: numberAmountValidate(200000),
  incomePreviousTaxYearProof: filePDFValidate,
  isCreditReportAuthorized: ruleRequired,
  isCurrentYearMinIncomeExpected: ruleRequired,
  personEthAddress: ruleRequired,
  personHasSocialSecurityNumber: ruleRequired,
  personNetWorth: numberAmountValidate(1000000),
  personNetWorthProof: filePDFValidate,
  recentDebtsProof: filePDFValidate,
};

const getConditionsMap = (
  formValues: Record<string, any>
): Partial<Conditions> => ({
  [Fields.incomeRecentTaxYear]: [
    formValues[Fields.basisForAccreditation] === BasisForAccreditation.over200K,
  ],
  [Fields.incomeRecentTaxYearProof]: [
    formValues[Fields.basisForAccreditation] === BasisForAccreditation.over200K,
  ],
  [Fields.incomePreviousTaxYear]: [
    formValues[Fields.basisForAccreditation] === BasisForAccreditation.over200K,
  ],
  [Fields.incomePreviousTaxYearProof]: [
    formValues[Fields.basisForAccreditation] === BasisForAccreditation.over200K,
  ],
  [Fields.isCurrentYearMinIncomeExpected]: [
    formValues[Fields.basisForAccreditation] === BasisForAccreditation.over200K,
  ],
  [Fields.personNetWorth]: [
    formValues[Fields.basisForAccreditation] === BasisForAccreditation.over1M,
  ],
  [Fields.personNetWorthProof]: [
    formValues[Fields.basisForAccreditation] === BasisForAccreditation.over1M,
  ],
  [Fields.personHasSocialSecurityNumber]: [
    formValues[Fields.basisForAccreditation] === BasisForAccreditation.over1M,
  ],
  [Fields.isCreditReportAuthorized]: [
    formValues[Fields.basisForAccreditation] === BasisForAccreditation.over1M &&
      formValues[Fields.personHasSocialSecurityNumber] ===
        HasSocialSecurityNumber.Yes,
  ],
  [Fields.recentDebtsProof]: [
    formValues[Fields.basisForAccreditation] === BasisForAccreditation.over1M &&
      formValues[Fields.personHasSocialSecurityNumber] ===
        HasSocialSecurityNumber.No,
  ],
});

export default React.memo(function FundsProofPersonBaseForm(
  props: FundsProofPersonFormProps
) {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');

  // mainly to restore conditional fields
  // as the formValues will add/remove props based on if rendered
  const [allValues, setToAllValues] = useState<Partial<Values>>({});

  const form = useForm({
    defaultValues: INITIAL_VALUES,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const {
    errors,
    formState,
    getValues,
    register,
    setValue,
    triggerValidation,
    watch,
  } = form;
  // watch all values that change
  // see: https://react-hook-form.com/api/#watch
  const formValues = watch() as Values;

  /**
   * @note From the docs: "Read the formState before render to subscribe the form state through Proxy"
   * @see https://react-hook-form.com/api#formState
   */
  const {isValid} = formState;

  const conditionsMap = getConditionsMap(formValues);

  const renderConditionsToString = JSON.stringify(
    Object.keys(conditionsMap).map((f) => ({
      [f]: testRenderConditions(f as Fields),
    }))
  );

  const testRenderConditionsCached = useCallback(testRenderConditions, [
    getValues,
  ]);

  // Set unique namespace on mount for checkbox click.
  // Since the forms are all shown but hidden by CSS we
  // need to do this. At some point we'll attempt conditional
  // rendering with hydration of form values.
  // @todo get rid of this
  const idNamespace = useRef<string>(new Date().getTime().toString());

  // send parent the current form status
  useEffect(() => {
    props.onFormChange({
      formName: Forms.baseFundsProof,
      isValid,
      triggerValidation: form.triggerValidation,
      values: form.getValues() as Values,
    });

    // clean up form values on unmount and set as valid
    return () => {
      props.onFormChange({
        formName: Forms.baseFundsProof,
        isValid: true,
        triggerValidation: form.triggerValidation,
        values: {} as Values,
      });
    };
  }, [form, isValid, props]);

  /**
   * re-validate if conditional is shown and has a value
   *
   * @note This particular form's effect below will infinitely re-render if not set up correctly
   *   with deps. Not sure why this one is any different?
   * @todo Should look at all of the render condition effects as a result
   *   of the above note.
   */
  useEffect(() => {
    Object.keys(getConditionsMap(getValues())).forEach(async (f) => {
      const field = f as Fields;

      (await testRenderConditionsCached(field)) &&
        getValues()[field] &&
        triggerValidation(field, true);
    });
  }, [
    renderConditionsToString,
    getValues,
    testRenderConditionsCached,
    triggerValidation,
  ]);

  function testRenderConditions(field: Fields) {
    return (getConditionsMap(getValues())[field] || []).every(
      (c: boolean) => c
    );
  }

  return (
    <>
      <FormContext {...form}>
        <h3 className={`${s['form-section-heading']} org-form-section-heading`}>
          How are you accredited?
        </h3>

        {/* BASIS FOR ACCREDITATION */}
        <label className={`${i['label--column']} org-label--column`}>
          Basis for Accreditation
        </label>
        <Controller
          as={
            <RadioList
              items={[
                BasisForAccreditation.over200K,
                BasisForAccreditation.over1M,
              ]}
              defaultValue={BasisForAccreditation.over200K}
            />
          }
          name={Fields.basisForAccreditation}
        />

        {/* INCOME: RECENT TAX YEAR */}
        {testRenderConditions(Fields.incomeRecentTaxYear) && (
          <>
            <h3
              className={`${s['form-section-heading']} org-form-section-heading`}>
              Your income in the most recent tax year
            </h3>

            <label className={`${i['label--column']} org-label--column`}>
              <span>Income</span>
              <input
                aria-describedby="error-incomeRecentTaxYear"
                aria-invalid={errors.incomeRecentTaxYear ? 'true' : 'false'}
                defaultValue={formatNumber(allValues.incomeRecentTaxYear || '')}
                name={Fields.incomeRecentTaxYear}
                onChange={(event) => {
                  const {value} = event.target;
                  // set this form field to a formatted value
                  setValue(Fields.incomeRecentTaxYear, formatNumber(value));

                  // set a value to remember between renders of conditional fields
                  setToAllValues({
                    ...allValues,
                    [Fields.incomeRecentTaxYear]: value,
                  });
                }}
                ref={
                  validateConfig.incomeRecentTaxYear &&
                  register(validateConfig.incomeRecentTaxYear)
                }
                type="text"
              />
            </label>
            <InputError
              error={getValidationError(Fields.incomeRecentTaxYear, errors)}
              id="error-incomeRecentTaxYear"
            />
          </>
        )}

        {/* INCOME: RECENT TAX YEAR PROOF */}
        {testRenderConditions(Fields.incomeRecentTaxYearProof) && (
          <>
            <label className={`${i['label--column']} org-label--column`}>
              Documentation
            </label>
            <small>
              Government tax filings, pay stubs, or a letter from your attorney,
              accountant, or investment advisor written in the last 90 days
              (upload pdf, docx).
            </small>
            <Controller
              as={
                <FileDropzone
                  acceptedTypes={ACCEPTED_DOC_MIME_TYPES}
                  aria-describedby="error-incomeRecentTaxYearProof"
                  aria-invalid={
                    errors.incomeRecentTaxYearProof ? 'true' : 'false'
                  }
                />
              }
              // I would think typically you don't return values
              // from an onChange handler, but it works.
              // see: https://react-hook-form.com/api/#Controller
              onChange={([event]) => event.target.files[0]}
              name={Fields.incomeRecentTaxYearProof}
              rules={validateConfig.incomeRecentTaxYearProof}
            />
            <InputError
              error={getValidationError(
                Fields.incomeRecentTaxYearProof,
                errors
              )}
              id="error-incomeRecentTaxYearProof"
            />
          </>
        )}

        {/* INCOME: PREVIOUS TAX YEAR */}
        {testRenderConditions(Fields.incomePreviousTaxYear) && (
          <>
            <h3
              className={`${s['form-section-heading']} org-form-section-heading`}>
              Your income in the previous tax year
            </h3>

            <label className={`${i['label--column']} org-label--column`}>
              <span>Income</span>
              <input
                aria-describedby="error-incomePreviousTaxYear"
                aria-invalid={errors.incomePreviousTaxYear ? 'true' : 'false'}
                defaultValue={formatNumber(
                  allValues.incomePreviousTaxYear || ''
                )}
                name={Fields.incomePreviousTaxYear}
                onChange={(event) => {
                  const {value} = event.target;
                  // set this form field to a formatted value
                  setValue(Fields.incomePreviousTaxYear, formatNumber(value));

                  // set a value to remember between renders of conditional fields
                  setToAllValues({
                    ...allValues,
                    [Fields.incomePreviousTaxYear]: value,
                  });
                }}
                ref={
                  validateConfig.incomePreviousTaxYear &&
                  register(validateConfig.incomePreviousTaxYear)
                }
                type="text"
              />
            </label>
            <InputError
              error={getValidationError(Fields.incomePreviousTaxYear, errors)}
              id="error-incomePreviousTaxYear"
            />
          </>
        )}

        {/* INCOME: PREVIOUS TAX YEAR PROOF */}
        {testRenderConditions(Fields.incomePreviousTaxYearProof) && (
          <>
            <label className={`${i['label--column']} org-label--column`}>
              Documentation
            </label>
            <small>
              Government tax filings, pay stubs, or a letter from your attorney,
              accountant, or investment advisor written in the last 90 days
              (upload pdf, docx).
            </small>
            <Controller
              as={
                <FileDropzone
                  acceptedTypes={ACCEPTED_DOC_MIME_TYPES}
                  aria-describedby="error-incomePreviousTaxYearProof"
                  aria-invalid={
                    errors.incomePreviousTaxYearProof ? 'true' : 'false'
                  }
                />
              }
              // I would think typically you don't return values
              // from an onChange handler, but it works.
              // see: https://react-hook-form.com/api/#Controller
              onChange={([event]) => event.target.files[0]}
              name={Fields.incomePreviousTaxYearProof}
              rules={validateConfig.incomePreviousTaxYearProof}
            />
            <InputError
              error={getValidationError(
                Fields.incomePreviousTaxYearProof,
                errors
              )}
              id="error-incomePreviousTaxYearProof"
            />
          </>
        )}

        {/* INCOME: CURRENT YEAR EXPECTATIONS */}
        {testRenderConditions(Fields.isCurrentYearMinIncomeExpected) && (
          <>
            <label className={`${i['label--column']} org-label--column`}>
              Current year income
            </label>
            <input
              aria-checked={formValues.isCurrentYearMinIncomeExpected}
              aria-describedby="error-isCurrentYearMinIncomeExpected"
              aria-invalid={
                errors.isCurrentYearMinIncomeExpected ? 'true' : 'false'
              }
              className={`${c['checkbox-input']} org-checkbox-input`}
              defaultChecked={allValues.isCurrentYearMinIncomeExpected}
              id={`${Fields.isCurrentYearMinIncomeExpected}-${idNamespace.current}`}
              name={Fields.isCurrentYearMinIncomeExpected}
              onChange={(event) => {
                const {checked} = event.target;
                // re-do validation if false
                checked === false &&
                  triggerValidation(
                    Fields.isCurrentYearMinIncomeExpected,
                    true
                  );

                setToAllValues({
                  ...allValues,
                  [Fields.isCurrentYearMinIncomeExpected]: checked,
                });
              }}
              ref={
                validateConfig.isCurrentYearMinIncomeExpected &&
                register(validateConfig.isCurrentYearMinIncomeExpected)
              }
              type="checkbox"
            />

            <label
              className={`${c['checkbox-label']} org-checkbox-label`}
              htmlFor={`${Fields.isCurrentYearMinIncomeExpected}-${idNamespace.current}`}>
              <span className={`${c['checkbox-box']} org-checkbox-box`}></span>
              <span>
                I expect to earn an income of at least $200k ($300k with spouse)
                again this year.
              </span>
            </label>

            <InputError
              error={getValidationError(
                Fields.isCurrentYearMinIncomeExpected,
                errors
              )}
              id="error-isCurrentYearMinIncomeExpected"
            />
          </>
        )}

        {/* PERSON NET WORTH */}
        {testRenderConditions(Fields.personNetWorth) && (
          <>
            <h3
              className={`${s['form-section-heading']} org-form-section-heading`}>
              Your assets
            </h3>

            <label className={`${i['label--column']} org-label--column`}>
              <span>Your net worth</span>
              <input
                aria-describedby="error-personNetWorth"
                aria-invalid={errors.personNetWorth ? 'true' : 'false'}
                defaultValue={formatNumber(allValues.personNetWorth || '')}
                name={Fields.personNetWorth}
                onChange={(event) => {
                  const {value} = event.target;

                  // set this form field to a formatted value
                  setValue(Fields.personNetWorth, formatNumber(value));
                  // set a value to remember between renders of conditional fields
                  setToAllValues({
                    ...allValues,
                    [Fields.personNetWorth]: value,
                  });
                }}
                ref={
                  validateConfig.personNetWorth &&
                  register(validateConfig.personNetWorth)
                }
                type="text"
              />
            </label>
            <InputError
              error={getValidationError(Fields.personNetWorth, errors)}
              id="error-personNetWorth"
            />
            <small>
              Your net worth includes all assets (except your primary
              residence), minus any debts.
            </small>
          </>
        )}

        {/* PERSON NET WORTH: PROOF OF ASSETS */}
        {testRenderConditions(Fields.personNetWorthProof) && (
          <>
            <h3
              className={`${s['form-section-heading']} org-form-section-heading`}>
              Your proof of assets
            </h3>
            <label
              id="person-net-worth-proof"
              className={`${i['label--column']} org-label--column`}>
              Documentation of assets
            </label>
            <small>
              Upload a statement from a financial institution, asset appraisals,
              or letter from your lawyer, accountant, investment advisor or
              investment broker indicating net worth.
            </small>
            <br />
            <br />{' '}
            <small>
              You can accredit based on your crypto assets. Upload a screenshot
              from a credible cryptocurrency exchange or wallet showing holdings
              &amp; valuations worth over $1MM USD. The screenshot should
              include the date and evidence tying the investor to the account
              (e.g. your name or the investing entity&rsquo;s name). You will
              also need to provide documentation of your debts (e.g. via a
              credit report or letter from your accountant) in order to qualify.
            </small>
            <Controller
              as={
                <FileDropzone
                  acceptedTypes={ACCEPTED_DOC_MIME_TYPES}
                  aria-describedby="error-personNetWorthProof"
                  aria-invalid={errors.personNetWorthProof ? 'true' : 'false'}
                  aria-labelledby="person-net-worth-proof"
                />
              }
              // I would think typically you don't return values
              // from an onChange handler, but it works.
              // see: https://react-hook-form.com/api/#Controller
              onChange={([event]) => event.target.files[0]}
              name={Fields.personNetWorthProof}
              rules={validateConfig.personNetWorthProof}
            />
            <InputError
              error={getValidationError(Fields.personNetWorthProof, errors)}
              id="error-personNetWorthProof"
            />
          </>
        )}

        {testRenderConditions(Fields.personHasSocialSecurityNumber) && (
          <>
            <h3
              className={`${s['form-section-heading']} org-form-section-heading`}>
              Your debts
            </h3>

            {/* DO YOU HAVE SOCIAL SECURITY NUMBER? */}
            <label className={`${i['label--column']} org-label--column`}>
              Do you have a Social Security number?
            </label>
            <Controller
              as={
                <RadioList
                  items={[
                    HasSocialSecurityNumber.Yes,
                    HasSocialSecurityNumber.No,
                  ]}
                  defaultValue={HasSocialSecurityNumber.Yes}
                />
              }
              name={Fields.personHasSocialSecurityNumber}
            />
          </>
        )}

        {/* INCOME: CURRENT YEAR EXPECTATIONS */}
        {testRenderConditions(Fields.isCreditReportAuthorized) && (
          <>
            <label className={`${i['label--column']} org-label--column`}>
              Authorize Credit Report
            </label>
            <input
              aria-checked={formValues.isCreditReportAuthorized}
              aria-describedby="error-isCreditReportAuthorized"
              aria-invalid={errors.isCreditReportAuthorized ? 'true' : 'false'}
              className={`${c['checkbox-input']} org-checkbox-input`}
              defaultChecked={allValues.isCreditReportAuthorized}
              id={Fields.isCreditReportAuthorized}
              name={Fields.isCreditReportAuthorized}
              onChange={(event) => {
                const {checked} = event.target;
                // re-do validation if false
                checked === false &&
                  triggerValidation(Fields.isCreditReportAuthorized, true);

                setToAllValues({
                  ...allValues,
                  [Fields.isCreditReportAuthorized]: checked,
                });
              }}
              ref={
                validateConfig.isCreditReportAuthorized &&
                register(validateConfig.isCreditReportAuthorized)
              }
              type="checkbox"
            />

            <label
              className={`${c['checkbox-label']} org-checkbox-label`}
              htmlFor={Fields.isCreditReportAuthorized}>
              <span className={`${c['checkbox-box']} org-checkbox-box`}></span>
              <span>
                I authorize {orgName} and its affiliates in writing to obtain my
                credit report which shall evidence all of my outstanding
                liabilities. {orgName} may use this data and share it with any
                client solely to verify my accreditation status in compliance
                with SEC requirements.
              </span>
            </label>

            <InputError
              error={getValidationError(
                Fields.isCreditReportAuthorized,
                errors
              )}
              id="error-isCreditReportAuthorized"
            />
          </>
        )}

        {/* RECENT DEBTS PROOF */}
        {testRenderConditions(Fields.recentDebtsProof) && (
          <>
            <label
              id="recent-debts-proof"
              className={`${i['label--column']} org-label--column`}>
              Recent debts report
            </label>
            <small>
              Upload a credit report or letter from your accountant/lawyer
              indicating your debts, dated in the last 90 days.
            </small>
            <Controller
              as={
                <FileDropzone
                  acceptedTypes={ACCEPTED_DOC_MIME_TYPES}
                  aria-describedby="error-recentDebtsProof"
                  aria-invalid={errors.recentDebtsProof ? 'true' : 'false'}
                  aria-labelledby="recent-debts-proof"
                />
              }
              // I would think typically you don't return values
              // from an onChange handler, but it works.
              // see: https://react-hook-form.com/api/#Controller
              onChange={([event]) => event.target.files[0]}
              name={Fields.recentDebtsProof}
              rules={validateConfig.recentDebtsProof}
            />
            <InputError
              error={getValidationError(Fields.recentDebtsProof, errors)}
              id="error-recentDebtsProof"
            />
          </>
        )}
      </FormContext>
    </>
  );
});
