import React from 'react';
import Wrap from '../../components/layout/Wrap';
import MentorCard from './MentorCard';
import FadeIn from '../../components/common/FadeIn';
import s from '../../assets/scss/modules/mentorcard.module.scss';

export default function Mentors() {
  return (
    <Wrap className="section-wrapper">
      <FadeIn>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">Mentors</h2>
        </div>

        <div className="card" style={{padding: '2rem'}}>
          <div className={s['mentor-introduction']}>
            <p>GET ADVICE</p>
            <p>
              Mentors are available to funded projects and can help out as
              advisors on an ad hoc basis or as board members, friends, fans or
              just the occassional provider of enthusiasm. Feel free to reach
              out to them.
            </p>
          </div>

          <div className={s['mentor-container']}>
            <MentorCard
              key={'ricburton'}
              emoji={'🌊'}
              name={'Richard Burton'}
              handle={'@ricburton'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/ricburton',
              }}>
              Richard Burton is a time billionaire investing in decentralized
              finance (DeFi) space. Previously, he has spent time at Stripe and
              Ethereum.
            </MentorCard>

            <MentorCard
              key={'joonian'}
              emoji={'💣'}
              name={'Wong Joon Ian'}
              handle={'@joonian'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/joonian',
              }}>
              Joon Ian Wong has reported on the cryptocurrency world since 2013,
              telling the stories of everything from Mt. Gox to CryptoKitties.
              In 2015, he started the Consensus conference. He has been a
              reporter and editor in three continents. He can help teams explain
              their stuff to a big audience, figure out product-market fit
              around content products, and build communities.
            </MentorCard>

            <MentorCard
              key={'twobitidiot'}
              emoji={'🐗'}
              name={'Ryan Selkis'}
              handle={'@twobitidiot'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/twobitidiot',
              }}>
              Ryan Selkis is an entrepreneur, analyst, and former venture
              capitalist. He is currently the founder and CEO of Messari in New
              York City, a firm that is building an open data library for the
              crypto asset class. He has founded two separate Bitcoin ventures.
              Ryan has a Bachelor of Science in finance from Boston College and
              an MBA in entrepreneurship and innovation from the MIT Sloan
              School of Management.
            </MentorCard>

            <MentorCard
              key={'AlexMasmej'}
              emoji={'⚡️'}
              name={'Alex Masmej'}
              handle={'@AlexMasmej'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/AlexMasmej',
              }}>
              Alex Masmej is a member of many DAOs, including MetaCartel (grants
              given to Ethereum applications) and Marketing DAO (growing
              Ethereum as a brand). He is also the founder of Rocket, a platform
              to get a loan against your digital assets (NFT).
            </MentorCard>

            <MentorCard
              key={'DeFiDude'}
              emoji={'😎'}
              name={'DeFiDude'}
              handle={'@DeFiDude'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/DeFiDude',
              }}>
              After first discovering Bitcoin in 2012, DeFi Dude was intrigued
              by Bitcoin's technology, at least until he discovered Ethereum –
              where he realized there’s much more to all of this than just
              sending a value back and forth. Since 2017, he’s focused on
              evangelizing the Ethereum ecosystem – specifically decentralized
              finance through projects like Kyber Network and POAP where he aids
              in community development. He is also the host for Chicago's DeFi
              meetups and manages the ETH Network Discord where they hold
              bi-weekly community calls for everyone.
            </MentorCard>

            <MentorCard
              key={'AmandaCassatt'}
              emoji={'🌟'}
              name={'Amanda Gutterman'}
              handle={'@AmandaCassatt'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/AmandaCassatt',
              }}>
              Amanda Cassatt is an Advisor and the former CMO of ConsenSys,
              co-creator of Ethereal, and Secretary of MarketingDAO. She is
              fascinated by how we tell stories about transformative
              technologies, and passionate about supporting Ethereum and the
              projects and people taking it to the next level.
            </MentorCard>

            <MentorCard
              key={'bramanathan'}
              emoji={'🤹🏽'}
              name={'Reuben Bramanathan'}
              handle={'@bramanathan'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/bramanathan',
              }}>
              Reuben Bramanathan works at the intersection of product, law and
              cryptocurrency. Reuben was the first product counsel at Coinbase,
              and was later the product lead for Coinbase Index Fund. He
              currently advises a number of early stage crypto projects on all
              aspects of regulatory and product strategy.
            </MentorCard>

            <MentorCard
              key={'Camilla Russo'}
              emoji={'👸'}
              name={'Camilla Russo'}
              handle={'@Camilla Russo'}>
              Camilla Russo Founder at The Defiant, a daily newsletter focusing
              on decentralized finance. Author of upcoming Harper Collins book
              The Infinite Machine. Former markets reporter at Bloomberg News.
            </MentorCard>

            <MentorCard
              key={'DonnaRedel'}
              emoji={'🔮'}
              name={'Donna Redel'}
              handle={'@DonnaRedel'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/DonnaRedel',
              }}>
              Donna Redel is a businesswoman, a professor of blockchain-digital
              assets, an angel investor and a philanthropist. She was the
              managing director of The World Economic Forum, the foremost global
              organization combining business, political, academic, and other
              leaders of society committed to improving the state of the world.
              Ms. Redel was the first woman to chair a USA exchange , The
              Commodity Exchange. Following her work in global organizations,
              Ms. Redel began a second career as a New York City based advisor
              and investor focusing on financial technology, blockchain and
              emerging technologies. She is an active participant in the startup
              community with New York Angels, serving as a board member , the
              co-founder of the Blockchain Committee and co-chair of the Israeli
              Investment Committee and chair of the Education Committee. Ms .
              Redel developed and is teaching at Fordham Law school and Fordham
              Gabelli Business a course on Blockchain-Crypto-Digital Assets. The
              focus of her public service efforts are the environment, health
              and promoting women’s leadership. She has a J.D. from Fordham Law
              School, an MBA from Columbia and a BA from Barnard College
              (Columbia).
            </MentorCard>

            <MentorCard
              key={'MattCorva'}
              emoji={'🏔️'}
              name={'Matt Corva'}
              handle={'@MattCorva'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/MattCorva',
              }}>
              Matt Corva serves as general counsel to ConsenSys, where he's
              helped the company navigate novel legal and business issues
              pertaining to Ethereum and decentralized systems and complex
              enterprise sales since 2016. Within the broader Ethereum
              ecosystem, Matt's been a co-founder and interim general counsel to
              the Enterprise Ethereum Alliance, and has served on the board of
              several blockchain focused companies. He's particularly interested
              in helping businesses scale through sensible pricing plans and
              contracting. Prior to joining ConsenSys, Matt served as a Vice
              President at GLG.
            </MentorCard>

            <MentorCard
              key={'AndrewDARMACAP'}
              emoji={'🏝'}
              name={'Andrew Keys'}
              handle={'@AndrewDARMACAP'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/AndrewDARMACAP',
              }}>
              Co-Founding Andrew Keys is Managing Partner - Digital Asset Risk
              Management Advisors (DARMA Capital). Prior to joining DARMA,
              Andrew was co-founder of ConsenSys Capital and Head of Global
              Business Development at ConsenSys. In doing so, Andrew co-created
              the first Ethereum Blockchain-as-a-Service offering with Microsoft
              and launched the Enterprise Ethereum Alliance (EEA), a
              cross-industry initiative that has grown into the largest
              open-source blockchain enterprise consortium on Earth. Previously,
              he worked for UBS in equities analysis. Later, he led the creation
              of alternative asset insurance products for various insurers and
              hedge funds. Andrew then co-founded a healthcare revenue cycle
              management company, where he encountered the inefficiencies of
              legacy databases and payment processing systems.
            </MentorCard>

            <MentorCard
              key={'evabeylin'}
              emoji={'🎨'}
              name={'Eva Beylin'}
              handle={'@evabeylin'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/evabeylin',
              }}>
              Eva is a social experiment enthusiast. Currently she's
              contributing to The Graph and MolochDAO. Previously Eva worked at
              the Ethereum Foundation and OmiseGO, and advised various crypto
              startups.
            </MentorCard>

            <MentorCard
              key={'mkriak'}
              emoji={'🎭'}
              name={'Mike Kriak'}
              handle={'@mkriak'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/mkriak',
              }}>
              Michael Kriak is a media and technology executive with +20 years
              of experience in finance and operations for emerging growth
              companies. He is also a Tony Award-winning Broadway producer and
              board director and advisor to several technology and cultural
              organizations. Presently, Mike runs the media & advertising
              industry practice for ConsenSys, the global blockchain software
              company. Prior to ConsenSys Mike served as Chief Operating Officer
              / Chief Financial Officer of Mashable for over 5 years. In that
              role, Mike was responsible for raising $50mm of capital from
              venture and strategic investors. Prior to Mashable, Mike worked at
              Haymarket Media, one of Britain’s largest private media companies,
              as the Chief Operating Officer of their US division. Prior to
              Haymarket, Mike was VP of Finance at RxCentric, an early digital
              media company for healthcare professionals. He eventually sold the
              business to the publicly-held Allscripts after 2 years of growth.
              Mike began his digital career in Boston as part of Arthur
              Andersen’s Technology practice auditing some of the internet’s
              first companies. He is a CPA (retired).
            </MentorCard>

            <MentorCard
              key={'knotmegan'}
              emoji={'🙂'}
              name={'Megan Knab'}
              handle={'@knotmegan'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/knotmegan',
              }}>
              Megan is the founder of VeriLedger, a company that builds
              crypto-accounting software for Web3 businesses. She is passionate
              about helping cutting edge startups innovate in creating systems
              of shared value through their companies.
            </MentorCard>

            <MentorCard
              key={'ManuAlzuru'}
              emoji={'🥑'}
              name={'Manuel Gonzalez Alzuru'}
              handle={'@ManuAlzuru'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/ManuAlzuru',
              }}>
              Manuel is a passionate serial entrepreneur who has bootstrapped
              startups, participated in the growth face of others that have been
              backed by top tier VCs (Rocket Internet, Goldman Sachs, Microsoft
              Ventures, Axa Ventures, 500 Startups), has advised entrepreneurs
              with growth, fundraising and human resources strategies, and has
              been involved in the blockchain industry since 2016. Manuel is
              Brickblock's former CMO (asset tokenization platform), he is an
              active member of the MetaCartel DAO where he helps with due
              diligence of the different dapps that apply for funding, he is a
              member of the dGov Council and has also co-founded Ethereum's
              MarketingDAO.
            </MentorCard>

            <MentorCard
              key={'VinyasAlex'}
              emoji={'💥'}
              name={'Alex Vinas'}
              handle={'@VinyasAlex'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/VinyasAlex',
              }}>
              Alex currently works at Nfq Solutions, in a Finance Technical
              Consulting role, working on projects for Haya Real Estate, BBVA,
              Santander Bank and Europea de Titulización. In each of these
              projects, he has pushed for and or driven cryptocurrency
              initiatives. Beyond consulting, he has developed his passion for
              blockchain through entrepreneurship initiatives, which included
              co-founding the first Spanish company to be registered with its
              equity denominated in cryptocurrency and handled by a smart
              contract. He dedicates much of his free time to blockchain
              development courses, planning and attending tech events and
              teaching undergraduate and postgraduate courses at various Spanish
              Universities.
            </MentorCard>

            <MentorCard
              key={'defiprime'}
              emoji={'🍤'}
              name={'Nick Sawinyh'}
              handle={'@defiprime'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/defiprime',
              }}>
              Nick Sawinyh is an LA-based crypto entrepreneur. After having
              spent over four years in the blockchain industry, Nick founded
              DeFiprime in 2019, with the idea to provide information about the
              emerging DeFi ecosystem.
            </MentorCard>

            <MentorCard
              key={'rzurrer'}
              emoji={'👨‍🎤'}
              name={'Ryan Zurrer'}
              handle={'@rzurrer'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/rzurrer',
              }}>
              Ryan has been an active investor and entrepreneur in crypto since
              2012, backing both start-ups and token-enabled networks. Prior to
              leading Polychain’s investment portfolio, he was an early investor
              in a number of projects in the Ethereum ecosystem including
              MakerDAO, ran a bitcoin mining operation throughout 2013, and
              built a bitcoin-remittance firm in South America. Ryan has led the
              transition to SAFTs as a legitimate investment instrument and has
              certainly closed the most SAFTs of anyone globally. He is known in
              the space for his commitment to being a value-add investor and
              directly supporting his projects leveraging his prior experience
              as CEO of a large Brazilian renewables firm where he built and
              operated renewable-energy infrastructure projects and evaluated an
              array of emerging technologies. Prior to his time in renewables,
              Ryan worked in investment banking focused on financial technology.
              Ryan’s contributions to Cryptoeconomics include the concept of
              Keepers, a new cryptoeconomic model for carbon credits, the
              SportsDAO and he launched a Cryptoeconomics lab at his alma mater
              the Richard Ivey School of Business in Canada.
            </MentorCard>

            <MentorCard
              key={'burrrata'}
              emoji={'🧀'}
              name={'Burrrata'}
              handle={'@CheeseYet'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/CheeseYet',
              }}>
              Humans coordinate to create things that are greater than we could
              accomplish alone. It's a super power. Cryptoeconomic systems and
              DAOs are an evolution of human cooperation. I help people
              understand, build, and use these systems for positive-sum value
              creation.
            </MentorCard>

            <MentorCard
              key={'AmoilsNisa'}
              emoji={'😇'}
              name={'Nisa Amoils'}
              handle={'@AmoilsNisa'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/AmoilsNisa',
              }}>
              Nisa Amoils is a partner at Grasshopper Capital investing in
              fintech/Blockchain and has been an early stage investor for the
              past decade. She was named one of Business Insider’s Women VC’s to
              watch and top 100 Women in Fintech, and top 50 global Blockchain
              and emerging tech thinkers. She is a former securities lawyer and
              on the Boards of several institutions including Wharton
              Entrepreneurship and Girls Who Invest. She contributes for Forbes
              and Blockchain Magazine. Prior to investing, she was an
              entrepreneur and spent many years in business development at
              companies like Time Warner and NBC Universal. She holds a business
              degree from the University of Michigan and a law degree from the
              University of Pennsylvania.
            </MentorCard>

            <MentorCard
              key={'lastmjs'}
              emoji={'🕸'}
              name={'Jordan Last'}
              handle={'@lastmjs'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/lastmjs',
              }}>
              Full-stack software developer focused on the web, distributed
              systems, blockchain, security, and open source. Creator of
              Podcrypt and ELI5 Smart Contracts.
            </MentorCard>

            <MentorCard
              key={'brendencmaher'}
              emoji={'🏗'}
              name={'Brenden C. Maher'}
              handle={'@brendencmaher'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/brendencmaher',
              }}>
              Brenden C. Maher is a MIT Media Lab Alumnus '98 M.S. He was an
              early Pioneer in VR (1993)- One of the first ~150 people in the
              World developing Virtual Reality as Member/Director(s) of the
              "Original" Boston Virtual Reality Group 1993-01; and created the
              (first) 3-D audio content/simulation engine for his Thesis. He was
              involved in the early 90's MIT Media Lab thinking on IoT/Things
              That Think, Mesh Networks and Software Agents. He has had a long
              history with Decentralized and Distributed Systems. In 2000, he
              was involved in Automated and Autonomous Smart Appliance Research
              using Java Jini- for Decentralized Software Discovery at MERL.
              Since 2016, he has become deeply fascinated by DAO's and their
              ability to operate at the edge of the network-including incentive
              systems, bonding curves and jurisdiction pegging. Brenden has a
              deep passion for the intersection of Law and Computation and is
              connected to The MIT Computational Law Report and Ecosystems He
              was a speaker on AALE Automated and Autonomous Legal Entities at
              The (Bushwick) Computational Law and Blockchain Festival CB+L
              Conference- A Seminal Event of (DAO) enthusiasts seriously
              discussing pegging DAO's to US jurisdictions. He has launched 3
              Corporations and a Series LLC out of WY/Delaware and is fascinated
              by Parallel Entrepreneurship, SCALE with a deep understanding of
              VC, Preferred Share structures and clauses. Brenden is full stack-
              Assembly to AI and Beyond; attended Clark University and holds a
              B.A. Biology (Neuroscience/Neuroendocrinology Cybernetic Systems)
            </MentorCard>

            <MentorCard
              key={'Lippencott'}
              emoji={'🍵'}
              name={'Jane Lippencott'}
              handle={'@janehk'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/janehk',
              }}>
              Jane has been an active crypto investor and operator since 2014.
              She worked in finance and venture capital in Hong Kong before
              joining the founding team of Horizen (prev. ZenCash) in 2016,
              leading BD and growth until 2018. Today, Jane is Winklevoss
              Capital’s crypto investor across the family office's venture and
              liquid strategies. She is also an advisor to Teller Finance, a
              decentralized lending protocol.
            </MentorCard>

            <MentorCard
              key={'Wetekamp'}
              emoji={'💥'}
              name={'Fabian Wetekamp'}
              handle={'@fabianffebo'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/fabianffebo',
              }}>
              Fabian Wetekamp is a Berlin-based entrepreneurial product lead and
              seasoned DeFi investor. Having lead product teams at YC's dahmakan
              and various other ventures in Asia and Europe, he marries
              extensive product expertise with deep crypto literacy.
            </MentorCard>

            <MentorCard
              key={'Zuckerman'}
              emoji={'🤔'}
              name={'Danny Zuckerman'}
              handle={'@dazuck'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/dazuck',
              }}>
              Danny is the co-founder and COO of 3Box and the co-creator of
              Ceramic Network, which provide decentralized identity
              infrastructure that powers a web connected directly through its
              users and builders. He previously co-authored Identity Theft,
              built more than a dozen products used by millions of students at
              Zearn, and was a strategy consultant at Bain & Co. He is endlessly
              fascinated by how groups of all sizes coordinate, and tools we can
              build to help us do so better.
            </MentorCard>

            <MentorCard
              key={'Somensatto'}
              emoji={'🏌️'}
              name={'Jason Somensatto'}
              handle={'@jasonsomensatto'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/jasonsomensatto',
              }}>
              Jason Somensatto is a lawyer for 0x Labs and an advocate for the
              crypto industry with policy makers and regulators as a board
              member of the Blockchain Association. Before becoming a full time
              crypto lawyer, Jason spent over a decade as a private lawyer,
              primarily representing clients in front of the SEC and in other
              government investigations.
            </MentorCard>

            <MentorCard
              key={'Lakoff'}
              emoji={'👊'}
              name={'Ben Lakoff'}
              handle={'@benlakoff'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/benlakoff',
              }}>
              Ben Lakoff, CFA is a startup enthusiast, advisor and investor. He
              has a broad background in Finance before joining the more
              entrepreneurial blockchain space full time in 2016. Since then,
              he’s started, failed, and sold a number of startups and has been
              an integral part of early-stage startups that have raised over
              $15M in funding.
            </MentorCard>

            <MentorCard
              key={'Delmonti'}
              emoji={'🥧'}
              name={'Alessio Delmonti'}
              handle={'@alexintosh'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/alexintosh',
              }}>
              Alessio is a blockchain developer and DeFi believer who’s
              passionate about co-ordination of decentralized autonomous
              organizations. Involved in the startup scene since 2011, he has
              been leading different Ethereum projects from wallets to exchanges
              and most recently he is serving as coordinator of PieDAO.
            </MentorCard>

            <MentorCard
              key={'Turley'}
              emoji={'🔮'}
              name={'Cooper Turley'}
              handle={'@cooopahtroopa'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/cooopahtroopa',
              }}>
              Cooper Turley is an active contributor to notable DeFi outlets
              like DeFi Rate, The Defiant and Bankless. He actively works with
              teams on community governance to establish sound narratives around
              strong token models and fair launches. Cooper recently joined
              Audius as a crypto community lead where he's developing social
              token standards alongside his work with the Personal Token Agency.
              He's a member of DAOs like MetaCartel and Raid Guild and
              passionate about building the Ownership Economy.
            </MentorCard>

            <MentorCard
              key={'Flynn'}
              emoji={'🏹'}
              name={'Brian Flynn'}
              handle={'@flynnjamm'}
              socialMediaLinks={{
                twitter: 'https://twitter.com/flynnjamm',
              }}>
              Brian writes Jamm Session, a newsletter focused on the
              intersection of crypto and creators. He currently consults for
              multiple projects in crypto-focused on web3 consumer platforms.
              Previously, he was a product manager Dapper Labs.
            </MentorCard>
          </div>
        </div>
      </FadeIn>
    </Wrap>
  );
}
