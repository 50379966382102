import React, {useState, useCallback, useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';

import {getOrgText} from '../../../util/helpers';
import {StoreState} from '../../../util/types';
import {FundsProofBaseValues} from './FundsProofBaseForm';
import {IdentityBaseValues} from './IdentityBaseForm';
import {OnFormChangeCallback, Forms} from './types';
import EntityForm, {
  Values as EntityFormValues,
  EntityPathway,
} from './EntityForm';
import FundsProofCompanyForm, {
  Values as FundsProofCompanyValues,
} from './FundsProofCompanyForm';
import FundsProofPersonForm from './FundsProofPersonForm';
import FundsProofTrustForm, {
  Values as FundsProofTrustValues,
} from './FundsProofTrustForm';
import IdentityCompanyForm, {
  IdentityCompanyValues,
} from './IdentityCompanyForm';
import IdentityPersonForm from './IdentityPersonForm';
import IdentityTrustForm, {IdentityTrustValues} from './IdentityTrustForm';
import Loader from '../../../components/feedback/Loader';
import Wrap from '../../../components/layout/Wrap';

import b from '../../../assets/scss/modules/buttons.module.scss';
import s from '../../../assets/scss/modules/memberverify.module.scss';

type MemberVerifyContainerProps = {
  appendFormData?: Record<string, string>;
  renderStartContent?: () => React.ReactElement;
  submitURL: (p: EntityPathway) => string;
  /**
   * Set in order of appearance any views you want to show. The default is all views.
   */
  views?: View[];
};

type View = 'entity' | 'identity' | 'accreditation';

const INITIAL_VIEW_STATE: View = 'entity';
const INITIAL_FORM_VALIDITY = {
  [Forms.baseFundsProof]: false,
  [Forms.baseIdentity]: false,
  [Forms.companyFundsProof]: false,
  [Forms.companyIdentity]: false,
  [Forms.entity]: false,
  [Forms.personFundsProof]: false,
  [Forms.personIdentity]: false,
  [Forms.trustFundsProof]: false,
  [Forms.trustIdentity]: false,
};

export default function MemberVerifyContainer(
  props: MemberVerifyContainerProps
) {
  type FormDataRef = {
    [Forms.baseFundsProof]: FundsProofBaseValues;
    [Forms.baseIdentity]: IdentityBaseValues;
    [Forms.companyFundsProof]: FundsProofCompanyValues;
    [Forms.companyIdentity]: EntityFormValues & IdentityCompanyValues;
    [Forms.entity]: EntityFormValues;
    [Forms.personFundsProof]: FundsProofBaseValues;
    [Forms.personIdentity]: IdentityBaseValues;
    [Forms.trustFundsProof]: FundsProofTrustValues;
    [Forms.trustIdentity]: EntityFormValues & IdentityTrustValues;
  };

  type FormValidityRef = {
    [Forms.baseFundsProof]: boolean;
    [Forms.baseIdentity]: boolean;
    [Forms.companyFundsProof]: boolean;
    [Forms.companyIdentity]: boolean;
    [Forms.entity]: boolean;
    [Forms.personFundsProof]: boolean;
    [Forms.personIdentity]: boolean;
    [Forms.trustFundsProof]: boolean;
    [Forms.trustIdentity]: boolean;
  };

  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  /**
   * State
   */

  const [view, setView] = useState<View>(INITIAL_VIEW_STATE);
  const [pathway, setPathway] = useState<EntityPathway>('person');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>('');

  /**
   * Refs
   */

  const formValidityRef = useRef<FormValidityRef>(INITIAL_FORM_VALIDITY);
  const isSubmitErrorRef = useRef<boolean>(false);

  /**
   * Their hooks
   */

  const history = useHistory();

  /**
   * Effects
   */

  // Scroll to top of window on form view change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [view]);

  /**
   * Variables
   */

  // storage for all form data for submission
  // @todo fix "any" types
  const formDataRef = useRef<FormDataRef>({
    [Forms.baseFundsProof]: {} as any,
    [Forms.baseIdentity]: {} as any,
    [Forms.companyFundsProof]: {} as any,
    [Forms.companyIdentity]: {} as any,
    [Forms.entity]: {} as any,
    [Forms.personFundsProof]: {} as any,
    [Forms.personIdentity]: {} as any,
    [Forms.trustFundsProof]: {} as any,
    [Forms.trustIdentity]: {} as any,
  });

  const formTriggerValidationRef = useRef<Record<string, any>>({
    [Forms.baseFundsProof]: () => {},
    [Forms.baseIdentity]: () => {},
    [Forms.companyFundsProof]: () => {},
    [Forms.companyIdentity]: () => {},
    [Forms.entity]: () => {},
    [Forms.personFundsProof]: () => {},
    [Forms.personIdentity]: () => {},
    [Forms.trustFundsProof]: () => {},
    [Forms.trustIdentity]: () => {},
  });

  const pathways: EntityPathway[] = ['company', 'person', 'trust'];
  // Set our views and allow user props.
  const viewsIndex: View[] = props.views || [
    'entity',
    'identity',
    'accreditation',
  ];

  const viewNameMap: Record<View, string> = {
    accreditation: 'Accreditation',
    entity: 'Start',
    identity: 'Identity',
  };

  const isLastView = view === viewsIndex[viewsIndex.length - 1];
  const navLeftText =
    viewNameMap[viewsIndex[viewsIndex.indexOf(view) - 1] || ''];

  const navRightText =
    viewNameMap[viewsIndex[viewsIndex.indexOf(view) + 1] || ''];

  const navClass =
    (navLeftText && navRightText) || isLastView
      ? s['nav--split']
      : navLeftText && !navRightText
      ? `${s['nav--left']} org-nav--left`
      : `${s['nav--right']} org-nav--right`;

  const entityAndPathwaysMapToForm: Record<
    EntityPathway | 'entity',
    {formName: Forms; formView: string; formViewKey: View}[]
  > = {
    entity: [
      {formName: Forms.entity, formView: 'Start', formViewKey: 'entity'},
    ],
    person: [
      {
        formName: Forms.personIdentity,
        formView: 'Identity',
        formViewKey: 'identity',
      },
      {
        formName: Forms.personFundsProof,
        formView: 'Accreditation',
        formViewKey: 'accreditation',
      },
    ],
    company: [
      {
        formName: Forms.companyFundsProof,
        formView: 'Accreditation',
        formViewKey: 'accreditation',
      },
      {
        formName: Forms.companyIdentity,
        formView: 'Identity',
        formViewKey: 'identity',
      },
    ],
    trust: [
      {
        formName: Forms.trustFundsProof,
        formView: 'Accreditation',
        formViewKey: 'accreditation',
      },
      {
        formName: Forms.trustIdentity,
        formView: 'Identity',
        formViewKey: 'identity',
      },
    ],
  };

  const getText = getOrgText(orgText);
  const orgMemberVerifySubmitButtonText = getText(
    'OrgMemberVerifySubmitButtonText'
  );
  const orgBecomeMemberTitleEmoji = getText('OrgBecomeMemberTitleEmoji');

  /**
   * Functions
   */

  const handleOnFormChange: OnFormChangeCallback = (formData) => {
    const {formName, isValid, triggerValidation, values} = formData;

    // set form data for submission
    formDataRef.current = {
      ...formDataRef.current,
      [formName]: values,
    };

    // set form validity for pre-submit check
    formValidityRef.current = {
      ...formValidityRef.current,
      [formName]: isValid,
    };

    // set triggerValidation functions
    formTriggerValidationRef.current = {
      ...formTriggerValidationRef.current,
      [formName]: triggerValidation,
    };
  };

  function handleBackButtonClicked() {
    setView(viewsIndex[viewsIndex.indexOf(view) - 1] || viewsIndex[0]);
  }

  function handleNextButtonClicked() {
    const isFormPageValid = handleSetIncompleteFormViewError(
      view,
      /**
       * @note 'entity' is not really really a pathway, it's just the page where we select
       *   the entity to complete the form with, so we have some special handling.
       */
      view === 'entity' ? 'entity' : pathway
    );

    // If the form is invalid, do not go to the next page.
    if (isFormPageValid === false) return;

    setView(
      viewsIndex[viewsIndex.indexOf(view) + 1] ||
        viewsIndex[viewsIndex.length - 1]
    );
  }

  /**
   * handleSetIncompleteFormError
   *
   * Triggers form validation on incomplete forms.
   * Fields with errors will then display errors beneath themselves.
   */
  function handleSetIncompleteFormError() {
    // Only include forms that are included in the `viewsIndex`
    const formPathwaysFiltered = entityAndPathwaysMapToForm[pathway].filter(
      (f) => viewsIndex.includes(f.formViewKey)
    );

    const incompleteForms = [
      ...entityAndPathwaysMapToForm.entity,
      ...formPathwaysFiltered,
    ]
      .map((fm: {formName: string; formView: string}) =>
        !formValidityRef.current[fm.formName as Forms]
          ? {
              formName: fm.formName,
              formView: fm.formView,
              triggerValidation: formTriggerValidationRef.current[fm.formName],
            }
          : null
      )
      .filter(Boolean);

    if (incompleteForms.length) {
      /**
       * @note Commout-out for now, as we now validate each page
       *   before allow naviagating to the next one.
       */

      // setSubmitError(
      //   `Please check the following forms: ${[
      //     ...Array.from(new Set(incompleteForms.map((f: any) => f.formView)))
      //   ].join(', ')}`
      // );

      // Trigger validation on the forms in question to show errors beneath fields
      // when the user navigates back to fix the fields.
      incompleteForms.forEach(async (fm: any) => {
        fm.triggerValidation && (await fm.triggerValidation());
      });

      isSubmitErrorRef.current = true;
    }
  }

  /**
   * handleSetIncompleteFormViewError
   *
   * Triggers form validation on an incomplete form view within a pathway.
   * Fields with errors will then display errors beneath themselves.
   *
   * @param {View} formView
   * @param {EntityPathway | 'entity'} pathway
   */
  function handleSetIncompleteFormViewError(
    formView: View,
    pathway: EntityPathway | 'entity'
  ) {
    // Only include forms that are included in the `viewsIndex`
    const formViewFiltered = entityAndPathwaysMapToForm[pathway].filter(
      (f) => f.formViewKey === formView
    );

    const incompleteForm = formViewFiltered
      .map((fm: {formName: string; formView: string}) =>
        !formValidityRef.current[fm.formName as Forms]
          ? {
              formName: fm.formName,
              formView: fm.formView,
              triggerValidation: formTriggerValidationRef.current[fm.formName],
            }
          : null
      )
      .filter(Boolean);

    if (incompleteForm.length) {
      // Trigger validation on the forms in question to show errors beneath fields
      // when the user navigates back to fix the fields.
      incompleteForm.forEach(async (fm: any) => {
        fm.triggerValidation && (await fm.triggerValidation());
      });

      return false;
    }

    return true;
  }

  function handleSubmit() {
    const isEntityFormValid = formValidityRef.current.entity;

    // Build validity list
    const validityFiltered: Record<EntityPathway, boolean> = pathways.reduce(
      (acc, next) => {
        const arePathwayFormsValid = entityAndPathwaysMapToForm[next]
          // Only include forms that are included in the `viewsIndex`
          .filter((f) => viewsIndex.includes(f.formViewKey))
          // Check if every form valid
          .every((f) => formValidityRef.current[f.formName]);

        // Combine booleans to make sure the Start/Entity form is valid
        // and that all pathway forms are valid.
        acc[next] = isEntityFormValid && arePathwayFormsValid;

        return acc;
      },
      {} as Record<EntityPathway, boolean>
    );

    const formData = buildFormData();

    // maybe set incomplete form error
    !validityFiltered[pathway] && handleSetIncompleteFormError();

    if (validityFiltered[pathway]) {
      setSubmitError('');
      setIsSubmitting(true);

      fetch(props.submitURL(pathway), {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            const COOKIE_NAME = 'discord_user';
            // Deletes `discord_user` cookie by setting a time in the past.
            document.cookie = `${COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;`;

            // Delete `entityForm` localStorage
            const entityForm = localStorage.getItem('entityForm');

            if (entityForm) {
              localStorage.removeItem('entityForm');
            }

            history.push('/verify-thanks');
            return;
          }

          if (!response.ok) {
            throw response;
          }
        })
        .catch((_error: Response) => {
          setIsSubmitting(false);
          setSubmitError('Something went wrong while submitting the request.');
        });
    }
  }

  function buildFormData() {
    // Build the form data map, including only form's data which are active (derived from the `viewsIndex`).
    const dataMapFiltered: Record<
      EntityPathway,
      Record<string, any>
    > = pathways.reduce((acc, next) => {
      const pathwayData = entityAndPathwaysMapToForm[next]
        // Only include forms that are included in the `viewsIndex`
        .filter((f) => viewsIndex.includes(f.formViewKey))
        // Merge data for all forms in the current pathway and combine with the `entity` form's data.
        .reduce(
          (acc, next) => ({
            ...acc,
            ...formDataRef.current.entity,
            ...formDataRef.current[next.formName],
          }),
          {} as Record<string, any>
        );

      acc[next] = pathwayData;

      return acc;
    }, {} as Record<EntityPathway, Record<string, any>>);

    const {appendFormData} = props;
    const getData = {
      ...dataMapFiltered[pathway],
    };

    const formData = new FormData();

    // Build FormData for submission
    Object.keys(getData).forEach((k) => {
      // If the keys are `trustBeneficiaries` or `companyShareholders` collections
      // we need to stringify them.
      if (k === 'trustBeneficiaries' || k === 'companyShareholders') {
        formData.append(k, JSON.stringify(getData[k]));
        return;
      }

      formData.append(k, getData[k]);
    });

    // If we have any other data to append from props.appendFormData
    appendFormData &&
      Object.keys(appendFormData).forEach((k) => {
        formData.append(k, appendFormData[k]);
      });

    return formData;
  }

  /**
   * Handle the show/hide via CSS so it's much easier to retain
   * certain state items like File objects. Though this renders the entire
   * form (not including conditional fields) the performance degradation should be negligable.
   */
  return (
    <Wrap className={`${s['wrap']} org-verify-wrap`}>
      <div className={view === 'entity' ? '' : 'hidden-no-accessibility'}>
        {/* START CONTENT */}
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">
            <span aria-label="Become member title emoji" role="img">
              {orgBecomeMemberTitleEmoji}
            </span>{' '}
            Become a member{' '}
            <span aria-label="Become member title emoji" role="img">
              {orgBecomeMemberTitleEmoji}
            </span>
          </h2>
        </div>
        <div className="org-verify-form-wrap">
          {/* START CONTENT */}
          {props.renderStartContent && props.renderStartContent()}

          {/* ENTITY FORM */}
          <EntityForm
            onFormChange={useCallback((formData) => {
              const {values} = formData;
              setPathway(values.entityType);

              handleOnFormChange(formData);
            }, [])}
          />
        </div>
      </div>

      {/**
       * @todo It would be nice to try and conditionally render and send the already set
       *   form values down to the components, not simply hide with CSS which makes testing more difficult (hence adding `data-testid="..."`).
       *   This decision toh ide forms via CSS was made early on when building with react-hook-form,
       *   so some investigation is needed for a bit of a refactor.
       */}

      {/* PERSON: IDENTITY FORM */}
      <div
        // @todo remove data-testid in favour of above @TODO
        data-testid="person-identity-form"
        className={
          view === 'identity' && pathway === 'person'
            ? ''
            : 'hidden-no-accessibility'
        }>
        <IdentityPersonForm onFormChange={handleOnFormChange} />
      </div>

      {/* PERSON: FUNDS PROOF FORM */}
      <div
        // @todo remove data-testid in favour of above @TODO
        data-testid="person-funds-proof-form"
        className={
          view === 'accreditation' && pathway === 'person'
            ? ''
            : 'hidden-no-accessibility'
        }>
        <FundsProofPersonForm onFormChange={handleOnFormChange} />
      </div>

      {/* COMPANY: IDENTITY FORM */}
      <div
        // @todo remove data-testid in favour of above @TODO
        data-testid="company-identity-form"
        className={
          view === 'identity' && pathway === 'company'
            ? ''
            : 'hidden-no-accessibility'
        }>
        <IdentityCompanyForm onFormChange={handleOnFormChange} />
      </div>

      {/* COMPANY: FUNDS PROOF FORM */}
      <div
        // @todo remove data-testid in favour of above @TODO
        data-testid="company-funds-proof-form"
        className={
          view === 'accreditation' && pathway === 'company'
            ? ''
            : 'hidden-no-accessibility'
        }>
        <FundsProofCompanyForm onFormChange={handleOnFormChange} />
      </div>

      {/* TRUST: IDENTITY FORM */}
      <div
        // @todo remove data-testid in favour of above @TODO
        data-testid="trust-identity-form"
        className={
          view === 'identity' && pathway === 'trust'
            ? ''
            : 'hidden-no-accessibility'
        }>
        <IdentityTrustForm onFormChange={handleOnFormChange} />
      </div>

      {/* TRUST: FUNDS PROOF FORM */}
      <div
        // @todo remove data-testid in favour of above @TODO
        data-testid="trust-funds-proof-form"
        className={
          view === 'accreditation' && pathway === 'trust'
            ? ''
            : 'hidden-no-accessibility'
        }>
        <FundsProofTrustForm
          identityTrustFormValues={formDataRef.current[Forms.trustIdentity]}
          onFormChange={handleOnFormChange}
        />
      </div>

      <div className="org-verify-nav-wrap">
        <div className="org-verify-nav">
          {/* FORM NAVIGATION */}
          <div className={navClass}>
            {navLeftText && (
              <button
                className={`${b.secondary} org-secondary-button`}
                onClick={handleBackButtonClicked}>
                &larr; {navLeftText}
              </button>
            )}

            {navRightText && (
              <button
                className={`${b.secondary} org-secondary-button`}
                onClick={handleNextButtonClicked}>
                {navRightText} &rarr;
              </button>
            )}

            {/* FORM SUBMIT */}
            {/* Show on the last view: accreditation */}
            {isLastView && (
              <button
                className={`${b.primary} org-primary-button`}
                onClick={isSubmitting ? () => {} : handleSubmit}>
                {isSubmitting ? (
                  <Loader
                    text={'Submitting\u2026'}
                    textProps={{fontFamily: 'inherit'}}
                  />
                ) : (
                  orgMemberVerifySubmitButtonText
                )}
              </button>
            )}
          </div>

          {submitError && isLastView && (
            <p
              className={`${s['submit-error']} error-message org-error-message`}>
              {submitError}
            </p>
          )}
        </div>
      </div>
    </Wrap>
  );
}
