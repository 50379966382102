/**
 * MAX_FILE_SIZE_MB
 *
 * @note Changed to 5MB (from 15MB) as the files are delivered all at once to the KYC FE.
 *   We could eventually change this to load each file on demand.
 */
const MAX_FILE_SIZE_MB: number = 5;

export const ERROR_FILE_TOO_LARGE: string = `The file is too large. Please provide a file of ${MAX_FILE_SIZE_MB}MB or less.`;
export const ERROR_REQUIRED_FIELD: string = 'This field is required.';
export const MAX_FILE_SIZE: number =
  MAX_FILE_SIZE_MB * (1024 * 1024); /* bytes = n MB * (1024^2 bytes) */
export const ACCEPTED_IMAGE_MIME_TYPES: string[] = ['image/jpeg', 'image/png'];
export const PDF_MIME_TYPE = 'application/pdf';
export const ACCEPTED_DOC_MIME_TYPES: string[] = [
  PDF_MIME_TYPE,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
