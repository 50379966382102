import React from 'react';

export default function DropSVG(props: any) {
  return (
    <svg
      width="15"
      height="21"
      viewBox="0 0 15 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 13.325C3 11.5423 5.4448 7.17907 7.50017 3.99902C9.55546 7.17807 12 11.5404 12 13.325C12 15.9022 9.98132 17.999 7.5 17.999C5.01868 17.999 3 15.9022 3 13.325ZM11.743 6.10703C10.2013 3.62787 8.68387 1.58794 8.62004 1.50232L7.49995 0L6.37986 1.50232C6.31604 1.58794 4.79864 3.62787 3.25689 6.10703C1.06531 9.63119 0 12.1327 0 13.7547C0 17.7498 3.36442 21 7.49995 21C11.6355 21 15 17.7498 15 13.7547C15 12.1327 13.9346 9.63119 11.743 6.10703Z"
        fill="currentColor"
      />
    </svg>
  );
}
