import l from '../../assets/scss/modules/logo.module.scss';

interface LogoGlimmerProps {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoGlimmer(props: LogoGlimmerProps) {
  const classNameSizes = {
    glimmer: props.size ? `${l[`glimmer--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.glimmer} ${classNameSizes.glimmer} org-logo`}>
      Glimmer
    </div>
  );
}
