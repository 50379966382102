import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {dontCloseWindowWarning, contractSend} from '../../util/helpers';
import {ETHERSCAN_URLS} from '../../util/config';
import {StoreState} from '../../util/types';
import {ProposalType, Web3TxStatus} from '../../util/enums';
import {useETHGasPrice} from '../../hooks';

export type SubmitProposalArguments = [
  // `applicant`
  string,
  // `sharesRequested`
  number,
  // `lootRequested`
  number,
  // `tributeOffered`
  string,
  // `tributeToken`
  string,
  // `paymentRequested`
  string,
  // `paymentToken`
  string,
  // `details`
  string
];

export type SubmitGuildKickProposalArguments = [
  // `memberToKick`
  string,
  // `details`
  string
];

export type SubmitWhitelistProposalArguments = [
  // `tokenToWhitelist`
  string,
  // `details`
  string
];

type SubmitProposalChildrenProps = {
  error?: Error;
  etherscanURL: string;
  isPromptOpen: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  openPrompt: () => void;
};

type SubmitProposalProps = {
  /**
   * Determines which "submit proposal" function will be called
   * The functions are: `submitProposal`, `submitGuildKickProposal`, `submitWhitelistProposal`.
   *
   * A proposal can be one of 5 types: `payment`, `membership`, `general`, `guildkick`, or `whitelist` proposal.
   */
  proposalType?: ProposalType;
  /**
   * **SubmitProposalArguments**
   *
   * @param {string} applicant - Applicant ethereum address
   * @param {number} sharesRequested - Number of Moloch shares requested.
   * @param {number} lootRequested - Number of Moloch loot requested.
   * @param {string} tributeOffered - Amount of contribution in WEI.
   * @param {string} tributeToken - Contract address of tributeToken.
   * @param {string} paymentRequested - Amount of payment requested in WEI.
   * @param {string} paymentToken - Contract address of paymentToken.
   * @param {string} details - Any string (short) detailing the proposal.
   *
   * **SubmitGuildKickProposalArguments**
   *
   * @param {string} memberToKick - Address of membe to kick
   * @param {string} details - Any string (short) detailing the proposal.
   *
   * **SubmitWhitelistProposalArguments**
   *
   * @param {string} tokenToWhitelist - Address of token to whitelist.
   * @param {string} details - Any string (short) detailing the proposal.
   */
  proposalArguments:
    | Promise<SubmitProposalArguments | undefined>
    | SubmitProposalArguments
    | SubmitGuildKickProposalArguments
    | SubmitWhitelistProposalArguments
    | undefined;
  /**
   * Runs before wallet prompt opens; provides an
   * opportunity to run any validation.
   *
   * If an `Error` is thrown, the prompt will not open and
   * an `error` argument will be provided to the `render` prop.
   */
  onBeforeProcess?: () => void;
  onProcess?: (txHash: string) => void;
  onError?: (e: Error) => void;
  onComplete?: (rv: Record<string, any>) => void;
  render: (props: SubmitProposalChildrenProps) => React.ReactElement;
};

type SubmitProposalFunction =
  | 'submitProposal'
  | 'submitGuildKickProposal'
  | 'submitWhitelistProposal';

/**
 * SubmitProposal
 *
 * Returns a rendered component via a render prop.
 * SubmitProposalChildrenProps are passed to the render prop.
 *
 * @param {SubmitProposalProps} props
 * @returns {FunctionComponent}
 */
export default function SubmitProposal(props: SubmitProposalProps) {
  /**
   * State
   */

  const [error, setError] = useState<Error>();
  const [etherscanURL, setEtherscanURL] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitProposalFunction, setSubmitProposalFunction] =
    useState<SubmitProposalFunction>('submitProposal');

  /**
   * Selectors
   */

  const VentureMoloch = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.VentureMoloch
  );
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );
  const contractMolochAddress = useSelector(
    (state: StoreState) => state.org?.contractMolochAddress
  );

  /**
   * External hooks
   */

  const {average: gasPrice} = useETHGasPrice();

  /**
   * Effects
   */

  useEffect(() => {
    const functionName =
      props.proposalType === ProposalType.GUILDKICK
        ? 'submitGuildKickProposal'
        : props.proposalType === ProposalType.WHITELIST
        ? 'submitWhitelistProposal'
        : 'submitProposal';

    setSubmitProposalFunction(functionName);
  }, [props.proposalType]);

  /**
   * Variables
   */

  const {
    onBeforeProcess,
    onError,
    onProcess,
    onComplete,
    proposalArguments,
    render,
  } = props;

  /**
   * Functions
   */

  function handleError(error: Error) {
    setError(error);
    setIsSubmitted(false);
    setIsSubmitting(false);

    onError && onError(error);
  }

  async function handleOpenSubmitPrompt() {
    // activate "don't close window" warning
    const unsubscribeDontCloseWindow = dontCloseWindowWarning();

    const handleProcessingTx = (txHash: string) => {
      if (!txHash) return;

      setIsPromptOpen(false);
      setIsSubmitting(true);
      setEtherscanURL(`${ETHERSCAN_URLS[chainId]}/tx/${txHash}`);

      // Maybe call user `onProcess` and handle any `Error`.
      try {
        onProcess && onProcess(txHash);
      } catch (error) {
        handleError(error);
      }
    };

    try {
      // await; in case its a promise
      const submitArguments = await proposalArguments;

      // throw if there's empty `proposalArguments`
      if (!submitArguments) {
        throw new Error('No proposal arguments were provided.');
      }

      // throw if there's an empty `applicant`
      if (!submitArguments[0]) {
        throw new Error('No applicant address exists.');
      }

      if (!connectedAddress) {
        throw new Error(
          'No connected address was found. Is your wallet connected?'
        );
      }

      /**
       * Run user callback `onBeforeProcess`
       * @note We `await` just in case the value is a Promise.
       */
      onBeforeProcess && (await onBeforeProcess());

      setError(undefined);
      setEtherscanURL('');
      setIsPromptOpen(true);
      setIsSubmitted(false);
      setIsSubmitting(false);

      const {methods} = (VentureMoloch && VentureMoloch.instance) || {};

      const txArguments = {
        from: connectedAddress,
        to: contractMolochAddress,
        // set proposed gas price
        ...(gasPrice ? {gasPrice} : null),
      };

      /**
       * submitProposal
       *
       * Execute contract call for `submitProposal`
       */
      contractSend(
        submitProposalFunction,
        methods,
        submitArguments,
        txArguments,
        handleProcessingTx
      )
        .then(({txStatus, receipt, error}) => {
          if (txStatus === Web3TxStatus.FULFILLED) {
            if (!receipt) return;

            // Tx `receipt` resolved; tx went through.
            setIsSubmitted(true);
            setIsSubmitting(false);
            /**
             * Get `returnValues` from `receipt`.
             *
             * @see https://web3js.readthedocs.io/en/v1.2.7/web3-eth-contract.html#id37
             */
            const {
              SubmitProposal: {returnValues},
            } = receipt.events;

            // Send `returnValues` in callback.
            onComplete && onComplete(returnValues);
          }

          if (txStatus === Web3TxStatus.REJECTED) {
            if (!error) return;
            handleError(error);

            // If user closed modal (MetaMask error code 4001)
            // or via WalletConnect, which only provides a message and no code
            setIsPromptOpen(false);

            unsubscribeDontCloseWindow();
          }
        })
        .catch(({error}) => {
          handleError(error);

          // If user closed modal (MetaMask error code 4001)
          // or via WalletConnect, which only provides a message and no code
          setIsPromptOpen(false);

          unsubscribeDontCloseWindow();
        });

      unsubscribeDontCloseWindow();
    } catch (error) {
      setIsPromptOpen(false);
      handleError(error);

      unsubscribeDontCloseWindow();
    }
  }

  return render({
    error,
    etherscanURL,
    isPromptOpen,
    isSubmitted,
    isSubmitting,
    openPrompt: handleOpenSubmitPrompt,
  });
}
