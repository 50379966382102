import l from '../../assets/scss/modules/logo.module.scss';

interface LogoMusicProps {
  size?: 'small' | 'medium' | 'large' | '';
}

function NoiseSVG() {
  return (
    <svg
      width="225"
      height="53"
      viewBox="0 0 225 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_316_3)">
        <path
          d="M12.7116 44.9344C10.6191 44.9344 8.91095 43.6506 7.58713 41.0829C6.306 38.5152 5.38788 35.4126 4.83273 31.7751C4.27757 28.1375 4 24.6284 4 21.2476C4 15.7271 4.87544 10.8271 6.62631 6.54759C8.41988 2.22532 11.4091 0.0641913 15.5941 0.0641913C17.4304 0.0641913 19.0318 0.770305 20.3984 2.18253C21.8076 3.59476 23.0247 5.43493 24.0496 7.70305C25.0745 9.92837 26.3129 13.031 27.7648 17.0109L28.8538 20.0279C28.7684 18.4017 28.7256 17.2035 28.7256 16.4332C28.7256 11.4262 29.3876 7.44628 30.7114 4.49344C32.0779 1.54061 34.2345 0.0641913 37.181 0.0641913C40.7255 0.0641913 43.1596 2.18253 44.4834 6.41921C45.8073 10.6131 46.4692 15.5559 46.4692 21.2476C46.4692 24.6284 46.0421 28.1375 45.188 31.7751C44.3767 35.4126 42.9888 38.5152 41.0244 41.0829C39.06 43.6506 36.4978 44.9344 33.3377 44.9344C31.416 44.9344 29.7292 44.2497 28.2773 42.8803C26.868 41.4681 25.6723 39.7349 24.6901 37.6807C23.7506 35.6266 22.619 32.8021 21.2951 29.2074C20.6119 27.3244 20.0781 25.9122 19.6937 24.9707L19.7578 30.1703C19.7578 33.5511 19.587 36.2899 19.2453 38.3869C18.9037 40.441 18.2204 42.0458 17.1955 43.2013C16.2134 44.3567 14.7187 44.9344 12.7116 44.9344Z"
          fill="#FFF500"
        />
        <path
          d="M77.4764 44.9986C73.5904 45.0414 69.9819 44.0786 66.651 42.11C63.3627 40.1414 60.7151 37.4454 58.708 34.0218C56.7436 30.5554 55.7187 26.7253 55.6333 22.5314C55.5906 18.5087 56.5301 14.7856 58.4518 11.362C60.3735 7.93842 63.0211 5.22095 66.3947 3.2096C69.7684 1.15546 73.4623 0.128386 77.4764 0.128386C81.4052 0.0855895 85.0351 1.06987 88.366 3.08122C91.7396 5.09257 94.4086 7.85283 96.373 11.362C98.3374 14.8712 99.3196 18.7441 99.3196 22.9808C99.3196 26.9607 98.3374 30.6196 96.373 33.9576C94.4086 37.2956 91.7396 39.9489 88.366 41.9174C85.0351 43.886 81.4052 44.913 77.4764 44.9986ZM77.6686 32.4812C79.633 32.4812 81.2985 31.7751 82.665 30.3628C84.0743 28.9506 84.7575 27.1104 84.7148 24.8423C84.7148 22.5314 84.0315 20.6698 82.665 19.2576C81.2985 17.8454 79.633 17.1393 77.6686 17.1393C75.7042 17.1393 74.0388 17.8454 72.6723 19.2576C71.3057 20.6698 70.6225 22.5314 70.6225 24.8423C70.6225 27.1104 71.3057 28.9506 72.6723 30.3628C74.0388 31.7751 75.7042 32.4812 77.6686 32.4812Z"
          fill="#FFF500"
        />
        <path
          d="M126.019 33.3157C128.197 33.5724 129.884 34.1288 131.08 34.9847C132.318 35.7978 132.937 37.103 132.937 38.9004C132.937 40.6978 132.297 42.0244 131.015 42.8803C129.777 43.7362 128.282 44.2711 126.532 44.4851C124.781 44.6563 122.517 44.7419 119.742 44.7419C117.009 44.7419 114.767 44.6563 113.016 44.4851C111.265 44.2711 109.77 43.7362 108.532 42.8803C107.293 42.0244 106.674 40.6978 106.674 38.9004C106.674 37.1458 107.251 35.862 108.404 35.0489C109.599 34.193 111.222 33.6152 113.272 33.3157C113.058 30.8336 112.952 27.2816 112.952 22.6598C112.952 18.5087 113.037 15.1707 113.208 12.6458C110.731 12.3891 108.788 11.7899 107.379 10.8485C105.97 9.90698 105.265 8.43056 105.265 6.41921C105.265 4.45065 105.948 2.99563 107.315 2.05415C108.681 1.11266 110.325 0.534933 112.247 0.32096C114.169 0.106987 116.646 0 119.678 0C122.71 0 125.186 0.106987 127.108 0.32096C129.03 0.534933 130.674 1.11266 132.04 2.05415C133.45 2.99563 134.154 4.45065 134.154 6.41921C134.154 8.43056 133.428 9.90698 131.976 10.8485C130.567 11.7899 128.603 12.3891 126.083 12.6458C126.297 14.9995 126.403 18.3375 126.403 22.6598C126.403 26.8109 126.275 30.3628 126.019 33.3157Z"
          fill="#FFF500"
        />
        <path
          d="M159.483 44.9986C156.664 44.9986 153.952 44.6135 151.347 43.8432C148.785 43.0301 146.693 41.9174 145.07 40.5052C143.447 39.0502 142.636 37.4026 142.636 35.5624C142.636 33.8078 143.17 32.417 144.237 31.3899C145.305 30.3628 146.65 29.8493 148.273 29.8493C149.34 29.8493 150.258 29.9991 151.027 30.2987C151.838 30.5554 152.714 30.9406 153.653 31.4541C154.337 31.7965 154.913 32.0746 155.383 32.2886C155.895 32.4598 156.429 32.5454 156.984 32.5454C158.394 32.5454 159.098 31.989 159.098 30.8764C159.098 30.4484 158.799 30.0847 158.201 29.7851C157.604 29.4428 156.6 28.9934 155.191 28.4371C152.671 27.4528 150.621 26.4899 149.041 25.5484C146.607 24.0934 144.92 22.51 143.981 20.7982C143.084 19.0864 142.636 16.9467 142.636 14.379C142.636 11.8969 143.426 9.56462 145.006 7.38209C146.586 5.15676 148.742 3.38078 151.476 2.05415C154.209 0.72751 157.198 0.0641913 160.443 0.0641913C163.603 0.0641913 166.486 0.55633 169.091 1.54061C171.739 2.48209 173.81 3.80873 175.304 5.52052C176.842 7.18951 177.61 9.05108 177.61 11.1052C177.61 13.4589 176.906 15.2349 175.497 16.4332C174.13 17.5886 172.593 18.1664 170.884 18.1664C169.475 18.1664 168.408 18.0808 167.682 17.9096C166.998 17.7384 166.315 17.503 165.632 17.2035C165.077 16.9895 164.607 16.8397 164.223 16.7541C163.838 16.6257 163.305 16.5616 162.621 16.5616C162.066 16.5616 161.532 16.7541 161.02 17.1393C160.55 17.4816 160.315 17.9738 160.315 18.6157C160.315 19.0864 160.593 19.493 161.148 19.8353C161.703 20.1349 162.344 20.4131 163.07 20.6698C163.796 20.9266 164.308 21.1192 164.607 21.2476C168.578 22.7026 171.504 24.3716 173.383 26.2546C175.262 28.0947 176.201 30.4912 176.201 33.4441C176.201 37.0816 174.728 39.9275 171.781 41.9816C168.877 43.993 164.778 44.9986 159.483 44.9986Z"
          fill="#FFF500"
        />
        <path
          d="M217.413 34.6637C219.463 35.3056 220.488 36.6537 220.488 38.7078C220.488 40.6336 219.505 42.1528 217.541 43.2655C215.577 44.3781 212.182 44.9344 207.356 44.9344C201.335 44.9344 196.168 43.4794 191.854 40.5694C187.584 37.6593 185.449 31.8821 185.449 23.2375C185.449 18.8725 186.196 14.9354 187.691 11.4262C189.185 7.91702 191.406 5.13537 194.353 3.08122C197.342 1.02707 200.993 0 205.306 0C211.071 0 215.128 0.898689 217.477 2.69607C219.826 4.45065 221 6.61178 221 9.17946C221 10.3777 220.701 11.4904 220.103 12.5175C219.505 13.5017 218.609 14.272 217.413 14.8284C216.388 15.3419 215.064 15.5987 213.441 15.5987L211.712 15.5345C211.37 15.4917 210.879 15.4703 210.239 15.4703C208.018 15.4703 206.267 15.8768 204.986 16.6899C203.705 17.4602 202.829 18.6585 202.36 20.2847C204.153 20.1991 205.776 20.1563 207.228 20.1563C211.541 20.1563 214.338 20.2847 215.619 20.5415C217.071 20.841 218.182 21.4188 218.95 22.2746C219.762 23.1305 220.167 24.1362 220.167 25.2917C220.167 26.4471 219.783 27.4528 219.014 28.3087C218.246 29.1218 217.093 29.6781 215.555 29.9777C214.36 30.2345 211.456 30.3628 206.844 30.3628C204.965 30.3628 203.641 30.3414 202.872 30.2987C204.196 33.1231 206.673 34.5353 210.303 34.5353C210.516 34.5353 211.007 34.4925 211.776 34.4069C212.801 34.3214 213.676 34.2786 214.402 34.2786C215.641 34.2786 216.644 34.4069 217.413 34.6637Z"
          fill="#FFF500"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_316_3"
          x="0"
          y="0"
          width="225"
          height="53"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_316_3"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_316_3"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default function LogoMusic(props: LogoMusicProps) {
  const classNameSizes = {
    noisedao: props.size ? `${l[`noisedao--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.noisedao} ${classNameSizes.noisedao}`}>
      <NoiseSVG />
    </div>
  );
}
