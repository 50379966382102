import React from 'react';

export default function HeadphonesSVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1283.98 1131.09"
      {...props}>
      <g>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1231.44,699.51c-15.72,2.1-25.44,10.25-31.49,18.74-9.85,13.85-9,23.14-9,42.31l0,201.92c.55,49,57.3,65.58,84.32,29.49,9.51-12.72,8.71-24.05,8.71-42.26V747.77c-.22-28.82-22.32-52.32-52.54-48.26m-1192.25.37C2.58,706.24.57,736.42.57,762.88c0,64.33-1.28,139.07,0,202,1,47.61,60.59,62.84,85.2,25.92,8.2-12.32,7.68-26.52,7.68-43.36,0-64.11,1.32-139.42,0-202C92.86,717.46,69.17,694.68,39.19,699.88ZM1050,580.35c-28.12,3.89-46.37,17.62-59.46,32.85-15.69,18.26-21.08,40-21.08,70.77V960.15c0,25.15-2.52,68.92,1,91.77,12.28,80,113.57,106.45,163.9,45.13,15.77-19.2,20.85-39.28,20.85-70.75V750.11c0-25.61,2.46-68.38-1-91.81C1147.32,612.49,1104.17,572.86,1050,580.35Zm-839.28-.15c-28.75,3.77-46.31,17.41-59.74,32.44-15.63,17.51-21.58,39.62-21.57,70.17,0,15.78-1.42,351.84.85,368.43,6.32,46.08,49.76,86.31,104.25,79,28.12-3.79,46.13-17.89,59.12-32.59,15-17,21.54-40.25,21.53-70.17V751.28c0-26.2,2.42-67.6-.95-92C307.82,613.2,264.72,573.11,210.68,580.2ZM182.8,310.63c6.41,4.4,7.66,10.32,21.4,17.73a68.78,68.78,0,0,0,32,8.71c31.4.88,46.56-16.18,66.28-33.61,87-76.83,216.65-124.75,339.89-124.66,139,.1,254,50.72,349.85,133.3,7.92,6.81,11.15,11.59,22.14,17.29,23.63,12.26,57.23,9.36,77.15-8.6,4.48-4,5.85-6.71,10.39-10.14,10.23,10.38,34.92,55.9,42.43,71a554.94,554.94,0,0,1,53.4,174.1c2.29,17.35,3.76,34.56,4.48,52.22.85,21.17-2.39,33.06,10.53,46.42,18,18.69,60.46,15.47,59.61-31.9-2.44-137.25-48.44-275.08-132.48-379.12-4.39-5.44-8.19-10.61-12.42-15.43-16.89-19.26-22.37-26.6-41.2-44.69-25-24.06-30.94-30-60.51-53.2C918.39,46,780.15,0,642.33,0,365,0,166.78,171.41,78.94,346.82c-42.55,85-65,175.77-66.77,274.48-.9,50.09,43,50.54,59.2,33.62,13.18-13.75,10.09-24.1,10.89-45.65.68-17.91,2.16-35.29,4.46-52.81a558.11,558.11,0,0,1,53.07-174.27C146.75,368,174.8,317.09,182.8,310.63Z"
        />
      </g>
    </svg>
  );
}
