import React from 'react';

import {GetInvestmentProposalResponse} from '../../../util/types';
import CreateSnapshotInvestmentProposal from './CreateSnapshotInvestmentProposal';
import SnapshotVoting from '../../../components/snapshot/SnapshotVoting';

type SnapshotInvestmentProposalActionsProps = {
  proposal: GetInvestmentProposalResponse;
  /**
   * Provide a callback to run once any action completes.
   * e.g. Update the proposal from the server.
   */
  onActionComplete: () => void;
};

export default function SnapshotInvestmentProposalActions(
  props: SnapshotInvestmentProposalActionsProps
): JSX.Element | null {
  const {onActionComplete, proposal} = props;

  // Show the sponsor button which submits the Snapshot proposal data to the Hub.
  if (!proposal.snapshotHubProposal) {
    return (
      <CreateSnapshotInvestmentProposal
        proposal={proposal}
        onSnapshotCreated={onActionComplete}
      />
    );
  }

  // Show the voting status (and action buttons if permissions allow)
  if (proposal.snapshotHubProposal && proposal.snapshotHubVotes) {
    return (
      <SnapshotVoting
        onRefetchVotes={onActionComplete}
        snapshotProposal={proposal.snapshotHubProposal}
        votes={proposal.snapshotHubVotes}
      />
    );
  }

  // Default fallthrough
  return null;
}
