import React, {useEffect, useState} from 'react';
import ReactModal from 'react-modal';

import FadeIn from '../../components/common/FadeIn';
import {StoreState} from '../../util/types';
import {InternalNamesToMap} from '../../util/orgDomainMappings';
import {useSelector} from 'react-redux';
import {FetchStatus} from '../../util/enums';
import TimesSVG from '../../assets/svg/TimesSVG';

import b from '../../assets/scss/modules/buttons.module.scss';
import m from '../../assets/scss/modules/modal.module.scss';
import sm from '../../assets/scss/modules/sale.module.scss';

type ProposalActionWhyDisabledRenderProps = {
  canShowDisabledReason: boolean;
  isDisabled: boolean;
  openWhyDisabledModal: () => void;
  WhyDisabledModal: () => React.ReactElement;
};

type ProposalActionWhyDisabledProps = {
  otherReasons?: string[];
  render: (p: ProposalActionWhyDisabledRenderProps) => React.ReactElement;
};

/**
 * ProposalActionWhyDisabled
 *
 * This component covers the most common cases for why a proposal action button should be disabled.
 * More reasons for disabling the button can be added via props using `otherReasons`.
 *
 * The most common cases across all stages of a proposal are:
 *
 *  - Wallet is not connected
 *  - Member address is not connected
 *
 * @param {ProposalActionWhyDisabledProps} props
 */
export default function ProposalActionWhyDisabled(
  props: ProposalActionWhyDisabledProps
) {
  const {otherReasons, render} = props;

  /**
   * State
   */

  const [isMemberActive, setIsMemberActive] = useState<boolean>(false);
  const [shouldShowWhyModal, setShouldShowWhyModal] = useState<boolean>(false);

  /**
   * Selectors
   */

  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const connectedMember = useSelector(
    (state: StoreState) => state.connectedMember
  );
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  /**
   * Variables
   */

  // Get the first "other reason".
  const otherReason = otherReasons && otherReasons.find((r) => r);
  const isDisabled =
    !connectedAddress || !isMemberActive || (otherReason ? true : false);
  const disabledReason = getDisabledReason();
  // To ensure we are not showing a message prematurely while we wait for async.
  const canShowDisabledReason = isDisabled && disabledReason ? true : false;

  /**
   * Effects
   */

  // Check connected address is a moloch member
  useEffect(() => {
    if (
      connectedMember.isMemberActive === undefined ||
      connectedMember.isMemberActive === false
    ) {
      setIsMemberActive(false);
    }

    if (connectedMember.isMemberActive) {
      setIsMemberActive(true);
    }
  }, [connectedMember]);

  /**
   * Functions
   */

  function getDisabledReason() {
    if (!connectedAddress) {
      return 'Your wallet is not connected.';
    }

    /**
     * This logic differs slightly to serve `canShowDisabledReason`
     * to ensure we are not showing a message prematurely.
     */
    if (
      !isMemberActive &&
      connectedMember.fetchStatus === FetchStatus.FULFILLED
    ) {
      return 'Either you are not a member, or your membership is not active.';
    }

    return otherReason || '';
  }

  function openWhyDisabledModal() {
    setShouldShowWhyModal(true);
  }

  function renderCloseButton() {
    switch (orgInternalName) {
      case InternalNamesToMap.nft:
      case InternalNamesToMap.liquidity:
        return (
          <span
            className="org-modal-close"
            onClick={() => {
              setShouldShowWhyModal(false);
            }}>
            <TimesSVG />
          </span>
        );
      case InternalNamesToMap.thelao:
      default:
        return (
          <div className={m['close']} style={{top: '.9rem'}}>
            <button
              className={`${b.underline}`}
              onClick={() => {
                setShouldShowWhyModal(false);
              }}>
              Close
            </button>
          </div>
        );
    }
  }

  function WhyDisabledModal() {
    return (
      <>
        {canShowDisabledReason && (
          <ReactModal
            ariaHideApp={false}
            className={`${m['modal-content-wide']}`}
            isOpen={shouldShowWhyModal}
            onRequestClose={() => {
              setShouldShowWhyModal(false);
            }}
            overlayClassName={`${m['modal-overlay']} org-modal-overlay`}
            role="dialog"
            style={
              {overlay: {zIndex: '99'}, content: {maxWidth: '32.5rem'}} as any
            }>
            <FadeIn>
              <div
                className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} org-modal`}>
                <div className={`${sm.sales} ${m['modal-title']} card`}>
                  {renderCloseButton()}
                  <div className="titlebar">
                    <h2 className="titlebar__title org-titlebar__title">
                      Why disabled?
                    </h2>
                  </div>
                  <p>{disabledReason}</p>
                </div>
              </div>
            </FadeIn>
          </ReactModal>
        )}
      </>
    );
  }

  return render({
    canShowDisabledReason,
    isDisabled,
    openWhyDisabledModal,
    WhyDisabledModal,
  });
}
