import React from 'react';
import {useSelector} from 'react-redux';

import {
  Proposal,
  SidePocketProposal,
  GovernanceProposal,
  StoreState,
} from '../../util/types';
import {InternalNamesToMap} from '../../util/orgDomainMappings';
import {useProposalPeriodInfo} from '../../hooks';
import ProposalPeriod from '../../components/ui/ProposalPeriod';
import ProposalVoters from '../../pages/proposals/moloch/vote/ProposalVoters';
import DropSVG from '../../assets/svg/DropSVG';
import StopwatchSVG from '../../assets/svg/StopwatchSVG';
import CircleCrossSVG from '../../assets/svg/CircleCrossSVG';
import CircleCheckSVG from '../../assets/svg/CircleCheckSVG';

import vc from '../../assets/scss/modules/votingcard.module.scss';

type VotingCardProps = {
  governanceProposal?: GovernanceProposal;
  proposal?: Proposal | SidePocketProposal;
  currentPeriod: number;
};

export default function VotingCard(props: VotingCardProps) {
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const {
    molochProposalId,
    endingPeriod,
    sponsored,
    processed,
    yesShares,
    noShares,
  } = props.proposal || props.governanceProposal || {};
  const {molochProposalId: governanceMolochProposalId} =
    props.governanceProposal || {};

  /**
   * Variables
   */

  const proposalDetails = props.proposal || props.governanceProposal || {};
  const getMolochProposalId = molochProposalId || governanceMolochProposalId;

  /**
   * Use these as the moloch proposal id and index could be -1 from the server.
   *
   * @todo Look into making the db entry `null` by default.
   */
  const molochProposalIdSafe =
    getMolochProposalId !== undefined &&
    getMolochProposalId !== null &&
    getMolochProposalId !== -1
      ? getMolochProposalId
      : undefined;

  const proposalPeriodDates = useProposalPeriodInfo(
    proposalDetails as Proposal
  );

  /**
   * Functions
   */

  function renderCountdownIcon(): JSX.Element {
    switch (orgInternalName) {
      case InternalNamesToMap.liquidity:
        return <DropSVG />;
      case InternalNamesToMap.nft:
      case InternalNamesToMap.thelao:
      default:
        return <StopwatchSVG />;
    }
  }

  function renderApprovedIcon(): JSX.Element {
    switch (orgInternalName) {
      case InternalNamesToMap.liquidity:
        return <DropSVG />;
      case InternalNamesToMap.metaverse:
        return <CircleCheckSVG />;
      case InternalNamesToMap.nft:
      case InternalNamesToMap.thelao:
      default:
        return <StopwatchSVG />;
    }
  }

  function renderFailedIcon(): JSX.Element {
    switch (orgInternalName) {
      case InternalNamesToMap.liquidity:
        return <DropSVG />;
      case InternalNamesToMap.metaverse:
        return <CircleCrossSVG />;
      case InternalNamesToMap.nft:
      case InternalNamesToMap.thelao:
      default:
        return <StopwatchSVG />;
    }
  }

  function getProposalStatus() {
    /** REQUEST (NOT SPONSORED) PROPOSALS */
    if (!molochProposalIdSafe || !sponsored) {
      return (
        <div
          className={`${vc['row-w-icon']} ${vc['countdown']} org-countdown org-countdown--pending`}>
          {renderCountdownIcon()}
          <span className={`${vc['status']} org-status org-status--pending`}>
            Pending Nomination
          </span>
        </div>
      );
    }

    /** VOTING PROPOSALS */
    if (
      sponsored &&
      !processed &&
      molochProposalIdSafe &&
      Number(endingPeriod) > props.currentPeriod
    ) {
      return (
        <div className={`${vc['row-w-icon']} ${vc['countdown']} org-countdown`}>
          {renderCountdownIcon()}
          <ProposalPeriod
            proposalPeriodInfo={proposalPeriodDates}
            simpleDisplay={true}
          />
        </div>
      );
    }

    /** VOTING COMPLETED, APPROVED GOVERNANCE PROPOSALS */
    if (
      sponsored &&
      Number(endingPeriod) <= props.currentPeriod &&
      Number(yesShares) > Number(noShares)
    ) {
      return (
        <div
          className={`${vc['row-w-icon']} ${vc['countdown']} org-countdown org-countdown--success`}>
          {renderApprovedIcon()}
          <span className={`${vc['status']} org-status`}>Approved</span>
        </div>
      );
    }

    /** VOTING COMPLETED, FAILED PROPOSALS */
    if (
      sponsored &&
      Number(endingPeriod) <= props.currentPeriod &&
      Number(yesShares) <= Number(noShares)
    ) {
      return (
        <div
          className={`${vc['row-w-icon']} ${vc['countdown']} org-countdown org-countdown--failed`}>
          {renderFailedIcon()}
          <span
            className={`${vc['status']} ${vc['status--failed']} org-status org-status--failed`}>
            Ended
          </span>
        </div>
      );
    }
  }

  return (
    <>
      {getProposalStatus()}

      <ProposalVoters proposal={props.proposal || props.governanceProposal} />
    </>
  );
}
