import React from 'react';

import l from '../../assets/scss/modules/logo.module.scss';

interface LogoLiquidityProps {
  orgInternalName: string;
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoLiquidity(props: LogoLiquidityProps) {
  const classNameSizes = {
    neptune: props.size ? `${l[`neptune--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.neptune} ${classNameSizes.neptune}`}>
      <img
        alt="Neptune Logo"
        src={`/orgs/${props.orgInternalName}/images/neptune-logo-large.png`}
      />
    </div>
  );
}
