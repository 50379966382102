import React, {useState, useEffect, useCallback} from 'react';
import {useForm, FormContext, Controller} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {
  getValidationError,
  formatNumber,
  isOnboardingWithUSDCOrgName,
} from '../../../util/helpers';
import {InternalNamesToMap} from '../../../util/orgDomainMappings';
import {OnFormChangeCallback, Forms} from './types';
import {StoreState} from '../../../util/types';
import {useLatestETHPrice} from '../../../hooks';

// import DiscordVerification from '../../../components/feedback/DiscordVerification';
import DiscordSVG from '../../../assets/svg/DiscordSVG';
import InputError from '../../../components/common/InputError';
import Media from 'react-media';
import RadioList from '../../../components/common/RadioList';
import Slider from '../../../components/common/Slider';

import i from '../../../assets/scss/modules/input.module.scss';

enum Fields {
  amountExpected = 'amountExpected',
  discordUsername = 'discordUsername',
  entityType = 'entityType',
  personLegalName = 'personLegalName',
  companyLegalName = 'companyLegalName',
  trustLegalName = 'trustLegalName',
}

type EntityFormProps = {
  onFormChange: OnFormChangeCallback;
};

export type EntityPathway = 'person' | 'company' | 'trust';

export type Values = {
  amountExpected: string;
  discordUsername: string;
  entityType: EntityPathway;
  personLegalName: string;
  companyLegalName: string;
  trustLegalName: string;
};

type Conditions = {
  [l in Fields]: boolean[];
};

const getConditionsMap = (
  formValues: Record<string, any>
): Partial<Conditions> => ({
  [Fields.companyLegalName]: [formValues[Fields.entityType] === 'company'],
  [Fields.personLegalName]: [formValues[Fields.entityType] === 'person'],
  [Fields.trustLegalName]: [formValues[Fields.entityType] === 'trust'],
});

export default function EntityForm(props: EntityFormProps) {
  /**
   * State
   */

  // keeping mainly to restore conditional fields
  const [values, setValues] = useState<Partial<Values>>({});

  /**
   * Selectors
   */

  const orgSaleSettings = useSelector((s: StoreState) =>
    s.org ? s.org.saleSettings : undefined
  );
  const orgFeatures = useSelector((s: StoreState) => s.org && s.org.features);
  const discordUsernameRequired =
    orgFeatures && orgFeatures.discordUsernameRequired;
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  /**
   * Validation variables
   */

  const ERROR_REQUIRED_FIELD = 'This is a required field.';
  // const ERROR_REQUIRED_VERIFICATION_FIELD = 'This verification is required.';
  const ERROR_INVALID_DISCORD_USERNAME =
    'Please provide your Discord username. Include the numeric identifier if your username has it (e.g. janedoe#0123).';

  const discordUsernameRegex = /^(.{2,32}#\d{4}|(?!.*\.\.)[a-z0-9_.]{2,32})$/;
  const discordUsernameValidation = {
    validate: (discordUsername: string) => {
      if (!discordUsernameRequired) {
        return discordUsername && !discordUsername.match(discordUsernameRegex)
          ? ERROR_INVALID_DISCORD_USERNAME
          : true;
      }

      return !discordUsername
        ? ERROR_REQUIRED_FIELD
        : !discordUsername.match(discordUsernameRegex)
        ? ERROR_INVALID_DISCORD_USERNAME
        : true;
    },
  };

  // validation configuration for react-hook-form
  const validateConfig: Partial<Record<Fields, Record<string, any>>> = {
    companyLegalName: {
      required: ERROR_REQUIRED_FIELD,
    },
    discordUsername: {
      ...discordUsernameValidation,
      // required: ERROR_REQUIRED_VERIFICATION_FIELD,
    },
    personLegalName: {
      required: ERROR_REQUIRED_FIELD,
    },
    trustLegalName: {
      required: ERROR_REQUIRED_FIELD,
    },
  };

  /**
   * Slider variables
   */

  const amountExpectedStep: number = orgSaleSettings
    ? orgSaleSettings.ethPerPercent
    : 0;
  const amountExpectedMin: number = orgSaleSettings
    ? orgSaleSettings.minPercent * amountExpectedStep
    : 1 * amountExpectedStep;
  const amountExpectedMax: number = orgSaleSettings
    ? orgSaleSettings.maxPercent * amountExpectedStep
    : 9 * amountExpectedStep;

  /**
   * External hooks
   */

  const ethPrice = useLatestETHPrice();

  const form = useForm<Values>({
    defaultValues: {
      amountExpected: String(amountExpectedMin),
      discordUsername: '',
      entityType: 'person',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  /**
   * Variables
   */

  const {errors, formState, register, setValue, watch} = form;

  /**
   * @note From the docs: "Read the formState before render to subscribe the form state through Proxy"
   * @see https://react-hook-form.com/api#formState
   */
  const {isValid} = formState;

  // watch all values that change
  // see: https://react-hook-form.com/api/#watch
  const formValues = watch() as Values;

  const conditionsMap = getConditionsMap(formValues);

  const renderConditionsToString = JSON.stringify(
    Object.keys(conditionsMap).map((f) => ({
      [f]: testRenderConditions(f as Fields),
    }))
  );

  /**
   * testRenderConditionsCached cached callback
   */

  const testRenderConditionsCached = useCallback(testRenderConditions, [
    conditionsMap,
  ]);

  /**
   * Effects
   */

  // send parent the current form status
  useEffect(() => {
    props.onFormChange({
      formName: Forms.entity,
      isValid,
      triggerValidation: form.triggerValidation,
      values: form.getValues() as Values,
    });
  }, [form, isValid, props]);

  // re-validate if conditional is shown and has a value
  useEffect(() => {
    Object.keys(conditionsMap).forEach(async (f) => {
      const field = f as Fields;

      (await testRenderConditionsCached(field)) &&
        form.getValues()[field] &&
        form.triggerValidation(field, true);
    });
  }, [
    form,
    conditionsMap,
    renderConditionsToString,
    testRenderConditionsCached,
  ]);

  /**
   * If the `amountExpected` default value changes on init (async from org)
   * then set the value to react-hook-form.
   */
  useEffect(() => {
    setValue(Fields.amountExpected, String(amountExpectedMin));
  }, [amountExpectedMin, setValue]);

  /**
   * Maintains user input using localStorage. To retrieve after Discord
   * verification check during site redirection.
   */
  // useEffect(() => {
  //   localStorage.setItem('entityForm', JSON.stringify(form.getValues()));
  // }, [form, setValue]);

  /**
   * Functions
   */

  function handleValuesChange(key: keyof Values) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;

      setValues({...values, [key]: value});
    };
  }

  function testRenderConditions(field: Fields) {
    return (conditionsMap[field] || []).every((c: boolean) => c);
  }

  return (
    <>
      <FormContext {...form}>
        <form className="org-verify-form" onSubmit={(e) => e.preventDefault()}>
          {/** DISCORD AUTH VERIFICATION */}
          {/* <Controller
            as={
              <DiscordVerification
                aria-describedby="error-discordUsername"
                aria-invalid={errors.discordUsername ? 'true' : 'false'}
              />
            }
            name={Fields.discordUsername}
            rules={discordUsernameRequired && validateConfig.discordUsername}
          />
          <InputError
            error={getValidationError(Fields.discordUsername, errors)}
            id="error-discordUsername"
          /> */}

          {/** DISCORD USERNAME */}
          <label>
            <span className={`${i['label--column']} org-label--column`}>
              Discord Username{' '}
              <small>(include Unique Identifier if applicable)</small>
            </span>
            <div className={`${i['input-prefix__wrap']}`}>
              <input
                aria-describedby="error-personLegalName"
                aria-invalid={errors.discordUsername ? 'true' : 'false'}
                defaultValue={values.discordUsername}
                name={Fields.discordUsername}
                onChange={handleValuesChange(Fields.discordUsername)}
                placeholder="janedoe#0123"
                ref={
                  validateConfig.discordUsername &&
                  register(validateConfig.discordUsername)
                }
                type="text"
                className={`${i['input-prefix--sm']} ${i['width-50']}`}
              />
              <div
                className={`${i['input-prefix__item--sm']} org-input-prefix__item--sm org-input-prefix-discord`}>
                <DiscordSVG />
              </div>
            </div>
            <InputError
              error={getValidationError(Fields.discordUsername, errors)}
              id="error-discordUsername"
            />
          </label>

          <label className={`${i['label--column']} org-label--column`}>
            Type of entity
          </label>
          <Controller
            as={
              <RadioList
                items={['person', 'company', 'trust']}
                itemsText={['Person', 'Company', 'Trust']}
                defaultValue={form.getValues().entityType}
              />
            }
            name={Fields.entityType}
          />

          {testRenderConditions(Fields.personLegalName) && (
            <label>
              <span className={`${i['label--column']} org-label--column`}>
                Legal Name of Person
              </span>
              <input
                aria-describedby="error-personLegalName"
                aria-invalid={errors.personLegalName ? 'true' : 'false'}
                defaultValue={values.personLegalName}
                name={Fields.personLegalName}
                onChange={handleValuesChange(Fields.personLegalName)}
                placeholder="Your First, Middle & Last Name"
                ref={
                  validateConfig.personLegalName &&
                  register(validateConfig.personLegalName)
                }
                type="text"
              />
              <InputError
                error={getValidationError(Fields.personLegalName, errors)}
                id="error-personLegalName"
              />
            </label>
          )}

          {testRenderConditions(Fields.companyLegalName) && (
            <label>
              <span className={`${i['label--column']} org-label--column`}>
                Legal Name of Company
              </span>
              <input
                aria-describedby="error-companyLegalName"
                aria-invalid={errors.companyLegalName ? 'true' : 'false'}
                defaultValue={values.companyLegalName}
                name={Fields.companyLegalName}
                onChange={handleValuesChange(Fields.companyLegalName)}
                placeholder="e.g. DarkCrystal Fund LP"
                ref={
                  validateConfig.companyLegalName &&
                  register(validateConfig.companyLegalName)
                }
                type="text"
              />
              <InputError
                error={getValidationError(Fields.companyLegalName, errors)}
                id="error-companyLegalName"
              />
            </label>
          )}

          {testRenderConditions(Fields.trustLegalName) && (
            <label>
              <span className={`${i['label--column']} org-label--column`}>
                Legal Name of Trust
              </span>
              <input
                aria-describedby="error-trustLegalName"
                aria-invalid={errors.trustLegalName ? 'true' : 'false'}
                defaultValue={values.trustLegalName}
                name={Fields.trustLegalName}
                onChange={handleValuesChange(Fields.trustLegalName)}
                placeholder="e.g. The Ethereum Charitable Trust"
                ref={
                  validateConfig.trustLegalName &&
                  register(validateConfig.trustLegalName)
                }
                type="text"
              />
              <InputError
                error={getValidationError(Fields.trustLegalName, errors)}
                id="error-trustLegalName"
              />
            </label>
          )}

          {/* AMOUNT EXPECTED SLIDER */}
          <label
            className={`${i['label--column']} org-label--column`}
            htmlFor={Fields.amountExpected}
            id={`${Fields.amountExpected}-label`}>
            How much are you thinking of investing? This helps us gauge project
            interest and is not a factor in your application.
          </label>
          <Media queries={{mediumMin: '(min-width: 48em)' /* $bp-md: 768px */}}>
            {(matches) => (
              <div style={{width: matches.mediumMin ? '40%' : '75%'}}>
                <Controller
                  as={
                    <Slider
                      aria-labelledby={`${Fields.amountExpected}-label`}
                      defaultValue={amountExpectedMin}
                      id={Fields.amountExpected}
                      max={amountExpectedMax}
                      min={amountExpectedMin}
                      step={amountExpectedStep}
                    />
                  }
                  name={Fields.amountExpected}
                />

                <p className="org-input-row-text">
                  {formatNumber(formValues.amountExpected)}{' '}
                  {isOnboardingWithUSDCOrgName(
                    orgInternalName as InternalNamesToMap
                  )
                    ? 'USDC'
                    : `ETH ($${formatNumber(
                        (
                          Number(formValues.amountExpected) * (ethPrice || 0)
                        ).toFixed(0)
                      )})`}
                </p>
              </div>
            )}
          </Media>
        </form>
      </FormContext>
    </>
  );
}
