import gql from 'graphql-tag';

/**
 * Used in the admin page
 * @todo will also be used for members and members details page
 */

const memberFragment = gql`
  fragment memberInformation on Proposal {
    id
    proposalId
    proposalIndex
    applicant
    tributeOffered
    sharesRequested
    noShares
    yesShares
    startingPeriod
    proposer
    didPass
    cancelled
    whitelist
    guildkick
    sponsored
    processed
    details
    newMember
  }
`;

/**
 * GET_PENDING_MEMBER_PROPOSALS
 *
 * Get the latest pending member proposals, that are awaiting sponsoring
 */
export const GET_PENDING_MEMBER_PROPOSALS = gql`
  query MemberProposals {
    proposals(
      orderBy: proposalId
      orderDirection: desc
      first: 1000
      where: {
        paymentRequested: 0
        cancelled: false
        processed: false
        proposalIndex: null
      }
    ) {
      ...memberInformation
    }
  }
  ${memberFragment}
`;

/**
 * GET_VOTING_MEMBER_PROPOSALS
 *
 * Get the latest voting member proposals, that are awaiting processing or voting
 */
export const GET_VOTING_MEMBER_PROPOSALS = gql`
  query MemberProposals {
    moloches {
      id
      summoningTime
      periodDuration
      votingPeriodLength
      gracePeriodLength
    }
    proposals(
      orderBy: proposalIndex
      orderDirection: asc
      first: 1000
      where: {
        # paymentRequested: 0
        cancelled: false
        processed: false
        proposalIndex_not: null
      }
    ) {
      ...memberInformation
    }
  }
  ${memberFragment}
`;

/**
 * GET_PROCESSED_MEMBER_PROPOSALS
 *
 * Gets a list of processed proposals
 */
export const GET_PROCESSED_MEMBER_PROPOSALS = gql`
  query MemberProposals {
    proposals(
      orderBy: proposalIndex
      orderDirection: desc
      first: 1000
      where: {paymentRequested: 0, cancelled: false, processed: true}
    ) {
      ...memberInformation
    }
  }
  ${memberFragment}
`;

/**
 * GET_GENERAL_MEMBERS
 *
 * Gets a list of existing contributors
 */
export const GET_GENERAL_MEMBERS = gql`
  query GetGeneralMembers {
    moloches {
      totalShares
    }
    general: contributors(
      first: 1000
      where: {didRagequit: false, exists: true}
    ) {
      id
      shares
      exists
      loot
      applicant
      didRagequit
    }
  }
`;

/**
 * GET_SEEKING_MEMBERS
 *
 * Get the latest member proposals, that are awaiting sponsoring or processing
 */
export const GET_SEEKING_MEMBERS = gql`
  query GetSeekingMembers {
    seeking: contributors(
      first: 1000
      where: {isPendingOrActive: true, exists: false}
    ) {
      id
      shares
      exists
      loot
      applicant
      isPendingOrActive
    }
  }
`;

/**
 * `tokenBalances` is for *any* Moloch "member" (someone who has balances).
 *
 * @note A Moloch Member is created for "funded" (e.g. `didPass: true`)
 *   proposals requesting payment (i.e. `paymentRequested > 0`).
 */
export const GET_MEMBER_DETAILS = gql`
  query GetMemberDetails($id: ID!) {
    member(id: $id) {
      id
      memberAddress
      shares
      exists
      loot
      tokenBalances {
        token {
          tokenAddress
        }
        tokenBalance
      }
      votes {
        uintVote
        proposal {
          proposalIndex
          proposalId
          didPass
          processed
        }
      }
    }
  }
`;

export const VOTING_POWER_AT_SNAPSHOT = gql`
  query VotingPowerAtSnapshot($snapshot: Int!, $addresses: [String!]) {
    members(block: {number: $snapshot}, where: {memberAddress_in: $addresses}) {
      delegateKey
      memberAddress
      shares
    }
  }
`;

export const GET_ALL_CONTRIBUTING_MEMBERS = gql`
  query GetAllContributingMembers {
    # unique processed contributors
    processedMembers: contributors(
      first: 1000
      where: {didRagequit: false, exists: true, shares_gt: "1"}
    ) {
      applicant
    }
    # this includes: submitted, sponsored, processed contributor proposals
    totalMembers: contributors(
      first: 1000
      where: {didRagequit: false, isPendingOrActive: true}
    ) {
      applicant
    }
  }
`;
