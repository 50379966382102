import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {StoreState, Moloches, Proposal} from '../util/types';

export type ProposalPeriodInfo = {
  startPeriod: Date | null;
  endPeriod: Date | null;
  gracePeriod: Date | null;
  isVotingReady: boolean;
};

const INITIAL_PROPOSAL_PERIOD = {
  startPeriod: null,
  endPeriod: null,
  gracePeriod: null,
  isVotingReady: false,
};

export default function useProposalPeriodInfo(molochProposal: Proposal) {
  const molochConstants = useSelector(
    (state: StoreState) => state.blockchain.molochConstants
  );

  const [proposalPeriodInfo, setProposalPeriodInfo] =
    useState<ProposalPeriodInfo>(INITIAL_PROPOSAL_PERIOD);

  useEffect(() => {
    async function fetchProposalPeriod() {
      try {
        const {
          summoningTime = 0,
          periodDuration = 0,
          gracePeriodLength = 0,
          votingPeriodLength = 0,
        } = molochConstants as Moloches;
        const {startingPeriod} = molochProposal as Proposal;

        /** @note
         * sp: Moloch - Starting Period
         * sm: Moloch - Summoning Time
         * gp: Moloch - Grace Period Length
         * st: Calculated Voting Start Time
         * gr: Calculated Voting Grace Period
         * ex: Calculated Voting End Time
         */

        const sp: number = Number(startingPeriod) * Number(periodDuration);
        const sm: number = Number(summoningTime);
        const gp: number = Number(gracePeriodLength) * Number(periodDuration);

        const st: number = sp + sm;
        const ex: number =
          st + Number(votingPeriodLength) * Number(periodDuration);
        const gr: number =
          st + Number(votingPeriodLength) * Number(periodDuration) + gp;

        const currentDate = new Date();
        const startPeriod = new Date(st * 1000);
        const endPeriod = new Date(ex * 1000);
        const gracePeriod = new Date(gr * 1000);

        setProposalPeriodInfo({
          ...proposalPeriodInfo,
          startPeriod,
          endPeriod,
          gracePeriod,
          isVotingReady: currentDate > startPeriod,
        });
      } catch (error) {}
    }

    const {startPeriod, endPeriod, gracePeriod} = proposalPeriodInfo;

    if (
      !startPeriod &&
      !endPeriod &&
      !gracePeriod &&
      molochConstants &&
      molochConstants.summoningTime
    ) {
      fetchProposalPeriod();
    }
  }, [proposalPeriodInfo, molochConstants, molochProposal]);

  return proposalPeriodInfo;
}
