import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ApolloProvider} from '@apollo/react-hooks';
import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
// wallet providers
import WalletConnectProvider from '@walletconnect/web3-provider';

import * as serviceWorker from './serviceWorker';
import {disableReactDevTools, isTributeOrgName} from './util/helpers';
import {ENVIRONMENT, INFURA_PROJECT_ID} from './util/config';
import {InternalNamesToMap} from './util/orgDomainMappings';
import {store} from './store';
import App from './App';
import Init, {InitError} from './Init';
import Web3ModalManager from './components/web3/Web3ModalManager';

import './assets/scss/global.scss';

const root = document.getElementById('root');

// disable React dev tools for production
ENVIRONMENT === 'production' && disableReactDevTools();

// will be deprecated eventually, for now we set it to false to
// silence the console warning
window.ethereum &&
  window.ethereum.autoRefreshOnNetworkChange &&
  (window.ethereum.autoRefreshOnNetworkChange = false);

// Tell Web3modal what providers we have available.
// Built-in web browser provider (only one can exist at a time),
// MetaMask, Brave or Opera is added automatically by Web3modal
function getProviderOptions() {
  const providerOptions = {
    // Injected providers
    injected: {
      display: {
        name: 'MetaMask',
        description: 'Connect with the provider in your Browser',
      },
      package: null,
    },
    // WalletConnect provider
    walletconnect: {
      display: {
        name: 'WalletConnect',
        description: 'Connect with your mobile wallet',
      },
      package: WalletConnectProvider,
      options: {
        infuraId: INFURA_PROJECT_ID, // required
        qrcodeModalOptions: {
          mobileLinks: ['rainbow', 'metamask', 'argent', 'trust'],
        },
      },
    },
  };
  return providerOptions;
}

function renderIndex(
  apolloClient: ApolloClient<NormalizedCacheObject> | undefined,
  error: Error | undefined,
  isInitComplete: boolean,
  orgInternalName: string | null
) {
  // Render  <App /> without apolloClient provider, no subgraph required
  if (
    isTributeOrgName(orgInternalName as InternalNamesToMap) &&
    isInitComplete
  ) {
    return <App />;
  }

  // Render <App /> with apolloClient if init phase is complete.
  if (isInitComplete && apolloClient) {
    return (
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    );
  }

  if (error) {
    return <InitError error={error} />;
  }

  return null;
}

if (root !== null) {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Web3ModalManager providerOptions={getProviderOptions()}>
          <Init
            render={({apolloClient, error, isInitComplete, orgInternalName}) =>
              renderIndex(apolloClient, error, isInitComplete, orgInternalName)
            }
          />
        </Web3ModalManager>
      </BrowserRouter>
    </Provider>,
    root
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
