import React from 'react';
import {trimText} from '../../../util/helpers';

import s from '../../../assets/scss/modules/proposaldetails.module.scss';

type ProposalLinkProps = {
  title?: string;
  url: string;
};

type ProposalLinksListProps = {
  title?: string;
  urls: string[];
};

export function ProposalLink(props: ProposalLinkProps) {
  const {title, url} = props;

  if (!url) return null;

  return (
    <div className={`${s['info-item']} org-info-item`}>
      {title && <span>{title}</span>}

      <a
        href={/^https?:\/\//.test(url) ? url : `https://${url}`}
        rel="noopener noreferrer"
        title={url}
        target="_blank">
        {trimText(url, {
          characters: 32,
          word: false,
        })}
      </a>
    </div>
  );
}

export function ProposalLinksList(props: ProposalLinksListProps) {
  const {title, urls} = props;

  if (!urls || !urls.length) return null;

  return (
    <div className={`${s['info-item']} org-info-item ${s['info-item-list']}`}>
      {title && <span>{title}</span>}

      <ul>
        {urls.map((u: string, i: number) => {
          const parsedURL = /^https?:\/\//.test(u) ? u : `https://${u}`;
          return (
            <li key={i}>
              <a
                href={parsedURL}
                rel="noopener noreferrer"
                target="_blank"
                title={parsedURL}>
                {trimText(u, {
                  characters: 32,
                  word: false,
                })}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
