import React, {useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {GetInvestmentProposalResponse, StoreState} from '../../../util/types';
import {getQueryStringParam, normalizeString} from '../../../util/helpers';
import {InvestmentProposalContext} from '../InvestmentProposalContainer';
import {ProposalType} from '../../../util/enums';
import MinionProposal from './MinionProposal';
import MolochEasyApplyInvestmentProposal from './MolochEasyApplyInvestmentProposal';
import MolochInvestmentProposalFunded from './MolochInvestmentProposalFunded';
import MolochInvestmentProposalNotFunded from './MolochInvestmentProposalNotFunded';
import MolochSponsorInvestmentProposal from './MolochSponsorInvestmentProposal';
import MolochSubmitInvestmentProposal from './MolochSubmitInvestmentProposal';
import MolochVoting from './MolochVoting';

type MolochInvestmentProposalActionsProps = {
  /**
   * Helpful to send the parent receipt of a `proposalId`
   * once the proposal has been submitted to the blockchain.
   */
  onProposalId?: (proposalId: number) => void;
  proposal: GetInvestmentProposalResponse;
};

export default function MolochInvestmentProposalActions(
  props: MolochInvestmentProposalActionsProps
): JSX.Element | null {
  const {proposal} = props;

  /**
   * State
   */

  const [submittedProposalId, setSubmittedProposalId] = useState<number>();

  /**
   * Selectors
   */

  const orgFeatures = useSelector((s: StoreState) => s.org && s.org.features);

  /**
   * Their hooks
   */

  const location = useLocation();

  /**
   * Context
   */

  const {molochGQLInvestmentProposal} = useContext(InvestmentProposalContext);

  /**
   * Variables
   */

  /**
   * @note This is currently only used for Minion proposals (submitted via Admin).
   * @note We use `parseInt` instead of `Number` so null values are not returned as `0`.
   */
  const molochProposalIdSearchParam = parseInt(
    getQueryStringParam('molochProposalId', location.search) || ''
  );
  const molochProposalIdSearchParamSafe = isNaN(molochProposalIdSearchParam)
    ? null
    : molochProposalIdSearchParam;

  const molochProposalIdSafe =
    proposal &&
    proposal.molochProposalId !== null &&
    proposal.molochProposalId !== undefined &&
    proposal.molochProposalId >= 0
      ? Number(proposal.molochProposalId)
      : submittedProposalId !== undefined
      ? submittedProposalId
      : molochProposalIdSearchParamSafe !== null
      ? molochProposalIdSearchParamSafe
      : undefined;

  const hasMolochProposalId =
    molochProposalIdSafe !== -1 &&
    molochProposalIdSafe !== undefined &&
    molochProposalIdSafe !== null;

  const easyApplyFeatureFlag = orgFeatures && orgFeatures.easyApplyProject;

  /**
   * RENDER LOGIC
   */

  // Render EasyApply (combined submit & sponsor)
  if (easyApplyFeatureFlag && !hasMolochProposalId) {
    return (
      <MolochEasyApplyInvestmentProposal
        onProposalId={(proposalId) => {
          /**
           * Now that we have a `proposalId` from EasyApply
           * set it in state so TheGraph gql queries will pick it up.
           */
          if (proposalId !== null && proposalId !== undefined) {
            setSubmittedProposalId(proposalId);
            props.onProposalId && props.onProposalId(proposalId);
          }
        }}
        proposal={proposal}
      />
    );
  }

  /**
   * Render submit
   *
   * @note For when `easyApplyFeatureFlag = false`
   */
  if (easyApplyFeatureFlag === false && !hasMolochProposalId) {
    return (
      <MolochSubmitInvestmentProposal
        onComplete={({proposalId}) => {
          /**
           * Now that we have a `proposalId` set it in state
           * so TheGraph gql queries will pick it up.
           */
          if (proposalId !== null && proposalId !== undefined) {
            setSubmittedProposalId(proposalId);
            props.onProposalId && props.onProposalId(proposalId);
          }
        }}
        proposal={proposal}
      />
    );
  }

  /**
   * Render actions based on a submitted proposal.
   *
   * Use `molochGQLInvestmentProposal` to detemine the status of the proposal
   */
  if (molochGQLInvestmentProposal && hasMolochProposalId) {
    const {details} = molochGQLInvestmentProposal;

    const proposalType = normalizeString(details).startsWith(
      ProposalType.MINION
    )
      ? ProposalType.MINION
      : ProposalType.PAYMENT;

    /**
     * Render sponsor
     *
     * @note This can occur if a proposal was submitted without EasyApply.
     */
    if (!molochGQLInvestmentProposal.sponsored) {
      return (
        <MolochSponsorInvestmentProposal
          molochProposalId={molochProposalIdSafe}
          paymentRequestedWEI={molochGQLInvestmentProposal.paymentRequested}
        />
      );
    }

    // Render Voting
    if (
      molochGQLInvestmentProposal.sponsored &&
      !molochGQLInvestmentProposal.processed
    ) {
      return (
        <MolochVoting
          molochProposal={molochGQLInvestmentProposal}
          proposalType={proposalType}
        />
      );
    }

    // Render voting completed; proposal did not pass.
    if (
      molochGQLInvestmentProposal.processed &&
      !molochGQLInvestmentProposal.didPass
    ) {
      return (
        <MolochInvestmentProposalNotFunded
          molochProposal={molochGQLInvestmentProposal}
        />
      );
    }

    // Render voting completed; proposal passed.
    if (
      molochGQLInvestmentProposal.processed &&
      molochGQLInvestmentProposal.didPass
    ) {
      if (proposalType === ProposalType.MINION) {
        // Render execute Minion action
        return <MinionProposal molochProposal={molochGQLInvestmentProposal} />;
      } else {
        // Render proposal passed
        return (
          <MolochInvestmentProposalFunded
            molochProposal={molochGQLInvestmentProposal}
          />
        );
      }
    }
  }

  // Default fallthrough
  return null;
}
