import React from 'react';
import {useSelector} from 'react-redux';

import {getOrgText} from '../../util/helpers';
import {StoreState} from '../../util/types';
import Wrap from '../../components/layout/Wrap';
import MentorCardAlt from './MentorCardAlt';
import FadeIn from '../../components/common/FadeIn';

import s from '../../assets/scss/modules/mentorcard.module.scss';

// manually ordering in alphabetical order by last name
const curatorsList = [
  {
    name: 'Fake Guide 1',
    handle: '@fake_guide_1',
    twitter: 'https://twitter.com/fake_guide_1',
    bio: (
      <>
        Founder,{' '}
        <a
          href="https://twitter.com/fake_guide_1_co"
          target="_blank"
          rel="noopener noreferrer">
          @fake_guide_1_co
        </a>
      </>
    ),
  },
  {
    name: 'Fake Guide 2',
    handle: '@fake_guide_2',
    twitter: 'https://twitter.com/fake_guide_2',
    bio: (
      <>
        Founder,{' '}
        <a
          href="https://twitter.com/fake_guide_2_co"
          target="_blank"
          rel="noopener noreferrer">
          @fake_guide_2_co
        </a>
      </>
    ),
  },
];

export default function MentorsLiquidity() {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgMentorsText = getText('OrgMentorsText');

  return (
    <Wrap className="section-wrapper">
      <FadeIn>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">
            {orgMentorsText}
          </h2>
        </div>

        <div className="card org-card org-mentors-container">
          <div
            className={`${s['mentor-introduction']} org-mentor-introduction`}>
            <p>
              Guides are part of the Neptune network. Lorem ipsum dolor sit
              amet, consectetuer adipiscing elit. Aenean commodo ligula eget
              dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
              parturient montes, nascetur
            </p>
          </div>

          <div className={`${s['mentor-container']} org-mentor-container`}>
            {curatorsList.map((curator) => (
              <MentorCardAlt
                key={curator.name}
                name={curator.name}
                handle={curator.handle}
                socialMediaLinks={{
                  twitter: curator.twitter,
                }}>
                {curator.bio}
              </MentorCardAlt>
            ))}
          </div>
        </div>
      </FadeIn>
    </Wrap>
  );
}
