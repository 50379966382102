import React from 'react';

export function ProjectIcon0SVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>Default Proposal Icon: 0</title>
      <circle cx="31" cy="31" r="31" fill="#242424" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4923 15.9771L12.8412 36.1775L9.90978 33.164L29.5608 12.9635L32.4923 15.9771ZM12.8412 38.141L34.4702 16.0037L29.5608 11L8 33.164L12.8412 38.141ZM44.8452 27.662L25.1942 47.8625L22.2628 44.8489L41.9131 24.6484L44.8452 27.662ZM24.3054 40.6879L21.374 37.6743L41.025 17.4738L43.9565 20.4874L24.3054 40.6879ZM29.8512 45.1361L49.5022 24.9357L52.4337 27.9493L32.7826 48.1499L29.8512 45.1361ZM45.8472 26.7289L42.8209 23.618L45.8662 20.4874L40.961 15.4751L19.4635 37.6743L23.3976 41.7183L20.3523 44.8489L25.1942 49.826L28.8485 46.0692L32.7826 50.1133L54.3434 27.9493L49.5022 22.9722L45.8472 26.7289V26.7289Z"
        fill="#057FEF"
      />
    </svg>
  );
}

export function ProjectIcon1SVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>Default Proposal Icon: 1</title>
      <circle cx="31" cy="31" r="31" fill="#242424" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4998 22.2585L17.3097 20.6361V34.7067L30.9972 42.6091L44.6855 34.7067V20.6361L47.4954 22.2585V41.3082L30.9972 50.8331L14.4998 41.3082V22.2585ZM28.1504 14.3811L30.9987 12.7369L38.98 17.345V26.5604L30.9987 31.1684L23.0181 26.5604V17.345L28.1504 14.3811ZM43.1863 33.841L31.0003 40.8766L18.815 33.841V19.7704L21.5175 18.2104V27.4265L31.0003 32.9013L40.4838 27.4265V18.2104L43.1863 19.7704V33.841ZM13 21.3924V42.1771L30.9996 52.5695L49 42.1771V21.3924L30.9996 11L13 21.3924V21.3924Z"
        fill="#F2AF61"
      />
    </svg>
  );
}

export function ProjectIcon2SVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>Default Proposal Icon: 2</title>
      <circle cx="31" cy="31" r="31" fill="#242424" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6864 20.2396V43.3992L21.4413 27.1887L14.6864 20.2396ZM23.2439 26.8071L31.7019 35.5339L38.2663 29.5596L36.8181 27.0842L32.3634 31.5379L27.2449 17.204L23.2439 26.8071ZM40.2218 29.8863L48.6578 44.3045V22.2274L40.2218 29.8863ZM47.4808 45.311L39.0668 30.9315L31.6345 37.6789L22.5908 28.3745L15.5341 45.311H47.4808ZM13.1719 16.4159L22.0964 25.6236L27.3568 13L32.9889 28.7723L37.1378 24.6241L39.416 28.5179L50.1719 18.7281V46.8283H13.1719V16.4159V16.4159Z"
        fill="#F261B8"
      />
    </svg>
  );
}

export function ProjectIcon3SVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>Default Proposal Icon: 3</title>
      <circle cx="31" cy="31" r="31" fill="#242424" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5805 16.3202L15.084 42.5359L36.7422 24.524L31.5805 16.3202ZM48.8503 43.7644L45.3319 38.1733L43.1091 43.7644H48.8503ZM41.9296 43.3872L44.5368 36.9101L37.3975 25.5648L30.2013 31.5414L41.9296 43.3872ZM29.2481 32.3352L15.4884 43.7645H40.5649L29.2481 32.3352ZM12.0737 45L31.5808 14L51.0872 45H12.0737V45Z"
        fill="#BAF11C"
      />
    </svg>
  );
}

export function ProjectIcon4SVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>Default Proposal Icon: 4</title>
      <circle cx="31" cy="31" r="30.5" fill="#242424" stroke="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5606 19.6464L38.5205 16.6871L53.3336 31.5002L38.5205 46.3134L35.5606 43.3541L47.4145 31.5002L35.5606 19.6464ZM33.8744 19.6464L30.9145 22.6062L27.9546 19.6469L30.9145 16.6871L33.8744 19.6464ZM26.2687 19.6469L14.4148 31.5002L26.2687 43.3541L23.3088 46.3134L8.49565 31.5002L23.3088 16.6871L26.2687 19.6469ZM31.7579 23.4491L34.7177 20.4898L45.7279 31.4999L34.7177 42.5101L31.7579 39.5508L39.8087 31.4999L31.7579 23.4491ZM22.0208 31.4999L30.0717 39.5508L27.1118 42.5101L16.1017 31.4999L27.1118 20.4898L30.0717 23.4491L22.0208 31.4999ZM30.9148 24.2931L38.122 31.5002L30.9148 38.7073L23.7071 31.5002L30.9148 24.2931ZM27.9546 43.3539L30.9145 40.394L33.8744 43.3539L30.9145 46.3132L27.9546 43.3539ZM38.5207 15L34.7176 18.803L30.9146 15L27.1116 18.803L23.3086 15L6.80859 31.5L23.3086 48L27.1116 44.197L30.9146 48L34.7176 44.197L38.5207 48L55.0206 31.5L38.5207 15V15Z"
        fill="#ED9EFF"
      />
    </svg>
  );
}

export function ProjectIcon5SVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>Default Proposal Icon: 5</title>
      <circle cx="31" cy="31" r="31" fill="#242424" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5022 21.2591L21.5935 17.1647L33.1989 26.3486V35.2199L21.6212 44.4188L14.5022 40.3087V21.2591ZM23.0214 45.2277L33.5002 36.9017L46.577 40.8427L31.0012 49.835L23.0214 45.2277ZM47.4976 39.5501L34.7009 35.6939V25.8754L47.4976 22.0372V39.5501ZM46.5983 20.7396L33.4982 24.6687L22.9939 16.3569L31.0007 11.7345L46.5983 20.7396ZM13 20.3924V41.1771L30.9996 51.5695L49 41.1771V20.3924L30.9996 10L13 20.3924V20.3924Z"
        fill="#87BAD6"
      />
    </svg>
  );
}

export function ProjectIcon6SVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>Default Proposal Icon: 6</title>
      <circle cx="31" cy="31" r="31" fill="#242424" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.9995 11.6773C36.0762 11.6773 40.8532 13.6225 44.4828 17.1575L38.9784 22.662L30.9995 14.6831L23.0208 22.662L17.5164 17.1575C21.1458 13.6225 25.9229 11.6773 30.9995 11.6773ZM12.2721 35.7847L15.8594 32.1971L21.8351 38.1728L16.3652 43.6422C14.3524 41.3136 12.9885 38.6151 12.2721 35.7847ZM30.9998 50.3423C25.923 50.3423 21.1459 48.3979 17.5164 44.8622L23.0209 39.3576L30.9997 47.3365L38.9785 39.3576L44.483 44.8622C40.8536 48.3979 36.0765 50.3423 30.9998 50.3423ZM50.122 28.2157C50.3905 30.0678 50.3905 31.9527 50.122 33.8056L47.3271 31.0103L50.122 28.2157ZM40.1651 38.1728L46.1407 32.1971L49.7282 35.7847C49.0117 38.6151 47.6477 41.3136 45.635 43.6422L40.1651 38.1728ZM38.9783 25.0346L44.954 31.0103L38.9783 36.9861L33.0026 31.0103L38.9783 25.0346ZM24.2071 23.8488L30.9997 17.0556L37.7923 23.8488L30.9997 30.6411L24.2071 23.8488ZM30.9997 31.3796L37.7923 38.172L30.9997 44.9644L24.2071 38.172L30.9997 31.3796ZM23.0212 25.0346L28.9969 31.0103L23.0212 36.9861L17.0456 31.0103L23.0212 25.0346ZM11.8782 28.2157L14.6731 31.0103L11.8782 33.8056C11.6098 31.9527 11.6098 30.0678 11.8782 28.2157ZM21.8351 23.8483L15.8594 29.8233L12.2721 26.2365C12.9885 23.4053 14.3525 20.7068 16.3653 18.3782L21.8351 23.8483ZM45.6349 18.3782C47.6477 20.7068 49.0116 23.4053 49.7281 26.2365L46.1407 29.8233L40.1651 23.8483L45.6349 18.3782ZM45.8562 16.1542C41.888 12.1861 36.6119 10 31 10C25.3881 10 20.1119 12.1861 16.1437 16.1542C7.95209 24.3457 7.95209 37.6743 16.1437 45.8666C20.112 49.8346 25.3882 52.02 31.0001 52.02C36.612 52.02 41.888 49.8346 45.8562 45.8666C54.0479 37.6743 54.0479 24.3457 45.8562 16.1542V16.1542Z"
        fill="#63C7BA"
      />
    </svg>
  );
}

export function ProjectIcon7SVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>Default Proposal Icon: 7</title>
      <circle cx="31" cy="31" r="31" fill="#242424" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4241 31.0529L13.9141 26.7411L22.9647 42.4184H17.9862L11.4241 31.0529ZM21.506 48.5144L18.9293 44.0514L44.2462 44.0513L41.6695 48.5145L21.506 48.5144ZM18.3769 19.0096L20.9711 14.5165L37.0799 42.4181L31.8916 42.418L18.3769 19.0096ZM24.8511 42.4185L14.8568 25.1075L17.4343 20.6439L30.0053 42.4185H24.8511ZM38.9663 42.4182L36.3904 37.956H47.7656L45.1897 42.4182H38.9663ZM35.4466 36.3219L32.8707 31.8597L51.2852 31.8598L48.7093 36.3219H35.4466ZM31.927 30.2251L29.3511 25.7629H48.6973L51.274 30.2251L31.927 30.2251ZM28.4079 24.1296L25.832 19.6675H45.179L47.7549 24.1297L28.4079 24.1296ZM41.6694 13.5904L44.2349 18.034H24.8879L22.3224 13.5903L41.6694 13.5904ZM42.5879 12.0001L20.5879 12L9.58789 31.0524L20.5879 50.1048H42.5879L53.5879 31.0525L42.5879 12.0001V12.0001Z"
        fill="#F26164"
      />
    </svg>
  );
}

export function ProjectIcon8SVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>Default Proposal Icon: 8</title>
      <circle cx="31" cy="31" r="31" fill="#242424" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.0985 35.8626L31.0541 50.907L21.5049 41.3578L32.3637 30.4992L26.8684 25.0039L31.0541 20.8183L46.0985 35.8626ZM47.1446 34.8166L31.0541 18.7261L25.8223 23.9579L21.5049 19.6405L31.0541 10.0912L51.462 30.4992L47.1446 34.8166ZM25.8235 26.0512L30.2727 30.5004L20.46 40.313L16.0108 35.8638L25.8235 26.0512ZM20.4594 20.6874L24.7768 25.0048L14.9641 34.8174L10.6467 30.5001L20.4594 20.6874ZM31.055 53L53.5551 30.5001L31.055 8L8.44434 30.5001L31.055 53V53Z"
        fill="#B26DD5"
      />
    </svg>
  );
}

export function ProjectIcon9SVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>Default Proposal Icon: 9</title>
      <circle cx="31" cy="31" r="31" fill="#2C2B2B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0111 48.8555L11.3875 32.1861L21.0111 15.5176H40.2592L44.8915 23.5415L40.8094 30.6448L36.1672 22.605H25.1031L19.5715 32.1869L25.1031 41.7681H34.4164L30.3434 48.8555H21.0111ZM25.9791 40.2497L21.3233 32.1864L25.9791 24.1223H35.2901L39.9346 32.1659L35.2886 40.2497H25.9791ZM32.0937 48.8565L36.1667 41.7691L41.6991 32.1879L41.6862 32.1659L45.7684 25.0618L49.8824 32.1872L40.2587 48.8565H32.0937ZM20.1345 50.3735H41.1341L51.6343 32.1864L41.1341 14H20.1345L9.63428 32.1864L20.1345 50.3735V50.3735Z"
        fill="#00FF75"
      />
    </svg>
  );
}

export function ProjectIcon10SVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <title>Default Proposal Icon: 10</title>
      <circle cx="31" cy="31" r="31" fill="#2C2B2B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9474 20.2646L43.3282 20.2646L40.2018 25.234L18.0737 25.234L14.9474 20.2646ZM18.7992 26.3868L39.4768 26.3868L36.2526 31.5107L22.0234 31.5107L18.7992 26.3868ZM32.5836 37.5476L29.579 32.7725H35.5888L32.5836 37.5476ZM22.8178 32.7728H28.2174L32.5836 39.712L44.8209 20.2648L50.2212 20.2648L32.5836 48.2936L22.8178 32.7728ZM12.6619 19.0027L32.5836 50.6626L52.5059 19.0027L12.6619 19.0027V19.0027Z"
        fill="#00E0FF"
      />
    </svg>
  );
}
