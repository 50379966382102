import React, {useEffect, useState, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';
import ReactModal from 'react-modal';

import Wrap from '../../components/layout/Wrap';
import FadeIn from '../../components/common/FadeIn';
import QuestionCircleSVG from '../../assets/svg/QuestionCircleSVG';
import MembersCard from '../../components/ui/MembersCard';
import {getOrgText} from '../../util/helpers';
import {InternalNamesToMap} from '../../util/orgDomainMappings';
import {Member, MemberMolochResponse, StoreState} from '../../util/types';
import {useMembers} from '../../hooks';
import TimesSVG from '../../assets/svg/TimesSVG';

import b from '../../assets/scss/modules/buttons.module.scss';
import m from '../../assets/scss/modules/modal.module.scss';
import s from '../../assets/scss/modules/members.module.scss';
import t from '../../assets/scss/modules/tabbedwrapper.module.scss';
import sm from '../../assets/scss/modules/sale.module.scss';

enum TabOptions {
  GENERAL = 'GENERAL',
  SEEKING_APPROVAL = 'SEEKING_APPROVAL',
}

/**
 * @todo The username is not updated in the members list on entry from /contribute.
 *   Will require some refactoring.
 */
export default function Members() {
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');

  /**
   * State
   */
  const [tabSelected, setTabSelected] = useState<string>(TabOptions.GENERAL);
  const [seekingApprovalCount, setSeekingApprovalCount] = useState<number>(0);
  const [approvedCount, setApprovedCount] = useState<number>(0);
  const [approved, setApproved] = useState<
    (Member & Partial<MemberMolochResponse>)[]
  >([]);
  const [seekingApproval, setSeekingApproval] = useState<Member[]>([]);
  const [shouldShowWhyModal, setShouldShowWhyModal] = useState<boolean>(false);
  const [totalShares, setTotalShares] = useState<string>('');

  /**
   * External hooks
   */
  const {members, molochGQLMembers} = useMembers();
  const history = useHistory();

  const fetchMolochMembersCached = useCallback(fetchMolochMembers, [
    members,
    molochGQLMembers,
  ]);

  // fetch members from moloch contract
  useEffect(() => {
    if (members && molochGQLMembers && molochGQLMembers.moloches) {
      const {moloches} = molochGQLMembers;

      // set total LAO shares
      setTotalShares(moloches[0].totalShares);

      if (molochGQLMembers.general && molochGQLMembers.seeking) {
        fetchMolochMembersCached();
      }
    }
  }, [members, molochGQLMembers, fetchMolochMembersCached]);

  function handleClick(
    tabSelected: TabOptions.GENERAL | TabOptions.SEEKING_APPROVAL
  ) {
    tabSelected && setTabSelected(tabSelected);
  }

  function handleMemberDetails(ethereumAddress: string) {
    history.push(`/members/${ethereumAddress}`);
  }

  function fetchMolochMembers() {
    if (!molochGQLMembers || !members) return;

    let approvedMembers: (Member & Partial<MemberMolochResponse>)[] = [];
    let seekingApprovalMembers: Member[] = [];

    members.forEach((member: Member) => {
      try {
        let alreadyAddedSeeking: boolean = false;

        const {ethereumAddress} = member.user;
        const filteredMember = molochGQLMembers.general.filter(
          (member: Record<string, any>) => member.applicant === ethereumAddress
        );
        const filteredSeekingMember = molochGQLMembers.seeking.filter(
          (proposal: Record<string, any>) =>
            proposal.applicant === ethereumAddress
        );
        const molochMember = filteredMember[0];
        const molochSeekingMember = filteredSeekingMember[0];

        // add general members, who are processed
        if (molochMember) {
          const maybeMember =
            molochMember.exists &&
            (Number(molochMember.shares) > 0 || Number(molochMember.loot) > 0)
              ? member
              : undefined;
          const hasQuit =
            molochMember.exists &&
            Number(molochMember.shares) === 0 &&
            Number(molochMember.loot) === 0;

          if (maybeMember) {
            approvedMembers.push({...member, shares: molochMember.shares});
          }
          if (!maybeMember && !hasQuit) {
            seekingApprovalMembers.push(member);
            alreadyAddedSeeking = true;
          }
        }

        // add proposals of new members
        if (molochSeekingMember && !alreadyAddedSeeking) {
          seekingApprovalMembers.push(member);
        }
      } catch (err) {
        throw err;
      }

      setApproved(approvedMembers);
      setApprovedCount(approvedMembers.length || 0);

      setSeekingApproval(seekingApprovalMembers);
      setSeekingApprovalCount(seekingApprovalMembers.length || 0);
    });
  }

  function renderMemberCards(
    members: (Member & Partial<MemberMolochResponse>)[]
  ) {
    // Sort in decending order
    const sortMembers = [...members];
    const sortedMembers = sortMembers.sort((a, b) =>
      a.id > b.id ? 1 : b.id > a.id ? -1 : 0
    );

    return sortedMembers.reverse().map((member: any, index: number) => {
      const {ethereumAddress} = member.user;
      const shares = (member && member.shares) || undefined;

      const memberVotingWeight =
        shares && totalShares
          ? ((Number(shares) / Number(totalShares)) * 100).toFixed(2)
          : '';

      return (
        <div key={index}>
          <MembersCard
            member={member}
            memberVotingWeight={memberVotingWeight}
            memberVotingShares={shares}
            key={ethereumAddress}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.preventDefault();
              handleMemberDetails(ethereumAddress);
            }}
          />
        </div>
      );
    });
  }

  function handleWhyModalClose(close: boolean) {
    setShouldShowWhyModal(close);
  }

  function renderCloseButton() {
    switch (orgInternalName) {
      case InternalNamesToMap.nft:
      case InternalNamesToMap.liquidity:
        return (
          <span
            className="org-modal-close"
            onClick={() => handleWhyModalClose(false)}>
            <TimesSVG />
          </span>
        );
      case InternalNamesToMap.thelao:
      default:
        return (
          <div className={m['close']} style={{top: '.9rem'}}>
            <button
              className={`${b.underline}`}
              onClick={() => handleWhyModalClose(false)}>
              Close
            </button>
          </div>
        );
    }
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <Wrap className="section-wrapper">
        <FadeIn>
          <div className="titlebar">
            <h2 className="titlebar__title org-titlebar__title">Members</h2>
          </div>
          <div className="card org-card" style={{padding: '2rem'}}>
            <div className={t['tabbed-wrapper-header']}>
              <div className={`${t['tab-group']} org-tab-group`}>
                <ul>
                  <li>
                    <button
                      key={TabOptions.GENERAL}
                      className={`${
                        tabSelected === TabOptions.GENERAL
                          ? `${t['selected']} org-selected`
                          : ''
                      } ${t['tab']} org-tab`}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.preventDefault();
                        handleClick(TabOptions.GENERAL);
                      }}>
                      General Members{' '}
                      {approvedCount > 0 && <>({approvedCount})</>}
                    </button>
                  </li>
                  <li>
                    <button
                      key={TabOptions.SEEKING_APPROVAL}
                      className={`${
                        tabSelected === TabOptions.SEEKING_APPROVAL
                          ? `${t['selected']} org-selected`
                          : ''
                      } ${t['tab']} org-tab`}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.preventDefault();
                        handleClick(TabOptions.SEEKING_APPROVAL);
                      }}>
                      Processing{' '}
                      {seekingApprovalCount > 0 && (
                        <>
                          ({seekingApprovalCount}){' '}
                          <span
                            className={t['question-mark']}
                            onClick={(
                              event: React.MouseEvent<HTMLAnchorElement>
                            ) => {
                              event.preventDefault();
                              handleWhyModalClose(true);
                            }}>
                            <QuestionCircleSVG />
                          </span>
                        </>
                      )}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className={t['tabbed-wrapper-content']}>
              <section className={s['members-container']}>
                {approvedCount > 0 && tabSelected === TabOptions.GENERAL && (
                  <>{renderMemberCards(approved)}</>
                )}
                {seekingApprovalCount > 0 &&
                  tabSelected === TabOptions.SEEKING_APPROVAL && (
                    <>{renderMemberCards(seekingApproval)}</>
                  )}
              </section>
            </div>
          </div>
        </FadeIn>
      </Wrap>

      {/** WHY MODAL TO PROVIDE INFO TO SEEKING MEMBERS */}
      {shouldShowWhyModal && (
        <ReactModal
          ariaHideApp={false}
          className={`${m['modal-content-wide']}`}
          isOpen={shouldShowWhyModal}
          onRequestClose={() => {
            handleWhyModalClose(false);
          }}
          overlayClassName={`${m['modal-overlay']} org-modal-overlay`}
          role="dialog"
          style={
            {overlay: {zIndex: '99'}, content: {maxWidth: '32.5rem'}} as any
          }>
          <FadeIn>
            <div
              className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} org-modal`}>
              <div className={`${sm.sales} ${m['modal-title']} card`}>
                {renderCloseButton()}
                {/* CONTENT */}
                <div className="titlebar">
                  <h3 className="titlebar__title org-titlebar__title">
                    Why does my membership need approval?
                  </h3>
                </div>
                <p>
                  {orgName} relies on smart contracts for its operation. To
                  formally join, we have submitted a proposal to the smart
                  contract so that your address can be registered as a member.
                  Member processing will take several days, assuming that there
                  are no other pending proposals.
                </p>
              </div>
            </div>
          </FadeIn>
        </ReactModal>
      )}
    </>
  );
}
