import {useState} from 'react';

import {dontCloseWindowWarning, ethEstimateGas} from '../../util/helpers';
import {StoreState, MetaMaskRPCError} from '../../util/types';
import {useSelector} from 'react-redux';
import {ETHERSCAN_URLS} from '../../util/config';
import {useETHGasPrice} from '../../hooks';

type EasyApplyContributionRenderProps = {
  error?: Error;
  etherscanURL: string;
  isPromptOpen: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  openPrompt: () => void;
};

type EasyApplyContributionProps = {
  onBeforeProcess?: () => void;
  onError?: (e: Error) => void;
  onProcess?: (txHash: string) => void;
  onComplete?: () => void;
  render: (p: EasyApplyContributionRenderProps) => React.ReactElement;
  tributeAmountWEI: string;
};

/**
 * EasyApplyContribution
 *
 * Allows interaction with the EasyApply smart contract's `_submitProposalLAO`.
 * The contract allows us to merge 3 separate smart contracts into one:
 *
 * 1. Wrap ETH to WETH
 * 2. Approve WETH
 * 3. Moloch `submitProposal()`
 *
 * A render prop is used to allow for passing internal state
 * to parent/wrapping components.
 *
 * @see https://github.com/openlawteam/Lao-easy-apply
 *
 * @param {EasyApplyContributionProps} props
 * @returns Result of `render` prop.
 */
export default function EasyApplyContribution(
  props: EasyApplyContributionProps
) {
  const {
    onBeforeProcess,
    onError,
    onProcess,
    onComplete,
    render,
    tributeAmountWEI,
  } = props;

  /**
   * State
   */

  const [error, setError] = useState<Error>();
  const [etherscanURL, setEtherscanURL] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /**
   * Selectors
   */

  const easyApplyContractAddress = useSelector(
    (s: StoreState) => s.org && s.org.contractEasyApplyAddress
  );
  const web3Instance = useSelector(
    (state: StoreState) => state.blockchain.web3Instance
  );
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );

  /**
   * External Hooks
   */

  const {average: gasPrice} = useETHGasPrice();

  /**
   * Functions
   */

  function handleError(error: Error) {
    setError(error);
    setIsSubmitted(false);
    setIsSubmitting(false);

    onError && onError(error);
  }

  async function handleEasyApplyWalletPrompt() {
    if (!web3Instance || !easyApplyContractAddress) return;

    // Activate "don't close window" warning
    const unsubscribeDontCloseWindow = dontCloseWindowWarning();

    try {
      /**
       * Run user callback `onBeforeProcess`
       * @note We `await` just in case the value is a Promise.
       */
      onBeforeProcess && (await onBeforeProcess());

      setError(undefined);
      setEtherscanURL('');
      setIsPromptOpen(true);
      setIsSubmitted(false);
      setIsSubmitting(false);

      const txConfig = {
        from: connectedAddress,
        to: easyApplyContractAddress,
        value: tributeAmountWEI,
        ...(gasPrice ? {gasPrice} : null),
      };

      const gas = await ethEstimateGas(txConfig, web3Instance);

      /**
       * sendTransaction
       *
       * @see https://web3js.readthedocs.io/en/v1.2.4/web3-eth.html#sendtransaction
       */
      await web3Instance.eth
        .sendTransaction({
          ...txConfig,
          /**
           * @note Sometimes `gas` will return `undefined` from estimation.
           *   For example, we notice this with Gnosis safe (on both mainnet and rinkeby).
           *   Do not set it and let the wallet decide and present the user with its own options.
           */
          ...(gas ? {gas: `0x${gas.toString(16)}`} : null),
        })
        .on('transactionHash', function (txHash: string) {
          setIsPromptOpen(false);
          setIsSubmitting(true);
          setEtherscanURL(`${ETHERSCAN_URLS[chainId]}/tx/${txHash}`);

          // Maybe call user `onProcess` and handle any `Error`.
          try {
            onProcess && onProcess(txHash);
          } catch (error) {
            handleError(error);
          }
        })
        .on('error', (error: Error | MetaMaskRPCError) => {
          handleError(error);

          // if user closed modal (MetaMask error code 4001)
          // or via WalletConnect, which only provides a message and no code
          setIsPromptOpen(false);

          unsubscribeDontCloseWindow();
        });

      // Send `returnValues` in callback.
      onComplete && onComplete();

      // Tx `receipt` resolved; tx went through.
      setIsSubmitted(true);
      setIsSubmitting(false);

      unsubscribeDontCloseWindow();
    } catch (error) {
      setIsPromptOpen(false);

      handleError(error);

      unsubscribeDontCloseWindow();
    }
  }

  return render({
    error,
    etherscanURL,
    isPromptOpen,
    isSubmitted,
    isSubmitting,
    openPrompt: handleEasyApplyWalletPrompt,
  });
}
