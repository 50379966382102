import l from '../../assets/scss/modules/logo.module.scss';

interface LogoPunkProps {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoPunk(props: LogoPunkProps) {
  const classNameSizes = {
    punk: props.size ? `${l[`punk--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.punk} ${classNameSizes.punk} org-logo`}>Punk DAO</div>
  );
}
