import l from '../../assets/scss/modules/logo.module.scss';

interface LogoFashionProps {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoFashion(props: LogoFashionProps) {
  const classNameSizes = {
    reddao: props.size ? `${l[`reddao--${props.size}`]}` : '',
  };

  return <div className={`${l.reddao} ${classNameSizes.reddao}`}>Red</div>;
}
