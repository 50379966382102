import {
  ACCOUNT_FETCH_STATUS,
  CONFIRM_ACCOUNT,
  FETCH_ACCOUNT_ERROR,
  FETCH_ACCOUNT_SUCCESS,
  RESET_CURRENT_USER,
  SIGNUP_USER,
  USER_UPDATE,
} from '../actions';
import {UserState} from '../../util/types';
import {FetchStatus} from '../../util/enums';

const initialState = {
  accountConfirmed: undefined,
  accountType: undefined,
  confirmToken: null,
  emailAddress: undefined,
  ethereumAddress: undefined,
  fetchStatus: FetchStatus.STANDBY,
  id: undefined,
  isConfirmed: false,
  member: undefined,
  role: undefined,
  username: undefined,
};

export default function reducer(state: UserState = initialState, action: any) {
  const {type, ...payload} = action;

  switch (type) {
    case ACCOUNT_FETCH_STATUS:
      return currentAccountFetchStatus(state, payload);
    case CONFIRM_ACCOUNT:
      return confirmAccount(state, payload);
    case FETCH_ACCOUNT_SUCCESS:
      return currentAccount(state, payload);
    case FETCH_ACCOUNT_ERROR:
      return currentAccount(state, {user: initialState});
    case RESET_CURRENT_USER:
      return currentAccount(state, {user: initialState});
    case SIGNUP_USER:
      return signUp(state, payload);
    case USER_UPDATE:
      return currentAccount(state, payload);
    default:
      return state;
  }
}

function confirmAccount(state: UserState, {accountConfirmed}: any) {
  return {...state, accountConfirmed};
}

function currentAccount(state: UserState, {user}: any) {
  return {...state, ...user};
}

function currentAccountFetchStatus(
  state: UserState,
  {fetchStatus}: {fetchStatus: FetchStatus}
) {
  return {...state, fetchStatus};
}

function signUp(
  state: UserState,
  {accountType, emailAddress, ethereumAddress, id}: any
) {
  return {...state, accountType, emailAddress, ethereumAddress, id};
}
