import gql from 'graphql-tag';

/**
 * GET_MOLOCH
 *
 * Get moloch constants
 */
export const GET_MOLOCH = gql`
  query Moloch($id: ID) {
    moloches(where: {id: $id}) {
      id # contract address
      summoningTime
      periodDuration
      votingPeriodLength
      gracePeriodLength
      totalShares
    }
  }
`;
