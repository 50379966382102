import Web3 from 'web3';

import {FetchStatus} from '../../util/enums';
import {getAccessToken} from '../../util/helpers';

export const AUTH_SERVER_ACCESS_TOKEN = 'AUTH_SERVER_ACCESS_TOKEN';
export const AUTH_SERVER_HIDE_MODAL = 'AUTH_SERVER_HIDE_MODAL';
export const AUTH_SERVER_SHOW_MODAL = 'AUTH_SERVER_SHOW_MODAL';
export const AUTH_SERVER_STATUS = 'AUTH_SERVER_STATUS';

/**s
 * serverAuthenticate
 *
 * Requests an accessToken from the server, or retrieves from localStorage
 * if it already exists.
 *
 * Dispatches actions which set an `accessToken` (JSON Web Token), payload (via JWT),
 * and status for JSON Web Token.
 *
 * @param {string} ethereumAddress Ethereum address of user to use for server authentication.
 */
export function authServer(
  ethereumAddress: string,
  backendURL: string,
  web3Instance: Web3
) {
  return async function (dispatch: any) {
    try {
      dispatch(authServerStatus(FetchStatus.PENDING));

      const accessTokenStored = getAccessToken(ethereumAddress);

      // If an accessToken exists in localStorage then set it and exit early.
      if (accessTokenStored) {
        dispatch(authServerStatus(FetchStatus.FULFILLED));

        // Dispatch an action to change the `authServer.accessToken` state
        dispatch(authServerAccessToken(accessTokenStored));

        return;
      }

      // Get the org-specific message from the server to sign.
      const ethSignMessageResponse = await fetch(`${backendURL}/auth/message`);

      if (!ethSignMessageResponse.ok) {
        throw new Error(
          'Something went wrong while getting the message to sign.'
        );
      }

      const {message: ethSignMessage} =
        (await ethSignMessageResponse.json()) as Record<'message', string>;

      if (!ethSignMessage) throw Error('No signing message was found.');

      /**
       * Prompt in wallet for user to sign a message
       *
       * @note Using eth.personal.sign is a choice of stability.
       *
       *   Not all wallets accept signing methods.
       *   For example Gnosis is a smart contract wallet and does not accept signing methods.
       *   Additionally, not all wallets support the newer EIP712 eth_SignTypedData.
       *
       *   In the case of smart contract wallets, if they want to authenticate with our
       *   server they will need to also import their wallet to a key-based wallet (e.g. MetaMask, Rainbow),
       *   or update their `delegateKey`.
       *
       * @see https://web3js.readthedocs.io/en/v1.2.11/web3-eth-personal.html#sign
       */
      const signature = await web3Instance.eth.personal.sign(
        ethSignMessage,
        ethereumAddress,
        ''
      );

      // Request authentication
      const response = await fetch(`${backendURL}/auth`, {
        method: 'POST',
        body: JSON.stringify({ethereumAddress, signature}),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 401) {
        throw new Error('You were not authorized.');
      }

      if (!response.ok) {
        throw new Error('Something went wrong while getting the access token.');
      }

      dispatch(authServerStatus(FetchStatus.FULFILLED));

      const accessTokenJSON = await response.json();

      // Dispatch an action to change the `serverAuth.accessToken` state
      dispatch(authServerAccessToken(accessTokenJSON.accessToken));

      // Store authentication accessToken in localStorage
      localStorage.setItem(
        'me',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('me') || 'null'),
          [ethereumAddress.toLowerCase()]: accessTokenJSON.accessToken,
        })
      );
    } catch (error) {
      // Dispatch an action to change the `serverAuth.accessToken` state
      dispatch(authServerAccessToken(''));

      // Remove the accessToken from localStorage
      localStorage.setItem(
        'me',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('me') || 'null'),
          [ethereumAddress.toLowerCase()]: '',
        })
      );

      dispatch(authServerStatus(FetchStatus.REJECTED));

      throw error;
    }
  };
}

export function authServerAccessToken(accessToken: string) {
  return {
    type: AUTH_SERVER_ACCESS_TOKEN,
    accessToken,
  };
}

export function authServerStatus(status: FetchStatus) {
  return {
    type: AUTH_SERVER_STATUS,
    status,
  };
}

export function authServerShowModal(showAuthModal: boolean) {
  return {
    type: showAuthModal ? AUTH_SERVER_SHOW_MODAL : AUTH_SERVER_HIDE_MODAL,
    showAuthModal,
  };
}
