import Web3 from 'web3';
import React from 'react';
import {roddeh_i18n as I18nInstance} from 'roddeh-i18n';

import {
  devSubdomainOrgNameMap,
  hostnameOrgNameMap,
  InternalNamesToMap,
} from './orgDomainMappings';
import {ENVIRONMENT} from './config';
import {ProposalFlagsMap, OrgState, ContractSendType} from './types';
import {Web3TxStatus} from './enums';

/**
 * @see https://en.wikipedia.org/wiki/ISO_3166-2:US#Subdivisions_included_in_ISO_3166-1
 */
export const US_AND_TERRITORIES_COUNTRY_CODES = [
  'US',
  'AS',
  'GU',
  'MP',
  'PR',
  'UM',
  'VI',
];

export const formatEthereumAddress = (addr: string, maxLength: number = 5) => {
  if (addr === null) return '---';

  if (typeof addr !== 'undefined' && addr.length > 9) {
    const firstSegment = addr.substring(0, maxLength);
    const secondPart = addr.substring(addr.length - 3);
    return firstSegment + '...' + secondPart;
  } else {
    return '---';
  }
};

export const attemptParseJSON = (possibleJSONString: string) => {
  try {
    return JSON.parse(possibleJSONString);
  } catch (error) {
    return null;
  }
};

/**
 * chooseRandom
 *
 * Choose a random item from an array.
 *
 * @param {array} array - The array to choose from.
 * @param doNotChooseItem - An item to not choose (e.g. previously chosen item)
 */
export function chooseRandom<T>(array: T[], doNotChooseItem?: T) {
  const arrayToUse =
    doNotChooseItem !== undefined
      ? array.filter((a) => a !== doNotChooseItem)
      : array;

  return arrayToUse[Math.floor(Math.random() * arrayToUse.length)];
}

export const cleanDate = (date: string, options?: Intl.DateTimeFormatOptions) =>
  new Date(date).toLocaleDateString(
    'en',
    options || {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }
  );

/**
 * stripTrailingZeroes
 *
 * Strips trailing zeros after a decimal if there
 * is 1 or more at the end.
 *
 * Example:
 *
 * 1.10000 => 1.1
 * 1.100100 => 1.1001
 * 1.000 => 1
 * 1 >= 1
 *
 * @param {string | number} data
 * @returns {string}
 * @see https://stackoverflow.com/a/58896161
 */
export function stripTrailingZeroes(data: string | number) {
  return data
    .toString()
    .replace(/^([\d,]+)$|^([\d,]+)\.0*$|^([\d,]+\.[0-9]*?)0*$/, '$1$2$3');
}

/**
 * formatDecimal
 *
 * A simple formatter with respect for dynamic decimal places
 * from `toFixed(2)` to `toFixed(4)`. If the number provided
 * is less than `0.01`, then `toFixed(4)` is used, else `toFixed(2)`.
 *
 * This may not work for all cases where the number is tiny, as it could
 * result in `"0.0000"`.
 *
 * @param {number} n
 * @returns {string} A `.toFixed()` representation of the decimal number.
 */
export function formatDecimal(n: number): string {
  return n < 0.01 ? n.toFixed(4) : n.toFixed(2);
}

/**
 * getQueryStringParam
 *
 * Gets the value of a query string search param.
 *
 * @param {string} queryParam - String to search for.
 * @param {string} search - Optionally provide the search string (e.g. via useLocation).
 *   Can be especially helpful for Node test environments.
 */
export function getQueryStringParam(
  queryParam: string,
  search?: string
): string | null {
  const urlParams = new URLSearchParams(search || window.location.search);
  return urlParams.get(queryParam);
}

/**
 * buildQueryString
 *
 * Takes an object and builds a query string out of the key:value pairs.
 *
 * @param {Record<string, any>} data
 * @returns {string}
 */
export function buildQueryString(data?: Record<string, any>): string {
  if (!data) return '';

  const entries = Object.entries(data);

  if (!entries.length) return '';

  const queryString = entries
    .filter(([_, value]) => value !== null && value !== undefined)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return queryString ? `?${queryString}` : '';
}

/**
 * getNextArrayItem
 *
 * Selects the next array item. Once it reaches the end of the array
 * the first item will be returned. If the `currentItem` is falsy
 * the first item is returned.
 *
 * @param {T} currentItem
 * @param {Array<T>} items
 * @returns {T}
 */
export function getNextArrayItem<T>(
  currentItem: T | undefined,
  items: Array<T>
) {
  const currentIndex = items.indexOf(currentItem || items[0]);
  const nextIndex = (currentIndex + 1) % items.length;

  return items[nextIndex];
}

/**
 * getOrgText
 *
 * @note The i18n module does not have accessible types for TS.
 *
 * @param {any} i18nInstance - The instance created by `i18n.create`
 * @returns {(k: string) => string} Returns a helper function which gets the i18n text.
 */
export function getOrgText(i18nInstance: typeof I18nInstance | null) {
  return (...args: Parameters<typeof I18nInstance>) => {
    return i18nInstance ? i18nInstance(...args) : '';
  };
}

/**
 * isTributeOrgName
 *
 * @note Returns `true` if the internal org name matches any names defined in the
 * `tributeOrgNames` array.
 *
 * @param internalName
 * @returns
 */
export function isTributeOrgName(internalName: InternalNamesToMap) {
  // @note Add the tribute org name to the array
  const tributeOrgNames = [
    InternalNamesToMap.ai,
    InternalNamesToMap.blue,
    InternalNamesToMap.bright,
    InternalNamesToMap.cc0,
    InternalNamesToMap.darkhorse,
    InternalNamesToMap.doge,
    InternalNamesToMap.fashion,
    InternalNamesToMap.gaming,
    InternalNamesToMap.glimmer,
    InternalNamesToMap.krpdm,
    InternalNamesToMap.long,
    InternalNamesToMap.metaverse,
    InternalNamesToMap.music,
    InternalNamesToMap.nftv2,
    InternalNamesToMap.punk,
    InternalNamesToMap.science,
    InternalNamesToMap.si,
    InternalNamesToMap.spaceship,
    InternalNamesToMap.test,
    InternalNamesToMap.testv2,
    InternalNamesToMap.thelaov2,
    InternalNamesToMap.unicorn,
    InternalNamesToMap.university,
    InternalNamesToMap.urbit,
    InternalNamesToMap.zk,
    InternalNamesToMap['university-bc'],
    InternalNamesToMap['university-berkeley'],
    InternalNamesToMap['university-cambridge'],
    InternalNamesToMap['university-columbia'],
    InternalNamesToMap['university-cornell'],
    InternalNamesToMap['university-franklin'],
    InternalNamesToMap['university-illinois'],
    InternalNamesToMap['university-imperial'],
    InternalNamesToMap['university-michigan'],
    InternalNamesToMap['university-new-york'],
    InternalNamesToMap['university-oregon'],
    InternalNamesToMap['university-purdue'],
    InternalNamesToMap['university-texas'],
    InternalNamesToMap['university-vanderbilt'],
    InternalNamesToMap['university-virginia'],
    InternalNamesToMap['university-vox'],
    InternalNamesToMap['university-waterloo'],
  ];

  return tributeOrgNames.some((o) => o === internalName);
}

/**
 * isOnboardingWithUSDCOrgName
 *
 * @note Returns `true` if the internal org name matches any names defined in the
 * `onboardingWithUSDCOrgName` array.
 *
 * @export
 * @param {InternalNamesToMap} internalName
 * @returns
 */
export function isOnboardingWithUSDCOrgName(internalName: InternalNamesToMap) {
  // @note Add the org name to the array if its members onboard with USDC
  // contributions
  const onboardingWithUSDCOrgName = [InternalNamesToMap.si];

  return onboardingWithUSDCOrgName.some((o) => o === internalName);
}

/**
 * getOrgInternalNameFromHostnameMap
 *
 * Checks our internal hostname mapping and returns an internal name,
 * or an empty string.
 *
 * @param {string} hostname From `window.location.hostname`, e.g. www.cooldao.io
 * @returns {string}
 */
export function getOrgInternalNameFromHostnameMap(hostname: string): string {
  // Check if there's a mapped hostname->org.internalName.
  const mappedHostname = hostnameOrgNameMap[hostname];

  return mappedHostname || '';
}

/**
 * getOrgInternalNameFromSubdomainMap
 *
 * First checks the development subdomain mapping
 * then returns the subdomain as-as, if no matches.
 *
 * @param {string} subdomain
 * @returns {string}
 */
export function getOrgInternalNameFromSubdomainMap(subdomain: string): string {
  // If development, check if there's a mapped subdomain->org.internalName.
  const devMappedSubdomain =
    ENVIRONMENT !== 'production' ? devSubdomainOrgNameMap[subdomain] : '';

  const orgInternalName = devMappedSubdomain || subdomain;

  return orgInternalName;
}

/**
 * getOrgSubdomainFromDomain
 *
 * Returns the org subdomain from the `domain` name provided.
 * For example if the browser is at coolorg.thelao.io, and given `thelao.io` domain,
 * it will return `coolorg`.
 *
 * If the `window.location.hostname` does not match the domain argument
 * the return value is `null`.
 *
 * If the subdomain is `www.` (e.g. Netlify default redirect to "www")
 * the return value is `null`.
 *
 * @param domain
 * @returns {string} e.g. "www."
 */
export function getOrgSubdomainFromDomain(domain: string): string | null {
  const hostname = window.location.hostname;

  // If the hostname does not contain our domain, return `null`.
  if (!hostname.endsWith(domain)) return null;

  // e.g. "coolorgname."
  const subdomain = hostname.substring(0, hostname.lastIndexOf(domain));

  // If the subdomain is `www.` then return `null` (e.g. Netlify redirect to www.).
  if (subdomain === 'www.') return null;

  // Strip the last period separator
  return subdomain.replace(/\.$/, '');
}

export function loadOrgCss(internalName: string) {
  const cssId = `${internalName}_css`;

  if (!document.getElementById(cssId)) {
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');

    link.id = cssId;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `/orgs/${internalName.toLowerCase()}/style.css`;
    link.media = 'all';

    head.appendChild(link);
  }
}

/**
 * getVotingPeriodFormatted
 *
 * Outputs the Moloch voting period length as a readable period duration string.
 * The longest period is "day", the shortest is "minute".
 *
 * @param data
 * @returns {string} Returns a string which is formatted "x days/hours/mintues"
 */
export function getVotingPeriodFormatted(data: {
  periodDuration: number;
  votingPeriodLength: number;
  pluralize: boolean;
}): {
  length: number;
  lengthSeconds: number;
  periodName: 'Day' | 'Hour' | 'Minute';
  formatted: string;
} {
  // Calculate voting length seconds
  const votingLengthSeconds = data.votingPeriodLength * data.periodDuration;
  // Create a map for different voting lengths
  const votingLengthsMap = [
    // Day
    {
      periodName: 'Day' as 'Day',
      length: Math.floor(votingLengthSeconds / (3600 * 24)),
    },
    // Hours
    {
      periodName: 'Hour' as 'Hour',
      length: Math.floor(votingLengthSeconds / 3600),
    },
    // Minutes
    {
      periodName: 'Minute' as 'Minute',
      length: Math.floor(votingLengthSeconds / 60),
    },
  ];
  // Get the first voting length that is not 0
  const {length, periodName} = votingLengthsMap.filter((v) => v.length > 0)[0];
  // Format voting length string
  const formatted = `${length} ${
    (length === 0 || length > 1) && data.pluralize
      ? `${periodName}s`
      : periodName
  }`.toLowerCase();

  return {length, periodName, formatted, lengthSeconds: votingLengthSeconds};
}

/**
 * normalizeString
 *
 * Converts string case to lowerCase and trims whitespace.
 *
 * @param {string} stringToNormalize
 * @returns {string} The normalized string lowercased and trimmed.
 */
export function normalizeString(stringToNormalize: string): string {
  return !stringToNormalize ? '' : stringToNormalize.toLowerCase().trim();
}

/**
 * Derive the org's internalName from the subdomain (e.g. cooldao.thelao.io),
 * querystring, or use the default.
 */
export const orgNameFromURL =
  getOrgSubdomainFromDomain('thelao.io') ||
  /**
   * Mainly for development
   *
   * @todo Possibly only use this if `ENVIRONMENT` is not production.
   */
  getQueryStringParam('org') ||
  // default
  'thelao';

// Checks client-side mappings for any entries, and returns `orgNameFromURL` if not.
export const orgNameFromSubdomainMap =
  getOrgInternalNameFromSubdomainMap(orgNameFromURL);

/**
 * Checks our client-side mapping for any entries
 * for custom hostnames (e.g. www.cooldao.io).
 */
export const orgNameFromHostnameMap = getOrgInternalNameFromHostnameMap(
  window.location.hostname
);

/**
 * getAccessToken
 *
 * Gets a JWT from the `me` localStorage key.
 *
 * @param {string} ethereumAddress
 * @returns {string} Access token string, or empty.
 */

export function getAccessToken(ethereumAddress: string) {
  const accessTokenMapParsed: Record<string, string> | null = JSON.parse(
    localStorage.getItem('me') || 'null'
  );
  const accessTokenStored =
    accessTokenMapParsed && accessTokenMapParsed[ethereumAddress];

  return accessTokenStored || '';
}

/**
 * getAuthHeader
 *
 * Provides an `Authorization` header for protected requests to the API.
 *
 * @param {string} accessToken JWT Access token
 * @returns {Record<'Authorization', string>} `Authorization` header object with `Bearer: ${accessToken}`.
 */
export function getAuthHeader(accessToken: string) {
  return {
    Authorization: `Bearer ${accessToken}`,
  };
}

/**
 * getLeadingZeroNumberString
 *
 * @param {number} number
 * @return {string} Number string with leading zero, if less than 10.
 */
export const getLeadingZeroNumberString = (number: number) => {
  return number < 10 ? `0${number}` : number.toString();
};

export const getTimeRemaining = (endtime: Date) => {
  const t = Date.parse(endtime.toString()) - Date.parse(new Date().toString());
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  const days = Math.floor(t / (1000 * 60 * 60 * 24));

  return {
    total: t,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

/**
 * getValidationError
 *
 * Used with react-hook-form (mostly to solve a TS incorrect behavior)
 * Gets the associated error message with a field.
 *
 * @param {string} field
 * @param {Record<string, any>} errors
 * @returns string
 */
export function getValidationError(
  field: string,
  errors: Record<string, any>
): string {
  return errors[field] && 'message' in errors[field]
    ? (errors[field].message as string)
    : '';
}

// see: https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
export const getByObjectPath = (
  obj: Record<string, any>,
  path: string,
  defaultValue: any
) => {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

/**
 * getOpenLawAddressFromFormatted
 *
 * We may already have a formatted address via OpenLaw, but we'll still
 * need to structure the address data into something OpenLaw accepts.
 *
 * @param {string} formattedAddress
 */
export function getOpenLawAddressFromFormatted(formattedAddress: string) {
  return `{"placeId":"","streetName":"","streetNumber":"","city":"","state":"","country":"","zipCode":"","formattedAddress":"${formattedAddress}"}`;
}

/**
 * formatNumber
 *
 * Formats a number (U.S. region) with commas (e.g. 1000 -> 1,000).
 *
 * @param {string | number} value
 * @returns {string}
 *
 * @todo maybe a more friendly way via Intl API in JS core?
 */
export const formatNumber = (value: number | string) => {
  const regEx = new RegExp(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g);
  return typeof value === 'number'
    ? value.toString().replace(/,/g, '').replace(regEx, '$1,')
    : value.replace(/,/g, '').replace(regEx, '$1,');
};

/**
 * stripFormatNumber
 *
 * Strips a number string formatting (via `formatNumber`) (e.g. 10,000 -> 10000).
 *
 * @param {string} value Number string to strip formatting (via `formatNumber`) from.
 * @returns {string} A Number string without any formatting form `formatNumber`.
 *
 * @todo maybe a more friendly way via Intl API in JS core?
 */
export const stripFormatNumber = (value: string) =>
  value.toString().replace(/,/g, '');

/**
 * numberRangeArray
 *
 * Outputs an array sequence of numbers given a total size and starting offset.
 * e.g. Years 1920..2002
 *
 * @param {size} number - Maximum number and last value of array. Default is 1.
 * @param {startAt} number - Offset to begin from. Default is 0.
 * @returns {number[]}
 */
export function numberRangeArray(
  size: number = 1,
  startAt: number = 0
): number[] {
  const adjustedSize = size + 1 - startAt;
  return [...Array(adjustedSize)].map((_, i) => startAt + i);
}

/**
 * parseProposalFlags
 *
 * Parses Moloch `proposalId` flags.
 *
 * @param {boolean[]} flags [sponsored, processed, didPass, cancelled, whitelist, guildkick]
 */
export function parseProposalFlags(flags: boolean[]): ProposalFlagsMap {
  return flags.reduce((acc, next, index) => {
    switch (index) {
      case 0:
        acc.sponsored = next;
        break;
      case 1:
        acc.processed = next;
        break;
      case 2:
        acc.didPass = next;
        break;
      case 3:
        acc.cancelled = next;
        break;
      case 4:
        acc.whitelist = next;
        break;
      case 5:
        acc.guildkick = next;
        break;
      default:
        throw new Error(
          'parseProposalFlags: Proposal flag index does not exist.'
        );
    }
    return acc;
  }, {} as ProposalFlagsMap);
}

/**
 * ethPersonalSign
 *
 * Signs a message using web3.eth.personal.sign.
 *
 * @see https://web3js.readthedocs.io/en/v1.2.11/web3-eth-personal.html#sign
 *
 * @param {[string, string, string]} signArgs
 * @param {Web3} web3Instance
 * @returns {Promise<string>} Signature
 */
export async function ethPersonalSign(
  signArgs: [string, string, string],
  web3Instance: Web3
): Promise<string> {
  try {
    /**
     * Prompt in wallet for user to sign a message
     *
     * @note Not all wallets accept signing methods.
     *   For example Gnosis is a smart contract wallet and does not accept signing methods.
     *   Additionally, not all wallets support the newer EIP712 eth_SignTypedData.
     *
     *   In the case of smart contract wallets, if they want to sign data
     *   they will need to also import their wallet to a key-based wallet (e.g. MetaMask, Rainbow),
     *   or update their `delegateKey`.
     *
     */
    const signature = await web3Instance.eth.personal.sign(...signArgs);

    return signature;
  } catch (error) {
    throw error;
  }
}

/**
 * ethEstimateGas
 *
 * Returns the estimated tx gas limit using web3.eth.estimateGas
 *
 * @see https://web3js.readthedocs.io/en/v1.2.11/web3-eth.html?highlight=estimateGas#estimategas
 *
 * @param {Record<string, any>} txConfig
 * @param {Web3} web3Instance
 * @returns {Promise<string>} Estimated Gas Limit
 */
export async function ethEstimateGas(
  txConfig: Record<string, any>,
  web3Instance: Web3
): Promise<number> {
  try {
    const estimateGas = await web3Instance.eth.estimateGas({...txConfig});

    return estimateGas;
  } catch (error) {
    throw error;
  }
}

/**
 * contractSend
 *
 * Returns the resolved transaction receipt or error
 *
 * @param {any} contractInstance
 * @param {any} methodArguments
 * @param {string} methodName
 * @param {Record<string, any>} txArguments
 * @param {(txHash: string) => void} callback
 * @returns {Promise<ContractSendType>} Resolved transaction receipt or error
 */
export async function contractSend(
  methodName: string,
  contractInstance: any,
  methodArguments: any, // args passed as an array
  txArguments: Record<string, any>,
  callback: (txHash: string) => void // callback; return txHash
) {
  return new Promise<ContractSendType>((resolve, reject) => {
    // estimate gas limit for transaction
    contractInstance[methodName](...methodArguments)
      .estimateGas({from: txArguments.from})
      .then((gas: number) => {
        contractInstance[methodName](...methodArguments)
          .send({
            ...txArguments,
            gas,
          })
          .on('transactionHash', function (txHash: string) {
            // return transaction hash
            callback(txHash);
          })
          .on('receipt', function (receipt: Record<string, any>) {
            // return transaction receipt; contains event returnValues
            resolve({
              receipt,
              txStatus: Web3TxStatus.FULFILLED,
            } as ContractSendType);
          })
          .on('error', (error: Error) => {
            // return transaction error
            reject({
              error,
              txStatus: Web3TxStatus.REJECTED,
            } as ContractSendType);
          });
      })
      .catch((error: Error) => {
        // return estimateGas error
        reject({
          error,
          txStatus: Web3TxStatus.REJECTED,
        } as ContractSendType);
      });
  });
}

interface TrimTextOptions {
  characters?: number;
  withEllipsis?: boolean;
  word?: boolean;
}

/**
 * trimText
 *
 * @param {string} text - Any text to trim.
 * @param {TrimTextOptions} options - An object of options to provide; defaults to `characters: 300, withEllipsis: true`.
 * @return {string} Returns the trimmed text, or original text if the trimming didn't work.
 */
export function trimText(text: string, options?: TrimTextOptions): string {
  const defaultOptions = {
    characters: 300,
    withEllipsis: true,
    word: true,
  };
  const mergedOptions = options
    ? {...defaultOptions, ...options}
    : defaultOptions;
  const isTextLessOrEqualToCount = text.length <= mergedOptions.characters;
  const maybeEllipsis = `${
    mergedOptions.withEllipsis && !isTextLessOrEqualToCount ? '\u2026' : ''
  }`;
  const trimToRegex = new RegExp(`^(.?){1,${mergedOptions.characters}}`);
  const textBreaksRemoved = text.replace(/[\t\n\r]/g, '');

  if (isTextLessOrEqualToCount) return textBreaksRemoved;

  const [trimmedToCharacterCount = ''] =
    textBreaksRemoved.match(trimToRegex) || [];
  const [maybeTrimmedToLastWord = ''] =
    (mergedOptions.word && trimmedToCharacterCount.match(/^(.+\s)/)) || [];

  return maybeTrimmedToLastWord
    ? `${maybeTrimmedToLastWord.trim()}${maybeEllipsis}`
    : mergedOptions.word === false && trimmedToCharacterCount
    ? `${trimmedToCharacterCount}${maybeEllipsis}`
    : text;
}

/**
 * disableReactDevTools
 *
 * Run before the app mounts to disable React dev tools.
 * Ideally, this is run conditionally based on environment.
 *
 * @see: https://github.com/facebook/react-devtools/issues/191#issuecomment-443607190
 */
export function disableReactDevTools() {
  const noop = (): void => undefined;
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === 'object') {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
    }
  }
}

/**
 * dontCloseWindowWarning
 *
 * Warns user not to close the window.
 *
 * @returns {() => void} unsubscribe function to stop listening, and the callback from firing.
 */
export function dontCloseWindowWarning() {
  // @see: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#Example
  const callback = (event: BeforeUnloadEvent) => {
    // Cancel the event
    event.preventDefault();
    // Chrome requires returnValue to be set
    event.returnValue = '';
  };

  window.addEventListener('beforeunload', callback);

  return function unsubscribe() {
    window.removeEventListener('beforeunload', callback);
  };
}

/** siteMetadata
 * Returns the meta header, containing title, and meta tags
 * for page head element in react-helmet
 */
export function siteMetadata(pathname: string, org: OrgState) {
  if (!org)
    return {
      title: '',
      description: '',
    };

  const getText = getOrgText(org.text);
  const orgMentorsText = getText('OrgMentorsText');
  const orgInvestmentsText = getText('OrgInvestmentsText');
  const orgProjectsText = getText('OrgProjectsText');

  const memberRegex = /^\/members\/\w+\/?$/;
  if (memberRegex.test(pathname)) {
    return {
      title: `${org.name} - Member`,
      description: `Member of ${org.name}`,
    };
  }

  switch (pathname) {
    case '/proposals':
      return {
        title: `${org.name} - ${orgProjectsText}`,
        description: `A list of ${orgProjectsText.toLowerCase()} for ${
          org.name
        }`,
      };
    case `/${orgInvestmentsText.toLowerCase()}`:
      return {
        title: `${org.name} - ${orgInvestmentsText}`,
        description: `A list of investments and voting history for ${org.name}`,
      };
    case '/governance-proposals':
      return {
        title: `${org.name} - Governance`,
        description: `A list of governance proposals for ${org.name}`,
      };
    case '/governance-proposal':
      return {
        title: `${org.name} - New governance proposal`,
        description: `Submit governance proposal for ${org.name}`,
      };
    case '/members':
      return {
        title: `${org.name} - Members`,
        description: `Members of ${org.name}`,
      };
    case '/verify':
    case '/verify/non-us':
    case '/verify-thanks':
      return {
        title: `${org.name} - Verification`,
        description: `Apply to ${org.name}`,
      };
    case `/${orgMentorsText.toLowerCase()}`:
      return {
        title: `${org.name} - ${orgMentorsText}`,
        description: `${orgMentorsText} helping ${orgProjectsText.toLowerCase()} of ${
          org.name
        }`,
      };
    case '/apply':
      return {
        title: `${org.name} - Apply for funding`,
        description: `Request funding from ${org.name}`,
      };
    case '/contribute':
      return {
        title: `${org.name} - Contribution`,
        description: `Become a member of ${org.name}`,
      };
    case '/privacy':
      return {
        title: `${org.name} - Privacy Policy`,
        description: `Privacy Policy of ${org.name}`,
      };
    case '/admin':
      return {
        title: `${org.name} - Admin`,
        description: `Admin features of ${org.name}`,
      };
    case '/withdraw':
      return {
        title: `${org.name} - Withdraw`,
        description: `Withdraw available balances from ${org.name}`,
      };
    default:
      return {
        title: org.name,
        description: org.metaDesc,
      };
  }
}

/** orgFaviconSet
 * Returns the hrefs by org for link and meta tags for page head element in
 * react-helmet
 */
export function orgFaviconSet(org: OrgState) {
  if (!org) {
    return {
      appleTouchIconLink: '',
      icon32x32Link: '',
      icon16x16Link: '',
      siteWebManifest: '',
      safariPinnedTab: '',
    };
  }

  const {internalName} = org;

  return {
    appleTouchIconLink: `/orgs/${internalName.toLowerCase()}/favicon/apple-touch-icon.png`,
    icon32x32Link: `/orgs/${internalName.toLowerCase()}/favicon/favicon-32x32.png`,
    icon16x16Link: `/orgs/${internalName.toLowerCase()}/favicon/favicon-16x16.png`,
    siteWebManifest: `/orgs/${internalName.toLowerCase()}/favicon/site.webmanifest`,
    safariPinnedTab: `/orgs/${internalName.toLowerCase()}/favicon/safari-pinned-tab.svg`,
  };
}

/**
 * getlocalStorage
 *
 * Gets the `LAO` localStorage key.
 *
 * @returns {string} Access token string, or empty.
 */
export function getlocalStorage() {
  const laoStorageParsed: Record<string, string> | null = JSON.parse(
    localStorage.getItem('LAO') || 'null'
  );
  return laoStorageParsed || '';
}

/**
 * setlocalStorage
 *
 * Set the `LAO` localStorage key.
 *
 * @params {string} Name of the key.
 * @params {string} Value to store in the key
 */
export function setlocalStorage(name: string, value: string) {
  const LAO: Record<string, string> | null =
    localStorage.getItem('LAO') &&
    JSON.parse(localStorage.getItem('LAO') || '');

  localStorage.setItem(
    'LAO',
    JSON.stringify({
      ...LAO,
      [name]: value,
    })
  );
}

export function displaySimpleCountdown(
  countdown: Date,
  showDaysOnly?: boolean
) {
  const {days, hours, minutes, seconds} = getTimeRemaining(countdown);

  if (days > 2 && showDaysOnly) {
    return `~${days} days`;
  } else if (days > 0) {
    return `${formatTimePeriod(days, 'day')} : ${formatTimePeriod(
      hours,
      'hr'
    )} : ${formatTimePeriod(minutes, 'min')}`;
  } else if (hours > 0) {
    return `${formatTimePeriod(hours, 'hr')} : ${formatTimePeriod(
      minutes,
      'min'
    )}`;
  } else if (minutes > 0) {
    return `${formatTimePeriod(minutes, 'min')} : ${formatTimePeriod(
      seconds,
      'sec'
    )}`;
  } else {
    return React.createElement(
      'span',
      {className: 'org-last-seconds'},
      `${formatTimePeriod(seconds, 'sec')}`
    );
  }
}

export function formatTimePeriod(time: number, period: string) {
  const formattedPeriod = time === 0 || time > 1 ? `${period}s` : period;

  return `${time} ${formattedPeriod}`;
}
