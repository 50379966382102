import React, {useState} from 'react';
import ReactModal from 'react-modal';
import {useSelector, useDispatch} from 'react-redux';

import {formatEthereumAddress, formatNumber} from '../../util/helpers';
import {getConnectedMemberFromSmartContract} from '../../store/actions';
import {Member, StoreState} from '../../util/types';
import {InternalNamesToMap} from '../../util/orgDomainMappings';
import {useApprovedTokens, useMolochTokenBalances} from '../../hooks';
import {useCanRagequit} from './hooks';
import FadeIn from '../../components/common/FadeIn';
import Delegation from './Delegation';
import MemberBalancesManager from './MemberBalancesManager';
import RagequitFlow, {RagequitFlowStep} from './RagequitFlow';
import useERC20Balances from '../../hooks/useERC20Balances';
import TimesSVG from '../../assets/svg/TimesSVG';

import b from '../../assets/scss/modules/buttons.module.scss';
import mo from '../../assets/scss/modules/modal.module.scss';
import c from '../../assets/scss/modules/contract.module.scss';
import m from '../../assets/scss/modules/memberinfo.module.scss';
import sm from '../../assets/scss/modules/sale.module.scss';

type MemberInfoProps = {
  canEdit: boolean;
  canView: boolean;
  // allows for a static, non-async value (e.g. from router param)
  ethereumAddress: string;
  memberDetails?: Member;
  onIdentityUpdateClicked: () => any;
  shares: number | undefined;
  votingWeight: string;
};

type WhyInfoModalProps = {
  children: React.ReactChild;
  onClose: () => void;
  shouldOpen: boolean;
  title: string;
};

const PLACEHOLDER = '\u00A0\u2013'; /* nbsp ndash */

export default function MemberInfo(props: MemberInfoProps) {
  /**
   * Use state
   */

  const [showBalancesManager, setShowBalancesManager] =
    useState<boolean>(false);
  const [showRagequitFlow, setShowRagequitFlow] = useState<boolean>(false);
  const [showWhyNoRagequitModal, setShowWhyNoRagequitModal] =
    useState<boolean>(false);

  /**
   * Use selectors
   */

  const dispactch = useDispatch();
  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );

  /**
   * Use custom hooks
   */

  const {amountSharesLootOK, canRagequit, highestIndexYesVoteProcessed} =
    useCanRagequit();
  const {approvedTokens} = useApprovedTokens();
  const {erc20Balances, refetchERC20Balances} = useERC20Balances(
    connectedAddress,
    approvedTokens
  );
  const {molochTokenBalances, refetchTokenBalances} = useMolochTokenBalances(
    connectedAddress,
    approvedTokens
  );

  /**
   * Variables
   */

  const {
    canEdit,
    ethereumAddress,
    shares,
    memberDetails,
    onIdentityUpdateClicked,
    votingWeight,
  } = props;

  const {
    user: {
      emailAddress,
      // username
    },
  } = memberDetails || {user: {}};

  // const memberIsActive =
  //   member &&
  //   member.exists &&
  //   (Number(member.shares) || Number(member.loot)) > 0;

  // const memberHasQuit =
  //   member && member.exists && !Number(member.shares) && !Number(member.loot);

  // const memberStatus = memberIsActive // has shares or loot
  //   ? 'Active'
  //   : memberHasQuit // does not have shares or loot
  //   ? 'Quit'
  //   : memberDetails && memberDetails.status === MemberStatuses.PENDING
  //   ? 'Processing'
  //   : '';

  const filteredTokenBalances = molochTokenBalances.filter((b) =>
    Number(b.balance)
  );
  const filteredERC20Balances = erc20Balances.filter((b) => Number(b.balance));
  const hasBalances =
    filteredTokenBalances.length > 0 || filteredERC20Balances.length > 0;

  /**
   * Functions
   */

  function handleHideBalancesManager() {
    setShowBalancesManager(false);
  }

  function handleShowBalancesManager() {
    setShowBalancesManager(true);
  }

  function handleHideRagequitFlow() {
    setShowRagequitFlow(false);
  }

  function handleHideWhyNoRagequitModal() {
    setShowWhyNoRagequitModal(false);
  }

  function handleOpenWhyNoRagequitModal() {
    setShowWhyNoRagequitModal(true);
  }

  /**
   * Updates the `connectedMember` and user token balances, and closes modal on exit.
   * We update any time a user completes a RagequitFlow step.
   */
  function handleRagequitFlowStepSuccess(step: RagequitFlowStep) {
    if (step === 'ragequit' || step === 'withdraw') {
      // Refetch the `conncetedMember` data.
      dispactch(getConnectedMemberFromSmartContract());
    }

    // Update any balances that may have changed.
    updateBalances();
  }

  function handleShowRagequitFlow() {
    setShowRagequitFlow(true);
  }

  function updateBalances() {
    refetchERC20Balances();
    refetchTokenBalances();
  }

  return (
    <div className={m.wrap}>
      <div
        className={`${m['member-details-container']} org-member-details-container`}>
        <div
          className={`${m['member-section-title']} org-member-section-title`}>
          Member Details
        </div>

        <div className={`${c['contract-info-item']} org-contract-info-item`}>
          <span>Member</span>
          <span>
            {ethereumAddress
              ? formatEthereumAddress(ethereumAddress, 10)
              : PLACEHOLDER}
          </span>
        </div>

        {votingWeight && (
          <div className={`${c['contract-info-item']} org-contract-info-item`}>
            <span>Voting Weight</span>
            <span>{`${votingWeight}%`}</span>
          </div>
        )}

        {shares !== undefined && (
          <div className={`${c['contract-info-item']} org-contract-info-item`}>
            <span>Units</span>
            <span>{formatNumber(shares)}</span>
          </div>
        )}

        {/* AUTHORIZED INFO */}
        {/* MEMBER HAS QUIT */}
        {/* {memberStatus && (
          <div className={`${c['contract-info-item']} org-contract-info-item`}>
            <span>Status</span>
            <span>{memberStatus}</span>
          </div>
        )} */}

        {canEdit && memberDetails && (
          <div className={`${c['contract-info-item']} org-contract-info-item`}>
            <span>
              Contact <small>(viewable by you)</small>
            </span>
            <span>{emailAddress}</span>
          </div>
        )}

        {/* <div className={`${c['contract-info-item']} org-contract-info-item`}>
          <span>Username</span>
          <span>{username || PLACEHOLDER}</span>
        </div> */}

        {/* AUTHORIZED ACTION */}
        {canEdit && memberDetails && (
          <button
            className={`${m.button} org-member-info-button`}
            onClick={onIdentityUpdateClicked}>
            Update
          </button>
        )}
      </div>

      {/* HIDE ACTIONS IF MEMBER IS NOT CURRENT USER */}
      {canEdit && (
        <div className={m['member-actions-container']}>
          {/* BALANCES */}
          <section className={m['action']}>
            <div
              className={`${m['member-section-title']} org-member-section-title`}>
              Token Balances
            </div>
            <div className={m['action__help']}>
              Investor tokens will be listed here as they become available to
              members.
            </div>
            {/* TODO: redesign has token balances and withdraw buttons inline (instead of modal) */}
            <button
              className={`${m.button} org-member-info-button ${m['action__button']}`}
              disabled={!hasBalances}
              onClick={handleShowBalancesManager}>
              Balances
            </button>

            {hasBalances && showBalancesManager && !showRagequitFlow && (
              <MemberBalancesManager
                erc20Balances={erc20Balances}
                molochTokenBalances={molochTokenBalances}
                onClose={handleHideBalancesManager}
                shouldOpen={showBalancesManager}
                updateBalances={updateBalances}
              />
            )}
          </section>

          {/* DELEGATION */}
          <section className={m['action']}>
            <div
              className={`${m['member-section-title']} org-member-section-title`}>
              Delegation
            </div>
            <div className={m['action__help']}>
              You can delegate your voting rights to a different ethereum
              address.
            </div>
            <Delegation />
          </section>

          {/* RAGE QUIT */}
          <section className={m['action']}>
            <div
              className={`${m['member-section-title']} org-member-section-title`}>
              Rage Quit
            </div>
            <div className={m['action__help']}>
              If you&rsquo;re ready to pack up and head out, you can liquidate
              your investment here.
            </div>

            {/* WHY IS RAGE QUITTING DISABLED */}
            {!canRagequit && (
              <span
                className="why__tooltip why__tooltip--left"
                onClick={handleOpenWhyNoRagequitModal}>
                Why can&rsquo;t I rage quit?
              </span>
            )}

            {showWhyNoRagequitModal && (
              <WhyInfoModal
                onClose={handleHideWhyNoRagequitModal}
                shouldOpen={showWhyNoRagequitModal}
                title="Why can&rsquo;t I rage quit?">
                <ul className="color-brightlime">
                  {!amountSharesLootOK && (
                    <li style={{marginBottom: '1em', textAlign: 'initial'}}>
                      You do not have any voting and/or non-voting shares to
                      rage quit.
                    </li>
                  )}
                  {!highestIndexYesVoteProcessed && (
                    <li style={{marginBottom: '1em', textAlign: 'initial'}}>
                      The last proposal you voted on has not been processed yet.
                    </li>
                  )}
                </ul>
              </WhyInfoModal>
            )}

            <button
              className={`${m.button} org-member-info-button ${m['action__button']}`}
              disabled={!canRagequit}
              onClick={canRagequit ? handleShowRagequitFlow : () => {}}>
              Rage Quit
            </button>

            {showRagequitFlow && (
              <RagequitFlow
                onClose={handleHideRagequitFlow}
                onStepSuccess={handleRagequitFlowStepSuccess}
                shouldOpen={showRagequitFlow}
              />
            )}
          </section>
        </div>
      )}
    </div>
  );
}

function WhyInfoModal(props: WhyInfoModalProps) {
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  function renderCloseButton() {
    switch (orgInternalName) {
      case InternalNamesToMap.nft:
      case InternalNamesToMap.liquidity:
        return (
          <span className="org-modal-close" onClick={props.onClose}>
            <TimesSVG />
          </span>
        );
      case InternalNamesToMap.thelao:
      default:
        return (
          <div className={mo['close']} style={{top: '.9rem'}}>
            <button className={`${b.underline}`} onClick={props.onClose}>
              Close
            </button>
          </div>
        );
    }
  }

  return (
    <ReactModal
      ariaHideApp={false}
      className={`${mo['modal-content-wide']}`}
      isOpen={props.shouldOpen}
      onRequestClose={props.onClose}
      overlayClassName={`${mo['modal-overlay']} org-modal-overlay`}
      role="dialog"
      style={{overlay: {zIndex: '99'}, content: {maxWidth: '32.5rem'}} as any}>
      <FadeIn>
        <div className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} org-modal`}>
          <div className={`${sm.sales} ${mo['modal-title']} card`}>
            {renderCloseButton()}
            {/* CONTENT */}
            <div className="titlebar">
              <h3 className="titlebar__title org-titlebar__title">
                {props.title}
              </h3>
            </div>

            {props.children}
          </div>
        </div>
      </FadeIn>
    </ReactModal>
  );
}
