import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {AbiItem} from 'web3-utils/types';

import {useETHGasPrice} from '../../hooks';
import {StoreState} from '../../util/types';
import MolochContract from '../../truffle-contracts/Moloch.json';

import AdminFee from './AdminFee';

import 'react-tabs/style/react-tabs.css';

type InitialContractStateType = {
  summoner: string | undefined;
  approvedTokens: string;
  periodDuration: number;
  votingPeriodLength: number;
  gracePeriodLength: number;
  proposalDeposit: number;
  dilutionBound: number;
  processingReward: number;
  laoFundAddress: string;
};

export default function DeployContract() {
  const web3Instance = useSelector(
    (state: StoreState) => state.blockchain.web3Instance
  );
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const {average: gasPrice} = useETHGasPrice();

  const INITIAL_CONTRACT_STATE: InitialContractStateType = {
    summoner: connectedAddress,
    approvedTokens: '',
    periodDuration: 0,
    votingPeriodLength: 0,
    gracePeriodLength: 0,
    proposalDeposit: 0,
    dilutionBound: 0,
    processingReward: 0,
    laoFundAddress: '',
  };

  const [contractVariables, setContractVariables] =
    useState<InitialContractStateType>(INITIAL_CONTRACT_STATE);

  function preCheckDeployment() {
    if (window.confirm('Do you really want to deploy this contract?')) {
      deploy();
    }
  }

  /**
   * REFERENCES FOR CONTRACT DEPLOYMENT
   * https://web3js.readthedocs.io/en/v1.2.0/web3-eth-contract.html
   * https://github.com/openlawteam/lao/issues/365
   * 
   * Launch Protocol
   * https://docs.google.com/document/d/1im-NsqnC4gVRo0JiTo7FSjM6b5qX6H-ybrsyuw5vQq0/edit
   * 
   * Moloch Contract Constructor
   * constructor(
        address _summoner,
        address[] memory _approvedTokens,
        uint256 _periodDuration,
        uint256 _votingPeriodLength,
        uint256 _gracePeriodLength,
        uint256 _proposalDeposit,
        uint256 _dilutionBound,
        uint256 _processingReward,
        address _laoFundAddress
    ) 
   */
  async function deploy() {
    if (!web3Instance || !connectedAddress) return;

    try {
      const contractValues: Array<string | number | Array<string> | undefined> =
        Object.values(contractVariables);
      const {abi, bytecode} = MolochContract;
      const deployContract = new web3Instance.eth.Contract(abi as AbiItem[]);

      contractValues[0] = connectedAddress;
      contractValues[1] = [contractVariables.approvedTokens]; // need to pass approvedTokens as array

      await deployContract
        .deploy({
          data: bytecode,
          arguments: contractValues,
        })
        .send(
          {
            from: connectedAddress,
            gas: 1500000, // @note: Value taken from example snippet in web3 docs
            // set proposed gas price
            ...(gasPrice ? {gasPrice} : null),
          },
          function (error: Error, transactionHash: string) {
            console.log('error, transactionHash', error, transactionHash);
          }
        )
        .on('error', function (error: Error) {
          console.log('error', error);
        })
        .on('transactionHash', function (transactionHash: string) {
          console.log('transactionHash', transactionHash);
        })
        .on('receipt', function (receipt: Record<string, any>) {
          console.log(receipt, receipt.contractAddress); // contains the new contract address
        })
        .on(
          'confirmation',
          function (confirmationNumber: number, receipt: Record<string, any>) {
            console.log(
              'confirmationNumber, receipt',
              confirmationNumber,
              receipt
            );
          }
        )
        .then(function (newContractInstance: Record<string, any>) {
          console.log(
            'newContractInstance',
            newContractInstance,
            newContractInstance.options.address
          ); // instance with the new contract address
        });
    } catch (error) {
      console.error('error', error);
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {name, value} = event.currentTarget;

    event.persist();

    setContractVariables({
      ...contractVariables,
      [name]: value,
    });
  }

  return (
    <Tabs>
      <TabList>
        <Tab>Deploy Contract</Tab>
        <Tab>Admin Fee</Tab>
      </TabList>

      <TabPanel>
        <div className="admin-deploy-contract">
          <h2>Deploy a Contract</h2>
          {/* <label htmlFor="summoner" className="hidden">Summoner</label>
          <input
            className="hidden"
            type="text"
            name="summoner"
            value={connectedAddress}
            onChange={handleChange}
          /> */}

          <label htmlFor="approvedTokens">Approved Tokens</label>
          <input
            type="text"
            name="approvedTokens"
            value={contractVariables.approvedTokens}
            onChange={handleChange}
          />

          <label htmlFor="periodDuration">Period Duration</label>
          <input
            type="text"
            name="periodDuration"
            value={contractVariables.periodDuration}
            onChange={handleChange}
          />

          <label htmlFor="votingPeriodLength">Voting Period Length</label>
          <input
            type="text"
            name="votingPeriodLength"
            value={contractVariables.votingPeriodLength}
            onChange={handleChange}
          />

          <label htmlFor="gracePeriodLength">Grace Period Length</label>
          <input
            type="text"
            name="gracePeriodLength"
            value={contractVariables.gracePeriodLength}
            onChange={handleChange}
          />

          <label htmlFor="proposalDeposit">Proposal Deposit</label>
          <input
            type="text"
            name="proposalDeposit"
            value={contractVariables.proposalDeposit}
            onChange={handleChange}
          />

          <label htmlFor="dilutionBound">Dilution Bound</label>
          <input
            type="text"
            name="dilutionBound"
            value={contractVariables.dilutionBound}
            onChange={handleChange}
          />

          <label htmlFor="processingReward">Processing Reward</label>
          <input
            type="text"
            name="processingReward"
            value={contractVariables.processingReward}
            onChange={handleChange}
          />

          <label htmlFor="laoFundAddress">LAO Fund Address</label>
          <input
            type="text"
            name="laoFundAddress"
            value={contractVariables.laoFundAddress}
            onChange={handleChange}
          />

          <button className={'org-primary-button'} onClick={preCheckDeployment}>
            Deploy Contract
          </button>

          {/** PROCESSING DEPLOYMENT MSG */}

          {/** DEPLOYMENT COMPLETE */}

          {/** ERROR HANDLING FEEDBACK */}
        </div>
      </TabPanel>
      <TabPanel>
        <h2>Admin Fee</h2>
        <AdminFee />
      </TabPanel>
    </Tabs>
  );
}
