import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Web3 from 'web3';

import {ENVIRONMENT, RPC_WS_URLS} from '../util/config';
import {initWeb3Instance} from '../store/actions';
import {StoreState} from '../util/types';
import {useIsMounted} from './useIsMounted';
import ChainlinkETHPrice from '../truffle-contracts/ChainlinkETHPrice.json';

/**
 * useLatestETHPrice
 *
 * Gets the current ETH/USD price using a "Chainlinked" contract.
 *
 * @return {number} ETH price in USD per 1 unit of ETH.
 */
export default function useLatestETHPrice() {
  // if development, check every hour, else every 10 minutes (Chainlink sets frequency).
  const CHECK_PRICE_INTERVAL =
    process.env.REACT_APP_ENVIRONMENT !== 'production' ? 3600000 : 600000;

  const [latestETHPrice, setLatestETHPrice] = useState<number | null>();

  /**
   * Selectors
   */

  const web3Instance = useSelector(
    (state: StoreState) => state.blockchain.web3Instance
  );
  const defaultChain = useSelector(
    (state: StoreState) => state.blockchain.defaultChain
  );

  /**
   * Their hooks
   */

  const dispatch = useDispatch();

  /**
   * Our hooks
   */

  const {isMountedRef} = useIsMounted();

  /**
   * Initialise Redux Web3 instance
   * Allows for non-connected users to see latest ETH price (e.g. Verify form slider).
   */
  useEffect(() => {
    if (ENVIRONMENT !== 'production' && defaultChain === 1) return;
    if (ENVIRONMENT === 'production' && defaultChain !== 1) return;
    if (defaultChain === 1337) return; // ganache network

    defaultChain &&
      !web3Instance &&
      dispatch(
        initWeb3Instance(
          new Web3(
            new Web3.providers.WebsocketProvider(RPC_WS_URLS[defaultChain])
          )
        )
      );
  }, [dispatch, defaultChain, web3Instance]);

  useEffect(() => {
    let latestPriceIntervalId: NodeJS.Timeout;

    if (!web3Instance) return;

    try {
      web3Instance.eth.net.getId().then((networkId: number) => {
        /**
         * https://github.com/openlawteam/lao/issues/789
         *
         * @note IMPORTANT
         * Chainlink have yet to deploy the new proxy to rinkeby testnet
         * We need to use two different contracts
         * mainnet - uses new proxy contract
         * rinkeby - uses new proxy contract
         * @see https://docs.chain.link/docs/price-feeds-migration-august-2020
         */
        const chainlinkContract: Record<string, any> = ChainlinkETHPrice;
        const deployedNetwork: any = chainlinkContract.networks[networkId];

        // no contract exists at user's selected network
        if (!deployedNetwork) {
          isMountedRef.current && setLatestETHPrice(null);
          return;
        }

        const {methods} = new web3Instance.eth.Contract(
          chainlinkContract.abi,
          deployedNetwork && deployedNetwork.address
        );

        if (!isMountedRef.current) return;

        /**
         * The `getLatestPrice` value for all reference data contracts
         * is multiplied by 100000000 before being written on-chain.
         * @note also see above note
         * @see https://docs.chain.link/docs/using-chainlink-reference-contracts#section-live-reference-data-contracts-ethereum-mainnet
         */
        // const functionName = ;
        const checkAndMaybeSetPrice = async () => {
          const latestPrice =
            (await methods['getLatestPrice']().call()) / 100000000;

          setLatestETHPrice(latestPrice);
        };

        // check initially
        checkAndMaybeSetPrice();

        // check at interval
        latestPriceIntervalId = setInterval(
          checkAndMaybeSetPrice,
          CHECK_PRICE_INTERVAL
        );
      });
    } catch (error) {
      if (!isMountedRef.current) return;

      setLatestETHPrice(undefined);
    }

    return () => {
      latestPriceIntervalId && clearInterval(latestPriceIntervalId);
    };
  }, [web3Instance, CHECK_PRICE_INTERVAL, isMountedRef]);

  return latestETHPrice;
}
