import {useState} from 'react';

import {
  CreateInvestmentProposalRequest,
  CreateInvestmentProposalResponse,
} from '../util/types';
import {FetchStatus} from '../util/enums';
import useBackendURL from './useBackendURL';

type UseCreateInvestmentProposalReturn = {
  createInvestmentProposal: (
    data: CreateInvestmentProposalRequest
  ) => Promise<CreateInvestmentProposalResponse>;
  createInvestmentProposalError: Error | undefined;
  createInvestmentProposalStatus: FetchStatus;
};

export default function useCreateInvestmentProposal(): UseCreateInvestmentProposalReturn {
  /**
   * State
   */

  const [createInvestmentProposalError, setCreateInvestmentProposalError] =
    useState<Error>();
  const [createInvestmentProposalStatus, setCreateInvestmentProposalStatus] =
    useState<FetchStatus>(FetchStatus.STANDBY);

  /**
   * Our hooks
   */

  const backendURL = useBackendURL();

  async function handleCreateInvestmentProposal(data: any) {
    if (!backendURL) return;

    try {
      setCreateInvestmentProposalStatus(FetchStatus.PENDING);

      const response = await fetch(`${backendURL}/proposals-investment`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(
          'Something went wrong while creating your investment proposal.'
        );
      }

      const responseJSON = await response.json();

      setCreateInvestmentProposalStatus(FetchStatus.FULFILLED);

      return responseJSON;
    } catch (error) {
      setCreateInvestmentProposalStatus(FetchStatus.REJECTED);
      setCreateInvestmentProposalError(error);
    }
  }

  return {
    createInvestmentProposal: handleCreateInvestmentProposal,
    createInvestmentProposalError,
    createInvestmentProposalStatus,
  };
}
