import React, {useContext} from 'react';
import {useSelector} from 'react-redux';

import {InternalNamesToMap} from '../../../../util/orgDomainMappings';
import {Proposal, StoreState} from '../../../../util/types';
import {useProposalPeriodInfo} from '../../../../hooks';
import {VoteContext} from '../MolochVoting';
import DropSVG from '../../../../assets/svg/DropSVG';
import ProposalPeriod from '../../../../components/ui/ProposalPeriod';
import ProposalVoters from './ProposalVoters';
import StopwatchSVG from '../../../../assets/svg/StopwatchSVG';

import vc from '../../../../assets/scss/modules/votingcard.module.scss';

export default function CurrentVotes() {
  const {molochProposal} = useContext(VoteContext);

  const proposalPeriodDates = useProposalPeriodInfo(molochProposal as Proposal);

  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  function renderCountdownIcon(): JSX.Element {
    switch (orgInternalName) {
      case InternalNamesToMap.liquidity:
        return <DropSVG />;
      case InternalNamesToMap.nft:
      case InternalNamesToMap.thelao:
      default:
        return <StopwatchSVG />;
    }
  }

  return (
    <>
      <div className={`${vc['row-w-icon']} ${vc['countdown']} org-countdown`}>
        {renderCountdownIcon()}
        {molochProposal && (
          <ProposalPeriod
            proposalPeriodInfo={proposalPeriodDates}
            simpleDisplay={true}
          />
        )}
      </div>

      <ProposalVoters proposal={molochProposal} />
    </>
  );
}
