import React, {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import Markdown from 'markdown-to-jsx';

import {
  getOrgText,
  getVotingPeriodFormatted,
  getValidationError,
  formatNumber,
} from '../../util/helpers';
import {isEthAddressValid} from '../../util/validation';
import {StoreState} from '../../util/types';
import {SnapshotGetFormValuesReturn} from './types';
import {SnapshotProposalSubType, SnapshotProposalType} from './enums';
import {useSelector} from 'react-redux';
import InputError from '../common/InputError';

import c from '../../assets/scss/modules/checkbox.module.scss';
import fs from '../../assets/scss/modules/formsteps.module.scss';

type BaseSnapshotProposalFormRender = {
  /**
   * `useForm` result of the base form.
   */
  form: ReturnType<typeof useForm>;
  /**
   * Gets the form values that are required by Snapshot, and no more.
   */
  getFormValues: () => SnapshotGetFormValuesReturn;
  /**
   * Helper to check if the form is valid
   */
  isValid: boolean;
  /**
   * Form error (i.e. Total `metadata` length is wrong)
   */
  formErrorMessage: string;
  /**
   * Address to fund component
   */
  AddressToFundInput: () => JSX.Element | null;
  /**
   * Sub-type select input component.
   */
  SubTypeInput: (p: SubTypeInputProps) => JSX.Element | null;
  /**
   * Name input component
   */
  NameInput: () => JSX.Element | null;
  /**
   * Body input component
   */
  BodyInput: () => JSX.Element | null;
  /**
   * Privacy checkbox input component
   */
  PrivacyInput: (p: PrivacyInputProps) => JSX.Element | null;
  /**
   * Voting length range slider input component
   */
  VotingLengthInput: (p: VotingLengthInputProps) => JSX.Element | null;
};

enum Fields {
  name = 'name',
  body = 'body',
  addressToFund = 'addressToFund',
  voteLength = 'voteLength',
  voteLengthSeconds = 'voteLengthSeconds',
  private = 'private',
  subType = 'subType',
}

type BaseSnapshotProposalForm = {
  onIsFormValid?: (isValid: boolean, values: Record<string, any>) => void;
  /**
   * A render prop which renders any additional fields.
   */
  render: (p: BaseSnapshotProposalFormRender) => JSX.Element;
  /**
   * The `type` will be attached to the `metadata` of the Snapshot proposal
   * in order to distinguish different types of Snapshots per org.
   */
  type: SnapshotProposalType;
  /**
   * An optional prop to set the `subType` explicitly (i.e. no <select> field rendered).
   * @note The `subType` will fall back to `SnapshotProposalSubType.General`.
   */
  subType?: SnapshotProposalSubType;
  /**
   * Set the voting period explictly.
   * i.e. Can be useful when deciding not to render the vote length slider component.
   */
  votePeriodSettings?: {
    /**
     * Seconds value to multiply by `votingPeriodLength`.
     * e.g. 60 sec (1 minute); 86,400 sec (1 day).
     * @note Same as Moloch voting period duration.
     */
    periodDuration: number;
    /**
     * An incremented value to multiply `periodDuration` by.
     * @note Same as Moloch voting period length.
     */
    votingPeriodLength: number;
  };
};

type SubTypeInputProps = {
  renderHelpText?: (
    value: SnapshotProposalSubType
  ) => JSX.Element | string | null;
};

type VotingLengthInputProps = {
  sliderSettings?: {
    max?: number;
    min?: number;
  };
};

type PrivacyInputProps = {
  labelText?: string;
  text?: string;
};

// Allowed lengths per the Snapshot Hub API
const ALLOWED_LENGTHS = {name: 256, body: 50000, metadata: 20000};
const FORM_ERROR_KEY = 'formError';
const ERROR_REQUIRED_FIELD = 'This is a required field.';

const errorLengthExceeded = (length: number) =>
  `The maximum length is ${length} characters.`;

const validateEthAddress = {
  validate: (ethAddress: string): string | boolean => {
    return !ethAddress
      ? ERROR_REQUIRED_FIELD
      : !isEthAddressValid(ethAddress)
      ? 'The ethereum address is invalid.'
      : true;
  },
};

// Match the allowed lengths of the Snapshot API endpoint
const validateStringLength = (maxLength: number) => ({
  validate: (string: string): string | boolean => {
    return !string
      ? ERROR_REQUIRED_FIELD
      : string.trim().length > maxLength
      ? errorLengthExceeded(maxLength)
      : true;
  },
});

// Validation configuration for react-hook-form
const validateConfig: Partial<Record<Fields, Record<string, any>>> = {
  name: validateStringLength(ALLOWED_LENGTHS.name),
  body: validateStringLength(ALLOWED_LENGTHS.body),
  addressToFund: validateEthAddress,
};

/**
 * BaseSnapshotProposalForm
 *
 * A react-hook form which provides the core fields for
 * Snapshot proposals. It can wrap any other fields via
 * its `render` prop.
 *
 * The `render` prop will also pass details about the validity
 * of this form, its values, etc., via the callback arguments.
 *
 * The submission of the form values should be done outside of this component.
 *
 * @returns {JSX.Element}
 *
 * @see https://github.com/balancer-labs/snapshot-hub
 * @see https://snapshot.page
 */
export default function BaseCreateSnapshotProposalForm(
  props: BaseSnapshotProposalForm
) {
  const {render, type, subType: subTypeProp} = props;

  /**
   * Selectors
   */

  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );
  const molochVotingPeriodLength = useSelector(
    (s: StoreState) =>
      s.blockchain.molochConstants &&
      s.blockchain.molochConstants.votingPeriodLength
  );
  const molochPeriodDuration = useSelector(
    (s: StoreState) =>
      s.blockchain.molochConstants &&
      s.blockchain.molochConstants.periodDuration
  );

  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');

  /**
   * External hooks
   */

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  /**
   * Variables
   */

  const {
    clearError,
    errors,
    formState,
    getValues,
    register,
    setError,
    setValue,
    watch,
  } = form;

  const watchedValues = watch();
  /**
   * @note From the docs: "Read the formState before render to subscribe the form state through Proxy"
   * @see https://react-hook-form.com/api#formState
   */
  const {isValid} = formState;

  const {periodDuration, votingPeriodLength} = props.votePeriodSettings || {};

  // Get Moloch constants for voting period max length and period name (i.e. "Minute", "Hour", "Day")
  const {
    length: voteMaxLengthParsed,
    lengthSeconds: voteMaxLengthSecondsParsed,
    periodName: votePeriodNameParsed,
  } = getVotingPeriodFormatted({
    votingPeriodLength: votingPeriodLength || molochVotingPeriodLength || 0,
    periodDuration: periodDuration || molochPeriodDuration || 0,
    pluralize: true,
  });

  /**
   * Cached callbacks
   */

  const getFormValuesCached = useCallback(getFormValues, [
    getValues,
    subTypeProp,
    type,
    voteMaxLengthSecondsParsed,
  ]);

  const checkMetadataLengthCached = useCallback(checkMetadataLength, [
    getFormValuesCached,
  ]);

  const SubTypeInputCached = useCallback(SubTypeInput, [getValues, register]);
  const NameInputCached = useCallback(NameInput, [errors, register]);

  const BodyMarkdownPreviewCached = useCallback(BodyMarkdownPreview, [
    getValues,
  ]);

  const BodyInputCached = useCallback(BodyInput, [
    BodyMarkdownPreviewCached,
    errors,
    register,
  ]);

  const AddressToFundInputCached = useCallback(AddressToFundInput, [
    connectedAddress,
    errors,
    orgName,
    register,
  ]);

  const PrivacyInputCached = useCallback(PrivacyInput, [
    getValues,
    errors,
    register,
    orgName,
  ]);

  const VotingLengthInputCached = useCallback(VotingLengthInput, [
    getValues,
    register,
    voteMaxLengthParsed,
    votePeriodNameParsed,
  ]);

  /**
   * Effects
   */

  // Set votingLengthSeconds
  useEffect(() => {
    let voteLengthSeconds = 0;

    switch (votePeriodNameParsed) {
      case 'Day':
        voteLengthSeconds = watchedValues.voteLength * (3600 * 24);
        break;
      case 'Hour':
        voteLengthSeconds = watchedValues.voteLength * 3600;
        break;
      case 'Minute':
        voteLengthSeconds = watchedValues.voteLength * 60;
        break;
    }

    setValue(Fields.voteLengthSeconds, Math.floor(voteLengthSeconds));
  }, [setValue, votePeriodNameParsed, watchedValues.voteLength]);

  /**
   * Check for any other form-level errors we should show.
   *
   * @note All functions added to `Promise.all` array should conform to:
   *   `Promise<string>` and throw an Error string, if not valid.
   */
  useEffect(() => {
    // Check all validation promises
    Promise.all([checkMetadataLengthCached()])
      .then(() => {
        if (getValidationError(FORM_ERROR_KEY, errors)) {
          clearError(FORM_ERROR_KEY);
        }
      })
      .catch((error: Error) => {
        setError(FORM_ERROR_KEY, 'formError', error.message);
      });
  }, [watchedValues, clearError, setError, errors, checkMetadataLengthCached]);

  /**
   * Functions
   */

  /**
   * getFormValues
   *
   * Only destructures the form values required by Snapshot.
   *
   * @returns {SnapshotGetFormValuesReturn}
   */
  function getFormValues(): SnapshotGetFormValuesReturn {
    const defaultSubType = subTypeProp || SnapshotProposalSubType.General;

    const {
      name,
      body,
      addressToFund,
      // Defaults to `subTypeProp` or falls back to `SnapshotProposalSubType.General`
      subType = defaultSubType,
      // Defaults to `votePeriodSettings` or falls back to Moloch constants
      voteLengthSeconds = voteMaxLengthSecondsParsed,
      /* @note `private` is a reserved JS namespace */
      private: privacy,
      ...restValues
    } = getValues();

    // We don't need `voteLength`
    delete restValues[Fields.voteLength];

    return {
      name,
      body,
      addressToFund,
      voteLengthSeconds,
      metadata: {private: privacy, type, subType, ...restValues},
    };
  }

  /**
   * checkMetadataLength
   *
   * Any non-base fields rendered by another component
   * will be classified as `metadata` which collectively
   * has a character limit in the Snapshot API, once JSON stringifed.
   * At the moment that limit is 20,000 characters.
   *
   * This function will set an error if the sum of the
   * non-base fields' characters is beyond the limit.
   *
   * @returns {Promise<string>} Promise string, or throws Error.
   */
  async function checkMetadataLength(): Promise<string> {
    try {
      const {metadata} = getFormValuesCached();
      const metadataTotalLength = JSON.stringify(metadata).length;
      const {private: privacy, type, ...restMetadata} = metadata;
      const lengthCalculated: number = Object.entries(restMetadata).reduce(
        (acc, m) => (m[1] ? acc + m[1].toString().length : acc),
        0
      );
      // Subtract any core `metdata` fields
      const maxLengthCalculated =
        ALLOWED_LENGTHS.metadata - JSON.stringify({privacy, type}).length;

      if (metadataTotalLength > ALLOWED_LENGTHS.metadata) {
        throw new Error(
          `Please check fields, other than Title and Description, for character count. The character count submitted was ${formatNumber(
            lengthCalculated
          )}, but the limit is ${formatNumber(maxLengthCalculated)}.`
        );
      }

      // If validation passed, give an empty string;
      return '';
    } catch (error) {
      throw error;
    }
  }

  function SubTypeInput(props: SubTypeInputProps) {
    const {renderHelpText} = props;

    return (
      <div className={`${fs['input-row']}`}>
        <label
          className={`${fs['input-row-label']} org-input-row-label`}
          htmlFor={`snapshot-${Fields.subType}`}>
          Proposal Category
        </label>
        <div className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
          <select
            id={`snapshot-${Fields.subType}`}
            name={Fields.subType}
            ref={register}
            className="org-select">
            {Object.values(SnapshotProposalSubType).map((s) => (
              <option key={s} value={s}>
                {s}
              </option>
            ))}
          </select>

          {renderHelpText && (
            <p>
              <small>{renderHelpText(getValues().subType)}</small>
            </p>
          )}
        </div>
      </div>
    );
  }

  // Name input rendered
  function NameInput() {
    return (
      <div className={`${fs['input-row']}`}>
        <label
          className={`${fs['input-row-label']} org-input-row-label`}
          htmlFor={`snapshot-${Fields.name}`}>
          Proposal Title
        </label>
        <div className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
          <input
            aria-describedby={`snapshot-error-${Fields.name}`}
            aria-invalid={errors[Fields.name] ? 'true' : 'false'}
            id={`snapshot-${Fields.name}`}
            name={Fields.name}
            ref={validateConfig.name && register(validateConfig.name)}
            type="text"
          />
          <InputError
            error={getValidationError(Fields.name, errors)}
            id={`snapshot-error-${Fields.name}`}
          />
        </div>
      </div>
    );
  }

  // Body input rendered
  function BodyInput() {
    return (
      // BODY
      <div className={`${fs['textarea-row']}`}>
        <label
          className={`${fs['input-row-label']} org-input-row-label`}
          htmlFor={`snapshot-${Fields.body}`}>
          Proposal Description
        </label>
        <div className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
          <textarea
            aria-describedby={`snapshot-error-${Fields.body}`}
            aria-invalid={errors[Fields.body] ? 'true' : 'false'}
            id={`snapshot-${Fields.body}`}
            name={Fields.body}
            placeholder="What do you propose?"
            ref={validateConfig.body && register(validateConfig.body)}
          />
          <InputError
            error={getValidationError(Fields.body, errors)}
            id={`snapshot-error-${Fields.body}`}
          />

          <BodyMarkdownPreviewCached />
        </div>
      </div>
    );
  }

  function BodyMarkdownPreview() {
    const body = getValues().body;

    return body ? (
      <details>
        <summary
          className={`color-keylargo ${fs['snapshot-preview']} org-snapshot-preview`}
          style={{cursor: 'pointer', outline: 'none'}}>
          <small>Preview Markdown</small>
        </summary>

        <div style={{marginTop: '1em'}}>
          <Markdown>{body}</Markdown>
        </div>
      </details>
    ) : null;
  }

  function AddressToFundInput() {
    return (
      <div className={`${fs['input-row']}`}>
        <label className={`${fs['input-row-label']} org-input-row-label`}>
          Address to Fund
        </label>
        <div className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
          <input
            aria-describedby={`snapshot-error-${Fields.addressToFund}`}
            aria-invalid={errors[Fields.addressToFund] ? 'true' : 'false'}
            defaultValue={connectedAddress}
            id={`snapshot-${Fields.addressToFund}`}
            name={Fields.addressToFund}
            placeholder="ETH Address for funding"
            ref={
              validateConfig.addressToFund &&
              register(validateConfig.addressToFund)
            }
            type="text"
          />
          <InputError
            error={getValidationError(Fields.addressToFund, errors)}
            id={`snapshot-error-${Fields.addressToFund}`}
          />

          <p
            className={`${fs['input-row-help']} org-input-row-help org-input-row-help--alert color-sunny`}>
            If your proposal is accepted for funding, this is your address which
            you will use to withdraw / receive funds from {orgName}. If a member
            chooses to submit this proposal as a Snapshot proposal then this
            address is where the side-pocket will send the funds.
          </p>

          <p
            className={`${fs['input-row-help']} org-input-row-help org-input-row-help--alert color-sunny`}>
            If this proposal is submitted by a member as a Moloch proposal the
            address above cannot change.
          </p>
        </div>
      </div>
    );
  }

  // Voting length input rendered
  function VotingLengthInput(votingLengthProps: VotingLengthInputProps) {
    /**
     * Defaults for `min` voting length, and `step` for incrementing.
     * The period (i.e. day, hour, minute) will be determined via Moloch period.
     */
    const VOTE_LENGTH = {min: 1, step: 1};
    const voteLengthValue = getValues().voteLength;
    const {sliderSettings} = votingLengthProps;
    const propSliderMax = sliderSettings && sliderSettings.max;
    const propSliderMin = sliderSettings && sliderSettings.min;

    return (
      // VOTING LENGTH
      <div className={`${fs['input-row']}`}>
        <label
          className={`${fs['input-row-label']} org-input-row-label`}
          htmlFor={`snapshot-${Fields.voteLength}`}>
          Voting Length
        </label>
        <div className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
          {/* VISIBLE FIELD FOR VOTING LENGTH */}
          <input
            aria-valuemax={propSliderMax || voteMaxLengthParsed}
            aria-valuemin={propSliderMin || VOTE_LENGTH.min}
            aria-valuenow={Number(Fields.voteLength)}
            id={`snapshot-${Fields.voteLength}`}
            max={propSliderMax || voteMaxLengthParsed}
            min={propSliderMin || VOTE_LENGTH.min}
            name={Fields.voteLength}
            ref={register}
            step={VOTE_LENGTH.step}
            type="range"
          />
          <p>{`${voteLengthValue} ${votePeriodNameParsed}${
            voteLengthValue > 1 ? 's' : ''
          }`}</p>

          {/* HIDDEN, COMPUTED FIELD for VOTING SECONDS */}
          <input
            aria-hidden="true"
            className="hidden"
            name={Fields.voteLengthSeconds}
            ref={register}
            // The styles for `.hidden` `width: 1px` get overriden
            style={{width: 1}}
            type="number"
          />
        </div>
      </div>
    );
  }

  // Privacy checkbox rendered
  function PrivacyInput(props: PrivacyInputProps) {
    const privacy = getValues().private;

    return (
      <div className={`${fs['checkbox-row']}`}>
        <label
          className={`${fs['input-row-label']} org-input-row-label`}
          htmlFor={`snapshot-${Fields.private}`}>
          {props.labelText || 'Proposal Privacy'}
        </label>
        <div className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
          <input
            aria-checked={privacy}
            aria-invalid={errors[Fields.private] ? 'true' : 'false'}
            className={`${c['checkbox-input']} org-checkbox-input`}
            id={`snapshot-${Fields.private}`}
            name={Fields.private}
            ref={register}
            type="checkbox"
          />
          <label
            className={`${c['checkbox-label']} org-checkbox-label`}
            htmlFor={`snapshot-${Fields.private}`}>
            <span className={`${c['checkbox-box']} org-checkbox-box`}></span>
            <span>
              {props.text ||
                `Only ${orgName} members can view this proposal. This cannot be
              changed once submitted.`}
            </span>
          </label>
        </div>
      </div>
    );
  }

  /**
   * Render
   */

  return (
    <form
      className={`${fs['input-rows-wrap']}`}
      onSubmit={(e) => e.preventDefault()}>
      {/* RENDER FIELDS, ETC. */}
      {render({
        form,
        getFormValues,
        isValid,
        formErrorMessage: getValidationError(FORM_ERROR_KEY, errors),
        AddressToFundInput: AddressToFundInputCached,
        SubTypeInput: SubTypeInputCached,
        NameInput: NameInputCached,
        BodyInput: BodyInputCached,
        PrivacyInput: PrivacyInputCached,
        VotingLengthInput: VotingLengthInputCached,
      })}
    </form>
  );
}
