import {combineReducers} from 'redux';

import authServer from './authServer/reducers';
import blockchain from './blockchain/reducers';
import connectedMember from './connectedMember/reducers';
import org from './org/reducers';
import user from './users/reducers';

export default combineReducers({
  authServer,
  blockchain,
  connectedMember,
  org,
  user,
});
