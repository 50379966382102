import React from 'react';

interface IProps {
  fill?: string;
}

export default function XCloseSVG(
  props: IProps & React.PropsWithoutRef<JSX.IntrinsicElements['svg']>
) {
  const {fill, ...restProps} = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
      width="13"
      height="13"
      viewBox="0 0 13 13">
      <title>Red "X" Button</title>
      <g fill={fill || '#FFF'} fillRule="evenodd" stroke="none" strokeWidth="1">
        <rect
          width="2.813"
          height="15"
          x="5"
          y="-1"
          rx="1.125"
          transform="rotate(-45 6.406 6.5)"></rect>
        <rect
          width="2.813"
          height="15"
          x="5"
          y="-1"
          rx="1.125"
          transform="rotate(45 6.406 6.5)"></rect>
      </g>
    </svg>
  );
}
