import {useEffect, useState} from 'react';
import {QueryHookOptions, useQuery} from '@apollo/react-hooks';

import {GET_PROPOSAL} from '../gql';
import {GQL_QUERY_POLLING_INTERVAL} from '../util/config';
import {InvestmentProposalGQLResponse} from '../util/types';
import {usePageVisibility} from '../hooks';

type UseMolochProposalData = {
  /**
   * The result of GQL query for `GET_PROPOSAL(proposalId)`
   */
  molochGQLProposal: InvestmentProposalGQLResponse | undefined;
};

/**
 * useMolochProposal
 *
 * Gets the proposal result from our subgraph for Moloch.
 *
 * @param {number | null} proposalId
 * @returns {UseMolochProposalData}
 */
export default function useMolochProposal(
  proposalId: number | null,
  queryOptions?: QueryHookOptions
): UseMolochProposalData {
  const pollInterval =
    queryOptions?.pollInterval !== undefined
      ? queryOptions.pollInterval
      : GQL_QUERY_POLLING_INTERVAL;

  const proposalIdSafe =
    proposalId !== null && proposalId >= 0 ? proposalId.toString() : null;

  const isPageVisible = usePageVisibility();

  /**
   * GQL Query
   */

  const proposal = useQuery<Record<string, any>>(GET_PROPOSAL, {
    variables: {proposalId: proposalIdSafe},
    ...queryOptions,
  });

  /**
   * State
   */

  const [molochGQLProposal, setMolochGQLProposal] =
    useState<InvestmentProposalGQLResponse>();

  /**
   * Effects
   */

  useEffect(() => {
    if (isPageVisible) {
      proposal.startPolling(pollInterval);
    } else {
      proposal.stopPolling();
    }
  }, [isPageVisible, pollInterval, proposal]);

  // Get GQL proposal details
  useEffect(() => {
    try {
      if (!proposal.loading && proposal.data) {
        const {proposals} = proposal.data as Record<string, any>;
        setMolochGQLProposal(proposals[0]);
      }
    } catch (error) {
      setMolochGQLProposal(undefined);
    }
  }, [proposal]);

  useEffect(() => {
    if (!proposalIdSafe) {
      // Do not poll if the proposal ID does not exist.
      proposal.stopPolling();

      return;
    }

    // Start polling
    pollInterval && proposal.startPolling(pollInterval);
  }, [pollInterval, proposal, proposalIdSafe]);

  return {
    molochGQLProposal,
  };
}
