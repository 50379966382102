import React, {useEffect, useState} from 'react';

import {displaySimpleCountdown} from '../../../util/helpers';

type SnapshotCountProps = {
  endTimestamp: number | null;
};

export default function SnapshotCountdown({endTimestamp}: SnapshotCountProps) {
  const [countdown, setCountdown] = useState<
    string | React.DetailedReactHTMLElement<{className: string}, HTMLElement>
  >();

  useEffect(() => {
    if (!endTimestamp) return;

    const intervalId = setInterval(() => {
      const currentTimestamp = Math.ceil(Date.now() / 1000);

      if (endTimestamp < currentTimestamp) {
        const endedText = React.createElement(
          'span',
          {className: 'org-status org-status--ended'},
          `Ended`
        );
        setCountdown(endedText);

        clearInterval(intervalId);
      } else {
        setCountdown(displaySimpleCountdown(new Date(endTimestamp * 1000)));
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [endTimestamp]);

  return <>{countdown}</>;
}
