import React from 'react';

export default function TokensMultiSVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="199"
      height="208"
      viewBox="0 0 199 208"
      {...props}>
      <defs>
        <linearGradient
          id="tokens-multi-svg-a"
          x1="62.506%"
          x2="37.494%"
          y1="50%"
          y2="0%">
          <stop offset="0%" stopColor="#8ECEF0"></stop>
          <stop offset="100%" stopColor="#D485EE"></stop>
        </linearGradient>
        <linearGradient
          id="tokens-multi-svg-b"
          x1="100%"
          x2="0%"
          y1="50%"
          y2="50%">
          <stop offset="0%" stopColor="#8ECEF0"></stop>
          <stop offset="100%" stopColor="#D485EE"></stop>
        </linearGradient>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="1">
        <g transform="translate(-21 -21)">
          <path
            stroke="url(#tokens-multi-svg-a)"
            strokeWidth="12"
            d="M39.5 133.486c36.497-.015 66.095-29.618 66.11-66.12.013-32.518-23.455-59.541-54.38-65.044a66.46 66.46 0 00-11.677-1.025"
            transform="rotate(-129 72.555 67.392)"></path>
          <circle
            cx="140.5"
            cy="149.5"
            r="71.5"
            stroke="url(#tokens-multi-svg-b)"
            strokeWidth="16"></circle>
        </g>
      </g>
    </svg>
  );
}
