import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import FadeIn from '../../../components/common/FadeIn';
import InputError from '../../../components/common/InputError';
import Loader from '../../../components/feedback/Loader';
import SubmitProposal, {
  SubmitProposalArguments,
} from '../../../components/contract/SubmitProposal';
import Wrap from '../../../components/layout/Wrap';

import {useLatestETHPrice} from '../../../hooks';
import {authServerShowModal} from '../../../store/actions';
import {StoreState, MetaMaskRPCError, ReduxDispatch} from '../../../util/types';
import {ProposalType} from '../../../util/enums';
import {
  formatNumber,
  stripFormatNumber,
  getOrgText,
} from '../../../util/helpers';
import {
  hasValue,
  isNumberString,
  isEthAddressValid,
} from '../../../util/validation';

import b from '../../../assets/scss/modules/buttons.module.scss';
import fs from '../../../assets/scss/modules/formsteps.module.scss';
import i from '../../../assets/scss/modules/input.module.scss';

enum FieldNameEnum {
  tributeOffered = 'tributeOffered',
  applicant = 'applicant',
  sharesRequested = 'sharesRequested',
}

type Values = {
  tributeOffered: string;
  applicant: string;
  sharesRequested: string;
};

type State = {
  errors: {
    [N in FieldNameEnum]: string;
  };
  errorSubmit: string;
  errorObjectSubmit: Error | undefined;
  fieldsTouched: Array<FieldNameEnum>;
  isReadyForSubmit: boolean;
  isSubmitting: boolean;
  isSubmittingText: string;
  isValidatingProposal: boolean;
  etherscanURL: string;
  molochDepositTokenAddress: string;
  values: Values;
};

const handleValidateFundingAmount = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const valueCleaned = stripFormatNumber(value.trim());
  const possibleNonNumberError = isNumberString(valueCleaned)
    ? ''
    : ERROR_NUMBER_STRING_INVALID;

  return {
    errorMessage: possibleNonNumberError /*|| possibleLowValueError*/,
    isError: possibleNonNumberError.length > 0,
  };
};

const handleValidateExists = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const possibleHasValueError = hasValue(value) ? '' : ERROR_REQUIRED_FIELD;

  return {
    errorMessage: possibleHasValueError,
    isError: possibleHasValueError.length > 0,
  };
};

const handleValidateEthAddress = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const possibleHasValueError = hasValue(value) ? '' : ERROR_REQUIRED_FIELD;
  const possibleEthAddressError = isEthAddressValid(value)
    ? ''
    : ERROR_ETH_ADDRESS_INVALID;

  return {
    errorMessage: possibleHasValueError || possibleEthAddressError,
    isError: (possibleHasValueError || possibleEthAddressError).length > 0,
  };
};

/**
 * displayTransformers
 *
 * Formats a value before setting it in state.
 */
const displayTransformers: Partial<
  Record<FieldNameEnum, (value: any) => string>
> = {
  tributeOffered: (value: string) => formatNumber(value.trim()),
};

const requestDataTransformers: Partial<
  Record<FieldNameEnum & Values, (value: any, allValues: Values) => any>
> = {
  // convert to lowerCase
  applicant: (value: string): string => value.toLowerCase(),
  // Strip number of comma formatting
  tributeOffered: (value: string) => Number(stripFormatNumber(value)),
};

const ERROR_REQUIRED_FIELD = 'Members need this information.';
const ERROR_NUMBER_STRING_INVALID = 'Value should be a number.';
const ERROR_ETH_ADDRESS_INVALID = 'The ETH address is invalid.';

export default function MembershipForm() {
  /**
   * Selectors
   */
  const accessToken = useSelector(
    (s: StoreState) => s.authServer && s.authServer.accessToken
  );
  const web3Instance = useSelector(
    (state: StoreState) => state.blockchain && state.blockchain.web3Instance
  );
  const VentureMoloch = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.VentureMoloch
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');
  const INITIAL_STATE: State = {
    errors: {
      tributeOffered: '',
      applicant: '',
      sharesRequested: '',
    },
    errorSubmit: '',
    errorObjectSubmit: undefined,
    fieldsTouched: [],
    isReadyForSubmit: false,
    isSubmitting: false,
    isSubmittingText: '',
    isValidatingProposal: false,
    etherscanURL: '',
    molochDepositTokenAddress: '',
    values: {
      tributeOffered: '0',
      applicant: '',
      sharesRequested: '',
    },
  };

  /**
   * State
   */

  const [state, setState] = useState<State>(INITIAL_STATE);
  const [isSubmitReady, setIsSubmitReady] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [proposalId, setProposalId] = useState<string>();
  const [molochDepositTokenAddress, setMolochDepositTokenAddress] = useState<
    string | null
  >(null);
  const [proposalArguments, setProposalArguments] =
    useState<SubmitProposalArguments>();

  const dispatch = useDispatch<ReduxDispatch>();
  const ethPrice = useLatestETHPrice();

  const setDepositTokenAddressCached = useCallback(setDepositTokenAddress, [
    VentureMoloch,
  ]);
  const getTransformedValuesCached = useCallback(getTransformedValues, [state]);

  useEffect(() => {
    setDepositTokenAddressCached();
  }, [setDepositTokenAddressCached]);

  useEffect(() => {
    const isReady =
      Object.values(state.values).every((v) => v) &&
      Object.values(state.errors).every((e) => !e);
    setIsSubmitReady(isReady);
  }, [state.values, state.errors]);

  useEffect(() => {
    if (!ethPrice) return;
    if (!web3Instance) return;
    if (!molochDepositTokenAddress) return;

    const transformedValues = getTransformedValuesCached();
    /**
     * Sets the shares amount requested to the user-typed amount.
     *
     * @todo Find a way to use transformedValues in a typesafe way.
     */
    const sharesRequested: number = Number(
      (transformedValues as Values).sharesRequested
    );

    // Check to make sure we have a number
    if (isNaN(sharesRequested)) return;

    setProposalArguments([
      /**
       * Applicant
       */
      state.values.applicant,
      /**
       * Shares
       */
      sharesRequested,
      /**
       * Loot
       */
      0,
      /**
       * Tribute offered in WEI
       */
      '0',
      /**
       * WETH address
       */
      molochDepositTokenAddress,
      /**
       * Payment requested in WEI
       */
      '0',
      /**
       * Payment token
       */
      molochDepositTokenAddress,
      /**
       * Details
       */
      `Membership`,
    ]);
  }, [
    molochDepositTokenAddress,
    ethPrice,
    web3Instance,
    state.values.applicant,
    state.values.sharesRequested,
    getTransformedValuesCached,
  ]);

  function maybeShowFieldError(
    fieldName: FieldNameEnum,
    showBeforeTouched: boolean = false
  ): React.ReactNode {
    const error = state.errors[fieldName];
    const touched = state.fieldsTouched.indexOf(fieldName) >= 0;

    if ((error && touched) || (error && showBeforeTouched)) {
      return (
        <FadeIn>
          <InputError className={fs['error']} error={error} />
        </FadeIn>
      );
    }

    return null;
  }

  function getTransformedValues() {
    const {values} = state;
    const maybeTransformedValues = {...values} as {
      [key: string]: any;
    };
    const transformers = requestDataTransformers as {
      [key: string]: (v: any, values: Values) => any;
    };

    for (const k in maybeTransformedValues) {
      if (transformers[k]) {
        maybeTransformedValues[k] = transformers[k](
          maybeTransformedValues[k],
          values
        );
      }
    }

    return maybeTransformedValues;
  }

  /**
   * setDepositTokenAddress
   *
   * Used when calling `submitProposal` with `tributeToken` and `paymentToken`
   * as they cannot be empty.
   */
  async function setDepositTokenAddress() {
    if (!VentureMoloch) return;

    const molochDepositTokenAddress = VentureMoloch
      ? ((await VentureMoloch.instance.methods.depositToken().call()) as string)
      : '';

    setMolochDepositTokenAddress(molochDepositTokenAddress);
  }

  function handleFieldTouched(fieldName: FieldNameEnum) {
    setState((prevState) => ({
      ...prevState,
      fieldsTouched: [...prevState.fieldsTouched, fieldName],
    }));
  }

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: FieldNameEnum,
    validator: (value: string) => {errorMessage: string; isError: boolean}
  ) {
    const {value} = event.currentTarget;
    const {errors, values} = state;
    const {errorMessage = ''} = validator(value);
    // Possibly transform the value for display
    const maybeDisplayTransformer = displayTransformers[fieldName]
      ? displayTransformers[fieldName]
      : undefined;
    const maybeTransformedValue = maybeDisplayTransformer
      ? maybeDisplayTransformer(value)
      : value;
    const fieldValuesObject = {
      values: {...values, [fieldName]: maybeTransformedValue},
    };
    event.persist();
    setState((prevState) => ({
      ...prevState,
      ...fieldValuesObject,
      errors: {
        ...errors,
        [fieldName]: errorMessage,
      },
    }));
  }

  function handleShowAuthServerModal() {
    // Show the auth modal and exit this process if the user has not authenticated with the server.
    if (!accessToken) {
      dispatch(authServerShowModal(true));
    }
  }

  function handleOnComplete(returnValues: Record<string, any>) {
    returnValues.proposalId && setIsComplete(true);
    returnValues.proposalId && setProposalId(returnValues.proposalId);
  }

  return (
    <Wrap className={'section-wrapper'}>
      <FadeIn>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">Membership</h2>
          <p>
            Submits a new member to the smart contract `submitProposal`. <br />
            The new member may not exist in KYC. If this is the case they will{' '}
            <br />
            need to be KYC'd in order to perform member actions, such as voting.
          </p>
        </div>

        <div className="org-form-wrap">
          <div className={`${fs['content-wrap']}`}>
            <div className={`${fs['input-rows-wrap']}`}>
              {/* APPLICANT ADDRESS */}
              <FadeIn>
                <div className={`${fs['input-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Applicant Address
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <input
                      name={FieldNameEnum.applicant}
                      onBlur={() => handleFieldTouched(FieldNameEnum.applicant)}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(
                          event,
                          FieldNameEnum.applicant,
                          handleValidateEthAddress
                        )
                      }
                      placeholder="ETH address of the member"
                      type="text"
                      value={state.values.applicant}
                    />

                    {maybeShowFieldError(FieldNameEnum.applicant)}

                    <p
                      className={`${fs['input-row-help']} org-input-row-help org-input-row-help--alert color-sunny`}>
                      If the proposal is sponsored, this is the address which
                      the member will use to perform member actions in {orgName}
                      .
                    </p>

                    <p
                      className={`${fs['input-row-help']} org-input-row-help org-input-row-help--alert color-sunny`}>
                      This address cannot change once the form is submitted to
                      the smart contract.
                    </p>
                  </div>
                </div>
              </FadeIn>

              {/* SHARES REQUESTED */}
              <FadeIn>
                <div className={`${fs['textarea-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Shares Requested
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <input
                      name={FieldNameEnum.sharesRequested}
                      onBlur={() =>
                        handleFieldTouched(FieldNameEnum.sharesRequested)
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(
                          event,
                          FieldNameEnum.sharesRequested,
                          handleValidateExists
                        )
                      }
                      placeholder="Amount of shares requested"
                      type="text"
                      value={state.values.sharesRequested}
                    />
                    {maybeShowFieldError(FieldNameEnum.sharesRequested)}
                  </div>
                </div>
              </FadeIn>

              {/* TRIBUTE OFFERED AMOUNT */}
              <FadeIn>
                <div className={`${fs['input-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Tribute Amount
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <div className={i['input-prefix__wrap']}>
                      <input
                        className={`${i['input-prefix--sm']}`}
                        name={FieldNameEnum.tributeOffered}
                        onBlur={() =>
                          handleFieldTouched(FieldNameEnum.tributeOffered)
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleChange(
                            event,
                            FieldNameEnum.tributeOffered,
                            handleValidateFundingAmount
                          )
                        }
                        type="text"
                        placeholder="How much will the member offer?"
                        // Formats number string to have commas for display.
                        value={state.values.tributeOffered}
                      />
                      <div
                        className={`${i['input-prefix__item--sm']} org-input-prefix__item--sm`}>
                        $
                      </div>
                    </div>
                    {maybeShowFieldError(FieldNameEnum.tributeOffered)}

                    <p className={`${fs['input-row-help']} org-input-row-help`}>
                      This will be managed by the {orgName} side-pocket.
                    </p>
                  </div>
                </div>
              </FadeIn>

              {/* SUBMIT */}
              {isSubmitReady && (
                <FadeIn>
                  <SubmitProposal
                    onComplete={handleOnComplete}
                    proposalArguments={proposalArguments}
                    proposalType={ProposalType.MEMBERSHIP}
                    render={({
                      error,
                      etherscanURL,
                      isPromptOpen,
                      isSubmitted,
                      isSubmitting,
                      openPrompt,
                    }) => (
                      <div
                        style={{
                          margin: '0 auto',
                          textAlign: 'center',
                        }}>
                        <button
                          className={`${b.primary} org-primary-button`}
                          onClick={
                            isSubmitting || isSubmitted || isPromptOpen
                              ? () => {}
                              : async () => {
                                  if (accessToken) {
                                    openPrompt();
                                  } else {
                                    handleShowAuthServerModal();
                                  }
                                }
                          }
                          type="submit"
                          disabled={
                            isSubmitting || isSubmitted || isPromptOpen
                          }>
                          {state.isValidatingProposal || isPromptOpen ? (
                            <Loader text="Preparing&hellip;" />
                          ) : isSubmitting ? (
                            <Loader text="Processing&hellip;" />
                          ) : (
                            'Submit Proposal'
                          )}
                        </button>

                        {/* ETHERSCAN URL */}
                        {etherscanURL && (
                          <p className="text-center">
                            <small>
                              <a
                                href={etherscanURL}
                                rel="noopener noreferrer"
                                target="_blank">
                                {isComplete
                                  ? '(view transaction)'
                                  : ' (view progress)'}
                              </a>
                            </small>
                          </p>
                        )}

                        {isComplete && (
                          <>
                            <p className="org-input-row-help--alert">
                              <small>Membership proposal submitted!</small>
                            </p>
                            <p className="org-input-row-help--alert">
                              <small>
                                Nagivate to the "members" tab in admin to see
                                the member, proposal id: {proposalId}
                              </small>
                            </p>
                          </>
                        )}

                        {error && (error as MetaMaskRPCError).code !== 4001 && (
                          <div className="text-center">
                            <ErrorMessageWithDetails
                              error={error}
                              renderText="Something went wrong while submitting your proposal"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  />
                </FadeIn>
              )}
            </div>
          </div>
        </div>
      </FadeIn>
    </Wrap>
  );
}
