import {useCallback, useEffect, useState} from 'react';

import {FetchStatus} from '../../util/enums';
import {SnapshotGovernanceProposalResponse} from './types';
import {useAbortController} from '../../hooks/useAbortController';
import {useBackendURL, useCounter} from '../../hooks';

type UseSnapshotGovernanceProposalsReturn = {
  snapshotGovernanceProposals: SnapshotGovernanceProposalResponse[];
  snapshotGovernanceProposalsStatus: FetchStatus;
  refetchSnapshotGovernanceProposals: () => void;
};

/**
 * useSnapshotGovernanceProposals
 */
export default function useSnapshotGovernanceProposals(): UseSnapshotGovernanceProposalsReturn {
  /**
   * State
   */

  const [snapshotGovernanceProposals, setSnapshotGovernanceProposals] =
    useState<SnapshotGovernanceProposalResponse[]>([]);
  const [
    snapshotGovernanceProposalsStatus,
    setSnapshotGovernanceProposalsStatus,
  ] = useState<FetchStatus>(FetchStatus.STANDBY);

  /**
   * Our hooks
   */

  const [
    refetchSnapshotGovernanceProposalsCount,
    dispatchRefetchSnapshotGovernanceProposals,
  ] = useCounter();
  const {abortController, isMountedRef} = useAbortController();
  const backendURL = useBackendURL();

  /**
   * Cached callbacks
   */

  const getProposalsCached = useCallback(getProposals, [
    abortController,
    backendURL,
  ]);

  const handleGetProposalsCached = useCallback(handleGetProposals, [
    getProposalsCached,
    isMountedRef,
  ]);

  /**
   * Effects
   */

  /**
   * Fetch proposal details from the Snapshot API by the `token` key we are using,
   * which is our Moloch address.
   */
  useEffect(() => {
    if (!abortController) return;

    handleGetProposalsCached();
  }, [
    abortController,
    handleGetProposalsCached,
    refetchSnapshotGovernanceProposalsCount,
  ]);

  /**
   * Functions
   */

  async function handleGetProposals() {
    try {
      setSnapshotGovernanceProposalsStatus(FetchStatus.PENDING);

      const proposals = await getProposalsCached();

      if (!proposals || !proposals.length) {
        setSnapshotGovernanceProposals([]);
        setSnapshotGovernanceProposalsStatus(FetchStatus.FULFILLED);

        return;
      }

      setSnapshotGovernanceProposals(proposals);
      setSnapshotGovernanceProposalsStatus(FetchStatus.FULFILLED);
    } catch (error) {
      // Don't try to set state if not mounted.
      if (!isMountedRef.current) return;

      setSnapshotGovernanceProposals([]);
      setSnapshotGovernanceProposalsStatus(FetchStatus.REJECTED);
    }
  }

  async function getProposals(): Promise<SnapshotGovernanceProposalResponse[]> {
    try {
      const response = await fetch(`${backendURL}/governance`, {
        signal: abortController && abortController.signal,
      });

      if (!response.ok) {
        throw new Error(
          'Something went wrong while getting the governance proposals.'
        );
      }

      const proposals: SnapshotGovernanceProposalResponse[] =
        await response.json();

      return proposals;
    } catch (error) {
      throw error;
    }
  }

  return {
    snapshotGovernanceProposals,
    snapshotGovernanceProposalsStatus,
    refetchSnapshotGovernanceProposals: () => {
      dispatchRefetchSnapshotGovernanceProposals({type: 'increment'});
    },
  };
}
