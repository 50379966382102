import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {FetchStatus} from '../../util/enums';
import {getSnapshotIgnoreList} from './helpers';
import {SnapshotGovernanceProposalResponse} from './types';
import {StoreState} from '../../util/types';
import {useAbortController} from '../../hooks/useAbortController';
import {useBackendURL, useCounter} from '../../hooks';

type SnapshotProposalReturn = {
  snapshotProposal: SnapshotGovernanceProposalResponse | undefined;
  snapshotProposalStatus: FetchStatus;
  refetchSnapshotProposal: () => void;
};

/**
 * useSnapshotGovernanceProposal
 */
export default function useSnapshotGovernanceProposal(
  proposalId: string
): SnapshotProposalReturn {
  /**
   * State
   */

  const [snapshotProposal, setSnapshotProposal] =
    useState<SnapshotGovernanceProposalResponse>();
  const [snapshotProposalStatus, setSnapshotProposalStatus] =
    useState<FetchStatus>(FetchStatus.STANDBY);

  const [ignoreList, setIgnoreList] = useState<string[]>([]);
  const [ignoreListStatus, setIgnoreListStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );

  /**
   * Selectors
   */

  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  /**
   * Our hooks
   */

  const [refetchProposalCount, dispatchRefetchProposal] = useCounter();
  const {abortController, isMountedRef} = useAbortController();
  const backendURL = useBackendURL();

  /**
   * Cached callbacks
   */

  const getProposalCached = useCallback(getProposal, [
    abortController,
    backendURL,
    proposalId,
  ]);

  const handleGetProposalCached = useCallback(handleGetProposal, [
    getProposalCached,
    ignoreList,
    ignoreListStatus,
    isMountedRef,
    proposalId,
    refetchProposalCount,
  ]);

  /**
   * Effects
   */

  /**
   * Fetch proposal details from the Snapshot API by the `token` key we are using,
   * which is our Moloch address.
   */
  useEffect(() => {
    handleGetProposalCached();
  }, [handleGetProposalCached]);

  // Get ignore list
  useEffect(() => {
    if (!orgInternalName) return;

    setIgnoreListStatus(FetchStatus.PENDING);

    getSnapshotIgnoreList(orgInternalName)
      .then(setIgnoreList)
      .then(() => setIgnoreListStatus(FetchStatus.FULFILLED))
      .catch(() => {
        setIgnoreList([]);
        setIgnoreListStatus(FetchStatus.REJECTED);
      });
  }, [orgInternalName]);

  /**
   * Functions
   */

  async function handleGetProposal() {
    try {
      if (
        ignoreListStatus === FetchStatus.STANDBY ||
        ignoreListStatus === FetchStatus.PENDING
      ) {
        return;
      }

      const isRefetch = refetchProposalCount > 0;

      if (ignoreList.includes(proposalId)) {
        setSnapshotProposalStatus(FetchStatus.FULFILLED);

        return;
      }

      /**
       * If we're refetching don't reset the fetch status, do it silently, in case consumer
       * components rely on it to set their loading states (it won't show their loading UI, again).
       */
      !isRefetch && setSnapshotProposalStatus(FetchStatus.PENDING);

      const proposal = await getProposalCached();

      if (!proposal) {
        setSnapshotProposalStatus(FetchStatus.FULFILLED);

        return;
      }

      setSnapshotProposal(proposal);
      setSnapshotProposalStatus(FetchStatus.FULFILLED);
    } catch (error) {
      // Don't try to set state if not mounted.
      if (!isMountedRef.current) return;

      setSnapshotProposalStatus(FetchStatus.REJECTED);
    }
  }

  async function getProposal(): Promise<
    SnapshotGovernanceProposalResponse | undefined
  > {
    try {
      const response = await fetch(`${backendURL}/governance/${proposalId}`, {
        signal: abortController && abortController.signal,
      });

      if (!response.ok) {
        throw new Error(
          'Something went wrong while getting the governance proposal details.'
        );
      }

      const proposal: SnapshotGovernanceProposalResponse =
        await response.json();

      return proposal;
    } catch (error) {
      throw error;
    }
  }

  return {
    snapshotProposal,
    snapshotProposalStatus,
    refetchSnapshotProposal: () => {
      dispatchRefetchProposal({type: 'increment'});
    },
  };
}
