import l from '../../assets/scss/modules/logo.module.scss';

interface LogoUniversityVirginiaProps {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoUniversityVirginia(
  props: LogoUniversityVirginiaProps
) {
  const classNameSizes = {
    daoV2: props.size ? `${l[`daoV2--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.daoV2} ${classNameSizes.daoV2} org-logo`}>
      Dorm-UVA
    </div>
  );
}
