import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Markdown from 'markdown-to-jsx';

import {useIsAdmin} from '../../../hooks';
import {FetchStatus} from '../../../util/enums';
import {
  formatEthereumAddress,
  getOrgText,
  trimText,
} from '../../../util/helpers';
import {SnapshotProposalSubType} from '../../../components/snapshot/enums';
import {StoreState} from '../../../util/types';

import FadeIn from '../../../components/common/FadeIn';
import HiThereLoader from '../../../components/feedback/HiThereLoader';
import SnapshotVoting from '../../../components/snapshot/SnapshotVoting';
import useSnapshotGovernanceProposal from '../../../components/snapshot/useSnapshotGovernanceProposal';
import VoteIndicatorBadge from '../../../components/ui/VoteIndicatorBadge';
import Wrap from '../../../components/layout/Wrap';

import s from '../../../assets/scss/modules/proposaldetails.module.scss';

/**
 * GovernanceProposal
 */
export default function GovernanceProposal() {
  /**
   * Selectors
   */

  const isMember = useSelector((s: StoreState) =>
    s.connectedMember && s.connectedMember.isMemberActive ? true : false
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  /**
   * External hooks
   */

  const params = useParams();
  const history = useHistory();

  /**
   * Page ID
   */

  const {id = ''} = params as Record<string, any>;

  /**
   * Our hooks
   */

  const {snapshotProposal, snapshotProposalStatus, refetchSnapshotProposal} =
    useSnapshotGovernanceProposal(id);
  const isAdmin = useIsAdmin();

  /**
   * Variables
   */

  const proposal = snapshotProposal && snapshotProposal.snapshotHubProposal;
  const votes = snapshotProposal && snapshotProposal.snapshotHubVotes;
  // @note If no subType was detected (early Snapshot proposal, then use "Governance").
  const subType =
    (snapshotProposal &&
      snapshotProposal.snapshotHubProposal.msg.payload.metadata.subType) ||
    SnapshotProposalSubType.Governance;
  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');

  /**
   * Effects
   */

  useEffect(() => {
    // Go to 404 not found, if no proposal was found.
    if (!snapshotProposal && snapshotProposalStatus === FetchStatus.FULFILLED) {
      history.push('/404');
      return;
    }
  }, [snapshotProposal, snapshotProposalStatus, history]);

  /**
   * Functions
   */

  function goBack(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    history.push('/governance-proposals');
  }

  function renderSubTypeText(subType: SnapshotProposalSubType) {
    const governance = (
      <span>
        If the &ldquo;yes&rdquo; votes are more than 50% then the vote will
        pass.
      </span>
    );

    switch (subType) {
      case SnapshotProposalSubType.General:
        return (
          <span>
            If there are more &ldquo;yes&rdquo; votes than &ldquo;no&rdquo;
            votes then the vote will pass.
          </span>
        );
      case SnapshotProposalSubType.Governance:
        return governance;
      // @note If no subType was detected (early Snapshot proposal, then use "Governance").
      default:
        return governance;
    }
  }

  function renderProposalMetadata() {
    if (!proposal) return;

    const title = `${orgName} Governance Proposal | ${
      proposal.msg.payload.name || '\u2026'
    }`;
    const description = trimText(proposal.msg.payload.body, {
      characters: 200,
      word: true,
    });

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
    );
  }

  // Return loading if the proposal is not done fetching.
  if (
    snapshotProposalStatus === FetchStatus.STANDBY ||
    snapshotProposalStatus === FetchStatus.PENDING
  ) {
    return (
      <>
        <Helmet>
          <title>{`${orgName} - Governance Proposal`}</title>
          <meta
            name="description"
            content={`Governance proposal for ${orgName}`}
          />
        </Helmet>

        <Wrap className={'section-wrapper'}>
          <FadeIn>
            <div style={{width: '3rem', margin: '0 auto'}}>
              <HiThereLoader />
            </div>
            <p className="text-center">Loading&hellip;</p>
          </FadeIn>
        </Wrap>
      </>
    );
  }

  // Return message if not authorised
  if (
    snapshotProposal &&
    snapshotProposalStatus === FetchStatus.FULFILLED &&
    snapshotProposal.snapshotHubProposal.msg.payload.metadata.private &&
    !isMember &&
    !isAdmin
  ) {
    return (
      <>
        <Helmet>
          <title>{`${orgName} - Governance Proposal`}</title>
          <meta
            name="description"
            content={`Governance proposal for ${orgName}`}
          />
        </Helmet>

        <Wrap className={'section-wrapper'}>
          <FadeIn>
            <p className="color-yellow text-center" style={{marginTop: '4rem'}}>
              Either you are not a member, or your membership is not active.
            </p>
          </FadeIn>
        </Wrap>
      </>
    );
  }

  // Return details
  return (
    <>
      {renderProposalMetadata()}

      <Wrap className={'section-wrapper'}>
        <FadeIn>
          <div className="titlebar">
            <h2 className="titlebar__title org-titlebar__title">
              {(proposal && proposal.msg.payload.name) || '\u2026'}
            </h2>
            <button
              className="titlebar__action org-titlebar__action"
              onClick={goBack}>
              &larr;
              <span className="titlebar__action-text">View all</span>
            </button>
          </div>

          <div>
            <section
              className={`${s['proposal-wrapper-alt']} org-proposal-wrapper-alt`}>
              {proposal && votes && (
                <>
                  <section className={s['proposal-content']}>
                    <h3
                      className={`${s['proposal-content-title']} org-proposal-content-title`}>
                      Proposal Details
                    </h3>

                    {/* DESCRIPTION */}
                    <Markdown>{proposal.msg.payload.body}</Markdown>
                  </section>

                  {/* RIGHT SIDE */}
                  <aside
                    className={`${s['proposal-details']} org-proposal-details`}>
                    <div
                      className={`${s['proposal-actions-container']} org-proposal-actions-container`}>
                      <div className={`org-proposal-actions-wrapper`}>
                        {/* VOTE INDICATOR BADGE */}
                        {subType === SnapshotProposalSubType.Governance && (
                          <VoteIndicatorBadge subType={subType} />
                        )}

                        <div className={s['proposer-info']}>
                          <div
                            className={`${s['proposer-info__title']} org-proposer-info__title`}>
                            Proposer
                          </div>
                          <div className={s['proposer-info__address']}>
                            {
                              proposal.address
                                ? formatEthereumAddress(proposal.address, 10)
                                : '\u00A0\u2013' /* nbsp ndash */
                            }
                          </div>
                        </div>

                        {/* VOTING STATUS & BUTTONS */}
                        <SnapshotVoting
                          onRefetchVotes={refetchSnapshotProposal}
                          snapshotProposal={proposal}
                          votes={votes}
                        />
                      </div>

                      {/* CATEGORY (SUBTYPE) */}
                      <div>
                        <div className={`${s['info-item']} org-info-item`}>
                          <span>
                            {/* If no subType was detected (early Snapshot proposal, then use "Governance"). */}
                            {`Category: ${subType}`}
                          </span>
                          {renderSubTypeText(subType)}
                        </div>
                      </div>
                    </div>
                  </aside>
                </>
              )}
            </section>
          </div>
        </FadeIn>
      </Wrap>
    </>
  );
}
