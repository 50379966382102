import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {StoreState} from '../../util/types';
import {InternalNamesToMap} from '../../util/orgDomainMappings';
import {GOVERNANCE_PASS_PERCENTAGE} from './helpers';
import {SnapshotProposal, SnapshotCombinedVotesResponse} from './types';
import {
  SnapshotProposalSubType,
  SnapshotProposalType,
  SnapshotVote,
} from './enums';
import ProposalPeriod from '../ui/ProposalPeriod';
import SquareRootVotingBar from '../feedback/SquareRootVotingBar';
import DropSVG from '../../assets/svg/DropSVG';
import StopwatchSVG from '../../assets/svg/StopwatchSVG';
import CircleCheckSVG from '../../assets/svg/CircleCheckSVG';
import CircleCrossSVG from '../../assets/svg/CircleCrossSVG';

import vc from '../../assets/scss/modules/votingcard.module.scss';

type SnapshotVotingStatusProps = {
  onVoteEnded?: (didPass: boolean, proposalId: string) => void;
  /**
   * Controls the view of the vote status bar of if a vote has ended and passed.
   * Defaults to `false`.
   * e.g. For a cleaner UI the snapshot investment proposals do not show, but for governance do.
   */
  hideVotesForPassedVote?: boolean;
  snapshotProposal?: SnapshotProposal;
  subType?: SnapshotProposalSubType;
  votes?: SnapshotCombinedVotesResponse;
};

const DEFAULT_VOTE_DATA_ITEM = 0;

export default function SnapshotVotingStatus(props: SnapshotVotingStatusProps) {
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const {onVoteEnded, snapshotProposal, subType, votes} = props;
  // Set a default for `hideVotesForPassedVote`
  const {hideVotesForPassedVote = false} = props;

  const votingEndSeconds = getVoteEndSeconds();
  const votingStartSeconds = getVoteStartSeconds();

  /**
   * State
   */

  const [hasVotingEnded, setHasVotingEnded] = useState<boolean>(
    Math.ceil(Date.now() / 1000) > votingEndSeconds
  );

  /**
   * Variables
   */

  const votingEndedAndPassed = getDidVotePass();

  // Actively check if voting has ended
  useEffect(() => {
    if (!snapshotProposal) return;
    if (!votes) return;
    // If the value is already `true`, then exit.
    if (hasVotingEnded) return;

    // Check if voting has ended every 1 second
    const intervalID = setInterval(() => {
      const hasEndedCheck = Math.ceil(Date.now() / 1000) > votingEndSeconds;

      if (!hasEndedCheck) return;

      setHasVotingEnded(hasEndedCheck);

      // Run the onVoteEnded callback
      onVoteEnded &&
        onVoteEnded(
          votingEndedAndPassed || false,
          snapshotProposal.authorIpfsHash
        );
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, [
    hasVotingEnded,
    onVoteEnded,
    snapshotProposal,
    votes,
    votingEndedAndPassed,
    votingEndSeconds,
  ]);

  /**
   * Functions
   */

  function getDidVotePass(): boolean | undefined {
    if (!snapshotProposal) return;
    if (!votes) return;
    if (!hasVotingEnded) return;

    const type = snapshotProposal.msg.payload.metadata.type;
    const yesGreaterThanNo =
      votes.votesByChoice[SnapshotVote.Yes].voteShares >
      votes.votesByChoice[SnapshotVote.No].voteShares;
    const yesGreaterThanTheshold =
      votes.votesByChoice[SnapshotVote.Yes].votePercent >
      GOVERNANCE_PASS_PERCENTAGE;

    switch (getSubType()) {
      case SnapshotProposalSubType.Governance:
        return yesGreaterThanTheshold;
      case SnapshotProposalSubType.General:
        return yesGreaterThanNo;
      /**
       * For the original few proposals which were Governance votes only.
       * e.g. LAO I's GIP002.
       */
      case undefined:
        return type === SnapshotProposalType.Governance || type === undefined
          ? yesGreaterThanTheshold
          : yesGreaterThanNo;
      default:
        return yesGreaterThanNo;
    }
  }

  /**
   * getSubType
   *
   * Safely gets the voting `subType`.
   *
   * @returns {string | undefined}
   */
  function getSubType(): string | undefined {
    if (snapshotProposal) {
      return snapshotProposal.msg.payload.metadata.subType;
    }

    return subType || '';
  }

  /**
   * getVoteEndSeconds
   *
   * Safely gets the voting end seconds.
   *
   * @returns {number}
   */
  function getVoteEndSeconds(): number {
    // If we're reading from a Snapshot Hub proposal response
    if (snapshotProposal) {
      return snapshotProposal.msg.payload.end;
    }

    return 0;
  }

  /**
   * getVoteStartSeconds
   *
   * Safely gets the voting start seconds.
   *
   * @returns {number}
   */
  function getVoteStartSeconds(): number {
    // If we're reading from a Snapshot Hub proposal response
    if (snapshotProposal) {
      return snapshotProposal.msg.payload.start;
    }

    return 0;
  }

  function renderCountdownIcon(): JSX.Element {
    switch (orgInternalName) {
      case InternalNamesToMap.liquidity:
        return <DropSVG />;
      case InternalNamesToMap.metaverse:
        return votingEndedAndPassed ? <CircleCheckSVG /> : <CircleCrossSVG />;
      case InternalNamesToMap.nft:
      case InternalNamesToMap.thelao:
      default:
        return <StopwatchSVG />;
    }
  }

  return (
    <>
      <div className={`${vc['row-w-icon']} ${vc['countdown']} org-countdown`}>
        {renderCountdownIcon()}

        {/* CLOCK WHILE IN VOTING */}
        {snapshotProposal && hasVotingEnded === false && (
          <ProposalPeriod
            proposalPeriodInfo={{
              // convert to relative Date from UTC so it works with the component
              startPeriod: new Date(votingStartSeconds * 1000),
              // convert to relative Date from UTC so it works with the component
              endPeriod: new Date(votingEndSeconds * 1000),
              // No grace period needed in Snapshot voting
              gracePeriod: null,
              isVotingReady: hasVotingEnded === false,
            }}
            simpleDisplay
          />
        )}

        {/* STATUS WHEN NO SNAPSHOT HUB PROPOSAL */}
        {!snapshotProposal && votingEndedAndPassed === undefined && (
          <span className={`${vc['status']} org-status org-status--pending`}>
            Pending Nomination
          </span>
        )}

        {/* STATUSES ON VOTING ENDED */}

        {votingEndedAndPassed && (
          <span className={`${vc['status']} org-status org-status--success`}>
            Approved
          </span>
        )}

        {votingEndedAndPassed === false && (
          <span
            className={`${vc['status']} ${vc['status--failed']} org-status org-status--failed`}>
            Ended
          </span>
        )}
      </div>

      {/* VOTES */}
      {hideVotesForPassedVote && votingEndedAndPassed ? null : (
        <SquareRootVotingBar
          yes={
            votes
              ? votes.votesByChoice[SnapshotVote.Yes]?.votePercent?.toString()
              : DEFAULT_VOTE_DATA_ITEM.toString()
          }
          no={
            votes
              ? votes.votesByChoice[SnapshotVote.No]?.votePercent?.toString()
              : DEFAULT_VOTE_DATA_ITEM.toString()
          }
          votingExpired={hasVotingEnded === true}
          yesVotes={
            votes
              ? votes.votesByChoice[SnapshotVote.Yes]?.voteShares
              : DEFAULT_VOTE_DATA_ITEM
          }
          noVotes={
            votes
              ? votes.votesByChoice[SnapshotVote.No]?.voteShares
              : DEFAULT_VOTE_DATA_ITEM
          }
          // If there's a Snapshot proposal from the Hub, then we count it as sponsored.
          sponsored={
            snapshotProposal !== undefined && snapshotProposal !== null
          }
        />
      )}
    </>
  );
}
