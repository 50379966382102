import {useEffect, useState} from 'react';

import {
  getBrowserVisibilityProp,
  getIsDocumentHidden,
  BrowserVisibilityType,
} from '../util/pageVisibility';

export default function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden());

  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  useEffect(() => {
    const visibilityChange: BrowserVisibilityType = getBrowserVisibilityProp();

    visibilityChange &&
      document.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      visibilityChange &&
        document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isVisible;
}
