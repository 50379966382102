import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {
  SnapshotView,
  SnapshotProposalSubType,
  SnapshotProposalType,
  SnapshotVote,
} from '../../../components/snapshot/enums';
import {GOVERNANCE_PASS_PERCENTAGE} from '../../../components/snapshot/helpers';
import {SnapshotGovernanceProposalResponse} from '../../../components/snapshot/types';
import useSnapshotGovernanceProposals from '../../../components/snapshot/useSnapshotGovernanceProposals';
import SnapshotCountdown from './SnapshotCountdown';

export default function GovernanceSnapshots() {
  const {snapshotGovernanceProposals} = useSnapshotGovernanceProposals();

  const [inProgressSnapshots, setInProgressSnapshots] = useState<
    SnapshotGovernanceProposalResponse[]
  >([]);

  const [completedSnapshots, setCompletedSnapshots] = useState<
    SnapshotGovernanceProposalResponse[]
  >([]);

  const sortSnapshotStatusCallback = useCallback(sortSnapshotStatus, [
    snapshotGovernanceProposals,
  ]);

  useEffect(() => {
    sortSnapshotStatusCallback();
  }, [sortSnapshotStatusCallback]);

  function handleRefresh() {
    sortSnapshotStatusCallback();
  }

  function sortSnapshotStatus() {
    const currentTimestamp = Math.ceil(Date.now() / 1000);

    const inProgress: SnapshotGovernanceProposalResponse[] = [];
    const completed: SnapshotGovernanceProposalResponse[] = [];

    snapshotGovernanceProposals.forEach(
      (s: SnapshotGovernanceProposalResponse) => {
        if (s.snapshotHubProposal) {
          const {
            msg: {
              payload: {end},
            },
          } = s.snapshotHubProposal;

          if (end < currentTimestamp) {
            completed.push(s);
          } else {
            inProgress.push(s);
          }
        }
      }
    );
    setInProgressSnapshots(inProgress);
    setCompletedSnapshots(completed);
  }

  /**
   * getSubType
   *
   * Safely gets the voting `subType`.
   *
   * @returns {string | undefined}
   */
  function getSubType(
    snapshotProposal: SnapshotGovernanceProposalResponse
  ): string | undefined {
    const {
      snapshotHubProposal: {
        msg: {
          payload: {
            metadata: {subType},
          },
        },
      },
    } = snapshotProposal;

    return subType || '';
  }

  function getDidVotePass(
    snapshotGovernance: SnapshotGovernanceProposalResponse
  ): boolean | undefined {
    const {snapshotHubProposal, snapshotHubVotes} = snapshotGovernance;

    const type = snapshotHubProposal.msg.payload.metadata.type;
    const yesGreaterThanNo =
      snapshotHubVotes.votesByChoice[SnapshotVote.Yes].voteShares >
      snapshotHubVotes.votesByChoice[SnapshotVote.No].voteShares;
    const yesGreaterThanTheshold =
      snapshotHubVotes.votesByChoice[SnapshotVote.Yes].votePercent >
      GOVERNANCE_PASS_PERCENTAGE;

    switch (getSubType(snapshotGovernance)) {
      case SnapshotProposalSubType.Governance:
        return yesGreaterThanTheshold;
      case SnapshotProposalSubType.General:
        return yesGreaterThanNo;
      /**
       * For the original few proposals which were Governance votes only.
       * e.g. LAO I's GIP002.
       */
      case undefined:
        return type === SnapshotProposalType.Governance
          ? yesGreaterThanTheshold
          : yesGreaterThanNo;
      default:
        return yesGreaterThanNo;
    }
  }

  function renderYesNoPercent(s: SnapshotGovernanceProposalResponse) {
    // yesPercent: number, noPercent: number
    const didPass = getDidVotePass(s);
    // yesPercent > noPercent || (yesPercent !== 0 && noPercent !== 0);

    return (
      <span role="img" aria-label="Status">
        {didPass ? '✅' : '⛔️'}
      </span>
    );
  }

  function renderSnapshotViews(
    snapshots: SnapshotGovernanceProposalResponse[],
    snapshotView: SnapshotView
  ) {
    return snapshots.map((s, i) => {
      const currentTimestamp = Math.ceil(Date.now() / 1000);

      return (
        <tr key={`${snapshotView}-${i}`}>
          <td>{s.snapshotHubProposal.msg.payload.name}</td>
          <td>{s.snapshotHubProposal.address}</td>
          <td>
            {s.snapshotHubVotes &&
              s.snapshotHubVotes.votesByChoice.Yes.votePercent.toFixed(2)}
          </td>
          <td>
            {s.snapshotHubVotes &&
              s.snapshotHubVotes.votesByChoice.No.votePercent.toFixed(2)}
          </td>

          {s.snapshotHubProposal &&
            s.snapshotHubProposal.msg.payload.end > currentTimestamp && (
              <td>
                <SnapshotCountdown
                  endTimestamp={
                    s.snapshotHubProposal &&
                    s.snapshotHubProposal.msg.payload.end
                  }
                />
              </td>
            )}

          {s.snapshotHubProposal &&
            s.snapshotHubProposal.msg.payload.end < currentTimestamp && (
              <td>{s.snapshotHubVotes && renderYesNoPercent(s)}</td>
            )}
          <td>
            <Link
              to={`governance-proposals/${s.snapshotHubProposal.authorIpfsHash}`}>
              View
            </Link>
          </td>
        </tr>
      );
    });
  }

  return (
    <div>
      <button onClick={handleRefresh}>Refresh</button>

      <h2>In Progress</h2>
      {inProgressSnapshots.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Address To Fund</th>
                <th>Yes Votes (%)</th>
                <th>No Votes (%)</th>
                <th>End Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {renderSnapshotViews(
                inProgressSnapshots,
                SnapshotView.IN_PROGRESS
              )}
            </tbody>
          </table>
        </>
      ) : (
        'No governance proposals in progress'
      )}
      <h2>Completed</h2>
      {completedSnapshots.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Address To Fund</th>
                <th>Yes Votes (%)</th>
                <th>No Votes (%)</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {renderSnapshotViews(completedSnapshots, SnapshotView.COMPLETED)}
            </tbody>
          </table>
        </>
      ) : (
        'No completed governance proposals'
      )}
    </div>
  );
}
