import React, {useState, useContext, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {
  formatDecimal,
  formatNumber,
  stripTrailingZeroes,
} from '../../util/helpers';
import {FetchStatus} from '../../util/enums';
import {SaleModalContext} from './SaleModal';
import {StoreState} from '../../util/types';
import EmojiSlider from '../../components/feedback/EmojiSlider';

type PurchaseAmountChildrenProps = {
  amountETH: string;
  amountETHInWEI: string;
  amountUnits: string;
  amountUnitsFormatted: string;
  amountUSD: string;
  canPurchase: boolean;
  isFullySubscribed: boolean;
  EmojiSliderRendered: JSX.Element;
  maxSubscriptionAllowed: number;
  percentAmount: string;
  readyStatus: FetchStatus;
};

type PurchaseAmountProps = {
  render: (props: PurchaseAmountChildrenProps) => JSX.Element;
};

/**
 * PurchaseAmount
 *
 * Returns a rendered component via a render prop.
 * PurchaseAmountChildrenProps are passed to the render prop.
 *
 * @param {PurchaseAmountProps} props
 * @returns {FunctionComponent}
 */
export default function PurchaseAmount(props: PurchaseAmountProps) {
  /**
   * State
   */

  const [amountETH, setAmountETH] = useState<string>('0');
  const [amountETHInWEI, setAmountETHInWEI] = useState<string>('0');
  const [amountUSD, setAmountUSD] = useState<string>('0');
  const [canPurchase, setCanPurchase] = useState<boolean>(false);
  const [percentAmount, setPercentAmount] = useState<string>('0');
  const [amountUnits, setAmountUnits] = useState<string>('0');
  const [maxSubscriptionAllowed, setMaxSubscriptionAllowed] =
    useState<number>(0);
  const [readyStatus, setReadyStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );

  /**
   * Context
   */

  const {
    buyerAccountInfo,
    latestETHPrice,
    maxNewMembersLimitReached,
    saleInfo: {saleInfo, saleInfoStatus},
  } = useContext(SaleModalContext);

  /**
   * Selectors
   */

  const web3Instance = useSelector(
    (state: StoreState) => state.blockchain.web3Instance
  );

  /**
   * Cached callbacks
   */

  const getMaxSubscriptionCached = useCallback(getMaxSubscription, [
    buyerAccountInfo,
    saleInfo.amountSharesSold,
    saleInfo.isFullySubscribed,
    saleInfo.maxShares,
    saleInfo.totalShares,
  ]);

  const handleSliderChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;
      const calculatePercent: number =
        (Number(value) / saleInfo.totalShares) * 100;

      setAmountUnits(value);
      setPercentAmount(stripTrailingZeroes(formatDecimal(calculatePercent)));
    },
    [saleInfo.totalShares]
  );

  const EmojiSliderComponentCached = useCallback(EmojiSliderComponent, [
    amountUnits,
    handleSliderChange,
    maxSubscriptionAllowed,
    saleInfo.minShares,
    saleInfo.sharesPerChunk,
  ]);

  /**
   * Variables
   */

  const {render} = props;
  const {REACT_APP_ENVIRONMENT} = process.env;
  const isProd = REACT_APP_ENVIRONMENT === 'production';

  /**
   * Effects
   */

  // Set `maxSubscriptionAllowed`
  useEffect(() => {
    setMaxSubscriptionAllowed(getMaxSubscriptionCached());
  }, [getMaxSubscriptionCached]);

  // Check if can purchase shares
  useEffect(() => {
    const canPurchaseShares = maxSubscriptionAllowed > 0 ? true : false;
    const notFullySubscribed = saleInfo.isFullySubscribed === false;

    setCanPurchase(canPurchaseShares && notFullySubscribed);
  }, [maxSubscriptionAllowed, saleInfo]);

  // Set initial units amount once `saleInfo` is fetched
  useEffect(() => {
    setAmountUnits(saleInfo.minShares.toString());
  }, [saleInfo.minShares]);

  // Set initial percent amount once `saleInfo` is fetched
  useEffect(() => {
    const calculatePercent: number =
      (Number(saleInfo.minShares) / saleInfo.totalShares) * 100;

    setPercentAmount(stripTrailingZeroes(formatDecimal(calculatePercent)));
  }, [saleInfo.minShares, saleInfo.totalShares]);

  // Set ETH, ETH in WEI, and USD
  useEffect(() => {
    if (!web3Instance) return;

    const sharesDividedByChunk: number =
      Number(amountUnits) / saleInfo.sharesPerChunk || 0;

    const amountETHToSet: string = (
      saleInfo.ethPerChunk * sharesDividedByChunk
    ).toString();

    setAmountETH(amountETHToSet);
    setAmountETHInWEI(web3Instance.utils.toWei(amountETHToSet));

    // e.g. 2 x $195.56 x 120 ETH per share = 46934.4
    setAmountUSD(
      (
        sharesDividedByChunk *
        (latestETHPrice || 0) *
        saleInfo.ethPerChunk
      ).toFixed(2)
    );
  }, [
    amountUnits,
    isProd,
    latestETHPrice,
    percentAmount,
    saleInfo.ethPerChunk,
    saleInfo.ethPerPercent,
    saleInfo.sharesPerChunk,
    web3Instance,
  ]);

  // Determine `readyStatus` for render props usage.
  // We look at the context values which are async to determine if we're ready.
  useEffect(() => {
    const contextInfoArrived =
      saleInfoStatus !== FetchStatus.STANDBY &&
      saleInfoStatus !== FetchStatus.PENDING &&
      buyerAccountInfo !== undefined &&
      latestETHPrice !== undefined &&
      maxNewMembersLimitReached !== undefined &&
      saleInfo.ethPerPercent > 0;

    contextInfoArrived
      ? setReadyStatus(FetchStatus.FULFILLED)
      : setReadyStatus(FetchStatus.PENDING);
  }, [
    buyerAccountInfo,
    canPurchase,
    latestETHPrice,
    maxNewMembersLimitReached,
    saleInfo,
    saleInfoStatus,
  ]);

  /**
   * Functions
   */

  function EmojiSliderComponent() {
    return (
      <EmojiSlider
        handleChange={handleSliderChange}
        max={maxSubscriptionAllowed.toString()}
        min={saleInfo.minShares.toString()}
        step={saleInfo.sharesPerChunk.toString()}
        value={amountUnits}
      />
    );
  }

  /**
   * getMaxSubscription
   *
   * Get the maximum number of shares a potential member can purchase.
   *
   * @note We do not check the user's balance of ETH. The wallet will alert them.
   *
   * @returns {number}
   */
  function getMaxSubscription(): number {
    const {sharesPurchased = 0} = buyerAccountInfo || {};

    const totalSharesRemaining = saleInfo.isFullySubscribed
      ? 0
      : saleInfo.totalShares - saleInfo.amountSharesSold;

    // Max subscription per user (e.g. 900,000) minus the shares user already purchased (e.g. 200,000).
    const buyerSharesPurchaseLimit = saleInfo.maxShares - sharesPurchased;

    const sharesPurchaseMax =
      // Check if there's enough shares remaining to accommodate the amount the user can purchase.
      totalSharesRemaining >= buyerSharesPurchaseLimit
        ? buyerSharesPurchaseLimit
        : // Check if there's less shares remaining to purchase than the user is able to buy.
        totalSharesRemaining < buyerSharesPurchaseLimit
        ? totalSharesRemaining
        : // Fall back to 0
          0;

    return sharesPurchaseMax;
  }

  return render({
    amountETH,
    amountETHInWEI,
    amountUnits,
    amountUnitsFormatted: formatNumber(amountUnits),
    amountUSD,
    canPurchase,
    EmojiSliderRendered: EmojiSliderComponentCached(),
    isFullySubscribed: saleInfo.isFullySubscribed,
    maxSubscriptionAllowed,
    percentAmount,
    readyStatus,
  });
}
