import React from 'react';
import {useSelector} from 'react-redux';

import {getOrgText} from '../../../util/helpers';
import {FetchStatus} from '../../../util/enums';
import {MetaMaskRPCError, StoreState} from '../../../util/types';
import {InternalNamesToMap} from '../../../util/orgDomainMappings';
import {useIsPaymentRequestedOK} from '../../../hooks/useIsPaymentRequestedOK';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import Loader from '../../../components/feedback/Loader';
import ProposalActionWhyDisabled from '../ProposalDisabledReason';
import SponsorProposal from '../../../components/contract/SponsorProposal';
import DropSVG from '../../../assets/svg/DropSVG';
import StopwatchSVG from '../../../assets/svg/StopwatchSVG';

import s from '../../../assets/scss/modules/votingcard.module.scss';
import v from '../../../assets/scss/modules/voting.module.scss';

type Props = {
  molochProposalId: number | undefined;
  paymentRequestedWEI: string;
};

/**
 * MolochSponsorInvestmentProposal
 *
 * Provides a sponsorship UI for projects.
 *
 * 1) Will run pre-sponsorship validation checks (e.g. enough money in GUILD).
 * 2) Open a modal for members to set project investment terms which
 *    impact the Convertible Note generation for projects.
 * 3) Submit tx to the blockchain.
 * 4) Handoff to the API (while tx processing) where we listen for the tx
 *    to complete and store data about it,
 * 5) Update the local Redux store with new data for `proposalDetails`.
 *
 * @param props
 */
export default function MolochSponsorInvestmentProposal(props: Props) {
  const {molochProposalId, paymentRequestedWEI} = props;

  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  /**
   * Use these as the moloch proposal id and index could be -1 from the server.
   *
   * @todo Look into making the db entry `null` by default.
   */
  const molochProposalIdSafe =
    molochProposalId !== undefined && molochProposalId !== -1
      ? molochProposalId
      : undefined;

  /**
   * External hooks
   */

  const {isPaymentRequestedOK, isPaymentRequestedOKStatus} =
    useIsPaymentRequestedOK(paymentRequestedWEI);

  /**
   * Variables
   */

  const getText = getOrgText(orgText);
  const orgLoaderTextColor = getText('OrgLoaderTextColor');
  const orgProjectText = getText('OrgProjectText');
  const disabledReasonPayment =
    isPaymentRequestedOKStatus === FetchStatus.FULFILLED &&
    isPaymentRequestedOK === false
      ? 'There is not enough funds available to meet the payment requested from the proposal.'
      : '';

  /**
   * Functions
   */

  function renderCountdownIcon(): JSX.Element {
    switch (orgInternalName) {
      case InternalNamesToMap.liquidity:
        return <DropSVG />;
      case InternalNamesToMap.nft:
      case InternalNamesToMap.thelao:
      default:
        return <StopwatchSVG />;
    }
  }

  return (
    <>
      {/* SPONSOR PROPOSAL */}
      <div
        className={`${s['row-w-icon']} ${s['countdown']} org-countdown org-countdown--pending`}>
        {renderCountdownIcon()}
        <span className={`${s['status']} org-status org-status--pending`}>
          Ready for sponsorship
        </span>
      </div>
      <p style={{fontSize: '.8rem', lineHeight: '1.75', marginTop: '2rem'}}>
        Members have the right to sponsor a {orgProjectText} for funding. Once
        sponsored, members will be able to vote on whether the {orgProjectText}{' '}
        should receive funding.
      </p>

      <ProposalActionWhyDisabled
        otherReasons={[disabledReasonPayment]}
        render={({
          canShowDisabledReason,
          isDisabled,
          openWhyDisabledModal,
          WhyDisabledModal,
        }) => (
          <>
            <SponsorProposal
              proposalId={molochProposalIdSafe}
              render={({
                error,
                etherscanURL,
                isPromptOpen,
                isSubmitted,
                isSubmitting,
                openPrompt,
              }) => (
                <>
                  {/* SPONSORSHIP SUBMIT BUTTON */}
                  <button
                    disabled={isDisabled || isSubmitting || isSubmitted}
                    onClick={
                      isDisabled || isSubmitted || isSubmitting
                        ? () => {}
                        : openPrompt
                    }
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '2rem',
                    }}>
                    {isPromptOpen ? (
                      <Loader
                        text="Preparing&hellip;"
                        textProps={{
                          style: {color: orgLoaderTextColor},
                        }}
                      />
                    ) : isSubmitting ? (
                      <Loader
                        text="Processing&hellip;"
                        textProps={{
                          style: {color: orgLoaderTextColor},
                        }}
                      />
                    ) : isSubmitted ? (
                      'Finishing up\u2026'
                    ) : (
                      'Sponsor Proposal'
                    )}
                  </button>

                  {etherscanURL && (
                    <p className="text-center">
                      <small>
                        <a
                          href={etherscanURL}
                          rel="noopener noreferrer"
                          target="_blank">
                          (view {isSubmitting ? 'progress' : 'transaction'})
                        </a>
                      </small>
                    </p>
                  )}

                  {/* if error is not a MM window close/cancel code */}
                  {error && (error as MetaMaskRPCError).code !== 4001 && (
                    <div className="text-center">
                      <ErrorMessageWithDetails
                        error={error}
                        renderText={() => (
                          <small>
                            Something went wrong while sponsoring your proposal.
                          </small>
                        )}
                      />
                    </div>
                  )}
                </>
              )}
            />

            {canShowDisabledReason && (
              <div style={{paddingBottom: '1em'}}>
                <span
                  className={v['why--tooltip']}
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    event.preventDefault();
                    openWhyDisabledModal();
                  }}>
                  Why is sponsoring disabled?
                </span>
              </div>
            )}

            <WhyDisabledModal />
          </>
        )}
      />
    </>
  );
}
