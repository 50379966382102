import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';

import {dontCloseWindowWarning, contractSend} from '../../util/helpers';
import {ETHERSCAN_URLS} from '../../util/config';
import {StoreState, TypeOfTypeProp} from '../../util/types';
import {
  useETHGasPrice,
  useMolochTokenBalances,
  useApprovedTokens,
} from '../../hooks';
import {FetchStatus, Web3TxStatus} from '../../util/enums';
import {TokenBalance} from '../../hooks/useMolochTokenBalances';

type WithdrawalMap = Record<string, string>;

/**
 * `handleChange` - Amount passed should be a percentage (e.g. 1, 10, 100).
 *   Should be used when allowing the user change the amounts to withdraw.
 */
type RenderProps = {
  etherscanURL: string | undefined;
  handleChange: (
    tokenAddress: TokenBalance
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => Promise<void>;
  submitError: Error | undefined;
  submitReadyStatus: FetchStatus;
  molochTokenBalances:
    | TypeOfTypeProp<
        ReturnType<typeof useMolochTokenBalances>,
        'molochTokenBalances'
      >
    | undefined;
  molochTokenBalancesStatus: FetchStatus | undefined;
  txStatus: Web3TxStatus;
};

export type OnProcessOrSuccessData = {
  etherscanURL: string;
  txHash: string;
  tokenWithdrawals: TokenBalance[];
};

/**
 * `withdrawals?` - Set withdrawals to only these `WithdrawalMap` items
 *   (e.g. { [token address]: amountToWithdrawInWEI }). Mainly only useful for maximum withdrawals
 *   (e.g. withdraw from the initial deposit token address). To withdraw the maximum amount
 *   leave the amount as an empty string and set `max` prop to `true`.
 *
 * `max?` - Set all withdrawals to `max = true`. Defaults to false.
 *
 * `onError` - A callback which fires on error.
 *
 * `onProcess` - A callback which fires when processing.
 *
 * `onSuccess` - A callback which fires when processing complete.
 *
 * `render` - Render anything you'd like with the help of passed `RenderProps`.
 */
type WithdrawProps = {
  max?: boolean;
  onError?: (e: Error) => void;
  onProcess?: (d: OnProcessOrSuccessData) => void;
  onStart?: () => void;
  onSuccess?: (d: OnProcessOrSuccessData) => void;
  render: (r: RenderProps) => React.ReactElement;
  withdrawals?: WithdrawalMap;
};

/**
 * Withdraw
 *
 * Provides the ability to withdraw tokens from a Moloch contract.
 * Render any UI you'd like using the `render` prop.
 * Props (`RenderProps`) will be passed into `render`'s function component to be acted upon.
 *
 * @param {WithdrawProps} props
 */
export default function Withdraw(props: WithdrawProps) {
  const {onError, onProcess, onStart, onSuccess, withdrawals} = props;

  /**
   * Selectors
   */

  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );
  const VentureMoloch = useSelector(
    (s: StoreState) =>
      s.blockchain.contracts && s.blockchain.contracts.VentureMoloch
  );
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );
  const contractMolochAddress = useSelector(
    (s: StoreState) => s.org?.contractMolochAddress
  );

  /**
   * Refs
   */

  const etherscanURLRef = useRef<string>('');
  const txHashRef = useRef<string>('');

  /**
   * Custom hooks
   */

  const {average: gasPrice} = useETHGasPrice();
  const {approvedTokens} = useApprovedTokens();
  const {molochTokenBalances, molochTokenBalancesStatus} =
    useMolochTokenBalances(connectedAddress, approvedTokens);

  /**
   * State
   */

  const [etherscanURL, setEtherscanURL] = useState<string>();
  const [txStatus, setTxStatus] = useState<Web3TxStatus>(Web3TxStatus.STANDBY);
  const [submitReadyStatus, setSubmitReadyStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );
  const [submitError, setSubmitError] = useState<Error>();
  const [withdrawalMap, setWithdrawalMap] = useState<WithdrawalMap>();

  /**
   * Effects
   */

  // If `withdrawals` are passed in then set `setWithdrawalMap`
  // to only these token addresses and amounts.
  useEffect(() => {
    // Sets withdrawals to user-defined { [token address]: amount }
    // or defaults to all approved tokens with their fetched amounts.
    const withdrawalsToSet =
      withdrawals && Object.keys(withdrawals).length > 0
        ? withdrawals
        : approvedTokens.reduce((acc, next) => {
            const foundTokenBalance = molochTokenBalances.find(
              (t) => t.address === next
            );

            // Set the withdrawal amount if the balance is not 0
            if (foundTokenBalance && Number(foundTokenBalance.balance) > 0) {
              foundTokenBalance && (acc[next] = foundTokenBalance.balance);
            }

            return acc;
          }, {} as Record<string, string>);

    setWithdrawalMap(withdrawalsToSet);
  }, [approvedTokens, withdrawals, molochTokenBalances]);

  useEffect(() => {
    const waiting =
      (!withdrawalMap || !Object.keys(withdrawalMap).length) &&
      (molochTokenBalancesStatus === FetchStatus.PENDING ||
        molochTokenBalancesStatus === FetchStatus.STANDBY);

    if (waiting) {
      setSubmitReadyStatus(FetchStatus.PENDING);

      return;
    }

    setSubmitReadyStatus(FetchStatus.FULFILLED);
  }, [molochTokenBalancesStatus, withdrawalMap]);

  /**
   * Functions
   */

  /**
   * Called via a rendered component's input (i.e. via `render` prop function component).
   *
   * @param {string} tokenAddress
   * @param {string} balanceToWithdrawInWEI
   */
  function handleMergeWithdrawalEntry(
    tokenAddress: string,
    balanceToWithdrawInWEI: string
  ) {
    setWithdrawalMap({
      ...withdrawalMap,
      [tokenAddress]: balanceToWithdrawInWEI,
    });
  }

  /**
   * handleWithdrawAmountChange
   *
   * Safely sets a percentage of the amount in WEI to withdraw.
   *
   * @param tokenBalance
   *
   * @todo Handle setting a controlled min/max value to send via `RenderProps`
   *   to set on the <input value={} />
   */
  function handleWithdrawAmountChange(tokenBalance: TokenBalance) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const BN = BigNumber;
      const {value: percent} = event.target;

      // If there's no percent, send a blank value
      if (!percent || !Number(percent)) {
        handleMergeWithdrawalEntry(tokenBalance.address, '');
        return;
      }

      const amountToWithdrawInWEI = new BN(tokenBalance.balance)
        .multipliedBy(new BN(percent).dividedBy(new BN(100)))
        .decimalPlaces(0, BigNumber.ROUND_DOWN)
        .toString();

      handleMergeWithdrawalEntry(tokenBalance.address, amountToWithdrawInWEI);
    };
  }

  /**
   * buildWithdrawArguments
   *
   * Builds the argument list that `withdrawBalances` requires.
   *
   * @returns {Array} [ token[], balance[], maxBoolean ]
   */
  function buildWithdrawArguments() {
    const entries = Object.entries(withdrawalMap || {});
    const tokens = entries.map((w) => w[0]);
    const balance = entries.map((w) => Web3.utils.toBN(w[1]));

    return [tokens, balance, props.max || false];
  }

  async function handleWithdrawal() {
    // activate "don't close window" warning
    const unsubscribeDontCloseWindow = dontCloseWindowWarning();
    const withdrawalArgs = buildWithdrawArguments();
    const hasWithdrawalArgs =
      withdrawalArgs
        .flatMap((a) => (typeof a !== 'boolean' ? a.toString() : undefined))
        .filter(Boolean).length > 0;

    const txArguments = {
      from: connectedAddress,
      to: contractMolochAddress,
      // set proposed gas price
      ...(gasPrice ? {gasPrice} : null),
    };

    const handleProcessingTx = (txHash: string) => {
      if (!txHash) return;

      const txURL = `${ETHERSCAN_URLS[chainId]}/tx/${txHash}`;

      setEtherscanURL(txURL);
      setTxStatus(Web3TxStatus.PENDING);

      // Set ref data to be accessed outside of closure scope.
      etherscanURLRef.current = txURL;
      txHashRef.current = txHash;

      try {
        onProcess &&
          onProcess({
            etherscanURL: etherscanURLRef.current,
            txHash: txHashRef.current,
            tokenWithdrawals: molochTokenBalances.filter((t) =>
              Object.keys(withdrawalMap || {}).includes(t.address)
            ),
          });
      } catch (error) {
        setSubmitError(error);

        onError && onError(error);
      }
    };

    try {
      if (!VentureMoloch) throw new Error('No VentureMoloch contract found.');
      if (!connectedAddress) throw new Error('No user account found.');
      if (!hasWithdrawalArgs)
        throw new Error('No withdrawal `{ [token]: amounts }` are set.');

      setTxStatus(Web3TxStatus.AWAITING_CONFIRM);

      /**
       * Run user callback `onStart`
       * Set start when wallet opens
       * e.g. UI modals may want to prevent close.
       *
       * @note We `await` just in case the value is a Promise.
       */
      onStart && (await onStart());

      /**
       * VentureMoloch - withdrawBalances
       *
       * @param {object} withdrawalArgs - withdraw funds
       */
      contractSend(
        'withdrawBalances',
        VentureMoloch.instance.methods,
        withdrawalArgs,
        txArguments,
        handleProcessingTx
      )
        .then(({txStatus, receipt, error}) => {
          if (txStatus === Web3TxStatus.FULFILLED) {
            if (!receipt) return;

            setTxStatus(Web3TxStatus.FULFILLED);

            onSuccess &&
              onSuccess({
                etherscanURL: etherscanURLRef.current,
                txHash: txHashRef.current,
                tokenWithdrawals: molochTokenBalances.filter((t) =>
                  Object.keys(withdrawalMap || {}).includes(t.address)
                ),
              });

            unsubscribeDontCloseWindow();
          }

          if (txStatus === Web3TxStatus.REJECTED) {
            setTxStatus(Web3TxStatus.REJECTED);
            setSubmitError(error);

            onError && error && onError(error);

            unsubscribeDontCloseWindow();
          }
        })
        .catch(({error}) => {
          setTxStatus(Web3TxStatus.REJECTED);
          setSubmitError(error);

          onError && onError(error);

          unsubscribeDontCloseWindow();
        });
    } catch (error) {
      setTxStatus(Web3TxStatus.REJECTED);
      setSubmitError(error);

      onError && onError(error);

      unsubscribeDontCloseWindow();
    }
  }

  return props.render({
    etherscanURL,
    handleChange: handleWithdrawAmountChange,
    handleSubmit: handleWithdrawal,
    molochTokenBalances,
    molochTokenBalancesStatus,
    submitError,
    submitReadyStatus,
    txStatus,
  });
}
