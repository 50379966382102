import {EnvironmentName} from './types';

const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_INFURA_PROJECT_ID_LOCAL,
  REACT_APP_INFURA_PROJECT_ID_DEV,
  REACT_APP_INFURA_PROJECT_ID_PROD,
  REACT_APP__DEV__FUNDING_IN_USD,
  REACT_APP__DEV__ORG_INTERNAL_NAME,
  REACT_APP_BACKEND_URL,
  REACT_APP_BACKEND_URL_KYC,
  REACT_APP_SNAPSHOT_HUB_API_URL,
  REACT_APP_DISCORD_OAUTH_CLIENT_ID,
  REACT_APP_MONGO_BFF_URL,
} = process.env;

export const ENVIRONMENT = REACT_APP_ENVIRONMENT as EnvironmentName | undefined;

/**
 * A helper for development to build the app with
 * a certain org via `internalName` from the database.
 */
export const __DEV__ORG_INTERNAL_NAME =
  ENVIRONMENT !== 'production' && REACT_APP__DEV__ORG_INTERNAL_NAME
    ? REACT_APP__DEV__ORG_INTERNAL_NAME
    : '';

/**
 * BACKEND_URL
 *
 * The server URL, including the path of the org's id.
 */
export const BACKEND_URL = REACT_APP_BACKEND_URL;
export const BACKEND_URL_KYC = REACT_APP_BACKEND_URL_KYC;

/**
 * Discord OAuth
 */
export const DISCORD_OAUTH_CLIENT_ID =
  REACT_APP_DISCORD_OAUTH_CLIENT_ID as string;

/**
 * @todo We need to change this to be $x worth of ETH on the day,
 * or whatever we deem the amount to be.
 */
export const FUNDING_IN_USD: number =
  typeof REACT_APP__DEV__FUNDING_IN_USD !== 'undefined'
    ? /** @see docker-compose.yml */
      Number(REACT_APP__DEV__FUNDING_IN_USD)
    : REACT_APP_ENVIRONMENT === 'production'
    ? 50000
    : 1; // SubmitProposal `paymentRequested`

// Infura Project Id
export const INFURA_PROJECT_ID =
  REACT_APP_ENVIRONMENT === 'production'
    ? // Stored in https://github.com/openlawteam/lao/settings/secrets/actions (for actual use) and in Netlify App (for reference purposes only)
      REACT_APP_INFURA_PROJECT_ID_PROD
    : REACT_APP_ENVIRONMENT === 'development'
    ? // Stored in https://github.com/openlawteam/lao/settings/secrets/actions (for actual use) and in Netlify App (for reference purposes only)
      REACT_APP_INFURA_PROJECT_ID_DEV
    : REACT_APP_INFURA_PROJECT_ID_LOCAL;

// Network IDs, when users change wallet networks
export const CHAINS = {
  MAINNET: 1,
  RINKEBY: 4,
  GOERLI: 5,
  POLYGON: 137,
  GANACHE: 1337,
};

// Network names for modal messaging
export const CHAIN_NAME = {
  [CHAINS.MAINNET]: 'Main Ethereum Network',
  [CHAINS.RINKEBY]: 'Rinkeby Test Network',
  [CHAINS.GOERLI]: 'Goerli Test Network',
  [CHAINS.POLYGON]: 'Polygon Main Network',
  [CHAINS.GANACHE]: 'Ganache Test Network',
};

// Required: Used for WalletConnect connection
export const POLLING_INTERVAL = 12000;

// Required: WalletConnect RPR_URLS for default chain
export const RPC_URLS: {[chainId: number]: string} = {
  [CHAINS.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_PROJECT_ID}`,
  [CHAINS.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_PROJECT_ID}`,
  [CHAINS.GOERLI]: `https://goerli.infura.io/v3/${INFURA_PROJECT_ID}`,
  [CHAINS.POLYGON]: `https://polygon-mainnet.infura.io/v3/${INFURA_PROJECT_ID}`,
};

/**
 * Web3 Websocket Provider URL
 *
 * @todo Remove after we upgrade to Graph for Members list, as
 *   it is the last holdout using a Web3 instance created without using the
 *   normal Redux route on wallet connection.
 */
export const RPC_WS_URLS: {[chainId: number]: string} = {
  [CHAINS.MAINNET]: `wss://mainnet.infura.io/ws/v3/${INFURA_PROJECT_ID}`,
  [CHAINS.RINKEBY]: `wss://rinkeby.infura.io/ws/v3/${INFURA_PROJECT_ID}`,
  [CHAINS.GOERLI]: `wss://goerli.infura.io/ws/v3/${INFURA_PROJECT_ID}`,
  [CHAINS.POLYGON]: `wss://polygon-mainnet.infura.io/ws/v3/${INFURA_PROJECT_ID}`,
};

export const ETHERSCAN_URLS: {[chainId: number]: string} = {
  [CHAINS.MAINNET]: 'https://etherscan.io',
  [CHAINS.RINKEBY]: 'https://rinkeby.etherscan.io',
  [CHAINS.GOERLI]: 'https://goerli.etherscan.io',
  [CHAINS.POLYGON]: 'https://polygonscan.com',
};

/**
 * POLLING INTERVAL FOR GQL QUERIES
 * localhost | development - ms, poll every 5sec = 5000
 * production - ms, poll every 10sec = 10000
 */
export const GQL_QUERY_POLLING_INTERVAL: number =
  REACT_APP_ENVIRONMENT === 'production' ? 10000 : 5000;

export const REST_POLLING_INTERVAL: number =
  REACT_APP_ENVIRONMENT === 'production' ? 10000 : 5000;

/**
 * SNAPSHOT HUB API URL
 * The snapshot-hub API URL.
 * @see https://snapshot.page for latest prod URL
 * @see https://demo.snapshot.page for latest dev URL
 * We might use URLs that points to snapshot-hub hosted by TheLAO as well.
 */
export const SNAPSHOT_HUB_API_URL: string | undefined =
  REACT_APP_SNAPSHOT_HUB_API_URL;

// Mongo Data BFF Contribution Endpoint
export const MONGO_BFF_URL = REACT_APP_MONGO_BFF_URL;
