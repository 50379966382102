import {MemberMolochResponse, StoreState} from '../../util/types';
import {FetchStatus} from '../../util/enums';

export const FETCH_STATUS_GET_MEMBER_FROM_SMART_CONTRACT =
  'FETCH_STATUS_GET_MEMBER_FROM_SMART_CONTRACT';
export const GET_MEMBER_FROM_SMART_CONTRACT = 'GET_MEMBER_FROM_SMART_CONTRACT';

/**
 * getConnectedMemberFromSmartContract
 *
 * Gets information about a LAO member from the Moloch K.
 *
 * @todo If for some reason the order of operations to run this action after a Moloch contract
 *   has been initiated becomes annoying, we can always create a new contract instance here
 *   via helper function or otherwise.
 */
export function getConnectedMemberFromSmartContract() {
  return async function (dispatch: any, getState: () => StoreState) {
    const blockchainState = getState().blockchain;
    const {contracts, connectedAddress} = blockchainState;
    const ventureMolochInstance =
      contracts && contracts.VentureMoloch && contracts.VentureMoloch.instance;

    if (!ventureMolochInstance || !connectedAddress) {
      dispatch({type: 'GET_MEMBER_FROM_SMART_CONTRACT'});
      dispatch({
        type: 'FETCH_STATUS_GET_MEMBER_FROM_SMART_CONTRACT',
        fetchStatus: FetchStatus.REJECTED,
      });

      return;
    }

    try {
      dispatch({
        type: 'FETCH_STATUS_GET_MEMBER_FROM_SMART_CONTRACT',
        fetchStatus: FetchStatus.PENDING,
      });

      const memberAddressByDelegateKey: string =
        await ventureMolochInstance.methods
          .memberAddressByDelegateKey(connectedAddress)
          .call({from: connectedAddress});

      let maybeMemberAddress = connectedAddress;

      /**
       * @note we need to check for a delegation, if the addresses don't
       * match, this means a likely delegation
       */
      if (
        memberAddressByDelegateKey.toLowerCase() !==
          connectedAddress.toLowerCase() &&
        !memberAddressByDelegateKey.toLowerCase().startsWith('0x00000')
      ) {
        maybeMemberAddress = memberAddressByDelegateKey;
      }

      /**
       * @note now lets get our `maybeMemberAddress` info
       */
      const member: MemberMolochResponse = await ventureMolochInstance.methods
        .members(maybeMemberAddress)
        .call({from: connectedAddress});

      dispatch({
        type: 'FETCH_STATUS_GET_MEMBER_FROM_SMART_CONTRACT',
        fetchStatus: FetchStatus.FULFILLED,
      });

      const {delegateKey, shares, loot, exists, highestIndexYesVote, jailed} =
        member;

      member.exists
        ? dispatch({
            type: 'GET_MEMBER_FROM_SMART_CONTRACT',
            delegateKey,
            shares,
            loot,
            exists,
            highestIndexYesVote,
            jailed,
            isVotingMember: Number(shares) > 0,
            isMemberActive: exists && (Number(shares) > 0 || Number(loot) > 0),
            memberAddress: maybeMemberAddress,
          })
        : dispatch({type: 'GET_MEMBER_FROM_SMART_CONTRACT'});
    } catch (error) {
      dispatch({
        type: 'FETCH_STATUS_GET_MEMBER_FROM_SMART_CONTRACT',
        fetchStatus: FetchStatus.REJECTED,
      });

      throw error;
    }
  };
}
