import {useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {StoreState} from '../util/types';
import {FetchStatus} from '../util/enums';

export default function useGuildBalance() {
  /**
   * State
   */

  const [guildBalance, setGuildBalance] = useState<string>();
  const [guildBalanceStatus, setGuildBalanceStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );

  /**
   * Selectors
   */

  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const ventureMolochInstance = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts &&
      state.blockchain.contracts.VentureMoloch &&
      state.blockchain.contracts.VentureMoloch.instance
  );
  const wrapETHAddress = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts &&
      state.blockchain.contracts.WrapETH &&
      state.blockchain.contracts.WrapETH.contractAddress
  );

  /**
   * useCallback
   */
  const getGuildBalanceCached = useCallback(getGuildBalance, [
    ventureMolochInstance,
    wrapETHAddress,
    connectedAddress,
  ]);

  useEffect(() => {
    getGuildBalanceCached();
  }, [getGuildBalanceCached]);

  async function getGuildBalance() {
    if (!ventureMolochInstance || !wrapETHAddress || !connectedAddress) {
      return;
    }
    setGuildBalanceStatus(FetchStatus.PENDING);

    try {
      const GUILD_ADDRESS = await ventureMolochInstance.methods
        .GUILD()
        .call({from: connectedAddress});

      const balance: string = await ventureMolochInstance.methods
        .getUserTokenBalance(GUILD_ADDRESS, wrapETHAddress)
        .call({from: connectedAddress});

      setGuildBalance(balance);
      setGuildBalanceStatus(FetchStatus.FULFILLED);
    } catch (error) {
      setGuildBalance(undefined);
      setGuildBalanceStatus(FetchStatus.REJECTED);
    }
  }

  return {guildBalance, guildBalanceStatus};
}
