import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';

import MinionForm from './forms/MinionForm';
import EasyApplySidePocketForm from './forms/EasyApplySidePocketForm';
import MembershipForm from './forms/MembershipForm';

export default function Forms() {
  return (
    <div>
      <h1>Forms</h1>

      <Tabs forceRenderTabPanel defaultIndex={0}>
        <TabList>
          <Tab>Side-Pocket Form</Tab>
          <Tab>Minion Form</Tab>
          <Tab>Membership Form</Tab>
        </TabList>

        <TabPanel>
          <EasyApplySidePocketForm />
        </TabPanel>
        <TabPanel>
          <MinionForm />
        </TabPanel>
        <TabPanel>
          <MembershipForm />
        </TabPanel>
      </Tabs>
    </div>
  );
}
