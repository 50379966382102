import React from 'react';
import {useSelector} from 'react-redux';

import {getOrgText} from '../../util/helpers';
import {ProposalHeaderNames} from '../../util/enums';
import {StoreState} from '../../util/types';
import {useInvestmentProposalsParsedSponsored, useIsAdmin} from '../../hooks';
import FadeIn from '../../components/common/FadeIn';
import HiThereLoader from '../../components/feedback/HiThereLoader';
import InvestmentProposalsCards from './InvestmentProposalsCards';
import Wrap from '../../components/layout/Wrap';

/**
 * InvestmentProposalsSponsored
 *
 * Renders investment proposals which are:
 *   - In voting
 *   - Passed vote
 *   - Failed vote
 *
 * @returns {JSX.Element}
 */
export default function InvestmentProposalsSponsored(): JSX.Element {
  /**
   * Selectors
   */

  const connectedMember = useSelector((s: StoreState) => s.connectedMember);
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  /**
   * Our hooks
   */

  const isAdmin = useIsAdmin();

  const {
    areInvestmentProposalsLoading,
    proposalEntriesFailed,
    proposalEntriesPassed,
    proposalEntriesVoting,
    updateSnapshotProposals,
  } = useInvestmentProposalsParsedSponsored();

  /**
   * Variables
   */

  const isMember = connectedMember && connectedMember.isMemberActive;

  const getText = getOrgText(orgText);
  const orgLoaderEmoji = getText('OrgLoaderEmoji');
  const orgNoInvestmentsText = getText('OrgNoInvestmentsText');

  // Map the rendered proposals for display
  const passedProposals = proposalEntriesPassed.map((p) => p[2]);
  const votingProposals = proposalEntriesVoting.map((p) => p[2]);
  const failedProposals = proposalEntriesFailed.map((p) => p[2]);

  const noInvestmentsFound =
    !areInvestmentProposalsLoading &&
    !votingProposals.length &&
    !passedProposals.length &&
    !failedProposals.length;

  /**
   * Render
   */

  // Return loading if the proposals are not done fetching.
  if (areInvestmentProposalsLoading) {
    return (
      <Wrapper>
        <div style={{width: '3rem', margin: '0 auto'}}>
          <HiThereLoader />
        </div>

        <p className="text-center">Loading&hellip;</p>
      </Wrapper>
    );
  }

  // Render if no proposals were found
  if (noInvestmentsFound) {
    return (
      <Wrapper>
        <div className="no-data-container">
          <div className="emoji-container">
            <span role="img" aria-label="Emoji.">
              {orgLoaderEmoji}
            </span>
          </div>

          <p className="text-center org-bold">{orgNoInvestmentsText}</p>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <section
        className={
          'grid--fluid sections-grid-container org-sections-grid-container'
        }>
        {/* SHOW THE VOTING PROPOSALS AS HIGHER PRIORITY FOR MEMBERS and ADMINS */}
        {(isMember || isAdmin) && votingProposals.length > 0 && (
          <>
            <div className="sections-grid__header org-sections-grid__header">
              {ProposalHeaderNames.VOTING}
            </div>
            <div className="sections-grid__cards">
              <InvestmentProposalsCards
                headerName={ProposalHeaderNames.VOTING}
                onUpdateSnapshotProposals={updateSnapshotProposals}
                proposals={votingProposals}
              />
            </div>
          </>
        )}

        {/* INVESTMENTS (PASSED) */}
        {passedProposals.length > 0 && (
          <>
            <div className="sections-grid__header org-sections-grid__header">
              {ProposalHeaderNames.INVESTMENTS}
            </div>
            <div className="sections-grid__cards">
              <InvestmentProposalsCards
                onUpdateSnapshotProposals={updateSnapshotProposals}
                proposals={passedProposals}
              />
            </div>
          </>
        )}

        {/* SHOW THE VOTING PROPOSALS AS LOWER PRIORITY FOR NON-MEMBERS and NON-ADMINS */}
        {!isMember && !isAdmin && votingProposals.length > 0 && (
          <>
            <div className="sections-grid__header org-sections-grid__header">
              {ProposalHeaderNames.VOTING}
            </div>
            <div className="sections-grid__cards">
              <InvestmentProposalsCards
                headerName={ProposalHeaderNames.VOTING}
                onUpdateSnapshotProposals={updateSnapshotProposals}
                proposals={votingProposals}
              />
            </div>
          </>
        )}

        {/* FAILED */}
        {failedProposals.length > 0 && (
          <>
            <div className="sections-grid__header org-sections-grid__header">
              {ProposalHeaderNames.FAILED}
            </div>
            <div className="sections-grid__cards">
              <InvestmentProposalsCards
                headerName={ProposalHeaderNames.FAILED}
                onUpdateSnapshotProposals={updateSnapshotProposals}
                proposals={failedProposals}
              />
            </div>
          </>
        )}
      </section>
    </Wrapper>
  );
}

/**
 * Wrapper
 *
 * A helper to render the same wrapper for any `children` which
 * need it. This helps tame the hard-to-read render logic in JSX.
 *
 * @param {React.PropsWithChildren<React.ReactNode>} props
 * @returns {JSX.Element}
 */
function Wrapper(props: React.PropsWithChildren<React.ReactNode>) {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);

  const orgInvestmentsText = getText('OrgInvestmentsText');

  return (
    <Wrap className={'section-wrapper'}>
      <FadeIn>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">
            {orgInvestmentsText}
          </h2>
        </div>

        {props.children}
      </FadeIn>
    </Wrap>
  );
}
