/**
 * An enum of org `internalNames` in our database
 * we want to map to.
 */
export enum InternalNamesToMap {
  'university-bc' = 'university-bc',
  'university-berkeley' = 'university-berkeley',
  'university-cambridge' = 'university-cambridge',
  'university-columbia' = 'university-columbia',
  'university-cornell' = 'university-cornell',
  'university-franklin' = 'university-franklin',
  'university-illinois' = 'university-illinois',
  'university-imperial' = 'university-imperial',
  'university-michigan' = 'university-michigan',
  'university-new-york' = 'university-new-york',
  'university-oregon' = 'university-oregon',
  'university-purdue' = 'university-purdue',
  'university-texas' = 'university-texas',
  'university-vanderbilt' = 'university-vanderbilt',
  'university-virginia' = 'university-virginia',
  'university-vox' = 'university-vox',
  'university-waterloo' = 'university-waterloo',
  ai = 'ai',
  blue = 'blue',
  bright = 'bright',
  cc0 = 'cc0',
  darkhorse = 'darkhorse',
  doge = 'doge',
  fashion = 'fashion',
  gaming = 'gaming',
  glimmer = 'glimmer',
  krpdm = 'krpdm',
  liquidity = 'liquidity',
  long = 'long',
  metaverse = 'metaverse',
  music = 'music',
  nft = 'nft',
  nftv2 = 'nftv2',
  punk = 'punk',
  science = 'science',
  si = 'si',
  spaceship = 'spaceship',
  test = 'test',
  testv2 = 'testv2',
  thelao = 'thelao',
  thelaov2 = 'thelaov2',
  unicorn = 'unicorn',
  university = 'university',
  urbit = 'urbit',
  zk = 'zk',
}

/**
 * Use this file to provide domain and subdomain mappings where the org's
 * internalName cannot be determined by the URL alone.
 *
 * For example:
 *
 * - A custom DAO domain like cooldao.io
 * - A Netlify branch URL (e.g. https://demo-dev--thelao.netlify.app/)
 * - A development/testing subdomain that needs to be set to the correct
 *   internalName.
 */

/**
 * hostnameOrgNameMap
 *
 * For local development: `localhost` is the hostname that can be mapped to an
 * org that you have set up in your local database.
 *
 * For production: a unique domain can be mapped to an org that has been set in
 * the prod database.
 *
 * For example, https://neptunedao.xyz can be mapped to the `liquidity` org.
 */
export const hostnameOrgNameMap: Record<string, string> = {
  // Used for local development to switch between orgs.
  // Commenting this out will have `localhost` fall back to `thelao` org.
  // localhost: InternalNamesToMap.nftv2,

  // https://old.thelao.io
  'old.thelao.io': InternalNamesToMap.thelao,

  // https://old.flamingodao.xyz
  'old.flamingodao.xyz': InternalNamesToMap.nft,
  // https://develop--old.flamingodao.xyz
  'develop--old.flamingodao.xyz': InternalNamesToMap.nft,

  // https://neptunedao.xyz, https://www.neptunedao.xyz
  'neptunedao.xyz': InternalNamesToMap.liquidity,
  'www.neptunedao.xyz': InternalNamesToMap.liquidity,
  // https://develop.neptunedao.xyz
  'develop.neptunedao.xyz': InternalNamesToMap.liquidity,

  // https://admin.neondao.xyz
  'admin.neondao.xyz': InternalNamesToMap.metaverse,
  // https://develop--admin.neondao.xyz
  'develop--admin.neondao.xyz': InternalNamesToMap.metaverse,

  // https://admin.reddao.xyz
  'admin.reddao.xyz': InternalNamesToMap.fashion,
  // https://develop--admin.reddao.xyz
  'develop--admin.reddao.xyz': InternalNamesToMap.fashion,

  // https://admin.readyplayerdao.xyz
  'admin.readyplayerdao.xyz': InternalNamesToMap.gaming,
  // https://develop--admin.readyplayerdao.xyz
  'develop--admin.readyplayerdao.xyz': InternalNamesToMap.gaming,

  // https://admin.noisedao.xyz
  'admin.noisedao.xyz': InternalNamesToMap.music,
  // https://develop--admin.noisedao.xyz
  'develop--admin.noisedao.xyz': InternalNamesToMap.music,

  // https://admin.spaceshipdao.xyz
  'admin.spaceshipdao.xyz': InternalNamesToMap.spaceship,
  // https://develop--admin.spaceshipdao.xyz
  'develop--admin.spaceshipdao.xyz': InternalNamesToMap.spaceship,

  // https://admin.unicorndao.love
  'admin.unicorndao.love': InternalNamesToMap.unicorn,
  // https://develop--admin.unicorndao.love
  'develop--admin.unicorndao.love': InternalNamesToMap.unicorn,

  // https://admin.punk-dao.io
  'admin.punk-dao.io': InternalNamesToMap.punk,
  // https://develop--admin.punk-dao.io
  'develop--admin.punk-dao.io': InternalNamesToMap.punk,

  // https://admin.xxdao.xyz
  'admin.xxdao.xyz': InternalNamesToMap.bright,
  // https://develop--admin.xxdao.xyz
  'develop--admin.xxdao.xyz': InternalNamesToMap.bright,

  // https://admin.darkhorsedao.xyz
  'admin.darkhorsedao.xyz': InternalNamesToMap.darkhorse,
  // https://develop--admin.darkhorsedao.xyz
  'develop--admin.darkhorsedao.xyz': InternalNamesToMap.darkhorse,

  // https://admin.beakerdao.xyz
  'admin.beakerdao.xyz': InternalNamesToMap.science,
  // https://develop--admin.beakerdao.xyz
  'develop--admin.beakerdao.xyz': InternalNamesToMap.science,

  // https://admin.c0c0dao.xyz
  'admin.c0c0dao.xyz': InternalNamesToMap.cc0,
  // https://develop--admin.c0c0dao.xyz
  'develop--admin.c0c0dao.xyz': InternalNamesToMap.cc0,

  // https://admin.sidao.io
  'admin.sidao.io': InternalNamesToMap.si,
  // https://develop--admin.sidao.io
  'develop--admin.sidao.io': InternalNamesToMap.si,

  // https://admin.glimmerdao.io
  'admin.glimmerdao.io': InternalNamesToMap.glimmer,
  // https://develop--admin.glimmerdao.io
  'develop--admin.glimmerdao.io': InternalNamesToMap.glimmer,

  // https://develop--admin.testdao.tributelabs.xyz
  'develop--admin.testdao.tributelabs.xyz': InternalNamesToMap.test,

  // https://develop--admin.testv2dao.tributelabs.xyz
  'develop--admin.testv2dao.tributelabs.xyz': InternalNamesToMap.testv2,

  // https://admin.metropolisdao.io
  'admin.metropolisdao.io': InternalNamesToMap.ai,
  // https://develop--admin.metropolisdao.io
  'develop--admin.metropolisdao.io': InternalNamesToMap.ai,

  // https://admin.dormdao.io
  'admin.dormdao.io': InternalNamesToMap.university,
  // https://develop--admin.dormdao.io
  'develop--admin.dormdao.io': InternalNamesToMap.university,

  // https://admin.labyrinthdao.io
  'admin.labyrinthdao.io': InternalNamesToMap.urbit,
  // https://develop--admin.labyrinthdao.io
  'develop--admin.labyrinthdao.io': InternalNamesToMap.urbit,

  // https://admin.krpdm.xyz
  'admin.krpdm.xyz': InternalNamesToMap.krpdm,
  // https://develop--admin.krpdm.xyz
  'develop--admin.krpdm.xyz': InternalNamesToMap.krpdm,

  // https://admin.zerodao.io
  'admin.zerodao.io': InternalNamesToMap.zk,
  // https://develop--admin.zerodao.io
  'develop--admin.zerodao.io': InternalNamesToMap.zk,

  // https://admin.thelao.io
  'admin.thelao.io': InternalNamesToMap.thelaov2,
  // https://develop--admin.thelao.io
  'develop--admin.thelao.io': InternalNamesToMap.thelaov2,

  // https://admin.flamingodao.xyz
  'admin.flamingodao.xyz': InternalNamesToMap.nftv2,
  // https://develop--admin.flamingodao.xyz
  'develop--admin.flamingodao.xyz': InternalNamesToMap.nftv2,

  // https://admin.bc.dormdao.io
  'admin.bc.dormdao.io': InternalNamesToMap['university-bc'],
  // https://develop--admin.bc.dormdao.io
  'develop--admin.bc.dormdao.io': InternalNamesToMap['university-bc'],

  // https://admin.berkeley.dormdao.io
  'admin.berkeley.dormdao.io': InternalNamesToMap['university-berkeley'],
  // https://develop--admin.berkeley.dormdao.io
  'develop--admin.berkeley.dormdao.io':
    InternalNamesToMap['university-berkeley'],

  // https://admin.cambridge.dormdao.io
  'admin.cambridge.dormdao.io': InternalNamesToMap['university-cambridge'],
  // https://develop--admin.cambridge.dormdao.io
  'develop--admin.cambridge.dormdao.io':
    InternalNamesToMap['university-cambridge'],

  // https://admin.columbia.dormdao.io
  'admin.columbia.dormdao.io': InternalNamesToMap['university-columbia'],
  // https://develop--admin.columbia.dormdao.io
  'develop--admin.columbia.dormdao.io':
    InternalNamesToMap['university-columbia'],

  // https://admin.cornell.dormdao.io
  'admin.cornell.dormdao.io': InternalNamesToMap['university-cornell'],
  // https://develop--admin.cornell.dormdao.io
  'develop--admin.cornell.dormdao.io': InternalNamesToMap['university-cornell'],

  // https://admin.franklin.dormdao.io
  'admin.franklin.dormdao.io': InternalNamesToMap['university-franklin'],
  // https://develop--admin.franklin.dormdao.io
  'develop--admin.franklin.dormdao.io':
    InternalNamesToMap['university-franklin'],

  // https://admin.illinois.dormdao.io
  'admin.illinois.dormdao.io': InternalNamesToMap['university-illinois'],
  // https://develop--admin.illinois.dormdao.io
  'develop--admin.illinois.dormdao.io':
    InternalNamesToMap['university-illinois'],

  // https://admin.imperial.dormdao.io
  'admin.imperial.dormdao.io': InternalNamesToMap['university-imperial'],
  // https://develop--admin.imperial.dormdao.io
  'develop--admin.imperial.dormdao.io':
    InternalNamesToMap['university-imperial'],

  // https://admin.michigan.dormdao.io
  'admin.michigan.dormdao.io': InternalNamesToMap['university-michigan'],
  // https://develop--admin.michigan.dormdao.io
  'develop--admin.michigan.dormdao.io':
    InternalNamesToMap['university-michigan'],

  // https://admin.nyu.dormdao.io
  'admin.nyu.dormdao.io': InternalNamesToMap['university-new-york'],
  // https://develop--admin.nyu.dormdao.io
  'develop--admin.nyu.dormdao.io': InternalNamesToMap['university-new-york'],

  // https://admin.oregon.dormdao.io
  'admin.oregon.dormdao.io': InternalNamesToMap['university-oregon'],
  // https://develop--admin.oregon.dormdao.io
  'develop--admin.oregon.dormdao.io': InternalNamesToMap['university-oregon'],

  // https://admin.purdue.dormdao.io
  'admin.purdue.dormdao.io': InternalNamesToMap['university-purdue'],
  // https://develop--admin.purdue.dormdao.io
  'develop--admin.purdue.dormdao.io': InternalNamesToMap['university-purdue'],

  // https://admin.texas.dormdao.io
  'admin.texas.dormdao.io': InternalNamesToMap['university-texas'],
  // https://develop--admin.texas.dormdao.io
  'develop--admin.texas.dormdao.io': InternalNamesToMap['university-texas'],

  // https://admin.uva.dormdao.io
  'admin.uva.dormdao.io': InternalNamesToMap['university-virginia'],
  // https://develop--admin.uva.dormdao.io
  'develop--admin.uva.dormdao.io': InternalNamesToMap['university-virginia'],

  // https://admin.vanderbilt.dormdao.io
  'admin.vanderbilt.dormdao.io': InternalNamesToMap['university-vanderbilt'],
  // https://develop--admin.vanderbilt.dormdao.io
  'develop--admin.vanderbilt.dormdao.io':
    InternalNamesToMap['university-vanderbilt'],

  // https://admin.vox.dormdao.io
  'admin.vox.dormdao.io': InternalNamesToMap['university-vox'],
  // https://develop--admin.vox.dormdao.io
  'develop--admin.vox.dormdao.io': InternalNamesToMap['university-vox'],

  // https://admin.waterloo.dormdao.io
  'admin.waterloo.dormdao.io': InternalNamesToMap['university-waterloo'],
  // https://develop--admin.waterloo.dormdao.io
  'develop--admin.waterloo.dormdao.io':
    InternalNamesToMap['university-waterloo'],

  // https://admin.awedao.xyz
  'admin.awedao.xyz': InternalNamesToMap.long,
  // https://develop--admin.awedao.xyz
  'develop--admin.awedao.xyz': InternalNamesToMap.long,

  // https://admin.doggydao.xyz
  'admin.doggydao.xyz': InternalNamesToMap.doge,
  // https://develop--admin.doggydao.xyz
  'develop--admin.doggydao.xyz': InternalNamesToMap.doge,

  // https://admin.flamingoblue.xyz
  'admin.flamingoblue.xyz': InternalNamesToMap.blue,
  // https://develop--admin.flamingoblue.xyz
  'develop--admin.flamingoblue.xyz': InternalNamesToMap.blue,
};

/**
 * devSubdomainOrgNameMap
 *
 * For development: `thelao.io` subdomains which do not have matching
 * org.internalNames.
 *
 * For example https://develop--old.thelao.io does not have a unique org
 * internalName, but it should still map to `thelao.io`.
 */
export const devSubdomainOrgNameMap: Record<string, string> = {
  // https://develop--old.thelao.io
  'develop--old': InternalNamesToMap.thelao,
};
