import React from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {getOrgText} from '../../../util/helpers';
import {StoreState} from '../../../util/types';
import {FetchStatus, ProposalHeaderNames} from '../../../util/enums';
import {useIsAdmin} from '../../../hooks';
import {
  InvestmentOrGovernanceProposal,
  SnapshotCombinedVotesResponse,
  SnapshotProposal,
} from '../../../components/snapshot/types';
import {
  SnapshotProposalType,
  SnapshotProposalSubType,
} from '../../../components/snapshot/enums';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import FadeIn from '../../../components/common/FadeIn';
import HiThereLoader from '../../../components/feedback/HiThereLoader';
import SidePocketProposals from '../sidepocket/SidePocketProposals';
import SnapshotProposalCard from '../../../components/snapshot/SnapshotProposalCard';
import useSnapshotGovernanceProposals from '../../../components/snapshot/useSnapshotGovernanceProposals';
import useSnapshotProposalsParsed from '../../../components/snapshot/useSnaphotProposalsParsed';
import Wrap from '../../../components/layout/Wrap';

import p from '../../../assets/scss/modules/proposalcard.module.scss';

export default function GovernanceProposals() {
  /**
   * State
   */

  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgLoaderEmoji = getText('OrgLoaderEmoji');

  /**
   * Selectors
   */

  const connectedMember = useSelector((s: StoreState) => s.connectedMember);
  const orgFeatures = useSelector((s: StoreState) => s.org && s.org.features);
  const showSidePocketProposals =
    orgFeatures && orgFeatures.showSidePocketProposals;
  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );

  /**
   * Their hooks
   */

  const history = useHistory();

  /**
   * Our hooks
   */

  const isAdmin = useIsAdmin();

  const {snapshotGovernanceProposals, snapshotGovernanceProposalsStatus} =
    useSnapshotGovernanceProposals();

  const {
    snapshotProposalsFailedAll,
    snapshotProposalsPassedAll,
    snapshotProposalsVotingAll,
    snapshotNoProposalsFoundAll,
    updateSnapshotProposals,
  } = useSnapshotProposalsParsed({
    proposalsAndVotes: snapshotGovernanceProposals,
    type: SnapshotProposalType.Governance,
  });

  /**
   * Variables
   */

  const isMember = connectedMember && connectedMember.isMemberActive;
  const cardIconRendered = (
    <div
      className={`${p['proposal-icon-governance']} org-proposal-icon-governance`}>
      <span role="img" aria-label="Emoji">
        {orgLoaderEmoji}
      </span>
    </div>
  );

  /**
   * Functions
   */

  function goToGovernanceForm(): void {
    history.push(`/governance-proposal`);
  }

  function goToGovernanceProposal(proposalId: string): void {
    history.push(`/governance-proposals/${proposalId}`);
  }

  function canViewSnapshotProposal(proposerAddress: string): boolean {
    const isSnapshotProposer =
      proposerAddress && connectedAddress
        ? proposerAddress.toLowerCase() === connectedAddress.toLowerCase()
        : false;

    return isSnapshotProposer || isAdmin || isMember;
  }

  /**
   * renderProposalCards
   *
   * Returns a proposal card JSX element.
   *
   * @param {SnapshotProposal} proposal
   * @returns {JSX.Element[]} A proposal card JSX.
   */
  function renderProposalCards(
    proposalsAndVotes: InvestmentOrGovernanceProposal[],
    handleOnVoteEnded?: () => void
  ): JSX.Element[] {
    return proposalsAndVotes.map(({snapshotHubProposal, snapshotHubVotes}) => {
      const {
        address,
        authorIpfsHash,
        msg: {
          payload: {
            end,
            metadata: {private: privateProposal, subType},
          },
        },
      } = snapshotHubProposal as SnapshotProposal;
      const hasVoteEnded = end < Math.ceil(Date.now() / 1000);
      const privateView = privateProposal && !canViewSnapshotProposal(address);

      return (
        <SnapshotProposalCard
          buttonText={isMember && !hasVoteEnded ? 'Vote' : 'Review'}
          key={authorIpfsHash}
          onClick={!privateView ? goToGovernanceProposal : () => {}}
          onVoteEnded={handleOnVoteEnded}
          pathToProposal={`/governance-proposals/${authorIpfsHash}`}
          privateView={privateView}
          renderIcon={() => cardIconRendered}
          snapshotProposal={snapshotHubProposal as SnapshotProposal}
          snapshotVotes={snapshotHubVotes as SnapshotCombinedVotesResponse}
          subType={subType ?? SnapshotProposalSubType.Governance}
        />
      );
    });
  }

  // Return loading if the proposals are not done fetching.
  if (
    snapshotGovernanceProposalsStatus === FetchStatus.STANDBY ||
    snapshotGovernanceProposalsStatus === FetchStatus.PENDING
  ) {
    return (
      <Wrap className={'section-wrapper'}>
        <FadeIn>
          <div className="titlebar">
            <h2 className="titlebar__title org-titlebar__title">Governance</h2>
          </div>

          <div style={{width: '3rem', margin: '0 auto'}}>
            <HiThereLoader />
          </div>
          <p className="text-center">Loading&hellip;</p>
        </FadeIn>
      </Wrap>
    );
  }

  // Return if no proposals were found.
  if (snapshotNoProposalsFoundAll) {
    return (
      <Wrap className={'section-wrapper'}>
        <FadeIn>
          <div className="titlebar">
            <h2 className="titlebar__title org-titlebar__title">Governance</h2>
            <button
              className="titlebar__action org-titlebar__action"
              onClick={goToGovernanceForm}>
              New Proposal
            </button>
          </div>

          <div className="no-data-container">
            <div className="emoji-container">
              <span role="img" aria-label="Emoji.">
                {orgLoaderEmoji}
              </span>
            </div>
            <p className="text-center org-bold">No proposals, yet.</p>
          </div>

          {showSidePocketProposals && <SidePocketProposals />}
        </FadeIn>
      </Wrap>
    );
  }

  return (
    <Wrap className={'section-wrapper'}>
      <FadeIn>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">Governance</h2>
          <button
            className="titlebar__action org-titlebar__action"
            onClick={goToGovernanceForm}>
            New Proposal
          </button>
        </div>

        {snapshotGovernanceProposalsStatus === FetchStatus.REJECTED && (
          <ErrorMessageWithDetails renderText="Something went wrong while getting the proposals." />
        )}

        <section
          className={
            'grid--fluid sections-grid-container org-sections-grid-container'
          }>
          {snapshotProposalsVotingAll.length > 0 && (
            <>
              <div className="sections-grid__header org-sections-grid__header">
                {ProposalHeaderNames.VOTING}
              </div>

              <div className="sections-grid__cards">
                {renderProposalCards(
                  snapshotProposalsVotingAll,
                  updateSnapshotProposals
                )}
              </div>
            </>
          )}
          {snapshotProposalsPassedAll.length > 0 && (
            <>
              <div className="sections-grid__header org-sections-grid__header">
                {ProposalHeaderNames.GOVERNANCE_APPROVED}
              </div>

              <div className="sections-grid__cards">
                {renderProposalCards(snapshotProposalsPassedAll)}
              </div>
            </>
          )}
          {snapshotProposalsFailedAll.length > 0 && (
            <>
              <div className="sections-grid__header org-sections-grid__header">
                {ProposalHeaderNames.FAILED}
              </div>

              <div className="sections-grid__cards">
                {renderProposalCards(snapshotProposalsFailedAll)}
              </div>
            </>
          )}

          {showSidePocketProposals && <SidePocketProposals />}
        </section>
      </FadeIn>
    </Wrap>
  );
}
