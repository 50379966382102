import React, {useEffect} from 'react';
import {Transition} from 'react-transition-group';
import {useSelector} from 'react-redux';

import {getOrgText} from '../../../util/helpers';
import {StoreState} from '../../../util/types';

import TelegramSVG from '../../../assets/svg/TelegramSVG';
import TwitterSVG from '../../../assets/svg/TwitterSVG';

import s from '../../../assets/scss/modules/interest.module.scss';

// see: http://reactcommunity.org/react-transition-group/transition
const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles: Record<string, any> = {
  entering: {opacity: 0},
  entered: {opacity: 1},
  exiting: {opacity: 0},
  exited: {opacity: 0},
};

export default function VerificationThankYou() {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgTelegramURL = getText('OrgTelegramURL');
  const orgTwitterURL = getText('OrgTwitterURL');
  const orgVerifyThankYouEmoji = getText('OrgVerifyThankYouEmoji');

  const telegramOrTwitterLinkExists =
    orgTelegramURL || orgTwitterURL ? true : false;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Transition appear in={true} timeout={duration}>
      {(transition) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[transition],
          }}>
          <div className={`${s['thankyou-text']} org-thankyou-text`}>
            <p>Woo. You&apos;re registered. We&apos;ll keep you updated.</p>
            <div
              className={`${s['emoji-moloch-wrapper']} org-emoji-moloch-wrapper`}>
              <span aria-label="org thank you emoji" role="img">
                {orgVerifyThankYouEmoji}
              </span>
            </div>
            {telegramOrTwitterLinkExists && (
              <p>In the meantime, click below and join the conversation.</p>
            )}
          </div>

          {telegramOrTwitterLinkExists && (
            <div
              className={`${s['thankyou-social']} org-thankyou-social ${s['square-container']}`}>
              {orgTelegramURL && (
                <div className={s['square-bg']}>
                  <a
                    href={orgTelegramURL}
                    rel="noopener noreferrer"
                    target="_blank">
                    <div
                      className={`${s['social-icon-wrap']} org-social-icon-wrap`}>
                      <TelegramSVG />
                    </div>
                  </a>
                </div>
              )}
              {orgTwitterURL && (
                <div className={s['square-bg']}>
                  <a
                    href={orgTwitterURL}
                    rel="noopener noreferrer"
                    target="_blank">
                    <div
                      className={`${s['social-icon-wrap']} org-social-icon-wrap`}>
                      <TwitterSVG />
                    </div>
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Transition>
  );
}
