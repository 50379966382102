import React, {Fragment, useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/react-hooks';

import {MemoizedProposalCard} from '../../../components/ui/ProposalCard';

import {
  useSidePocketProposals,
  useIsAdmin,
  usePageVisibility,
} from '../../../hooks';
import {SidePocketProposal, StoreState} from '../../../util/types';
import {GQL_QUERY_POLLING_INTERVAL} from '../../../util/config';
import {ProposalHeaderNames, ProposalStatuses} from '../../../util/enums';
import {getOrgText} from '../../../util/helpers';

import {GET_VOTING_PROPOSALS, GET_VOTING_DONE_PROPOSALS} from '../../../gql';

type RenderProposalType = {
  renderProposals: React.ReactNode | null;
  counter: number;
};

const INITIAL_RENDER_PROPOSAL = {
  renderProposals: null,
  counter: 0,
};

export default function SidePocketProposals() {
  const molochConstants = useSelector(
    (s: StoreState) => s.blockchain.molochConstants
  );
  const connectedMember = useSelector((s: StoreState) => s.connectedMember);
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  const isMember = connectedMember && connectedMember.isMemberActive;

  const history = useHistory();

  const isAdmin = useIsAdmin();
  const isPageVisible = usePageVisibility();
  const {sidePocketProposals: proposals} = useSidePocketProposals();
  const {summoningTime = 0, periodDuration = 0} = molochConstants || {};
  const now =
    Math.floor(
      (Math.floor(Date.now() / 1000) - Number(summoningTime)) /
        Number(periodDuration)
    ) || 0;

  const getText = getOrgText(orgText);
  const orgLoaderEmoji = getText('OrgLoaderEmoji');

  /**
   * GQL Queries
   */

  const getVotingProposals = useQuery(GET_VOTING_PROPOSALS, {
    variables: {currentPeriod: now},
    pollInterval: GQL_QUERY_POLLING_INTERVAL,
  });
  const getVotingDoneProposals = useQuery(GET_VOTING_DONE_PROPOSALS, {
    variables: {currentPeriod: now},
    pollInterval: GQL_QUERY_POLLING_INTERVAL,
  });

  /**
   * State
   */

  const [investmentProposals, setInvestmentProposals] =
    useState<RenderProposalType>(INITIAL_RENDER_PROPOSAL);
  const [failedProposals, setFailedProposals] = useState<RenderProposalType>(
    INITIAL_RENDER_PROPOSAL
  );
  const [votingProposals, setVotingProposals] = useState<RenderProposalType>(
    INITIAL_RENDER_PROPOSAL
  );
  const [requestProposals, setRequestProposals] = useState<RenderProposalType>(
    INITIAL_RENDER_PROPOSAL
  );

  /**
   * Cached callbacks
   */

  const handleProposalDetailsCached = useCallback(handleProposalDetails, [
    history,
  ]);

  const renderProposalCardsCached = useCallback(renderProposalCards, [
    proposals,
    handleProposalDetailsCached,
    molochConstants,
  ]);

  const noProposalsFound =
    !getVotingProposals.loading &&
    !getVotingDoneProposals.loading &&
    !votingProposals.counter &&
    !investmentProposals.counter &&
    !failedProposals.counter &&
    !requestProposals.counter;

  useEffect(() => {
    if (isPageVisible) {
      getVotingProposals.startPolling(GQL_QUERY_POLLING_INTERVAL);
      getVotingDoneProposals.startPolling(GQL_QUERY_POLLING_INTERVAL);
    } else {
      getVotingProposals.stopPolling();
      getVotingDoneProposals.stopPolling();
    }
  }, [isPageVisible, getVotingProposals, getVotingDoneProposals]);

  /**
   * FUNDED
   *
   * Any proposal that has more "Yes" shares voted than "No" shares voted after
   * the voting period has ended (regardless of whether the proposal has been
   * processed)
   */
  useEffect(() => {
    try {
      if (!getVotingDoneProposals.loading && proposals) {
        const {sidepocket} = getVotingDoneProposals.data;
        const votingDoneProposalData = [...sidepocket];
        const {renderProposals, counter}: any = renderProposalCardsCached(
          getPassedProposals(votingDoneProposalData)
        );

        setInvestmentProposals({
          renderProposals: renderProposals.reverse(),
          counter,
        });
      }
    } catch (error) {
      setInvestmentProposals(INITIAL_RENDER_PROPOSAL);
    }
  }, [getVotingDoneProposals, proposals, renderProposalCardsCached]);

  /**
   * FAILED
   *
   * Any proposal that has less or equal "Yes" shares voted than "No" shares
   * voted after the voting period has ended (regardless of whether the proposal
   * has been processed)
   */
  useEffect(() => {
    try {
      if (!getVotingDoneProposals.loading && proposals) {
        const {sidepocket} = getVotingDoneProposals.data;

        const votingDoneProposalData = [...sidepocket];
        const {renderProposals, counter}: any = renderProposalCardsCached(
          getFailedProposals(votingDoneProposalData)
        );

        setFailedProposals({
          renderProposals: renderProposals.reverse(),
          counter,
        });
      }
    } catch (error) {
      setFailedProposals(INITIAL_RENDER_PROPOSAL);
    }
  }, [getVotingDoneProposals, proposals, renderProposalCardsCached]);

  /**
   * VOTING
   */
  useEffect(() => {
    try {
      if (!getVotingProposals.loading && proposals) {
        const {sidepocket} = getVotingProposals.data;
        const votingProposalData = [...sidepocket];
        const {renderProposals, counter}: any =
          renderProposalCardsCached(votingProposalData);

        setVotingProposals({
          renderProposals,
          counter,
        });
      }
    } catch (error) {
      setVotingProposals(INITIAL_RENDER_PROPOSAL);
    }
  }, [getVotingProposals, proposals, renderProposalCardsCached]);

  /**
   * REQUESTS
   * @note Get proposals that have been submitted to the database,
   * but do not have a `molochProposalId` or `molochProposalIndex`,
   * which indicates that the proposal needs to either be submitted or sponsored
   */
  useEffect(() => {
    try {
      if (proposals.length > 0) {
        const requestProposalData = proposals.filter(
          (p: any) => !p.molochProposalId || !p.molochProposalIndex
        );

        const renderProposals = requestProposalData.map(
          (currentProposal: any) => (
            <Fragment key={currentProposal.uuid}>
              <MemoizedProposalCard
                onClick={handleProposalDetailsCached}
                proposalDetails={{
                  ...molochConstants,
                  ...currentProposal,
                }}
                pathToProposal={`/guildbank-proposals/${currentProposal.id}`}
              />
            </Fragment>
          )
        );

        setRequestProposals({
          renderProposals,
          counter: renderProposals.length,
        });
      }
    } catch (error) {
      setRequestProposals(INITIAL_RENDER_PROPOSAL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposals, renderProposalCardsCached]);

  function getPassedProposals(fetchedProposalData: any[]) {
    const passedProposals = fetchedProposalData.filter(
      (proposal: SidePocketProposal) =>
        Number(proposal.yesShares) > Number(proposal.noShares)
    );

    return passedProposals;
  }

  function getFailedProposals(fetchedProposalData: any[]) {
    const failedProposals = fetchedProposalData.filter(
      (proposal: SidePocketProposal) =>
        Number(proposal.yesShares) <= Number(proposal.noShares)
    );

    return failedProposals;
  }

  function renderProposalCards(fetchedProposalData: any[]) {
    if (!fetchedProposalData) return;

    const reduced = (proposals as any).reduce(
      (filtered: Record<string, any>, currentProposal: SidePocketProposal) => {
        // find proposals the exist in the data and the subgraph
        const proposalExists = fetchedProposalData.find(
          (proposal: SidePocketProposal) => {
            return (
              Number(proposal.proposalId) ===
                Number(currentProposal.molochProposalId) &&
              currentProposal.status !== ProposalStatuses.PENDING
            );
          }
        );

        if (proposalExists) {
          const proposalCard = (
            <Fragment key={currentProposal.molochProposalId}>
              <MemoizedProposalCard
                onClick={handleProposalDetailsCached}
                proposalDetails={{
                  ...molochConstants,
                  ...proposalExists,
                  ...currentProposal,
                }}
                pathToProposal={`/guildbank-proposals/${currentProposal.id}`}
              />
            </Fragment>
          );
          filtered.push(proposalCard);
        }

        return filtered;
      },
      [] as any
    );

    return {
      renderProposals: reduced as React.ReactNode,
      counter: reduced.length as number,
    };
  }

  function handleProposalDetails(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();

    const {proposalId} = event.currentTarget.dataset;
    if (proposalId) {
      history.push(`/guildbank-proposals/${proposalId}`);
    }
  }

  return (
    <>
      {(isMember || isAdmin) && (
        <>
          <div className="titlebar">
            <h2 className="titlebar__title org-titlebar__title">
              Guildbank Management
            </h2>
          </div>

          {/* Render if no proposals were found */}
          {noProposalsFound && (
            <div className="no-data-container">
              <div className="emoji-container">
                <span role="img" aria-label="Emoji.">
                  {orgLoaderEmoji}
                </span>
              </div>
              <p className="text-center org-bold">No proposals, yet.</p>
            </div>
          )}

          {votingProposals.counter > 0 && (
            <>
              <div className="sections-grid__header org-sections-grid__header">
                {ProposalHeaderNames.VOTING}
              </div>
              <div className="sections-grid__cards">
                {proposals && !getVotingProposals.loading && (
                  <>{votingProposals.renderProposals}</>
                )}
              </div>
            </>
          )}
          {investmentProposals.counter > 0 && (
            <>
              <div className="sections-grid__header org-sections-grid__header">
                {ProposalHeaderNames.INVESTMENTS}
              </div>
              <div className="sections-grid__cards">
                {proposals && !getVotingDoneProposals.loading && (
                  <>{investmentProposals.renderProposals}</>
                )}
              </div>
            </>
          )}
          {requestProposals.counter > 0 && (
            <>
              <div className="sections-grid__header org-sections-grid__header">
                {ProposalHeaderNames.REQUESTS}
              </div>
              <div className="sections-grid__cards">
                {proposals && <>{requestProposals.renderProposals}</>}
              </div>
            </>
          )}
          {failedProposals.counter > 0 && (
            <>
              <div className="sections-grid__header org-sections-grid__header">
                {ProposalHeaderNames.FAILED}
              </div>
              <div className="sections-grid__cards">
                {proposals && !getVotingDoneProposals.loading && (
                  <>{failedProposals.renderProposals}</>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
