import React from 'react';

import FadeIn from './FadeIn';

import s from '../../assets/scss/modules/inputerror.module.scss';

type InputErrorProps = {
  error: string;
};

export default function InputError(
  props: InputErrorProps & React.HtmlHTMLAttributes<HTMLParagraphElement>
) {
  const {error, ...restProps} = props;

  return (
    <>
      {error && (
        <FadeIn>
          <p
            {...restProps}
            className={`error-message org-error-message ${s.error} ${
              restProps.className || ''
            }`}>
            {error}
          </p>
        </FadeIn>
      )}
    </>
  );
}
