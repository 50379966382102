import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {dontCloseWindowWarning} from '../../util/helpers';
import {ETHERSCAN_URLS} from '../../util/config';
import {StoreState} from '../../util/types';
import {useETHGasPrice} from '../../hooks';

export type ProposeActionArguments = [
  // `minionActionAddress` is the `applicant`
  string,
  // `requestedAmountInWEI`
  string,
  // `minionActionData`
  string,
  // `details`
  string
];

type MinionProposeActionChildrenProps = {
  error?: Error;
  etherscanURL: string;
  isPromptOpen: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  openPrompt: () => void;
};

type MinionProposeActionProps = {
  /**
   * **ProposeActionArguments**
   *
   * @param {string} minionActionAddress - Address of the action contract
   * @param {string} amountOfWei - Amount of payment requested in WEI
   * @param {string} minionActionData - Byte data for the encoded contract
   * @param {string} details - Any string (short) detailing the proposal, prefixed with `Minion:`
   */
  proposalArguments: ProposeActionArguments | undefined;
  /**
   * Runs before wallet prompt opens; provides an
   * opportunity to run any validation.
   *
   * If an `Error` is thrown, the prompt will not open and
   * an `error` argument will be provided to the `render` prop.
   */
  onBeforeProcess?: () => void;
  onProcess?: (txHash: string) => void;
  onError?: (e: Error) => void;
  onComplete?: (rv: Record<string, any>) => void;
  render: (props: MinionProposeActionChildrenProps) => React.ReactElement;
};

/**
 * MinionProposeAction
 *
 * Returns a rendered component via a render prop.
 * MinionProposeActionChildrenProps are passed to the render prop.
 *
 * @param {MinionProposeActionProps} props
 * @returns {FunctionComponent}
 */
export default function MinionProposeAction(props: MinionProposeActionProps) {
  /**
   * State
   */

  const [error, setError] = useState<Error>();
  const [etherscanURL, setEtherscanURL] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /**
   * Selectors
   */

  const web3Instance = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.web3Instance
  );
  const Minion = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.Minion
  );
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );

  /**
   * External hooks
   */

  const {average: gasPrice} = useETHGasPrice();

  /**
   * Variables
   */

  const {
    onBeforeProcess,
    onError,
    onProcess,
    onComplete,
    proposalArguments,
    render,
  } = props;

  /**
   * Functions
   */

  function handleError(error: Error) {
    setError(error);
    setIsSubmitted(false);
    setIsSubmitting(false);

    onError && onError(error);
  }

  async function handleOpenSubmitPrompt() {
    // activate "don't close window" warning
    const unsubscribeDontCloseWindow = dontCloseWindowWarning();

    try {
      // throw if there's empty `proposalArguments`
      if (!proposalArguments) {
        throw new Error('No proposal arguments were provided.');
      }

      // throw if there's an empty `applicant`
      if (!proposalArguments[0]) {
        throw new Error('No applicant address exists.');
      }

      if (!connectedAddress) {
        throw new Error(
          'No connected address was found. Is your wallet connected?'
        );
      }

      if (!web3Instance) {
        throw new Error("Web3 isn't ready yet");
      }

      if (!Minion) {
        throw new Error("Minion contract isn't ready yet");
      }

      const {methods} = (Minion && Minion.instance) || {};

      /**
       * Run user callback `onBeforeProcess`
       * @note We `await` just in case the value is a Promise.
       */
      onBeforeProcess && (await onBeforeProcess());

      setError(undefined);
      setEtherscanURL('');
      setIsPromptOpen(true);
      setIsSubmitted(false);
      setIsSubmitting(false);

      /**
       * proposeAction
       */
      const receipt = await methods['proposeAction'](...proposalArguments)
        .send({
          from: connectedAddress,
          // set proposed gas price
          ...(gasPrice ? {gasPrice} : null),
        })
        .on('transactionHash', function (txHash: string) {
          setIsPromptOpen(false);
          setIsSubmitting(true);
          setEtherscanURL(`${ETHERSCAN_URLS[chainId]}/tx/${txHash}`);

          // Maybe call user `onProcess` and handle any `Error`.
          try {
            onProcess && onProcess(txHash);
          } catch (error) {
            handleError(error);
          }
        })
        .on('error', (error: Error) => {
          handleError(error);

          // If user closed modal (MetaMask error code 4001)
          // or via WalletConnect, which only provides a message and no code
          setIsPromptOpen(false);

          unsubscribeDontCloseWindow();
        });

      // Tx `receipt` resolved; tx went through.
      setIsSubmitted(true);
      setIsSubmitting(false);

      /**
       * Get `returnValues` from `receipt`.
       *
       * @see https://web3js.readthedocs.io/en/v1.2.7/web3-eth-contract.html#id37
       */
      const {
        ActionProposed: {returnValues},
      } = receipt.events;

      // Send `returnValues` in callback.
      onComplete && onComplete(returnValues);

      unsubscribeDontCloseWindow();
    } catch (error) {
      setIsPromptOpen(false);

      handleError(error);

      unsubscribeDontCloseWindow();
    }
  }

  return render({
    error,
    etherscanURL,
    isPromptOpen,
    isSubmitted,
    isSubmitting,
    openPrompt: handleOpenSubmitPrompt,
  });
}
