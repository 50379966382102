import React from 'react';

import {formatNumber} from '../../util/helpers';
import {useLatestETHPrice, useTotalAmountContributedMongo} from '../../hooks';
import FadeIn from '../../components/common/FadeIn';

import s from '../../assets/scss/modules/contributedanddeployedbalances.module.scss';
import i from '../../assets/scss/modules/interest.module.scss';

type TotalContributedAmountProps = {
  render?: (
    v: ReturnType<typeof useTotalAmountContributedMongo> & {
      formatted: string;
    }
  ) => JSX.Element | null;
  rootElementProps?: React.HTMLAttributes<HTMLDivElement>;
};

export default function TotalContributedAmount(
  props: TotalContributedAmountProps
): JSX.Element | null {
  const {render, rootElementProps} = props;

  const latestETHPrice = useLatestETHPrice();
  const result = useTotalAmountContributedMongo();

  // Custom render
  if (render) {
    return render({
      ...result,
      formatted: result.amountContributed
        ? formatNumber(Math.floor(result.amountContributed))
        : '0',
    });
  }

  const {amountContributed} = result;

  // Don't render text until there is a value
  if (!amountContributed) {
    // By assigning any of the same props (e.g. styles) it helps the text not unexpectedly "jump".
    return <div aria-hidden {...rootElementProps} />;
  }

  return (
    <FadeIn>
      <div className={i['eth-data']}>
        <div {...rootElementProps} className={s['balances-container']}>
          <span className={s['eth-amount']}>
            {formatNumber(Math.floor(amountContributed))} ETH Contributed
          </span>
          <span
            className={`${s['usd-amount']} ${s['usd-amount--contributed']}`}
            style={{marginBottom: 0}}>
            $
            {formatNumber(
              (Math.floor(amountContributed) * (latestETHPrice || 0)).toFixed(2)
            )}
          </span>
        </div>
      </div>
    </FadeIn>
  );
}
