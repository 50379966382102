import React, {useState} from 'react';

import s from '../../assets/scss/modules/emojitoggle.module.scss';

type EmojiToggleType = {
  /**
   * An array of emojis to use. The 0 index is for `false`, 1 is for `true`.
   */
  emojis: string[];
  /**
   * Sets an initial value for the toggle.
   */
  isChecked: boolean;
  /**
   * Sets the input to disabled, if `true`.
   */
  isDisabled?: boolean;
  /**
   * A callback triggered after a user toggles the input.
   */
  onToggle?: (isChecked: boolean) => void;
  /**
   * Text to use for labels. The 0 index is for `false`, 1 is for `true`.
   */
  text: string[];
};

export default function EmojiToggle(props: EmojiToggleType) {
  const {emojis, isChecked, isDisabled, onToggle, text} = props;

  const [checked, setChecked] = useState<boolean>(isChecked);

  const toggleSwitchClass = checked
    ? `${s['switch--on']} org-switch--on`
    : `${s['switch--off']} org-switch--off`;

  function handleToggleChecked(event: React.ChangeEvent<HTMLInputElement>) {
    if (isDisabled) return;

    const checkedValue = event.target.checked;

    setChecked(checkedValue);

    onToggle && onToggle(checkedValue);
  }

  return (
    <div>
      {/* HIDDEN CHECKBOX INPUT */}
      <input
        aria-checked={checked}
        checked={checked}
        className={s.checkbox}
        disabled={isDisabled}
        id="view-mode"
        onChange={handleToggleChecked}
        type="checkbox"
      />

      {/* LABEL CONTAINER */}
      <label
        aria-label={`Change proposal privacy to ${
          checked ? 'public' : 'private'
        }`}
        className={s.container}
        htmlFor="view-mode">
        {/* OFF LABEL */}
        <span
          className={`${s['toggle-text']} ${s['toggle-text__off']} ${
            !checked
              ? `${s['toggle-text--selected']} org-toggle-text--selected`
              : ''
          }`}>
          {text[0]}
        </span>

        {/* EMOJI SWITCH */}
        <div className={`${s.switch} ${toggleSwitchClass} org-switch`}>
          <span className={s.switch__emoji} role="img">
            {checked ? emojis[1] : emojis[0]}
          </span>
        </div>

        {/* ON LABEL */}
        <span
          className={`${s['toggle-text']} ${s['toggle-text__on']} ${
            checked
              ? `${s['toggle-text--selected']} org-toggle-text--selected`
              : ''
          }`}>
          {text[1]}
        </span>
      </label>
    </div>
  );
}
