import React from 'react';

export default function DefaultBlockieSVG(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      {...props}>
      <image
        fill="none"
        fillRule="evenodd"
        height="35"
        stroke="none"
        strokeWidth="1"
        width="35"
        x="0"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAAAXNSR0IArs4c6QAAAMVJREFUWAntljsOxCAMRHdXtBRcgDNyZi5AwQH2I6XCz5KLSDuF0zGyrMnzxOE5xng/jmetdSj3H1trpunLKH8U0owHX4pM8VyeOgXurPHO0Q9Cikya8cYpRabMOT2jt+l7b9OLNCkyacbM7BKkyJRaqzFK2za6RU2zr9B7NzL1kyKTZszMLkGKDF4hKFzRUEfriI4UmTRDI/ppUmQK/cqjG5PeMBp+urpIkUkzNF69AFNYyTltVgphtB/VZWaIvFxmpMb0AVECL6FcPAvJAAAAAElFTkSuQmCC"
        y="0"
      />
    </svg>
  );
}
