import React, {useState} from 'react';
import ReactModal from 'react-modal';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import Web3 from 'web3';

import {
  useKYCCheck,
  useKYCContributionWhitelist,
  useInvestmentProposalsParsedSponsored,
} from '../../hooks';
import {CenterLogo} from '../../components/logo';
import {getOrgText} from '../../util/helpers';
import {StoreState} from '../../util/types';
import {useSales} from '../../hooks';
import {useWeb3Modal} from '../../components/web3/Web3ModalManager';
import DAOBalancesText from '../sale/DAOBalancesText';
import FadeIn from '../../components/common/FadeIn';
import HiThereLoader from '../../components/feedback/HiThereLoader';
import InvestmentProposalsCards from '../proposals/InvestmentProposalsCards';
import SaleModal from '../sale/SaleModal';
import Web3ModalButton from '../../components/web3/Web3ModalButton';
import Wrap from '../../components/layout/Wrap';

import TimesSVG from '../../assets/svg/TimesSVG';

import b from '../../assets/scss/modules/buttons.module.scss';
import s from '../../assets/scss/modules/interest.module.scss';
import m from '../../assets/scss/modules/modal.module.scss';
import sm from '../../assets/scss/modules/sale.module.scss';

export default function GetStartedLiquidity() {
  /**
   * Selectors
   */

  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const orgDocsURL =
    useSelector((s: StoreState) => s.org && s.org.docsURL) ||
    'https://docs.neptunedao.xyz';
  const orgFeatures = useSelector((s: StoreState) => s.org && s.org.features);
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  const {account, connected} = useWeb3Modal();
  const history = useHistory();

  /**
   * Our hooks
   */

  const {sales} = useSales();

  const {userKYCInfo} = useKYCCheck();

  const {kycIsContributionAllowed} = useKYCContributionWhitelist();

  const {
    areInvestmentProposalsLoading,
    proposalEntriesPassed,
    filterInvestmentProposalsByViewable,
  } = useInvestmentProposalsParsedSponsored();

  /**
   * State
   */

  const [showSaleModal, setShowSaleModal] = useState<boolean>(false);
  const [showPendingVerificationModal, setShowPendingVerificationModal] =
    useState<boolean>(false);

  /**
   * Variables
   */

  const deployedCapitalFeatureFlag = orgFeatures && orgFeatures.deployedCapital;
  const getText = getOrgText(orgText);
  const orgInvestmentsText = getText('OrgInvestmentsText');

  // Get the last 3 passed proposals
  const passedProposals = filterInvestmentProposalsByViewable(
    proposalEntriesPassed
  )
    .map((p) => p[2])
    .filter((_, i) => i < 3);

  // Get the total ETH requested from Moloch passed proposals
  const molochEthRequested = getMolochDeployedEthAmount(passedProposals);

  // Get the total ETH requested from snapshot passed proposals
  const snapshotEthRequested = getSnapshotDeployedEthAmount(passedProposals);

  // Get the total ETH requested from Moloch and snapshot passed proposals
  const totalEthRequested = (
    Number(molochEthRequested) + snapshotEthRequested
  ).toString();

  /**
   * Functions
   */

  /**
   * Sums total ETH requested from moloch passed proposals (after the voting
   * period has ended and regardless of whether the proposal has been processed)
   */
  function getMolochDeployedEthAmount(
    proposals: typeof passedProposals
  ): string {
    const committedWeiAmounts = proposals
      .filter((p) => p.proposalTarget === 'moloch')
      .map((proposal) =>
        Web3.utils.toBN((proposal as Record<string, any>).paymentRequested)
      );
    const sum: string = committedWeiAmounts.reduce((a, b) => {
      return Web3.utils.toBN(a.toString()).add(Web3.utils.toBN(b.toString()));
    }, 0 as any);
    const totalCommittedWeiAmount = Web3.utils.toBN(sum);

    return Web3.utils.fromWei(totalCommittedWeiAmount, 'ether');
  }

  /**
   * Sums total ETH requested from snapshot passed proposals (after the voting
   * period has ended)
   */
  function getSnapshotDeployedEthAmount(
    snapshotProposals: typeof passedProposals
  ): number {
    return snapshotProposals
      .filter((p) => p.proposalTarget === 'snapshot')
      .reduce(
        (acc, currentValue) => acc + Number(currentValue.amountRequested),
        0
      );
  }

  function handleModalClose() {
    showSaleModal && setShowSaleModal(false);
    showPendingVerificationModal && setShowPendingVerificationModal(false);
  }

  function DisabledReason() {
    return (
      <ReactModal
        ariaHideApp={false}
        className={`${m['modal-content-wide']}`}
        isOpen={showPendingVerificationModal}
        onRequestClose={handleModalClose}
        overlayClassName={`${m['modal-overlay']} org-modal-overlay`}
        role="dialog"
        style={
          {overlay: {zIndex: '99'}, content: {maxWidth: '32.5rem'}} as any
        }>
        <FadeIn>
          <div
            className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} org-modal`}>
            <div className={`${sm.sales} card`}>
              {/* MODEL CLOSE BUTTON */}
              <span
                className={`${b['modal-close']} org-modal-close`}
                onClick={handleModalClose}>
                <TimesSVG />
              </span>

              <p className={`text-center org-notification info`}>
                We&rsquo;re still verifying your information
              </p>
            </div>
          </div>
        </FadeIn>
      </ReactModal>
    );
  }

  return (
    <Wrap
      className={`${s['wrap']} org-splash-wrap ${
        deployedCapitalFeatureFlag ? 'deployed-capital' : ''
      }`}>
      <div className="org-splash-neptune-head">
        <img
          alt="Neptune head"
          src={`/orgs/${orgInternalName}/images/neptune-head.png`}
        />
      </div>

      <header className={s['eth-data-container']}>
        <CenterLogo />

        <div className="org-dao-data">
          <DAOBalancesText
            deployedETHAmount={
              deployedCapitalFeatureFlag ? totalEthRequested : undefined
            }
            sales={sales}
            showDeployedETHAmount={deployedCapitalFeatureFlag}
            showRemainingAmount={!deployedCapitalFeatureFlag}
          />
        </div>
      </header>

      <div className={`${s['middle-links']} org-middle-links`}>
        {account && connected ? (
          <button
            className={`${b['primary']} ${s['middle-links__button']} org-middle-links__button`}
            onClick={() => {
              !userKYCInfo || !Object.keys(userKYCInfo).length
                ? history.push('/verify-start')
                : userKYCInfo.isVerified &&
                  kycIsContributionAllowed(userKYCInfo.ethereumAddress)
                ? setShowSaleModal(true)
                : setShowPendingVerificationModal(true);
            }}>
            Inject
          </button>
        ) : (
          <Web3ModalButton customWalletText="Inject" />
        )}

        <span className={`${s['middle-links__link']} org-middle-links__link`}>
          {deployedCapitalFeatureFlag ? (
            <Link to={`/${orgInvestmentsText.toLowerCase()}`}>Supporting</Link>
          ) : (
            <a href={orgDocsURL} rel="noopener noreferrer" target="_blank">
              Learn More
            </a>
          )}
        </span>
      </div>

      {deployedCapitalFeatureFlag && (
        <div className={`${s['recent-investments']} org-recent-investments`}>
          {areInvestmentProposalsLoading ? (
            <>
              <div style={{width: '3rem', margin: '0 auto'}}>
                <HiThereLoader />
              </div>

              <p className="text-center">Loading&hellip;</p>
            </>
          ) : (
            <section
              className={
                'grid--fluid sections-grid-container org-sections-grid-container'
              }>
              <div
                className={`sections-grid__cards${
                  passedProposals.length === 1
                    ? ' sections-grid__cards--center-one'
                    : ''
                }`}>
                <InvestmentProposalsCards proposals={passedProposals} />
              </div>
            </section>
          )}
        </div>
      )}

      <div
        className={`${s['submit-proposal-container']} org-submit-proposal-container`}>
        <div className={`${s['submit-proposal']} org-submit-proposal`}>
          <div
            className={`${s['submit-proposal__title']} org-submit-proposal__title`}>
            The Big Shore
          </div>
          <div
            className={`${s['submit-proposal__text']} org-submit-proposal__text`}>
            More liquidity provision enables a better experience for DeFi users
            and increased adoption for platforms. In exchange for providing
            liquidity, providers receive YIELD for their services. This is a DAO
            with this single purpose.
          </div>
        </div>
      </div>

      {showSaleModal && userKYCInfo?.isVerified && (
        <SaleModal
          onRequestClose={handleModalClose}
          sales={sales}
          show={showSaleModal}
          userInfo={userKYCInfo}
        />
      )}

      {showPendingVerificationModal && !userKYCInfo?.isVerified && (
        <DisabledReason />
      )}
    </Wrap>
  );
}
