import {useState} from 'react';
import {useSelector} from 'react-redux';

import {getBalancerHubVersion, SNAPSHOT_HUB_URL} from './helpers';
import {ethPersonalSign} from '../../util/helpers';
import {FetchStatus} from '../../util/enums';
import {useIsDefaultChain} from '../../hooks';
import {SnapshotVote} from './enums';
import {StoreState} from '../../util/types';
import {SnapshotProposal} from './types';

type UseCreateSnapshotVoteReturn = {
  createSnapshotVote: (v: SnapshotVote) => () => Promise<void>;
  snapshotVoteChosen: SnapshotVote | undefined;
  snapshotVoteSignStatus: FetchStatus;
  snapshotVoteSubmitError: Error | undefined;
  snapshotVoteSubmitStatus: FetchStatus;
  snapshotSubmitIsInProcessOrDone: boolean;
};

export default function useCreateSnapshotVote(
  proposal: SnapshotProposal
): UseCreateSnapshotVoteReturn {
  /**
   * Hooks
   */
  const {isDefaultChain, defaultChainError} = useIsDefaultChain();

  /**
   * State
   */

  const [voteChosen, setVoteChosen] = useState<SnapshotVote>();
  const [submitError, setSubmitError] = useState<Error>();
  const [submitStatus, setSubmitStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );
  const [signStatus, setSignStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );

  /**
   * Selectors
   */

  const connectedMember = useSelector((s: StoreState) => s.connectedMember);

  const snapshotToken = useSelector(
    (s: StoreState) => s.org && s.org.snapshotToken
  );

  const web3Instance = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.web3Instance
  );

  /**
   * Variables
   */

  const isInProcessOrDone =
    signStatus === FetchStatus.PENDING ||
    submitStatus === FetchStatus.PENDING ||
    (signStatus === FetchStatus.FULFILLED &&
      submitStatus === FetchStatus.FULFILLED);

  /**
   * Functions
   */

  async function getMessage(vote: SnapshotVote): Promise<string> {
    if (!web3Instance) {
      throw new Error('Please check if your wallet is connected.');
    }

    if (!snapshotToken) {
      throw new Error('No snapshot token was found.');
    }

    try {
      const hubVersion = await getBalancerHubVersion();
      // @note The Snapshot API does not accept falsy choices like index `0`.
      const choiceIndex =
        proposal.msg.payload.choices.findIndex((c) => c === vote) + 1;

      if (choiceIndex <= 0) {
        throw new Error(
          'Could not find the vote choice in the proposal choices.'
        );
      }

      return JSON.stringify({
        payload: {
          choice: choiceIndex,
          proposal: proposal.authorIpfsHash,
          metadata: {
            /**
             * @note We add the memberAddress so we can accurately look up shares.
             *   e.g. Delegate key was updated after Snapshot was created.
             */
            memberAddress: connectedMember.memberAddress,
          },
        },
        // seconds string
        timestamp: (Date.now() / 1000).toFixed(),
        /**
         * Required by API for generating unique key string for storage
         * Should be the same as the proposal's `key`.
         */
        token: snapshotToken,
        type: 'vote',
        // must match the Snapshot Hub's package.json version
        version: hubVersion,
      });
    } catch (error) {
      throw error;
    }
  }

  /**
   * handleCreateProposal
   *
   * Handles submitting the proposal to the Snapshot API.
   *
   * @returns {() => Promise<void>}
   */
  function handleCreateVote(vote: SnapshotVote) {
    return async () => {
      setVoteChosen(vote);

      try {
        if (!web3Instance) {
          throw Error('No Web3 instance was found.');
        }

        if (!connectedMember.delegateKey) {
          throw Error('No member address was found.');
        }

        if (!isDefaultChain) {
          throw new Error(defaultChainError);
        }

        const message = await getMessage(vote);
        const signature = await handleEthSign(message);

        setSubmitStatus(FetchStatus.PENDING);

        const response = await fetch(`${SNAPSHOT_HUB_URL}/api/message`, {
          method: 'POST',
          body: JSON.stringify({
            address: connectedMember.delegateKey,
            msg: message,
            sig: signature,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          const errorJSON = await response.json();
          throw new Error(JSON.stringify(errorJSON));
        }

        setSubmitStatus(FetchStatus.FULFILLED);
      } catch (error) {
        setVoteChosen(undefined);
        setSubmitError(error);
        setSubmitStatus(FetchStatus.REJECTED);
      }
    };
  }

  async function handleEthSign(message: string) {
    try {
      if (!web3Instance) {
        throw new Error('Please check if your wallet is connected.');
      }

      setSignStatus(FetchStatus.PENDING);

      const signature = await ethPersonalSign(
        [message, connectedMember.delegateKey, ''],
        web3Instance
      );

      setSignStatus(FetchStatus.FULFILLED);

      return signature;
    } catch (error) {
      setSignStatus(FetchStatus.REJECTED);

      throw error;
    }
  }

  return {
    createSnapshotVote: handleCreateVote,
    snapshotVoteChosen: voteChosen,
    snapshotVoteSignStatus: signStatus,
    snapshotVoteSubmitError: submitError,
    snapshotVoteSubmitStatus: submitStatus,
    snapshotSubmitIsInProcessOrDone: isInProcessOrDone,
  };
}
