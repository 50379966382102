import Web3 from 'web3';

import {getCurrentUser} from '../actions';
import {StoreState, Moloches, SmartContractItem} from '../../util/types';
import {Web3State} from '../../util/enums';
import ChainlinkETHPrice from '../../truffle-contracts/ChainlinkETHPrice.json';
import EasyApply from '../../truffle-contracts/EasyApply.json';
import Minion from '../../truffle-contracts/Minion.json';
import Moloch from '../../truffle-contracts/Moloch.json';
import WrapETH from '../../truffle-contracts/WrapETH.json';
import {Dispatch} from 'redux';

export const BLOCKCHAIN_SIGNUP_SIGNATURE = 'BLOCKCHAIN_SIGNUP_SIGNATURE';
export const BLOCKCHAIN_WALLET_AUTHENTICATED =
  'BLOCKCHAIN_WALLET_AUTHENTICATED';
export const BLOCKCHAIN_WEB3_STATE = 'BLOCKCHAIN_WEB3_STATE';
export const CONNECTED_ADDRESS = 'CONNECTED_ADDRESS';
export const BLOCKCHAIN_CONTRACTS = 'BLOCKCHAIN_CONTRACTS';
export const BLOCKCHAIN_WEB3_INSTANCE = 'BLOCKCHAIN_WEB3_INSTANCE';
export const BLOCKCHAIN_MOLOCH_CONSTANTS = 'BLOCKCHAIN_MOLOCH_CONSTANTS';
export const BLOCKCHAIN_DEFAULT_CHAIN = 'BLOCKCHAIN_DEFAULT_CHAIN';

/**
 * setConnectedAddress
 *
 * @param {string} selectedAddress
 * @param {string} backendURL - Required as an arg, as we don't know if the org has been fetched
 *   yet and we need the id to build the backend URL. Therefore we can't use `getState()` from redux thunk.
 */
export function setConnectedAddress(
  selectedAddress: string,
  backendURL: string
) {
  return async function (dispatch: any, getState: () => StoreState) {
    dispatch({type: CONNECTED_ADDRESS, connectedAddress: selectedAddress});

    dispatch(
      selectedAddress
        ? web3State(Web3State.Connected)
        : web3State(Web3State.Locked)
    );

    try {
      if (!selectedAddress) return;

      const delegateOrMemberAddress = await getMemberDelegateKey(
        selectedAddress,
        getState().blockchain.contracts?.VentureMoloch
      );

      // Check if user exists in The LAO
      await dispatch(getCurrentUser(delegateOrMemberAddress, backendURL));
    } catch (error) {}
  };
}

async function getMemberDelegateKey(
  selectedAddress: string,
  ventureMolochContract: SmartContractItem | undefined
): Promise<string> {
  if (!ventureMolochContract) {
    // Just return the selected address
    return selectedAddress;
  }

  // Check if connected address if delegated to get member address
  const memberAddressByDelegateKey =
    await ventureMolochContract.instance.methods
      .memberAddressByDelegateKey(selectedAddress)
      .call({from: selectedAddress});

  return memberAddressByDelegateKey.startsWith('0x00000')
    ? selectedAddress
    : memberAddressByDelegateKey;
}

export function walletAuthenticated(isAuthenticated: boolean) {
  return {
    type: BLOCKCHAIN_WALLET_AUTHENTICATED,
    walletAuthenticated: isAuthenticated,
  };
}

export function getMolochConstants(molochConstants: Moloches) {
  return {
    type: BLOCKCHAIN_MOLOCH_CONSTANTS,
    molochConstants,
  };
}

export function web3State(web3State: string) {
  return {
    type: BLOCKCHAIN_WEB3_STATE,
    web3State,
  };
}

export function initWeb3Instance(instance: Web3) {
  return {
    type: BLOCKCHAIN_WEB3_INSTANCE,
    web3Instance: instance,
  };
}

export function initChainlinkETHPrice(web3Instance: Web3) {
  return async function (dispatch: Dispatch<any>) {
    try {
      if (web3Instance) {
        const networkId = await web3Instance.eth.net.getId();
        /**
         * @note IMPORTANT
         * mainnet - uses new proxy contract
         * rinkeby - uses new proxy contract
         * @see https://docs.chain.link/docs/price-feeds-migration-august-2020
         */
        const chainlinkJSON: Record<string, any> = ChainlinkETHPrice;
        const deployedNetwork: any = chainlinkJSON.networks[networkId];

        const instance = new web3Instance.eth.Contract(
          chainlinkJSON.abi,
          deployedNetwork && deployedNetwork.address
        );

        dispatch({
          type: BLOCKCHAIN_CONTRACTS,
          contracts: {
            ChainlinkETHPrice: {
              instance,
              abi: chainlinkJSON.abi,
              contractAddress: deployedNetwork.address,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function initContractVentureMoloch(web3Instance: Web3) {
  return async function (
    dispatch: Dispatch<any>,
    getState: () => StoreState
  ): Promise<any> {
    try {
      if (web3Instance) {
        const molochContract: Record<string, any> = Moloch;
        const orgState = getState().org;
        const contractAddress = orgState ? orgState.contractMolochAddress : '';

        const instance = new web3Instance.eth.Contract(
          molochContract.abi,
          contractAddress
        );

        dispatch({
          type: BLOCKCHAIN_CONTRACTS,
          contracts: {
            VentureMoloch: {
              instance,
              abi: molochContract.abi,
              contractAddress,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function initContractWrapETH(web3Instance: Web3) {
  return async function (dispatch: Dispatch<any>) {
    try {
      if (web3Instance) {
        const networkId = await web3Instance.eth.net.getId();
        const wrapETHContract: Record<string, any> = WrapETH;
        const deployedNetwork: any = wrapETHContract.networks[networkId];
        const contractAddress = deployedNetwork.address;
        const instance = new web3Instance.eth.Contract(
          wrapETHContract.abi,
          contractAddress
        );

        dispatch({
          type: BLOCKCHAIN_CONTRACTS,
          contracts: {
            WrapETH: {
              abi: WrapETH.abi,
              contractAddress,
              instance,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function initContractMinion(web3Instance: Web3) {
  return async function (
    dispatch: Dispatch<any>,
    getState: () => StoreState
  ): Promise<any> {
    try {
      if (web3Instance) {
        const minionContract: Record<string, any> = Minion;
        const orgState = getState().org;

        const contractAddress: string =
          (orgState && orgState.contractMinionAddress) || '';

        const instance = new web3Instance.eth.Contract(
          minionContract.abi,
          contractAddress
        );

        dispatch({
          type: BLOCKCHAIN_CONTRACTS,
          contracts: {
            Minion: {
              instance,
              abi: minionContract.abi,
              contractAddress,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function initContractEasyApply(web3Instance: Web3) {
  return async function (
    dispatch: Dispatch<any>,
    getState: () => StoreState
  ): Promise<any> {
    try {
      if (web3Instance) {
        const easyApplyContract: Record<string, any> = EasyApply;
        const orgState = getState().org;
        const contractAddress = orgState
          ? orgState.contractEasyApplyAddress
          : '';

        const instance = new web3Instance.eth.Contract(
          easyApplyContract.abi,
          contractAddress
        );

        dispatch({
          type: BLOCKCHAIN_CONTRACTS,
          contracts: {
            EasyApply: {
              instance,
              abi: easyApplyContract.abi,
              contractAddress,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}
