import {useState, useEffect} from 'react';

import {BACKEND_URL} from '../util/config';
import {useIsMounted} from './useIsMounted';

export type GeoResponse = {
  ip: string;
  is_eu: boolean;
  city: string;
  region: string;
  region_code: string;
  country_name: string;
  country_code: string;
  continent_name: string;
  continent_code: string;
  latitude: number;
  longitude: number;
  postal: string;
  calling_code: string;
  flag: string;
  emoji_flag: string;
  emoji_unicode: string;
  asn: {
    asn: string;
    name: string;
    domain: string;
    route: string;
    type: string;
  };
  languages: [
    {
      name: string;
      native: string;
    }
  ];
  currency: {
    name: string;
    code: string;
    symbol: string;
    native: string;
    plural: string;
  };
  time_zone: {
    name: string;
    abbr: string;
    offset: string;
    is_dst: true;
    current_time: string;
  };
  threat: {
    is_tor: boolean;
    is_proxy: boolean;
    is_anonymous: boolean;
    is_known_attacker: boolean;
    is_known_abuser: boolean;
    is_threat: boolean;
    is_bogon: boolean;
  };
  count: string;
};

const DEFAULT = {} as GeoResponse;
/**
 * @note Make sure you edit this name copied into the find regex below.
 */
// const COOKIE_NAME: string = "earth";

export default function useGeo(useCookie: boolean = true) {
  const [geo, setGeo] = useState<GeoResponse>(DEFAULT);

  /**
   * Our hooks
   */

  const {isMountedRef} = useIsMounted();

  /**
   * Effects
   */

  useEffect(() => {
    try {
      if (process.env.REACT_APP_ENVIRONMENT === 'localhost') {
        throw new Error('No geo lookups for localhost.');
      }

      // If the cookie hasn't expired, use it instead.
      /* if (hasGeoCookie()) {
        setGeo(JSON.parse(getGeoCookie()));

        return;
      } */

      fetch(`${BACKEND_URL}/geo`)
        .then(async (r) => {
          if (!r.ok) throw new Error('');
          return await r.json();
        })
        .then((r) => {
          if (!isMountedRef.current) return;

          setGeo(r);
          // createGeoCookie(r);
        })
        .catch(() => {
          if (!isMountedRef.current) return;

          setGeo(DEFAULT);
        });
    } catch {
      setGeo(DEFAULT);
    }
  }, [isMountedRef]);

  return geo;
}

// function createGeoCookie(geoData: GeoResponse) {
//   /**
//    * Stores a cookie so we limit the amount of API calls
//    * Expiration is 1 day.
//    *
//    * @note Safari will set expiry to "session".
//    */
//   document.cookie = `${COOKIE_NAME}=${JSON.stringify(
//     geoData
//   )};domain=.thelao.io;max-age=${60 * 60 * 24}`;

//   // If the user has not closed the tab in 1 day, delete the cookie.
//   // Many browsers let users specify that cookies should never expire.
//   setTimeout(deleteGeoCookie, 60 * 60 * 24 * 1000);
// }

/**
 * getGeoCookie
 *
 * @note If the cookie name changes, change it here, too.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
 */
/* function getGeoCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)earth\s*=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
} */

/* function hasGeoCookie(): boolean {
  if (
    document.cookie.split(";").some(i => i.trim().startsWith(`${COOKIE_NAME}=`))
  ) {
    return true;
  }

  return false;
} */

/* function deleteGeoCookie() {
  // Deletes a cookie by setting a time in the past.
  document.cookie = `${COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
} */

/**
 * @debug Use in place of useGeo(), or in place of the return value above.
 * 
 * {
    ip: '134.209.92.162',
    is_eu: true,
    city: 'Amsterdam',
    region: 'North Holland',
    region_code: 'NH',
    country_name: 'Netherlands',
    country_code: 'NL',
    continent_name: 'Europe',
    continent_code: 'EU',
    latitude: 52.374,
    longitude: 4.8897,
    postal: '1012',
    calling_code: '31',
    flag: 'https://ipdata.co/flags/nl.png',
    emoji_flag: '\ud83c\uddf3\ud83c\uddf1',
    emoji_unicode: 'U+1F1F3 U+1F1F1',
    asn: {
      asn: 'AS14061',
      name: 'DigitalOcean, LLC',
      domain: 'digitalocean.com',
      route: '134.209.80.0/20',
      type: 'hosting'
    },
    languages: [
      {
        name: 'Dutch',
        native: 'Nederlands'
      }
    ],
    currency: {
      name: 'Euro',
      code: 'EUR',
      symbol: '\u20ac',
      native: '\u20ac',
      plural: 'euros'
    },
    time_zone: {
      name: 'Europe/Amsterdam',
      abbr: 'CEST',
      offset: '+0200',
      is_dst: true,
      current_time: '2020-04-16T22:44:40.848845+02:00'
    },
    threat: {
      is_tor: false,
      is_proxy: true,
      is_anonymous: false,
      is_known_attacker: false,
      is_known_abuser: false,
      is_threat: false,
      is_bogon: false
    },
    count: '4'
  };
  */
