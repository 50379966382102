import {useEffect, useState, useCallback} from 'react';

import {FetchStatus} from '../util/enums';
import {GetInvestmentProposalResponse} from '../util/types';
import {useAbortController} from './useAbortController';
import {useBackendURL, useCounter} from './';

type UseInvestmentProposalFromDBState = {
  investmentProposal: GetInvestmentProposalResponse | undefined;
  investmentProposalError: Error | undefined;
  investmentProposalStatus: FetchStatus;
  investmentProposalNotFound: boolean;
  refetchInvestmentProposal: () => void;
};

/**
 * useInvestmentProposalFromDB
 *
 * Searches for a proposal entry by `uuid` from the
 * `proposals_investment_common` table and returns the result.
 *
 * @param {string} uuid - Unique ID of the investment proposal.
 * @returns {UseInvestmentProposalFromDBState}
 */
export default function useInvestmentProposalFromDB(
  uuid: string
): UseInvestmentProposalFromDBState {
  const [investmentProposal, setInvestmentProposal] =
    useState<GetInvestmentProposalResponse>();
  const [investmentProposalStatus, setInvestmentProposalStatus] =
    useState<FetchStatus>(FetchStatus.STANDBY);
  const [investmentProposalError, setInvestmentProposalError] =
    useState<Error>();
  const [investmentProposalNotFound, setInvestmentProposalNotFound] =
    useState<boolean>(false);

  /**
   * Our hooks
   */

  const backendURL = useBackendURL();
  // Send dispatch to consumer as a callback that we can "re-run" the fetchProposalFromDBCached effect.
  const [updateCounter, dispatchUpdateProposal] = useCounter();
  const {abortController, isMountedRef} = useAbortController();

  /**
   * Callbacks
   */
  const fetchProposalFromDBCached = useCallback(fetchProposalFromDB, [
    abortController?.signal,
    backendURL,
    isMountedRef,
    updateCounter,
    uuid,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    fetchProposalFromDBCached();
  }, [fetchProposalFromDBCached]);

  /**
   * Functions
   */

  async function fetchProposalFromDB() {
    try {
      if (!backendURL) return;
      if (!uuid) return;

      const isRefetch = updateCounter > 0;

      /**
       * If we're refetching don't reset the fetch status, do it silently, in case consumer
       * components rely on it to set their loading states (it won't show their loading UI, again).
       */
      !isRefetch && setInvestmentProposalStatus(FetchStatus.PENDING);

      setInvestmentProposalError(undefined);
      setInvestmentProposalNotFound(false);

      const response = await fetch(
        `${backendURL}/proposals-investment/${uuid}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          signal: abortController?.signal,
        }
      );

      if (!isMountedRef.current) return;

      if (response.status === 404) {
        setInvestmentProposal(undefined);
        setInvestmentProposalNotFound(true);
        setInvestmentProposalStatus(FetchStatus.FULFILLED);

        return;
      }

      if (!response.ok) {
        throw new Error(
          'Something went wrong while getting the investment proposal.'
        );
      }

      const proposal: GetInvestmentProposalResponse = await response.json();

      setInvestmentProposal(proposal);
      setInvestmentProposalStatus(FetchStatus.FULFILLED);
    } catch (error) {
      if (!isMountedRef.current) return;

      setInvestmentProposal(undefined);
      setInvestmentProposalStatus(FetchStatus.REJECTED);
      setInvestmentProposalError(error);
    }
  }

  function refetchInvestmentProposal() {
    dispatchUpdateProposal({type: 'increment'});
  }

  return {
    investmentProposal,
    investmentProposalError,
    investmentProposalNotFound,
    investmentProposalStatus,
    refetchInvestmentProposal,
  };
}
