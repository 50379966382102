import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {GetInvestmentProposalResponseSnapshot} from '../../../util/types';
import {SnapshotView} from '../../../components/snapshot/enums';
import SnapshotCountdown from './SnapshotCountdown';
import useInvestmentProposals from '../../../hooks/useInvestmentProposals';

export default function InvestmentSnapshots() {
  /**
   * Our hooks
   */

  const {investmentProposals} = useInvestmentProposals({sponsored: true});

  /**
   * State
   */

  const [snapshotInvestmentProposals, setSnapshotInvestmentProposals] =
    useState<GetInvestmentProposalResponseSnapshot[]>([]);

  const [inProgressSnapshots, setInProgressSnapshots] = useState<
    GetInvestmentProposalResponseSnapshot[]
  >([]);

  const [completedSnapshots, setCompletedSnapshots] = useState<
    GetInvestmentProposalResponseSnapshot[]
  >([]);

  const sortSnapshotStatusCallback = useCallback(sortSnapshotStatus, [
    snapshotInvestmentProposals,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    // @todo Once we have API query filtering for `target` we won't need this.
    setSnapshotInvestmentProposals(
      (investmentProposals as GetInvestmentProposalResponseSnapshot[]).filter(
        (p) => p.proposalTarget === 'snapshot'
      )
    );
  }, [investmentProposals]);

  useEffect(() => {
    sortSnapshotStatusCallback();
  }, [sortSnapshotStatusCallback]);

  /**
   * Functions
   */

  function handleRefresh() {
    sortSnapshotStatusCallback();
  }

  function sortSnapshotStatus() {
    const currentTimestamp = Math.ceil(Date.now() / 1000);

    const inProgress: GetInvestmentProposalResponseSnapshot[] = [];
    const completed: GetInvestmentProposalResponseSnapshot[] = [];

    snapshotInvestmentProposals.forEach(
      (s: GetInvestmentProposalResponseSnapshot) => {
        if (s.snapshotHubProposal) {
          const {
            msg: {
              payload: {end},
            },
          } = s.snapshotHubProposal;

          if (end < currentTimestamp) {
            completed.push(s);
          } else {
            inProgress.push(s);
          }
        }
      }
    );

    setInProgressSnapshots(inProgress);
    setCompletedSnapshots(completed);
  }

  function renderYesNoPercent(yesPercent: number, noPercent: number) {
    const didPass =
      yesPercent > noPercent || (yesPercent !== 0 && noPercent !== 0);

    return (
      <span role="img" aria-label="Status">
        {didPass ? '✅' : '⛔️'}
      </span>
    );
  }

  function renderSnapshotViews(
    snapshots: GetInvestmentProposalResponseSnapshot[],
    snapshotView: SnapshotView
  ) {
    if (snapshotView === SnapshotView.CREATED) {
      return snapshots.map((s, i) => (
        <tr key={`${snapshotView}-${i}`}>
          <td>{s.name}</td>
          <td>{s.addressToFund}</td>
          <td>
            {s.amountRequested} {s.amountRequestedCurrency}
          </td>
          <td>
            <Link to={`proposals/${s.uuid}`}>View</Link>
          </td>
        </tr>
      ));
    }

    return snapshots.map((s, i) => {
      const currentTimestamp = Math.ceil(Date.now() / 1000);

      return (
        <tr key={`${snapshotView}-${i}`}>
          <td>{s.name}</td>
          <td>{s.addressToFund}</td>
          <td>
            {s.amountRequested} {s.amountRequestedCurrency}
          </td>
          <td>
            {s.snapshotHubVotes &&
              s.snapshotHubVotes.votesByChoice.Yes.votePercent.toFixed(2)}
          </td>
          <td>
            {s.snapshotHubVotes &&
              s.snapshotHubVotes.votesByChoice.No.votePercent.toFixed(2)}
          </td>

          {s.snapshotHubProposal &&
            s.snapshotHubProposal.msg.payload.end > currentTimestamp && (
              <td>
                <SnapshotCountdown
                  endTimestamp={
                    s.snapshotHubProposal &&
                    s.snapshotHubProposal.msg.payload.end
                  }
                />
              </td>
            )}

          {s.snapshotHubProposal &&
            s.snapshotHubProposal.msg.payload.end < currentTimestamp && (
              <td>
                {s.snapshotHubVotes &&
                  renderYesNoPercent(
                    s.snapshotHubVotes.votesByChoice.Yes.votePercent,
                    s.snapshotHubVotes.votesByChoice.No.votePercent
                  )}
              </td>
            )}
          <td>
            <Link to={`proposals/${s.uuid}`}>View</Link>
          </td>
        </tr>
      );
    });
  }

  return (
    <div>
      <button onClick={handleRefresh}>Refresh</button>

      <h2>In Progress</h2>
      {inProgressSnapshots.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Address To Fund</th>
                <th>Amount</th>
                <th>Yes Votes (%)</th>
                <th>No Votes (%)</th>
                <th>End Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {renderSnapshotViews(
                inProgressSnapshots,
                SnapshotView.IN_PROGRESS
              )}
            </tbody>
          </table>
        </>
      ) : (
        'No investment proposals in progress'
      )}
      <h2>Completed</h2>
      {completedSnapshots.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Address To Fund</th>
                <th>Amount</th>
                <th>Yes Votes (%)</th>
                <th>No Votes (%)</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {renderSnapshotViews(completedSnapshots, SnapshotView.COMPLETED)}
            </tbody>
          </table>
        </>
      ) : (
        'No completed investment proposals'
      )}
    </div>
  );
}
