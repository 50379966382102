import React, {useEffect, useState, useCallback} from 'react';

import {getTimeRemaining, displaySimpleCountdown} from '../../util/helpers';
import {ProposalPeriodInfo} from '../../hooks/useProposalPeriodInfo';

import s from '../../assets/scss/modules/votingcard.module.scss';

type ProposalPeriodProps = {
  proposalPeriodInfo: ProposalPeriodInfo;
  simpleDisplay?: boolean;
};

export default function ProposalPeriod(props: ProposalPeriodProps) {
  const [proposalPeriod, setProposalPeriod] = useState<React.ReactNode>(null);

  const displaySimpleCountdownCached = useCallback(displaySimpleCountdown, []);

  useEffect(() => {
    if (!props.proposalPeriodInfo) return;
    const {startPeriod, endPeriod, gracePeriod} = props.proposalPeriodInfo;

    if (!startPeriod && !endPeriod && !gracePeriod) return;

    const interval = setInterval(() => {
      const currentDate = new Date();
      if (startPeriod && currentDate < startPeriod) {
        let start = <span>Voting starts: {displayCountdown(startPeriod)}</span>;
        if (props.simpleDisplay) {
          start = (
            <span>
              <span
                className={`${s['status']} ${s['status--starts']} org-status org-status--starts`}>
                Starts:
              </span>{' '}
              <span
                className={`${s['count-until-starts']} org-count-until-starts ${s['timer']}`}>
                {displaySimpleCountdownCached(startPeriod, true)}
              </span>
            </span>
          );
        }
        setProposalPeriod(start);
      } else if (endPeriod && currentDate < endPeriod) {
        let end = <span>Voting ends: {displayCountdown(endPeriod)}</span>;
        if (props.simpleDisplay) {
          end = (
            <span>
              <span className={`${s['status']} org-status`}>Ends:</span>{' '}
              <span
                className={`${s['count-until-ends']} org-count-until-ends ${s['timer']}`}>
                {displaySimpleCountdownCached(endPeriod)}
              </span>
            </span>
          );
        }
        setProposalPeriod(end);
      } else if (gracePeriod && currentDate < gracePeriod) {
        let grace = (
          <span>Grace period ends: {displayCountdown(gracePeriod)}</span>
        );
        if (props.simpleDisplay) {
          grace = (
            <span>
              <span className={`${s['status']} org-status`}>Grace Ends:</span>{' '}
              <span
                className={`${s['count-until-ends']} org-count-until-ends ${s['timer']}`}>
                {displaySimpleCountdownCached(gracePeriod)}
              </span>
            </span>
          );
        }
        setProposalPeriod(grace);
      } else {
        let ended = <span>Voting Ended</span>;
        if (props.simpleDisplay) {
          ended = (
            <span
              className={`${s['status']} ${s['status--ended']} org-status org-status--ended`}>
              Ended
            </span>
          );
        }
        setProposalPeriod(ended);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [
    props.proposalPeriodInfo,
    props.simpleDisplay,
    displaySimpleCountdownCached,
  ]);

  function displayCountdown(countdown: Date) {
    const remaining = getTimeRemaining(countdown);

    if (remaining.days > 0) {
      return `${remaining.days}d ${remaining.hours}h ${remaining.minutes}m ${remaining.seconds}s`;
    } else if (remaining.hours > 0) {
      return `${remaining.hours}h ${remaining.minutes}m ${remaining.seconds}s`;
    } else if (remaining.minutes > 0) {
      return `${remaining.minutes}m ${remaining.seconds}s`;
    } else {
      return (
        <span className={`${s['last-seconds']} org-last-seconds`}>
          {remaining.seconds}s
        </span>
      );
    }
  }

  return <div>{proposalPeriod}</div>;
}
