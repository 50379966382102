import React from 'react';
import {useSelector} from 'react-redux';

import {getOrgText} from '../util/helpers';
import {StoreState} from '../util/types';
import SocialMedia from './socialmedia';

import s from '../assets/scss/modules/footer.module.scss';

const year = () => new Date().getFullYear();

export default function Footer() {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const orgDocsURL =
    useSelector((s: StoreState) => s.org && s.org.docsURL) || '';
  const getText = getOrgText(orgText);
  const orgIncName = getText('OrgIncorporatedName');
  const orgPrimaryContactEmail = getText('OrgPrimaryContactEmail');

  return (
    <footer className={`${s.wrap} org-footer-wrap grid--fluid`}>
      <div className="grid__row">
        <div className={`${s.left} grid__col-lg-6 grid__col-12`}>
          <span className={`${s.item} org-footer-item`}>
            <span className={`${s['copyright-symbol']} org-copyright-symbol`}>
              &copy;
            </span>{' '}
            {year()} {orgIncName}
          </span>
          <a
            className={`${s.item} org-footer-item`}
            href={orgDocsURL}
            target="_blank"
            rel="noopener noreferrer">
            Help
          </a>
          <a className={`${s.item} org-footer-item`} href="/privacy">
            Privacy
          </a>
          <a
            className={`${s.item} org-footer-item`}
            href={`mailto:${orgPrimaryContactEmail}`}
            target="_blank"
            rel="noopener noreferrer">
            Message Us
          </a>
        </div>

        <div className={`${s.right} grid__col-lg-6 grid__col-12`}>
          <SocialMedia />
        </div>
      </div>
    </footer>
  );
}
