import {useState, useRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {FetchStatus} from '../../util/enums';
import {StoreState} from '../../util/types';
import {updateCurrentUser, updateCurrentUserData} from '../../store/actions';
import {useBackendURL} from '../../hooks';

enum IdentityKeys {
  emailAddress = 'emailAddress',
  username = 'username',
}

type Identity = {
  [k in IdentityKeys]: string;
};

type IdentityPayload = {
  [IdentityKeys.emailAddress]: string;
  [IdentityKeys.username]: string;
};

type UpdateMemberIdentityProps = {
  onError?: (e: Error) => void;
  onFetch?: (u: Partial<Identity>) => void;
  onProcess?: () => void;
  onSuccess?: (u: Partial<Identity>) => void;
  render: (p: UpdateMemberIdentityRenderProps) => React.ReactElement | null;
};

type UpdateMemberIdentityRenderProps = {
  currentIdentity: Identity | undefined;
  updateUserError: Error | undefined;
  updateUserStatus: FetchStatus;
  updateUserIdentity: (i: Partial<Identity>) => Promise<void>;
  createUserUsername: (u: string) => Promise<void>;
  updatedIdentity: Identity | undefined;
};

/**
 * UpdateMemberIdentity
 *
 * Handles updating the user's editable identity information.
 *
 * Currently allows updates for:
 *   - emailAddress (for contact)
 *   - username (for alias).
 *
 * @note It is connected to the app's Redux store.
 * @note It does not validate user input. This is deferred to the rendered component.
 * @todo Had to update the username piece as a specific email was needed, and therefore processing endpoint.
 *   Probably best to refactor that into a separate component or use a passed prop in the callback.
 *
 * @returns {React.ReactElement} Renders any ReactElement via render prop.
 */
export default function UpdateMemberIdentity(props: UpdateMemberIdentityProps) {
  const {onFetch} = props;

  /**
   * State
   */

  const [currentIdentity, setCurrentIdentity] = useState<Identity>();
  const [updateUserError, setUpdateUserError] = useState<Error>();
  const [updateUserStatus, setUpdateUserStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );

  /**
   * Refs
   */

  const updatedIdentity = useRef<Identity>();

  /**
   * Selectors
   */

  const currentUser = useSelector((s: StoreState) => s.user);
  const connectedAddress = useSelector((s: StoreState) => {
    const address = s.blockchain.connectedAddress;
    return address ? address.toLowerCase() : '';
  });

  /**
   * External Hooks
   */

  const dispatch = useDispatch();
  const backendURL = useBackendURL();

  /**
   * Effects
   */

  // Filter the current user identity information.
  useEffect(() => {
    if (!connectedAddress) return;
    if (!currentUser) return;

    const validKeys = Object.values(IdentityKeys).map((k) => k.toLowerCase());
    const userKeys = Object.keys(currentUser);

    const filteredUserKeys = userKeys.filter((k) =>
      validKeys.filter(Boolean).includes(k.toLowerCase())
    );

    const filteredUserValues = (filteredUserKeys as IdentityKeys[]).reduce(
      (acc, next) => {
        acc[next] = currentUser[next] || '';

        return acc;
      },
      {} as Identity
    );

    setCurrentIdentity(filteredUserValues);

    onFetch && onFetch(filteredUserValues);
  }, [connectedAddress, currentUser, onFetch]);

  /**
   * Functions
   */

  /**
   * getIdentityUpdates
   *
   * Makes sure the emailAddress or username are not the same
   * before returning the payload.
   *
   * @param {Identity | undefined} currentIdentity
   * @param {Partial<Identity>} userIdentityUpdates
   * @returns {IdentityPayload}
   */
  function getIdentityUpdates(
    currentIdentity: Identity | undefined,
    userIdentityUpdates: Partial<Identity>
  ): IdentityPayload {
    // Email
    const currentEmailAddress =
      currentIdentity && currentIdentity.emailAddress
        ? currentIdentity.emailAddress.toLowerCase()
        : '';
    const updatedEmailAddress = userIdentityUpdates.emailAddress
      ? userIdentityUpdates.emailAddress.toLowerCase()
      : '';

    // Username
    const currentUsername =
      currentIdentity && currentIdentity.username
        ? currentIdentity.username.toLowerCase()
        : '';
    const updatedUsername = userIdentityUpdates.username
      ? userIdentityUpdates.username.toLowerCase()
      : '';

    // Checks if the current data matches the updates
    const isSameEmailAddress = currentEmailAddress === updatedEmailAddress;
    const isSameUsername = currentUsername === updatedUsername;

    // Provide the emailAddress and username if they are set, and different than the current.
    const emailAddress =
      updatedEmailAddress && !isSameEmailAddress
        ? updatedEmailAddress.trim()
        : '';
    const username =
      updatedUsername && !isSameUsername ? updatedUsername.trim() : '';

    return {emailAddress, username};
  }

  async function updateUserIdentity(userIdentityUpdates: Partial<Identity>) {
    setUpdateUserStatus(FetchStatus.PENDING);

    props.onProcess && props.onProcess();

    try {
      if (!backendURL) {
        throw new Error('No backend URL found.');
      }

      const {emailAddress, username} = getIdentityUpdates(
        currentIdentity,
        userIdentityUpdates
      );

      if (!username && !emailAddress) {
        setUpdateUserStatus(FetchStatus.STANDBY);

        return;
      }

      await dispatch(
        updateCurrentUser(
          connectedAddress,
          {
            ...(emailAddress ? {emailAddress} : null),
            ...(username ? {username} : null),
          },
          backendURL
        )
      );

      setUpdateUserStatus(FetchStatus.FULFILLED);

      props.onSuccess && props.onSuccess(userIdentityUpdates);
    } catch (error) {
      setUpdateUserStatus(FetchStatus.REJECTED);
      setUpdateUserError(error);

      props.onError && props.onError(error);
    }
  }

  async function createUserUsername(username: string) {
    setUpdateUserStatus(FetchStatus.PENDING);

    props.onProcess && props.onProcess();

    try {
      /**
       * @note The POST endpoint is not protected but it's OK, because
       *   the creation operation can only be done once.
       *   The member can change their username any time from their profile page,
       *   which uses a protected PATCH endpoint.
       *
       *   This was done to not require a user to authenticate with our API before contributing.
       */
      if (!backendURL) {
        throw new Error('No backend URL was found.');
      }

      // @note The response is a 201 if successful - there's no response body.
      const response = await fetch(
        `${backendURL}/users/${connectedAddress}/username`,
        {
          method: 'POST',
          body: JSON.stringify({username}),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 400) {
        throw new Error(
          "Something doesn't look correct, or a username already exists."
        );
      }

      if (response.status === 404) {
        throw new Error('User was not found.');
      }

      if (!response.ok) {
        throw new Error('Something went wrong while updating your username.');
      }

      dispatch(updateCurrentUserData({username}));

      setUpdateUserStatus(FetchStatus.FULFILLED);

      props.onSuccess && props.onSuccess({username});
    } catch (error) {
      setUpdateUserStatus(FetchStatus.REJECTED);
      setUpdateUserError(error);

      props.onError && props.onError(error);
    }
  }

  return props.render({
    createUserUsername,
    currentIdentity,
    updatedIdentity: updatedIdentity.current,
    updateUserError,
    updateUserIdentity,
    updateUserStatus,
  });
}
