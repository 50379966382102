import React from 'react';

import {formatNumber} from '../../util/helpers';
import {Sale} from '../../util/types';
import {useLatestETHPrice} from '../../hooks';

import s from '../../assets/scss/modules/salebalance.module.scss';

type SaleBalanceProps = {
  sales: Sale[];
};

export default function SaleBalance(props: SaleBalanceProps) {
  const latestETHPrice = useLatestETHPrice();
  const {sales = []} = props;

  function getETHAmount() {
    return sales.reduce((acc, currentValue) => {
      return Number(acc) + Number(currentValue.amount);
    }, 0);
  }

  return (
    <div className={`${s['subscribed-amount']}`}>
      <p className={`text-center`}>
        <span className={`${s['contribution-amount']}`}>
          ${formatNumber((getETHAmount() * (latestETHPrice || 0)).toFixed(2))}
        </span>
        <span className={`${s['swing']}`}>
          {formatNumber(getETHAmount().toFixed(2))} ETH
        </span>
      </p>
    </div>
  );
}
