import {useEffect, useState} from 'react';
import BigNumber from 'bignumber.js';

import {FetchStatus} from '../util/enums';
import useGuildBalance from './useGuildBalance';

/**
 * useIsPaymentRequestedOK
 *
 * This follows the logic from Moloch in `processProposal`:
 *
 *    if (proposal.paymentRequested > userTokenBalances[GUILD][proposal.paymentToken]) {
 *      didPass = false;
 *    }
 *
 * @param paymentRequested
 */
export function useIsPaymentRequestedOK(
  paymentRequestedWEI: string | undefined
) {
  /**
   * State
   */

  const [isPaymentRequestedOK, setIsPaymentRequestedOK] =
    useState<boolean>(false);
  const [isPaymentRequestedOKStatus, setIsPaymentRequestedOKStatus] =
    useState<FetchStatus>(FetchStatus.STANDBY);

  /**
   * External hooks
   */

  const {guildBalance} = useGuildBalance();

  /**
   * Effects
   */

  useEffect(() => {
    if (!paymentRequestedWEI || !guildBalance) {
      setIsPaymentRequestedOKStatus(FetchStatus.PENDING);

      return;
    }

    const isGuildBalanceGreater = new BigNumber(guildBalance).gte(
      new BigNumber(paymentRequestedWEI)
    );
    // If `paymentRequested` is greater, it's not OK.
    setIsPaymentRequestedOK(isGuildBalanceGreater);
    setIsPaymentRequestedOKStatus(FetchStatus.FULFILLED);
  }, [guildBalance, paymentRequestedWEI]);

  return {isPaymentRequestedOK, isPaymentRequestedOKStatus};
}
