import React, {useState} from 'react';

import {InvestmentProposalTarget} from '../../util/types';

type InvestmentProposalSelectTargetRenderActionsArgs = {
  selectedTargetOption: InvestmentProposalTarget;
};

type InvestmentProposalSelectTargetProps = {
  defaultTarget?: InvestmentProposalTarget;
  showPropsalTargetSelector: boolean;
  renderActions: (
    a: InvestmentProposalSelectTargetRenderActionsArgs
  ) => JSX.Element | null;
};

/**
 * InvestmentProposalSelectTarget
 *
 * Displays a drop-down confirm button with options to
 * choose to:
 *
 *  - Create a Snapshot Proposal
 *  - Create a Moloch Proposal / Create & sponsor Moloch proposal
 *    depending on EasyApply feature flag.
 *
 * Once this option is selected and run the `proposalTarget` will
 * be set by the server then
 *
 * @param {InvestmentProposalSelectTargetProps} props
 * @returns {JSX.Element}
 */
export default function InvestmentProposalSelectTarget(
  props: InvestmentProposalSelectTargetProps
) {
  const {defaultTarget, showPropsalTargetSelector, renderActions} = props;

  /**
   * State
   */

  const [selectedTargetOption, setSelectedTargetOption] =
    useState<InvestmentProposalTarget>(defaultTarget || null);

  /**
   * Functions
   */

  function handleSelectTargetChange(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    const {value} = event.target;

    setSelectedTargetOption(value as InvestmentProposalTarget);
  }

  return (
    <>
      {/* CHOOSE PROPOSAL TARGET */}
      {showPropsalTargetSelector && (
        <select className="org-select" onChange={handleSelectTargetChange}>
          <option value="" disabled={selectedTargetOption !== null}>
            Select a proposal type&hellip;
          </option>
          <option value="moloch">Moloch Proposal</option>
          <option value="snapshot">Snapshot Proposal</option>
        </select>
      )}

      {/* ACTIONS */}
      {renderActions({selectedTargetOption})}
    </>
  );
}
