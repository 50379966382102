import React, {createContext, useCallback} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {useSelector} from 'react-redux';

import HiThereLoader from '../../components/feedback/HiThereLoader';
import SyncSVG from '../../assets/svg/SyncSVG';

import {useKYCContributors, useKYCContributionWhitelist} from '../../hooks';
import ProcessingProposal, {
  ProcessingProposalHeader,
} from './proposals/ProcessingProposal';
import CompletedProposal, {
  CompletedProposalHeader,
} from './proposals/CompletedProposal';
import OpenProposal, {OpenProposalHeader} from './proposals/OpenProposal';
import MemberKYCStatistics from './MemberKYCStatistics';

import {MemberProposalData, StoreState} from '../../util/types';
import {VerifedKYCMember} from '../../hooks/useKYCContributors';
import {getOrgText} from '../../util/helpers';

import {
  GET_PENDING_MEMBER_PROPOSALS,
  GET_VOTING_MEMBER_PROPOSALS,
  GET_PROCESSED_MEMBER_PROPOSALS,
} from '../../gql';

import s from '../../assets/scss/modules/admin.module.scss';
import {FetchStatus} from '../../util/enums';

// Memoized prevents re-rendering
export const MemberExists = React.memo(() => {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgAdminKYCEmoji = getText('OrgAdminKYCEmoji');

  return (
    <span role="img" aria-label="Diamond shape emoji">
      {orgAdminKYCEmoji}
    </span>
  );
});

// Memoized prevents re-rendering
export const MemberWhitelisted = React.memo(() => {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgAdminKYCWhitelistedEmoji = getText('OrgAdminKYCWhitelistedEmoji');

  return (
    <span role="img" aria-label="Circle shape emoji">
      {orgAdminKYCWhitelistedEmoji}
    </span>
  );
});

export type ProposalsContextValue = {
  moveOpenProposal: (returnValues: Record<string, any> | undefined) => void;
  moveProcessedProposal: (
    returnValues: Record<string, any> | undefined
  ) => void;
  kycMembers: Array<VerifedKYCMember>;
};

export const ProposalsContext = createContext<ProposalsContextValue>(
  {} as ProposalsContextValue
);

export default function Members() {
  const contractEasyApplyAddress = useSelector(
    (s: StoreState) => s.org && s.org.contractEasyApplyAddress
  );
  const getPendingMembers = useQuery<MemberProposalData>(
    GET_PENDING_MEMBER_PROPOSALS
  );
  const getVotingMembers = useQuery<MemberProposalData>(
    GET_VOTING_MEMBER_PROPOSALS
  );
  const getProcessedMembers = useQuery<MemberProposalData>(
    GET_PROCESSED_MEMBER_PROPOSALS
  );

  const {kycMembers} = useKYCContributors();
  const {kycContributionWhitelist, kycContributionWhitelistStatus} =
    useKYCContributionWhitelist();

  const isMemberInWhitelistCallback = useCallback(isMemberInWhitelist, [
    contractEasyApplyAddress,
    kycContributionWhitelist,
    kycContributionWhitelistStatus,
  ]);

  function isMemberVerified(applicant: string): boolean {
    const memberIsVerified = kycMembers.find(
      (item: any) => applicant === item.ethereumAddress
    );

    return (memberIsVerified && memberIsVerified.isVerified) ?? false;
  }

  function isMemberInWhitelist(memberAddress: string): boolean {
    if (memberAddress.toLowerCase() === contractEasyApplyAddress?.toLowerCase())
      return true;

    if (kycContributionWhitelistStatus === FetchStatus.FULFILLED) {
      const isWhitelisted = kycContributionWhitelist.find(
        (w) => w.ethereumAddress === memberAddress
      );

      return isWhitelisted ? true : false;
    }

    return false;
  }

  function moveOpenProposal(returnValues: Record<string, any> | undefined) {
    if (!returnValues) return;

    getPendingMembers.refetch();
    getVotingMembers.refetch();
  }

  function moveProcessedProposal(
    returnValues: Record<string, any> | undefined
  ) {
    if (!returnValues) return;

    getVotingMembers.refetch();
    getProcessedMembers.refetch();
  }

  return (
    <ProposalsContext.Provider
      value={{
        moveOpenProposal,
        moveProcessedProposal,
        kycMembers,
      }}>
      <section className={`grid--fluid ${s['admin']}`}>
        <MemberKYCStatistics />
        <p>
          <MemberExists /> = Member is verified
        </p>
        <p>
          <MemberWhitelisted /> = Member is whitelisted
        </p>
        {/** PENDING PROPOSALS, AWAITING SPONSORING */}

        <div style={{textAlign: 'center', margin: '4rem'}}>
          <span>Membership requests</span>
          <button
            className={s.sync}
            onClick={() => {
              getPendingMembers.refetch();
            }}
            disabled={getPendingMembers.loading}>
            <SyncSVG />
          </button>
        </div>

        {getPendingMembers.loading ? (
          <div style={{textAlign: 'center'}}>
            <div style={{width: '3rem', margin: '0 auto'}}>
              <HiThereLoader />
            </div>
            <small>{'Fetching pending memberships\u2026'}</small>
          </div>
        ) : (
          <>
            <table key={'open-table'} className={'opentable'}>
              {OpenProposalHeader}
              <tbody>
                {getPendingMembers.data &&
                  getPendingMembers.data.proposals.map(
                    (item: Record<string, any>) => (
                      <OpenProposal
                        key={`openproposal${item.proposalId}`}
                        isWhitelisted={isMemberInWhitelistCallback(
                          item.applicant
                        )}
                        isVerified={isMemberVerified(item.applicant)}
                        proposal={item}
                      />
                    )
                  )}
              </tbody>
            </table>
            {getPendingMembers.data &&
              getPendingMembers.data.proposals.length === 0 && (
                <p className="text-center color-yellow">
                  No pending memberships
                </p>
              )}
            {getPendingMembers.error && (
              <p>{getPendingMembers.error.message}</p>
            )}
          </>
        )}

        {/** PROPOSALS IN PROGRESS ie, VOTING, PROCESSING */}

        <div style={{textAlign: 'center', margin: '4rem'}}>
          <span>PROCESSING MEMBERSHIPS</span>
          <button
            className={s.sync}
            onClick={() => {
              getVotingMembers.refetch();
            }}
            disabled={getVotingMembers.loading}>
            <SyncSVG />
          </button>
        </div>

        {getVotingMembers.loading ? (
          <div style={{textAlign: 'center'}}>
            <div style={{width: '3rem', margin: '0 auto'}}>
              <HiThereLoader />
            </div>
            <small>{'Fetching voting/grace period memberships\u2026'}</small>
          </div>
        ) : (
          <>
            <table key={'processing-table'} className={'processingtable'}>
              {ProcessingProposalHeader}
              <tbody>
                {getVotingMembers.data &&
                  getVotingMembers.data.proposals
                    .filter(
                      (item: Record<string, any>) =>
                        // item.details === 'Membership'
                        Number(item.sharesRequested) > 0
                    )
                    .map((item: Record<string, any>) => (
                      <ProcessingProposal
                        key={`processingproposal${item.proposalId}`}
                        isWhitelisted={isMemberInWhitelistCallback(
                          item.applicant
                        )}
                        isVerified={isMemberVerified(item.applicant)}
                        proposal={item}
                      />
                    ))}
              </tbody>
            </table>
            {getVotingMembers.data &&
              getVotingMembers.data.proposals.length === 0 && (
                <p className="text-center color-yellow">No proposals</p>
              )}
            {getVotingMembers.error && <p>{getVotingMembers.error.message}</p>}
          </>
        )}

        {/** PROPOSAL COMPLETED */}

        <div style={{textAlign: 'center', margin: '4rem'}}>
          <span>COMPLETED MEMBERSHIPS</span>
          <button
            className={s.sync}
            onClick={() => {
              getProcessedMembers.refetch();
            }}
            disabled={getProcessedMembers.loading}>
            <SyncSVG />
          </button>
        </div>

        {getProcessedMembers.loading ? (
          <div style={{textAlign: 'center'}}>
            <div style={{width: '3rem', margin: '0 auto'}}>
              <HiThereLoader />
            </div>
            <small>{'Fetching completed memberships\u2026'}</small>
          </div>
        ) : (
          <>
            <table key={'completed-table'} className={'completedtable'}>
              {CompletedProposalHeader}
              <tbody>
                {getProcessedMembers.data &&
                  getProcessedMembers.data.proposals.map(
                    (item: Record<string, any>) => (
                      <CompletedProposal
                        key={`completedproposal${item.proposalId}`}
                        isWhitelisted={isMemberInWhitelistCallback(
                          item.applicant
                        )}
                        isVerified={isMemberVerified(item.applicant)}
                        proposal={item}
                      />
                    )
                  )}
              </tbody>
            </table>
            {getProcessedMembers.data &&
              getProcessedMembers.data.proposals.length === 0 && (
                <p className="text-center color-yellow">
                  No completed proposals
                </p>
              )}
            {getProcessedMembers.error && (
              <p>{getProcessedMembers.error.message}</p>
            )}
          </>
        )}
      </section>
    </ProposalsContext.Provider>
  );
}
