import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

import {
  getMolochConstants,
  getOrg,
  getOrgFeatures,
  getOrgText,
  getConnectedMemberFromSmartContract,
  initWeb3Instance,
  setConnectedAddress,
  walletAuthenticated,
  resetCurrentUser,
} from './store/actions';

import {
  isTributeOrgName,
  orgNameFromHostnameMap,
  orgNameFromSubdomainMap,
  orgNameFromURL,
  loadOrgCss,
} from './util/helpers';
import {InternalNamesToMap} from './util/orgDomainMappings';
import {__DEV__ORG_INTERNAL_NAME} from './util/config';
import {apolloLinkWithFallback} from './gql/helpers';
import {FetchStatus} from './util/enums';
import {GET_MOLOCH} from './gql';
import {ReduxDispatch, StoreState} from './util/types';
import {useIsMounted} from './hooks/useIsMounted';
import {useInitContracts, useBackendURL} from './hooks';
import ErrorMessageWithDetails from './components/common/ErrorMessageWithDetails';
import FadeIn from './components/common/FadeIn';
import Header from './components/header';
import Wrap from './components/layout/Wrap';

import {useWeb3Modal} from './components/web3/Web3ModalManager';

type InitPropsRenderProps = {
  apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
  error: Error | undefined;
  isInitComplete: boolean;
  orgInternalName: string | null;
};

type InitProps = {
  render: (p: InitPropsRenderProps) => React.ReactElement | null;
};

type InitErrorProps = {
  error: Error;
};

/**
 * Register any new async process names here.
 * It is mainly to check their progress before rendering.
 */
type ProcessName =
  | 'initOrg'
  | 'initOrgFeatures'
  | 'initOrgText'
  | 'initMolochConstants';

const {REACT_APP_GRAPH_FALLBACK_URL = ''} = process.env;

/**
 * Init Component
 *
 * Init will run any designated sync/async
 * setup processes and then render any child component
 * upon completion.
 *
 * In our case the children prop component to render is our app.
 *
 * @param {InitProps} props
 */
export default function Init(props: InitProps) {
  const {render} = props;

  /**
   * State
   */

  const [apolloClient, setApolloClient] =
    useState<ApolloClient<NormalizedCacheObject>>();
  const [error, setError] = useState<Error>();
  const [isInitComplete, setIsInitComplete] = useState<boolean>(false);
  const [processReadyMap, setProcessReadyMap] = useState<
    Record<ProcessName, FetchStatus>
  >({
    initMolochConstants: FetchStatus.STANDBY,
    initOrg: FetchStatus.STANDBY,
    initOrgFeatures: FetchStatus.STANDBY,
    initOrgText: FetchStatus.STANDBY,
  });

  /**
   * Selectors
   */

  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const orgMolochAddress = useSelector(
    (s: StoreState) => s.org && s.org.contractMolochAddress
  );
  const graphURL = useSelector((s: StoreState) => s.org && s.org.graphURL);

  /**
   * External Hooks
   */

  const dispatch = useDispatch<ReduxDispatch>();
  const {isMountedRef} = useIsMounted();
  const {initContracts} = useInitContracts();
  const {account, connected, provider, web3Instance} = useWeb3Modal();
  const backendURL = useBackendURL();

  /**
   * Callbacks
   */

  const initOrgCached = useCallback(initOrg, [dispatch, isMountedRef]);
  const initMolochConstantsCached = useCallback(initMolochConstants, [
    apolloClient,
    dispatch,
    orgMolochAddress,
  ]);

  /**
   * Effects
   */

  // Here we check for wallet connection changes and init updates
  // to the app contracts, web3, wallet connected state, and wallet address
  useEffect(() => {
    const selectedAddress: string = account?.toLowerCase() ?? '';
    const isTributeDAO = isTributeOrgName(
      orgInternalName as InternalNamesToMap
    );

    // init contracts if connected or reset connection state
    connected && selectedAddress && !isTributeDAO
      ? initContracts()
      : dispatch(resetCurrentUser());

    // set web3 instance
    web3Instance && dispatch(initWeb3Instance(web3Instance));

    // set wallet auth
    dispatch(walletAuthenticated(connected === true));

    // set the address of the connected user
    backendURL && dispatch(setConnectedAddress(selectedAddress, backendURL));

    // if we don't have a connected address update connected member state
    !selectedAddress && dispatch(getConnectedMemberFromSmartContract());
  }, [
    account,
    backendURL,
    connected,
    dispatch,
    initContracts,
    orgInternalName,
    web3Instance,
  ]);

  // Check to see if the init has completed.
  useEffect(() => {
    setIsInitComplete(
      Object.values(processReadyMap).every((fs) => fs === FetchStatus.FULFILLED)
    );
  }, [processReadyMap]);

  // Init fetching and setting of the org state
  useEffect(() => {
    initOrgCached();
  }, [initOrgCached]);

  // Init fetching the Moloch constants from TheGraph
  useEffect(() => {
    const isTributeDAO = isTributeOrgName(
      orgInternalName as InternalNamesToMap
    );

    // Don't fetch moloch constants if a Tribute DAO, but we still need to set
    // the process as complete.
    if (isTributeDAO) {
      setProcessReadyMap((state) => ({
        ...state,
        initMolochConstants: FetchStatus.FULFILLED,
      }));
    } else {
      initMolochConstantsCached();
    }
  }, [initMolochConstantsCached, orgInternalName]);

  // Init the contracts used in the dApp,
  // we do not need to wait for it to be ready
  useEffect(() => {
    const isTributeDAO = isTributeOrgName(
      orgInternalName as InternalNamesToMap
    );

    if (isTributeDAO) return;

    connected && provider && web3Instance && initContracts();
  }, [connected, provider, web3Instance, initContracts, orgInternalName]);

  // Init loading the org CSS file into <head>
  useEffect(() => {
    orgInternalName && loadOrgCss(orgInternalName);
  }, [orgInternalName]);

  // Init fetching the org JSON text file
  useEffect(() => {
    if (!orgInternalName) return;

    setProcessReadyMap((state) => ({
      ...state,
      initOrgText: FetchStatus.PENDING,
    }));

    dispatch(getOrgText(orgInternalName))
      .then(() => {
        setProcessReadyMap((state) => ({
          ...state,
          initOrgText: FetchStatus.FULFILLED,
        }));
      })
      .catch((error) => {
        setProcessReadyMap((state) => ({
          ...state,
          initOrgText: FetchStatus.REJECTED,
        }));
        setError(error);
      });
  }, [dispatch, orgInternalName]);

  // Init fetching the fetaures JSON file
  useEffect(() => {
    if (!orgInternalName) return;

    setProcessReadyMap((state) => ({
      ...state,
      initOrgFeatures: FetchStatus.PENDING,
    }));

    dispatch(getOrgFeatures(orgInternalName))
      .then(() => {
        setProcessReadyMap((state) => ({
          ...state,
          initOrgFeatures: FetchStatus.FULFILLED,
        }));
      })
      .catch((error) => {
        setProcessReadyMap((state) => ({
          ...state,
          initOrgFeatures: FetchStatus.REJECTED,
        }));
        setError(error);
      });
  }, [dispatch, orgInternalName]);

  // Init ApolloClient for the <ApolloProvider client={...} /> child component
  useEffect(() => {
    if (!graphURL) return;

    const isTributeDAO = isTributeOrgName(
      orgInternalName as InternalNamesToMap
    );

    if (isTributeDAO) return;

    setApolloClient(
      new ApolloClient({
        link: apolloLinkWithFallback(graphURL, REACT_APP_GRAPH_FALLBACK_URL),
        cache: new InMemoryCache(),
      })
    );
  }, [graphURL, orgInternalName]);

  /**
   * Functions
   */

  async function initMolochConstants() {
    if (!apolloClient) return;

    try {
      setProcessReadyMap((state) => ({
        ...state,
        initMolochConstants: FetchStatus.PENDING,
      }));

      const {data} = await apolloClient.query({
        query: GET_MOLOCH,
        variables: {
          id: orgMolochAddress?.toLowerCase(),
        },
      });

      if (!data.moloches || !data.moloches.length) {
        throw new Error(
          'No Moloch data was found. Make sure your Moloch address matches (case sensitive) the subgraph deployment.'
        );
      }

      const {id: contractAddress, ...restData} = data.moloches[0];

      // Delete this prop; we don't need it.
      delete restData.__typename;

      // Set the Redux state
      dispatch(
        getMolochConstants({
          contractAddress,
          ...restData,
        })
      );

      setProcessReadyMap((state) => ({
        ...state,
        initMolochConstants: FetchStatus.FULFILLED,
      }));
    } catch (error) {
      setProcessReadyMap((state) => ({
        ...state,
        initMolochConstants: FetchStatus.REJECTED,
      }));
      setError(error);
    }
  }

  async function initOrg() {
    setProcessReadyMap((state) => ({
      ...state,
      initOrg: FetchStatus.PENDING,
    }));

    try {
      await dispatch(
        getOrg(
          __DEV__ORG_INTERNAL_NAME ||
            orgNameFromHostnameMap ||
            orgNameFromSubdomainMap ||
            orgNameFromURL
        )
      );

      if (!isMountedRef.current) return;

      setProcessReadyMap((state) => ({
        ...state,
        initOrg: FetchStatus.FULFILLED,
      }));
    } catch (error) {
      if (!isMountedRef.current) return;

      setProcessReadyMap((state) => ({
        ...state,
        initOrg: FetchStatus.REJECTED,
      }));
      setError(error);
    }
  }

  // Render children
  return render({apolloClient, error, isInitComplete, orgInternalName});
}

/**
 * InitError
 *
 * An error component that is meant to be used if the <Init /> component
 * could not complete any of its processes to provide the app with vital data.
 *
 * @param {InitErrorProps} props
 */
export function InitError(props: InitErrorProps) {
  const {error} = props;

  return (
    <Wrap className="section-wrapper">
      <Header />

      <FadeIn>
        <div
          style={{
            padding: '2em 1em 1em',
            textAlign: 'center',
          }}>
          <h1 style={{fontSize: '2rem'}}>
            <span
              className="pulse"
              role="img"
              aria-label="Emoji with eyes crossed out."
              style={{display: 'inline-block'}}>
              😵
            </span>{' '}
            Oops, something went wrong.
          </h1>
        </div>

        <div
          style={{
            textAlign: 'center',
            maxWidth: 600,
            display: 'block',
            margin: '0 auto',
          }}>
          <ErrorMessageWithDetails error={error} renderText="" />
        </div>
      </FadeIn>
    </Wrap>
  );
}
