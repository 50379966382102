import React, {useCallback, useEffect, useState} from 'react';

import {BACKEND_URL_KYC} from '../../../util/config';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';

import {CheckStatus} from './index';

/**
 * TODOs
 * - ping kyc server
 */
export default function KYCDBChecks() {
  const [isEndpointUP, setIsEndpointUP] = useState<boolean>(false);
  const [isDBUP, setIsDBUP] = useState<boolean>(false);
  const [dbError, setDBError] = useState<Error>();
  const [endpointError, setEndpointError] = useState<Error>();

  const checkKYCEndpointCached = useCallback(checkKYCEndpoint, []);
  const checkKYCDatabaseCached = useCallback(checkKYCDatabase, []);

  useEffect(() => {
    checkKYCEndpointCached();
    checkKYCDatabaseCached();
  }, [checkKYCEndpointCached, checkKYCDatabaseCached]);

  function reRunChecks() {
    // @todo loader
    checkKYCEndpointCached();
    checkKYCDatabaseCached();
  }

  async function checkKYCEndpoint() {
    try {
      const response = await fetch(`${BACKEND_URL_KYC}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setIsEndpointUP(response.ok);
    } catch (error) {
      setEndpointError(error);
    }
  }

  async function checkKYCDatabase() {
    try {
      const response = await fetch(`${BACKEND_URL_KYC}/db`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setIsDBUP(response.ok);
    } catch (error) {
      setDBError(error);
    }
  }
  return (
    <div>
      <h2>KYC Endpoint</h2>
      <button onClick={reRunChecks}>Run checks</button>
      <p>KYC Backend URL: {BACKEND_URL_KYC}</p>
      <p>KYC ENV VAR: BACKEND_URL_KYC</p>
      {/**
       * 1. Check KYC Endpoint
       */}
      <div>
        <p>
          <CheckStatus checkStatus={isEndpointUP} /> Endpoint is available:{' '}
          {String(isEndpointUP)}
        </p>
      </div>

      <div
        style={{
          textAlign: 'center',
          maxWidth: 600,
          display: 'block',
          margin: '0 auto',
        }}>
        <ErrorMessageWithDetails error={endpointError} renderText="" />
      </div>
      {/**
       * 2. Check KYC Database connection
       */}
      <div>
        <p>
          <CheckStatus checkStatus={isDBUP} /> Database Connection is UP:{' '}
          {String(isDBUP)}
        </p>
      </div>
      <div
        style={{
          textAlign: 'center',
          maxWidth: 600,
          display: 'block',
          margin: '0 auto',
        }}>
        <ErrorMessageWithDetails error={dbError} renderText="" />
      </div>
    </div>
  );
}
