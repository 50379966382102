import React, {useContext} from 'react';
import Web3 from 'web3';
import {useSelector} from 'react-redux';

import {formatNumber, getOrgText} from '../../../util/helpers';
import {StoreState} from '../../../util/types';
import {ProposalsContext, MemberExists, MemberWhitelisted} from '../Members';
import Loader from '../../../components/feedback/Loader';
import SponsorProposal from '../../../components/contract/SponsorProposal';

type OpenProposalProps = {
  isWhitelisted: boolean;
  isVerified: boolean;
  proposal: Record<string, any>;
};

const __DEV__ = /development|localhost/.test(
  process.env.REACT_APP_ENVIRONMENT || ''
);

export const OpenProposalHeader = (
  <thead>
    <tr>
      <th>KYCed</th>
      <th>Proposal Id</th>
      <th>Ethereum Address</th>
      <th>Shares Requested</th>
      <th>Tribute Offered (ETH)</th>
      <th></th>
    </tr>
  </thead>
);

export default function OpenProposal(props: OpenProposalProps) {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgLoaderTextColor = getText('OrgLoaderTextColor');

  const web3 = new Web3();
  const {moveOpenProposal} = useContext(ProposalsContext);

  const {isWhitelisted, isVerified, proposal} = props;
  const {applicant, proposalId, tributeOffered, sharesRequested} = proposal;

  const tributeOfferedETH = web3.utils.fromWei(tributeOffered, 'ether');

  return (
    <tr>
      <td>
        {isVerified && <MemberExists />}
        {isWhitelisted && <MemberWhitelisted />}
      </td>
      <td>{proposalId}</td>
      <td>{applicant}</td>
      <td>{formatNumber(sharesRequested)}</td>
      <td>{tributeOfferedETH}</td>
      <td>
        <SponsorProposal
          proposalId={proposalId}
          onComplete={(returnedValues) => {
            moveOpenProposal(returnedValues);
          }}
          render={({
            error,
            etherscanURL,
            isPromptOpen,
            isSubmitted,
            isSubmitting,
            openPrompt,
          }) => (
            <>
              {
                <>
                  {(__DEV__ || Number(sharesRequested > 0)) && (
                    <button
                      className={'org-admin-button'}
                      /**
                       * @note We are loose with the disabling of the button as to allow
                       *   the admin make an educated decision with the infomation provided.
                       */
                      disabled={isSubmitting || isSubmitted}
                      onClick={
                        isSubmitted || isSubmitting ? () => {} : openPrompt
                      }>
                      {isSubmitting ? (
                        <Loader
                          text={
                            isPromptOpen
                              ? 'Preparing\u2026'
                              : 'Processing\u2026'
                          }
                          textProps={{
                            style: {color: orgLoaderTextColor},
                          }}
                        />
                      ) : isSubmitted ? (
                        'Finishing up\u2026'
                      ) : (
                        'Sponsor'
                      )}
                    </button>
                  )}

                  {etherscanURL && (
                    <p>
                      <small>
                        <a
                          href={etherscanURL}
                          rel="noopener noreferrer"
                          target="_blank">
                          (view progress)
                        </a>
                      </small>
                    </p>
                  )}
                </>
              }
            </>
          )}
        />
      </td>
    </tr>
  );
}
