import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import Blockie from '../ethereum-ui/Blockie';
import {Member, StoreState} from '../../util/types';

import s from '../../assets/scss/modules/memberscard.module.scss';

interface MemberCardProps {
  member: Member;
  memberVotingWeight?: string | undefined;
  memberVotingShares?: string | undefined;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export default function MembersCard(props: MemberCardProps) {
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const {ethereumAddress, username} = props.member.user;
  const memberVotingWeight = props.memberVotingWeight;
  const memberVotingShares = props.memberVotingShares;

  return (
    <Link
      to={`/members/${ethereumAddress}`}
      className={`${s['members-card__link']} org-members-card__link`}>
      <div
        className={`${s['members-card']} org-members-card ${
          connectedAddress === ethereumAddress
            ? `${s['connected-member']} org-connected-member`
            : ''
        }`}
        style={{position: 'relative'}}
        onClick={props.onClick}
        data-member-id={ethereumAddress}>
        <div className={s['members-blockie']}>
          <Blockie
            opts={{
              seed: ethereumAddress,
            }}
          />
        </div>
        <div className={`${s['text-wrap']} org-member-text-wrap`}>
          {username && <span className={s['username']}>{username}</span>}
          <span className={s['eth-address']}>{ethereumAddress}</span>
          {memberVotingWeight && memberVotingShares && (
            <span className={s['voting-weight']}>
              ({memberVotingWeight}% voting weight)
            </span>
          )}
        </div>
      </div>
    </Link>
  );
}
