import React, {useState, useEffect, ReactElement} from 'react';
import {useDropzone} from 'react-dropzone';

import s from '../../assets/scss/modules/filedropzone.module.scss';
// import {useFormContext} from 'react-hook-form';
import FileSVG from '../../assets/svg/FileSVG';
import XCloseSVG from '../../assets/svg/XCloseSVG';

type FileDropzoneProps = {
  acceptedTypes?: string | string[];
  isFileClearable?: boolean;
  onFilesChange?: (files: File[]) => void;
  renderClearButton?: (clearFunc: () => void) => ReactElement;
};

/**
 * FileDropzone
 *
 * Drag and drop component for files.
 * By default, accepts a single file (`multiple={false}`).
 * This component is integrated with react-hook-form.
 *
 * @param {React.InputHTMLAttributes<HTMLInputElement>} props
 */
export default function FileDropzone(
  props: FileDropzoneProps & React.InputHTMLAttributes<HTMLInputElement>
) {
  const {
    acceptedTypes,
    isFileClearable,
    onChange,
    onFilesChange,
    renderClearButton,
    value,
    ...restProps
  } = props;

  const {onBlur} = restProps;

  const [userTouchedFileDialog, setUserTouchedFileDialog] = useState<
    'untouched' | 'touched'
  >('untouched');
  const [files, setFiles] = useState<File[]>([]);
  const onDrop = (acceptedFiles: File[]) => {
    onFilesChange && onFilesChange(acceptedFiles);
    // @TODO trigger the onChange function onDrop that the react-hook-form Controller
    // makes use of . Don't know if there's a better way than a fake Event object and typecasting?
    onChange &&
      onChange({
        target: {files: acceptedFiles as any},
      } as React.ChangeEvent<HTMLInputElement>);
    setFiles(acceptedFiles.map((f: File) => f));
  };
  const {getRootProps, getInputProps, isDragActive, isFileDialogActive} =
    useDropzone({
      accept: props.acceptedTypes,
      onDrop,
      onFileDialogCancel: handleFileDialogCancel,
    });

  // const formContext = useFormContext();
  // const {register, unregister} = formContext || {};

  // set once so we know a user has opened the dialog and can
  // trigger an onBlur event
  if (userTouchedFileDialog === 'untouched' && isFileDialogActive) {
    setUserTouchedFileDialog('touched');
  }

  // react-hook-form possible registration of field
  // useEffect(() => {
  //   formContext && props.name && register(props.name);

  //   return () => {
  //     formContext && props.name && unregister(props.name);
  //   };
  // }, [register]);

  useEffect(() => {
    // @TODO look into using onFileDialogCancel from react-dropzone
    userTouchedFileDialog === 'touched' &&
      !isFileDialogActive &&
      onBlur &&
      onBlur({} as React.FocusEvent<HTMLInputElement>);
  }, [isFileDialogActive, userTouchedFileDialog, onBlur]);

  function handleFileDialogCancel(index?: number) {
    if (isFileClearable && index === undefined) {
      onFilesChange && onFilesChange([]);
      setFiles([]);
      onChange && onChange([] as any);
    }

    if (isFileClearable && typeof index === 'number' && index >= 0) {
      const editedFiles = [...files];

      editedFiles.splice(index, 1);

      onFilesChange && onFilesChange(editedFiles);
      setFiles(editedFiles);
      onChange && onChange(editedFiles as any);
    }
  }

  return (
    <>
      <div className={s.container} {...getRootProps()}>
        <input
          {...getInputProps({
            multiple: props.multiple || false,
            ...restProps,
            onChange,
            // onChange: e => {
            //   onChange &&
            //     onChange(e,
            //       e.target.files ? (Array.from(e.target.files) as any) : []
            //     );
            // }
          })}
        />

        {/* inner text of dropzone */}
        {isDragActive ? (
          <p
            className={`${s.text} org-filedropzone-text ${
              isDragActive
                ? `${s['text--drag-active']} org-filedropzone-text--drag-active`
                : ''
            }`}>
            Drop the file here&hellip;
          </p>
        ) : (
          <p className={`${s.text} org-filedropzone-text`}>
            Drop the file here, or click to select one.
          </p>
        )}
      </div>

      {/* file name list */}
      {files.length > 0 && (
        <ul className={s.list}>
          {files.map((f, i) => (
            <li
              className={`${s.listItem} org-filedropzone-listItem`}
              key={f.name}>
              <FileSVG />
              {f.name}
              {/* CLEAR BUTTON from USER */}
              {isFileClearable &&
                renderClearButton &&
                renderClearButton(() => handleFileDialogCancel(i))}

              {/* CLEAR BUTTON */}
              {isFileClearable && !renderClearButton && (
                <button
                  className={s['file-remove-button']}
                  onClick={() => handleFileDialogCancel(i)}
                  type="button">
                  <XCloseSVG
                    aria-labelledby={`remove-file-${f.name}`}
                    // fill="#ff6f6f" /* $color-brightsalmon */
                    fill="white"
                  />
                  <span id={`remove-file-${f.name}`} className="hidden">
                    Remove
                  </span>
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
