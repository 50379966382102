import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
// Web3 types
import {AbiItem} from 'web3-utils/types';
import {StoreState} from '../../../util/types';
import Moloch from '../../../truffle-contracts/Moloch.json';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import {
  CheckStatus,
  // HealthcheckContext,
  // HealthcheckContextValue
} from './index';

/**
 * TODOs
 * - check moloch contract is deployed
 * - check moloch balance. can use same fn from mainpage
 */
export default function MolochContract() {
  // const {processReadyMap, setProcessReadyMapState} = useContext<
  //   HealthcheckContextValue
  // >(HealthcheckContext);
  const contractMolochAddress = useSelector(
    (s: StoreState) => s.org && s.org.contractMolochAddress
  );
  const web3Instance = useSelector(
    (s: StoreState) => s.blockchain.web3Instance
  );

  const [error, setError] = useState<Error>();

  const getContractCallback = useCallback(getContract, [
    contractMolochAddress,
    web3Instance,
  ]);

  // @todo check is alll done `processCompleteMap` then dispatch
  // setProcessReadyMapState('statusMoloch', FetchStatus.PENDING);

  useEffect(() => {
    getContractCallback();
  }, [getContractCallback]);

  function reRunChecks() {
    getContractCallback();
  }

  function getContract() {
    if (!web3Instance) return;
    if (!contractMolochAddress) return;

    try {
      new web3Instance.eth.Contract(
        Moloch.abi as AbiItem[],
        contractMolochAddress
      );
    } catch (error) {
      setError(error);
    }
  }

  return (
    <div>
      <h2>Moloch Contract</h2>
      <button onClick={reRunChecks}>Run checks</button>{' '}
      {!error && (
        <p>
          <CheckStatus checkStatus={contractMolochAddress !== ''} /> Moloch
          deployed at: {contractMolochAddress}
        </p>
      )}
      <div
        style={{
          textAlign: 'center',
          maxWidth: 600,
          display: 'block',
          margin: '0 auto',
        }}>
        {error && (
          <ErrorMessageWithDetails
            error={error}
            renderText="Moloch address is incorrect"
          />
        )}
      </div>
    </div>
  );
}
