import React from 'react';
import {useSelector} from 'react-redux';

import {getOrgText} from '../../util/helpers';
import {StoreState} from '../../util/types';
import Wrap from '../../components/layout/Wrap';
import MentorCardAlt from './MentorCardAlt';
import FadeIn from '../../components/common/FadeIn';

import s from '../../assets/scss/modules/mentorcard.module.scss';

// manually ordering in alphabetical order by last name
const curatorsList = [
  {
    name: 'Alex Atallah',
    handle: '@xanderatallah',
    twitter: 'https://twitter.com/xanderatallah',
    bio: (
      <>
        Co-Founder &amp; Chief Technical Officer,{' '}
        <a
          href="https://twitter.com/opensea"
          target="_blank"
          rel="noopener noreferrer">
          @OpenSea
        </a>
      </>
    ),
  },
  {
    name: 'Jason Bailey',
    handle: '@artnome',
    twitter: 'https://twitter.com/artnome',
    bio: (
      <>
        Founder,{' '}
        <a
          href="https://www.artnome.com"
          target="_blank"
          rel="noopener noreferrer">
          artnome.com
        </a>
      </>
    ),
  },
  {
    name: 'Sebastien Borget',
    handle: '@borgetsebastien',
    twitter: 'https://twitter.com/borgetsebastien',
    bio: (
      <>
        Co-Founder &amp; Chief Operating Officer,{' '}
        <a
          href="https://twitter.com/TheSandboxGame"
          target="_blank"
          rel="noopener noreferrer">
          @TheSandboxGame
        </a>
      </>
    ),
  },
  {
    name: 'Jake Brukhman',
    handle: '@jbrukh',
    twitter: 'https://twitter.com/jbrukh',
    bio: (
      <>
        Founder &amp; Chief Executive Officer,{' '}
        <a
          href="https://twitter.com/CoinFund"
          target="_blank"
          rel="noopener noreferrer">
          @CoinFund
        </a>
        <br />
        Curator at{' '}
        <a
          href="https://firstedition.xyz"
          target="_blank"
          rel="noopener noreferrer">
          FIRSTEDITION.XYZ
        </a>
      </>
    ),
  },
  {
    name: 'John Crain',
    handle: '@SuperRareJohn',
    twitter: 'https://twitter.com/superrarejohn',
    bio: (
      <>
        Co-Founder &amp; Chief Executive Officer,{' '}
        <a
          href="https://twitter.com/superrare_co"
          target="_blank"
          rel="noopener noreferrer">
          @SuperRare_co
        </a>
      </>
    ),
  },
  {
    name: 'Marguerite deCourcelle',
    handle: '@coin_artist',
    twitter: 'https://twitter.com/coin_artist',
    bio: (
      <>
        Chief Executive Officer,{' '}
        <a
          href="https://twitter.com/blockadegames"
          target="_blank"
          rel="noopener noreferrer">
          @BlockadeGames
        </a>{' '}
        &amp; Artist
      </>
    ),
  },
  {
    name: 'Simon de la Rouviere',
    handle: '@simondlr',
    twitter: 'https://twitter.com/simondlr',
    bio: (
      <>
        Advisor,{' '}
        <a
          href="https://twitter.com/oceanprotocol"
          target="_blank"
          rel="noopener noreferrer">
          @OceanProtocol
        </a>{' '}
        <a
          href="https://twitter.com/commonsstack"
          target="_blank"
          rel="noopener noreferrer">
          @CommonsStack
        </a>{' '}
        <a
          href="https://twitter.com/ujomusic"
          target="_blank"
          rel="noopener noreferrer">
          @UjoMusic
        </a>
      </>
    ),
  },
  {
    name: 'Devin Finzer',
    handle: '@dfinzer',
    twitter: 'https://twitter.com/dfinzer',
    bio: (
      <>
        Co-Founder &amp; Chief Executive Officer,{' '}
        <a
          href="https://twitter.com/opensea"
          target="_blank"
          rel="noopener noreferrer">
          @OpenSea
        </a>
      </>
    ),
  },
  {
    name: 'Franklin Fitch',
    handle: '@cryptofitch',
    twitter: 'https://twitter.com/cryptofitch',
    bio: (
      <>
        Director of Marketing &amp; Curation,{' '}
        <a
          href="https://twitter.com/Blockparty"
          target="_blank"
          rel="noopener noreferrer">
          @Blockparty
        </a>
      </>
    ),
  },
  {
    name: 'Brian Flynn',
    handle: '@flynnjamm',
    twitter: 'https://twitter.com/flynnjamm',
    bio: <>Curator</>,
  },
  {
    name: 'Nate Geier',
    handle: '@nategeier',
    twitter: 'https://twitter.com/nategeier',
    bio: (
      <>
        Co-Founder &amp; Chief Executive Officer,{' '}
        <a
          href="https://twitter.com/mintbase"
          target="_blank"
          rel="noopener noreferrer">
          @Mintbase
        </a>
      </>
    ),
  },
  {
    name: 'Roham Gharegozlou',
    handle: '@rohamg',
    twitter: 'https://twitter.com/rohamg',
    bio: (
      <>
        Co-Founder &amp; Chief Executive Officer,{' '}
        <a
          href="https://twitter.com/dapperlabs"
          target="_blank"
          rel="noopener noreferrer">
          @DapperLabs
        </a>
      </>
    ),
  },
  {
    name: 'Vlad Ginzburg',
    handle: '@vlad_ginzburg',
    twitter: 'https://twitter.com/vlad_ginzburg',
    bio: (
      <>
        Co-founder &amp; Chief Executive Officer,{' '}
        <a
          href="https://twitter.com/Blockparty"
          target="_blank"
          rel="noopener noreferrer">
          @Blockparty
        </a>
      </>
    ),
  },
  {
    name: 'Drew Harding',
    handle: '@drewharding',
    twitter: 'https://twitter.com/drewharding',
    bio: (
      <>
        Co-Founder,{' '}
        <a
          href="https://twitter.com/themetafactory"
          target="_blank"
          rel="noopener noreferrer">
          @themetafactory
        </a>
      </>
    ),
  },
  {
    name: 'Jacob Horne',
    handle: '@js_horne',
    twitter: 'https://twitter.com/js_horne',
    bio: (
      <>
        Co-Founder &amp; Chief Executive Officer,{' '}
        <a
          href="https://twitter.com/ourzora"
          target="_blank"
          rel="noopener noreferrer">
          @ourZora
        </a>
      </>
    ),
  },
  {
    name: 'Lindsay Howard',
    handle: '@Lindsay_Howard',
    twitter: 'https://twitter.com/Lindsay_Howard',
    bio: (
      <>
        Head of Community,{' '}
        <a
          href="https://twitter.com/withfnd"
          target="_blank"
          rel="noopener noreferrer">
          @withFND
        </a>
      </>
    ),
  },
  {
    name: 'Joel Hubert',
    handle: '@jmahh',
    twitter: 'https://twitter.com/jmahh',
    bio: (
      <>
        Co-founder &amp; Chief Executive Officer,{' '}
        <a
          href="https://twitter.com/NIFTEXdotcom"
          target="_blank"
          rel="noopener noreferrer">
          @NIFTEXdotcom
        </a>
      </>
    ),
  },
  {
    name: 'Xavier Iturralde',
    handle: '@xavieriturralde',
    twitter: 'https://twitter.com/xavieriturralde',
    bio: (
      <>
        Creator, Designer, Artist, Founder of $PIXEL &amp; Summoner Founder Art
        Director,{' '}
        <a
          href="https://twitter.com/Aavegotchi"
          target="_blank"
          rel="noopener noreferrer">
          @Aavegotchi
        </a>
      </>
    ),
  },
  {
    name: 'Ilya Komolkin',
    handle: '@dappdesigner',
    twitter: 'https://twitter.com/dappdesigner',
    bio: (
      <>
        Head of Design &amp; Co-founder,{' '}
        <a
          href="https://twitter.com/rariblecom"
          target="_blank"
          rel="noopener noreferrer">
          @rariblecom
        </a>
      </>
    ),
  },
  {
    name: 'Aleksander Leonard Larsen',
    handle: '@Psycheout86',
    twitter: 'https://twitter.com/psycheout86',
    bio: (
      <>
        Co-Founder &amp; Chief Operating Officer,{' '}
        <a
          href="https://twitter.com/axieinfinity"
          target="_blank"
          rel="noopener noreferrer">
          @AxieInfinity
        </a>
      </>
    ),
  },
  {
    name: 'Igor Lilic',
    handle: '@ililic',
    twitter: 'https://twitter.com/ililic',
    bio: (
      <>
        Co-Founder,{' '}
        <a
          href="https://twitter.com/dontbuymeme"
          target="_blank"
          rel="noopener noreferrer">
          @DontBuyMeme
        </a>
      </>
    ),
  },
  {
    name: 'Jordan Lyall',
    handle: '@JordanLyall',
    twitter: 'https://twitter.com/JordanLyall',
    bio: (
      <>
        Co-Founder,{' '}
        <a
          href="https://twitter.com/dontbuymeme"
          target="_blank"
          rel="noopener noreferrer">
          @DontBuyMeme
        </a>
      </>
    ),
  },
  {
    name: 'Shiv Madan',
    handle: '@shivcoin',
    twitter: 'https://twitter.com/shivcoin',
    bio: (
      <>
        Co-Founder &amp; Chief Product Officer,{' '}
        <a
          href="https://twitter.com/Blockparty"
          target="_blank"
          rel="noopener noreferrer">
          @Blockparty
        </a>
        <br />
        Contributor,{' '}
        <a
          href="https://twitter.com/nft_protocol"
          target="_blank"
          rel="noopener noreferrer">
          @NFT_Protocol
        </a>
      </>
    ),
  },
  {
    name: 'Matty',
    handle: '@dclblogger',
    twitter: 'https://twitter.com/dclblogger',
    bio: <>NFT Investor</>,
  },
  {
    name: 'Kevin McCoy',
    handle: '@mccoyspace',
    twitter: 'https://twitter.com/mccoyspace',
    bio: 'Artist',
  },
  {
    name: 'Tyler Mulvihill',
    handle: '@MulvihillTyler',
    twitter: 'https://twitter.com/MulvihillTyler',
    bio: (
      <>
        Co-Founder,{' '}
        <a
          href="https://twitter.com/treum_io"
          target="_blank"
          rel="noopener noreferrer">
          @treum_io
        </a>
      </>
    ),
  },
  {
    name: 'Nelly',
    handle: '@ArtByNelly',
    twitter: 'https://twitter.com/ArtByNelly',
    bio: <>Artist</>,
  },
  {
    name: 'Jon Perkins',
    handle: '@jperkinsx',
    twitter: 'https://twitter.com/jperkinsx',
    bio: (
      <>
        Co-Founder &amp; Chief Product Officer,{' '}
        <a
          href="https://twitter.com/superrare_co"
          target="_blank"
          rel="noopener noreferrer">
          @SuperRare_co
        </a>
      </>
    ),
  },
  {
    name: 'Alex Russman',
    handle: '@alexrussman',
    twitter: 'https://twitter.com/alexrussman',
    bio: (
      <>
        Director of Business Development,{' '}
        <a
          href="https://twitter.com/Enjin"
          target="_blank"
          rel="noopener noreferrer">
          @Enjin
        </a>
      </>
    ),
  },
  {
    name: 'Derek Edward Schloss',
    handle: '@derekedws',
    twitter: 'https://twitter.com/derekedws',
    bio: (
      <>
        Partner,{' '}
        <a
          href="https://twitter.com/collab_currency"
          target="_blank"
          rel="noopener noreferrer">
          @Collab_Currency
        </a>
      </>
    ),
  },
  {
    name: 'Serena Tabacchi',
    handle: '@SerenaTabacchi',
    twitter: 'https://twitter.com/SerenaTabacchi',
    bio: (
      <>
        Director and Co-founder,{' '}
        <a
          href="https://twitter.com/Mocda_"
          target="_blank"
          rel="noopener noreferrer">
          @Mocda_
        </a>
      </>
    ),
  },
  {
    name: 'Kayvon Tehranian',
    handle: '@saturnial',
    twitter: 'https://twitter.com/saturnial',
    bio: (
      <>
        Founder &amp; Chief Executive Officer,{' '}
        <a
          href="https://twitter.com/withfnd"
          target="_blank"
          rel="noopener noreferrer">
          @withFND
        </a>
      </>
    ),
  },

  {
    name: 'Matthew Vernon',
    handle: '@dApp_boi',
    twitter: 'https://twitter.com/dApp_boi',
    bio: (
      <>
        Head of Design,{' '}
        <a
          href="https://twitter.com/withFND"
          target="_blank"
          rel="noopener noreferrer">
          @withFND
        </a>
      </>
    ),
  },
  {
    name: 'Carolin Wend',
    handle: '@CarolinWend',
    twitter: 'https://twitter.com/carolinwend',
    bio: (
      <>
        Co-Founder &amp; Director of Business Development,{' '}
        <a
          href="https://twitter.com/mintbase"
          target="_blank"
          rel="noopener noreferrer">
          @Mintbase
        </a>
      </>
    ),
  },
  {
    name: 'Joon Ian Wong',
    handle: '@joonian',
    twitter: 'https://twitter.com/joonian',
    bio: (
      <>
        Journalist,{' '}
        <a
          href="https://joonian.media"
          target="_blank"
          rel="noopener noreferrer">
          joonian.media
        </a>
      </>
    ),
  },
  {
    name: 'John Orion Young',
    handle: '@JohnOrionYoung',
    twitter: 'https://twitter.com/JohnOrionYoung',
    bio: (
      <>
        Creator,{' '}
        <a
          href="https://www.joy.lol/"
          target="_blank"
          rel="noopener noreferrer">
          joy.lol
        </a>
      </>
    ),
  },
  {
    name: 'Stephen Young',
    handle: '@stephen_yo',
    twitter: 'https://twitter.com/stephen_yo',
    bio: (
      <>
        Founder and Chief Executive Officer,{' '}
        <a
          href="https://twitter.com/nftfi"
          target="_blank"
          rel="noopener noreferrer">
          @nftfi
        </a>
      </>
    ),
  },
];

export default function MentorsNFT() {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgMentorsText = getText('OrgMentorsText');

  return (
    <Wrap className="section-wrapper">
      <FadeIn>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">
            {orgMentorsText}
          </h2>
        </div>

        <div className="card org-card org-mentors-container">
          <div
            className={`${s['mentor-introduction']} org-mentor-introduction`}>
            <p>
              Curators are part of the Flamingo network. They help Flamingo
              identify trends, spot emerging artists, and find other
              opportunities in the NFTs space. Curators are not members and do
              not make any acquisition decisions.
            </p>
          </div>

          <div className={`${s['mentor-container']} org-mentor-container`}>
            {curatorsList.map((curator) => (
              <MentorCardAlt
                key={curator.name}
                name={curator.name}
                handle={curator.handle}
                socialMediaLinks={{
                  twitter: curator.twitter,
                }}>
                {curator.bio}
              </MentorCardAlt>
            ))}
          </div>
        </div>
      </FadeIn>
    </Wrap>
  );
}
