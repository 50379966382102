import l from '../../assets/scss/modules/logo.module.scss';

interface LogoZKProps {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoZK(props: LogoZKProps) {
  const classNameSizes = {
    daoV2: props.size ? `${l[`daoV2--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.daoV2} ${classNameSizes.daoV2} org-logo`}>
      Zero DAO
    </div>
  );
}
