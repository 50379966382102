import l from '../../assets/scss/modules/logo.module.scss';

interface LogoSpaceshipProps {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoSpaceship(props: LogoSpaceshipProps) {
  const classNameSizes = {
    spaceship: props.size ? `${l[`spaceship--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.spaceship} ${classNameSizes.spaceship}`}>
      Spaceship
    </div>
  );
}
