import {Control} from 'react-hook-form/dist/types';

import {TypeOfTypeProp} from '../../../util/types';

export enum Forms {
  baseFundsProof = 'baseFundsProof',
  baseIdentity = 'baseIdentity',
  companyFundsProof = 'companyFundsProof',
  companyIdentity = 'companyIdentity',
  entity = 'entity',
  personFundsProof = 'personFundsProof',
  personIdentity = 'personIdentity',
  trustFundsProof = 'trustFundsProof',
  trustIdentity = 'trustIdentity',
}

export type OnFormChangeArgs = {
  formName: Forms;
  isValid: boolean;
  triggerValidation: TypeOfTypeProp<Control, 'triggerValidation'>;
  values: Record<string, any>;
};

export type OnFormChangeCallback = (formData: OnFormChangeArgs) => void;
