import React, {Fragment} from 'react';

import c from '../../assets/scss/modules/checkbox.module.scss';

interface IProps {
  checked: boolean;
  id: string;
  label: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type OtherProps = {[key: string]: any};

const Checkbox = ({...props}: IProps & OtherProps) => {
  // const {className} = props;

  return (
    <Fragment>
      <input
        {...props}
        aria-checked={props.checked}
        checked={props.checked}
        className={`${c['checkbox-input']} org-checkbox-input ${
          props.className || ''
        }`}
        id={props.id}
        name={props.name}
        onChange={props.onChange}
        type="checkbox"
      />

      <label
        className={`${c['checkbox-label']} org-checkbox-label`}
        htmlFor={props.id}>
        <span className={`${c['checkbox-box']} org-checkbox-box`}></span>
        <span>{props.label}</span>
      </label>
    </Fragment>
  );
};

export default Checkbox;
