export enum SnapshotVote {
  Yes = 'Yes',
  No = 'No',
}

export enum SnapshotProposalType {
  Investment = 'Investment',
  Governance = 'Governance',
}

export enum SnapshotProposalSubType {
  /**
   * A Snapshot proposal sub-type where the decision to pass requires a simple majority.
   * e.g. 'Yes' votes are greater than 'No' votes so the vote passed.
   *
   * @note This is the default value for the Snapshot proposal `subType` field.
   */
  General = 'General',
  /**
   * A Snapshot proposal sub-type where the decision to pass requires > `GOVERNANCE_PASS_PERCENTAGE`
   * e.g. > 50% votes.
   */
  Governance = 'Governance',
}

export enum SnapshotView {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}
