import {useSelector} from 'react-redux';

import {
  GetInvestmentProposalResponse,
  GetInvestmentProposalResponseMoloch,
} from '../util/types';
import {normalizeString} from '../util/helpers';
import {StoreState} from '../util/types';
import {useIsAdmin} from '.';

export function useCanViewInvestmentProposal() {
  /**
   * Selectors
   */

  const isMember = useSelector((s: StoreState) =>
    s.connectedMember && s.connectedMember.isMemberActive ? true : false
  );
  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );

  /**
   * Our hooks
   */

  const isAdmin = useIsAdmin();

  /**
   * Functions
   */

  function canViewInvestmentProposal(
    proposal: GetInvestmentProposalResponse | Record<string, any> | undefined
  ): boolean {
    if (!proposal) return false;

    const {private: isPrivate, proposalTarget} = proposal;

    if (!isPrivate) return true;

    const {isAdmin, isMember, isMolochProposerOrApplicant, isProposalOwner} =
      getInvestmentProposalPermissions(proposal);

    switch (proposalTarget) {
      case 'moloch':
        return (
          isMolochProposerOrApplicant || isProposalOwner || isMember || isAdmin
        );
      case 'snapshot':
        return isProposalOwner || isMember || isAdmin;
      case null:
        return isProposalOwner || isMember || isAdmin;
      default:
        return true;
    }
  }

  function getInvestmentProposalPermissions(
    proposal: GetInvestmentProposalResponse | Record<string, any> | undefined
  ) {
    const {proposalTarget} = proposal || {};
    const {ethereumAddress = ''} = proposal?.user || {};

    const isProposalOwner =
      ethereumAddress && connectedAddress
        ? ethereumAddress.toLowerCase() === connectedAddress.toLowerCase()
        : false;

    const isMolochProposerOrApplicant =
      proposalTarget === 'moloch'
        ? checkIsMolochProposerOrApplicant(
            proposal as GetInvestmentProposalResponseMoloch &
              Record<string, any>
          )
        : false;

    return {
      isAdmin,
      isMember,
      isMolochProposerOrApplicant,
      isProposalOwner,
    };
  }

  /**
   * checkIsMolochProposerOrApplicant
   *
   * This is mostly to be extra safe for Moloch proposals
   * that the proposal's `proposer` or `applicant` addresses can
   * view the proposal.
   *
   * @param {GetInvestmentProposalResponseMoloch & Record<string, any>}
   *   A combined server Moloch response and any data spread-in from the gql query.
   * @returns {boolean}
   */
  function checkIsMolochProposerOrApplicant({
    proposer,
    applicant,
  }: GetInvestmentProposalResponseMoloch & Record<string, any>): boolean {
    const isOwner =
      connectedAddress && proposer
        ? normalizeString(proposer) === normalizeString(connectedAddress)
        : false;

    const isApplicant =
      connectedAddress && applicant
        ? normalizeString(applicant) === normalizeString(connectedAddress)
        : false;

    return isOwner || isApplicant;
  }

  return {
    canViewInvestmentProposal,
    getInvestmentProposalPermissions,
  };
}
