import React, {useEffect, useCallback, useRef, useState} from 'react';
import {FormContext, useForm, Controller, useFieldArray} from 'react-hook-form';

import {
  ACCEPTED_DOC_MIME_TYPES,
  ERROR_FILE_TOO_LARGE,
  ERROR_REQUIRED_FIELD,
  MAX_FILE_SIZE,
} from './helper';
import {getByObjectPath, getValidationError} from '../../../util/helpers';
import {OnFormChangeCallback, Forms, OnFormChangeArgs} from './types';
import IdentityBaseForm, {IdentityBaseValues} from './IdentityBaseForm';
import InputError from '../../../components/common/InputError';
import RadioList from '../../../components/common/RadioList';
import XCloseSVG from '../../../assets/svg/XCloseSVG';

import b from '../../../assets/scss/modules/buttons.module.scss';
import i from '../../../assets/scss/modules/input.module.scss';
import s from '../../../assets/scss/modules/memberverify.module.scss';
import FileDropzone from '../../../components/common/FileDropzone';

enum Fields {
  taxIdNumber = 'taxIdNumber', // don't prefix with "company", keep generic for server
  trustAgreement = 'trustAgreement',
  trustBeneficiaries = 'trustBeneficiaries',
  trustSignatoryLegalName = 'trustSignatoryLegalName',
  trustSignatoryTitle = 'trustSignatoryTitle',
  trustType = 'trustType',
}

type Values = {
  [Fields.taxIdNumber]: string;
  [Fields.trustAgreement]: File;
  [Fields.trustBeneficiaries]: {name: string; ssnOrPassportNumber: string}[];
  [Fields.trustSignatoryLegalName]: string;
  [Fields.trustSignatoryTitle]: string;
  [Fields.trustType]: 'Revocable' | 'Irrevocable';
};

export type IdentityTrustValues = Values & IdentityBaseValues;

type IdentityTrustFormProps = {
  onFormChange: OnFormChangeCallback;
};

const INITIAL_VALUES: Partial<Values> = {
  trustType: 'Revocable',
};

// validation configuration for react-hook-form
const ruleRequired = {required: ERROR_REQUIRED_FIELD};
const filePDFValidate = {
  validate: (file: File) => {
    return !file
      ? ERROR_REQUIRED_FIELD
      : (file.size > MAX_FILE_SIZE ? true : false)
      ? ERROR_FILE_TOO_LARGE
      : !ACCEPTED_DOC_MIME_TYPES.includes(file.type)
      ? 'The file is not the correct type. Please provide a .pdf, .doc, .docx file.'
      : true;
  },
};
const taxIdNumberValidate = {
  validate: (numberString: string) => {
    // make optional
    return !numberString
      ? true
      : numberString && /^\d{2}-\d{7}$|^\d{9}$/.test(numberString) === false
      ? 'Please provide a valid tax ID number.'
      : true;
  },
};
const validateConfig: Partial<Record<Fields, Record<string, any>>> = {
  trustSignatoryLegalName: ruleRequired,
  trustSignatoryTitle: ruleRequired,
  taxIdNumber: taxIdNumberValidate,
  trustAgreement: filePDFValidate,
  trustBeneficiaries: ruleRequired,
  trustType: ruleRequired,
};

export default React.memo(function IdentityTrustForm(
  props: IdentityTrustFormProps
) {
  const baseFormDataRef = useRef<OnFormChangeArgs>();
  const form = useForm({
    defaultValues: INITIAL_VALUES,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const {control, errors, formState, register, watch} = form;
  // watch all values that change
  // see: https://react-hook-form.com/api/#watch
  const formValues = watch({nest: true});
  const [isBaseFormValid, setIsBaseFormValid] = useState<boolean>(false);
  const beneficiariesFieldArray = useFieldArray({
    control,
    name: Fields.trustBeneficiaries,
  });

  /**
   * @note From the docs: "Read the formState before render to subscribe the form state through Proxy"
   * @see https://react-hook-form.com/api#formState
   */
  const {isValid} = formState;

  const handleBaseFormChange = useCallback((formData) => {
    baseFormDataRef.current = formData;

    setIsBaseFormValid(formData.isValid);
  }, []);

  // Add an initial, required beneficiary to the array of dynamic fields (add/remove)
  useEffect(() => {
    if (beneficiariesFieldArray.fields.length) return;

    beneficiariesFieldArray.append({
      name: Fields.trustBeneficiaries,
    });
  }, [beneficiariesFieldArray]);

  // send parent the current form status
  useEffect(() => {
    if (!baseFormDataRef.current) return;

    props.onFormChange({
      formName: Forms.trustIdentity,
      isValid: isValid && baseFormDataRef.current.isValid,
      triggerValidation: async () => {
        const validation = await form.triggerValidation();
        const baseValidation = baseFormDataRef.current
          ? await baseFormDataRef.current.triggerValidation()
          : false;

        return validation && baseValidation;
      },
      values: {
        ...form.getValues({nest: true}),
        ...baseFormDataRef.current.values,
      },
    });
  }, [form, formValues, isBaseFormValid, isValid, props]);

  return (
    <>
      <header>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">Identity</h2>
        </div>
      </header>

      <FormContext {...form}>
        <div className="org-verify-form-wrap">
          <form
            className="org-verify-form"
            onSubmit={(e) => e.preventDefault()}>
            <h3
              className={`${s['form-section-heading']} org-form-section-heading`}>
              Trust details
            </h3>

            {/* TRUST TYPE */}
            <label className={`${i['label--column']} org-label--column`}>
              Type of trust
            </label>
            <Controller
              as={
                <RadioList
                  items={['Revocable', 'Irrevocable']}
                  defaultValue="Revocable"
                />
              }
              name={Fields.trustType}
            />

            {/* (U.S. ONLY TAX ID NUMBER) */}
            <label className={`${i['label--column']} org-label--column`}>
              <span>(U.S. ONLY) Tax ID Number</span>
              <input
                aria-describedby="error-taxIdNumber"
                aria-invalid={errors.taxIdNumber ? 'true' : 'false'}
                name={Fields.taxIdNumber}
                ref={
                  validateConfig.taxIdNumber &&
                  register(validateConfig.taxIdNumber)
                }
                type="text"
              />
            </label>
            <InputError
              error={getValidationError(Fields.taxIdNumber, errors)}
              id="error-taxIdNumber"
            />

            <h3
              className={`${s['form-section-heading']} org-form-section-heading`}>
              Trust agreement
            </h3>

            {/* TRUST AGREEMENT */}
            <small>Upload a trust agreement in English.</small>
            <Controller
              as={
                <FileDropzone
                  acceptedTypes={ACCEPTED_DOC_MIME_TYPES}
                  aria-describedby="error-trustAgreement"
                  aria-invalid={errors.trustAgreement ? 'true' : 'false'}
                />
              }
              // I would think typically you don't return values
              // from an onChange handler, but it works.
              // see: https://react-hook-form.com/api/#Controller
              onChange={([event]) => event.target.files[0]}
              name={Fields.trustAgreement}
              rules={validateConfig.trustAgreement}
            />
            <InputError
              error={getValidationError(Fields.trustAgreement, errors)}
              id="error-trustAgreement"
            />

            <h3
              className={`${s['form-section-heading']} org-form-section-heading`}>
              Grantors and Beneficiaries
            </h3>

            {/* BENEFICIARIES ADD/REMOVE */}
            <div>
              {/* DYNAMIC BENEFICIARIES */}
              {beneficiariesFieldArray.fields.map((field, fieldIndex) => (
                <div
                  className={`${s['flex-container']} ${s['inputs-row']}`}
                  key={field.id}>
                  {/* BENEFICIARY NAME */}
                  <div className={s['inputs-row-item--w40']}>
                    <label
                      className={`${i['label--column']} org-label--column ${s['collection-label']}`}>
                      <span>Legal name</span>
                      <input
                        aria-describedby={`error-trustBeneficiariesName-${fieldIndex}`}
                        aria-invalid={
                          errors.trustBeneficiaries ? 'true' : 'false'
                        }
                        name={`${Fields.trustBeneficiaries}[${fieldIndex}].name`}
                        ref={
                          validateConfig.trustBeneficiaries &&
                          register(validateConfig.trustBeneficiaries)
                        }
                        type="text"
                      />
                    </label>
                    <InputError
                      error={getByObjectPath(
                        errors,
                        `${Fields.trustBeneficiaries}[${fieldIndex}].name.message`,
                        ''
                      )}
                      id={`error-trustBeneficiariesName-${fieldIndex}`}
                    />
                  </div>
                  {/* BENEFICIARY PASSPORT/SSN */}
                  <div>
                    <label
                      className={`${i['label--column']} org-label--column ${s['collection-label']}`}>
                      <span>Social Security or passport number</span>
                      <input
                        aria-describedby={`error-trustBeneficiariesIdNumber-${fieldIndex}`}
                        aria-invalid={
                          errors.trustBeneficiaries ? 'true' : 'false'
                        }
                        name={`${Fields.trustBeneficiaries}[${fieldIndex}].ssnOrPassportNumber`}
                        ref={
                          validateConfig.trustBeneficiaries &&
                          register(validateConfig.trustBeneficiaries)
                        }
                        type="text"
                      />
                    </label>

                    <InputError
                      error={getByObjectPath(
                        errors,
                        `${Fields.trustBeneficiaries}[${fieldIndex}].ssnOrPassportNumber.message`,
                        ''
                      )}
                      id={`error-trustBeneficiariesIdNumber-${fieldIndex}`}
                    />
                  </div>

                  {/* COLLECTION ITEM REMOVE BUTTON */}
                  {fieldIndex > 0 && (
                    <button
                      className={s['collection-remove-button']}
                      onClick={(e) => {
                        e.preventDefault();
                        beneficiariesFieldArray.remove(fieldIndex);
                      }}
                      type="button">
                      <XCloseSVG
                        aria-labelledby={`remove-shareholder-${fieldIndex}`}
                        fill="#ff6f6f" /* $color-brightsalmon */
                      />
                      <span
                        id={`remove-shareholder-${fieldIndex}`}
                        className="hidden">
                        Remove
                      </span>
                    </button>
                  )}
                </div>
              ))}

              {/* COLLECTION ITEM ADD BUTTON */}
              {/* Maximum 10 shareholders (including the static one) */}
              {beneficiariesFieldArray.fields.length <= 9 && (
                <button
                  className={`${b['secondary']} org-secondary-button ${b['secondary--small']} ${s['collection-add-button']}`}
                  onClick={(e) => {
                    e.preventDefault();
                    beneficiariesFieldArray.append({
                      name: Fields.trustBeneficiaries,
                    });
                  }}
                  type="button">
                  Add another grantor or beneficiary
                </button>
              )}
            </div>

            {/* SIGATORY DETAILS */}

            <h3
              className={`${s['form-section-heading']} org-form-section-heading`}>
              Signatory details
            </h3>

            <label>
              <span className={`${i['label--column']} org-label--column`}>
                Legal Name
              </span>
              <input
                aria-describedby="error-companySignatoryLegalName"
                aria-invalid={errors.trustSignatoryLegalName ? 'true' : 'false'}
                name={Fields.trustSignatoryLegalName}
                placeholder="Your First, Middle & Last Name"
                ref={
                  validateConfig.trustSignatoryLegalName &&
                  register(validateConfig.trustSignatoryLegalName)
                }
                type="text"
              />
              <InputError
                error={getValidationError(
                  Fields.trustSignatoryLegalName,
                  errors
                )}
                id="error-trustSignatoryLegalName"
              />
            </label>

            <label>
              <span className={`${i['label--column']} org-label--column`}>
                Title at trust
              </span>
              <input
                aria-describedby="error-trustSignatoryTitle"
                aria-invalid={errors.trustSignatoryTitle ? 'true' : 'false'}
                name={Fields.trustSignatoryTitle}
                ref={
                  validateConfig.trustSignatoryTitle &&
                  register(validateConfig.trustSignatoryTitle)
                }
                type="text"
              />
              <InputError
                error={getValidationError(Fields.trustSignatoryTitle, errors)}
                id="error-trustSignatoryTitle"
              />
            </label>

            <IdentityBaseForm onFormChange={handleBaseFormChange} />
          </form>
        </div>
      </FormContext>
    </>
  );
});
