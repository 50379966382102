import React, {useEffect, useLayoutEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';

import {getCurrentUser} from '../../../store/actions';
import {
  formatEthereumAddress,
  formatNumber,
  getOrgText,
  trimText,
} from '../../../util/helpers';
import {ProposalType, FetchStatus} from '../../../util/enums';
import {StoreState, ReduxDispatch} from '../../../util/types';
import {REST_POLLING_INTERVAL} from '../../../util/config';
import {useBackendURL, useIsAdmin} from '../../../hooks';
import {useUSDToWEI} from '../../../hooks/useUSDToWEI';
// import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import FadeIn from '../../../components/common/FadeIn';
import HiThereLoader from '../../../components/feedback/HiThereLoader';
import MinionProposal from '../../proposals/moloch/MinionProposal';
import MolochInvestmentProposalFunded from '../../proposals/moloch/MolochInvestmentProposalFunded';
import MolochInvestmentProposalNotFunded from '../../proposals/moloch/MolochInvestmentProposalNotFunded';
import MolochVoting from '../../proposals/moloch/MolochVoting';
import Loader from '../../../components/feedback/Loader';
import Wrap from '../../../components/layout/Wrap';

import useMolochProposal from '../../../hooks/useMolochProposal';
import useSidePocketProposal from '../../../hooks/useSidePocketProposal';

import s from '../../../assets/scss/modules/proposaldetails.module.scss';

/**
 * SidePocketDetails
 */
export default function SidePocketDetails() {
  /**
   * State
   */

  const [amountRequestedETH, setAmountRequestedETH] = useState<string>();
  const [canView, setCanView] = useState<boolean>();
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [molochProposal, setMolochProposal] = useState<Record<string, any>>();

  /**
   * Selectors
   */

  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );
  const connectedMember = useSelector((s: StoreState) => s.connectedMember);
  // const orgFeatures = useSelector((s: StoreState) => s.org && s.org.features);
  const walletAuthenticated = useSelector(
    (s: StoreState) => s.blockchain.walletAuthenticated
  );
  const web3Instance = useSelector(
    (s: StoreState) => s.blockchain.web3Instance
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  const {id: proposalId} = useParams<{id: string}>();

  /**
   * Get proposal details hook
   */

  const isAdmin = useIsAdmin();
  const {
    sidePocketProposal: proposalDetails,
    sidePocketProposalStatus,
    refetchSidePocket,
  } = useSidePocketProposal(Number(proposalId));

  /**
   * Variables
   */

  const isMember = connectedMember && connectedMember.isMemberActive;
  const molochProposalIdSafe =
    proposalDetails &&
    proposalDetails.molochProposalId !== null &&
    proposalDetails.molochProposalId !== undefined &&
    proposalDetails.molochProposalId >= 0
      ? Number(proposalDetails.molochProposalId)
      : undefined;
  const getText = getOrgText(orgText);
  const orgProjectText = getText('OrgProjectText');
  const orgName = getText('OrgName');

  /**
   * Hooks state
   */

  const backendURL = useBackendURL();
  const dispatch = useDispatch<ReduxDispatch>();
  const history = useHistory();
  const {molochGQLProposal} = useMolochProposal(
    molochProposalIdSafe ? molochProposalIdSafe : null
  );
  const {WEIFromUSD, WEIFromUSDStatus} = useUSDToWEI(
    proposalDetails && proposalDetails.fundingAmountRequestedUSD
  );

  /**
   * Cached functions (for hooks deps)
   */

  const fetchCurrentUserCached = useCallback(fetchCurrentUser, [
    backendURL,
    dispatch,
  ]);

  /**
   * Variables
   */

  const isProjectApplicant =
    molochGQLProposal && connectedAddress
      ? molochGQLProposal.applicant.toLowerCase() ===
        connectedAddress.toLowerCase()
      : proposalDetails &&
        connectedAddress &&
        proposalDetails.addressToFund.toLowerCase() ===
          connectedAddress.toLowerCase();

  const intervalMs = REST_POLLING_INTERVAL;

  /**
   * Effects
   */

  useEffect(() => {
    const intervalId = setInterval(() => {
      isPolling && refetchSidePocket();
    }, intervalMs);

    if (
      proposalDetails?.molochProposalId &&
      proposalDetails.molochProposalIndex &&
      isPolling
    ) {
      setIsPolling(false);

      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [intervalMs, isPolling, proposalDetails, refetchSidePocket]);

  // If the proposal fetch fails, redirect to the 404 page
  useEffect(() => {
    if (sidePocketProposalStatus === FetchStatus.REJECTED) {
      history.push('/404');
    }
  }, [sidePocketProposalStatus, history]);

  // Set canView
  useEffect(() => {
    if (!proposalDetails) return;

    setCanView(
      proposalDetails.private === false ||
        isProjectApplicant ||
        isMember ||
        isAdmin
    );
  }, [isProjectApplicant, isMember, isAdmin, proposalDetails]);

  /**
   * Set amount of Moloch proposal `paymentRequested` to ETH (2 decimal places).
   * @note this sets the funding amount for proposals already submitted to moloch
   */
  useEffect(() => {
    if (!web3Instance || !molochGQLProposal || !proposalDetails) {
      return;
    }

    // retain state, to prevent proposal sidebar showing null when undefined
    setMolochProposal(molochGQLProposal);

    try {
      const ethAmountRequested = web3Instance.utils.fromWei(
        new BigNumber(molochGQLProposal.paymentRequested).toString()
      );

      setAmountRequestedETH(Number(ethAmountRequested).toFixed(2));
    } catch (error) {}
  }, [web3Instance, molochGQLProposal, proposalDetails]);

  /**
   * Fallback!
   *
   * Set amount of Moloch proposal `fundingAmountRequestedUSD` to ETH (2 decimal places)
   * @note this sets the funding amount for projects not in moloch,
   *
   * see https://github.com/openlawteam/lao/issues/856
   */
  useEffect(() => {
    if (WEIFromUSDStatus !== FetchStatus.FULFILLED || !WEIFromUSD) {
      return;
    }

    // Set the amount to fund in ETH
    const fundingRequestedETH = Web3.utils.fromWei(WEIFromUSD, 'ether');

    setAmountRequestedETH(Number(fundingRequestedETH).toFixed(2));
  }, [WEIFromUSD, WEIFromUSDStatus]);

  useLayoutEffect(() => {
    if (proposalDetails) {
      setIsLoadingPage(false);
    } else {
      connectedAddress && fetchCurrentUserCached(connectedAddress);
    }
  }, [proposalDetails, connectedAddress, fetchCurrentUserCached, isMember]);

  // Scroll to top of window on page mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Functions
   */

  async function fetchCurrentUser(connectedAddress: string) {
    if (!backendURL) return;

    try {
      await dispatch(getCurrentUser(connectedAddress, backendURL));
    } catch (error) {
      // @todo handle error
    }
  }

  async function handleOnVoteProcess(txHash: string) {
    if (!backendURL) {
      throw new Error('No backend URL was found.');
    }

    if (!proposalDetails) {
      throw new Error('No Moloch proposal was found.');
    }

    try {
      // Send to server for processing
      const processingResponse = await fetch(
        `${backendURL}/sidepocket/${proposalDetails.uuid}/process`,
        {
          method: 'PATCH',
          body: JSON.stringify({
            txHash,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!processingResponse.ok) {
        throw new Error('Something went wrong while processing the proposal.');
      }
    } catch (error) {
      throw error;
    }
  }

  function displayProposalActions(): React.ReactNode {
    if (!proposalDetails) return null;

    const hasMolochProposalId =
      molochProposalIdSafe !== -1 &&
      molochProposalIdSafe !== undefined &&
      molochProposalIdSafe !== null;
    const hasMolochProposalIndex =
      proposalDetails.molochProposalIndex !== -1 &&
      proposalDetails.molochProposalIndex !== undefined &&
      proposalDetails.molochProposalIndex !== null;

    /**
     * Using `molochGQLProposal` to detemine the status of the proposal
     */

    if (molochProposal && hasMolochProposalId && hasMolochProposalIndex) {
      const {details} = molochProposal;
      const proposalType = details.toLowerCase().startsWith(ProposalType.MINION)
        ? ProposalType.MINION
        : ProposalType.PAYMENT;

      if (molochProposal.sponsored && !molochProposal.processed) {
        /**
         * VOTING PROPOSALS
         */
        return (
          <MolochVoting
            molochProposal={molochProposal}
            /**
             * Is called during a proposal's processing.
             */
            onVoteProcess={handleOnVoteProcess}
            proposalType={proposalType}
          />
        );
      } else if (molochProposal.processed && !molochProposal.didPass) {
        /**
         * VOTING COMPLETED, FAILED PROPOSALS
         */
        return (
          <MolochInvestmentProposalNotFunded molochProposal={molochProposal} />
        );
      } else if (molochProposal.processed && molochProposal.didPass) {
        /**
         * VOTING COMPLETED, APPROVED PROPOSALS
         */
        if (proposalType === ProposalType.MINION) {
          /**
           * EXECUTE MINION ACTION
           */
          return <MinionProposal molochProposal={molochProposal} />;
        } else {
          /**
           * PASSED PROJECT PROPOSALS
           */
          return (
            <MolochInvestmentProposalFunded molochProposal={molochProposal} />
          );
        }
      }
    } else if (!hasMolochProposalId || !hasMolochProposalIndex) {
      // poll until id and index are available
      !isPolling && setIsPolling(true);

      return (
        <div className="org-proposal-status--container">
          <small>Voting will commence shortly!</small>
          <Loader
            style={{
              height: '10px',
              margin: '10px',
              width: '10px',
            }}
            text="Retrieving proposal status&hellip;please wait"
          />
        </div>
      );
    } else {
      // Default fallthrough
      return null;
    }
  }

  function goBack(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    history.push('/governance-proposals');
  }

  function renderProposalMetadata() {
    if (!proposalDetails) return;

    if (canView) {
      const title = `${orgName} Guildbank Proposal | ${
        proposalDetails.name || '\u2026'
      }`;
      const description = trimText(proposalDetails.description, {
        characters: 200,
        word: true,
      });

      return (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
      );
    } else {
      return (
        <Helmet>
          <title>{`${orgName} - Guildbank Proposal`}</title>
          <meta
            name="description"
            content={`Guildbank Proposal for ${orgName}`}
          />
        </Helmet>
      );
    }
  }

  function displayPublicView() {
    return (
      <div>
        <section
          className={`${s['proposal-wrapper-alt']} org-proposal-wrapper-alt`}>
          {proposalDetails && Number(proposalId) === proposalDetails.id && (
            <>
              <section className={s['proposal-content']}>
                <h3
                  className={`${s['proposal-content-title']} org-proposal-content-title`}>
                  {orgProjectText} Details
                </h3>

                {/* DESCRIPTION */}
                <p>{proposalDetails.description}</p>
              </section>

              {/* RIGHT SIDE */}
              <aside
                className={`${s['proposal-details']} org-proposal-details`}>
                <div className={s['proposal-actions-container']}>
                  {displayProposalActions()}
                </div>
              </aside>
            </>
          )}
        </section>
      </div>
    );
  }

  return (
    <>
      {renderProposalMetadata()}

      <Wrap className={'section-wrapper'}>
        <FadeIn>
          {isLoadingPage && walletAuthenticated ? (
            <>
              <div style={{width: '3rem', margin: '0 auto'}}>
                <HiThereLoader />
              </div>
              <p className="text-center">Loading&hellip;</p>
            </>
          ) : !canView ? (
            <p className="color-yellow text-center" style={{marginTop: '4rem'}}>
              Sorry, you do not have access to this page.
            </p>
          ) : (
            <>
              <div className="titlebar">
                <h2 className="titlebar__title org-titlebar__title">
                  {(proposalDetails && proposalDetails.name) || '\u2026'}
                </h2>
                <button
                  className="titlebar__action org-titlebar__action"
                  onClick={goBack}>
                  &larr;<span className="titlebar__action-text">View all</span>
                </button>
              </div>

              {isProjectApplicant || isMember || isAdmin ? (
                <div>
                  <section
                    className={`${s['proposal-wrapper-alt']} org-proposal-wrapper-alt`}>
                    {proposalDetails && (
                      <>
                        <section className={s['proposal-content']}>
                          <h3
                            className={`${s['proposal-content-title']} org-proposal-content-title`}>
                            Proposal Details
                          </h3>

                          {/* DESCRIPTION */}
                          <p>{proposalDetails.description}</p>
                        </section>

                        {/* RIGHT SIDE */}
                        <aside
                          className={`${s['proposal-details']} org-proposal-details`}>
                          <div className={s['proposal-actions-container']}>
                            <div className={s['proposer-info']}>
                              <div
                                className={`${s['proposer-info__title']} org-proposer-info__title`}>
                                Proposer
                              </div>
                              <div className={s['proposer-info__address']}>
                                {
                                  proposalDetails.addressToFund
                                    ? formatEthereumAddress(
                                        proposalDetails.addressToFund,
                                        10
                                      )
                                    : '\u00A0\u2013' /* nbsp ndash */
                                }
                              </div>
                            </div>
                            {/* FUNDING REQUESTED in ETH */}
                            {amountRequestedETH && (
                              <div className={s['eth-requested-wrapper']}>
                                <div
                                  className={`${s['eth-requested']} org-eth-requested`}>
                                  <span>
                                    {`${formatNumber(
                                      amountRequestedETH
                                    )} ETH Requested`}
                                  </span>
                                </div>
                              </div>
                            )}
                            {/* VOTING BUTTONS */}
                            {displayProposalActions()}
                          </div>
                        </aside>
                      </>
                    )}
                  </section>
                </div>
              ) : (
                // hides ETH requested and Use of Funds if user is not Project
                // Owner, Member, or Admin
                displayPublicView()
              )}
            </>
          )}
        </FadeIn>
      </Wrap>
    </>
  );
}
