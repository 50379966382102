import React, {useEffect, useState, useCallback} from 'react';
import {FormContext, useForm, Controller} from 'react-hook-form';

import {
  ACCEPTED_DOC_MIME_TYPES,
  ERROR_FILE_TOO_LARGE,
  ERROR_REQUIRED_FIELD,
  MAX_FILE_SIZE,
} from './helper';
import {getValidationError, formatNumber} from '../../../util/helpers';
import {OnFormChangeCallback, Forms} from './types';
import FileDropzone from '../../../components/common/FileDropzone';
import InputError from '../../../components/common/InputError';
import RadioList from '../../../components/common/RadioList';

import i from '../../../assets/scss/modules/input.module.scss';
import s from '../../../assets/scss/modules/memberverify.module.scss';

enum Fields {
  basisForAccreditation = 'basisForAccreditation',
  companyAssets = 'companyAssets',
  companyAssetsProof = 'companyAssetsProof',
  companyOwnersAccreditationProof = 'companyOwnersAccreditationProof',
}

enum BasisForAccreditation {
  assetsOver5M = 'Company has over $5,000,000 in assets',
  allOwnersAccredited = 'All equity owners are accredited',
  allOwnersQualified = 'All equity owners are qualified purchasers',
}

export type Values = {
  [Fields.basisForAccreditation]: BasisForAccreditation;
  [Fields.companyAssets]: string;
  [Fields.companyAssetsProof]: File;
  [Fields.companyOwnersAccreditationProof]: File;
};

type Conditions = {
  [l in Fields]: boolean[];
};

type FundsProofCompanyFormProps = {
  onFormChange: OnFormChangeCallback;
};

const INITIAL_VALUES: Partial<Values> = {
  [Fields.basisForAccreditation]: BasisForAccreditation.assetsOver5M,
};

// validation configuration for react-hook-form
const ruleRequired = {required: ERROR_REQUIRED_FIELD};
const filePDFValidate = {
  validate: (file: File) => {
    return !file
      ? ERROR_REQUIRED_FIELD
      : (file.size > MAX_FILE_SIZE ? true : false)
      ? ERROR_FILE_TOO_LARGE
      : !ACCEPTED_DOC_MIME_TYPES.includes(file.type)
      ? 'The file is not the correct type. Please provide a .pdf, .doc, .docx file.'
      : true;
  },
};

const numberAmountValidate = (amount: number) => ({
  validate: (numberString: string) => {
    const parsedNumber = Number(numberString.replace(/,/g, ''));

    return !numberString
      ? ERROR_REQUIRED_FIELD
      : !parsedNumber
      ? 'Please provide a valid number'
      : parsedNumber < amount
      ? `The number is less than $${formatNumber(amount)}`
      : true;
  },
});

const getConditionsMap = (
  formValues: Record<string, any>
): Partial<Conditions> => ({
  [Fields.companyAssets]: [
    formValues[Fields.basisForAccreditation] ===
      BasisForAccreditation.assetsOver5M,
  ],
  [Fields.companyAssetsProof]: [
    formValues[Fields.basisForAccreditation] ===
      BasisForAccreditation.assetsOver5M,
  ],
  [Fields.companyOwnersAccreditationProof]: [
    formValues[Fields.basisForAccreditation] ===
      BasisForAccreditation.allOwnersAccredited ||
      formValues[Fields.basisForAccreditation] ===
        BasisForAccreditation.allOwnersQualified,
  ],
});

const validateConfig: Partial<Record<Fields, Record<string, any>>> = {
  basisForAccreditation: ruleRequired,
  companyAssets: numberAmountValidate(5000000),
  companyAssetsProof: filePDFValidate,
  companyOwnersAccreditationProof: filePDFValidate,
};

export default React.memo(function FundsProofPersonalForm(
  props: FundsProofCompanyFormProps
) {
  // mainly to restore conditional fields
  // as the formValues will add/remove props based on if rendered
  const [allValues, setToAllValues] = useState<Partial<Values>>({});

  const form = useForm({
    defaultValues: INITIAL_VALUES,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const {errors, formState, register, setValue, watch} = form;
  // watch all values that change
  // see: https://react-hook-form.com/api/#watch
  const formValues = watch() as Values;

  /**
   * @note From the docs: "Read the formState before render to subscribe the form state through Proxy"
   * @see https://react-hook-form.com/api#formState
   */
  const {isValid} = formState;

  const conditionsMap = getConditionsMap(formValues);

  const renderConditionsToString = JSON.stringify(
    Object.keys(conditionsMap).map((f) => ({
      [f]: testRenderConditions(f as Fields),
    }))
  );

  const testRenderConditionsCached = useCallback(testRenderConditions, [
    conditionsMap,
  ]);

  // send parent the current form status
  useEffect(() => {
    props.onFormChange({
      formName: Forms.companyFundsProof,
      isValid,
      triggerValidation: form.triggerValidation,
      values: form.getValues() as Values,
    });
  }, [form, isValid, props]);

  // re-validate if conditional is shown and has a value
  useEffect(() => {
    Object.keys(conditionsMap).forEach(async (f) => {
      const field = f as Fields;

      (await testRenderConditionsCached(field)) &&
        form.getValues()[field] &&
        form.triggerValidation(field, true);
    });
  }, [
    form,
    renderConditionsToString,
    testRenderConditionsCached,
    conditionsMap,
  ]);

  function testRenderConditions(field: Fields) {
    return (conditionsMap[field] || []).every((c: boolean) => c);
  }

  return (
    <>
      <header>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">Accreditation</h2>
        </div>
      </header>

      <FormContext {...form}>
        <div className="org-verify-form-wrap">
          <form
            className="org-verify-form"
            onSubmit={(e) => e.preventDefault()}>
            {/* BASIS FOR ACCREDITATION */}
            <label className={`${i['label--column']} org-label--column`}>
              Basis for Accreditation
            </label>
            <Controller
              as={
                <RadioList
                  items={[
                    BasisForAccreditation.assetsOver5M,
                    BasisForAccreditation.allOwnersAccredited,
                    BasisForAccreditation.allOwnersQualified,
                  ]}
                  defaultValue={BasisForAccreditation.assetsOver5M}
                />
              }
              name={Fields.basisForAccreditation}
            />

            {/* INCOME: RECENT TAX YEAR */}
            {testRenderConditions(Fields.companyAssets) && (
              <>
                <h3
                  className={`${s['form-section-heading']} org-form-section-heading`}>
                  Your company&rsquo;s assets
                </h3>

                <label className={`${i['label--column']} org-label--column`}>
                  <span>Assets</span>
                  <input
                    aria-describedby="error-companyAssets"
                    aria-invalid={errors.companyAssets ? 'true' : 'false'}
                    defaultValue={formatNumber(allValues.companyAssets || '')}
                    name={Fields.companyAssets}
                    onChange={(event) => {
                      const {value} = event.target;
                      // set this form field to a formatted value
                      setValue(Fields.companyAssets, formatNumber(value));

                      // set a value to remember between renders of conditional fields
                      setToAllValues({
                        ...allValues,
                        [Fields.companyAssets]: value,
                      });
                    }}
                    ref={
                      validateConfig.companyAssets &&
                      register(validateConfig.companyAssets)
                    }
                    type="text"
                  />
                </label>
                <InputError
                  error={getValidationError(Fields.companyAssets, errors)}
                  id="error-companyAssets"
                />
                <small>Must be at least $5,000,000</small>
              </>
            )}

            {/* COMPANY ASSETS PROOF */}
            {testRenderConditions(Fields.companyAssetsProof) && (
              <>
                <label className={`${i['label--column']} org-label--column`}>
                  Documentation of assets
                </label>
                <small>
                  Government filing showing net amount under management (Form D
                  or Form ADV in the US), financials prepared by accounting
                  firm, or letter from your lawyer or accountant indicating net
                  assets.
                </small>
                <br />
                <br />
                <small>
                  You can accredit based on your crypto assets. Upload a
                  screenshot from a credible cryptocurrency exchange or wallet
                  showing holdings &amp; valuations worth over $1MM USD. The
                  screenshot should include the date and evidence tying the
                  investor to the account (e.g. your name or the investing
                  entity&rsquo;s name). You will also need to provide
                  documentation of your debts (e.g. via a credit report or
                  letter from your accountant) in order to qualify.
                </small>
                <Controller
                  as={
                    <FileDropzone
                      acceptedTypes={ACCEPTED_DOC_MIME_TYPES}
                      aria-describedby="error-companyAssetsProof"
                      aria-invalid={
                        errors.companyAssetsProof ? 'true' : 'false'
                      }
                    />
                  }
                  // I would think typically you don't return values
                  // from an onChange handler, but it works.
                  // see: https://react-hook-form.com/api/#Controller
                  onChange={([event]) => event.target.files[0]}
                  name={Fields.companyAssetsProof}
                  rules={validateConfig.companyAssetsProof}
                />
                <InputError
                  error={getValidationError(Fields.companyAssetsProof, errors)}
                  id="error-companyAssetsProof"
                />
              </>
            )}

            {/* COMPANY OWNERS ACCREDITATION PROOF */}
            {testRenderConditions(Fields.companyOwnersAccreditationProof) && (
              <>
                <label className={`${i['label--column']} org-label--column`}>
                  Evidence of accreditation
                </label>
                <small>
                  Upload a statement from a lawyer, accountant, investment
                  advisor or investment broker indicating that all owners are{' '}
                  {formValues[Fields.basisForAccreditation] ===
                  BasisForAccreditation.allOwnersAccredited
                    ? 'accredited'
                    : 'qualified purchasers'}
                  .
                </small>
                <Controller
                  as={
                    <FileDropzone
                      acceptedTypes={ACCEPTED_DOC_MIME_TYPES}
                      aria-describedby="error-companyOwnersAccreditationProof"
                      aria-invalid={
                        errors.companyOwnersAccreditationProof
                          ? 'true'
                          : 'false'
                      }
                    />
                  }
                  // I would think typically you don't return values
                  // from an onChange handler, but it works.
                  // see: https://react-hook-form.com/api/#Controller
                  onChange={([event]) => event.target.files[0]}
                  name={Fields.companyOwnersAccreditationProof}
                  rules={validateConfig.companyOwnersAccreditationProof}
                />
                <InputError
                  error={getValidationError(
                    Fields.companyOwnersAccreditationProof,
                    errors
                  )}
                  id="error-companyOwnersAccreditationProof"
                />
              </>
            )}
          </form>
        </div>
      </FormContext>
    </>
  );
});
