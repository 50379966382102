export enum Web3State {
  Connected = 'Wallet connected',
  Error = 'Error connecting to wallet',
  NoWallet = 'No wallet found',
  Locked = 'Wallet disconnected',
}

/**
 * @see https://chainid.network/
 */
export enum NetworkIDs {
  MAINNET = 1,
  RINKEBY = 4,
  GOERLI = 5,
  POLYGON = 137,
  GANACHE = 1337,
}

/**
 * @see https://chainid.network/
 */
export enum NetworkNames {
  GANACHE = 'ganache',
  GOERLI = 'goerli',
  MAINNET = 'mainnet',
  POLYGON = 'polygon',
  RINKEBY = 'rinkeby',
}

export enum Vote {
  Abstained = '0',
  Yes = '1',
  No = '2',
}

export enum AccountType {
  INVESTOR = 'investor',
  PROJECT = 'project',
  ARCHIVED_INVESTOR = 'archivedInvestor',
}

export enum RoleType {
  ADMIN = 'admin',
  STANDARD = 'standard',
}

export enum Messages {
  AWAITING_EQUITY_TRANSFER = 'Awaiting equity tranfers',
  READY_FOR_SUBMISSION = 'The proposer has approved the transfer of equity funds, this proposal is ready for submission.',
}

export enum InvestmentProposalCurrency {
  ETH = 'ETH',
  USD = 'USD',
}

export enum ProposalStatuses {
  REQUEST = 'request',
  VOTING = 'voting',
  COMPLETED = 'completed',
  FUNDED = 'funded',
  PENDING = 'pending',
  READY_FOR_PROCESSING = 'ready for processing',
}

export enum ProposalHeaderNames {
  INVESTMENTS = 'Funded',
  VOTING = 'Voting',
  REQUESTS = 'Proposals',
  FAILED = 'Failed',
  GOVERNANCE_APPROVED = 'Approved',
  GOVERNANCE_REQUESTS = 'Proposed',
  SIDE_POCKETS = 'Side Pockets',
}

export enum ProposalStatusMessage {
  PENDING = 'Pending submission',
  REQUEST = 'Ready for sponsorship',
  AWAITING_TOKEN_WHITELISTING = 'Pending (token is being whitelisted)',
  APPROVED_EQUITY_TRANSFER = 'Ready for Member Voting',
}

export enum ProposalType {
  /**
   * A generic proposal for changing the org's operations.
   */
  GENERAL = 'general',
  /**
   * A proposal to kick a member (`flags[5]`).
   */
  GUILDKICK = 'guildkick',
  /**
   * A proposal requesting funds.
   */
  PAYMENT = 'payment',
  /**
   * A proposal requesting membership.
   */
  MEMBERSHIP = 'membership',
  /**
   * A proposal to whitelist a new ERC20 token address (`flags[4]`).
   */
  WHITELIST = 'whitelist',
  /**
   * A proposal generate from minion, where `details` prop starts with `Minion:`
   */
  MINION = 'minion',
}

export enum PaymentRequestType {
  PROJECT = 'Project',
  SIDEPOCKET = 'SidePocket',
}

export enum GovernanceProposalStatus {
  DID_NOT_PASS = 'didNotPass',
  PASSED = 'passed',
  PENDING = 'pending',
  REQUEST = 'request',
  VOTING = 'voting',
}

export enum GovernanceType {
  /**
   * A generic proposal for changing the org's operations.
   */
  GENERAL = 'general',
  /**
   * A proposal to kick a member (`flags[5]`).
   */
  GUILDKICK = 'guildkick',
  /**
   * A proposal to whitelist a new ERC20 token address (`flags[4]`).
   */
  WHITELIST = 'whitelist',
}

export enum MemberStatuses {
  PENDING = 'pending',
  APPROVED = 'approved',
  SHARES_APPROVED = 'shares-approved',
  REJECTED = 'rejected',
}

export enum FetchStatus {
  STANDBY = 'STANDBY',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export enum Web3TxStatus {
  STANDBY = 'STANDBY',
  AWAITING_CONFIRM = 'AWAITING_CONFIRM',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export enum SaleStatus {
  PENDING = 'pending', // sale has been submitted, but pending approval tx via MM
  PENDING_CANCELLATION = 'pending-cancellation', // sale has been cancelled, but pending confirmation via MM
  ACTIVE = 'active', // sale is active and will appear in the leaderboard
  PROCESSED = 'processed', // sale has been processed by org admin, and successful
  FAILED = 'failed', // sale has failed to processed
  CANCELLED = 'cancelled', // buyer has cancelled their sale
}

export enum ConnectorNames {
  injected = 'injected',
  walletconnect = 'walletconnect',
}

export enum CountriesEnum {
  'United States' = 'United States',
  'Afghanistan' = 'Afghanistan',
  'Albania' = 'Albania',
  'Algeria' = 'Algeria',
  'American Samoa' = 'American Samoa',
  'Andorra' = 'Andorra',
  'Angola' = 'Angola',
  'Anguilla' = 'Anguilla',
  'Antarctica' = 'Antarctica',
  'Antigua and Barbuda' = 'Antigua and Barbuda',
  'Argentina' = 'Argentina',
  'Armenia' = 'Armenia',
  'Aruba' = 'Aruba',
  'Australia' = 'Australia',
  'Austria' = 'Austria',
  'Azerbaijan' = 'Azerbaijan',
  'Bahamas' = 'Bahamas',
  'Bahrain' = 'Bahrain',
  'Bangladesh' = 'Bangladesh',
  'Barbados' = 'Barbados',
  'Belarus' = 'Belarus',
  'Belgium' = 'Belgium',
  'Belize' = 'Belize',
  'Benin' = 'Benin',
  'Bermuda' = 'Bermuda',
  'Bhutan' = 'Bhutan',
  'Bolivia, Plurinational State of' = 'Bolivia, Plurinational State of',
  'Bonaire, Sint Eustatius and Saba' = 'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina' = 'Bosnia and Herzegovina',
  'Botswana' = 'Botswana',
  'Bouvet Island' = 'Bouvet Island',
  'Brazil' = 'Brazil',
  'British Indian Ocean Territory' = 'British Indian Ocean Territory',
  'Brunei Darussalam' = 'Brunei Darussalam',
  'Bulgaria' = 'Bulgaria',
  'Burkina Faso' = 'Burkina Faso',
  'Burundi' = 'Burundi',
  'Cambodia' = 'Cambodia',
  'Cameroon' = 'Cameroon',
  'Canada' = 'Canada',
  'Cape Verde' = 'Cape Verde',
  'Cayman Islands' = 'Cayman Islands',
  'Central African Republic' = 'Central African Republic',
  'Chad' = 'Chad',
  'Chile' = 'Chile',
  'China' = 'China',
  'Christmas Island' = 'Christmas Island',
  'Cocos (Keeling) Islands' = 'Cocos (Keeling) Islands',
  'Colombia' = 'Colombia',
  'Comoros' = 'Comoros',
  'Congo' = 'Congo',
  'Congo, The Democratic Republic of the' = 'Congo, The Democratic Republic of the',
  'Cook Islands' = 'Cook Islands',
  'Costa Rica' = 'Costa Rica',
  'Croatia' = 'Croatia',
  'Cuba' = 'Cuba',
  'Curaçao' = 'Curaçao',
  'Cyprus' = 'Cyprus',
  'Czech Republic' = 'Czech Republic',
  "Côte d'Ivoire" = "Côte d'Ivoire",
  'Denmark' = 'Denmark',
  'Djibouti' = 'Djibouti',
  'Dominica' = 'Dominica',
  'Dominican Republic' = 'Dominican Republic',
  'Ecuador' = 'Ecuador',
  'Egypt' = 'Egypt',
  'El Salvador' = 'El Salvador',
  'Equatorial Guinea' = 'Equatorial Guinea',
  'Eritrea' = 'Eritrea',
  'Estonia' = 'Estonia',
  'Ethiopia' = 'Ethiopia',
  'Falkland Islands (Malvinas)' = 'Falkland Islands (Malvinas)',
  'Faroe Islands' = 'Faroe Islands',
  'Fiji' = 'Fiji',
  'Finland' = 'Finland',
  'France' = 'France',
  'French Guiana' = 'French Guiana',
  'French Polynesia' = 'French Polynesia',
  'French Southern Territories' = 'French Southern Territories',
  'Gabon' = 'Gabon',
  'Gambia' = 'Gambia',
  'Georgia' = 'Georgia',
  'Germany' = 'Germany',
  'Ghana' = 'Ghana',
  'Gibraltar' = 'Gibraltar',
  'Greece' = 'Greece',
  'Greenland' = 'Greenland',
  'Grenada' = 'Grenada',
  'Guadeloupe' = 'Guadeloupe',
  'Guam' = 'Guam',
  'Guatemala' = 'Guatemala',
  'Guernsey' = 'Guernsey',
  'Guinea' = 'Guinea',
  'Guinea-Bissau' = 'Guinea-Bissau',
  'Guyana' = 'Guyana',
  'Haiti' = 'Haiti',
  'Heard Island and McDonald Islands' = 'Heard Island and McDonald Islands',
  'Holy See (Vatican City State)' = 'Holy See (Vatican City State)',
  'Honduras' = 'Honduras',
  'Hong Kong' = 'Hong Kong',
  'Hungary' = 'Hungary',
  'Iceland' = 'Iceland',
  'India' = 'India',
  'Indonesia' = 'Indonesia',
  'Iran, Islamic Republic of' = 'Iran, Islamic Republic of',
  'Iraq' = 'Iraq',
  'Ireland' = 'Ireland',
  'Isle of Man' = 'Isle of Man',
  'Israel' = 'Israel',
  'Italy' = 'Italy',
  'Jamaica' = 'Jamaica',
  'Japan' = 'Japan',
  'Jersey' = 'Jersey',
  'Jordan' = 'Jordan',
  'Kazakhstan' = 'Kazakhstan',
  'Kenya' = 'Kenya',
  'Kiribati' = 'Kiribati',
  "Korea, Democratic People's Republic of" = "Korea, Democratic People's Republic of",
  'Korea, Republic of' = 'Korea, Republic of',
  'Kuwait' = 'Kuwait',
  'Kyrgyzstan' = 'Kyrgyzstan',
  "Lao People's Democratic Republic" = "Lao People's Democratic Republic",
  'Latvia' = 'Latvia',
  'Lebanon' = 'Lebanon',
  'Lesotho' = 'Lesotho',
  'Liberia' = 'Liberia',
  'Libya' = 'Libya',
  'Liechtenstein' = 'Liechtenstein',
  'Lithuania' = 'Lithuania',
  'Luxembourg' = 'Luxembourg',
  'Macao' = 'Macao',
  'Macedonia, Republic of' = 'Macedonia, Republic of',
  'Madagascar' = 'Madagascar',
  'Malawi' = 'Malawi',
  'Malaysia' = 'Malaysia',
  'Maldives' = 'Maldives',
  'Mali' = 'Mali',
  'Malta' = 'Malta',
  'Marshall Islands' = 'Marshall Islands',
  'Martinique' = 'Martinique',
  'Mauritania' = 'Mauritania',
  'Mauritius' = 'Mauritius',
  'Mayotte' = 'Mayotte',
  'Mexico' = 'Mexico',
  'Micronesia, Federated States of' = 'Micronesia, Federated States of',
  'Moldova, Republic of' = 'Moldova, Republic of',
  'Monaco' = 'Monaco',
  'Mongolia' = 'Mongolia',
  'Montenegro' = 'Montenegro',
  'Montserrat' = 'Montserrat',
  'Morocco' = 'Morocco',
  'Mozambique' = 'Mozambique',
  'Myanmar' = 'Myanmar',
  'Namibia' = 'Namibia',
  'Nauru' = 'Nauru',
  'Nepal' = 'Nepal',
  'Netherlands' = 'Netherlands',
  'New Caledonia' = 'New Caledonia',
  'New Zealand' = 'New Zealand',
  'Nicaragua' = 'Nicaragua',
  'Niger' = 'Niger',
  'Nigeria' = 'Nigeria',
  'Niue' = 'Niue',
  'Norfolk Island' = 'Norfolk Island',
  'Northern Mariana Islands' = 'Northern Mariana Islands',
  'Norway' = 'Norway',
  'Oman' = 'Oman',
  'Pakistan' = 'Pakistan',
  'Palau' = 'Palau',
  'Palestine, State of' = 'Palestine, State of',
  'Panama' = 'Panama',
  'Papua New Guinea' = 'Papua New Guinea',
  'Paraguay' = 'Paraguay',
  'Peru' = 'Peru',
  'Philippines' = 'Philippines',
  'Pitcairn' = 'Pitcairn',
  'Poland' = 'Poland',
  'Portugal' = 'Portugal',
  'Puerto Rico' = 'Puerto Rico',
  'Qatar' = 'Qatar',
  'Romania' = 'Romania',
  'Russian Federation' = 'Russian Federation',
  'Rwanda' = 'Rwanda',
  'Réunion' = 'Réunion',
  'Saint Barthélemy' = 'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha' = 'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis' = 'Saint Kitts and Nevis',
  'Saint Lucia' = 'Saint Lucia',
  'Saint Martin (French part)' = 'Saint Martin (French part)',
  'Saint Pierre and Miquelon' = 'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines' = 'Saint Vincent and the Grenadines',
  'Samoa' = 'Samoa',
  'San Marino' = 'San Marino',
  'Sao Tome and Principe' = 'Sao Tome and Principe',
  'Saudi Arabia' = 'Saudi Arabia',
  'Senegal' = 'Senegal',
  'Serbia' = 'Serbia',
  'Seychelles' = 'Seychelles',
  'Sierra Leone' = 'Sierra Leone',
  'Singapore' = 'Singapore',
  'Sint Maarten (Dutch part)' = 'Sint Maarten (Dutch part)',
  'Slovakia' = 'Slovakia',
  'Slovenia' = 'Slovenia',
  'Solomon Islands' = 'Solomon Islands',
  'Somalia' = 'Somalia',
  'South Africa' = 'South Africa',
  'South Georgia and the South Sandwich Islands' = 'South Georgia and the South Sandwich Islands',
  'South Sudan' = 'South Sudan',
  'Spain' = 'Spain',
  'Sri Lanka' = 'Sri Lanka',
  'Sudan' = 'Sudan',
  'Suriname' = 'Suriname',
  'Svalbard and Jan Mayen' = 'Svalbard and Jan Mayen',
  'Swaziland' = 'Swaziland',
  'Sweden' = 'Sweden',
  'Switzerland' = 'Switzerland',
  'Syrian Arab Republic' = 'Syrian Arab Republic',
  'Taiwan' = 'Taiwan',
  'Tajikistan' = 'Tajikistan',
  'Tanzania, United Republic of' = 'Tanzania, United Republic of',
  'Thailand' = 'Thailand',
  'Timor-Leste' = 'Timor-Leste',
  'Togo' = 'Togo',
  'Tokelau' = 'Tokelau',
  'Tonga' = 'Tonga',
  'Trinidad and Tobago' = 'Trinidad and Tobago',
  'Tunisia' = 'Tunisia',
  'Turkey' = 'Turkey',
  'Turkmenistan' = 'Turkmenistan',
  'Turks and Caicos Islands' = 'Turks and Caicos Islands',
  'Tuvalu' = 'Tuvalu',
  'Uganda' = 'Uganda',
  'Ukraine' = 'Ukraine',
  'United Arab Emirates' = 'United Arab Emirates',
  'United Kingdom' = 'United Kingdom',
  'United States Minor Outlying Islands' = 'United States Minor Outlying Islands',
  'Uruguay' = 'Uruguay',
  'Uzbekistan' = 'Uzbekistan',
  'Vanuatu' = 'Vanuatu',
  'Venezuela, Bolivarian Republic of' = 'Venezuela, Bolivarian Republic of',
  'Viet Nam' = 'Viet Nam',
  'Virgin Islands, British' = 'Virgin Islands, British',
  'Virgin Islands, U.S.' = 'Virgin Islands, U.S.',
  'Wallis and Futuna' = 'Wallis and Futuna',
  'Western Sahara' = 'Western Sahara',
  'Yemen' = 'Yemen',
  'Zambia' = 'Zambia',
  'Zimbabwe' = 'Zimbabwe',
  'Åland Islands' = 'Åland Islands',
}

export enum UnitedStatesEnum {
  'Alabama' = 'Alabama',
  'Alaska' = 'Alaska',
  'Arizona' = 'Arizona',
  'Arkansas' = 'Arkansas',
  'California' = 'California',
  'Colorado' = 'Colorado',
  'Connecticut' = 'Connecticut',
  'Delaware' = 'Delaware',
  'District of Columbia' = 'District of Columbia',
  'Florida' = 'Florida',
  'Georgia' = 'Georgia',
  'Hawaii' = 'Hawaii',
  'Idaho' = 'Idaho',
  'Illinois' = 'Illinois',
  'Indiana' = 'Indiana',
  'Iowa' = 'Iowa',
  'Kansas' = 'Kansas',
  'Kentucky' = 'Kentucky',
  'Louisiana' = 'Louisiana',
  'Maine' = 'Maine',
  'Maryland' = 'Maryland',
  'Massachusetts' = 'Massachusetts',
  'Michigan' = 'Michigan',
  'Minnesota' = 'Minnesota',
  'Mississippi' = 'Mississippi',
  'Missouri' = 'Missouri',
  'Montana' = 'Montana',
  'Nebraska' = 'Nebraska',
  'Nevada' = 'Nevada',
  'New Hampshire' = 'New Hampshire',
  'New Jersey' = 'New Jersey',
  'New Mexico' = 'New Mexico',
  'New York' = 'New York',
  'North Carolina' = 'North Carolina',
  'North Dakota' = 'North Dakota',
  'Ohio' = 'Ohio',
  'Oklahoma' = 'Oklahoma',
  'Oregon' = 'Oregon',
  'Pennsylvania' = 'Pennsylvania',
  'Rhode Island' = 'Rhode Island',
  'South Carolina' = 'South Carolina',
  'South Dakota' = 'South Dakota',
  'Tennessee' = 'Tennessee',
  'Texas' = 'Texas',
  'Utah' = 'Utah',
  'Vermont' = 'Vermont',
  'Virginia' = 'Virginia',
  'Washington' = 'Washington',
  'West Virginia' = 'West Virginia',
  'Wisconsin' = 'Wisconsin',
  'Wyoming' = 'Wyoming',
}
