import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';

import {CenterLogo} from '../../components/logo';
import {FetchStatus} from '../../util/enums';
import {Sale, StoreState} from '../../util/types';
import {useKYCCheck} from '../../hooks';
import {useSales} from '../../hooks';
import FadeIn from '../../components/common/FadeIn';
import Loader from '../../components/feedback/Loader';
import SaleBalance from './SaleBalance';
import SaleList from './SaleList';
import SaleModal from './SaleModal';
import SaleUnicornChart from './SaleUnicornChart';
import useKYCContributionWhitelist from '../../hooks/useKYCContributionWhitelist';
import Wrap from '../../components/layout/Wrap';

import b from '../../assets/scss/modules/buttons.module.scss';
import s from '../../assets/scss/modules/sale.module.scss';

type State = {
  showSaleModal: boolean;
  showCancelSaleModal: boolean;
  userSaleToCancel?: Sale;
};

const INITIAL_STATE = {
  showSaleModal: false,
  showCancelSaleModal: false,
  userSaleToCancel: undefined,
};

/**
 * SalePageContainer
 *
 * Manages the display of sale components.
 */
export default function SalePageContainer() {
  /**
   * Selectors
   */

  const walletAuthenticated = useSelector(
    (state: StoreState) => state.blockchain.walletAuthenticated
  );

  /**
   * State
   */

  const [state, setState] = useState<State>(INITIAL_STATE);
  const {showSaleModal} = state;

  /**
   * Our hooks
   */

  const {sales, salesStatus} = useSales();
  const {userKYCInfo, userKYCInfoStatus} = useKYCCheck();
  const {kycIsContributionAllowed} = useKYCContributionWhitelist();

  /**
   * Their hooks
   */

  const history = useHistory();

  /**
   * Functions
   */

  function handleSaleModalClose() {
    setState({...state, showSaleModal: INITIAL_STATE.showCancelSaleModal});
  }

  function handleAccrediatedRoute() {
    history.push('/verify-start');
  }

  return (
    <Wrap className="section-wrapper">
      <CenterLogo />

      <SaleBalance sales={sales} />

      {/* USER NOT FOUND */}
      {(!userKYCInfo || !Object.keys(userKYCInfo).length) && (
        <FadeIn>
          <button
            className={`${s.button} ${b.primary}`}
            onClick={handleAccrediatedRoute}
            // keep height, but hide, so page does not jump
            style={
              userKYCInfoStatus === FetchStatus.PENDING
                ? {visibility: 'hidden'}
                : {}
            }>
            START
            {!walletAuthenticated && ' OR LOGIN'}
          </button>
        </FadeIn>
      )}
      {/* USER FOUND, VERIFICATION PENDING */}
      {userKYCInfo && userKYCInfo.uuid && !userKYCInfo.isVerified && (
        <FadeIn>
          <p className={`text-center color-yellow`}>
            We&rsquo;re still verifying your information
          </p>
        </FadeIn>
      )}
      {/* USER FOUND, ENABLE SHARE PURCHASING */}
      {userKYCInfo &&
        userKYCInfo.uuid &&
        userKYCInfo.isVerified &&
        kycIsContributionAllowed(userKYCInfo.ethereumAddress) && (
          <FadeIn>
            <button
              className={`${s.button} ${b.primary}`}
              onClick={() =>
                setState({...state, showSaleModal: !showSaleModal})
              }>
              Start
            </button>
          </FadeIn>
        )}

      <SaleUnicornChart />

      {/* SALE MODAL */}
      {userKYCInfo && userKYCInfo.uuid && userKYCInfo.isVerified && (
        <>
          {showSaleModal && (
            <SaleModal
              onRequestClose={handleSaleModalClose}
              sales={sales}
              show={showSaleModal}
              userInfo={userKYCInfo}
            />
          )}
        </>
      )}

      {/* SALES LIST */}
      <div className={`${s.wrap} ${s.gradient}`}>
        <div className={`${s.sales} card`}>
          <div className="titlebar">
            <h2 className="titlebar__title org-titlebar__title">
              Contributors
            </h2>
          </div>

          <div
            className={
              sales.length > 0
                ? s['sale-container']
                : s['sale-container--empty']
            }>
            {salesStatus === 'FULFILLED' && sales.length > 0 ? (
              <SaleList sales={sales} />
            ) : /* LOADING POSSIBLE SALES */
            salesStatus === 'PENDING' ? (
              <Loader showAfterMs={200} />
            ) : /* NO SALES (AFTER FETCH) */
            salesStatus === 'FULFILLED' && sales.length === 0 ? (
              <span className={`${s['no-sales-text']} color-yellow`}>
                No contributions yet&mdash; be the first!
              </span>
            ) : (
              /* OOPS - ERROR FETCHING */
              salesStatus === 'REJECTED' && (
                <span
                  className={`${s['no-sales-text']} error-message org-error-message`}>
                  Oops! Something went wrong while getting the membership list.
                </span>
              )
            )}
          </div>
        </div>
      </div>
    </Wrap>
  );
}
