import {useCallback, useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {useSelector} from 'react-redux';

import {
  GetInvestmentProposalResponseMoloch,
  GetInvestmentProposalResponseSnapshot,
  MolochProposalEntry,
  SnapshotProposalEntry,
  StoreState,
} from '../util/types';
import {FetchStatus} from '../util/enums';
import {GET_VOTING_PROPOSALS, GET_VOTING_DONE_PROPOSALS} from '../gql';
import {GQL_QUERY_POLLING_INTERVAL} from '../util/config';
import {SnapshotProposalType} from '../components/snapshot/enums';
import {useCanViewInvestmentProposal} from './useCanViewInvestmentProposal';
import useInvestmentProposals from './useInvestmentProposals';
import useSnapshotProposalsParsed from '../components/snapshot/useSnaphotProposalsParsed';
import {usePageVisibility} from './';

/**
 * useInvestmentProposalsParsedSponsored
 *
 * @returns {}
 */
export default function useInvestmentProposalsParsedSponsored() {
  /**
   * Selectors
   */

  const molochConstants = useSelector(
    (s: StoreState) => s.blockchain.molochConstants
  );

  // Set Moloch period for gql query filtering
  const {summoningTime = 0, periodDuration = 0} = molochConstants || {};
  const currentMolochPeriod =
    Math.floor(
      (Math.floor(Date.now() / 1000) - Number(summoningTime)) /
        Number(periodDuration)
    ) || 0;

  /**
   * State
   */

  const [molochInvestmentProposals, setMolochInvestmentProposals] = useState<
    GetInvestmentProposalResponseMoloch[]
  >([]);

  const [snapshotInvestmentProposals, setSnapshotInvestmentProposals] =
    useState<GetInvestmentProposalResponseSnapshot[]>([]);

  const [molochProposalEntriesVoting, setMolochProposalEntriesVoting] =
    useState<MolochProposalEntry[]>([]);
  const [molochProposalEntriesPassed, setMolochProposalEntriesPassed] =
    useState<MolochProposalEntry[]>([]);
  const [molochProposalEntriesFailed, setMolochProposalEntriesFailed] =
    useState<MolochProposalEntry[]>([]);

  const [snapshotProposalEntriesVoting, setSnapshotProposalEntriesVoting] =
    useState<SnapshotProposalEntry[]>([]);
  const [snapshotProposalEntriesPassed, setSnapshotProposalEntriesPassed] =
    useState<SnapshotProposalEntry[]>([]);
  const [snapshotProposalEntriesFailed, setSnapshotProposalEntriesFailed] =
    useState<SnapshotProposalEntry[]>([]);

  /**
   * Our hooks
   */

  const {
    investmentProposals,
    investmentProposalsError,
    investmentProposalsStatus,
  } = useInvestmentProposals({sponsored: true});

  const {
    snapshotProposalsFailedAll,
    snapshotProposalsPassedAll,
    snapshotProposalsVotingAll,
    updateSnapshotProposals,
  } = useSnapshotProposalsParsed({
    proposalsAndVotes: snapshotInvestmentProposals,
    type: SnapshotProposalType.Investment,
  });

  const {canViewInvestmentProposal} = useCanViewInvestmentProposal();
  const isPageVisible = usePageVisibility();

  /**
   * Their hooks
   */

  // GQL querys

  const molochVotingProposalsQuery = useQuery(GET_VOTING_PROPOSALS, {
    variables: {currentPeriod: currentMolochPeriod},
    pollInterval: GQL_QUERY_POLLING_INTERVAL,
  });
  const molochVotingDoneProposalsQuery = useQuery(GET_VOTING_DONE_PROPOSALS, {
    variables: {currentPeriod: currentMolochPeriod},
    pollInterval: GQL_QUERY_POLLING_INTERVAL,
  });

  /**
   * Cached callbacks
   */

  const setProposalsByTargetCached = useCallback(setProposalsByTarget, [
    investmentProposals,
  ]);

  /**
   * Variables
   */

  const proposalEntriesFailed = [
    ...molochProposalEntriesFailed,
    ...snapshotProposalEntriesFailed,
  ].sort(
    // sort DESC
    (a, b) => b[1] - a[1]
  );

  const proposalEntriesPassed = [
    ...molochProposalEntriesPassed,
    ...snapshotProposalEntriesPassed,
  ].sort(
    // sort DESC
    (a, b) => b[1] - a[1]
  );

  const proposalEntriesVoting = [
    ...molochProposalEntriesVoting,
    ...snapshotProposalEntriesVoting,
  ].sort(
    // sort ASC
    (a, b) => a[1] - b[1]
  );

  const areInvestmentProposalsLoading =
    investmentProposalsStatus === FetchStatus.STANDBY ||
    investmentProposalsStatus === FetchStatus.PENDING ||
    molochVotingProposalsQuery.loading ||
    molochVotingDoneProposalsQuery.loading;

  /**
   * Effects
   */

  useEffect(() => {
    if (isPageVisible) {
      molochVotingProposalsQuery.startPolling(GQL_QUERY_POLLING_INTERVAL);
      molochVotingDoneProposalsQuery.startPolling(GQL_QUERY_POLLING_INTERVAL);
    } else {
      molochVotingProposalsQuery.stopPolling();
      molochVotingDoneProposalsQuery.stopPolling();
    }
  }, [
    isPageVisible,
    molochVotingProposalsQuery,
    molochVotingDoneProposalsQuery,
  ]);

  useEffect(() => {
    setProposalsByTargetCached();
  }, [setProposalsByTargetCached]);

  // Set Moloch entries for rendering
  useEffect(() => {
    setMolochProposalEntriesVoting(
      getMolochEntries(
        molochVotingProposalsQuery,
        molochInvestmentProposals,
        getMolochSortValue
      )
    );

    setMolochProposalEntriesPassed(
      getMolochEntries(
        molochVotingDoneProposalsQuery,
        molochInvestmentProposals,
        getMolochSortValue
      ).filter(filterPassedMolochProposal)
    );

    setMolochProposalEntriesFailed(
      getMolochEntries(
        molochVotingDoneProposalsQuery,
        molochInvestmentProposals,
        getMolochSortValue
      ).filter(filterFailedMolochProposal)
    );
  }, [
    molochInvestmentProposals,
    molochVotingDoneProposalsQuery,
    molochVotingProposalsQuery,
  ]);

  // Set Snapshot entries for rendering
  useEffect(() => {
    /**
     * @note We need to cast types for return values of `useSnapshotProposalsParsed`
     *   until we are fully migrated away from the old proposals databases, etc.
     */
    setSnapshotProposalEntriesVoting(
      getSnapshotEntries(
        snapshotProposalsVotingAll as GetInvestmentProposalResponseSnapshot[],
        getSnapshotSortValue
      )
    );

    setSnapshotProposalEntriesPassed(
      getSnapshotEntries(
        snapshotProposalsPassedAll as GetInvestmentProposalResponseSnapshot[],
        getSnapshotSortValue
      )
    );

    setSnapshotProposalEntriesFailed(
      getSnapshotEntries(
        snapshotProposalsFailedAll as GetInvestmentProposalResponseSnapshot[],
        getSnapshotSortValue
      )
    );
  }, [
    snapshotProposalsFailedAll,
    snapshotProposalsPassedAll,
    snapshotProposalsVotingAll,
  ]);

  /**
   * Functions
   */

  /**
   * getMolochEntries
   *
   * Gets array of tuples for combining with Snapshot proposals later.
   *
   * @param {Record<string, any>[]} proposalsGQL
   * @param {Proposal[]} proposalsDB
   * @returns {MolochProposalEntry[]}
   */
  function getMolochEntries(
    /* default {} */
    proposalsGQL: Record<string, any> = {},
    /* default [] */
    proposalsDB: GetInvestmentProposalResponseMoloch[] = [],
    getSortValue: (mp: Record<string, any>) => number
  ): MolochProposalEntry[] {
    const {minion = [], project = []} = proposalsGQL.data || {};

    const proposalEntries = [...minion, ...project]
      .map((mp: Record<string, any>): MolochProposalEntry | undefined => {
        // Find the corresponding proposal in the database
        const proposalDBMatch = proposalsDB.find(
          (mpDB) => Number(mp.proposalId) === Number(mpDB.molochProposalId)
        );

        // If we could not match a DB proposal to a Moloch proposal, then return `undefined`.
        if (!proposalDBMatch) return undefined;

        return [
          'moloch',
          // To sort by later
          getSortValue(mp),
          {...mp, ...proposalDBMatch},
        ];
      })
      // Filter out if no data.
      .filter((pe) => pe);

    return proposalEntries as MolochProposalEntry[];
  }

  /**
   * getMolochSortValue
   *
   * Get sort value: timestamp the Moloch was sponsored in milliseconds.
   *
   * @param {Record<string, any>} molochGQLProposal
   * @returns {number}
   */
  function getMolochSortValue(molochGQLProposal: Record<string, any>): number {
    return Number(molochGQLProposal.sponsoredAt * 1000);
  }

  /**
   * getSnapshotEntries
   *
   * Gets array of tuples for combining with Moloch proposals later.
   *
   * @param {GetInvestmentProposalResponseSnapshot[]} snapshotProposals
   * @returns {SnapshotProposalEntry[]}
   */
  function getSnapshotEntries(
    /* default [] */
    snapshotProposals: GetInvestmentProposalResponseSnapshot[] = [],
    getSortValue: (sp: GetInvestmentProposalResponseSnapshot) => number
  ): SnapshotProposalEntry[] {
    return snapshotProposals.map(
      (sp): SnapshotProposalEntry => [
        'snapshot',
        // To sort by later
        getSortValue(sp),
        sp,
      ]
    );
  }

  /**
   * getSnapshotSortValue
   *
   * Get sort value: voting end date in milliseconds.
   *
   * @param {SnapshotInvestmentProposalResponse} snapshotProposal
   * @return {number}
   */
  function getSnapshotSortValue(
    snapshotProposal: GetInvestmentProposalResponseSnapshot
  ): number {
    return snapshotProposal.snapshotHubProposal
      ? new Date(
          snapshotProposal.snapshotHubProposal.msg.payload.end * 1000
        ).getTime()
      : 0;
  }

  /**
   * filterInvestmentProposalsByViewable
   *
   * Provided to the consumer to filter any proposal entries by authorisation
   * to view them based on common and specific criteria (based on the proposal's target).
   *
   * @param {(SnapshotProposalEntry | MolochProposalEntry)[]} proposalEntries
   * @returns {(SnapshotProposalEntry | MolochProposalEntry)[]}
   * @public
   */
  function filterInvestmentProposalsByViewable(
    proposalEntries: (SnapshotProposalEntry | MolochProposalEntry)[]
  ): (SnapshotProposalEntry | MolochProposalEntry)[] {
    return proposalEntries.filter((p) => canViewInvestmentProposal(p[2]));
  }

  function filterPassedMolochProposal(molochProposal: MolochProposalEntry) {
    const proposal = molochProposal[2];

    return Number(proposal.yesShares) > Number(proposal.noShares);
  }

  function filterFailedMolochProposal(molochProposal: MolochProposalEntry) {
    const proposal = molochProposal[2];

    return Number(proposal.yesShares) <= Number(proposal.noShares);
  }

  function setProposalsByTarget() {
    // Filter proposals by Moloch target
    setMolochInvestmentProposals(
      investmentProposals.filter(
        (p) => p.proposalTarget === 'moloch'
      ) as GetInvestmentProposalResponseMoloch[]
    );

    // Filter proposals by Snapshot target
    setSnapshotInvestmentProposals(
      investmentProposals.filter(
        (p) => p.proposalTarget === 'snapshot'
      ) as GetInvestmentProposalResponseSnapshot[]
    );
  }

  return {
    areInvestmentProposalsLoading,
    filterInvestmentProposalsByViewable,
    investmentProposalsError,
    investmentProposalsStatus,
    proposalEntriesFailed,
    proposalEntriesPassed,
    proposalEntriesVoting,
    updateSnapshotProposals,
  };
}
