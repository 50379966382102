import {Route} from 'react-router-dom';
import {
  Redirect,
  Switch,
  RouteComponentProps,
  useLocation,
} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';

import {
  US_AND_TERRITORIES_COUNTRY_CODES,
  siteMetadata,
  orgFaviconSet,
  getOrgText,
} from './util/helpers';
import {StoreState} from './util/types';
import {InternalNamesToMap} from './util/orgDomainMappings';
import {
  CreateInvestmentProposalLAO,
  CreateInvestmentProposalLiquidity,
  CreateInvestmentProposalNFT,
} from './pages/proposals/forms';
import {GeoResponse} from './hooks/useGeo';
import {isTributeOrgName} from './util/helpers';
import {useGeo} from './hooks';
import Admin from './pages/admin';
import AuthServerModal from './components/common/AuthServerModal';
import CreateGovernanceProposal from './pages/governance/snapshot/CreateGovernanceProposal';
import Footer from './components/footer';
import GetStarted from './pages/start/GetStarted';
import GetStartedLiquidity from './pages/start/GetStartedLiquidity';
import GetStartedNFT from './pages/start/GetStartedNFT';
import GovernanceProposal from './pages/governance/snapshot/GovernanceProposal';
import GovernanceProposals from './pages/governance/snapshot/GovernanceProposals';
import Header from './components/header';
import InvestmentProposalContainer from './pages/proposals/InvestmentProposalContainer';
import InvestmentProposals from './pages/proposals/InvestmentProposals';
import MemberDetails from './pages/members/MemberDetails';
import Members from './pages/members/Members';
import Mentors from './pages/mentors/Mentors';
import MentorsLiquidity from './pages/mentors/MentorsLiquidity';
import MentorsNFT from './pages/mentors/MentorsNFT';
import NotFound from './pages/subpages/NotFound';
import Privacy from './pages/subpages/Privacy';
import SalePageContainer from './pages/sale/SalePageContainer';
import SidePocketDetails from './pages/governance/sidepocket/SidePocketDetails';
import VerificationThankYou from './pages/members/verification/VerificationThankYou';
import VerifyStart from './pages/members/verification/VerifyStart';
import VerifyStartNonUS from './pages/members/verification/VerifyStartNonUS';
import WithdrawBalances from './pages/withdraw/WithdrawBalances';
import InvestmentProposalsSponsored from './pages/proposals/InvestmentProposalsSponsored';

const isLive = process.env.REACT_APP_LAUNCH_LIVE === 'on';

export default function App() {
  const org = useSelector((s: StoreState) => s.org);
  const orgFeatures = useSelector((s: StoreState) => s.org && s.org.features);
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const showApplyLinkFeatureFlag = orgFeatures && orgFeatures.showApplyLink;
  const showFooterFeatureFlag = orgFeatures && orgFeatures.showFooter;
  const showMentors = orgFeatures && orgFeatures.showMentors;
  const location = useLocation();
  const getText = getOrgText(orgText);
  const orgMentorsText = getText('OrgMentorsText');
  const orgInvestmentsText = getText('OrgInvestmentsText');

  /**
   * Get geo data
   */
  const geo = useGeo();
  const isInUS = US_AND_TERRITORIES_COUNTRY_CODES.includes(
    (geo && geo.country_code) || ''
  );

  /**
   * splashPage - renders the org splash page, defaults to "The LAO"
   * @param org
   */
  function splashPage(org: string | null) {
    if (!org) return;

    switch (org) {
      case InternalNamesToMap.nft:
        return <GetStartedNFT />;
      case InternalNamesToMap.liquidity:
        return <GetStartedLiquidity />;
      case InternalNamesToMap.thelao:
      default:
        return <GetStarted />;
    }
  }

  /**
   * applyPage - renders the org apply page, defaults to "The LAO"
   * @param org
   */
  function applyPage(org: string | null, location: Record<string, any>) {
    if (!org) return;

    switch (org) {
      case InternalNamesToMap.nft:
        return <CreateInvestmentProposalNFT />;
      case InternalNamesToMap.liquidity:
        return <CreateInvestmentProposalLiquidity />;
      case InternalNamesToMap.thelao:
      default:
        return <CreateInvestmentProposalLAO {...location} />;
    }
  }

  /**
   * mentorPage - renders the org mentor page, defaults to "The LAO"
   * @param org
   */
  function mentorPage(org: string | null) {
    if (!org) return;

    switch (org) {
      case InternalNamesToMap.nft:
        return <MentorsNFT />;
      case InternalNamesToMap.liquidity:
        return <MentorsLiquidity />;
      case InternalNamesToMap.thelao:
      default:
        return <Mentors />;
    }
  }

  /**
   * salePage - renders the org sale page (or redirect), defaults to "The LAO"
   * @param org
   */
  function salePage(
    org: string | null,
    geo: GeoResponse,
    routeProps: RouteComponentProps<any, any>
  ) {
    if (!org) return;

    switch (org) {
      case InternalNamesToMap.nft:
      case InternalNamesToMap.liquidity:
        return <Redirect to="/" />;
      case InternalNamesToMap.thelao:
      default:
        return <SalePageContainer />;
    }
  }

  function proposalsPage(org: string | null) {
    if (!org) return;

    return <InvestmentProposals />;
  }

  function setLiquidityAnimation() {
    if (org?.internalName === InternalNamesToMap.liquidity) {
      const body = document.getElementById('body');
      const root = document.getElementById('root');

      if (location.pathname === '/') {
        body?.classList.add('animate');
        root?.classList.add('animate');
      } else {
        body?.classList.remove('animate');
        root?.classList.remove('animate');
      }
    }
  }

  return (
    <>
      {/** REACT HELMET */}
      {org && (
        <Helmet>
          <title>{siteMetadata(location.pathname, org).title}</title>
          <meta
            name="description"
            content={siteMetadata(location.pathname, org).description}
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={orgFaviconSet(org).appleTouchIconLink}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={orgFaviconSet(org).icon32x32Link}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={orgFaviconSet(org).icon16x16Link}
          />
          <link rel="manifest" href={orgFaviconSet(org).siteWebManifest} />
          <link
            rel="mask-icon"
            href={orgFaviconSet(org).safariPinnedTab}
            color="#5bbad5"
          />
        </Helmet>
      )}

      {/* sets or removes animation for `liquidity` org depending on page */}
      {setLiquidityAnimation()}

      {/** APP HEADER */}
      {isLive && <Header />}

      {/**
       * Maybe show the sign in (server auth) modal if triggered.
       * Possibly set Redux `authServer.accessToken` if localStorage accessToken exists.
       */}
      <AuthServerModal />

      <main>
        {isTributeOrgName(orgInternalName as InternalNamesToMap) ? (
          <Switch>
            {[
              // Admin dashboard is only page for Tribute framework DAOs
              <Route
                key="admin"
                exact
                path="/"
                render={({location}) => <Admin {...location} />}
              />,
              // Legacy `/admin` route redirects to index for backwards
              // compatibility
              <Route
                key="admin"
                exact
                path="/admin"
                render={() => <Redirect to="/" />}
              />,
              <Route key="notfound" path="/404" render={() => <NotFound />} />,
              <Route
                key="redirecttonotfound"
                render={() => <Redirect to="/404" />}
              />,
            ]}
          </Switch>
        ) : (
          <Switch>
            {[
              <Route
                key="admin"
                exact
                path="/admin"
                render={({location}) => <Admin {...location} />}
              />,
              isLive && (
                <Route
                  key="governance-proposal"
                  path="/governance-proposal"
                  render={() => <CreateGovernanceProposal />}
                />
              ),
              isLive && (
                // This route needs to come before `/governance-proposals`
                <Route
                  key="governance-proposal"
                  path="/governance-proposals/:id"
                  render={() => <GovernanceProposal />}
                />
              ),
              isLive && (
                <Route
                  key="governance-proposals"
                  path="/governance-proposals"
                  render={() => <GovernanceProposals />}
                />
              ),
              <Route
                key="splash"
                exact
                path="/"
                render={() => org && splashPage(org.internalName)}
              />,
              isLive && (
                <Route
                  exact
                  key="members"
                  path="/members"
                  render={({location}) => <Members {...location} />}
                />
              ),
              isLive && (
                <Route
                  exact
                  key="member-details"
                  path="/members/:ethereumAddress"
                  render={({location}) => <MemberDetails {...location} />}
                />
              ),
              isLive && (
                <Route
                  exact
                  key="proposals"
                  path="/proposals"
                  render={() => org && proposalsPage(org.internalName)}
                />
              ),
              isLive && (
                <Route
                  exact
                  key="proposal-details"
                  path="/proposals/:uuid"
                  render={() => <InvestmentProposalContainer />}
                />
              ),
              isLive && (
                <Route
                  exact
                  key="investments"
                  path={`/${orgInvestmentsText.toLowerCase()}`}
                  render={() => <InvestmentProposalsSponsored />}
                />
              ),
              isLive && (
                <Route
                  key="guildbank-proposals"
                  path="/guildbank-proposals/:id"
                  render={() => <SidePocketDetails />}
                />
              ),
              isLive && showApplyLinkFeatureFlag && (
                <Route
                  key="apply"
                  path="/apply"
                  render={({location}) =>
                    org && applyPage(org.internalName, location)
                  }
                />
              ),
              <Route
                key="member-verification-start"
                exact
                path="/verify-start"
                render={() => (
                  <Redirect to={isInUS ? '/verify' : '/verify/non-us'} />
                )}
              />,
              <Route
                key="member-verification"
                exact
                path="/verify"
                render={() => <VerifyStart geo={geo} />}
              />,
              <Route
                key="member-verification-non-us"
                exact
                path="/verify/non-us"
                render={() => <VerifyStartNonUS geo={geo} />}
              />,
              <Route
                key="member-verification-thanks"
                exact
                path="/verify-thanks"
                render={() => <VerificationThankYou />}
              />,
              isLive && (
                <Route
                  key="contribute"
                  path="/contribute"
                  render={(routeProps) =>
                    org && salePage(org.internalName, geo, routeProps)
                  }
                />
              ),
              isLive && (
                <Route
                  key="privacy"
                  path="/privacy"
                  render={() => <Privacy />}
                />
              ),
              isLive && showMentors && (
                <Route
                  key="mentors"
                  path={`/${orgMentorsText.toLowerCase()}`}
                  render={() => org && mentorPage(org.internalName)}
                />
              ),
              isLive && (
                <Route key="notfound" path="/404" render={() => <NotFound />} />
              ),
              isLive && (
                <Route
                  key="withdraw"
                  path="/withdraw"
                  render={() => <WithdrawBalances />}
                />
              ),
              <Route
                key="redirecttonotfound"
                render={() => <Redirect to="/404" />}
              />,
            ]}
          </Switch>
        )}
      </main>

      {isLive && showFooterFeatureFlag && <Footer />}
    </>
  );
}
