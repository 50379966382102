import React, {useState, useEffect} from 'react';
import debounce from 'debounce';

import {BACKEND_URL} from '../../util/config';
import {useFormContext} from 'react-hook-form';
import AutocompleteSelect, {DEFAULT_KEY} from './AutocompleteSelect';

type AddressProps = {
  onAddressError?: (message: string) => void;
  // @todo refactor all usages to onAddressSelect
  onChange: (value: string, addressData?: OpenLawAddress) => void;
};

export type OpenLawAddress = {
  placeId: string;
  streetName: string;
  streetNumber: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  formattedAddress: string;
};

type OpenLawAddressSearchItem = {
  address: string;
  placeId: string;
};

type AddressSuggestions = Array<OpenLawAddressSearchItem>;

const __AUTOCOMPLETESELECT_USER_CUSTOM__: string =
  '__AUTOCOMPLETESELECT_USER_CUSTOM__';

export default function Address(
  props: AddressProps & React.InputHTMLAttributes<HTMLInputElement>
) {
  const [addressSuggestions, setAddressSuggestions] =
    useState<AddressSuggestions>([]);
  const {register, unregister} = useFormContext() || {};
  const {onAddressError, onChange, ...restProps} = props;

  // react-hook-form possible registration of field
  useEffect(() => {
    restProps.name && register && register(restProps.name);

    return () => {
      restProps.name && unregister && unregister(restProps.name);
    };
  }, [register, unregister, restProps.name]);

  const handleAddressSearch = debounce(async (value: string) => {
    const valueTrimmed = value.trim();

    if (!valueTrimmed || valueTrimmed.length < 3) return;

    try {
      // set default address while searching
      setAddressSuggestions([
        {
          address: 'Searching\u2026',
          placeId: DEFAULT_KEY,
        },
      ]);

      const response = await fetch(`${BACKEND_URL}/address/search/${value}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status >= 400) {
        throw new Error('Something went wrong while searching for an address.');
      }

      const suggestions = await response.json();

      /**
       * Inform the user we could not find their address,
       * but they may use the one they typed.
       */
      if (!suggestions.length) {
        // set default address while searching
        setAddressSuggestions([
          {
            address:
              'Sorry, no results found, but you can select the address you typed.',
            placeId: DEFAULT_KEY,
          },
          {
            address: valueTrimmed,
            placeId: __AUTOCOMPLETESELECT_USER_CUSTOM__,
          },
        ]);
      }

      suggestions.length && setAddressSuggestions(suggestions);

      // Reset error
      onAddressError && onAddressError('');
    } catch (error) {
      /**
       * Clear suggestions and inform the user we could not search for their address,
       * but they may use the one they typed.
       */
      setAddressSuggestions([
        {
          address:
            "Sorry, we can't search right now 😞, but you can select the address you typed.",
          placeId: DEFAULT_KEY,
        },
        {
          address: valueTrimmed,
          placeId: __AUTOCOMPLETESELECT_USER_CUSTOM__,
        },
      ]);

      onAddressError &&
        onAddressError(
          error.message ||
            'Something went wrong while searching for an address.'
        );
    }
  }, 500);

  async function handleAddressSelect(addressItem?: OpenLawAddressSearchItem) {
    if (!addressItem) {
      onChange('');

      return;
    }

    /**
     * User could not find the address and they just want to use the address they typed.
     */
    if (addressItem.placeId === __AUTOCOMPLETESELECT_USER_CUSTOM__) {
      const createUserCustomAddress: OpenLawAddress = {
        placeId: '',
        streetName: '',
        streetNumber: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        formattedAddress: addressItem.address,
      };

      onChange(
        createUserCustomAddress.formattedAddress,
        createUserCustomAddress
      );

      // Reset error
      onAddressError && onAddressError('');

      return;
    }

    try {
      const response = await fetch(
        `${BACKEND_URL}/address/get/${addressItem.placeId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status >= 400) {
        throw new Error('Something went wrong while getting the address.');
      }

      const address: OpenLawAddress = await response.json();

      onChange(address.formattedAddress, address);

      // Reset error
      onAddressError && onAddressError('');
    } catch (error) {
      onAddressError &&
        onAddressError(
          error.message || 'Something went wrong while getting the address.'
        );
    }
  }

  return (
    <AutocompleteSelect
      itemReactKey={'placeId'}
      items={addressSuggestions || null}
      itemValueKey={'address'}
      onChange={handleAddressSearch}
      onSelect={handleAddressSelect}
      placeholder="Type to search for an address"
      userInputProps={restProps}
    />
  );
}
