import React from 'react';

import DefaultBlockieSVG from '../../assets/svg/DefaultBlockieSVG';
import {renderIcon} from '../../util/blockie';

/**
 * TODO:
 *  - Maybe just make a `seed` prop, since we're consistent
 *.   on each <Blockie /> with size & scale. No more need for cache.
 */

interface IProps {
  opts: {
    seed?: string;
  };
}

const createBlockieOptsCache = () => {
  const opts = {
    seed: '',
    size: 7,
    scale: 5,
  };

  return (seed: string) => {
    if (seed !== opts.seed) {
      opts.seed = seed;
    }

    return opts;
  };
};

const getBlockieProps = createBlockieOptsCache();

const createIcon = (opts: any) => {
  const canvas = document.createElement('canvas');
  renderIcon(opts, canvas);

  return canvas;
};

class Blockie extends React.Component<IProps> {
  static getBlockie(opts: any) {
    return createIcon(opts);
  }

  render() {
    const seed = this.props.opts.seed && this.props.opts.seed.toLowerCase();

    if (!seed) return <DefaultBlockieSVG />;

    let canvas = Blockie.getBlockie(getBlockieProps(seed));
    return (
      <img src={canvas.toDataURL()} alt={seed} style={{borderRadius: '10px'}} />
    );
  }
}

export default Blockie;
