import React from 'react';
import {useSelector} from 'react-redux';

import {getOrgText} from '../../util/helpers';
import {StoreState} from '../../util/types';
import Wrap from '../../components/layout/Wrap';
import FadeIn from '../../components/common/FadeIn';

import s from '../../assets/scss/modules/privacy.module.scss';

export default function Privacy() {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgPrivacyPolicyDateLastUpdated = getText(
    'OrgPrivacyPolicyDateLastUpdated'
  );
  const orgIncorporatedName = getText('OrgIncorporatedName');
  const orgPrivacyPolicySiteURL = getText('OrgPrivacyPolicySiteURL');
  const orgPrimaryContactEmail = getText('OrgPrimaryContactEmail');

  return (
    <>
      <Wrap className="section-wrapper org-privacy-policy">
        <FadeIn>
          <div className="titlebar">
            <h2 className="titlebar__title org-titlebar__title">
              Privacy Policy
            </h2>
          </div>

          <div className="card org-card" style={{padding: '2rem'}}>
            <p>Last Updated: {orgPrivacyPolicyDateLastUpdated}</p>

            <p>
              This privacy policy ("Policy") describes how the entity expected
              to be called {orgIncorporatedName} ("Company", "we", "our", or
              "us") collects, uses, shares, and stores personal information of
              users of this dapp,{' '}
              <a
                href={`https://${orgPrivacyPolicySiteURL}`}
                target="_blank"
                rel="noopener noreferrer">
                {orgPrivacyPolicySiteURL}
              </a>{' '}
              (the "Site"). This Policy applies to the Site, applications,
              products and services (collectively, "Services") on or in which it
              is posted, linked, or referenced.
            </p>

            <p>
              By using the Services, you accept the terms of this Policy and
              consent to our collection, use, disclosure, and retention of your
              information as described in this Policy.
            </p>

            <p>
              Please note that this Policy does not apply to information
              collected through third-party websites or services that you may
              access through the Services or that you submit to us through
              email, text message or other electronic message or offline.
            </p>

            <p>
              If you are visiting this site from the European Union (EU), see
              our Notice to EU Data Subjects below for our legal bases for
              processing and transfer of your data.
            </p>

            <div>
              <p className={s['section-header']}>WHAT WE COLLECT</p>

              <p>We collect various information about you.</p>

              <div>
                <p>
                  <i>Information You Give Us.</i> Information we collect from
                  you includes:
                </p>
                <ul>
                  <li>
                    Identity information, such as your first name, middle name,
                    last name, username or similar identifier, title, date of
                    birth, gender, social security number, and valid government
                    issued identification including passports and driver’s
                    licenses;
                  </li>
                  <li>
                    Contact information, such as your postal address, email
                    address and telephone number;
                  </li>
                  <li>
                    Profile information, such as your username and password,
                    interests, preferences, feedback and survey responses;
                  </li>
                  <li>
                    Wallet information, such as your cryptocurrency wallet
                    address;
                  </li>
                  <li>
                    Information to verify your status as an accredited investor,
                    such as verification of income, proof of assets, pulling
                    your credit history, and other documentation to verify that
                    you meet the definition of an accredited investor;
                  </li>
                  <li>
                    Location information, such as the geolocation of your
                    device;
                  </li>
                  <li>
                    Feedback and correspondence, such as the information you
                    provide in your responses to surveys, when you participate
                    in market research activities, report a problem with
                    Service, receive customer support or otherwise correspond
                    with us;
                  </li>
                  <li>
                    Usage information, such as information about how you use the
                    Service and interact with us;
                  </li>
                  <li>
                    Marketing information, such as your preferences for
                    receiving marketing communications and details about how you
                    engage with them;
                  </li>
                  <li>
                    For our business purposes, such as audits, security,
                    compliance with applicable laws and regulations, fraud
                    monitoring and prevention; and
                  </li>
                  <li>
                    Any other information or attachments that you provide to us.
                  </li>
                </ul>
              </div>

              <p>
                <i>Information We Get From Others.</i> We may get information
                about you from other third-party sources and we may add this to
                information we get from your use of the Services.
              </p>

              <div>
                <p>
                  <i>Information Automatically Collected.</i> We may
                  automatically record certain information about how you use our
                  Site (we refer to this information as "Log Data"). Log Data
                  may include information such as a user's Internet Protocol
                  (IP) address, device and browser type, operating system, the
                  pages or features of our Site to which a user browsed and the
                  time spent on those pages or features, the frequency with
                  which the Site is used by a user, search terms, the links on
                  our Site that a user clicked on or used, and other statistics.
                  We use this information to administer the Service and we
                  analyze (and may engage third parties to analyze) this
                  information to improve and enhance the Service by expanding
                  its features and functionality and tailoring it to our users'
                  needs and preferences.
                </p>

                <p>
                  We may use cookies or similar technologies to analyze trends,
                  administer the website, track users' movements around the
                  website, and to gather demographic information about our user
                  base as a whole. Users can control the use of cookies at the
                  individual browser level.
                </p>
              </div>

              <p>
                <i>Information We Will Never Collect.</i> We will never ask you
                to share your private keys or wallet seed. Never trust anyone or
                any site that asks you to enter your private keys or wallet
                seed.
              </p>
            </div>

            <div>
              <p className={s['section-header']}>USE OF PERSONAL INFORMATION</p>

              <div>
                <p>
                  <i>To Provide Our Service.</i> We will use your personal
                  information in the following ways:
                </p>
                <ul>
                  <li>To enable you to access and use the Services.</li>
                  <li>To verify your identity and accreditation status.</li>
                  <li>
                    To provide and deliver products and services that you may
                    request.
                  </li>
                  <li>
                    To send information, including confirmations, technical
                    notices, updates, security alerts, and support and
                    administrative messages.
                  </li>
                  <li>
                    For marketing purposes, such as developing promotional
                    materials.
                  </li>
                </ul>
              </div>

              <p>
                <i>To Comply With Law</i>. We use your personal information as
                we believe necessary or appropriate to comply with applicable
                laws (including anti-money laundering (AML) laws and
                know-your-customer (KYC) requirements), lawful requests and
                legal process, such as to respond to subpoenas or requests from
                government authorities.
              </p>

              <p>
                <i>To Communicate With You</i>. We use your personal information
                to communicate about promotions, upcoming events, and other news
                about products and services offered by us and our selected
                partners.
              </p>

              <p>
                <i>To Optimize Our Platform.</i> In order to optimize your user
                experience, we may use your personal information to operate,
                maintain, and improve our Services. We may also use your
                information to respond to your comments and questions regarding
                the Services, and to provide you and other users with general
                customer service.
              </p>

              <p>
                <i>With Your Consent.</i> We may use or share your personal
                information with your consent,, you instruct us to take a
                specific action with respect to your personal information, or
                you opt into third party marketing communications.
              </p>

              <p>
                <i>For Compliance, Fraud Prevention, and Safety.</i> We may use
                your personal information to protect, investigate, and deter
                against fraudulent, unauthorized, or illegal activity. We may
                also use your information for business purposes such as audits.
              </p>
            </div>

            <div>
              <p className={s['section-header']}>
                SHARING OF PERSONAL INFORMATION
              </p>

              <p>
                We do not share or sell the personal information that you
                provide us with other organizations without your express
                consent, except as described in this Privacy Policy. We disclose
                personal information to third parties under the following
                circumstances:
              </p>

              <ul>
                <li>
                  <i>Business Transfers.</i> We may share personal information
                  when we do a business deal, or negotiate a business deal,
                  involving the sale or transfer of all or a part of our
                  business or assets. These deals can include any merger,
                  financing, acquisition, or bankruptcy transaction or
                  proceeding.
                </li>
                <li>
                  <p>
                    <i>
                      Compliance with Laws and Law Enforcement; Protection and
                      Safety.
                    </i>{' '}
                    We may share personal information for legal, protection, and
                    safety purposes.
                  </p>
                  <ul>
                    <li>
                      We may share information to comply with laws, including
                      KYC and AML requirements.
                    </li>
                    <li>
                      We may share information to respond to lawful requests and
                      legal processes.
                    </li>
                    <li>
                      We may share information to protect the rights and
                      property of the Company, our agents, customers, and
                      others. This includes enforcing our agreements, policies,
                      and terms of use.
                    </li>
                    <li>
                      We may share information in an emergency. This includes
                      protecting the safety of our employees and agents, our
                      customers, or any person.
                    </li>
                  </ul>
                </li>
                <li>
                  <i>Professional Advisors and Service Providers.</i> We may
                  share information with those who need it to do work for us.
                  These recipients may include third party companies and
                  individuals to administer and provide the Services on our
                  behalf (such as customer support, hosting, data analysis,
                  credit bureaus, KYC and AML services, email delivery, database
                  management services, and other services), as well as lawyers,
                  bankers, auditors, and insurers.
                </li>
                <li>
                  <i>Other.</i> You may permit us to share your personal
                  information with other companies or entities of your choosing.
                  Those uses will be subject to the privacy policies of the
                  recipient entity or entities.
                </li>
              </ul>
            </div>

            <div>
              <p className={s['section-header']}>INTERNATIONAL TRANSFER</p>

              <p>
                Your personal information may be transferred to or from the
                United States or other locations outside of your state,
                province, country or other governmental jurisdiction where
                privacy laws may not be as protective as those in your
                jurisdiction.
              </p>

              <p>
                EU users should read the important information provided below
                about transfer of personal information outside of the European
                Economic Area (EEA).
              </p>
            </div>

            <div>
              <p className={s['section-header']}>HOW INFORMATION IS SECURED</p>

              <p>
                We retain information we collect as long as it is necessary and
                relevant to fulfill the purposes outlined in this privacy
                policy. In addition, we retain personal information to comply
                with applicable law where required, prevent fraud, resolve
                disputes, troubleshoot problems and other actions permitted by
                law. To determine the appropriate retention period for personal
                information, we consider the amount, nature, and sensitivity of
                the personal information, the potential risk of harm from
                unauthorized use or disclosure of your personal information, the
                purposes for which we process your personal information and
                whether we can achieve those purposes through other means, and
                the applicable legal requirements.
              </p>

              <p>
                In some circumstances we may anonymize your personal information
                (so that it can no longer be associated with you) in which case
                we may use this information indefinitely without further notice
                to you.
              </p>

              <p>
                We employ industry standard security measures designed to
                protect the security of all information submitted through the
                Services. However, the security of information transmitted
                through the internet can never be guaranteed. We are not
                responsible for any interception or interruption of any
                communications through the internet or for changes to or losses
                of data. Users of the Services are responsible for maintaining
                the security of any password, biometrics, user ID or other form
                of authentication involved in obtaining access to password
                protected or secure areas of any of our digital services. In
                order to protect you and your data, we may suspend your use of
                any of the Services, without notice, pending an investigation,
                if any breach of security is suspected.
              </p>
            </div>

            <div>
              <p className={s['section-header']}>
                INFORMATION CHOICES AND CHANGES
              </p>

              <p>
                <i>
                  Accessing, Updating, Correcting, and Deleting your Information
                </i>
              </p>
              <p>
                You may access information that you have voluntarily provided
                through your account on the Services, and to review, correct, or
                delete it by sending a request to{' '}
                <a
                  href={`mailto:${orgPrimaryContactEmail}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {orgPrimaryContactEmail}
                </a>
                . You can request to change contact choices, opt-out of our
                sharing with others, and update your personal information and
                preferences.
              </p>

              <p>
                <i>Tracking Technologies Generally</i>
              </p>
              <p>
                Regular cookies may generally be disabled or removed by tools
                available as part of most commercial browsers, and in some
                instances blocked in the future by selecting certain settings.
                For more information, please see the section entitled "Cookies
                Policy" below.
              </p>
            </div>

            <div>
              <p className={s['section-header']}>CONTACT INFORMATION</p>

              <p>
                We welcome your comments or questions about this Policy, and you
                may contact us at:{' '}
                <a
                  href={`mailto:${orgPrimaryContactEmail}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {orgPrimaryContactEmail}
                </a>
                .
              </p>
            </div>

            <div>
              <p className={s['section-header']}>
                CHANGES TO THIS PRIVACY POLICY
              </p>

              <p>
                We may change this Privacy Policy at any time. We encourage you
                to periodically review this page for the latest information on
                our privacy practices. If we make any changes, we will change
                the <i>Last Updated</i> date above.
              </p>

              <p>
                Any modifications to this Privacy Policy will be effective upon
                our posting of the new terms and/or upon implementation of the
                changes to the Site (or as otherwise indicated at the time of
                posting). In all cases, your continued use of the Site or
                Services after the posting of any modified Privacy Policy
                indicates your acceptance of the terms of the modified Privacy
                Policy.
              </p>
            </div>

            <div>
              <p className={s['section-header']}>ELIGIBILITY</p>

              <p>
                If you are under the age of majority in your jurisdiction of
                residence, you may use the Services only with the consent of or
                under the supervision of your parent or legal guardian.
                Consistent with the requirements of the Children's Online
                Privacy Protection Act (COPPA), if we learn that we have
                received any information directly from a child under age 13
                without first receiving his or her parent's verified consent, we
                will use that information only to respond directly to that child
                (or his or her parent or legal guardian) to inform the child
                that he or she cannot use the Site and subsequently we will
                delete that information.
              </p>
            </div>

            <div>
              <p className={s['section-header']}>
                NOTICE TO CALIFORNIA RESIDENTS
              </p>

              <p>
                Under California Civil Code Section 1789.3, California users are
                entitled to the following consumer rights notice: California
                residents may reach the Complaint Assistance Unit of the
                Division of Consumer Services of the California Department of
                Consumer Affairs by mail at 1625 North Market Blvd., Sacramento,
                CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.
              </p>

              <p>
                This section provides additional details about the personal
                information we collect about California consumers and the rights
                afforded to them under the California Consumer Privacy Act or
                "CCPA."
              </p>

              <p>
                For more details about the personal information we collect from
                you, please see the "What We Collect" section above. We collect
                this information for the business and commercial purposes
                described in the "Use of Personal Information" section above. We
                share this information with the categories of third parties
                described in the "Sharing of Personal Information" section
                above. The Company does not sell (as such term is defined in the
                CCPA) the personal information we collect (and will not sell it
                without providing a right to opt out). Please refer to our
                Cookie Policy below for more information regarding the types of
                third-party cookies, if any, that we use.
              </p>

              <p>
                Subject to certain limitations, the CCPA provides California
                consumers the right to request to know more details about the
                categories or specific pieces of personal information we collect
                (including how we use and disclose this information), to delete
                their personal information, to opt out of any "sales" that may
                be occurring, and to not be discriminated against for exercising
                these rights.
              </p>

              <p>
                California consumers may make a request pursuant to their rights
                under the CCPA by contacting us a{' '}
                <a
                  href={`mailto:${orgPrimaryContactEmail}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {orgPrimaryContactEmail}
                </a>
                . Please note that you must verify your identity and request
                before further action is taken. As a part of this process,
                government identification may be required. Consistent with
                California law, you may designate an authorized agent to make a
                request on your behalf. In order to designate an authorized
                agent to make a request on your behalf, you must provide a valid
                power of attorney, the requester's valid government issued
                identification, and the authorized agent's valid government
                issued identification.
              </p>
            </div>

            <div>
              <p className={s['section-header']}>NOTICE TO EU DATA SUBJECTS</p>

              <p>
                <i>Personal Information</i>. With respect to EU data subjects,
                "personal information," as used in this Privacy Policy, is
                equivalent to "personal data" as defined in the{' '}
                <a
                  href="https://gdpr-info.eu/art-4-gdpr/"
                  target="_blank"
                  rel="noopener noreferrer">
                  European Union General Data Protection Regulation
                </a>{' '}
                (GDPR).
              </p>

              <p>
                <i>Sensitive Data.</i> Some of the information you provide us
                may constitute sensitive data as defined in the GDPR (also
                referred to as special categories of personal data), including
                identification of your race or ethnicity on government-issued
                identification documents.
              </p>

              <p>
                <i>Legal Bases for Processing.</i> We only use your personal
                information as permitted by law. We are required to inform you
                of the legal bases of our processing of your personal
                information, which are described in the table below. If you have
                questions about the legal bases under which we process your
                personal information, contact us at{' '}
                <a
                  href={`mailto:${orgPrimaryContactEmail}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {orgPrimaryContactEmail}
                </a>
                .
              </p>

              <table>
                <thead>
                  <tr>
                    <th>
                      <i>Processing Purpose</i>
                    </th>
                    <th>
                      <i>Legal Basis</i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>To provide our service</td>
                    <td>
                      Our processing of your personal information is necessary
                      to perform the contract governing our provision of the
                      Services or to take steps that you request prior to
                      signing up for the Service.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      To communicate with you
                      <br />
                      <br />
                      To optimize our platform
                      <br />
                      <br />
                      For compliance, fraud prevention, and safety
                      <br />
                      <br />
                      To provide our service
                    </td>
                    <td>
                      These processing activities constitute our legitimate
                      interests. We make sure we consider and balance any
                      potential impacts on you (both positive and negative) and
                      your rights before we process your personal information
                      for our legitimate interests. We do not use your personal
                      information for activities where our interests are
                      overridden by any adverse impact on you (unless we have
                      your consent or are otherwise required or permitted to by
                      law).
                    </td>
                  </tr>
                  <tr>
                    <td>To comply with law</td>
                    <td>
                      We use your personal information to comply with applicable
                      laws and our legal obligations, including anti-money
                      laundering (AML) laws and know-your-customer (KYC)
                      requirements.
                    </td>
                  </tr>
                  <tr>
                    <td>With your consent</td>
                    <td>
                      Where our use of your personal information is based upon
                      your consent, you have the right to withdraw it anytime in
                      the manner indicated in the Service or by contacting us at{' '}
                      <a
                        href={`mailto:${orgPrimaryContactEmail}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        {orgPrimaryContactEmail}
                      </a>
                      .
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                <i>Use for New Purposes.</i> We may use your personal
                information for reasons not described in this Privacy Policy,
                where we are permitted by law to do so and where the reason is
                compatible with the purpose for which we collected it. If we
                need to use your personal information for an unrelated purpose,
                we will notify you and explain the applicable legal basis for
                that use. If we have relied upon your consent for a particular
                use of your personal information, we will seek your consent for
                any unrelated purpose.
              </p>

              <div>
                <p>
                  <i>Your Rights.</i> Under the GDPR, you have certain rights
                  regarding your personal information. You may ask us to take
                  the following actions in relation to your personal information
                  that we hold:
                </p>
                <ul>
                  <li>
                    <i>Opt-out.</i> Stop sending you direct marketing
                    communications which you have previously consented to
                    receive. We may continue to send you Service-related and
                    other non-marketing communications.
                  </li>
                  <li>
                    <i>Access.</i> Provide you with information about our
                    processing of your personal information and give you access
                    to your personal information.
                  </li>
                  <li>
                    <i>Correct.</i> Update or correct inaccuracies in your
                    personal information.
                  </li>
                  <li>
                    <i>Delete.</i> Delete your personal information.
                  </li>
                  <li>
                    <i>Transfer.</i> Transfer a machine-readable copy of your
                    personal information to you or a third party of your choice.
                  </li>
                  <li>
                    <i>Restrict.</i> Restrict the processing of your personal
                    information.
                  </li>
                  <li>
                    <i>Object.</i> Object to our reliance on our legitimate
                    interests as the basis of our processing of your personal
                    information that impacts your rights.
                  </li>
                </ul>
                <p>
                  You can submit these requests by email to{' '}
                  <a
                    href={`mailto:${orgPrimaryContactEmail}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {orgPrimaryContactEmail}
                  </a>
                  . We may request specific information from you to help us
                  confirm your identity and process your request. Applicable law
                  may require or permit us to decline your request. If we
                  decline your request, we will tell you why, subject to legal
                  restrictions. If you would like to submit a complaint about
                  our use of your personal information or response to your
                  requests regarding your personal information, you may contact
                  us at{' '}
                  <a
                    href={`mailto:${orgPrimaryContactEmail}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {orgPrimaryContactEmail}
                  </a>{' '}
                  or submit a complaint to the data protection regulator in your
                  jurisdiction. You can find your data protection regulator{' '}
                  <a
                    href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
                    target="_blank"
                    rel="noopener noreferrer">
                    here
                  </a>
                  .
                </p>
              </div>

              <p>
                <i>Cross-Border Data Transfer.</i> Please be aware that your
                personal data will be transferred to, processed, and stored in
                the United States. Data protection laws in the U.S. may be
                different from those in your country of residence. You consent
                to the transfer of your information, including personal
                information, to the U.S. as set forth in this Privacy Policy by
                visiting our site or using our service.
              </p>

              <p>
                Whenever we transfer your personal information out of the EEA to
                the U.S. or countries not deemed by the European Commission to
                provide an adequate level of personal information protection,
                the transfer will be based on a data transfer mechanism
                recognized by the European Commission as providing adequate
                protection for personal information.
              </p>

              <p>
                Please contact us if you want further information on the
                specific mechanism used by us when transferring your personal
                information out of the EEA.
              </p>
            </div>

            <div>
              <p className={s['section-header']}>COOKIES POLICY</p>

              <p>
                We understand that your privacy is important to you and are
                committed to being transparent about the technologies we use. In
                the spirit of transparency, this policy provides detailed
                information about how and when we use cookies on our Site. We
                and our marketing partners, affiliates, and analytics or service
                providers use cookies, web beacons, or pixels and other
                technologies to ensure everyone who uses the Site has the best
                possible experience.
              </p>

              <p>
                A cookie ("Cookie") is a small text file that is placed on your
                hard drive by a web page server. Cookies contain information
                that can later be read by a web server in the domain that issued
                the cookie to you. Some of the cookies will only be used if you
                use certain features or select certain preferences, and some
                cookies will always be used. You can find out more about each
                cookie by viewing our current cookie list below. We update this
                list periodically, so there may be additional cookies that are
                not yet listed. Web beacons, tags and scripts may be used in the
                Site or in emails to help us to deliver cookies, count visits,
                understand usage and campaign effectiveness and determine
                whether an email has been opened and acted upon. We may receive
                reports based on the use of these technologies by our
                service/analytics providers on an individual and aggregated
                basis.
              </p>

              <p>We generally use Cookies for the following purposes:</p>
              <p>To recognize new or past customers.</p>
              <ul>
                <li>
                  To store your password if you are registered on our Site.
                </li>
                <li>
                  To improve our Site and to better understand your visits on
                  our platforms and Site.
                </li>
                <li>
                  To serve you with interest-based or targeted advertising.
                </li>
                <li>
                  To observe your behaviors and browsing activities over time
                  across multiple websites or other platforms.
                </li>
                <li>
                  To better understand the interests of our customers and our
                  website visitors.
                </li>
              </ul>

              <p>
                Some Cookies are necessary for certain uses of the Site, and
                without such Cookies, we would not be able to provide many
                services that you need to properly use the Site. These Cookies,
                for example, allow us to operate our Site so you may access it
                as you have requested and let us recognize that you have created
                an account and have logged into that account to access Site
                content. They also include Cookies that enable us to remember
                your previous actions within the same browsing session and
                secure our Sites.
              </p>

              <p>
                We also use functional Cookies and Cookies from third parties
                for analysis and marketing purposes. Functional Cookies enable
                certain parts of the site to work properly and your user
                preferences to remain known. Analysis Cookies, among other
                things, collect information on how visitors use our Site, the
                content and products that users view most frequently, and the
                effectiveness of our third-party advertising. Advertising
                Cookies assist in delivering ads to relevant audiences and
                having our ads appear at the top of search results. Cookies are
                either "session" Cookies which are deleted when you end your
                browser session, or "persistent," which remain until their
                deletion by you (discussed below) or the party who served the
                cookie. Full details on all of the Cookies used on the Site are
                available at our Cookie Disclosure table below.
              </p>

              <p>
                You can generally activate or later deactivate the use of
                cookies through a functionality built into your web browser. To
                learn more about how to control cookie settings through your
                browser:
              </p>

              <ul>
                <li>
                  Click{' '}
                  <a
                    href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                    target="_blank"
                    rel="noopener noreferrer">
                    here
                  </a>{' '}
                  to learn more about the "Private Browsing" setting and
                  managing cookie settings in Firefox;
                </li>

                <li>
                  Click{' '}
                  <a
                    href="https://support.google.com/chrome/answer/95647?hl=en"
                    target="_blank"
                    rel="noopener noreferrer">
                    here
                  </a>{' '}
                  to learn more about "Incognito" and managing cookie settings
                  in Chrome;
                </li>

                <li>
                  Click{' '}
                  <a
                    href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                    target="_blank"
                    rel="noopener noreferrer">
                    here
                  </a>{' '}
                  to learn more about "InPrivate" and managing cookie settings
                  in Internet Explorer; or
                </li>

                <li>
                  Click{' '}
                  <a
                    href="https://support.apple.com/guide/safari/browse-in-private-ibrw1069/mac"
                    target="_blank"
                    rel="noopener noreferrer">
                    here
                  </a>{' '}
                  to learn more about "Private Browsing" and managing cookie
                  settings in Safari.
                </li>
              </ul>

              <p>
                If you want to learn more about cookies, or how to control,
                disable or delete them, please visit{' '}
                <a
                  href="http://www.aboutcookies.org"
                  target="_blank"
                  rel="noopener noreferrer">
                  http://www.aboutcookies.org
                </a>{' '}
                for detailed guidance. In addition, certain third-party
                advertising networks, including Google, permit users to opt out
                of or customize preferences associated with your internet
                browsing. To learn more about this feature from Google, click{' '}
                <a
                  href="https://adssettings.google.com/u/0/authenticated?hl=en"
                  target="_blank"
                  rel="noopener noreferrer">
                  here
                </a>
                .
              </p>

              <p>
                To control flash cookies, which we may use on our Site from time
                to time, you can go to this{' '}
                <a
                  href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                  target="_blank"
                  rel="noopener noreferrer">
                  link
                </a>{' '}
                because Flash cookies cannot be controlled through your browser
                settings. Please note that if you decline the use of Cookies,
                some functions of the website may be unavailable and we will not
                be able to present personally tailored content and
                advertisements to you.
              </p>

              <p>
                We may link the information collected by Cookies with other
                information we collect from you pursuant to this Privacy Policy
                and use the combined information as set forth herein. Similarly,
                the third parties who serve cookies on our Site may link your
                name or email address to other information they collect, which
                may include past purchases made offline or online, or your
                online usage information. If you are located in the European
                Economic Area, you have certain rights that are described above
                under the header "Notice to EU Data Subjects," including the
                right to inspect and correct or delete the data that we have
                about you.
              </p>

              <p>
                <i>Cookies Disclosure</i>
              </p>

              <table>
                <thead>
                  <tr>
                    <th>
                      <i>Name of Cookie/Identifier</i>
                    </th>
                    <th>
                      <i>
                        What does the cookie generally do (e.g., website
                        function and administration, analytics, marketing)?
                      </i>
                    </th>
                    <th>
                      <i>
                        Is it a 1st or 3rd party cookie and what is the name of
                        the party providing it?
                      </i>
                    </th>
                    <th>
                      <i>What type of cookie is it (persistent or session)?</i>
                    </th>
                    <th>
                      <i>
                        What is the duration of the cookie on the website (if
                        not cleared by the user)?
                      </i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </FadeIn>
      </Wrap>
    </>
  );
}
