import React from 'react';
import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';

import {getOrgText, trimText} from '../../../util/helpers';
import {GetInvestmentProposalResponse, StoreState} from '../../../util/types';

export default function InvestmentProposalHelmet({
  canView,
  proposal,
}: {
  canView: boolean;
  proposal: GetInvestmentProposalResponse;
}): JSX.Element {
  /**
   * Selectors
   */

  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  /**
   * Variables
   */

  const getText = getOrgText(orgText);
  const orgProjectText = getText('OrgProjectText');
  const orgName = getText('OrgName');

  // Render limited information if does not have access.
  if (!canView) {
    return (
      <Helmet>
        <title>{`${orgName} - ${orgProjectText}`}</title>
        <meta name="description" content={`${orgProjectText} for ${orgName}`} />
      </Helmet>
    );
  }

  const title = `${orgName} ${orgProjectText} | ${proposal?.name || '\u2026'}`;
  const metaDescription = trimText(proposal?.description || '', {
    characters: 200,
    word: true,
  });

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
  );
}
