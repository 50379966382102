import React from 'react';
import {useSelector} from 'react-redux';

import {StoreState} from '../../util/types';
import {getOrgText} from '../../util/helpers';

import CheckSVG from '../../assets/svg/CheckSVG';

import s from '../../assets/scss/modules/votingprogressbar.module.scss';

type SquareRootVotingBarProps = {
  yes: string;
  no: string;
  votingExpired: boolean;
  yesVotes: number;
  noVotes: number;
  sponsored: boolean;
};

export default function SquareRootVotingBar({
  yes = '0.00',
  no = '0.00',
  votingExpired,
  yesVotes,
  noVotes,
  sponsored,
}: SquareRootVotingBarProps) {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  const getText = getOrgText(orgText);
  const orgVotingBarOffset = getText('OrgVotingBarOffset') || '0';
  const percentYes = parseFloat(yes).toFixed(0) || '0';
  const percentNo = parseFloat(no).toFixed(0) || '0';

  // custom styles needed to properly show voting progress that is offset from
  // the end of its container (e.g., for liquidity org)
  const unanimousYesAdditionalClass =
    percentYes === '100' ? 'org-yes-vote--unanimous' : '';
  const unanimousNoAdditionalClass =
    percentNo === '100' ? 'org-no-vote--unanimous' : '';

  function calculateVotingBarOffset(votePercentage: string): string {
    const rawOffset =
      votePercentage === '100'
        ? (Number(orgVotingBarOffset) * 2).toString()
        : orgVotingBarOffset;

    return `${rawOffset}px`;
  }

  return (
    <div className={`${s['votes-bar-container']} org-votes-bar-container`}>
      <div
        className={`${s['square-root-votes-bar']} org-square-root-votes-bar`}>
        <div className={`${s['square-root-bar']} org-square-root-bar`}>
          <div
            className={`${s['yes-vote']} org-yes-vote ${unanimousYesAdditionalClass}`}
            style={{
              width: `calc(${percentYes}% - ${calculateVotingBarOffset(
                percentYes
              )})`,
            }}
          />
          <div
            className={`${s['no-vote']} org-no-vote ${unanimousNoAdditionalClass}`}
            style={{
              width: `calc(${percentNo}% - ${calculateVotingBarOffset(
                percentNo
              )})`,
            }}
          />
        </div>
      </div>

      <div className={s['votes']}>
        {sponsored && votingExpired && yesVotes > noVotes && (
          <span className={`${s['yes-check']} org-yes-check`}>
            <CheckSVG />
          </span>
        )}
        <span className={`${s['yes-percent']} org-yes-percent`}>
          {percentYes}%
        </span>
        <span className={`${s['no-percent']} org-no-percent`}>
          {percentNo}%
        </span>
        {sponsored && votingExpired && yesVotes <= noVotes && (
          <span className={`${s['no-check']} org-no-check`}>
            <CheckSVG />
          </span>
        )}
      </div>
    </div>
  );
}
