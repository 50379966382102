import React, {Fragment} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {GetInvestmentProposalResponse, StoreState} from '../../util/types';
import {InternalNamesToMap} from '../../util/orgDomainMappings';
import {MemoizedProposalCard} from '../../components/ui/ProposalCard';
import {ProposalHeaderNames} from '../../util/enums';
import {useCanViewInvestmentProposal} from '../../hooks/useCanViewInvestmentProposal';
import ProjectDefaultIcon from '../../components/ui/ProjectDefaultIcon';
import SnapshotProposalCard from '../../components/snapshot/SnapshotProposalCard';

export default function InvestmentProposalsCards({
  headerName,
  onUpdateSnapshotProposals,
  proposals,
}: {
  headerName?: ProposalHeaderNames;
  onUpdateSnapshotProposals?: () => void;
  proposals: GetInvestmentProposalResponse[];
}): JSX.Element {
  /**
   * Selectors
   */

  const isMember = useSelector((s: StoreState) =>
    s.connectedMember && s.connectedMember.isMemberActive ? true : false
  );
  const molochConstants = useSelector(
    (s: StoreState) => s.blockchain.molochConstants
  );
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  /**
   * Our hooks
   */

  const {canViewInvestmentProposal} = useCanViewInvestmentProposal();

  /**
   * Their hooks
   */

  const history = useHistory();

  /**
   * Functions
   */

  function handleClickProposalDetails(uuid: string) {
    return () => {
      if (!uuid) return;

      history.push(`/proposals/${uuid}`);
    };
  }

  function renderMolochProposalCard(
    molochProposal: GetInvestmentProposalResponse
  ): JSX.Element {
    const isPrivateView =
      molochProposal.private && !canViewInvestmentProposal(molochProposal);

    return (
      <Fragment key={molochProposal.uuid}>
        <MemoizedProposalCard
          onClick={
            !isPrivateView
              ? handleClickProposalDetails(molochProposal.uuid)
              : () => {}
          }
          privateView={isPrivateView}
          proposalDetails={{
            ...molochConstants,
            // @todo Fix types to accept
            ...(molochProposal as any),
          }}
          pathToProposal={
            isPrivateView ? '' : `/proposals/${molochProposal.uuid}`
          }
        />
      </Fragment>
    );
  }

  function renderSnapshotProposalCard(
    snapshotProposal: GetInvestmentProposalResponse,
    headerName?: ProposalHeaderNames
  ): JSX.Element {
    const renderVerbose =
      headerName === ProposalHeaderNames.VOTING ||
      headerName === ProposalHeaderNames.FAILED;

    const buttonText =
      renderVerbose && headerName === ProposalHeaderNames.VOTING && isMember
        ? 'Vote'
        : renderVerbose && headerName === ProposalHeaderNames.FAILED
        ? 'Review'
        : '';

    const privateView =
      snapshotProposal.private && !canViewInvestmentProposal(snapshotProposal);

    return (
      <SnapshotProposalCard
        key={snapshotProposal.uuid}
        buttonText={buttonText}
        name={snapshotProposal.name}
        onClick={
          !privateView
            ? handleClickProposalDetails(snapshotProposal.uuid)
            : () => {}
        }
        onVoteEnded={onUpdateSnapshotProposals}
        pathToProposal={`/proposals/${snapshotProposal.uuid}`}
        // Render only the basics for investments (passed).
        privateView={privateView}
        shouldRenderVerbose={renderVerbose}
        snapshotProposal={snapshotProposal.snapshotHubProposal}
        snapshotVotes={snapshotProposal.snapshotHubVotes}
        renderIcon={() => {
          if (orgInternalName === InternalNamesToMap.thelao) {
            return <ProjectDefaultIcon proposalId={-1} />;
          }

          return (
            <img
              alt="Proposal default icon"
              src={`/orgs/${orgInternalName}/images/proposal-default-icon.svg`}
            />
          );
        }}
      />
    );
  }

  return (
    <>
      {proposals.map((p) => {
        switch (p.proposalTarget) {
          case 'moloch':
            return renderMolochProposalCard(p);
          case 'snapshot':
            return renderSnapshotProposalCard(p, headerName);
          case null:
            // Use the same renderer as Moloch (more generic) if there's no target.
            return renderMolochProposalCard(p);
          default:
            return null;
        }
      })}
    </>
  );
}
