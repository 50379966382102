import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';

import {
  formatNumber,
  getOrgText,
  getValidationError,
  stripFormatNumber,
} from '../../../util/helpers';
import {FetchStatus, InvestmentProposalCurrency} from '../../../util/enums';
import {SnapshotGetFormValuesReturn} from '../../../components/snapshot/types';
import {SnapshotProposalType} from '../../../components/snapshot/enums';
import {StoreState} from '../../../util/types';
import {useCreateInvestmentProposal, useIsAdmin} from '../../../hooks';
import BaseSnapshotProposalForm from '../../../components/snapshot/BaseCreateSnapshotProposalForm';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import FadeIn from '../../../components/common/FadeIn';
import InputError from '../../../components/common/InputError';
import Loader from '../../../components/feedback/Loader';
import Wrap from '../../../components/layout/Wrap';

import b from '../../../assets/scss/modules/buttons.module.scss';
import fs from '../../../assets/scss/modules/formsteps.module.scss';
import i from '../../../assets/scss/modules/input.module.scss';

enum FieldNameEnum {
  amountRequested = 'amountRequested',
}

const ERROR_REQUIRED_FIELD = 'This is a required field.';

const validateETH = {
  validate: (value: string) => {
    const amount = Number(stripFormatNumber(value));

    return value === ''
      ? ERROR_REQUIRED_FIELD
      : isNaN(amount)
      ? 'The value is not a number.'
      : amount < 0
      ? 'The minimum amount is 0 ETH.'
      : true;
  },
};

// Validation configuration for react-hook-form
const validateConfig: Partial<Record<FieldNameEnum, Record<string, any>>> = {
  amountRequested: validateETH,
};

/**
 * CreateInvestmentProposalMetaverse
 *
 * Submits an entry to the snapshot proposals table.
 * This data is then used to create an actual Snapshot Hub proposal
 * (this is the equivalent of sponsoring in Moloch).
 *
 * @todo We should find a way to use form generation, instead of making a form for each DAO.
 *
 * @returns {JSX.Element}
 */
export default function CreateInvestmentProposalMetaverse() {
  /**
   * State
   */

  const [submitHandlerError, setSubmitHandlerError] = useState<Error>();

  /**
   * Selectors
   */

  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const connectedMember = useSelector((s: StoreState) => s.connectedMember);
  const userEmailAddress = useSelector((s: StoreState) => s.user.emailAddress);
  const userEthereumAddress = useSelector(
    (s: StoreState) => s.user.ethereumAddress
  );

  /**
   * Our Hooks
   */

  const isAdmin = useIsAdmin();
  const {
    createInvestmentProposal,
    createInvestmentProposalError,
    createInvestmentProposalStatus,
  } = useCreateInvestmentProposal();

  /**
   * Their hooks
   */

  const history = useHistory();

  /**
   * Variables
   */

  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');
  const orgSubmitProposalButtonText = getText('OrgSubmitProposalButtonText');
  const isAuthorized = connectedMember.isMemberActive || isAdmin ? true : false;
  const submitError = createInvestmentProposalError || submitHandlerError;
  const submitIsInProcessOrDone =
    createInvestmentProposalStatus === FetchStatus.PENDING ||
    createInvestmentProposalStatus === FetchStatus.FULFILLED;

  /**
   * Functions
   */

  async function handleSubmit(values: SnapshotGetFormValuesReturn) {
    try {
      if (!userEmailAddress || !userEthereumAddress) {
        throw new Error(
          'No user account was found. Please makes sure your wallet is connected.'
        );
      }

      // Shape our form values
      const {
        addressToFund,
        name,
        body,
        metadata: {amountRequested, private: privacy},
      } = values;

      const formValues = {
        // Core submit information
        name,
        description: body,
        addressToFund,
        amountRequested: Number(stripFormatNumber(amountRequested)),
        amountRequestedCurrency: InvestmentProposalCurrency.ETH,
        private: privacy,
        userEmailAddress,
        userEthereumAddress,

        // Additional information
        metadata: {},
      };

      const {uuid} = await createInvestmentProposal(formValues);

      // Navigate to proposal page
      history.push(`/proposals/${uuid}`);
    } catch (error) {
      setSubmitHandlerError(error);
    }
  }

  /**
   * Render
   */

  // Render wallet auth message if member is not connected
  if (!isAuthorized) {
    return (
      <RenderWrapper>
        <p
          className={`${fs['form-description']} color-yellow text-center org-notification info`}
          style={{paddingTop: 0}}>
          Connect your wallet to an active member address to submit a proposal.
        </p>
      </RenderWrapper>
    );
  }

  return (
    <RenderWrapper>
      <BaseSnapshotProposalForm
        type={SnapshotProposalType.Investment}
        render={({
          form,
          formErrorMessage,
          getFormValues,
          isValid,
          AddressToFundInput,
          NameInput,
          BodyInput,
          PrivacyInput,
        }) => (
          <>
            <NameInput />
            <BodyInput />

            {/* AMOUNT REQUESTED */}
            <div className={`${fs['input-row']}`}>
              <label
                className={`${fs['input-row-label']} org-input-row-label`}
                htmlFor={`snapshot-${FieldNameEnum.amountRequested}`}>
                Requested Amount
              </label>
              <div
                className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                <div className={i['input-suffix__wrap']}>
                  <input
                    aria-describedby={`snapshot-error-${FieldNameEnum.amountRequested}`}
                    aria-invalid={
                      form.errors[FieldNameEnum.amountRequested]
                        ? 'true'
                        : 'false'
                    }
                    id={`snapshot-${FieldNameEnum.amountRequested}`}
                    className={`${i['input-suffix--sm']}`}
                    onChange={() =>
                      form.setValue(
                        FieldNameEnum.amountRequested,
                        formatNumber(form.getValues().amountRequested)
                      )
                    }
                    name={FieldNameEnum.amountRequested}
                    ref={
                      validateConfig.amountRequested &&
                      form.register(validateConfig.amountRequested)
                    }
                    type="text"
                  />
                  <div
                    className={`${i['input-suffix__item--sm']} org-input-suffix__item--sm`}>
                    <small>ETH</small>
                  </div>
                </div>

                <InputError
                  error={getValidationError(
                    FieldNameEnum.amountRequested,
                    form.errors
                  )}
                  id={`snapshot-error-${FieldNameEnum.amountRequested}`}
                />
              </div>
            </div>

            <AddressToFundInput />

            <PrivacyInput
              text={`Only ${orgName} members can view this proposal.`}
            />

            {/* SUBMIT */}
            <button
              className={`${b.primary} org-primary-button`}
              disabled={submitIsInProcessOrDone}
              onClick={() => {
                if (submitIsInProcessOrDone) return;

                if (!isValid) {
                  form.triggerValidation();
                  return;
                }

                handleSubmit(getFormValues());
              }}
              type="submit">
              {createInvestmentProposalStatus === FetchStatus.PENDING ? (
                <Loader text="Submitting&hellip;" />
              ) : createInvestmentProposalStatus === FetchStatus.FULFILLED ? (
                'Submitted!'
              ) : (
                orgSubmitProposalButtonText
              )}
            </button>

            {/* SUBMIT ERROR */}
            {(formErrorMessage || submitError) && (
              <div className="text-center">
                <ErrorMessageWithDetails
                  renderText="Something went wrong while submitting the proposal."
                  error={submitError || new Error(formErrorMessage)}
                />
              </div>
            )}
          </>
        )}
      />
    </RenderWrapper>
  );
}

function RenderWrapper(props: React.PropsWithChildren<any>): JSX.Element {
  /**
   * Selectors
   */

  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  // const orgDocsURL = useSelector((s: StoreState) => s.org && s.org.docsURL);

  /**
   * Variables
   */

  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');
  const orgApplyTitle = getText('OrgApplyTitle');

  return (
    <Wrap className={'section-wrapper'}>
      <FadeIn>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">
            {orgApplyTitle}
          </h2>
        </div>

        <div className="org-form-wrap">
          <div className={`${fs['content-wrap']}`}>
            <div
              className={`${fs['form-description']} org-form-description`}
              style={{paddingTop: 0}}>
              <p style={{marginTop: 0}}>
                Make a strategy proposal to other members of {orgName}. Just
                fill out the information below.
              </p>
              {/* <p>
                If you have any questions, check out our{' '}
                <a
                  href={orgDocsURL || ''}
                  rel="noopener noreferrer"
                  target="_blank">
                  FAQs
                </a>
                .
              </p> */}
            </div>

            {/* RENDER CHILDREN */}
            {props.children}
          </div>
        </div>
      </FadeIn>
    </Wrap>
  );
}
