import React, {useCallback, useEffect} from 'react';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';

import {FetchStatus} from '../../../util/enums';
import {getOrgText} from '../../../util/helpers';
import {
  SnapshotProposalSubType,
  SnapshotProposalType,
} from '../../../components/snapshot/enums';
import {MetaMaskRPCError, StoreState} from '../../../util/types';
import {useIsAdmin} from '../../../hooks';
import BaseSnapshotProposalForm from '../../../components/snapshot/BaseCreateSnapshotProposalForm';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import FadeIn from '../../../components/common/FadeIn';
import Loader from '../../../components/feedback/Loader';
import useCreateSnapshotProposal from '../../../components/snapshot/useCreateSnapshotProposal';
import Wrap from '../../../components/layout/Wrap';

import b from '../../../assets/scss/modules/buttons.module.scss';
import fs from '../../../assets/scss/modules/formsteps.module.scss';

/**
 * CreateGovernanceProposal
 *
 * Renders a form which can create a new governance proposal
 * in Snapshot via the snapshot-hub `POST message`.
 *
 * @returns {JSX.Element}
 *
 * @see https://github.com/balancer-labs/snapshot-hub
 * @see https://snapshot.page
 */
export default function CreateGovernanceProposal() {
  /**
   * Selectors
   */

  const connectedMember = useSelector((s: StoreState) => s.connectedMember);
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  /**
   * Our Hooks
   */

  const {
    createSnapshotProposal,
    snapshotProposalIPFSHash,
    snapshotProposalSignStatus,
    snapshotProposalSubmitStatus,
    snapshotProposalSubmitError,
  } = useCreateSnapshotProposal();
  const isAdmin = useIsAdmin();

  /**
   * Their hooks
   */

  const history = useHistory();

  /**
   * Cached Callbacks
   */

  const handleGoToCreatedProposalCached = useCallback(
    handleGoToCreatedProposal,
    [history]
  );

  /**
   * Variables
   */

  const isInProcessOrDone =
    snapshotProposalSignStatus === FetchStatus.PENDING ||
    snapshotProposalSubmitStatus === FetchStatus.PENDING ||
    (snapshotProposalSignStatus === FetchStatus.FULFILLED &&
      snapshotProposalSubmitStatus === FetchStatus.FULFILLED);
  const getText = getOrgText(orgText);
  const orgSubmitProposalButtonText = getText('OrgSubmitProposalButtonText');
  const isAuthorized = connectedMember.isMemberActive || isAdmin ? true : false;

  /**
   * Effects
   */

  useEffect(() => {
    if (!snapshotProposalIPFSHash) return;

    handleGoToCreatedProposalCached(snapshotProposalIPFSHash);
  }, [handleGoToCreatedProposalCached, snapshotProposalIPFSHash]);

  /**
   * Functions
   */

  function handleGoToCreatedProposal(proposalIPFSHash: string) {
    history.push(`/governance-proposals/${proposalIPFSHash}`);
  }

  /**
   * Render
   */

  // Render wallet auth message if member is not connected
  if (!isAuthorized) {
    return (
      <RenderWrapper>
        <p
          className={`${fs['form-description']} color-yellow text-center org-notification info`}
          style={{paddingTop: 0}}>
          Connect your wallet to an active member address to submit a proposal.
        </p>
      </RenderWrapper>
    );
  }

  return (
    <RenderWrapper>
      <BaseSnapshotProposalForm
        type={SnapshotProposalType.Governance}
        render={({
          form,
          formErrorMessage,
          getFormValues,
          isValid,
          SubTypeInput,
          NameInput,
          BodyInput,
          PrivacyInput,
          VotingLengthInput,
        }) => (
          <>
            <SubTypeInput
              renderHelpText={(value) => {
                switch (value) {
                  case SnapshotProposalSubType.General:
                    return (
                      <span>
                        If there are more &ldquo;yes&rdquo; votes than
                        &ldquo;no&rdquo; votes then the vote will pass.
                      </span>
                    );
                  case SnapshotProposalSubType.Governance:
                    return (
                      <span>
                        If the &ldquo;yes&rdquo; votes are more than 50% then
                        the vote will pass.
                      </span>
                    );
                  default:
                    return null;
                }
              }}
            />
            <NameInput />
            <BodyInput />
            <VotingLengthInput />
            <PrivacyInput />

            {/* SUBMIT */}
            <button
              className={`${b.primary} org-primary-button`}
              disabled={isInProcessOrDone}
              onClick={() => {
                if (isInProcessOrDone) return;

                if (!isValid) {
                  form.triggerValidation();
                  return;
                }

                createSnapshotProposal(getFormValues())();
              }}
              type="submit">
              {snapshotProposalSignStatus === FetchStatus.PENDING ? (
                <Loader text="Preparing&hellip;" />
              ) : snapshotProposalSubmitStatus === FetchStatus.PENDING ? (
                <Loader text="Processing&hellip;" />
              ) : snapshotProposalSubmitStatus === FetchStatus.FULFILLED ? (
                'Submitted!'
              ) : (
                orgSubmitProposalButtonText
              )}
            </button>

            {/* SUBMIT ERROR */}
            {(formErrorMessage || snapshotProposalSubmitError) &&
              (snapshotProposalSubmitError as MetaMaskRPCError).code !==
                4001 && (
                <div className="text-center">
                  <ErrorMessageWithDetails
                    renderText="Something went wrong while submitting the proposal."
                    error={
                      snapshotProposalSubmitError || new Error(formErrorMessage)
                    }
                  />
                </div>
              )}
          </>
        )}
      />
    </RenderWrapper>
  );
}

function RenderWrapper(props: React.PropsWithChildren<any>): JSX.Element {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');

  return (
    <Wrap className={'section-wrapper'}>
      <FadeIn>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">
            Governance Proposal
          </h2>
        </div>

        <div className="org-form-wrap">
          <div className={`${fs['content-wrap']}`}>
            <div
              className={`${fs['form-description']} org-form-description`}
              style={{paddingTop: 0}}>
              <p style={{marginTop: 0}}>
                As a member, you can make proposals related to the governance of{' '}
                {orgName}.
              </p>
              <p>
                Describe in full so that others can make an informed decision.
                You may also provide any additional supporting materials to
                explicate your proposal.
              </p>
            </div>

            {/* RENDER CHILDREN */}
            {props.children}
          </div>
        </div>
      </FadeIn>
    </Wrap>
  );
}
