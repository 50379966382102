import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import Web3 from 'web3';

import {
  ProposalStatusMessage,
  FetchStatus,
  InvestmentProposalCurrency,
} from '../../../util/enums';
import {
  StoreState,
  MetaMaskRPCError,
  GetInvestmentProposalResponse,
} from '../../../util/types';
import {getOrgText} from '../../../util/helpers';
import {InternalNamesToMap} from '../../../util/orgDomainMappings';
import {useUSDToWEI} from '../../../hooks/useUSDToWEI';
import {useIsPaymentRequestedOK} from '../../../hooks/useIsPaymentRequestedOK';
import DropSVG from '../../../assets/svg/DropSVG';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import Loader from '../../../components/feedback/Loader';
import ProposalActionWhyDisabled from '../ProposalDisabledReason';
import SubmitProposal, {
  SubmitProposalArguments,
} from '../../../components/contract/SubmitProposal';
import StopwatchSVG from '../../../assets/svg/StopwatchSVG';

import s from '../../../assets/scss/modules/votingcard.module.scss';
import v from '../../../assets/scss/modules/voting.module.scss';

type MolochSubmitInvestmentProposalProps = {
  onComplete?: (v: Record<string, any>) => void;
  proposal: GetInvestmentProposalResponse;
};

/**
 * MolochSubmitInvestmentProposal
 */

export default function MolochSubmitInvestmentProposal(
  props: MolochSubmitInvestmentProposalProps
) {
  const {addressToFund, amountRequested, amountRequestedCurrency, uuid} =
    props.proposal;

  /**
   * State
   */

  const [proposalArguments, setProposalArguments] =
    useState<SubmitProposalArguments>();

  /**
   * Selectors
   */

  const wrapETHAddress = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts &&
      state.blockchain.contracts.WrapETH &&
      state.blockchain.contracts.WrapETH.contractAddress
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  /**
   * Our hooks
   */

  const {WEIFromUSD} = useUSDToWEI(
    amountRequestedCurrency === InvestmentProposalCurrency.USD
      ? Number(amountRequested)
      : 0
  );
  const WEIFromETH =
    amountRequestedCurrency === InvestmentProposalCurrency.ETH
      ? Web3.utils.toWei(amountRequested, 'ether')
      : '';

  const WEIForPayment = WEIFromUSD || WEIFromETH;

  const {isPaymentRequestedOK, isPaymentRequestedOKStatus} =
    useIsPaymentRequestedOK(WEIForPayment);

  /**
   * Variables
   */

  const disabledReasonPayment =
    isPaymentRequestedOKStatus === FetchStatus.FULFILLED &&
    isPaymentRequestedOK === false
      ? 'There is not enough funds available to meet the payment requested from the proposal.'
      : '';
  const getText = getOrgText(orgText);
  const orgSubmitProposalButtonText = getText('OrgSubmitProposalButtonText');

  /**
   * Effects
   */

  useEffect(() => {
    if (!addressToFund || !WEIForPayment || !wrapETHAddress || !uuid) {
      return;
    }

    setProposalArguments([
      /**
       * Applicant
       */
      addressToFund,
      /**
       * No shares
       */
      0,
      /**
       * No loot
       */
      0,
      /**
       * No tribute offered in WEI
       */
      '0',
      /**
       * Tribute token
       */
      wrapETHAddress,
      /**
       * Payment requested in WEI
       */
      WEIForPayment,
      /**
       * Payment token
       */
      wrapETHAddress,
      /**
       * Details
       */
      `Project::${uuid}`,
    ]);
  }, [addressToFund, WEIForPayment, wrapETHAddress, uuid]);

  /**
   * Functions
   */

  function renderCountdownIcon(): JSX.Element {
    switch (orgInternalName) {
      case InternalNamesToMap.liquidity:
        return <DropSVG />;
      case InternalNamesToMap.nft:
      case InternalNamesToMap.thelao:
      default:
        return <StopwatchSVG />;
    }
  }

  return (
    <>
      {/* SUBMIT PROPOSAL */}
      <div className={`${s['row-w-icon']} ${s['countdown']} org-countdown`}>
        {renderCountdownIcon()}
        <span className={`${s['status']} org-status`}>
          {ProposalStatusMessage.PENDING}
        </span>
      </div>

      <ProposalActionWhyDisabled
        otherReasons={[disabledReasonPayment]}
        render={({
          canShowDisabledReason,
          isDisabled,
          openWhyDisabledModal,
          WhyDisabledModal,
        }) => (
          <>
            <SubmitProposal
              onComplete={props.onComplete}
              proposalArguments={proposalArguments}
              render={({
                error,
                etherscanURL,
                isPromptOpen,
                isSubmitted,
                isSubmitting,
                openPrompt,
              }) => (
                <>
                  <button
                    disabled={
                      isDisabled || isSubmitting || isSubmitted || isPromptOpen
                    }
                    onClick={
                      isDisabled || isSubmitting || isSubmitted || isPromptOpen
                        ? () => {}
                        : openPrompt
                    }
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '2rem',
                    }}
                    type="submit">
                    {isPromptOpen ? (
                      <Loader text="Preparing&hellip;" />
                    ) : isSubmitting ? (
                      <Loader text="Processing&hellip;" />
                    ) : isSubmitted ? (
                      'Finishing up\u2026'
                    ) : (
                      orgSubmitProposalButtonText
                    )}
                  </button>

                  {/* ETHERSCAN URL */}
                  {etherscanURL && (
                    <p className="text-center">
                      <small>
                        <a
                          href={etherscanURL}
                          rel="noopener noreferrer"
                          target="_blank">
                          (view {isSubmitting ? 'progress' : 'transaction'})
                        </a>
                      </small>
                    </p>
                  )}

                  {error && (error as MetaMaskRPCError).code !== 4001 && (
                    <div className="text-center">
                      <ErrorMessageWithDetails
                        error={error}
                        renderText="Something went wrong while submitting your proposal."
                      />
                    </div>
                  )}
                </>
              )}
            />

            {canShowDisabledReason && (
              <div style={{paddingBottom: '1em'}}>
                <span
                  className={v['why--tooltip']}
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    event.preventDefault();
                    openWhyDisabledModal();
                  }}>
                  Why is submitting disabled?
                </span>
              </div>
            )}

            <WhyDisabledModal />
          </>
        )}
      />
    </>
  );
}
