import l from '../../assets/scss/modules/logo.module.scss';

interface LogoThelaov2Props {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoThelaov2(props: LogoThelaov2Props) {
  const classNameSizes = {
    daoV2: props.size ? `${l[`daoV2--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.daoV2} ${classNameSizes.daoV2} org-logo`}>The LAO</div>
  );
}
