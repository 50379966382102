import React, {useContext} from 'react';
import {useSelector} from 'react-redux';

import {getOrgText} from '../../../../util/helpers';
import {MetaMaskRPCError, StoreState} from '../../../../util/types';
import {Vote} from '../../../../util/enums';
import {InternalNamesToMap} from '../../../../util/orgDomainMappings';
import {VoteContext} from '../MolochVoting';
import ErrorMessageWithDetails from '../../../../components/common/ErrorMessageWithDetails';
import FadeIn from '../../../../components/common/FadeIn';
import Loader from '../../../../components/feedback/Loader';
import SubmitVote from '../../../../components/contract/SubmitVote';
import TimesSVG from '../../../../assets/svg/TimesSVG';

import b from '../../../../assets/scss/modules/buttons.module.scss';
import s from '../../../../assets/scss/modules/voting.module.scss';
import sm from '../../../../assets/scss/modules/salemodal.module.scss';

type VoteConfirmViewsProps = {
  getCurrentVotes: () => void;
  initiateVote: Vote;
  onRequestClose: () => void;
  onProcessingVote: (tx: string) => void;
  preventModalClose: (shouldClose: boolean) => void;
};

/**
 * VoteConfirmViews
 *
 * A content view that "navigates" between voting steps.
 *
 * It navigates through 3 voting "views", a view to submit vote,
 * a view for processing, and a view noting success, or error.
 *
 * @param {VoteConfirmViewsProps} props
 */
export default function VoteConfirmViews(props: VoteConfirmViewsProps) {
  const {getCurrentVotes, initiateVote, onProcessingVote, onRequestClose} =
    props;

  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgLoaderTextColor = getText('OrgLoaderTextColor');

  const {molochProposal} = useContext(VoteContext);
  const {proposalIndex} = molochProposal;
  const yesNoVote = initiateVote === Vote.Yes ? 'yes' : 'no';

  function renderCloseButton() {
    switch (orgInternalName) {
      case InternalNamesToMap.nft:
      case InternalNamesToMap.liquidity:
        return (
          <span className="org-modal-close" onClick={onRequestClose}>
            <TimesSVG />
          </span>
        );
      case InternalNamesToMap.thelao:
      default:
        return (
          <div className={s['close']}>
            <button
              className={`${s['button-close']} ${b.underline}`}
              onClick={onRequestClose}>
              Close
            </button>
          </div>
        );
    }
  }

  return (
    <>
      <SubmitVote
        proposalIndex={proposalIndex}
        onComplete={getCurrentVotes}
        onProcess={onProcessingVote}
        render={({
          error,
          etherscanURL,
          isSubmitted,
          isSubmitting,
          isPromptOpen,
          openPrompt,
        }) => (
          <>
            {!isSubmitting && !isSubmitted && (
              <>
                <div className="titlebar">
                  <h2 className="titlebar__title org-titlebar__title">
                    Confirm Your Vote
                  </h2>
                </div>
                <p>
                  Your{' '}
                  <span
                    className={`${s['highlighted']} org-vote-confirm-highlighted ${s[yesNoVote]} org-vote-confirm-${s[yesNoVote]}`}>
                    '{yesNoVote}'
                  </span>{' '}
                  vote will be recorded to the blockchain. If more members vote
                  'Yes' for this proposal than 'No', it will pass.
                </p>

                <div className={sm['confirm-buttons']}>
                  <button
                    className={`${b['mini-modal-button']} org-mini-modal-button`}
                    disabled={isSubmitted || isSubmitting || isPromptOpen}
                    onClick={
                      isSubmitted || isSubmitting || isPromptOpen
                        ? () => {}
                        : () => openPrompt(initiateVote)
                    }>
                    {isPromptOpen || isSubmitting ? (
                      <Loader
                        text={
                          isPromptOpen ? 'Processing\u2026' : 'Sending\u2026'
                        }
                        textProps={{
                          style: {color: orgLoaderTextColor},
                        }}
                      />
                    ) : isSubmitted ? (
                      'Confirmed!'
                    ) : (
                      'Confirm'
                    )}
                  </button>
                </div>

                <p className="color-litesmoke org-vote-confirm-supplementary-text">
                  <small>You&rsquo;ll be prompted in your wallet.</small>
                </p>
              </>
            )}

            {/* PROCESSING VOTE */}
            {isSubmitting && (
              <FadeIn duration={150}>
                <div className="titlebar">
                  <h2 className="titlebar__title org-titlebar__title">
                    Please wait
                  </h2>
                </div>

                <p>
                  Your vote is being recorded. It'll take a couple of seconds.
                </p>
                <Loader
                  style={{
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                />

                {/* ETHERSCAN URL */}
                {etherscanURL && (
                  <p>
                    <small>
                      <a
                        href={etherscanURL}
                        rel="noopener noreferrer"
                        target="_blank">
                        (view progress)
                      </a>
                    </small>
                  </p>
                )}
              </FadeIn>
            )}

            {/* VOTE SUBMISSION SUCCESS */}
            {isSubmitted && (
              <FadeIn duration={150}>
                {renderCloseButton()}
                <div className="titlebar">
                  <h2 className="titlebar__title org-titlebar__title">
                    Success
                  </h2>
                </div>

                <p>Your vote has been successfully recorded!</p>
                <p>
                  <span
                    aria-label="Party face emoji."
                    role="img"
                    className={s['emoji']}>
                    🥳
                  </span>
                </p>
              </FadeIn>
            )}

            {/**
             * VOTE SUBMISSION FAILED
             * and error is not a wallet closed/rejected error.
             */}
            {error && (error as MetaMaskRPCError).code !== 4001 && (
              <ErrorMessageWithDetails
                error={error}
                renderText="There was a problem recording your vote."
              />
            )}
          </>
        )}
      />
    </>
  );
}
