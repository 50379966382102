import Web3 from 'web3';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {FetchStatus} from '../util/enums';
import {StoreState} from '../util/types';
import {useAbortController} from './useAbortController';
import {MONGO_BFF_URL} from '../util/config';

type UseTotalAmountContributedReturn = {
  amountContributed: number | undefined;
  amountContributedStatus: FetchStatus;
};
type Contribution = {
  _id: string;
  totalClaimedContributions: string | number;
  totalClaimedUnits: string | number;
  totalContributions: string | number;
  totalRagequitUnits: string | number;
  totalUnclaimedContributions: string | number;
  totalUnclaimedUnits: string | number;
  totalUnits: string | number;
  totalUnitsTransferFrom: string | number;
  totalUnitsTransferTo: string | number;
};

const {FULFILLED, PENDING, REJECTED, STANDBY} = FetchStatus;

/**
 * Returns the total amount of ETH, WETH contributed to the DAO Moloch contract and
 * multi-sig address recorded into the Mongo Cloud Database.
 */
export default function useTotalAmountContributedMongo(): UseTotalAmountContributedReturn {
  /**
   * Selectors
   */

  const daoAddress = useSelector(
    (s: StoreState) => s.org?.contractMolochAddress
  );

  /**
   * State
   */

  const [amountContributedStatus, setAmountContributedStatus] =
    useState<FetchStatus>(STANDBY);
  const [amountContributed, setAmountContributed] = useState<
    number | undefined
  >();

  /**
   * Our hooks
   */

  const {abortController, isMountedRef} = useAbortController();

  /**
   * Cached callbacks
   */

  const getAmountContributedCached = useCallback(getAmountContributed, [
    abortController?.signal,
    daoAddress,
    isMountedRef,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    if (amountContributed) return;

    getAmountContributedCached();
  }, [amountContributed, getAmountContributedCached]);

  // Cleanup async processes
  useEffect(() => {
    return function cleanup() {
      abortController?.abort();
    };
  }, [abortController]);

  /**
   * Functions
   */

  async function getAmountContributed() {
    try {
      if (!daoAddress) {
        return;
      }

      setAmountContributedStatus(PENDING);

      const ENDPOINT = `${MONGO_BFF_URL}/daos/contributions?daoAddress=${daoAddress}&groupByDAO=true`;
      const result = await fetch(ENDPOINT, {
        method: 'GET',
        headers: {
          Origin: window.location.origin,
        },
        signal: abortController?.signal,
      });

      if (result.ok) {
        const contribution: Contribution[] = await result.json();

        let totalETHContribution =
          contribution.length > 0
            ? Web3.utils.fromWei(
                Web3.utils.toBN(contribution[0].totalContributions)
              )
            : undefined;

        if (!isMountedRef.current) return;

        totalETHContribution &&
          setAmountContributed(Number(totalETHContribution));
        setAmountContributedStatus(FULFILLED);
      } else {
        throw new Error();
      }
    } catch (error) {
      if (!isMountedRef.current) return;

      setAmountContributedStatus(REJECTED);
    }
  }

  return {
    amountContributed,
    amountContributedStatus,
  };
}
