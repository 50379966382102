import {useState} from 'react';

import {dontCloseWindowWarning} from '../../util/helpers';
import {StoreState, MetaMaskRPCError} from '../../util/types';
import {useSelector} from 'react-redux';
import {ETHERSCAN_URLS} from '../../util/config';
import {useETHGasPrice} from '../../hooks';

type EasyApplyProjectRenderProps = {
  error?: Error;
  etherscanURL: string;
  isPromptOpen: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  openPrompt: () => void;
};

type EasyApplyProjectProps = {
  onBeforeProcess?: () => void;
  onError?: (e: Error) => void;
  onProcess?: (txHash: string) => void;
  onProposalId?: (proposalId: number) => void;
  onComplete?: () => void;
  render: (p: EasyApplyProjectRenderProps) => React.ReactElement;
  /**
   * The ethereum address of the Moloch proposal applicant (the address which gets the ETH).
   */
  applicantAddress: string;
  /**
   * The amount of ETH in WEI requested for the Moloch `paymentRequested`
   */
  paymentRequestedWEI: string;
  /**
   * The proposal's `uuid` value from the database.
   */
  proposalUUID: string;
};

/**
 * EasyApplyProject
 *
 * Allows interaction with the EasyApply smart contract's `submitAndSponsorProjectProposal()`.
 * The contract allows us to merge 2 separate smart contract calls into one:
 *
 * 1. Moloch `submitProposal()`
 * 2. Moloch `sponsorProposal()`
 *
 * A render prop is used to allow for passing internal state
 * to parent/wrapping components.
 *
 * @see https://github.com/openlawteam/Lao-easy-apply
 *
 * @param {EasyApplyProjectProps} props
 * @returns Result of `render` prop.
 */
export default function EasyApplyProject(props: EasyApplyProjectProps) {
  const {
    onBeforeProcess,
    onError,
    onProcess,
    onProposalId,
    onComplete,
    render,
    applicantAddress,
    paymentRequestedWEI,
    proposalUUID,
  } = props;

  /**
   * State
   */

  const [error, setError] = useState<Error>();
  const [etherscanURL, setEtherscanURL] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /**
   * Selectors
   */

  const EasyApply = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.EasyApply
  );
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const MolochContract = useSelector(
    (s: StoreState) =>
      s.blockchain.contracts && s.blockchain.contracts.VentureMoloch
  );

  /**
   * External Hooks
   */

  const {average: gasPrice} = useETHGasPrice();

  /**
   * Functions
   */

  function handleError(error: Error) {
    setError(error);
    setIsSubmitted(false);
    setIsSubmitting(false);

    onError && onError(error);
  }

  async function listenForMolochSubmitProposalEvent(
    projectUUIDToFilterBy: string
  ) {
    return new Promise<number>((resolve, reject) => {
      if (!MolochContract) {
        reject(new Error('No Moloch contract was found.'));
        return;
      }

      const submitProposalEvent = MolochContract.instance.events
        .SubmitProposal()
        .on('data', (event: Record<string, any>) => {
          // EasyApply contract concatenates the Project::{uuid} in the `details`.
          // @todo Perhaps remove the `Project::`
          if (
            event.returnValues.details.toLowerCase().trim() ===
            `Project::${projectUUIDToFilterBy}`.toLowerCase()
          ) {
            submitProposalEvent.unsubscribe();
            resolve(event.returnValues.proposalId as number);
          }
        })
        .on('error', (error: Error) => {
          submitProposalEvent.unsubscribe();
          reject(error);
        });
    });
  }

  async function handleEasyApplyWalletPrompt() {
    // Activate "don't close window" warning
    const unsubscribeDontCloseWindow = dontCloseWindowWarning();

    try {
      if (!connectedAddress) {
        throw new Error('No connected wallet was found.');
      }

      if (!EasyApply) {
        throw new Error('No EasyApply contract found.');
      }

      /**
       * Run user callback `onBeforeProcess`
       * @note We `await` just in case the value is a Promise.
       */
      onBeforeProcess && (await onBeforeProcess());

      setError(undefined);
      setEtherscanURL('');
      setIsPromptOpen(true);
      setIsSubmitted(false);
      setIsSubmitting(false);

      /**
       * sendTransaction
       *
       * @see https://web3js.readthedocs.io/en/v1.2.4/web3-eth.html#sendtransaction
       */
      await EasyApply.instance.methods
        .submitAndSponsorProjectProposal(
          paymentRequestedWEI,
          applicantAddress,
          `Project::${proposalUUID}`
        )
        .send({
          from: connectedAddress,
          // set proposed gas price
          ...(gasPrice ? {gasPrice} : null),
        })
        .on('transactionHash', async (txHash: string) => {
          setIsPromptOpen(false);
          setIsSubmitting(true);
          setEtherscanURL(`${ETHERSCAN_URLS[chainId]}/tx/${txHash}`);

          // Maybe call user `onProcess` and handle any `Error`.
          try {
            onProcess && onProcess(txHash);

            const proposalId = await listenForMolochSubmitProposalEvent(
              proposalUUID
            );
            onProposalId && onProposalId(proposalId);
          } catch (error) {
            handleError(error);
          }
        })
        .on('error', (error: Error | MetaMaskRPCError) => {
          handleError(error);

          // if user closed modal (MetaMask error code 4001)
          // or via WalletConnect, which only provides a message and no code
          setIsPromptOpen(false);

          unsubscribeDontCloseWindow();
        });

      onComplete && onComplete();

      // Tx `receipt` resolved; tx went through.
      setIsSubmitted(true);
      setIsSubmitting(false);

      unsubscribeDontCloseWindow();
    } catch (error) {
      setIsPromptOpen(false);

      handleError(error);

      unsubscribeDontCloseWindow();
    }
  }

  return render({
    error,
    etherscanURL,
    isPromptOpen,
    isSubmitted,
    isSubmitting,
    openPrompt: handleEasyApplyWalletPrompt,
  });
}
