import {useEffect, useState, useCallback} from 'react';

import {SidePocketProposal} from '../util/types';
import {useBackendURL, useCounter} from './';
import {FetchStatus} from '../util/enums';

type UseSidePocketProposalState = {
  sidePocketProposal: SidePocketProposal | undefined;
  sidePocketProposalStatus: FetchStatus;
  refetchSidePocket: () => void;
};

/**
 * useSidePocketProposal
 *
 * Fetches a proposal entry from the database and returns it as state.
 */
export default function useSidePocketProposal(
  id: number
): UseSidePocketProposalState {
  const [sidePocketProposal, setSidePocketProposal] =
    useState<SidePocketProposal>();
  const [sidePocketProposalStatus, setSidePocketProposalStatus] =
    useState<FetchStatus>(FetchStatus.STANDBY);

  /**
   * External hooks
   */

  const backendURL = useBackendURL();
  // Send dispatch to consumer as a callback that we can "re-run" the fetchSidePocketFromDBCached effect.
  const [updateCounter, dispatchCounter] = useCounter();

  /**
   * Callbacks
   */
  const fetchSidePocketFromDBCached = useCallback(fetchSidePocketFromDB, [
    id,
    backendURL,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    fetchSidePocketFromDBCached();
  }, [fetchSidePocketFromDBCached, updateCounter]);

  /**
   * Functions
   */

  async function fetchSidePocketFromDB() {
    try {
      if (!backendURL) return;

      setSidePocketProposalStatus(FetchStatus.PENDING);

      const response = await fetch(`${backendURL}/sidepocket/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(
          'Something went wrong while getting the sidepocket proposal.'
        );
      }

      setSidePocketProposalStatus(FetchStatus.FULFILLED);

      const sidepocket: SidePocketProposal = await response.json();

      setSidePocketProposal(sidepocket);
    } catch (error) {
      setSidePocketProposalStatus(FetchStatus.REJECTED);
      setSidePocketProposal(undefined);
    }
  }

  function refetchSidePocket() {
    dispatchCounter({type: 'increment'});
  }

  return {sidePocketProposal, sidePocketProposalStatus, refetchSidePocket};
}
