import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
// Web3 types
import {AbiItem} from 'web3-utils/types';

import {FetchStatus} from '../util/enums';
import {StoreState} from '../util/types';
import {useCounter} from '.';
import ERC20 from '../truffle-contracts/ERC20.json';

/**
 * Gets fresh balances. Can cause a loop if not used carefully.
 */
type GetBalancesCallback = () => void;

export type TokenBalance = {
  address: string;
  balance: string;
  decimals: string;
  name: string;
  symbol: string;
};

export type TokenBalancesData = {
  molochTokenBalances: TokenBalance[];
  molochTokenBalancesStatus: FetchStatus;
  refetchTokenBalances: GetBalancesCallback;
};

/**
 * useTokenBalances
 *
 * Gets Moloch token balances for an address.
 * You may pass in any addresses for `approvedTokens`, but it's best to
 * use Moloch's approved tokens (i.e. `useApprovedTokens()`).
 *
 * @param {string} ethereumAddress of user to get balances for in Moloch
 * @param {string[]} approvedTokens i.e. `{ approvedTokens } = useApprovedTokens()` value.
 * @returns {TokenBalancesData}
 */
export default function useMolochTokenBalances(
  ethereumAddress: string = '',
  approvedTokens: string[]
): TokenBalancesData {
  /**
   * State
   */

  // Used internally only
  const [molochTokenBalances, setMolochTokenBalances] = useState<
    TokenBalance[]
  >([]);
  const [molochTokenBalancesWithERCData, setMolochTokenBalancesWithERCData] =
    useState<TokenBalance[]>([]);
  const [molochTokenBalancesStatus, setMolochTokenBalancesStatus] =
    useState<FetchStatus>(FetchStatus.STANDBY);

  /**
   * Selectors
   */

  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );
  const VentureMoloch = useSelector(
    (s: StoreState) =>
      s.blockchain.contracts && s.blockchain.contracts.VentureMoloch
  );
  const web3Instance = useSelector(
    (s: StoreState) => s.blockchain.web3Instance
  );

  /**
   * Custom hooks
   */

  // Send dispatch to consumer as a callback that can "re-run" this hook.
  const [getBalancesCount, dispatch] = useCounter();

  // Get user token balance in Moloch contract per token address
  useEffect(() => {
    if (
      !approvedTokens.length ||
      !VentureMoloch ||
      !connectedAddress ||
      !web3Instance
    ) {
      return;
    }

    setMolochTokenBalancesStatus(FetchStatus.PENDING);

    const tokenBalancePromises = approvedTokens.map(async (a) => {
      try {
        const balance: string = await VentureMoloch.instance.methods
          .getUserTokenBalance(ethereumAddress || connectedAddress, a)
          .call({from: connectedAddress});

        return {address: a, balance, name: '', symbol: '', decimals: ''};
      } catch (error) {
        throw error;
      }
    });

    Promise.all(tokenBalancePromises)
      .then((b) => {
        const tokenWithBalances = b.filter((tb) => tb.balance);

        if (!tokenWithBalances.length) {
          setMolochTokenBalancesStatus(FetchStatus.FULFILLED);

          return;
        }

        setMolochTokenBalances(b.filter((tb) => tb.balance));
      })
      .catch(() => {
        setMolochTokenBalances([]);
        setMolochTokenBalancesStatus(FetchStatus.REJECTED);
      });
  }, [
    ethereumAddress,
    approvedTokens,
    connectedAddress,
    VentureMoloch,
    web3Instance,
    getBalancesCount,
  ]);

  // Get token `name`, `symbol`, `decimals`
  // @note ERC20-compliant contracts are not required to include `name` and `symbol`
  useEffect(() => {
    if (
      !molochTokenBalances.length ||
      !ERC20 ||
      !connectedAddress ||
      !VentureMoloch ||
      !web3Instance
    ) {
      return;
    }

    const tokenBalancePromises = molochTokenBalances.map(async (t) => {
      try {
        const erc20 = new web3Instance.eth.Contract(
          ERC20 as AbiItem[],
          t.address
        );

        const name: string =
          (await erc20.methods.name().call({from: connectedAddress})) || '';
        const symbol: string =
          (await erc20.methods.symbol().call({from: connectedAddress})) || '';
        const decimals: string =
          (await erc20.methods.decimals().call({from: connectedAddress})) || '';

        return {...t, name, symbol, decimals};
      } catch (error) {
        throw error;
      }
    });

    Promise.all(tokenBalancePromises)
      .then((t) => {
        setMolochTokenBalancesWithERCData(t);
        setMolochTokenBalancesStatus(FetchStatus.FULFILLED);
      })
      .catch(() => {
        setMolochTokenBalances([]);
        setMolochTokenBalancesStatus(FetchStatus.REJECTED);
      });
  }, [molochTokenBalances, web3Instance, connectedAddress, VentureMoloch]);

  function incrementGetBalancesCount() {
    dispatch({type: 'increment'});
  }

  return {
    refetchTokenBalances: incrementGetBalancesCount,
    molochTokenBalances: molochTokenBalancesWithERCData,
    molochTokenBalancesStatus,
  };
}
