import {useSelector} from 'react-redux';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import {Link} from 'react-router-dom';

import {SnapshotProposal, SnapshotCombinedVotesResponse} from './types';
import {chooseRandom, numberRangeArray} from '../../util/helpers';
import {StoreState} from '../../util/types';
import {InternalNamesToMap} from '../../util/orgDomainMappings';
import {SnapshotProposalSubType} from './enums';
import SnapshotVotingStatus from './SnapshotVotingStatus';
import VoteIndicatorBadge from '../../components/ui/VoteIndicatorBadge';

import s from '../../assets/scss/modules/proposalcard.module.scss';

type SnapshotProposalCardProps = {
  buttonText?: string;
  name?: string;
  onClick: (proposalId: string) => void;
  onVoteEnded?: (didPass: boolean, proposalId: string) => void;
  pathToProposal: string;
  privateView?: boolean;
  renderIcon: () => JSX.Element;
  shouldRenderVerbose?: boolean;
  snapshotProposal?: SnapshotProposal;
  snapshotVotes?: SnapshotCombinedVotesResponse;
  subType?: SnapshotProposalSubType;
};

/**
 * Shows a preview of a Snapshot proposal's details.
 *
 * @param {SnapshotProposalCardProps} props
 */
export default function SnapshotProposalCard(props: SnapshotProposalCardProps) {
  const {
    buttonText,
    name,
    renderIcon,
    privateView,
    shouldRenderVerbose = true,
    snapshotProposal,
    snapshotVotes,
    subType,
  } = props;

  /**
   * Selectors
   */

  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  /**
   * Variables
   */

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
  const privateViewAdditionalClass = privateView
    ? s['proposal-card--private']
    : '';
  const customAdditionalClasses =
    orgInternalName === InternalNamesToMap.metaverse
      ? `org-random-background org-random-background--${getRandomNumber()} org-random-icon org-random-icon--${getRandomNumber()}`
      : '';

  /**
   * Functions
   */

  function getRandomNumber() {
    return chooseRandom<number>(numberRangeArray(13, 0));
  }

  /**
   * getName
   *
   * Safely gets a name for the card.
   *
   * @returns {string}
   */
  function getName(): string {
    // If we're reading from a Snapshot Hub proposal response
    if (snapshotProposal) {
      return snapshotProposal.msg.payload.name;
    }

    return name || '';
  }

  /**
   * getReactKey
   *
   * Safely gets a `React.Key` for the card.
   *
   * @returns {SnapshotProposalCardProps['key']}
   */
  function getReactKey(): {key: string} | null {
    // If we're reading from a Snapshot Hub proposal response
    if (snapshotProposal) {
      return {key: snapshotProposal.authorIpfsHash};
    }

    return null;
  }

  function handleClick() {
    const {authorIpfsHash = ''} = snapshotProposal || {};

    /**
     * If we're reading from a Snapshot Hub proposal response
     *
     * @note Governance Snapshot uses the Snapshot hash in its path.
     */
    props.onClick(authorIpfsHash);
  }

  function handleVoteEnded(didPass: boolean, proposalId: string) {
    // Run callback when vote ends
    props.onVoteEnded && props.onVoteEnded(didPass, proposalId);
  }

  function renderCard() {
    return (
      <div
        className={`${s['proposal-card']} ${privateViewAdditionalClass} ${customAdditionalClasses} org-proposal-card`}
        onClick={handleClick}
        {...getReactKey()}>
        {/* VOTE INDICATOR BADGE */}
        {!privateView &&
          snapshotProposal &&
          subType === SnapshotProposalSubType.Governance && (
            <VoteIndicatorBadge subType={subType} align="right" />
          )}

        {/* HEADER */}
        <div className={`${s['proposal-icon']} org-proposal-icon`}>
          {renderIcon()}
        </div>

        {/* TITLE */}
        <h3 className={`${s['proposal-title']} org-proposal-title`}>
          {privateView ? (
            '(Private)'
          ) : (
            <ResponsiveEllipsis
              text={getName()}
              maxLine={1}
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          )}
        </h3>

        {/* SHOW THE VOTING PROGRESS BAR & BUTTON. DEFAULTS to `true` */}
        {shouldRenderVerbose && (
          <>
            {/* STATUS */}
            <SnapshotVotingStatus
              snapshotProposal={snapshotProposal}
              onVoteEnded={handleVoteEnded}
              votes={snapshotVotes}
            />

            {/* BUTTON (no click handler) */}
            <button
              className={`${s['proposal-button']} org-proposal-button`}
              disabled={privateView}>
              {buttonText || 'View'}
            </button>
          </>
        )}
      </div>
    );
  }

  if (privateView) {
    return renderCard();
  }

  return (
    <Link
      className={'proposal-card--link org-proposal-card--link'}
      to={props.pathToProposal}>
      {renderCard()}
    </Link>
  );
}
