import React, {useState, useEffect} from 'react';

import {FetchStatus, Vote} from '../../util/enums';
import {formatEthereumAddress} from '../../util/helpers';
import {SnapshotCombinedVotesResponse, SnapshotProposal} from './types';
import {SnapshotVote} from './enums';
import {StoreState, MetaMaskRPCError} from '../../util/types';
import {useSelector} from 'react-redux';
import Delegation from '../../pages/members/Delegation';
import ErrorMessageWithDetails from '../common/ErrorMessageWithDetails';
import Loader from '../feedback/Loader';
import ProposalActionWhyDisabled from '../../pages/proposals/ProposalDisabledReason';
import SnapshotVotingStatus from './SnapshotVotingStatus';
import useCreateSnapshotVote from './useCreateSnapshotVote';

import v from '../../assets/scss/modules/voting.module.scss';

type SnapshotVotingProps = {
  onRefetchVotes?: () => void;
  snapshotProposal: SnapshotProposal;
  votes: SnapshotCombinedVotesResponse;
};

/**
 * SnapshotVoting
 *
 * Displays the status of the proposal's vote.
 * Displays "Yes", "No" voting buttons in order to cast a vote.
 *
 * @param props
 */
export default function SnapshotVoting(props: SnapshotVotingProps) {
  const {onRefetchVotes, snapshotProposal, votes} = props;
  const {
    msg: {
      payload: {end: votingEndSeconds, choices},
    },
  } = snapshotProposal;
  const {snapshotVotes} = votes;

  /**
   * State
   */

  const [memberPreviousVote, setMemberPreviousVote] = useState<string>();
  // Defaults to `true`
  const [canRefetchFlag, setCanRefetchFlag] = useState<boolean>(true);
  /**
   * Is only set as a result of an active check in <SnapshotVotingStatus />
   * while voting is still open. Defaults to `false`.
   */
  const [hasVoteEnded, setHasVoteEnded] = useState<boolean>(false);

  /**
   * Selectors
   */

  const connectedMember = useSelector((s: StoreState) => s.connectedMember);
  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.connectedAddress
  );

  /**
   * Our hooks
   */

  const {
    createSnapshotVote,
    snapshotSubmitIsInProcessOrDone,
    snapshotVoteChosen,
    snapshotVoteSignStatus,
    snapshotVoteSubmitError,
    snapshotVoteSubmitStatus,
  } = useCreateSnapshotVote(snapshotProposal);

  /**
   * Variables
   */

  const hasVoted =
    memberPreviousVote !== undefined ||
    snapshotVoteSubmitStatus === FetchStatus.FULFILLED;
  const noChosen =
    memberPreviousVote === SnapshotVote.No ||
    snapshotVoteChosen === SnapshotVote.No;
  const yesChosen =
    memberPreviousVote === SnapshotVote.Yes ||
    snapshotVoteChosen === SnapshotVote.Yes;
  const noChosenClassName: string =
    noChosen && hasVoted
      ? `${v['selected']} org-selected ${v['voted']}`
      : noChosen
      ? `${v['selected']} org-selected`
      : '';
  const yesChosenClassName: string =
    yesChosen && hasVoted
      ? `${v['selected']} org-selected ${v['voted']}`
      : yesChosen
      ? `${v['selected']} org-selected`
      : '';

  const disabledReasonVotingEnded =
    hasVoteEnded || votingEndSeconds < Math.ceil(Date.now() / 1000)
      ? `The voting period has ended as of ${new Date(
          votingEndSeconds * 1000
        ).toUTCString()}`
      : '';

  const disabledReasonDelegateKey =
    connectedMember.isMemberActive &&
    connectedMember.delegateKey.toLowerCase() !== connectedAddress
      ? `Your key is delegated to ${formatEthereumAddress(
          connectedMember.delegateKey
        )}. You must use that address to vote.`
      : '';

  const isDelegatedMember =
    connectedMember.isMemberActive &&
    connectedMember.delegateKey.toLowerCase() !== connectedAddress;

  const delegationInfoText = isDelegatedMember
    ? `Your vote is currently delegated to ${formatEthereumAddress(
        connectedMember.delegateKey
      )}`
    : `If you're using a multisig wallet you'll need to delegate
    votes to that address`;

  const isInProcessOrDone = snapshotSubmitIsInProcessOrDone || hasVoted;

  /**
   * Effects
   */

  // Find the member's previous vote, if any.
  useEffect(() => {
    if (!connectedMember.memberAddress) return;

    const memberVoteEntry = Object.entries(snapshotVotes).filter(
      (p) =>
        (p[1].msg.payload.metadata
          ? (p[1].msg.payload.metadata.memberAddress || '').toLowerCase().trim()
          : '') === connectedMember.memberAddress.toLowerCase().trim()
    )[0];

    const memberVoteChoice = memberVoteEntry
      ? // @note The Snapshot API does not accept falsy choices like index `0`, so we need to minus 1.
        choices[memberVoteEntry[1].msg.payload.choice - 1]
      : undefined;

    setMemberPreviousVote(memberVoteChoice);
  }, [connectedMember.memberAddress, snapshotVotes, choices]);

  // Check to refetch votes after a member votes
  useEffect(() => {
    if (
      !onRefetchVotes ||
      !canRefetchFlag ||
      snapshotVoteSubmitStatus !== FetchStatus.FULFILLED
    ) {
      return;
    }

    onRefetchVotes();
    setCanRefetchFlag(false);
  }, [canRefetchFlag, snapshotVoteSubmitStatus, onRefetchVotes]);

  /**
   * Functions
   */

  /**
   * handleVoteEnded
   *
   * Run callback when vote ends.
   */
  function handleVoteEnded() {
    setHasVoteEnded(true);
  }

  return (
    <>
      <SnapshotVotingStatus
        onVoteEnded={handleVoteEnded}
        snapshotProposal={props.snapshotProposal}
        votes={votes}
      />

      <ProposalActionWhyDisabled
        otherReasons={[disabledReasonVotingEnded, disabledReasonDelegateKey]}
        render={({
          canShowDisabledReason,
          isDisabled,
          openWhyDisabledModal,
          WhyDisabledModal,
        }) => (
          <>
            <div
              className={v['voting-button-group']}
              key={'voting-button-group'}>
              {/* VOTE YES */}
              <button
                data-vote={Vote.Yes}
                onClick={
                  isInProcessOrDone || isDisabled
                    ? () => {}
                    : createSnapshotVote(SnapshotVote.Yes)
                }
                disabled={isInProcessOrDone || isDisabled}
                className={`${v['voting-button']} org-voting-button ${yesChosenClassName}`}>
                {yesChosen && snapshotVoteSignStatus === FetchStatus.PENDING ? (
                  <Loader text="Preparing&hellip;" />
                ) : yesChosen &&
                  snapshotVoteSubmitStatus === FetchStatus.PENDING ? (
                  <Loader text="Processing&hellip;" />
                ) : (hasVoted && yesChosen) ||
                  (yesChosen &&
                    snapshotVoteSubmitStatus === FetchStatus.FULFILLED) ? (
                  'Voted yes'
                ) : (
                  'Vote yes'
                )}
              </button>

              {/* VOTE NO */}
              <button
                data-vote={Vote.No}
                onClick={
                  isInProcessOrDone || isDisabled
                    ? () => {}
                    : createSnapshotVote(SnapshotVote.No)
                }
                disabled={isInProcessOrDone || isDisabled}
                className={`${v['voting-button']} org-voting-button ${noChosenClassName}`}>
                {noChosen && snapshotVoteSignStatus === FetchStatus.PENDING ? (
                  <Loader text="Preparing&hellip;" />
                ) : noChosen &&
                  snapshotVoteSubmitStatus === FetchStatus.PENDING ? (
                  <Loader text="Processing&hellip;" />
                ) : (hasVoted && noChosen) ||
                  (noChosen &&
                    snapshotVoteSubmitStatus === FetchStatus.FULFILLED) ? (
                  'Voted no'
                ) : (
                  'Vote no'
                )}
              </button>
            </div>

            {canShowDisabledReason && (
              <div style={{paddingBottom: '1em'}}>
                <span
                  className={v['why--tooltip']}
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    event.preventDefault();
                    openWhyDisabledModal();
                  }}>
                  Why is voting disabled?
                </span>
              </div>
            )}

            {/* SUBMIT ERROR */}
            {snapshotVoteSubmitError &&
              (snapshotVoteSubmitError as MetaMaskRPCError).code !== 4001 && (
                <div className="text-center">
                  <ErrorMessageWithDetails
                    error={snapshotVoteSubmitError}
                    renderText="Something went wrong while submitting your vote."
                  />
                </div>
              )}

            <WhyDisabledModal />

            {/* DELEGATION BUTTON */}
            {!isInProcessOrDone && !isDisabled && (
              <>
                <small className={v['delegate-info-small']}>
                  {delegationInfoText}
                </small>
                <Delegation />
              </>
            )}
          </>
        )}
      />
    </>
  );
}
