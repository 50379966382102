import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {useWeb3Modal} from '../components/web3/Web3ModalManager';

import {StoreState} from '../util/types';
import {RoleType} from '../util/enums';

/**
 * useIsAdmin
 *
 * Uses the connected address from the store to check admin access
 * @returns isAdmin: boolean
 */
export default function useIsAdmin() {
  const {connected} = useWeb3Modal();

  const userRole = useSelector((state: StoreState) => state.user.role);

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    setIsAdmin(userRole === RoleType.ADMIN && (connected ?? false));
  }, [connected, userRole]);

  return isAdmin;
}
