import React from 'react';

export default function EthereumSVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="118"
      height="189"
      viewBox="0 0 118 189"
      {...props}>
      <defs>
        <linearGradient id="ethereum-svg-a" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#8ECEF0"></stop>
          <stop offset="100%" stopColor="#D485EE"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="url(#ethereum-svg-a)" fillRule="nonzero">
          <path d="M58.937 0a3.933 3.933 0 00-3.48 2.23L.67 88.377a3.94 3.94 0 00-.407.776 3.941 3.941 0 00-.261 1.545c.004.136.014.264.03.392l.016.077c.032.195.078.388.138.576l.031.108c.043.13.091.25.146.369v.007c.056.116.117.229.184.339.067.118.138.226.215.33.038.047.076.093.116.139a3.935 3.935 0 00.415.453c.191.174.4.328.622.461l.007.008.062.03.008.008 54.563 31.2a3.932 3.932 0 004.855.014l54.518-31.175.085-.046a3.933 3.933 0 00.33-.216l.054-.046a3.937 3.937 0 00.253-.192l.046-.038c.083-.076.162-.156.238-.239a3.939 3.939 0 00.469-.614.108.108 0 00.015-.031 3.94 3.94 0 00.185-.315c.044-.099.085-.191.123-.285a3.942 3.942 0 00.138-.446 3.98 3.98 0 00.008-.015c.036-.142.059-.263.076-.384.017-.1.03-.2.039-.3v-.03a3.626 3.626 0 00-.008-.593 3.941 3.941 0 00-.03-.292 3.941 3.941 0 00-.07-.33 2.846 2.846 0 00-.115-.392 3.933 3.933 0 00-.038-.092 3.94 3.94 0 00-.108-.262 4.075 4.075 0 00-.223-.392l-.015-.03-.123-.185L62.524 2.206A3.933 3.933 0 0058.937 0zm55.155 106.302a3.932 3.932 0 00-1.982.523l-53.112 30.368-53.112-30.368a3.933 3.933 0 00-5.445 1.612 3.938 3.938 0 00.33 4.145L55.571 187a3.933 3.933 0 006.845.007l54.81-74.426a3.938 3.938 0 00-3.135-6.28z"></path>
        </g>
      </g>
    </svg>
  );
}
