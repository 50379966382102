import l from '../../assets/scss/modules/logo.module.scss';

interface LogoGamingProps {
  orgInternalName: string;
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoGaming(props: LogoGamingProps) {
  const classNameSizes = {
    readyplayerdao: props.size ? `${l[`readyplayerdao--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.readyplayerdao} ${classNameSizes.readyplayerdao}`}>
      <img
        alt="Ready Player Logo"
        src={`/orgs/${props.orgInternalName}/images/ready-player-logo.png`}
      />
    </div>
  );
}
