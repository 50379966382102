import React, {createContext, useCallback} from 'react';
import {useQuery} from '@apollo/react-hooks';

import HiThereLoader from '../../components/feedback/HiThereLoader';
import SyncSVG from '../../assets/svg/SyncSVG';

import {
  useKYCContributors,
  useKYCContributionWhitelist,
  useSidePocketProposals,
} from '../../hooks';
import ProcessingProposal, {
  ProcessingProposalHeader,
} from './proposals/ProcessingProposal';
import {MemberExists, MemberWhitelisted} from './Members';

import {
  MemberProposalData,
  SidePocketProposal,
  GetInvestmentProposalResponseMoloch,
} from '../../util/types';
import {VerifedKYCMember} from '../../hooks/useKYCContributors';
import {FetchStatus} from '../../util/enums';
import useInvestmentProposals from '../../hooks/useInvestmentProposals';

import {GET_VOTING_MEMBER_PROPOSALS} from '../../gql';

import s from '../../assets/scss/modules/admin.module.scss';

export type QueueContextValue = {
  moveQueueProposal: (returnValues: Record<string, any> | undefined) => void;
  kycMembers: Array<VerifedKYCMember>;
  sidePocketProposals: Array<SidePocketProposal>;
  projectProposals: Array<GetInvestmentProposalResponseMoloch>;
};

export const QueueContext = createContext<QueueContextValue>(
  {} as QueueContextValue
);

export default function ProcessingQueue() {
  /**
   * @todo As this gets information about all types of proposals,
   *   not only Membership proposals, we should consider
   *   making a new query, or a copy and renaming it.
   */
  const getVotingMembers = useQuery<MemberProposalData>(
    GET_VOTING_MEMBER_PROPOSALS
  );
  const {kycMembers} = useKYCContributors();
  const {investmentProposals} = useInvestmentProposals({sponsored: true});
  const {sidePocketProposals} = useSidePocketProposals();
  const {kycContributionWhitelist, kycContributionWhitelistStatus} =
    useKYCContributionWhitelist();

  const isMemberInWhitelistCallback = useCallback(isMemberInWhitelist, [
    kycContributionWhitelist,
    kycContributionWhitelistStatus,
  ]);

  /**
   * Variables
   */

  // @todo Once we add an API query filter for `target` we won't need this.
  const projectProposals = (
    investmentProposals as GetInvestmentProposalResponseMoloch[]
  ).filter((p) => p.proposalTarget === 'moloch');

  function isMemberInWhitelist(memberAddress: string): boolean {
    if (kycContributionWhitelistStatus === FetchStatus.FULFILLED) {
      const isWhitelisted = kycContributionWhitelist.find(
        (w) => w.ethereumAddress === memberAddress
      );

      return isWhitelisted ? true : false;
    }

    return false;
  }

  function isMemberVerified(applicant: string): boolean {
    const memberIsVerified = kycMembers.find(
      (item: any) => applicant === item.ethereumAddress
    );

    return (memberIsVerified && memberIsVerified.isVerified) ?? false;
  }

  function moveQueueProposal(returnValues: Record<string, any> | undefined) {
    if (!returnValues) return;

    getVotingMembers.refetch();
  }

  return (
    <QueueContext.Provider
      value={{
        moveQueueProposal,
        kycMembers,
        sidePocketProposals,
        projectProposals,
      }}>
      <section className={`grid--fluid ${s['admin']}`}>
        <p>
          <MemberExists /> = Member is verified
        </p>
        <p>
          <MemberWhitelisted /> = Member is whitelisted
        </p>
        {/** PROPOSALS IN PROGRESS ie, VOTING, PROCESSING */}

        <div style={{textAlign: 'center', margin: '4rem'}}>
          <span>Ready to be processed</span>
          <button
            className={s.sync}
            onClick={() => {
              getVotingMembers.refetch();
            }}
            disabled={getVotingMembers.loading}>
            <SyncSVG />
          </button>
        </div>

        {getVotingMembers.loading ? (
          <div style={{textAlign: 'center'}}>
            <div style={{width: '3rem', margin: '0 auto'}}>
              <HiThereLoader />
            </div>
            <small>{'Fetching voting/grace period projects\u2026'}</small>
          </div>
        ) : (
          <>
            <table key={'processing-table'} className={'processingtable'}>
              {ProcessingProposalHeader}
              <tbody>
                {getVotingMembers.data &&
                  getVotingMembers.data.proposals.map(
                    (item: Record<string, any>) => (
                      <ProcessingProposal
                        key={`processingproposal${item.proposalId}`}
                        isWhitelisted={isMemberInWhitelistCallback(
                          item.applicant
                        )}
                        isVerified={isMemberVerified(item.applicant)}
                        proposal={item}
                      />
                    )
                  )}
              </tbody>
            </table>
            {getVotingMembers.data &&
              getVotingMembers.data.proposals.length === 0 && (
                <p className="text-center color-yellow">
                  No proposals in queue
                </p>
              )}
            {getVotingMembers.error && <p>{getVotingMembers.error.message}</p>}
          </>
        )}
      </section>
    </QueueContext.Provider>
  );
}
