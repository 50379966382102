import React from 'react';

import SaleItem from './SaleItem';
import {getLeadingZeroNumberString} from '../../util/helpers';
import {Sale} from '../../util/types';

type SaleListProps = {
  sales: Sale[];
};

/**
 * SaleList
 *
 * Renders a list of sales
 *
 * @param {SaleListProps} props
 */
export default function SaleList(props: SaleListProps) {
  const {sales = []} = props;

  return (
    <>
      {sales.length > 0 ? (
        <ul>
          {sales.map((b, i) => (
            <SaleItem
              key={`${b.creationDate}-${b.id}`}
              sale={b}
              saleNumberLabel={getLeadingZeroNumberString(i + 1)}
            />
          ))}
        </ul>
      ) : null}
    </>
  );
}
