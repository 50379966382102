import React from 'react';
import Media from 'react-media';
import {useSelector} from 'react-redux';

import Blockie from '../../components/ethereum-ui/Blockie';
import {formatEthereumAddress, cleanDate} from '../../util/helpers';
import {Sale, StoreState} from '../../util/types';
import {SaleStatus} from '../../util/enums';

import s from '../../assets/scss/modules/sale.module.scss';

type SaleItemProps = {
  sale: Sale;
  saleNumberLabel: string;
};

/**
 * SaleItem
 *
 * Displays a sale row item.
 * If the sale is a user's own sale, it may be clicked.
 *
 * @param {SaleItemProps} props
 */
export default function SaleItem(props: SaleItemProps) {
  const {sale} = props;
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const isOwnSale =
    connectedAddress &&
    sale.ethereumAddress.toLowerCase() === connectedAddress.toLowerCase();
  const isPendingTransaction =
    sale.status === SaleStatus.PENDING ||
    sale.status === SaleStatus.PENDING_CANCELLATION;

  return (
    <li>
      <div
        className={`${s['sale-row']} ${
          isOwnSale ? s['current-user-sale'] : ''
        } ${isOwnSale ? s['current-user-sale--latest'] : ''} ${
          isPendingTransaction ? s['pending-user-sale'] : ''
        }`}>
        {/* DATE */}
        <div className={s['sale-row-date']}>
          {cleanDate(sale.creationDate, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }).replace(
            /([A-z]{1,}) ([0-9]{1,2}), ([0-9]{2})/,
            (match, p1, p2, p3) => `${p1.toUpperCase()} ${p2}, ${p3}`
          )}
        </div>

        {/* BLOCKIE & ETH ADDRESS */}
        <Media queries={{medium: '(max-width: 768px)'}}>
          {(matches) => (
            <span className={s['sale-row-identity']}>
              <>
                <div className={s['sale-row-identity-blockie']}>
                  <Blockie opts={{seed: sale.ethereumAddress}} />
                </div>

                {formatEthereumAddress(
                  sale.ethereumAddress,
                  matches.medium ? 5 : 15
                )}
              </>
            </span>
          )}
        </Media>

        {/* PERCENT of TOTAL SHARES */}
        <span
          className={
            s['sale-row-percent']
          }>{`${sale.percentOfTotalShares}%`}</span>

        {/* ETH AMOUNT */}
        <span className={s['sale-row-amount']}>{`${Number(sale.amount).toFixed(
          2
        )} eth`}</span>
      </div>
    </li>
  );
}
