import l from '../../assets/scss/modules/logo.module.scss';

interface LogoTestv2Props {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoTestv2(props: LogoTestv2Props) {
  const classNameSizes = {
    daoV2: props.size ? `${l[`daoV2--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.daoV2} ${classNameSizes.daoV2} org-logo`}>Test v2</div>
  );
}
