import {useEffect, useState, useCallback} from 'react';
import {useQuery} from '@apollo/react-hooks';

import {GET_GENERAL_MEMBERS, GET_SEEKING_MEMBERS} from '../gql';
import {useBackendURL, usePageVisibility} from './';
import {Member} from '../util/types';
import {GQL_QUERY_POLLING_INTERVAL} from '../util/config';
import {FetchStatus} from '../util/enums';

/**
 * useMembers
 *
 * @todo Refactor to include data from The Graph.
 *   Then all we would need from the database is anything
 *   we can't get from The Graph.
 */
export default function useMembers() {
  /**
   * GQL Query
   */
  const getGeneralMembers = useQuery<Record<string, any>>(GET_GENERAL_MEMBERS, {
    pollInterval: GQL_QUERY_POLLING_INTERVAL,
  });
  const getSeekingMembers = useQuery<Record<string, any>>(GET_SEEKING_MEMBERS, {
    pollInterval: GQL_QUERY_POLLING_INTERVAL,
  });

  const [members, setMembers] = useState<Member[]>([]);
  const [membersStatus, setMembersStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );
  const [molochGQLMembers, setMolochGQLMembers] =
    useState<Record<string, any>>();

  const backendURL = useBackendURL();
  const isPageVisible = usePageVisibility();

  const fetchMembersFromDBCached = useCallback(fetchMembersFromDB, [
    backendURL,
  ]);

  useEffect(() => {
    if (isPageVisible) {
      getGeneralMembers.startPolling(GQL_QUERY_POLLING_INTERVAL);
      getSeekingMembers.startPolling(GQL_QUERY_POLLING_INTERVAL);
    } else {
      getGeneralMembers.stopPolling();
      getSeekingMembers.stopPolling();
    }
  }, [isPageVisible, getGeneralMembers, getSeekingMembers]);

  useEffect(() => {
    fetchMembersFromDBCached();
  }, [fetchMembersFromDBCached]);

  useEffect(() => {
    try {
      let generalMembers = {};
      let seekingMembers = {};

      if (!getGeneralMembers.loading && getGeneralMembers.data) {
        generalMembers = getGeneralMembers.data as Record<string, any>;
      }

      if (!getSeekingMembers.loading && getSeekingMembers.data) {
        seekingMembers = getSeekingMembers.data as Record<string, any>;
      }

      setMolochGQLMembers({
        ...generalMembers,
        ...seekingMembers,
      });
    } catch (error) {
      setMolochGQLMembers(undefined);
    }
  }, [getGeneralMembers, getSeekingMembers]);

  async function fetchMembersFromDB() {
    try {
      if (!backendURL) return;

      setMembersStatus(FetchStatus.PENDING);

      const response = await fetch(`${backendURL}/members`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Something went wrong while getting the members.');
      }

      setMembersStatus(FetchStatus.FULFILLED);

      const members = await response.json();

      setMembers(members);
    } catch (error) {
      setMembersStatus(FetchStatus.REJECTED);
      setMembers([]);
    }
  }

  return {members, membersStatus, molochGQLMembers};
}
