import {NavLink} from 'react-router-dom';
import {RouteComponentProps, withRouter} from 'react-router';
import {Transition} from 'react-transition-group';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import Media from 'react-media';
import ReactModal from 'react-modal';

import {getOrgText, isTributeOrgName} from '../util/helpers';
import {InternalNamesToMap} from '../util/orgDomainMappings';
import {ModalLogo} from './logo';
import {StoreState} from '../util/types';
import {useIsAdmin} from '../hooks';
import HamburgerSVG from '../assets/svg/HamburgerSVG';
import TimesSVG from '../assets/svg/TimesSVG';
import Web3ModalButton from './web3/Web3ModalButton';

import b from '../assets/scss/modules/buttons.module.scss';
import s from '../assets/scss/modules/nav.module.scss';
import m from '../assets/scss/modules/modal.module.scss';

type NavProps = RouteComponentProps<{location: any}>;

// see: http://reactcommunity.org/react-transition-group/transition
const duration = 200;

const defaultStyle = {
  transition: `0.1s`,
};

const transitionOpeningStyles: Record<string, any> = {
  entering: {right: '-300px'},
  entered: {right: 0},
  exiting: {right: 0, opacity: 0},
  exited: {right: '-300px', opacity: 0},
};

const transitionClosingStyles: Record<string, any> = {
  entering: {right: 0, opacity: 1},
  entered: {right: '-300px', opacity: 1},
  exiting: {right: '-300px', opacity: 1},
  exited: {right: 0, opacity: 1},
};

const isLive = process.env.REACT_APP_LAUNCH_LIVE === 'on';
const HIDE_NAV_BAR_ROUTES_WHITELIST = [
  '/',
  '/verify',
  '/verify/non-us',
  '/verify-thanks',
  '/contribute',
];
const PROJECTS_LINK_ROUTES_WHITELIST = ['/', '/contribute'];

function Nav(props: NavProps) {
  const isAdmin = useIsAdmin();
  const connectedMember = useSelector((s: StoreState) => s.connectedMember);
  const isMember = connectedMember && connectedMember.isMemberActive;
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const orgFeatures = useSelector((s: StoreState) => s.org && s.org.features);
  const orgDocsURL =
    useSelector((s: StoreState) => s.org && s.org.docsURL) || '';
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  const getText = getOrgText(orgText);
  const orgContributeLinkEmoji = getText('OrgContributeLinkEmoji');
  const orgApplyLinkEmoji = getText('OrgApplyLinkEmoji');
  const orgApplyLinkText = getText('OrgApplyLinkText');
  const orgSplashTopNavButtonText = getText('OrgSplashTopNavButtonText');
  const orgSplashTopNavButtonEmoji = getText('OrgSplashTopNavButtonEmoji');
  const orgSplashTopNavButtonLink = getText('OrgSplashTopNavButtonLink');
  const orgMentorsText = getText('OrgMentorsText');
  const orgInvestmentsText = getText('OrgInvestmentsText');
  const orgProjectsText = getText('OrgProjectsText');
  const showApplyLinkFeatureFlag = orgFeatures && orgFeatures.showApplyLink;
  const showGovernanceLinkFeatureFlag =
    orgFeatures && orgFeatures.showGovernanceLink;
  const showCollectionLinkFeatureFlag =
    orgFeatures && orgFeatures.showCollectionLink;
  const showContributeLinkFeatureFlag =
    orgFeatures && orgFeatures.showContributeLink;
  const showProposalsLinkFeatureFlag =
    orgFeatures && orgFeatures.showProposalsLink;
  const showHelpLinkFeatureFlag = orgFeatures && orgFeatures.showHelpLink;

  const showMentors = orgFeatures && orgFeatures.showMentors;

  const [shouldShowMenuModal, setShouldShowMenuModal] = useState(false);
  const [transitionStyles, setTransitionStyles] = useState<Record<string, any>>(
    transitionOpeningStyles
  );
  const {location} = props;

  function handleMenuModalClose(close: boolean) {
    // delay transition for closing
    if (close) {
      setShouldShowMenuModal(close);
      setTransitionStyles(transitionOpeningStyles);
    } else {
      setTransitionStyles(transitionClosingStyles);
      const closeMenu = setTimeout(() => setShouldShowMenuModal(close), 500);
      return () => clearTimeout(closeMenu);
    }
  }

  return (
    <>
      <Media query="(max-width: 62em)">
        {(matches: boolean) => (
          <div className={s['menu-container']}>
            <div
              className={`${s['menu-items']} org-menu-items ${
                matches ? s['is-active'] : ''
              } 
            ${
              PROJECTS_LINK_ROUTES_WHITELIST.includes(location.pathname)
                ? s['with-projects-item']
                : ''
            }
            `}>
              {PROJECTS_LINK_ROUTES_WHITELIST.includes(location.pathname) && (
                <nav role="navigation" id="navigation">
                  <ul className={`${s['menu']}`}>
                    {orgSplashTopNavButtonText && orgSplashTopNavButtonLink && (
                      <li tabIndex={0}>
                        <a
                          className={
                            !matches
                              ? `${b['secondary']} org-secondary-button ${s['projects-button']} org-projects-button`
                              : ''
                          }
                          href={orgSplashTopNavButtonLink}>
                          {orgSplashTopNavButtonText}
                          {orgSplashTopNavButtonEmoji && (
                            <span
                              className={s['projects-button-icon']}
                              aria-label="Unicorn emoji"
                              role="img">
                              {orgSplashTopNavButtonEmoji}
                            </span>
                          )}
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              )}
              {/* NAV */}
              {isLive &&
                !HIDE_NAV_BAR_ROUTES_WHITELIST.includes(location.pathname) && (
                  <nav role="navigation" id="navigation">
                    <ul className={`${s['menu']}`}>
                      {showCollectionLinkFeatureFlag && (
                        <li tabIndex={0}>
                          <NavLink
                            exact
                            to={`/${orgInvestmentsText.toLowerCase()}`}>
                            <span>{orgInvestmentsText}</span>
                          </NavLink>
                        </li>
                      )}
                      {showGovernanceLinkFeatureFlag && (
                        <li tabIndex={0}>
                          <NavLink to="/governance-proposals">
                            <span>Governance</span>
                          </NavLink>
                        </li>
                      )}
                      {showProposalsLinkFeatureFlag && (
                        <li tabIndex={0}>
                          <NavLink exact to="/proposals">
                            <span>{orgProjectsText}</span>
                          </NavLink>
                        </li>
                      )}
                      {showApplyLinkFeatureFlag && (
                        <li tabIndex={0}>
                          <NavLink to="/apply">
                            <span>{orgApplyLinkText}</span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </nav>
                )}
              <div tabIndex={0} className={`${s['menu-modal-wrapper']}`}>
                <div
                  className={`${s['menu-modal-hamburger']} org-menu-modal-hamburger`}
                  aria-label="Menu"
                  aria-controls="navigation"
                  onClick={(event) => {
                    event.preventDefault();
                    handleMenuModalClose(true);
                  }}>
                  <HamburgerSVG />
                </div>
              </div>
              <div className={`${s['get-connected-button']}`}>
                <Web3ModalButton showWalletETHBadge />
              </div>
            </div>
          </div>
        )}
      </Media>
      {/** MODAL MENU */}
      <ReactModal
        ariaHideApp={false}
        className={`${m['modal-menu-content']}`}
        isOpen={shouldShowMenuModal}
        onRequestClose={() => {
          handleMenuModalClose(false);
        }}
        overlayClassName={`${m['modal-menu-overlay']} org-modal-menu-overlay`}
        role="dialog"
        style={
          {overlay: {zIndex: '99'}, content: {maxWidth: '32.5rem'}} as any
        }>
        <Transition appear in={shouldShowMenuModal} timeout={duration}>
          {(transition) => (
            <nav role="navigation" id="navigation">
              <div
                style={{
                  ...defaultStyle,
                  ...transitionStyles[transition],
                }}
                className={`${m['modal-menu-container']} org-modal-menu-container`}>
                <button
                  className={`${m['modal-menu-close']} org-modal-menu-close`}
                  onClick={(event) => {
                    event.preventDefault();
                    handleMenuModalClose(false);
                  }}>
                  <TimesSVG />
                </button>

                <ModalLogo />

                <div
                  className={`${s['modal-menu-connected-button']} org-modal-menu-connected-button`}>
                  <Web3ModalButton showWalletETHBadge />
                </div>
                <ul
                  className={`${s['menu']} ${m['modal-menu']} org-modal-menu`}>
                  {showContributeLinkFeatureFlag && (
                    <li
                      onClick={() => {
                        handleMenuModalClose(false);
                      }}>
                      <NavLink className="contribute" to="/contribute">
                        <span aria-label="Contribute link emoji" role="img">
                          {orgContributeLinkEmoji} Contribute
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {showApplyLinkFeatureFlag && (
                    <li
                      onClick={() => {
                        handleMenuModalClose(false);
                      }}>
                      <NavLink className="contribute" to="/apply">
                        <span aria-label="Apply link emoji" role="img">
                          {orgApplyLinkEmoji} {orgApplyLinkText}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {showCollectionLinkFeatureFlag && (
                    <li
                      onClick={() => {
                        handleMenuModalClose(false);
                      }}>
                      <NavLink
                        exact
                        to={`/${orgInvestmentsText.toLowerCase()}`}>
                        <span>{orgInvestmentsText}</span>
                      </NavLink>
                    </li>
                  )}
                  {showGovernanceLinkFeatureFlag && (
                    <li
                      onClick={() => {
                        handleMenuModalClose(false);
                      }}>
                      <NavLink to="/governance-proposals">
                        <span>Governance</span>
                      </NavLink>
                    </li>
                  )}
                  {showProposalsLinkFeatureFlag && (
                    <li
                      onClick={() => {
                        handleMenuModalClose(false);
                      }}>
                      <NavLink exact to="/proposals">
                        <span>{orgProjectsText}</span>
                      </NavLink>
                    </li>
                  )}
                  {orgInternalName === InternalNamesToMap.thelao && (
                    <li
                      onClick={() => {
                        handleMenuModalClose(false);
                      }}>
                      <a
                        href="https://scout.thelao.io/"
                        rel="noopener noreferrer"
                        target="_blank">
                        <span>Scout</span>
                      </a>
                    </li>
                  )}
                  {showMentors && (
                    <li
                      onClick={() => {
                        handleMenuModalClose(false);
                      }}>
                      <NavLink to={`/${orgMentorsText.toLowerCase()}`}>
                        <span>{orgMentorsText}</span>
                      </NavLink>
                    </li>
                  )}
                  {showHelpLinkFeatureFlag && (
                    <li
                      onClick={() => {
                        handleMenuModalClose(false);
                      }}>
                      <a
                        href={orgDocsURL}
                        rel="noopener noreferrer"
                        target="_blank">
                        <span>Help</span>
                      </a>
                    </li>
                  )}
                  {isAdmin && (
                    <li
                      onClick={() => {
                        handleMenuModalClose(false);
                      }}
                      className={`${m['admin-link']} org-admin-link`}>
                      <NavLink
                        to={
                          isTributeOrgName(
                            orgInternalName as InternalNamesToMap
                          )
                            ? '/'
                            : '/admin'
                        }>
                        <span>Admin</span>
                      </NavLink>
                    </li>
                  )}
                  {isMember &&
                    !isTributeOrgName(
                      orgInternalName as InternalNamesToMap
                    ) && (
                      <li
                        onClick={() => {
                          handleMenuModalClose(false);
                        }}
                        className={`${m['profile-link']} org-profile-link`}>
                        <NavLink
                          to={`/members/${connectedMember.memberAddress}`}>
                          {orgInternalName === InternalNamesToMap.thelao ? (
                            <span role="img" aria-label="Space invader emoji">
                              👾 Profile
                            </span>
                          ) : (
                            <span>
                              <img
                                alt="Profile link icon"
                                src={`/orgs/${orgInternalName}/images/profile-link-icon.svg`}
                              />{' '}
                              Profile
                            </span>
                          )}
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </nav>
          )}
        </Transition>
      </ReactModal>
    </>
  );
}

export default withRouter(Nav);
