import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import BigNumber from 'bignumber.js';
// Web3 types
import {AbiItem} from 'web3-utils/types';
import {FetchStatus} from '../../../util/enums';
import {StoreState, MemberMolochResponse} from '../../../util/types';
import EasyApply from '../../../truffle-contracts/EasyApply.json';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';

import {
  CheckStatus,
  HealthcheckContext,
  HealthcheckContextValue,
} from './index';

type EasyApplyCompleteName =
  | 'isCompleteLAO'
  | 'isCompleteWETH'
  | 'isCompleteChunkSize'
  | 'isCompleteChunksPrice'
  | 'isCompleteLAOMember';

/**
 * @note EasyApplyContract Checks
 * - Check LAO addr is the correct address for org
 * - Check WETH addr is the correct address for org
 * - Check sale settings against easyApply (shares per eth, share price)
 * - Check easy apply is member of the org LAO
 */
export default function EasyApplyContract() {
  const {processReadyMap /*setProcessReadyMapState*/} =
    useContext<HealthcheckContextValue>(HealthcheckContext);

  const contractEasyApplyAddress = useSelector(
    (s: StoreState) => s.org && s.org.contractEasyApplyAddress
  );
  const contractMolochAddress = useSelector(
    (s: StoreState) => s.org && s.org.contractMolochAddress
  );
  const saleSettings = useSelector(
    (s: StoreState) => s.org && s.org.saleSettings
  );
  const web3Instance = useSelector(
    (s: StoreState) => s.blockchain.web3Instance
  );
  const VentureMoloch = useSelector(
    (s: StoreState) =>
      s.blockchain.contracts && s.blockchain.contracts.VentureMoloch
  );

  const [easyApplyContractInstance, setEasyApplyContractInstance] =
    useState<Record<string, any>>();
  const [molochDepositTokenAddress, setMolochDepositTokenAddress] = useState<
    string | null
  >(null);

  const [, /*processCompleteMap,*/ setProcessCompleteMap] = useState<
    Record<EasyApplyCompleteName, FetchStatus>
  >({
    isCompleteLAO: FetchStatus.STANDBY,
    isCompleteWETH: FetchStatus.STANDBY,
    isCompleteChunkSize: FetchStatus.STANDBY,
    isCompleteChunksPrice: FetchStatus.STANDBY,
    isCompleteLAOMember: FetchStatus.STANDBY,
  });

  const [error, setError] = useState<Error>();
  const [lao, setLAOAddress] = useState<string>('');
  const [weth, setWETHAddress] = useState<string>('');
  const [chunkSize, setChunkSize] = useState<number>();
  const [sharesPerChunk, setSharesPerChunk] = useState<number>();
  const [isLAOMember, setIsLAOMember] = useState<boolean>(false);
  const [isMatchingChunk, setIsMatchingChunk] = useState<boolean>(false);
  const [isMatchingChunkPrice, setIsMatchingChunkPrice] =
    useState<boolean>(false);

  const getContractCallback = useCallback(getContract, [
    contractEasyApplyAddress,
    web3Instance,
  ]);

  const setDepositTokenAddressCached = useCallback(setDepositTokenAddress, [
    VentureMoloch,
  ]);

  const checkLAOAddressCallback = useCallback(checkLAOAddress, [
    easyApplyContractInstance,
  ]);

  const checkWETHAddressCallback = useCallback(checkWETHAddress, [
    easyApplyContractInstance,
    molochDepositTokenAddress,
  ]);

  const checkLAOMembershipCached = useCallback(checkLAOMembership, [
    VentureMoloch,
    contractEasyApplyAddress,
  ]);

  const checkChunksAndSharesCached = useCallback(checkChunksAndShares, [
    easyApplyContractInstance,
    saleSettings,
    web3Instance,
    chunkSize,
  ]);

  // Send dispatch to consumer as a callback that can "re-run" this component.
  // const [, dispatch] = useCounter();

  // @todo check is all done `processCompleteMap` then dispatch
  // useEffect(() => {
  //   const hasChecksPassed = Object.values(processCompleteMap).every(
  //     fs => fs === FetchStatus.FULFILLED
  //   );
  // }, [processCompleteMap, setProcessReadyMapState]);

  useEffect(() => {
    if (processReadyMap.statusEasyApply === FetchStatus.STANDBY) {
      getContractCallback();
    }
  }, [processReadyMap, getContractCallback]);

  useEffect(() => {
    setDepositTokenAddressCached();
  }, [setDepositTokenAddressCached]);

  // check LAO
  useEffect(() => {
    checkLAOAddressCallback();
  }, [checkLAOAddressCallback]);

  // check WETH
  useEffect(() => {
    checkWETHAddressCallback();
  }, [checkWETHAddressCallback]);

  // check lao membership
  useEffect(() => {
    checkLAOMembershipCached();
  }, [checkLAOMembershipCached]);

  // check the ehunk size and shares  settings
  useEffect(() => {
    checkChunksAndSharesCached();
  }, [checkChunksAndSharesCached]);

  // @note race condition issue fetching saleSettings, so re-run
  // useEffect(() => {
  //   if (!saleSettings) {
  //     dispatch({type: 'increment'});
  //   }
  // }, [saleSettings, dispatch]);

  function reRunChecks() {
    // @todo loader
    checkLAOAddressCallback();
    checkWETHAddressCallback();
    checkLAOMembershipCached();
    checkChunksAndSharesCached();
  }

  async function getContract() {
    if (!contractEasyApplyAddress) return;
    if (!web3Instance) return;

    try {
      const instance = new web3Instance.eth.Contract(
        EasyApply.abi as AbiItem[],
        contractEasyApplyAddress
      );

      setEasyApplyContractInstance(instance);
    } catch (error) {
      setError(error);
    }
  }

  /**
   * setDepositTokenAddress
   *
   * Used when calling `submitProposal` with `tributeToken` and `paymentToken`
   * as they cannot be empty.
   */
  async function setDepositTokenAddress() {
    if (!VentureMoloch) return;

    const molochDepositTokenAddress = VentureMoloch
      ? ((await VentureMoloch.instance.methods.depositToken().call()) as string)
      : '';

    setMolochDepositTokenAddress(molochDepositTokenAddress);
  }

  async function checkChunksAndShares() {
    setError(undefined);
    try {
      if (!web3Instance) return;
      if (!easyApplyContractInstance) return;
      if (!saleSettings) {
        throw new Error(`saleSettings is ${saleSettings}`);
      }

      const CHUNK_SIZE = await easyApplyContractInstance.methods
        .CHUNK_SIZE()
        .call();
      const SHARES_PER_CHUNK = await easyApplyContractInstance.methods
        .SHARES_PER_CHUNK()
        .call();

      setChunkSize(CHUNK_SIZE);
      setSharesPerChunk(SHARES_PER_CHUNK);

      const {ethPerChunk} = saleSettings;

      const matchingChunkSize = new BigNumber(
        web3Instance.utils.toWei(String(ethPerChunk), 'ether')
      ).isEqualTo(new BigNumber(CHUNK_SIZE));

      const matchingChunkPrice = new BigNumber(String(chunkSize)).isEqualTo(
        new BigNumber(
          String(
            web3Instance?.utils.toWei(
              String(saleSettings?.ethPerChunk),
              'ether'
            )
          )
        )
      );

      setIsMatchingChunk(matchingChunkSize);
      setIsMatchingChunkPrice(matchingChunkPrice);

      setProcessCompleteMap((state) => ({
        ...state,
        isCompleteChunkSize: matchingChunkSize
          ? FetchStatus.FULFILLED
          : FetchStatus.REJECTED,
        isCompleteChunksPrice: matchingChunkPrice
          ? FetchStatus.FULFILLED
          : FetchStatus.REJECTED,
      }));
    } catch (error) {
      setError(error);
      setProcessCompleteMap((state) => ({
        ...state,
        isCompleteChunkSize: FetchStatus.REJECTED,
        isCompleteChunksPrice: FetchStatus.REJECTED,
      }));
    }
  }

  /**
   * checkLAOAddress
   *
   * Gets the lao address init'd in the deployment, to check against the
   * org moloch address
   */
  async function checkLAOAddress() {
    if (!easyApplyContractInstance) return;

    try {
      const laoAddress = await easyApplyContractInstance.methods.lao().call();
      if (laoAddress) {
        setLAOAddress(laoAddress.toLowerCase());
        setProcessCompleteMap((state) => ({
          ...state,
          isCompleteLAO: FetchStatus.FULFILLED,
        }));
      }
    } catch (error) {
      setProcessCompleteMap((state) => ({
        ...state,
        isCompleteLAO: FetchStatus.REJECTED,
      }));
    }
  }

  /**
   * checkWETHAddress
   *
   * Gets the weth address init'd in the deployment, to check against the
   * org network weth address
   */
  async function checkWETHAddress() {
    if (!easyApplyContractInstance) return;
    if (!molochDepositTokenAddress) return;

    try {
      const wethAddress = await easyApplyContractInstance.methods.weth().call();
      if (wethAddress) {
        setWETHAddress(wethAddress.toLowerCase());
        setProcessCompleteMap((state) => ({
          ...state,
          isCompleteWETH: FetchStatus.FULFILLED,
        }));
      }
    } catch (error) {
      setProcessCompleteMap((state) => ({
        ...state,
        isCompleteWETH: FetchStatus.REJECTED,
      }));
    }
  }

  /**
   * checkLAOMembership
   *
   * Checks if Easy Apply is a member of the org moloch contract
   */
  async function checkLAOMembership() {
    if (!VentureMoloch) return;
    if (!contractEasyApplyAddress) return;

    try {
      const molochMember = (await VentureMoloch.instance.methods
        .members(contractEasyApplyAddress)
        .call()) as MemberMolochResponse;

      if (molochMember.exists) {
        setIsLAOMember(molochMember.exists);
        setProcessCompleteMap((state) => ({
          ...state,
          isCompleteLAOMember: FetchStatus.FULFILLED,
        }));
      }
    } catch (error) {
      setProcessCompleteMap((state) => ({
        ...state,
        isCompleteLAOMember: FetchStatus.REJECTED,
      }));
    }
  }

  return (
    <div>
      <h2>Easy Apply Contract</h2>{' '}
      <button onClick={reRunChecks}>Run checks</button>
      <div
        style={{
          textAlign: 'center',
          maxWidth: 600,
          display: 'block',
          margin: '0 auto',
        }}>
        <ErrorMessageWithDetails error={error} renderText="" />
      </div>
      {/**
       * 1. Check LAO addr is the correct address for org
       */}
      <div>
        <p>
          <CheckStatus
            checkStatus={lao === contractMolochAddress?.toLowerCase()}
          />{' '}
          LAO address in Easy Apply is: {lao}
        </p>
      </div>
      {/**
       * 2. Check WETH addr is the correct address for org
       */}
      <div>
        <p>
          <CheckStatus
            checkStatus={weth === molochDepositTokenAddress?.toLowerCase()}
          />{' '}
          WETH address in Easy Apply is: {weth}
        </p>
      </div>
      {/**
       * 3. Check sale settings against easyApply (shares per eth, share price)
       */}
      <div>
        <p>
          <CheckStatus checkStatus={isMatchingChunkPrice} /> Contract
          `SHARES_PER_CHUNK` = Org `saleSettings.sharesPerChunk`
        </p>
        <ul>
          <li>Easy Apply Contract value: {chunkSize}</li>
          <li>
            Database Org value: {saleSettings?.ethPerChunk};{' '}
            <em>
              which is{' '}
              {web3Instance?.utils.toWei(
                String(saleSettings?.ethPerChunk),
                'ether'
              )}{' '}
              wei
            </em>{' '}
          </li>
        </ul>
        {/*  */}

        <p>
          <CheckStatus checkStatus={isMatchingChunk} /> Contract `CHUNK_SIZE` =
          Org `saleSettings.sharesPerChunk`
        </p>
        <ul>
          <li>Easy Apply Contract value: {sharesPerChunk}</li>
          <li>Database Org value: {saleSettings?.sharesPerChunk}</li>
        </ul>
      </div>
      {/**
       * 4. Check easy apply is member of the org LAO
       */}
      <p>
        <CheckStatus checkStatus={isLAOMember} /> Easy Apply Contract is a
        member: {String(isLAOMember)}
      </p>
    </div>
  );
}
