/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import InVotingSVG from '../../../../assets/svg/InProgressSVG';
import SquareRootVotingBar from '../../../../components/feedback/SquareRootVotingBar';

import {formatNumber} from '../../../../util/helpers';
import {StoreState, Proposal} from '../../../../util/types';

import vc from '../../../../assets/scss/modules/votingcard.module.scss';

type ProposalVotersProps = {
  proposal: Proposal | undefined | Record<string, any>;
};

export default function ProposalVoters(props: ProposalVotersProps) {
  const {proposal} = props;

  const molochConstants = useSelector(
    (state: StoreState) => state.blockchain.molochConstants
  );

  /**
   * GQL Query Hooks
   */

  /**
   * State
   */

  const [votingExpired, setVotingExpired] = useState<boolean>(true);
  const [percentYes, setPercentYes] = useState<number>(0);
  const [percentNo, setPercentNo] = useState<number>(0);
  const [yesVotes, setYesVotes] = useState<number>(0);
  const [noVotes, setNoVotes] = useState<number>(0);
  const [sponsored, setSponsored] = useState<boolean>(false);

  /**
   * Effects
   */

  useEffect(() => {
    try {
      if (!molochConstants || !proposal) return;
      const {endingPeriod, yesShares, noShares, sponsored} = proposal;

      const {summoningTime, periodDuration} = molochConstants;
      const now = Math.floor(
        (Math.floor(Date.now() / 1000) - Number(summoningTime)) /
          Number(periodDuration)
      );
      setVotingExpired(Number(now) >= Number(endingPeriod));

      let percentYes =
        typeof Number(
          (Number(yesShares) / Number(molochConstants.totalShares)) * 100
        ) !== 'number' || yesShares === undefined
          ? 0
          : Number(
              (Number(yesShares) / Number(molochConstants.totalShares)) * 100
            );

      let percentNo =
        typeof Number(
          (Number(noShares) / Number(molochConstants.totalShares)) * 100
        ) !== 'number' || noShares === undefined
          ? 0
          : Number(
              (Number(noShares) / Number(molochConstants.totalShares)) * 100
            );

      setPercentYes(percentYes);
      setPercentNo(percentNo);
      setYesVotes(Number(yesShares));
      setNoVotes(Number(noShares));
      setSponsored(sponsored);
    } catch (error) {
      console.error(error);
    }
  }, [molochConstants, proposal]);

  /**
   * Functions
   */

  function getVotingStatus(yes: number, no: number, hasExpired: boolean) {
    if (!molochConstants) return;

    const {totalShares} = molochConstants;
    const awaitingAbstained = Number(totalShares) - Number(yes) - Number(no);

    return (
      <>
        <InVotingSVG />

        <div className={vc['required-votes']}>
          <span className={vc['yesVote']}>Yes</span>
          <span>: {formatNumber(yes)} </span>
          <span className={vc['divider']}>|</span>{' '}
          <span className={vc['noVote']}>No</span>
          <span style={{marginRight: '.5rem'}}>: {formatNumber(no)} </span>
          {hasExpired ? (
            <span className={vc['remaining']}>
              ({formatNumber(awaitingAbstained)} abstained)
            </span>
          ) : (
            <span className={vc['remaining']}>
              (awaiting {formatNumber(awaitingAbstained)} responses)
            </span>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {/* Just commenting out this for now instead of deleting in case we want to revert back */}
      {/* <div className={vc['row-w-icon']}>
        {getVotingStatus(yesVotes, noVotes, votingExpired)}
      </div> */}

      <SquareRootVotingBar
        yes={percentYes.toString()}
        no={percentNo.toString()}
        votingExpired={votingExpired}
        yesVotes={yesVotes}
        noVotes={noVotes}
        sponsored={sponsored}
      />
    </>
  );
}
