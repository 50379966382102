import React, {useEffect, useRef, useState} from 'react';

import {BACKEND_URL} from '../../util/config';
import FadeIn from '../../components/common/FadeIn';
import Loader from '../../components/feedback/Loader';

import a from '../../assets/scss/modules/agreementtext.module.scss';

type RootProps = React.HTMLAttributes<HTMLDivElement>;

type AgreementTextProps = {
  parameters: Record<string, any>;
  rootProps?: RootProps;
  title: string;
};

let cachedText: Record<string, string> = {};

/**
 * AgreementText
 *
 * Fetches an OpenLaw agreement's (template) HTML.
 * Once fetched, the content is cached in a map ({ [string] : string }) while the application is running.
 *
 * @param {AgreementTextProps} props
 *
 * @todo: To make this component more generic for other possible uses, the styles will have to change.
 */
export default function AgreementText(props: AgreementTextProps) {
  const {title} = props;
  const [agreementText, setAgreementText] = useState<string>();
  const [agreementTextError, setAgreementTextError] = useState<string>();
  const textElement = useRef<HTMLDivElement>(null);
  const cleanedUp = useRef<boolean>(false);

  const ERROR_MESSAGE = 'Sorry, we are having trouble getting the agreement.';

  useEffect(() => {
    if (cachedText[title]) return;

    const abortController = new AbortController();

    fetch(`${BACKEND_URL}/contracts/template/${title}`, {
      body: JSON.stringify(props.parameters),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      signal: abortController.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(ERROR_MESSAGE);
        }

        return response.text();
      })
      .then((responseText) => {
        if (cleanedUp.current) return;

        setAgreementText(responseText);
        setAgreementTextError(undefined);
      })
      .catch((error) => {
        if (cleanedUp.current) return;

        setAgreementText(undefined);
        setAgreementTextError(ERROR_MESSAGE);
      });

    return function cleanup() {
      cleanedUp.current = true;

      abortController.abort();
    };
  }, [props.parameters, title]);

  /**
   * Strip any empty paragraph tags (e.g. causing unnecessary white space)
   * Strip any <hr> "page-break" dividers
   */
  useEffect(() => {
    if (!agreementText || !textElement.current) return;

    const textElementNodesToRemove = Array.from(textElement.current.childNodes)
      .map((n) => {
        if (n.nodeName === 'HR') return n;

        /**
         * If a paragraph contains a lot of <br /> elements,
         * then only allow up to 5 before removing the element.
         * Stylistically some documents have a long section of whitespace
         * on the first page that, taken out of its context, looks like a mistake.
         */
        if (
          n.nodeName === 'P' &&
          !n.textContent &&
          Array.from(n.childNodes).filter((n) => n.nodeName === 'BR').length > 5
        )
          return n;

        return undefined;
      })
      .filter(Boolean);

    // Remove elements from DOM
    textElementNodesToRemove.forEach((n) => n && n.remove());

    cachedText[title] = textElement.current.innerHTML;
  }, [agreementText, title]);

  return cachedText[title] || agreementText ? (
    <FadeIn>
      <div className={props.rootProps ? props.rootProps.className : ''}>
        <div
          className={a.content}
          dangerouslySetInnerHTML={{__html: agreementText || cachedText[title]}}
          ref={textElement}
        />
      </div>
    </FadeIn>
  ) : !agreementTextError ? (
    <div className={props.rootProps ? props.rootProps.className : ''}>
      <Loader showAfterMs={200} />
    </div>
  ) : (
    <FadeIn>
      <p className="error-message org-error-message text-center">
        {agreementTextError}
      </p>
    </FadeIn>
  );
}
