import React, {useState, useEffect} from 'react';
import {CSVLink} from 'react-csv';
import {useSelector} from 'react-redux';

import useInvestmentProposals from '../../hooks/useInvestmentProposals';
import {getOrgText} from '../../util/helpers';
import {StoreState} from '../../util/types';

export default function Projects(): React.ReactElement<{}> {
  /**
   * Selectors
   */

  const orgText = useSelector((s: StoreState) => s.org?.text ?? null);

  /**
   * State
   */

  const [items, setItems] = useState<Record<string, any>>();

  /**
   * Our hooks
   */

  const {investmentProposals} = useInvestmentProposals();

  /**
   * Variables
   */

  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');

  /**
   * Effects
   */

  useEffect(() => {
    try {
      if (investmentProposals.length > 0) {
        const data = investmentProposals.map((p) => {
          // delete these; they don't need to be in the csv
          const pWithDeletedItems = p as any;

          // First, add `details` and remove quotes from `description`
          pWithDeletedItems.details = p.description?.replace(/[,\n]/g, ' ');

          // We only return non-archived proposals from the API.
          delete pWithDeletedItems.archived;
          delete pWithDeletedItems.description;
          delete pWithDeletedItems.investmentProposalId;
          delete pWithDeletedItems.metadata;
          delete pWithDeletedItems.molochProposalId;
          delete pWithDeletedItems.molochProposalIndex;
          delete pWithDeletedItems.org;
          delete pWithDeletedItems.snapshotHubProposal;
          delete pWithDeletedItems.snapshotHubVotes;
          delete pWithDeletedItems.snapshotProposalId;
          delete pWithDeletedItems.type;
          delete pWithDeletedItems.user;
          delete pWithDeletedItems.uuid;

          return pWithDeletedItems;
        });

        setItems(data);
      }
    } catch (error) {
      error && console.error(error);
    }
  }, [investmentProposals]);

  /**
   * Render
   */

  return (
    <div className={'text-center'}>
      <p>Investment Proposals count: {investmentProposals.length}</p>

      {items && (
        <CSVLink
          data={items}
          filename={`${orgName.replace(/\s/, '-')}-investment-proposals.csv`}
          enclosingCharacter={`'`}>
          Download Investment Proposals CSV{' '}
          <span role="img" aria-label="Arrow down">
            ⬇️
          </span>
        </CSVLink>
      )}
    </div>
  );
}
