import {Dispatch} from 'react';
import {BACKEND_URL, ENVIRONMENT} from '../../util/config';
import {OrgResponse} from '../../util/types';
import {NetworkNames, NetworkIDs} from '../../util/enums';

import {BLOCKCHAIN_DEFAULT_CHAIN} from '../blockchain/actions';

export const ORG_SET_DATA = 'ORG_SET_DATA';
export const ORG_SET_FEATURES = 'ORG_SET_FEATURES';
export const ORG_SET_TEXT = 'ORG_SET_TEXT';
export const ORG_CLEAR_DATA = 'ORG_CLEAR_DATA';

function testnetDefaultChain(testnet: string) {
  // Determine `defaultChain` for testnet
  let defaultChain: number = 1;

  switch (testnet) {
    case NetworkNames.GOERLI:
      defaultChain = NetworkIDs.GOERLI;
      break;
    case NetworkNames.RINKEBY:
      defaultChain = NetworkIDs.RINKEBY;
      break;
    case NetworkNames.POLYGON:
      defaultChain = NetworkIDs.POLYGON;
      break;
    case NetworkNames.MAINNET:
      defaultChain = NetworkIDs.MAINNET;
      break;
    case NetworkNames.GANACHE:
      defaultChain = NetworkIDs.GANACHE;
      break;
  }

  return defaultChain;
}

function setDefaultChain(defaultChain: number) {
  return {
    type: BLOCKCHAIN_DEFAULT_CHAIN,
    defaultChain,
  };
}

function clearOrgData() {
  return {
    type: ORG_CLEAR_DATA,
  };
}

export function getOrg(internalName: string) {
  return async function (dispatch: Dispatch<any>) {
    try {
      const response = await fetch(
        `${BACKEND_URL}/org/search/${internalName.toLowerCase()}`,
        {
          method: 'GET',
        }
      );

      /**
       * Error response handling
       */

      if (response.status === 404) {
        throw new Error('The org was not found.');
      }

      if (!response.ok) {
        throw new Error('Something went wrong while getting the org.');
      }

      const responseJSON: OrgResponse = await response.json();

      dispatch(setOrgData(responseJSON));

      if (ENVIRONMENT !== 'production') {
        const defaultChain: number = testnetDefaultChain(responseJSON.testnet);
        dispatch(setDefaultChain(defaultChain));
      }

      return responseJSON;
    } catch (error) {
      dispatch(clearOrgData());

      throw error;
    }
  };
}

export function getOrgFeatures(internalName: string) {
  return async function (dispatch: Dispatch<any>) {
    try {
      // Fetch i18n text data from the client's `/public` directory.
      const response = await fetch(
        `/orgs/${internalName.toLowerCase()}/feature.json`,
        {
          method: 'GET',
        }
      );

      /**
       * Error response handling
       */

      if (response.status === 404) {
        throw new Error('The org was not found.');
      }

      if (!response.ok) {
        throw new Error('Something went wrong while getting feature.json.');
      }

      const responseJSON: Record<string, string> = await response.json();

      dispatch(setOrgFeatures(responseJSON));

      return responseJSON;
    } catch (error) {
      throw error;
    }
  };
}

export function getOrgText(internalName: string) {
  return async function (dispatch: Dispatch<any>) {
    try {
      // Fetch i18n text data from the client's `/public` directory.
      const response = await fetch(
        `/orgs/${internalName.toLowerCase()}/text.json`,
        {
          method: 'GET',
        }
      );

      /**
       * Error response handling
       */

      if (response.status === 404) {
        throw new Error('The org was not found.');
      }

      if (!response.ok) {
        throw new Error('Something went wrong while getting the text.');
      }

      const responseJSON: Record<string, string> = await response.json();

      dispatch(setOrgText(responseJSON));

      return responseJSON;
    } catch (error) {
      throw error;
    }
  };
}

/**
 * setOrgData
 *
 * An action that contains all of the data for the org.
 * This data is used throughout the app (e.g. Moloch contract addresses).
 *
 * @param {OrgResponse} org
 */
function setOrgData(org: OrgResponse) {
  return {
    type: ORG_SET_DATA,
    ...org,
  };
}

/**
 * setOrgFeatures
 *
 * An action which contains the feature flags for the org.
 *
 * @param {Record<string, string>} featureJSON
 */
function setOrgFeatures(featureJSON: Record<string, string>) {
  return {
    type: ORG_SET_FEATURES,
    features: featureJSON,
  };
}

/**
 * setOrgText
 *
 * Delivers an action that ultimately gets reduced to a new i18n instance.
 * The entire app will be able to use the text obtained from this i18n object.
 *
 * @param {Record<string, string>} textJSON
 */
function setOrgText(textJSON: Record<string, string>) {
  return {
    type: ORG_SET_TEXT,
    text: textJSON,
  };
}
