import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useForm, Controller} from 'react-hook-form';
import Editor from 'react-simple-code-editor';
import {highlight, languages} from 'prismjs/components/prism-core';

import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markup';
import b from '../../../assets/scss/modules/buttons.module.scss';

import {getAuthHeader} from '../../../util/helpers';
import {authServerShowModal} from '../../../store/actions';
import {StoreState} from '../../../util/types';
import {BACKEND_URL} from '../../../util/config';

export default function GlobalSettings() {
  const {control, register, getValues, setValue, watch} = useForm();
  watch();

  const dispatch = useDispatch();

  const orgId = useSelector((state: StoreState) => state.org && state.org.id);
  const accessToken = useSelector(
    (s: StoreState) => s.authServer && s.authServer.accessToken
  );
  const adminEmails = useSelector(
    (state: StoreState) => state.org && state.org.adminEmail
  );
  const globalCSS = useSelector(
    (state: StoreState) => state.org && state.org.globalCSS
  );
  const logoURL = useSelector(
    (state: StoreState) => state.org && state.org.logoURL
  );
  const saleSettings = useSelector(
    (state: StoreState) => state.org && state.org.saleSettings
  );

  const [error, setError] = useState<Error>();
  const [isSaved, setIsSaved] = useState<boolean>(false);

  function settingEditor() {
    const cssEditor = (
      <Editor
        ref={register}
        value={globalCSS || ''}
        onValueChange={(code) => setValue('globalCSS', code)}
        highlight={(code) => highlight(code, languages.css)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 16,
        }}
        className="container__editor"
      />
    );
    const saleSettingsStr: string = JSON.stringify(saleSettings);

    const saleSettingsEditor = (
      <Editor
        ref={register}
        value={saleSettingsStr}
        onValueChange={(code) => setValue('saleSettings', code)}
        highlight={(code) => highlight(code, languages.js)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 16,
        }}
        className="container__editor language-json"
      />
    );

    return (
      <form key={'global-settings'}>
        <div>
          <label
            htmlFor="adminEmail"
            style={{margin: '1rem 0 0.5rem', display: 'block'}}>
            Admin Email(s):
          </label>
          <input
            type="text"
            name="adminEmail"
            defaultValue={adminEmails || ''}
            ref={register}
          />
          <div style={{textAlign: 'right'}}>
            <sup>
              <span className={'color-yellow'}>Separated by commas</span>
            </sup>
          </div>
        </div>
        <div>
          <label
            htmlFor="logoURL"
            style={{margin: '1rem 0 0.5rem', display: 'block'}}>
            Logo URL:
          </label>
          <input
            type="text"
            name="logoURL"
            defaultValue={logoURL || ''}
            ref={register}
          />
        </div>
        <div>
          <label
            htmlFor="globalCSS"
            style={{margin: '1rem 0 0.5rem', display: 'block'}}>
            Global CSS:
          </label>
          <div className="container_editor_area">
            <Controller
              as={cssEditor}
              name="globalCSS"
              control={control}
              defaultValue={globalCSS}
              onChange={([event]) => event.target.value}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="saleSettings"
            style={{margin: '1rem 0 0.5rem', display: 'block'}}>
            Sale Settings:
          </label>
          <div className="container_editor_area">
            <Controller
              as={saleSettingsEditor}
              name="saleSettings"
              control={control}
              defaultValue={saleSettingsStr}
              onChange={([event]) => event.target.value}
            />
          </div>
        </div>
      </form>
    );
  }

  function handleShowAuthServerModal() {
    // Show the auth modal and exit this process if the user has not authenticated with the server.
    if (!accessToken) {
      dispatch(authServerShowModal(true));
    }
  }

  async function handleUpdateGlobal() {
    try {
      if (!accessToken) {
        throw new Error('No access token was found.');
      }

      const {
        adminEmail,
        saleSettings: saleSettingsStr,
        ...nestedObjectValue
      } = getValues({nest: true});
      const adminEmails = adminEmail.split(',');

      const updateSettings = {
        adminEmail: [...adminEmails],
        saleSettings: JSON.parse(saleSettingsStr),
        ...nestedObjectValue,
      };

      const response = await fetch(`${BACKEND_URL}/${orgId}/org`, {
        method: 'PATCH',
        body: JSON.stringify({
          ...updateSettings,
        }),
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader(accessToken),
        },
      });

      if (response.status === (400 || 500)) {
        throw new Error('Some form values do not look correct.');
      }

      setIsSaved(response.status === 204 || response.status === 200);
    } catch (error) {
      setError(error);
    }
  }

  return (
    <div className="email-settings">
      <div className="org-form-wrap">{settingEditor()}</div>

      {error && <ErrorMessage error={error} />}

      <div className="text-center" style={{marginTop: '2rem'}}>
        {isSaved && (
          <>
            <sup
              className={`${b['email-settings-save-confirm']} org-email-settings-save-confirm`}>
              Successfully saved!
            </sup>
            <br />
          </>
        )}
        <button
          className={`${b.primary} org-primary-button`}
          onClick={
            accessToken ? handleUpdateGlobal : handleShowAuthServerModal
          }>
          Update
        </button>
      </div>
    </div>
  );
}

function ErrorMessage(props: {error: Error}) {
  const {error} = props;

  return error ? (
    <>
      <p className="error-message org-error-message">
        Something went wrong while submitting the update
      </p>
      <details>
        <summary
          className="error-message org-error-message"
          style={{cursor: 'pointer', outline: 'none'}}>
          Error details
        </summary>
        <p className="error-message org-error-message font-mono">
          <small>{error.message}</small>
        </p>
      </details>
    </>
  ) : null;
}
