import l from '../../assets/scss/modules/logo.module.scss';

interface LogoMetaverseProps {
  orgInternalName: string;
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoMetaverse(props: LogoMetaverseProps) {
  const classNameSizes = {
    neon: props.size ? `${l[`neon--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.neon} ${classNameSizes.neon}`}>
      <img
        alt="NEON Logo"
        src={`/orgs/${props.orgInternalName}/images/neon-01.png`}
      />
    </div>
  );
}
