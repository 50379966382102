import l from '../../assets/scss/modules/logo.module.scss';

interface LogoDarkhorseProps {
  orgInternalName: string;
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoDarkhorse(props: LogoDarkhorseProps) {
  const classNameSizes = {
    darkhorse: props.size ? `${l[`darkhorse--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.darkhorse} ${classNameSizes.darkhorse} org-logo`}>
      <img
        alt="Dark Horse DAO Logo"
        src={`/orgs/${props.orgInternalName}/images/dhd_logo.png`}
      />
    </div>
  );
}
