import {useState, useEffect, useCallback} from 'react';

import {FetchStatus} from '../util/enums';
import {Sale} from '../util/types';
import {useBackendURL} from '../hooks';
import {useAbortController} from './useAbortController';

/**
 * useSales
 *
 * Returns state of sales and status of fetch,
 * and a function to update the state.
 */
export default function useSales() {
  /**
   * State
   */

  const [sales, setSales] = useState<Sale[]>([]);
  const [salesStatus, setSalesStatus] = useState<FetchStatus>(
    FetchStatus.PENDING
  );

  /**
   * Our hooks
   */

  const backendURL = useBackendURL();
  const {abortController, isMountedRef} = useAbortController();

  /**
   * Cached callbacks
   */

  const fetchSalesCached = useCallback(fetchSales, [
    abortController,
    backendURL,
    isMountedRef,
  ]);

  /**
   * Effects
   */

  // Fetch sales
  useEffect(() => {
    fetchSalesCached();

    return () => {
      abortController && abortController.abort();
    };
  }, [abortController, fetchSalesCached]);

  // Listen for any new, approved sales
  useEffect(() => {
    if (!backendURL) return;
    // IE, Edge
    if (!('EventSource' in window)) return;

    const eventSource = new EventSource(`${backendURL}/sales/events/approved`);

    eventSource.addEventListener('saleUpdate', (event) => {
      const {sale} = JSON.parse((event as MessageEvent).data);

      // Be safe and make sure unique
      setSales((prevSales) =>
        [...prevSales, sale as Sale].reduce((acc, next) => {
          // Default: start with the first item.
          if (!acc.length) {
            acc.push(next);
            return acc;
          }

          // If the id already exists, exit.
          if (acc.find((v) => v.id === next.id)) return acc;

          // Else, add it.
          acc.push(next);

          // Return sorted sales by DESC amount, or date if same amount.
          return acc.sort(
            (a, b) =>
              Number(b.amount) - Number(a.amount) ||
              new Date(b.creationDate).getTime() -
                new Date(a.creationDate).getTime()
          );
        }, [] as Sale[])
      );

      setSalesStatus(FetchStatus.FULFILLED);
    });

    return function cleanup() {
      eventSource.close();
    };
  }, [backendURL]);

  /**
   * Functions
   */

  async function fetchSales() {
    try {
      if (!backendURL) return;

      const response = await fetch(`${backendURL}/sales`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        signal: abortController?.signal,
      });

      if (!response.ok) {
        throw new Error('Something went wrong while getting the latest sales.');
      }

      const responseJSON = await response.json();

      if (!isMountedRef.current) return;

      setSales(responseJSON);
      setSalesStatus(FetchStatus.FULFILLED);
    } catch (error) {
      if (!isMountedRef.current) return;

      setSales([]);
      setSalesStatus(FetchStatus.REJECTED);
    }
  }

  return {sales, salesStatus};
}
