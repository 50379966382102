import l from '../../assets/scss/modules/logo.module.scss';

interface LogoTestProps {
  orgInternalName: string;
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoTest(props: LogoTestProps) {
  const classNameSizes = {
    test: props.size ? `${l[`test--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.test} ${classNameSizes.test} org-logo`}>
      <span>Test DAO</span>
      <img
        alt="Test DAO Logo"
        src={`/orgs/${props.orgInternalName}/images/logo_img.png`}
      />
    </div>
  );
}
