import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {BACKEND_URL} from '../util/config';
import {StoreState} from '../util/types';

/**
 * useBackendURL
 *
 * Provides a backend URL with the org id prefixed,
 * e.g. https://backend.thelao.io/{orgId}.
 *
 * @note Do not use this if you do not need the org id.
 *
 * @returns {string} The backend URL with org id.
 */
export default function useBackendURL() {
  const [backendURL, setBackendURL] = useState<string>();

  const id = useSelector((s: StoreState) => s.org && s.org.id);

  useEffect(() => {
    if (!BACKEND_URL || !id) return;

    setBackendURL(`${BACKEND_URL}/${id}`);
  }, [id]);

  return backendURL;
}
