import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

import {Proposal, SidePocketProposal, StoreState} from '../../util/types';
import {chooseRandom, numberRangeArray} from '../../util/helpers';
import {InternalNamesToMap} from '../../util/orgDomainMappings';
import VotingCard from './VotingCard';
import ProjectDefaultIcon from './ProjectDefaultIcon';

import s from '../../assets/scss/modules/proposalcard.module.scss';

type ProposalCardProps = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  privateView?: boolean;
  proposalDetails: Proposal | SidePocketProposal;
  pathToProposal: string;
};

/**
 * Shows a preview of a proposal's details.
 * The type can be a project proposal or a governance proposal.
 *
 * @param {ProposalCardProps} props
 * @returns {JSX.Element}
 */
function ProposalCard(props: ProposalCardProps) {
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

  /**
   * Selectors
   */

  const connectedMember = useSelector((s: StoreState) => s.connectedMember);
  const molochConstants = useSelector(
    (s: StoreState) => s.blockchain.molochConstants
  );
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  /**
   * Variables
   */

  const isMember = connectedMember && connectedMember.isMemberActive;

  const {
    id,
    molochProposalId,
    name = '',
    sponsored,
    yesShares,
    noShares,
    processed,
    endingPeriod,
    uuid,
  } = props.proposalDetails || {};

  const isProposalProcessed = processed !== undefined && processed;
  const isProposalSponsored = sponsored !== undefined && sponsored;
  const proposalEndingPeriod = endingPeriod;
  const proposalNoShares = noShares;
  const proposalYesShares = yesShares;

  const hasMolochProposalId =
    molochProposalId !== undefined &&
    molochProposalId !== null &&
    molochProposalId >= 0;

  const {summoningTime = 0, periodDuration = 0} = molochConstants || {};

  const now =
    Math.floor(
      (Math.floor(Date.now() / 1000) - Number(summoningTime)) /
        Number(periodDuration)
    ) || 0;

  const isVoting =
    isProposalSponsored &&
    !isProposalProcessed &&
    Number(proposalEndingPeriod) > now;

  const isPassedProject =
    hasMolochProposalId &&
    sponsored &&
    Number(proposalEndingPeriod) <= now &&
    Number(proposalYesShares) > Number(proposalNoShares);

  const privateViewAdditionalClass = props.privateView
    ? s['proposal-card--private']
    : '';
  const customAdditionalClasses =
    orgInternalName === InternalNamesToMap.metaverse
      ? `org-random-background org-random-background--${getRandomNumber()} org-random-icon org-random-icon--${getRandomNumber()}`
      : '';

  /**
   * Functions
   */

  function getRandomNumber() {
    return chooseRandom<number>(numberRangeArray(13, 0));
  }

  function getButtonText() {
    if (isVoting && isMember) {
      return 'Vote';
    }

    if (isProposalSponsored === false && isMember) {
      return 'Nominate';
    }

    return 'Review';
  }

  function renderButton() {
    return (
      <button
        className={`${s['proposal-button']} org-proposal-button`}
        disabled={props.privateView}>
        {getButtonText()}
      </button>
    );
  }

  function renderIcon() {
    if (hasMolochProposalId) {
      if (orgInternalName === InternalNamesToMap.thelao) {
        return <ProjectDefaultIcon proposalId={molochProposalId} />;
      } else {
        return (
          <img
            alt="Proposal default icon"
            src={`/orgs/${orgInternalName}/images/proposal-default-icon.svg`}
          />
        );
      }
    } else {
      if (orgInternalName === InternalNamesToMap.thelao) {
        return (
          <div className={s['proposal-icon-governance']}>
            <span role="img" aria-label="Space invader emoji">
              👾
            </span>
          </div>
        );
      } else {
        return (
          <img
            alt="Proposal default icon"
            src={`/orgs/${orgInternalName}/images/proposal-default-icon.svg`}
          />
        );
      }
    }
  }

  function renderCard() {
    return (
      <div
        data-proposal-id={id}
        className={`${s['proposal-card']} ${privateViewAdditionalClass} ${customAdditionalClasses} org-proposal-card`}
        onClick={props.onClick}
        key={uuid}>
        <div className={s['proposal-icon']}>{renderIcon()}</div>

        <h3 className={`${s['proposal-title']} org-proposal-title`}>
          {props.privateView ? (
            '(Private)'
          ) : (
            <ResponsiveEllipsis
              text={name}
              maxLine={1}
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          )}
        </h3>

        {!isPassedProject && (
          <>
            <VotingCard proposal={props.proposalDetails} currentPeriod={now} />
            {renderButton()}
          </>
        )}
      </div>
    );
  }

  if (props.privateView) {
    return renderCard();
  }

  return (
    <Link
      className="proposal-card--link org-proposal-card--link"
      to={props.pathToProposal}>
      {renderCard()}
    </Link>
  );
}

export const MemoizedProposalCard = React.memo(ProposalCard);
