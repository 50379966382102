import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Markdown from 'markdown-to-jsx';

import {GetInvestmentProposalResponse, StoreState} from '../../util/types';
import {getOrgText} from '../../util/helpers';
import {HasAccess} from '../../components/common/HasAccess';
import {ProposalLink, ProposalLinksList} from './helper/ProposalLinks';
import FadeIn from '../../components/common/FadeIn';
import InvestmentProposalAmountRequested from './InvestmentProposalAmountRequested';
import InvestmentProposalPrivacy from './InvestmentProposalPrivacy';
import ProposalSocialLinks from './helper/ProposalSocialLinks';
import Wrap from '../../components/layout/Wrap';

import s from '../../assets/scss/modules/proposaldetails.module.scss';

type RenderActionsArgs = {
  proposal: GetInvestmentProposalResponse;
};

type InvestmentProposalProps = {
  canEdit: boolean;
  canViewPrivilegedDetails: boolean;
  proposal: GetInvestmentProposalResponse;
  /**
   * A render prop to render any action button flows for a proposal.
   *
   * For example, we'll want to render a different set of actions
   * based on whether the `proposal.proposalTarget` is `null`, or
   * a Snapshot, or a Moloch.
   */
  renderActions: (p: RenderActionsArgs) => React.ReactNode;
};

/**
 * InvestmentProposal
 *
 * @param {InvestmentProposalProps} props
 * @returns {JSX.Element}
 */
export default function InvestmentProposal(
  props: InvestmentProposalProps
): JSX.Element {
  const {canViewPrivilegedDetails, canEdit, proposal, renderActions} = props;

  /**
   * Selectors
   */

  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  /**
   * Their hooks
   */

  const history = useHistory();

  /**
   * Variables
   */

  const getText = getOrgText(orgText);
  const orgProjectText = getText('OrgProjectText');
  const orgInvestmentsText = getText('OrgInvestmentsText');

  /**
   * Functions
   */

  function goBack(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    const hasMolochProposalIndex: boolean =
      proposal.molochProposalIndex !== null &&
      proposal.molochProposalIndex !== undefined;

    /**
     * If the proposal is "sponsored" (e.g. a Snapshot Hub proposal was created)
     * then go back to the investments/voting/failed page. Otherwise, go to
     * the non-sponsored proposals page.
     */
    if (hasMolochProposalIndex || proposal.snapshotProposalId) {
      history.push(`/${orgInvestmentsText.toLowerCase()}`);

      return;
    }

    history.push('/proposals');
  }

  // Return details
  return (
    <Wrap className={'section-wrapper'}>
      <FadeIn>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">
            {proposal.name || '\u2026'}
          </h2>

          <button
            className="titlebar__action org-titlebar__action"
            onClick={goBack}>
            &larr;
            <span className="titlebar__action-text">View all</span>
          </button>
        </div>

        <section
          className={`${s['proposal-wrapper-alt']} org-proposal-wrapper-alt`}>
          <section className={s['proposal-content']}>
            <h3
              className={`${s['proposal-content-title']} org-proposal-content-title`}>
              Proposal Details
            </h3>

            {/* DESCRIPTION */}
            <Markdown>{proposal.description}</Markdown>

            {/* FUNDING NEEDS */}
            <HasAccess hasAccess={canViewPrivilegedDetails}>
              {proposal.metadata && proposal.metadata.fundingNeeds && (
                /* @note Hardcoded style as a bit of a spacer */
                <div style={{marginTop: '2em'}}>
                  <h3
                    className={`${s['proposal-content-title']} org-proposal-content-title`}>
                    Use of Funds
                  </h3>

                  <Markdown>{proposal.metadata.fundingNeeds}</Markdown>
                </div>
              )}
            </HasAccess>
          </section>

          {/* RIGHT SIDE */}
          <aside className={`${s['proposal-details']} org-proposal-details`}>
            <div
              className={`${s['proposal-actions-container']} org-proposal-actions-container`}>
              {/* AMOUNT REQUESTED */}
              <HasAccess hasAccess={canViewPrivilegedDetails}>
                <div className={s['eth-requested-wrapper']}>
                  <div className={`${s['eth-requested']} org-eth-requested`}>
                    <InvestmentProposalAmountRequested proposal={proposal} />
                  </div>
                </div>
              </HasAccess>

              {/* SPONSOR & VOTING ACTIONS */}
              {renderActions({proposal})}

              {/* PRIVACY SETTING */}
              <HasAccess hasAccess={canEdit}>
                {/* @note Hardcoded style as a bit of a spacer */}
                <div style={{marginTop: '1.5em'}}>
                  <InvestmentProposalPrivacy proposal={proposal} />
                </div>
              </HasAccess>
            </div>

            {/* PROJECT INFORMATION */}
            {/* @todo Rethink how we show info per DAO, as not all have the same info. */}
            {Object.keys(proposal.metadata || {}).length > 0 && (
              <div
                className={`${s['proposal-data-container']} org-proposal-data-container`}>
                <div
                  className={`${s['proposal-data-title']} org-proposal-data-title`}>
                  {orgProjectText} Data
                </div>

                <ProposalLink
                  title={`${orgProjectText} Site`}
                  url={proposal.metadata?.projectURL}
                />

                <ProposalLinksList
                  title="Relevant Links"
                  urls={proposal.metadata?.documentURLs}
                />

                <ProposalSocialLinks
                  discord={proposal.metadata?.socialDiscordURL}
                  telegram={proposal.metadata?.socialTelegramURL}
                  twitterUsername={proposal.metadata?.socialTwitterUsername}
                />

                {/* CONTACT INFO */}
                {proposal.metadata?.contactName &&
                  proposal.metadata?.contactEmail && (
                    <div className={`${s['info-item']} org-info-item`}>
                      <span>{orgProjectText} Contact</span>
                      <a href={`mailto:${proposal.metadata.contactEmail}`}>
                        {proposal.metadata.contactName}
                      </a>
                    </div>
                  )}
              </div>
            )}
          </aside>
        </section>
      </FadeIn>
    </Wrap>
  );
}
