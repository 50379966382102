import React, {createContext, useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router';

import {FetchStatus} from '../../util/enums';
import {
  GetInvestmentProposalResponse,
  InvestmentProposalGQLResponse,
} from '../../util/types';
import {getQueryStringParam} from '../../util/helpers';
import {useCanViewInvestmentProposal} from '../../hooks/useCanViewInvestmentProposal';
import {useInvestmentProposalFromDB, useMolochProposal} from '../../hooks';
import ErrorMessageWithDetails from '../../components/common/ErrorMessageWithDetails';
import FadeIn from '../../components/common/FadeIn';
import HiThereLoader from '../../components/feedback/HiThereLoader';
import InvestmentProposal from './InvestmentProposal';
import InvestmentProposalActions from './InvestmentProposalActions';
import InvestmentProposalHelmet from './helper/InvestmentProposalHelmet';
import Wrap from '../../components/layout/Wrap';

type InvestmentProposalContextValue = {
  molochGQLInvestmentProposal: InvestmentProposalGQLResponse | undefined;
};

export const InvestmentProposalContext =
  createContext<InvestmentProposalContextValue>(
    {} as InvestmentProposalContextValue
  );

export default function InvestmentProposalContainer(): JSX.Element {
  // Get UUID for fetching the investment proposal.
  const {uuid} = useParams<{uuid: string}>();

  /**
   * State
   */

  /**
   * @note Special handling for Moloch only.
   *   This is so we can refetch the Moloch GQL proposal and then
   *   immediately update the UI to transition to the next action
   *   button on submit.
   */
  const [submittedMolochProposalId, setSubmittedMolochProposalId] =
    useState<number>();

  /**
   * Our hooks
   */

  const {
    investmentProposal,
    investmentProposalError,
    investmentProposalNotFound,
    investmentProposalStatus,
    refetchInvestmentProposal,
  } = useInvestmentProposalFromDB(uuid);

  const {canViewInvestmentProposal, getInvestmentProposalPermissions} =
    useCanViewInvestmentProposal();

  // @note Uses the Nullish coalescing operator (`??`) to allow falsey `0` values.
  const {molochGQLProposal} = useMolochProposal(
    submittedMolochProposalId ?? investmentProposal?.molochProposalId ?? null
  );

  /**
   * Their hooks
   */

  const history = useHistory();
  const location = useLocation<any>();

  /**
   * Variables
   */

  /**
   * @note This is currently only used for Minion proposals (submitted via Admin).
   * @note We use `parseInt` instead of `Number` so null values are not returned as `0`.
   */
  const molochProposalIdSearchParam = parseInt(
    getQueryStringParam('molochProposalId', location.search) || ''
  );
  const molochProposalIdSearchParamSafe = isNaN(molochProposalIdSearchParam)
    ? null
    : molochProposalIdSearchParam;

  const canView: boolean = canViewInvestmentProposal({
    ...investmentProposal,
    ...molochGQLProposal,
  });

  const {isMolochProposerOrApplicant, isMember, isAdmin, isProposalOwner} =
    getInvestmentProposalPermissions({
      ...investmentProposal,
      ...molochGQLProposal,
    });

  const investmentContextValue: InvestmentProposalContextValue = {
    molochGQLInvestmentProposal: molochGQLProposal,
  };

  const isNavigatingPostSubmission: boolean =
    location.state?.isNavigatingPostSubmission === true;

  /**
   * Effects
   */

  // Navigate to 404
  useEffect(() => {
    if (investmentProposalNotFound) {
      history.push('/404');
    }
  }, [history, investmentProposalNotFound]);

  // Clean up `location.state` after mount.
  useEffect(() => {
    if (isNavigatingPostSubmission) {
      const locationState = {...location.state};

      delete locationState.isNavigatingPostSubmission;

      // Use `window.history` as the `history` which comes with React Router will re-render the component.
      window.history.replaceState({...location, state: locationState}, '');
    }
  }, [isNavigatingPostSubmission, location]);

  /**
   * Functions
   */

  function handleOnActionComplete(data?: {molochProposalId?: number}) {
    if (data?.molochProposalId && data.molochProposalId >= 0) {
      setSubmittedMolochProposalId(data.molochProposalId);
    }

    refetchInvestmentProposal();
  }

  /**
   * Render logic
   */

  // Render loading
  if (
    investmentProposalStatus === FetchStatus.STANDBY ||
    investmentProposalStatus === FetchStatus.PENDING
  ) {
    return (
      <WrapHelper canView={canView} proposal={investmentProposal}>
        <div style={{width: '3rem', margin: '0 auto'}}>
          <HiThereLoader />
        </div>

        <p className="text-center">Loading&hellip;</p>
      </WrapHelper>
    );
  }

  // Render error if something went wrong while getting the page
  if (investmentProposalError) {
    return (
      <WrapHelper canView={canView} proposal={investmentProposal}>
        <ErrorMessageWithDetails renderText="Something went wrong while getting the proposal." />
      </WrapHelper>
    );
  }

  // Render an unauthorized message
  if (canView === false) {
    // Render a slightly different message if the viewer has arrived after submitting an application.
    if (isNavigatingPostSubmission) {
      return (
        <WrapHelper canView={canView} proposal={investmentProposal}>
          <p className="color-yellow text-center" style={{marginTop: '4rem'}}>
            Your application has been submitted. Since it&rsquo;s private,
            you&rsquo;ll need to connect the wallet you identified in the
            application form ({investmentProposal?.addressToFund}) in order to
            view and keep track of the proposal. Click the &ldquo;Connect
            Wallet&rdquo; button on the top right of the page to do so.
          </p>
        </WrapHelper>
      );
    }

    return (
      <WrapHelper canView={canView} proposal={investmentProposal}>
        <p className="color-yellow text-center" style={{marginTop: '4rem'}}>
          You cannot view this proposal because you&rsquo;re not a member, or if
          you&rsquo;re the applicant your wallet is not connected with the
          address you identified in the application form (
          {investmentProposal?.addressToFund}).
        </p>
      </WrapHelper>
    );
  }

  // Render investment proposal
  if (investmentProposal) {
    return (
      <WrapHelper canView={canView} proposal={investmentProposal}>
        <InvestmentProposalContext.Provider value={investmentContextValue}>
          <InvestmentProposal
            canEdit={isProposalOwner}
            canViewPrivilegedDetails={
              isAdmin ||
              isMember ||
              isMolochProposerOrApplicant ||
              isProposalOwner
            }
            proposal={investmentProposal}
            renderActions={() => (
              <InvestmentProposalActions
                defaultTarget={
                  molochProposalIdSearchParamSafe !== null ? 'moloch' : null
                }
                onActionComplete={handleOnActionComplete}
                proposal={investmentProposal}
              />
            )}
          />
        </InvestmentProposalContext.Provider>
      </WrapHelper>
    );
  }

  // Render nothing. Should never reach this case.
  return <></>;
}

/**
 * WrapHelper
 *
 * A wrapper with Helmet data and common markup.
 *
 * @returns {JSX.Element}
 */
function WrapHelper({
  children,
  canView,
  proposal,
}: {
  canView: boolean;
  proposal: GetInvestmentProposalResponse | undefined;
} & React.PropsWithChildren<React.ReactNode>): JSX.Element {
  return (
    <>
      {/* HELMET META */}
      {proposal && (
        <InvestmentProposalHelmet canView={canView} proposal={proposal} />
      )}

      {/* WRAP CHILDREN */}
      <Wrap className={'section-wrapper'}>
        <FadeIn>{children}</FadeIn>
      </Wrap>
    </>
  );
}
