import React, {useEffect, useState} from 'react';
import ReactModal from 'react-modal';
import {useSelector} from 'react-redux';

import {FetchStatus} from '../../util/enums';
import {useKYCContributors, useLatestETHPrice} from '../../hooks';
import {UserKYCInfo, Sale, StoreState} from '../../util/types';
import {useSaleBuyerInfo, useSaleInfo} from '../../hooks';

import ErrorMessageWithDetails from '../../components/common/ErrorMessageWithDetails';
import FadeIn from '../../components/common/FadeIn';
import SaleConfirmViews from './SaleConfirmViews';

import s from '../../assets/scss/modules/sale.module.scss';
import m from '../../assets/scss/modules/modal.module.scss';

type SaleModalProps = {
  onRequestClose: () => void;
  sales: Sale[];
  show: boolean;
  userInfo: UserKYCInfo;
};

export type SaleModalContextValue = {
  buyerAccountInfo: ReturnType<typeof useSaleBuyerInfo>;
  latestETHPrice: ReturnType<typeof useLatestETHPrice>;
  maxNewMembersLimitReached: boolean | undefined;
  preventModalClose: (shouldClose: boolean) => void;
  saleInfo: ReturnType<typeof useSaleInfo>;
  userInfo: UserKYCInfo;
};

export const SaleModalContext = React.createContext<SaleModalContextValue>(
  {} as SaleModalContextValue
);

const useKYCContributorsQueryOptions = {
  /**
   * Set a frequent poll interval for a more accurate, real-time count.
   *
   * This helps when many new people are competing for entry into the DAO
   * with a limited number of seats.
   */
  pollInterval: 2000,
};

/**
 * SaleModal Component
 *
 * Renders a sale modal in order to purchase shares of the LAO.
 *
 * @param {SaleModalProps} props
 */
export default function SaleModal(props: SaleModalProps) {
  /**
   * Selectors
   */

  const isMemberActive = useSelector(
    (s: StoreState) => s.connectedMember.isMemberActive
  );

  /**
   * State
   */

  const [shouldPreventModalClose, setShouldPreventModalClose] =
    useState<boolean>(false);
  const [maxNewMembersLimitReached, setMaxNewMembersLimitReached] = useState<
    boolean | undefined
  >();

  /**
   * Our hooks
   */

  const {totalMembersCount, kycMembersStatus} = useKYCContributors(
    useKYCContributorsQueryOptions
  );
  const buyerAccountInfo = useSaleBuyerInfo(props.sales);
  const latestETHPrice = useLatestETHPrice();
  const saleInfo = useSaleInfo();

  /**
   * Variables
   */

  const onRequestCloseFunction = shouldPreventModalClose
    ? () => {}
    : props.onRequestClose;

  const contextValue: SaleModalContextValue = {
    buyerAccountInfo,
    latestETHPrice,
    maxNewMembersLimitReached,
    preventModalClose: setShouldPreventModalClose,
    saleInfo,
    userInfo: props.userInfo,
  };

  /**
   * Effects
   */

  /**
   * Determine if the maximum number of new members has been reached.
   *
   * We take into account whether the connected user is already
   * a member of the current DAO, and the number of contributors in the smart
   * contract (via subgraph) who are KYCed. For example:
   *
   * If they are a current member and the number of current members is >=
   * the static org value of `saleInfo.maxMembers` we should set `false`.
   *
   * If they are not a current member and the number of current members >=
   * the static org value of `saleInfo.maxMembers`, then we set `true`.
   */
  useEffect(() => {
    if (
      saleInfo.saleInfoStatus !== FetchStatus.FULFILLED ||
      kycMembersStatus !== FetchStatus.FULFILLED
    ) {
      return;
    }

    setMaxNewMembersLimitReached(
      !isMemberActive && totalMembersCount >= saleInfo.saleInfo.maxMembers
    );
  }, [
    isMemberActive,
    kycMembersStatus,
    saleInfo.saleInfo.maxMembers,
    saleInfo.saleInfoStatus,
    totalMembersCount,
  ]);

  // If there was a fetch error
  if (
    saleInfo.saleInfoStatus === FetchStatus.REJECTED ||
    kycMembersStatus === FetchStatus.REJECTED
  ) {
    return (
      <SaleModalWrapper
        isOpen={props.show}
        onRequestClose={onRequestCloseFunction}>
        <h2>Oops!</h2>
        {/* ERROR */}
        <ErrorMessageWithDetails
          renderText={
            'Something went wrong while getting the contribution information.'
          }
        />
      </SaleModalWrapper>
    );
  }

  return (
    <SaleModalWrapper
      isOpen={props.show}
      onRequestClose={onRequestCloseFunction}>
      {/* CONFIRM PAGES */}
      <SaleModalContext.Provider value={contextValue}>
        <SaleConfirmViews />
      </SaleModalContext.Provider>
    </SaleModalWrapper>
  );
}

// Helper for rendering different text as children
function SaleModalWrapper(
  props: React.PropsWithChildren<React.ReactNode> & {
    onRequestClose: () => void;
    isOpen: boolean;
  }
) {
  return (
    <ReactModal
      ariaHideApp={false}
      className={`${m['modal-content-wide']}`}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      overlayClassName={`${m['modal-overlay']} org-modal-overlay`}
      role="dialog"
      style={{overlay: {zIndex: '99'}, content: {maxWidth: '52.5rem'}} as any}>
      <FadeIn>
        <div className={`${s.wrap} ${s.gradient} ${s.modalWrap} org-modal`}>
          <div className={`${s.sales} ${m['modal-title']} card`}>
            {props.children}
          </div>
        </div>
      </FadeIn>
    </ReactModal>
  );
}
