import React, {useContext, useEffect, useState} from 'react';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';

import {formatNumber} from '../../util/helpers';
import {GetInvestmentProposalResponse} from '../../util/types';
import {InvestmentProposalContext} from './InvestmentProposalContainer';
import {useLatestETHPrice} from '../../hooks';

type InvestmentPropsalAmountRequestedProps = {
  proposal: GetInvestmentProposalResponse;
};

export default function InvestmentPropsalAmountRequested(
  props: InvestmentPropsalAmountRequestedProps
) {
  const {proposal} = props;
  const {amountRequested, amountRequestedCurrency} = proposal;

  /**
   * State
   */

  const [amountRequestedETH, setAmountRequestedETH] = useState<string>('');
  const [amountRequestedUSD, setAmountRequestedUSD] = useState<string>('');

  /**
   * Our hooks
   */

  const ethPrice = useLatestETHPrice();

  /**
   * Their hooks
   */

  const {molochGQLInvestmentProposal} = useContext(InvestmentProposalContext);

  /**
   * Effects
   */

  // Set the amounts via Moloch proposal data
  useEffect(() => {
    if (!molochGQLInvestmentProposal) {
      return;
    }

    const eth = Web3.utils.fromWei(
      new BigNumber(molochGQLInvestmentProposal.paymentRequested).toString()
    );
    const usd = Number(eth) * Number(ethPrice);

    setAmountRequestedETH(Number(eth).toFixed(2));
    setAmountRequestedUSD(Number(usd).toFixed(2));
  }, [ethPrice, molochGQLInvestmentProposal]);

  // Set the amounts via the backend API
  useEffect(() => {
    if (molochGQLInvestmentProposal) {
      return;
    }

    if (ethPrice === undefined || ethPrice === null) {
      return;
    }

    switch (amountRequestedCurrency) {
      case 'USD':
        const eth = (Number(amountRequested) / ethPrice).toFixed(2);
        setAmountRequestedETH(eth);
        setAmountRequestedUSD(amountRequested);

        break;
      case 'ETH':
        const usd = (Number(amountRequestedETH) * Number(ethPrice)).toFixed(2);
        setAmountRequestedETH(amountRequested);
        setAmountRequestedUSD(usd);

        break;
      default:
        setAmountRequestedETH('0');
    }
  }, [
    ethPrice,
    molochGQLInvestmentProposal,
    amountRequested,
    amountRequestedCurrency,
    amountRequestedETH,
  ]);

  /**
   * Render
   */

  // If amount requested is USD
  if (amountRequestedCurrency === 'USD') {
    return (
      <>
        <span>{`${formatNumber(amountRequestedETH)} ETH Requested`}</span>
        <span>{`($${formatNumber(
          amountRequestedUSD
        )} ${amountRequestedCurrency})`}</span>
      </>
    );
  }

  // If amount requested is ETH
  if (amountRequestedCurrency === 'ETH') {
    return (
      <>
        <span>{`${formatNumber(
          amountRequestedETH
        )} ${amountRequestedCurrency} Requested`}</span>
        <span>{`($${formatNumber(amountRequestedUSD)} USD)`}</span>
      </>
    );
  }

  // Default fallthrough
  return null;
}
