import {useState} from 'react';
import {useSelector} from 'react-redux';

import {getAuthHeader} from '../util/helpers';
import {InvestmentProposalTarget, StoreState} from '../util/types';
import useBackendURL from './useBackendURL';

type UseUpdateInvestmentProposalPrivacyReturn = {
  privacyConfirmError: Error | undefined;
  privacyConfirmMessage: string;
  updateProposalPrivacy: (p: boolean) => Promise<void>;
};

export default function useUpdateInvestmentProposalPrivacy(
  uuid: string,
  proposalTarget?: InvestmentProposalTarget
): UseUpdateInvestmentProposalPrivacyReturn {
  const [privacyConfirmError, setPrivacyConfirmError] = useState<Error>();
  const [privacyConfirmMessage, setPrivacyConfirmMessage] =
    useState<string>('');

  /**
   * Selectors
   */

  const accessToken = useSelector(
    (s: StoreState) => s.authServer && s.authServer.accessToken
  );

  /**
   * Our hooks
   */

  const backendURL = useBackendURL();

  async function handleSavePrivacyPreference(isPrivate: boolean) {
    if (!backendURL) return;
    if (!accessToken) return;

    try {
      /**
       * @note The logic is temporary. We will use "proposals-investment"
       *   This is just in case we need to revert the proposal migration.
       */
      const endpointURL =
        proposalTarget === 'snapshot'
          ? `${backendURL}/proposals-snapshot/${uuid}/privacy`
          : proposalTarget === 'moloch'
          ? `${backendURL}/proposals/${uuid}/privacy`
          : `${backendURL}/proposals-investment/${uuid}/privacy`;

      const response = await fetch(`${endpointURL}`, {
        method: 'PATCH',
        body: JSON.stringify({private: isPrivate}),
        headers: {
          ...getAuthHeader(accessToken),
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(
          'Something went wrong while updating your privacy preference.'
        );
      }

      !privacyConfirmMessage && setPrivacyConfirmMessage('Saved!');

      // After 5 seconds, remove the confirmation message.
      !privacyConfirmMessage &&
        setTimeout(() => setPrivacyConfirmMessage(''), 5000);
    } catch (error) {
      setPrivacyConfirmError(error);
    }
  }

  return {
    privacyConfirmError,
    privacyConfirmMessage,
    updateProposalPrivacy: handleSavePrivacyPreference,
  };
}
