import React, {useRef} from 'react';
import ReactModal from 'react-modal';

import {formatEthereumAddress, getOrgText} from '../../util/helpers';
import {ETHERSCAN_URLS} from '../../util/config';
import {StoreState} from '../../util/types';
import {TokenBalance} from '../../hooks/useMolochTokenBalances';
import {useSelector} from 'react-redux';
import {Web3TxStatus} from '../../util/enums';
import CopySVG from '../../assets/svg/CopySVG';
import EthereumSVG from '../../assets/svg/EthereumSVG';
import FadeIn from '../../components/common/FadeIn';
import Loader from '../../components/feedback/Loader';
import TokensMultiSVG from '../../assets/svg/TokensMultiSVG';
import TimesSVG from '../../assets/svg/TimesSVG';
import UnwrapWETH from '../../components/contract/UnwrapWETH';
import Withdraw from '../../components/contract/Withdraw';

import mb from '../../assets/scss/modules/memberbalancesmanager.module.scss';
import modal from '../../assets/scss/modules/modal.module.scss';
import sm from '../../assets/scss/modules/sale.module.scss';
import b from '../../assets/scss/modules/buttons.module.scss';

/**
 * MemberBalancesManagerProps
 *
 * Fetch token balances ahead of time and pass in as props,
 * so we don't need to wait for this page to load.
 *
 * `erc20Balances` - Balances of the user's tokens (approved in Moloch) at their ERC20 address.
 *
 * `molochTokenBalances` - Balances of the user's tokens (approved in Moloch) in the Moloch contract.
 */
type MemberBalancesManagerProps = {
  erc20Balances: TokenBalance[];
  molochTokenBalances: TokenBalance[];
  onClose: () => void;
  shouldOpen: boolean;
  /**
   * A callback triggered `onSuccess` of any token updates; allows the
   * opportunity to pass in updated balances to update the UI.
   */
  updateBalances: () => void;
};

export default function MemberBalancesManager(
  props: MemberBalancesManagerProps
) {
  const web3Instance = useSelector(
    (s: StoreState) => s.blockchain.web3Instance
  );
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');

  const copyEthAddressButton = useRef<HTMLSpanElement>(null);

  const {
    erc20Balances,
    molochTokenBalances,
    onClose,
    shouldOpen,
    updateBalances,
  } = props;
  const filteredMolochBalances = molochTokenBalances.filter((b) =>
    Number(b.balance)
  );
  const filteredERC20Balances = erc20Balances.filter((b) => Number(b.balance));

  function fromWEIFormat(wei: string) {
    return web3Instance
      ? Number(web3Instance.utils.fromWei(wei)).toFixed(2)
      : '0';
  }

  function EtherscanLink(props: {address: string; children: React.ReactChild}) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${ETHERSCAN_URLS[chainId]}/address/${props.address}`}>
        {props.children}
      </a>
    );
  }

  /**
   * handleETHAddressCopy
   *
   * @see: https://gist.github.com/scottoffen/1363764209058daafed2#the-encapsulated-object
   * @todo generalise this - refactor into own component
   * @todo Use https://github.com/nkbt/react-copy-to-clipboard (execCommand is obsolete)
   */
  function handleETHAddressCopy() {
    const copyButtonEl = copyEthAddressButton.current;

    if (!copyButtonEl) return;

    const clipboard = {
      intercept: false,
      hook: function (event: any) {
        if (clipboard.intercept) {
          event.preventDefault();
          event.clipboardData.setData(
            'text/plain',
            copyButtonEl.getAttribute('data-copy')
          );
          clipboard.intercept = false;
        }
      },
    };

    window.addEventListener('copy', clipboard.hook);

    clipboard.intercept = true;

    document.execCommand('copy');

    window.removeEventListener('copy', clipboard.hook);
  }

  function renderWithdrawalButton(tokenBalance: TokenBalance) {
    return (
      <Withdraw
        max
        onSuccess={updateBalances}
        withdrawals={{[tokenBalance.address]: tokenBalance.balance}}
        render={({handleSubmit, txStatus}) => (
          <button
            className={`${mb['button-w-icon']} org-mini-modal-button`}
            disabled={
              txStatus === Web3TxStatus.AWAITING_CONFIRM ||
              txStatus === Web3TxStatus.PENDING ||
              txStatus === Web3TxStatus.FULFILLED
            }
            onClick={
              txStatus === Web3TxStatus.AWAITING_CONFIRM ||
              txStatus === Web3TxStatus.PENDING ||
              txStatus === Web3TxStatus.FULFILLED
                ? () => {}
                : handleSubmit
            }>
            <span>
              {txStatus === Web3TxStatus.AWAITING_CONFIRM ||
              txStatus === Web3TxStatus.PENDING ? (
                <Loader />
              ) : (
                <TokensMultiSVG height="24" width="24" />
              )}
            </span>
            <span className={mb['button-w-icon__text']}>
              {txStatus === Web3TxStatus.AWAITING_CONFIRM
                ? 'Preparing\u2026'
                : txStatus === Web3TxStatus.PENDING
                ? 'Withdrawing\u2026'
                : 'Withdraw'}
            </span>
          </button>
        )}
      />
    );
  }

  function renderUnwrapWETH(erc20TokenBalance: TokenBalance) {
    return (
      <UnwrapWETH
        amountToUnwrapInWEI={erc20TokenBalance.balance}
        onSuccess={updateBalances}
        render={({handleSubmit, txStatus}) => (
          <button
            className={`${mb['button-w-icon']} org-mini-modal-button`}
            disabled={
              txStatus === Web3TxStatus.AWAITING_CONFIRM ||
              txStatus === Web3TxStatus.PENDING ||
              txStatus === Web3TxStatus.FULFILLED
            }
            onClick={
              txStatus === Web3TxStatus.AWAITING_CONFIRM ||
              txStatus === Web3TxStatus.PENDING ||
              txStatus === Web3TxStatus.FULFILLED
                ? () => {}
                : handleSubmit
            }>
            <span>
              {txStatus === Web3TxStatus.AWAITING_CONFIRM ||
              txStatus === Web3TxStatus.PENDING ? (
                <Loader />
              ) : (
                <EthereumSVG height="24" width="16" />
              )}
            </span>
            <span className={mb['button-w-icon__text']}>
              {txStatus === Web3TxStatus.AWAITING_CONFIRM
                ? 'Preparing\u2026'
                : txStatus === Web3TxStatus.PENDING
                ? 'Converting\u2026'
                : 'Convert to ETH'}
            </span>
          </button>
        )}
      />
    );
  }

  // Do not render if there's no web3Instance ready
  if (!web3Instance) return null;

  return (
    <ReactModal
      ariaHideApp={false}
      className={`${modal['modal-content-wide']}`}
      isOpen={shouldOpen}
      onRequestClose={onClose}
      overlayClassName={`${modal['modal-overlay']} org-modal-overlay`}
      role="dialog"
      style={
        {
          overlay: {zIndex: '99'},
          content: {
            maxWidth: '32.5rem',
          },
        } as any
      }>
      <FadeIn>
        <div className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} org-modal`}>
          <div className={`${sm.sales} ${modal['modal-title']} card`}>
            {/* MODEL CLOSE BUTTON */}
            <span
              className={`${b['modal-close']} org-modal-close`}
              onClick={onClose}>
              <TimesSVG />
            </span>
            <div className="titlebar">
              <h2 className="titlebar__title org-titlebar__title">Balances</h2>
            </div>

            {filteredERC20Balances.length > 0 && (
              <div className={`${mb.section} org-member-balances-section`}>
                <h3 className={`${mb.heading} org-member-balances-heading`}>
                  Tokens you hold
                </h3>

                {filteredERC20Balances.map((b) => (
                  <FadeIn key={b.address}>
                    <div className={`${mb.row}`}>
                      <div className={`font-mono`}>
                        <div>
                          {b.symbol || formatEthereumAddress(b.address, 10)}:{' '}
                          <span>{fromWEIFormat(b.balance)}</span>
                        </div>
                        <div className={mb['eth-address__wrap']}>
                          <small>
                            <EtherscanLink address={b.address}>
                              {formatEthereumAddress(b.address, 10)}
                            </EtherscanLink>
                          </small>
                          <span
                            className={`${mb.copy} org-member-balances-copy`}
                            data-copy={b.address}
                            onClick={handleETHAddressCopy}
                            ref={copyEthAddressButton}>
                            <CopySVG height="16" width="16" />
                          </span>
                        </div>
                      </div>

                      {/* We can offer an unwrap for WETH */}
                      {b.symbol === 'WETH' && renderUnwrapWETH(b)}
                    </div>
                  </FadeIn>
                ))}
              </div>
            )}

            {filteredMolochBalances.length > 0 && (
              <div className={`${mb.section} org-member-balances-section`}>
                <h3 className={`${mb.heading} org-member-balances-heading`}>
                  Tokens in {orgName}
                </h3>

                {filteredMolochBalances.map((b) => (
                  <FadeIn key={b.address}>
                    <div className={`${mb.row}`}>
                      <div className="font-mono">
                        <div>
                          {b.symbol || formatEthereumAddress(b.address, 10)}:{' '}
                          <span>{fromWEIFormat(b.balance)}</span>
                        </div>
                        <div className={mb['eth-address__wrap']}>
                          <small>
                            <EtherscanLink address={b.address}>
                              {formatEthereumAddress(b.address, 10)}
                            </EtherscanLink>
                          </small>
                          <span
                            className={`${mb.copy} org-member-balances-copy`}
                            data-copy={b.address}
                            onClick={handleETHAddressCopy}
                            ref={copyEthAddressButton}>
                            <CopySVG height="16" width="16" />
                          </span>
                        </div>
                      </div>

                      {renderWithdrawalButton(b)}
                    </div>
                  </FadeIn>
                ))}
              </div>
            )}
          </div>
        </div>
      </FadeIn>
    </ReactModal>
  );
}
