import React from 'react';

import {MemberExists, MemberWhitelisted} from '../Members';

type CompletedProposalProps = {
  isWhitelisted: boolean;
  isVerified: boolean;
  proposal: Record<string, any>;
};

const DidPass = () => (
  <span role="img" aria-label="emoji tick">
    ✅
  </span>
);
const DidNotPass = () => (
  <span role="img" aria-label="emoji cross">
    ⛔️
  </span>
);

export const CompletedProposalHeader = (
  <thead>
    <tr>
      <th>Type</th>
      <th>Proposal Index</th>
      <th>Proposal Id</th>
      <th>Ethereum Address</th>
      <th>Did Pass</th>
    </tr>
  </thead>
);

export default function CompletedProposal(props: CompletedProposalProps) {
  const {isWhitelisted, isVerified, proposal} = props;
  const {applicant, proposalId, didPass, proposalIndex} = proposal;

  return (
    <tr>
      <td>
        {isVerified && <MemberExists />}
        {isWhitelisted && <MemberWhitelisted />}
      </td>
      <td>{proposalIndex}</td>
      <td>{proposalId}</td>
      <td>{applicant}</td>
      <td>{didPass ? <DidPass /> : <DidNotPass />}</td>
    </tr>
  );
}
