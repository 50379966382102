import {useState, useEffect, useCallback} from 'react';

import {FetchStatus} from '../util/enums';
import {SaleInfo} from '../util/types';
import {useAbortController} from './useAbortController';
import {useBackendURL} from '.';

const DEFAULT_SALE_INFO: SaleInfo = {
  amountSharesSold: 0,
  ethPerChunk: 0,
  ethPerPercent: 0,
  isFullySubscribed: false,
  maxMembers: 0,
  maxPercent: 0,
  maxShares: 0,
  minPercent: 0,
  minShares: 0,
  percentIncrement: 0,
  percentSharesSold: 0,
  sharesPerChunk: 0,
  sharesPerPercent: 0,
  totalShares: 0,
  totalAuthorizedUnits: 0,
};

/**
 * useSaleInfo
 *
 * Returns The LAO's sale info from our server.
 *
 * @returns {SaleInfo}
 */
export default function useSaleInfo() {
  /**
   * State
   */

  const [saleInfo, setSaleInfo] = useState<SaleInfo>(DEFAULT_SALE_INFO);
  const [saleInfoStatus, setSaleInfoStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );

  /**
   * Our hooks
   */

  const {abortController, isMountedRef} = useAbortController();
  const backendURL = useBackendURL();

  /**
   * Cached callbacks
   */

  const fetchSalesInfoCached = useCallback(fetchSalesInfo, [
    abortController,
    backendURL,
    isMountedRef,
  ]);

  useEffect(() => {
    fetchSalesInfoCached();

    return () => {
      abortController && abortController.abort();
    };
  }, [abortController, fetchSalesInfoCached, isMountedRef]);

  /**
   * Functions
   */

  async function fetchSalesInfo() {
    try {
      if (!abortController) return;
      if (!backendURL) return;

      setSaleInfoStatus(FetchStatus.PENDING);

      const response = await fetch(`${backendURL}/sales/info`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        signal: abortController.signal,
      });

      if (!response.ok) {
        throw new Error(
          'Something went wrong while getting the latest sales information.'
        );
      }

      const responseJSON = await response.json();

      if (!isMountedRef.current) return;

      setSaleInfo(responseJSON);
      setSaleInfoStatus(FetchStatus.FULFILLED);
    } catch (error) {
      if (!isMountedRef.current) return;

      setSaleInfo(DEFAULT_SALE_INFO);
      setSaleInfoStatus(FetchStatus.REJECTED);
    }
  }

  return {saleInfo, saleInfoStatus};
}
