import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

// import { useWeb3Modal } from "../hooks";
import {useWeb3Modal} from '../components/web3/Web3ModalManager';
import {CHAIN_NAME} from '../util/config';
import {StoreState} from '../util/types';

/**
 * useIsDefaultChain
 *
 * Checks if the connected account it connected to the default chain
 * @returns {
 *  defaultChain: number,
 *  defaultChainError: string,
 *  isDefaultChain: boolean
 * }
 */
export default function useIsDefaultChain() {
  const {networkId} = useWeb3Modal();
  const defaultChain = useSelector(
    (state: StoreState) => state.blockchain.defaultChain
  );
  const [isDefaultChain, setIsDefaultChain] = useState<boolean>(false);
  const [defaultChainError, setDefaultChainError] = useState<string>('');

  useEffect(() => {
    setIsDefaultChain(networkId === defaultChain);

    if (networkId !== defaultChain) {
      setDefaultChainError(
        `You're connected to an unsupported network. Please use the ${CHAIN_NAME[defaultChain]}`
      );
    }
  }, [networkId, defaultChain]);

  return {
    defaultChain,
    defaultChainError,
    isDefaultChain,
  };
}
