import {ApolloLink, createHttpLink} from '@apollo/client';
import {RetryLink} from '@apollo/client/link/retry';

import {getNextArrayItem} from '../../util/helpers';

type ApolloLinkWithFallbackContext = {
  uri: string;
};

/**
 * getFallbackURL
 *
 * Derives the fallback URL from the complete
 * subgraph URL of the `primaryURL` and concatenates the
 * fallback host and the URI path for the subgraph.
 *
 * @param {string} primaryURL
 * @returns {string}
 */
function getFallbackURL(primaryURL: string = '', fallbackHost: string): string {
  if (!fallbackHost) return primaryURL;

  const subgraphName: string = primaryURL
    // Only return `subgraphs/name/<name>/<subgraphName>?opname=<opName>`
    .replace(/(.*)\/(subgraphs\/name\/.*)/, '$2');

  return `${fallbackHost}/${subgraphName}`;
}

export function apolloLinkWithFallback(
  graphURL: string,
  fallbackHost: string
): ApolloLink {
  const graphURLs: string[] = [
    graphURL,
    getFallbackURL(graphURL, fallbackHost),
  ];
  const errorURLs = new Set<string>();

  const filterGraphURLs = () => graphURLs.filter((u) => !errorURLs.has(u));
  const getOpnameQuery = (operationName: string): string =>
    `?opname=${operationName}`;

  /**
   * Create retry link
   *
   * Runs after the `HttpLink`, if there is a network error.
   * Here we set the context to a new `uri` and then the `HttpLink`
   * will be retried, but with a new `uri`.
   */
  const retryLink = new RetryLink({
    attempts: {
      max: graphURLs.length,
      retryIf: (networkError, operation) => {
        const {operationName, setContext} = operation;

        // Set a new context on error to use the next fallback URI.
        setContext((prevContext: ApolloLinkWithFallbackContext) => {
          errorURLs.add(prevContext.uri);

          // Get next URI from `prevContext`
          const nextURI = getNextArrayItem<string>(
            // Remove the `?opname=<opName>` so we can compare against the `graphURLs`
            (prevContext.uri || '').replace(/\?opname=.*/, ''),
            filterGraphURLs()
          );

          // Return the new `uri` to be set.
          return {
            uri: `${nextURI}${getOpnameQuery(operationName)}`,
          };
        });

        return !!networkError;
      },
    },
  });

  // Create HTTP link
  const httpLink = createHttpLink({
    uri: ({getContext, operationName, setContext}) => {
      const ctx = getContext() as ApolloLinkWithFallbackContext;
      const nextURI = !ctx.uri ? filterGraphURLs()[0] : ctx.uri;

      // Set context so the `RetryLink` knows what `uri` came before.
      setContext({uri: nextURI});

      return `${nextURI}${getOpnameQuery(operationName)}`;
    },
    fetchOptions: {
      mode: 'cors',
    },
  });

  // return Apollo link chain
  return ApolloLink.from([retryLink, httpLink]);
}
