import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import Web3 from 'web3';

import {
  formatNumber,
  dontCloseWindowWarning,
  stripFormatNumber,
} from '../../util/helpers';
import {ETHERSCAN_URLS} from '../../util/config';
import {StoreState} from '../../util/types';
import {useCanRagequit} from './hooks';
import {useETHGasPrice, useBackendURL} from '../../hooks';

type RenderProps = {
  canRagequit: boolean;
  etherscanURL: string | undefined;
  handleLootChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSharesChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => Promise<void>;
  isSubmitDone: boolean;
  isSubmitting: boolean;
  loot: string | undefined;
  lootToBurn: string;
  lootToBurnDisplay: string | undefined;
  shares: string | undefined;
  sharesToBurn: string;
  sharesToBurnDisplay: string | undefined;
  submitError: Error | undefined;
};

type RagequitProps = {
  onError: (e: Error) => void;
  onProcess: () => void;
  onSuccess: () => void;
  render: (r: RenderProps) => React.ReactElement;
};

/**
 * Ragequit
 *
 * Provides the ability to "ragequit" from a Moloch contract.
 * Render any UI you'd like using the `render` prop.
 * Props (`RenderProps`) will be passed into `render`'s function component to be acted upon.
 *
 * @param {RagequitProps} props
 */
export default function Ragequit(props: RagequitProps) {
  /**
   * Selectors
   */

  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );
  const connectedMember = useSelector((s: StoreState) => s.connectedMember);
  const VentureMoloch = useSelector(
    (s: StoreState) =>
      s.blockchain.contracts && s.blockchain.contracts.VentureMoloch
  );
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );

  /**
   * External hooks
   */

  const {canRagequit} = useCanRagequit();
  const backendURL = useBackendURL();
  const {average: gasPrice} = useETHGasPrice();

  /**
   * State
   * @note Good to set them separately, or using useReducer when there's many state pieces
   * and especially when we're using callbacks which their closures store possible stale values inside.
   */
  const [etherscanURL, setEtherscanURL] = useState<string>();
  const [isSubmitDone, setIsSubmitDone] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [lootToBurn, setLootToBurn] = useState<string>('');
  const [lootToBurnDisplay, setLootToBurnDisplay] = useState<string>();
  const [sharesToBurn, setSharesToBurn] = useState<string>('');
  const [sharesToBurnDisplay, setSharesToBurnDisplay] = useState<string>();
  const [submitError, setSubmitError] = useState<Error>();

  function handleAmountToBurnChange(type: 'shares' | 'loot') {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;
      const typeToNumber = Number(connectedMember[type]);
      const stripCommasNumber = Number(stripFormatNumber(value));

      const isNumber = Boolean(stripCommasNumber);
      const amountToBurnFormattedString = isNumber
        ? formatNumber(Math.ceil(stripCommasNumber))
        : '';
      const amountToBurnCappedFormatted =
        stripCommasNumber > typeToNumber && typeToNumber !== 0
          ? // set to the max available if user typed an amount over
            formatNumber(Math.ceil(Number(connectedMember[type])))
          : stripCommasNumber > 0 && typeToNumber !== 0
          ? // set to the amount user typed
            amountToBurnFormattedString
          : // set to blank, as it's most likely 0
            '';
      const amountToBurnCappedParsed = stripFormatNumber(
        amountToBurnCappedFormatted
      );

      switch (type) {
        case 'shares':
          setSharesToBurn(amountToBurnCappedParsed);
          setSharesToBurnDisplay(amountToBurnCappedFormatted);

          break;
        case 'loot':
          setLootToBurn(amountToBurnCappedParsed);
          setLootToBurnDisplay(amountToBurnCappedFormatted);

          break;
        default:
          break;
      }
    };
  }

  async function handleRageQuit() {
    // activate "don't close window" warning
    const unsubscribeDontCloseWindow = dontCloseWindowWarning();

    try {
      if (!backendURL) throw new Error('No backend URL was found.');
      if (!canRagequit) throw new Error('Member not allowed to ragequit.');
      if (!VentureMoloch) throw new Error('No VentureMoloch contract found.');
      if (!connectedAddress) throw new Error('No user account found.');
      if (!sharesToBurn && !lootToBurn)
        throw new Error('No shares or loot set.');

      // Determine if the ragequit is complete or partial;
      // it is helpful to the server for sending particular emails.
      const isFullRagequit =
        Number(connectedMember.shares) === Number(sharesToBurn) &&
        Number(connectedMember.loot) === Number(lootToBurn);

      setIsSubmitting(true);

      props.onProcess();

      await VentureMoloch.instance.methods
        .ragequit(Web3.utils.toBN(sharesToBurn), Web3.utils.toBN(lootToBurn))
        // `send` returns a Promise
        .send({
          from: connectedAddress,
          // set proposed gas price
          ...(gasPrice ? {gasPrice} : null),
        })
        .on('transactionHash', (txHash: string) => {
          setEtherscanURL(`${ETHERSCAN_URLS[chainId]}/tx/${txHash}`);

          // send to backend to handle post-ragequit actions
          fetch(`${backendURL}/members/${connectedAddress}/ragequit`, {
            method: 'POST',
            body: JSON.stringify({
              isFullRagequit,
              lootBurned: Number(lootToBurn),
              percentOfLootBurned: Number(connectedMember.loot)
                ? (Number(lootToBurn) / Number(connectedMember.loot)) * 100
                : 0,
              percentOfSharesBurned: Number(connectedMember.shares)
                ? (Number(sharesToBurn) / Number(connectedMember.shares)) * 100
                : 0,
              sharesBurned: Number(sharesToBurn),
              txHash,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          });
        })
        .on('error', (error: Error) => {
          setIsSubmitting(false);
          setSubmitError(error);

          props.onError(error);

          unsubscribeDontCloseWindow();
        });

      setIsSubmitDone(true);
      setIsSubmitting(false);

      props.onSuccess();

      unsubscribeDontCloseWindow();
    } catch (error) {
      setIsSubmitting(false);
      setSubmitError(error);

      props.onError(error);

      unsubscribeDontCloseWindow();
    }
  }

  return props.render({
    canRagequit,
    etherscanURL,
    handleLootChange: handleAmountToBurnChange('loot'),
    handleSharesChange: handleAmountToBurnChange('shares'),
    handleSubmit: handleRageQuit,
    isSubmitDone,
    isSubmitting,
    loot: connectedMember.loot,
    lootToBurn,
    lootToBurnDisplay,
    shares: connectedMember.shares,
    sharesToBurn,
    sharesToBurnDisplay,
    submitError,
  });
}
