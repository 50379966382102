import React from 'react';

import IdentityBaseForm from './IdentityBaseForm';
import {OnFormChangeCallback, Forms} from './types';

type IdentityPersonFormProps = {
  onFormChange: OnFormChangeCallback;
};

/**
 * IdentityBaseForm
 *
 * A form that is used in every identification pathway: Person, Company, Trust.
 * NOTE: Keep this form generic as possible, as other forms compose it.
 */
export default function IdentityPersonForm(props: IdentityPersonFormProps) {
  return (
    <>
      <header>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">Identity</h2>
        </div>
      </header>

      <div className="org-verify-form-wrap">
        <form className="org-verify-form" onSubmit={(e) => e.preventDefault()}>
          <IdentityBaseForm
            onFormChange={(formData) => {
              props.onFormChange({
                ...formData,
                formName: Forms.personIdentity,
              });
            }}
          />
        </form>
      </div>
    </>
  );
}
