import {useEffect, useState} from 'react';
import Web3 from 'web3';

import {FetchStatus} from '../util/enums';
import useLatestETHPrice from './useLatestETHPrice';

/**
 * useUSDToWEI
 *
 * Converts a USD number value above 0 to a WEI string.
 *
 * @param paymentRequested
 */
export function useUSDToWEI(USD: number | undefined) {
  /**
   * State
   */

  const [WEIFromUSD, setWEIFromUSD] = useState<string>();
  const [WEIFromUSDStatus, setWEIFromUSDStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );

  /**
   * External hooks
   */

  const ethPrice = useLatestETHPrice();

  /**
   * Effects
   */

  useEffect(() => {
    // USD should be greater than 0, or defined.
    if (!ethPrice || !USD) {
      setWEIFromUSDStatus(FetchStatus.PENDING);

      return;
    }

    const wei = Web3.utils.toWei((USD / ethPrice).toFixed(4), 'ether');

    setWEIFromUSD(wei);
    setWEIFromUSDStatus(FetchStatus.FULFILLED);
  }, [ethPrice, USD]);

  return {WEIFromUSD, WEIFromUSDStatus};
}
