import React from 'react';

type PersonAndAgreementSVGProps = {
  fromGradient?: string;
  toGradient?: string;
};

export default function PersonAndAgreementSVG({
  fromGradient,
  toGradient,
  ...restProps
}: React.SVGAttributes<SVGElement> & PersonAndAgreementSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="33"
      viewBox="0 0 30 33"
      {...restProps}>
      <defs>
        <linearGradient
          id="linearGradient-1"
          x1="1.716%"
          x2="100%"
          y1="50%"
          y2="50%">
          <stop offset="0%" stopColor={fromGradient || '#81D7FF'}></stop>
          <stop offset="100%" stopColor={toGradient || '#B6ABFD'}></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-2"
          x1="1.443%"
          x2="181.078%"
          y1="50%"
          y2="50%">
          <stop offset="0%" stopColor={fromGradient || '#80D8FF'}></stop>
          <stop offset="100%" stopColor={toGradient || '#EA80FC'}></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-3"
          x1="0%"
          x2="373.304%"
          y1="49.999%"
          y2="54.296%">
          <stop offset="0%" stopColor={fromGradient || '#80D8FF'}></stop>
          <stop offset="100%" stopColor={toGradient || '#EA80FC'}></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-430 -478)">
          <g transform="translate(429 478)">
            <g fill="url(#linearGradient-1)" transform="translate(.5)">
              <path d="M9.107 28.828c0 .647-.528 1.172-1.18 1.172h-2.71C2.615 30 .5 27.897.5 25.312V4.688C.5 2.103 2.616 0 5.216 0h14.496c2.6 0 4.716 2.103 4.716 4.688v6.972c0 .647-.528 1.172-1.18 1.172a1.175 1.175 0 01-1.178-1.172V4.687a2.354 2.354 0 00-2.358-2.343H5.216a2.354 2.354 0 00-2.358 2.344v20.625a2.354 2.354 0 002.358 2.343h2.712c.651 0 1.179.525 1.179 1.172zM19.71 8.203c0-.647-.527-1.172-1.179-1.172H6.388c-.65 0-1.179.525-1.179 1.172 0 .647.528 1.172 1.18 1.172h12.143c.652 0 1.18-.525 1.18-1.172zm-4.709 4.688c0-.648-.528-1.172-1.179-1.172H6.388c-.65 0-1.179.524-1.179 1.172 0 .647.528 1.171 1.18 1.171h7.434c.651 0 1.18-.524 1.18-1.171zm-8.614 3.515c-.65 0-1.179.525-1.179 1.172 0 .647.528 1.172 1.18 1.172h4.9c.65 0 1.178-.525 1.178-1.172 0-.647-.528-1.172-1.179-1.172h-4.9z"></path>
            </g>
            <g transform="translate(11 14)">
              <path
                fill="url(#linearGradient-2)"
                d="M12.683 10.338H7.317c-3.83 0-6.947 3.037-6.947 6.77 0 .748.622 1.354 1.39 1.354h16.48c.768 0 1.39-.606 1.39-1.354 0-3.733-3.116-6.77-6.947-6.77zm-9.296 5.416c.573-1.576 2.119-2.708 3.93-2.708h5.366c1.812 0 3.357 1.132 3.93 2.708H3.387z"></path>
              <path
                fill="url(#linearGradient-3)"
                d="M10 0C7.55 0 5.556 1.988 5.556 4.43c0 2.444 1.993 4.432 4.444 4.432 2.45 0 4.444-1.988 4.444-4.431S12.451 0 10 0zm0 6.135c-.943 0-1.71-.765-1.71-1.704a1.709 1.709 0 013.42 0c0 .94-.767 1.704-1.71 1.704z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
