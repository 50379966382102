import React from 'react';

type HasAccessProps = {
  children: React.ReactNode;
  hasAccess: (() => boolean) | boolean;
};

/**
 * HasAccess
 *
 * A component which renders `null` or `children`
 * based on the prop value of the `hasAccess` prop.
 *
 * @param props
 */
export function HasAccess(props: HasAccessProps) {
  const {hasAccess} = props;
  const hasAccessResult =
    typeof hasAccess === 'boolean' ? hasAccess : hasAccess();

  if (!hasAccessResult) {
    return null;
  }

  return <>{props.children}</>;
}
