import React, {CSSProperties} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {InternalNamesToMap} from '../util/orgDomainMappings';
import {isTributeOrgName} from '../util/helpers';
import {LeftLogo} from './logo';
import {StoreState} from '../util/types';
import Nav from './nav';

import s from '../assets/scss/modules/header.module.scss';

export default function Header() {
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  const NAV_PATH_HIDELIST = isTributeOrgName(
    orgInternalName as InternalNamesToMap
  )
    ? []
    : ['/', '/contribute'];

  const location = useLocation();
  const isOnNavHidelist = NAV_PATH_HIDELIST.includes(location.pathname);
  const isIndexPath = location.pathname === '/';
  const isSalePath = location.pathname === '/contribute';
  const softLaunchStyles: Record<string, CSSProperties> = {
    header:
      !isTributeOrgName(orgInternalName as InternalNamesToMap) &&
      (isIndexPath || isSalePath)
        ? {alignItems: 'initial', flexDirection: 'column'}
        : {},

    navContainer: {marginTop: '2.55rem'},
  };

  // Render the location with or without a link depending on `location`
  function RenderLogo(props: React.PropsWithChildren<any>) {
    return !isTributeOrgName(orgInternalName as InternalNamesToMap) &&
      isIndexPath ? (
      props.children
    ) : (
      <Link to="/">{props.children}</Link>
    );
  }

  return (
    <>
      <header
        className={`${s['header']} ${
          s[location.pathname.replace('/', '')]
        } org-header`}
        style={softLaunchStyles.header}>
        {!isOnNavHidelist && (
          <RenderLogo>
            <LeftLogo />
          </RenderLogo>
        )}

        <div
          className={`${s['page-nav']} org-page-nav`}
          style={isOnNavHidelist ? softLaunchStyles.navContainer : {}}>
          <Nav />
        </div>
      </header>
    </>
  );
}
