import gql from 'graphql-tag';

const proposalFragment = gql`
  fragment projectInformation on Proposal {
    id
    applicant
    cancelled
    details
    didPass
    endingPeriod
    noShares
    paymentRequested
    processed
    proposalId
    proposalIndex
    proposer
    sponsored
    sponsoredAt
    startingPeriod
    yesShares
  }
`;

/**
 * @todo The schema.graphql in the `lao-graph` repo, has been updated to
 * allow this functionality using the name `proposalSearch`
 * Full details here: https://thegraph.com/docs/define-a-subgraph#defining-fulltext-search-fields
 */

/**
 * @note there is an issue with The Graph fulltext search not working
 * (https://github.com/graphprotocol/graph-node/issues/1823)
 * with the `where` clause, so solution for now is to use aliases
 * https://graphql.org/learn/queries/#aliases
 */
export const GET_FUNDED_PROPOSALS = gql`
  query FundedProposals {
    project: proposals(
      orderBy: proposalIndex
      orderDirection: desc
      first: 1000
      where: {
        sharesRequested: 0
        didPass: true
        processed: true
        details_starts_with: "Project"
      }
    ) {
      ...projectInformation
    }
    minion: proposals(
      orderBy: proposalIndex
      orderDirection: desc
      first: 1000
      where: {
        sharesRequested: 0
        didPass: true
        processed: true
        details_starts_with: "minion"
      }
    ) {
      ...projectInformation
    }
  }
  ${proposalFragment}
`;

export const GET_REQUEST_PROPOSALS = gql`
  query RequestProposals {
    project: proposals(
      orderBy: proposalId
      orderDirection: desc
      first: 1000
      where: {
        sharesRequested: 0
        sponsored: false
        proposalIndex: null
        cancelled: false
        details_starts_with: "Project"
      }
    ) {
      ...projectInformation
    }
    minion: proposals(
      orderBy: proposalId
      orderDirection: desc
      first: 1000
      where: {
        sharesRequested: 0
        sponsored: false
        proposalIndex: null
        cancelled: false
        details_starts_with: "minion"
      }
    ) {
      ...projectInformation
    }
  }
  ${proposalFragment}
`;

/**
 * Gets Moloch Minion proposals which are not yet sponsored.
 */
export const GET_REQUEST_PROPOSALS_MINION = gql`
  query RequestProposalsMinion {
    minionProposals: proposals(
      orderBy: proposalId
      orderDirection: desc
      first: 1000
      where: {
        sharesRequested: 0
        sponsored: false
        proposalIndex: null
        cancelled: false
        details_starts_with: "minion"
      }
    ) {
      ...projectInformation
    }
  }
  ${proposalFragment}
`;

export const GET_VOTING_PROPOSALS = gql`
  query VotingProposals($currentPeriod: BigInt!) {
    project: proposals(
      orderBy: proposalIndex
      orderDirection: asc
      first: 1000
      where: {
        sharesRequested: 0
        sponsored: true
        processed: false
        proposalIndex_not: null
        endingPeriod_gt: $currentPeriod
        details_starts_with: "Project"
      }
    ) {
      ...projectInformation
    }
    minion: proposals(
      orderBy: proposalIndex
      orderDirection: asc
      first: 1000
      where: {
        sharesRequested: 0
        sponsored: true
        processed: false
        proposalIndex_not: null
        endingPeriod_gt: $currentPeriod
        details_starts_with: "minion"
      }
    ) {
      ...projectInformation
    }
    sidepocket: proposals(
      orderBy: proposalIndex
      orderDirection: asc
      first: 1000
      where: {
        sharesRequested: 0
        sponsored: true
        processed: false
        proposalIndex_not: null
        endingPeriod_gt: $currentPeriod
        details_contains: "SidePocket"
      }
    ) {
      ...projectInformation
    }
  }
  ${proposalFragment}
`;

export const GET_VOTING_DONE_PROPOSALS = gql`
  query VotingDoneProposals($currentPeriod: BigInt!) {
    project: proposals(
      orderBy: proposalIndex
      orderDirection: asc
      first: 1000
      where: {
        sharesRequested: 0
        sponsored: true
        proposalIndex_not: null
        endingPeriod_lte: $currentPeriod
        details_starts_with: "Project"
      }
    ) {
      ...projectInformation
    }
    minion: proposals(
      orderBy: proposalIndex
      orderDirection: asc
      first: 1000
      where: {
        sharesRequested: 0
        sponsored: true
        proposalIndex_not: null
        endingPeriod_lte: $currentPeriod
        details_starts_with: "minion"
      }
    ) {
      ...projectInformation
    }
    sidepocket: proposals(
      orderBy: proposalIndex
      orderDirection: asc
      first: 1000
      where: {
        sharesRequested: 0
        sponsored: true
        proposalIndex_not: null
        endingPeriod_lte: $currentPeriod
        details_contains: "SidePocket"
      }
    ) {
      ...projectInformation
    }
  }
  ${proposalFragment}
`;

export const GET_GRACEPERIOD_PROPOSALS = gql`
  query GracePeriodProposals($currentPeriod: BigInt!) {
    project: proposals(
      orderBy: proposalIndex
      orderDirection: asc
      first: 1000
      where: {
        sharesRequested: 0
        sponsored: true
        processed: false
        proposalIndex_not: null
        endingPeriod_lte: $currentPeriod
        details_starts_with: "Project"
      }
    ) {
      ...projectInformation
    }
    minion: proposals(
      orderBy: proposalIndex
      orderDirection: desc
      first: 1000
      where: {
        sharesRequested: 0
        sponsored: true
        processed: false
        proposalIndex_not: null
        endingPeriod_lte: $currentPeriod
        details_starts_with: "minion"
      }
    ) {
      ...projectInformation
    }
  }
  ${proposalFragment}
`;

export const GET_FAILED_PROPOSALS = gql`
  query FailedProposals {
    project: proposals(
      orderBy: proposalIndex
      orderDirection: desc
      first: 1000
      where: {
        sharesRequested: 0
        didPass: false
        processed: true
        details_starts_with: "Project"
      }
    ) {
      ...projectInformation
    }
    minion: proposals(
      orderBy: proposalIndex
      orderDirection: desc
      first: 1000
      where: {
        sharesRequested: 0
        didPass: false
        processed: true
        details_starts_with: "minion"
      }
    ) {
      ...projectInformation
    }
  }
  ${proposalFragment}
`;

export const GET_PROPOSAL = gql`
  query GetProposal($proposalId: String!) {
    proposals(where: {proposalId: $proposalId}) {
      ...projectInformation
    }
  }
  ${proposalFragment}
`;

export const GET_PROPOSALS = gql`
  query GetProposals {
    proposals(first: 1000) {
      ...projectInformation
    }
  }
  ${proposalFragment}
`;

export const GET_PROJECT_PROPOSALS = gql`
  query ProjectProposals {
    project: proposals(
      orderBy: proposalId
      orderDirection: asc
      first: 1000
      where: {details_starts_with: "Project"}
    ) {
      ...projectInformation
    }
    minion: proposals(
      orderBy: proposalId
      orderDirection: asc
      first: 1000
      where: {details_starts_with: "minion"}
    ) {
      ...projectInformation
    }
  }
  ${proposalFragment}
`;
