import {ENVIRONMENT, SNAPSHOT_HUB_API_URL} from '../../util/config';
import {SnapshotVote} from './enums';

/**
 * SNAPSHOT_HUB_URL
 *
 * @note For `ENVIRONMENT=localhost` we need to use CRA's local proxy
 *   so that we can communicate with our develop Snapshot Hub API
 *   without any CORS issues.
 *
 * @see src/setupProxy.js
 */
export const SNAPSHOT_HUB_URL: string | undefined =
  ENVIRONMENT === 'localhost' ? '/snapshot-hub' : SNAPSHOT_HUB_API_URL;

/**
 * The options for Snapshot voting
 *
 * @note Do not change the order as we rely on this to tally votes.
 *   The index of the array is submitted to the Snapshot API.
 */
export const SNAPSHOT_CHOICES: SnapshotVote[] = [
  SnapshotVote.Yes,
  SnapshotVote.No,
];

/**
 * The threshold % for a governance vote to pass.
 */
export const GOVERNANCE_PASS_PERCENTAGE = 50;

/**
 * getBalancerHubVersion
 *
 * This gets the current balancer hub version so we can call the API
 * without any errors as the API validates the version against its own package.json.
 *
 * @returns {string} Version number string
 */
export async function getBalancerHubVersion() {
  try {
    const response = await fetch(`${SNAPSHOT_HUB_URL}/api`);

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const {version} = await response.json();

    return version;
  } catch (error) {
    throw error;
  }
}

/**
 * getSnapshotIgnoreList
 *
 * Gets the ignore list, per current `ENVIRONMENT`, of Snapshot
 * proposal ID's.
 *
 * @param {string} orgInternalName
 * @returns {string[]}
 */
export async function getSnapshotIgnoreList(
  orgInternalName: string,
  fetchOptions?: Parameters<typeof fetch>[1]
): Promise<string[]> {
  try {
    // Fetch from `public/orgs/...`
    const response = await fetch(
      `/orgs/${orgInternalName.toLowerCase()}/snapshotIgnore.json`,
      fetchOptions
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const ignoreList = await response.json();

    return ENVIRONMENT ? ignoreList[ENVIRONMENT] : [];
  } catch (error) {
    throw error;
  }
}
