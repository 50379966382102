import {useCallback, useEffect, useMemo, useState} from 'react';

import {FetchStatus} from '../util/enums';
import {useBackendURL} from './';
import {buildQueryString} from '../util/helpers';
import {GetInvestmentProposalResponse} from '../util/types';
import {useAbortController} from './useAbortController';

type UseSnapshotInvestmentProposalDBReturn = {
  investmentProposals: GetInvestmentProposalResponse[];
  investmentProposalsError: Error | undefined;
  investmentProposalsStatus: FetchStatus;
};

type UseInvestmentProposalsFetchOptions = {
  sponsored?: boolean;
};

/**
 * useInvestmentProposals
 *
 * Gets investments proposals data stored in our DB.
 *
 * @param {UseInvestmentProposalsFetchOptions} fetchOptions?
 * @returns {UseSnapshotInvestmentProposalDBReturn}
 */
export default function useInvestmentProposals(
  fetchOptions?: UseInvestmentProposalsFetchOptions
): UseSnapshotInvestmentProposalDBReturn {
  const {sponsored} = fetchOptions || {};

  /**
   * State
   */

  const [investmentProposals, setInvestmentProposals] = useState<
    GetInvestmentProposalResponse[]
  >([]);
  const [investmentProposalsError, setInvestmentProposalsError] =
    useState<Error>();
  const [investmentProposalsStatus, setInvestmentProposalsStatus] =
    useState<FetchStatus>(FetchStatus.STANDBY);

  /**
   * Our hooks
   */

  const backendURL = useBackendURL();
  const {abortController, isMountedRef} = useAbortController();

  /**
   * Cached callbacks
   */

  const optionsMemoized = useMemo(
    () =>
      ({
        sponsored,
      } as UseInvestmentProposalsFetchOptions),
    [sponsored]
  );

  const handleGetProposalsCached = useCallback(handleGetProposals, [
    abortController?.signal,
    backendURL,
    isMountedRef,
    optionsMemoized,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    handleGetProposalsCached();
  }, [handleGetProposalsCached]);

  /**
   * Functions
   */

  /**
   * handleGetProposals
   *
   * Handles getting the combined results of investment Snapshot proposals (data from our DB)
   * and the proposals data from Snapshot Hub.
   *
   * @returns {() => Promise<void>}
   */
  async function handleGetProposals() {
    try {
      if (!backendURL) return;

      setInvestmentProposalsStatus(FetchStatus.PENDING);

      const response = await fetch(
        `${backendURL}/proposals-investment${buildQueryString(
          optionsMemoized
        )}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          signal: abortController?.signal,
        }
      );

      if (!response.ok) {
        const errorJSON = await response.json();
        throw new Error(JSON.stringify(errorJSON));
      }

      const responseJSON = await response.json();

      if (!isMountedRef.current) return;

      setInvestmentProposalsStatus(FetchStatus.FULFILLED);
      setInvestmentProposals(responseJSON);
    } catch (error) {
      if (!isMountedRef.current) return;

      setInvestmentProposalsError(error);
      setInvestmentProposalsStatus(FetchStatus.REJECTED);
    }
  }

  return {
    investmentProposals,
    investmentProposalsError,
    investmentProposalsStatus,
  };
}
