import React from 'react';
import {useSelector} from 'react-redux';

import {getOrgText} from '../../util/helpers';
import {StoreState} from '../../util/types';

import s from '../../assets/scss/modules/hithereloader.module.scss';

export default function HiThereLoader() {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgLoaderEmoji = getText('OrgLoaderEmoji');

  return (
    <div className={s.hithere}>
      <span role="img" aria-label="Hi there loader">
        {orgLoaderEmoji}
      </span>
    </div>
  );
}
