import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {BACKEND_URL_KYC} from '../util/config';
import {StoreState} from '../util/types';

/**
 * useKYCBackendURL
 *
 * Provides a KYC backend URL with the org id prefixed,
 * e.g. https://kyc-backend.thelao.io/{orgId}.
 *
 * @note Do not use this if you do not need the org id.
 *
 * @returns {string} The backend URL with org id.
 */
export default function useKYCBackendURL() {
  const [backendURL, setBackendURL] = useState<string>();

  const id = useSelector((s: StoreState) => s.org && s.org.id);

  useEffect(() => {
    if (!BACKEND_URL_KYC || !id) return;

    setBackendURL(`${BACKEND_URL_KYC}/${id}`);
  }, [id]);

  return backendURL;
}
