import React, {useEffect, useState, useCallback, useRef} from 'react';
import {FormContext, useForm, Controller} from 'react-hook-form';

import {
  ACCEPTED_DOC_MIME_TYPES,
  ERROR_FILE_TOO_LARGE,
  ERROR_REQUIRED_FIELD,
  MAX_FILE_SIZE,
} from './helper';
import {getValidationError, formatNumber} from '../../../util/helpers';
import {IdentityTrustValues} from './IdentityTrustForm';
import {OnFormChangeCallback, Forms, OnFormChangeArgs} from './types';
import FileDropzone from '../../../components/common/FileDropzone';
import FundsProofBaseForm from './FundsProofBaseForm';
import InputError from '../../../components/common/InputError';

import i from '../../../assets/scss/modules/input.module.scss';
import s from '../../../assets/scss/modules/memberverify.module.scss';

enum Fields {
  irrevocableTrustAssets = 'irrevocableTrustAssets',
  irrevocableTrustAssetsProof = 'irrevocableTrustAssetsProof',
  trustRevocabilityProof = 'trustRevocabilityProof',
}

export type Values = {
  [Fields.irrevocableTrustAssets]: string;
  [Fields.irrevocableTrustAssetsProof]: File;
  [Fields.trustRevocabilityProof]: File;
};

type Conditions = {
  [l in Fields]: boolean[];
};

type FundsProofTrustFormProps = {
  identityTrustFormValues?: IdentityTrustValues;
  onFormChange: OnFormChangeCallback;
};

// validation configuration for react-hook-form
const ruleRequired = {required: ERROR_REQUIRED_FIELD};
const filePDFValidate = {
  validate: (file: File) => {
    return !file
      ? ERROR_REQUIRED_FIELD
      : (file.size > MAX_FILE_SIZE ? true : false)
      ? ERROR_FILE_TOO_LARGE
      : !ACCEPTED_DOC_MIME_TYPES.includes(file.type)
      ? 'The file is not the correct type. Please provide a .pdf, .doc, .docx file.'
      : true;
  },
};

const numberAmountValidate = (amount: number) => ({
  validate: (numberString: string) => {
    const parsedNumber = Number(numberString.replace(/,/g, ''));

    return !numberString
      ? ERROR_REQUIRED_FIELD
      : !parsedNumber
      ? 'Please provide a valid number'
      : parsedNumber < amount
      ? `The number is less than $${formatNumber(amount)}`
      : true;
  },
});

const validateConfig: Partial<Record<Fields, Record<string, any>>> = {
  irrevocableTrustAssets: numberAmountValidate(5000000),
  irrevocableTrustAssetsProof: filePDFValidate,
  trustRevocabilityProof: ruleRequired,
};

const getConditionsMap = (
  identityTrustFormValues: Record<string, any>
): Partial<Conditions> => ({
  [Fields.irrevocableTrustAssets]: [
    identityTrustFormValues.trustType === 'Irrevocable',
  ],
  [Fields.irrevocableTrustAssetsProof]: [
    identityTrustFormValues.trustType === 'Irrevocable',
  ],
  [Fields.trustRevocabilityProof]: [
    identityTrustFormValues.trustType === 'Revocable',
  ],
});

export default React.memo(function FundsProofTrustForm(
  props: FundsProofTrustFormProps
) {
  const {identityTrustFormValues = {} as IdentityTrustValues} = props || {};
  const baseFormDataRef = useRef<OnFormChangeArgs>();
  // mainly to restore conditional fields
  // as the formValues will add/remove props based on if rendered
  const [allValues, setToAllValues] = useState<Partial<Values>>({});
  const [isBaseFormValid, setIsBaseFormValid] = useState<boolean>(false);

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const {errors, formState, register, setValue, watch} = form;

  // watch all values that change
  // see: https://react-hook-form.com/api/#watch
  watch();

  /**
   * @note From the docs: "Read the formState before render to subscribe the form state through Proxy"
   * @see https://react-hook-form.com/api#formState
   */
  const {isValid} = formState;

  const conditionsMap = getConditionsMap(identityTrustFormValues);

  const renderConditionsToString = JSON.stringify(
    Object.keys(conditionsMap).map((f) => ({
      [f]: testRenderConditions(f as Fields),
    }))
  );

  const handleBaseFormChange: OnFormChangeCallback = useCallback((formData) => {
    baseFormDataRef.current = formData;

    setIsBaseFormValid(formData.isValid);
  }, []);

  const testRenderConditionsCached = useCallback(testRenderConditions, [
    conditionsMap,
  ]);

  // send parent the current form status
  useEffect(() => {
    if (!baseFormDataRef.current) return;

    props.onFormChange({
      formName: Forms.trustFundsProof,
      isValid: isValid && baseFormDataRef.current.isValid,
      triggerValidation: async () => {
        const validation = await form.triggerValidation();
        const baseValidation = baseFormDataRef.current
          ? await baseFormDataRef.current.triggerValidation()
          : false;

        return validation && baseValidation;
      },
      values: {...form.getValues(), ...baseFormDataRef.current.values},
    });
  }, [form, isValid, isBaseFormValid, props]);

  // re-validate if conditional is shown and has a value
  useEffect(() => {
    Object.keys(conditionsMap).forEach(async (f) => {
      const field = f as Fields;

      (await testRenderConditionsCached(field)) &&
        form.getValues()[field] &&
        form.triggerValidation(field, true);
    });
  }, [
    form,
    conditionsMap,
    renderConditionsToString,
    testRenderConditionsCached,
  ]);

  function testRenderConditions(field: Fields) {
    return (conditionsMap[field] || []).every((c: boolean) => c);
  }

  return (
    <>
      <header>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">Accreditation</h2>
        </div>
      </header>

      <FormContext {...form}>
        <div className="org-verify-form-wrap">
          <form
            className="org-verify-form"
            onSubmit={(e) => e.preventDefault()}>
            {/* IRREVOCABLE TRUST ASSETS */}
            {testRenderConditions(Fields.irrevocableTrustAssets) && (
              <>
                <h3
                  className={`${s['form-section-heading']} org-form-section-heading`}>
                  Trust assets
                </h3>

                <label className={`${i['label--column']} org-label--column`}>
                  <span>Assets</span>
                  <input
                    aria-describedby="error-irrevocableTrustAssets"
                    aria-invalid={
                      errors.irrevocableTrustAssets ? 'true' : 'false'
                    }
                    defaultValue={formatNumber(
                      allValues.irrevocableTrustAssets || ''
                    )}
                    name={Fields.irrevocableTrustAssets}
                    onChange={(event) => {
                      const {value} = event.target;
                      // set this form field to a formatted value
                      setValue(
                        Fields.irrevocableTrustAssets,
                        formatNumber(value)
                      );

                      // set a value to remember between renders of conditional fields
                      setToAllValues({
                        ...allValues,
                        [Fields.irrevocableTrustAssets]: value,
                      });
                    }}
                    ref={
                      validateConfig.irrevocableTrustAssets &&
                      register(validateConfig.irrevocableTrustAssets)
                    }
                    type="text"
                  />
                </label>
                <InputError
                  error={getValidationError(
                    Fields.irrevocableTrustAssets,
                    errors
                  )}
                  id="error-irrevocableTrustAssets"
                />
                <small>Must be at least $5,000,000</small>
              </>
            )}

            {/* IRREVOCABLE TRUST ASSETS PROOF */}
            {testRenderConditions(Fields.irrevocableTrustAssetsProof) && (
              <>
                <label className={`${i['label--column']} org-label--column`}>
                  Documentation of assets
                </label>
                <small>
                  Upload a statement from a financial institution, asset
                  appraisals, or letter from your lawyer, accountant, investment
                  advisor or investment broker indicating net assets.
                </small>
                <br />
                <br />
                <small>
                  You can accredit based on your crypto assets. Upload a
                  screenshot from a credible cryptocurrency exchange or wallet
                  showing holdings &amp; valuations worth over $1MM USD. The
                  screenshot should include the date and evidence tying the
                  investor to the account (e.g. your name or the investing
                  entity&rsquo;s name). You will also need to provide
                  documentation of your debts (e.g. via a credit report or
                  letter from your accountant) in order to qualify.
                </small>
                <Controller
                  as={
                    <FileDropzone
                      acceptedTypes={ACCEPTED_DOC_MIME_TYPES}
                      aria-describedby="error-irrevocableTrustAssetsProof"
                      aria-invalid={
                        errors.irrevocableTrustAssetsProof ? 'true' : 'false'
                      }
                    />
                  }
                  // I would think typically you don't return values
                  // from an onChange handler, but it works.
                  // see: https://react-hook-form.com/api/#Controller
                  onChange={([event]) => event.target.files[0]}
                  name={Fields.irrevocableTrustAssetsProof}
                  rules={validateConfig.irrevocableTrustAssetsProof}
                />
                <InputError
                  error={getValidationError(
                    Fields.irrevocableTrustAssetsProof,
                    errors
                  )}
                  id="error-irrevocableTrustAssetsProof"
                />
              </>
            )}

            {/* REVOCABILITY: BASE FUNDS PROOF FORM for PERSON */}
            {testRenderConditions(Fields.trustRevocabilityProof) && (
              <FundsProofBaseForm onFormChange={handleBaseFormChange} />
            )}

            {/* REVOCABILITY PROOF */}
            {testRenderConditions(Fields.trustRevocabilityProof) && (
              <>
                <h3
                  className={`${s['form-section-heading']} org-form-section-heading`}>
                  Trust Revocability
                </h3>
                <label className={`${i['label--column']} org-label--column`}>
                  Documentation of Revocability
                </label>
                <small>
                  Upload your trust agreement, instrument or deed. This should
                  show the grantor, beneficiaries, and the trust&rsquo;s
                  revocability.
                </small>
                <Controller
                  as={
                    <FileDropzone
                      acceptedTypes={ACCEPTED_DOC_MIME_TYPES}
                      aria-describedby="error-trustRevocabilityProof"
                      aria-invalid={
                        errors.trustRevocabilityProof ? 'true' : 'false'
                      }
                    />
                  }
                  // I would think typically you don't return values
                  // from an onChange handler, but it works.
                  // see: https://react-hook-form.com/api/#Controller
                  onChange={([event]) => event.target.files[0]}
                  name={Fields.trustRevocabilityProof}
                  rules={validateConfig.trustRevocabilityProof}
                />
                <InputError
                  error={getValidationError(
                    Fields.trustRevocabilityProof,
                    errors
                  )}
                  id="error-trustRevocabilityProof"
                />
              </>
            )}
          </form>
        </div>
      </FormContext>
    </>
  );
});
