import {useState, useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {normalizeString} from '../util/helpers';
import {Sale, StoreState} from '../util/types';
import {useIsMounted} from './useIsMounted';

type BuyerAccountInfo = {
  accountBalance: string;
  sharesPurchased: number;
  selectedAddress: string;
};

export default function useSaleBuyerInfo(sales: Sale[]) {
  /**
   * State
   */

  const [buyerAccountInfo, setBuyerAccountInfo] = useState<BuyerAccountInfo>();

  /**
   * Selectors
   */

  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const web3Instance = useSelector(
    (state: StoreState) => state.blockchain.web3Instance
  );

  /**
   * Our hooks
   */

  const {isMountedRef} = useIsMounted();

  /**
   * Cached callbacks
   */

  const getBuyerInfoCached = useCallback(getBuyerInfo, [
    connectedAddress,
    isMountedRef,
    sales,
    web3Instance,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    getBuyerInfoCached();
  }, [getBuyerInfoCached]);

  /**
   * Functions
   */

  async function getBuyerInfo() {
    if (!web3Instance || !connectedAddress) {
      setBuyerAccountInfo(undefined);
      return;
    }

    try {
      // Ether wallet balance
      const accountBalanceInWei = await web3Instance.eth.getBalance(
        connectedAddress
      );

      // Get the total number of shared already purchased
      const sharesPurchased: number = sales
        .filter(
          (s: Sale) =>
            normalizeString(s.ethereumAddress) ===
            normalizeString(connectedAddress)
        )
        .reduce(
          (acc, currentValue) => Number(acc) + Number(currentValue.shares),
          0
        );

      if (!isMountedRef.current) return;

      setBuyerAccountInfo((prevState) => ({
        ...prevState,
        accountBalance: web3Instance.utils.fromWei(accountBalanceInWei),
        sharesPurchased,
        selectedAddress: connectedAddress,
      }));
    } catch (error) {
      if (!isMountedRef.current) return;

      setBuyerAccountInfo(undefined);
    }
  }

  return buyerAccountInfo;
}
