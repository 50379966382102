import React, {useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {StoreState, MetaMaskRPCError} from '../../util/types';
import {useETHGasPrice} from '../../hooks';

import Loader from '../../components/feedback/Loader';
import ErrorMessageWithDetails from '../../components/common/ErrorMessageWithDetails';

import b from '../../assets/scss/modules/buttons.module.scss';

export default function AdminFee() {
  const [adminFeeDenominator, setAdminFeeDenominator] = useState<string>('');
  const [error, setError] = useState<Error | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [lastPaymentDate, setLastPaymentDate] = useState<string>('');
  const [laoFundAddress, setLAOFundAddress] = useState<string>('');
  const [maybeAdminFeeDenominator, setMaybeAdminFeeDenominator] =
    useState<string>('');

  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const VentureMoloch = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.VentureMoloch
  );

  /**
   * External Hooks
   */

  const {average: gasPrice} = useETHGasPrice();

  /**
   * Callbacks
   */

  const getAdminFeeInformationCached = useCallback(getAdminFeeInformation, [
    connectedAddress,
    VentureMoloch,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    getAdminFeeInformationCached()
      .then((t) => {
        t &&
          setLastPaymentDate(
            new Date(Number(t.lastPaymentTime) * 1000).toString()
          );
        t && setAdminFeeDenominator(t.adminFeeDenominator);
        t && setLAOFundAddress(t.laoFundAddress);
      })
      .catch((error) => {
        setError(error);
      });
  }, [getAdminFeeInformationCached]);

  /**
   * @note This function does not actually withdraw the admin fee, it only
   * allocates the funds to the token balances and updates the last
   * withdrawal date in the smart contract
   */
  async function getAdminFeeInformation() {
    if (!connectedAddress) return;

    try {
      const {methods} = (VentureMoloch && VentureMoloch.instance) || {};

      const adminFeeDenominator: string = await methods
        .adminFeeDenominator()
        .call();
      const lastPaymentTime: string = await methods.lastPaymentTime().call();
      const laoFundAddress: string = await methods.laoFundAddress().call();

      return {
        lastPaymentTime,
        adminFeeDenominator,
        laoFundAddress,
      };
    } catch (error) {
      throw error;
    }
  }

  async function withdrawFee() {
    try {
      if (!VentureMoloch) throw new Error('No VentureMoloch contract found.');

      /**
       * withdrawAdminFee
       */
      const receipt = await VentureMoloch.instance.methods
        .withdrawAdminFee()
        .send({
          from: connectedAddress,
          // set proposed gas price
          ...(gasPrice ? {gasPrice} : null),
        })
        .on('transactionHash', function (txHash: string) {
          setIsProcessing(true);
        })
        .on('error', (error: Error | MetaMaskRPCError) => {
          setError(error);
        });

      setIsProcessing(false);

      if (receipt) {
        const lastPaymentTime = await getAdminFeeInformation();
        setLastPaymentDate(new Date(Number(lastPaymentTime) * 1000).toString());
      }
    } catch (error) {
      setError(error);
    }
  }

  async function setFee() {
    try {
      if (!VentureMoloch) throw new Error('No VentureMoloch contract found.');

      if (Number(maybeAdminFeeDenominator) < Number(200)) {
        throw new Error(`Admin fee must be equal to or greater than 200`);
      }

      /**
       * setAdminFee(uint256 _adminFeeDenominator, address _laoFundAddress)
       */
      const receipt = await VentureMoloch.instance.methods
        .setAdminFee(maybeAdminFeeDenominator, laoFundAddress)
        .send({
          from: connectedAddress,
          // set proposed gas price
          ...(gasPrice ? {gasPrice} : null),
        })
        .on('transactionHash', function (txHash: string) {
          setIsProcessing(true);
        })
        .on('error', (error: Error | MetaMaskRPCError) => {
          setError(error);
        });

      setIsProcessing(false);

      if (receipt) {
        const t = await getAdminFeeInformation();
        setAdminFeeDenominator(t?.adminFeeDenominator || '');
      }
    } catch (error) {
      setError(error);
    }
  }

  function handleChangeFee(event: React.ChangeEvent<HTMLInputElement>) {
    const {value} = event.currentTarget;

    event.persist();

    setMaybeAdminFeeDenominator(value);
  }

  return (
    <>
      {/* <p className={'color-yellow text-center'}>
        <small>
          Note: This function does not actually withdraw the admin fee, it only
          allocates the funds to the token balances and updates the last
          withdrawal date in the smart contract
        </small>
      </p> */}
      {/** INITATE WITHDRAW ALLOCATION  */}
      <LastPaymentDate paymentDate={lastPaymentDate} />
      <button
        className={`${b.primary} org-primary-button`}
        onClick={isProcessing ? () => {} : withdrawFee}
        type="submit"
        disabled={isProcessing}>
        {isProcessing ? (
          <Loader text="Processing&hellip;" />
        ) : (
          'Withdraw Admin Fee'
        )}
      </button>

      <hr />

      {/** SET ADMIN FEE   */}
      <h3>
        Set Admin Fee <small className="color-yellow">* owner only</small>
      </h3>
      <CurrentAdminFee adminFeeDenominator={adminFeeDenominator} />
      <div className="text-center">
        <label htmlFor="adminFeeDenominator">Admin Denominator</label>
        <input
          type="text"
          style={{marginLeft: '1rem', width: '20rem'}}
          name="adminFeeDenominator"
          value={maybeAdminFeeDenominator}
          onChange={handleChangeFee}
        />
      </div>

      <button
        className={`${b.primary} org-primary-button`}
        onClick={isProcessing ? () => {} : setFee}
        type="submit"
        disabled={isProcessing}>
        {isProcessing ? <Loader text="Processing&hellip;" /> : 'Set Admin Fee'}
      </button>

      {/** ERROR MESSAGE  */}
      {error && (error as MetaMaskRPCError).code !== 4001 && (
        <div className="text-center">
          <ErrorMessageWithDetails
            error={error}
            renderText="Something went wrong while processing your withdrawal"
          />
        </div>
      )}
    </>
  );
}

type LastPaymentDateProp = {
  paymentDate: string;
};
function LastPaymentDate({paymentDate}: LastPaymentDateProp) {
  return <p>Last payment date: {paymentDate}</p>;
}

type CurrentAdminFeeProp = {
  adminFeeDenominator: string;
};
function CurrentAdminFee({adminFeeDenominator}: CurrentAdminFeeProp) {
  return <p>Current Fee Denominator: {adminFeeDenominator}</p>;
}
