import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';

import EmailTemplates from './settings/EmailTemplates';
import GlobalSettings from './settings/GlobalSettings';

export default function Settings() {
  return (
    <div>
      <h1>Settings</h1>

      <Tabs forceRenderTabPanel defaultIndex={1}>
        <TabList>
          <Tab>Global Settings</Tab>
          <Tab>Email Templates</Tab>
        </TabList>

        <TabPanel>
          <GlobalSettings />
        </TabPanel>
        <TabPanel>
          <EmailTemplates />
        </TabPanel>
      </Tabs>
    </div>
  );
}
