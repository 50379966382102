import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {dontCloseWindowWarning, contractSend} from '../../util/helpers';
import {ETHERSCAN_URLS} from '../../util/config';
import {StoreState} from '../../util/types';
import {useETHGasPrice} from '../../hooks';
import {Vote, Web3TxStatus} from '../../util/enums';

type SubmitVoteChildrenProps = {
  error?: Error;
  etherscanURL: string;
  isPromptOpen: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  openPrompt: (vote: Vote) => void;
  /**
   * The vote the user chose (e.g. 1, 2).
   */
  voteCast: number;
};

type SubmitVoteProps = {
  onBeforeProcess?: () => void;
  onComplete?: (returnedValues: Record<string, any> | undefined) => void;
  onError?: (e: Error) => void;
  onProcess?: (txHash: string) => void;
  proposalIndex: number | undefined;
  render: (props: SubmitVoteChildrenProps) => React.ReactElement;
};

/**
 * SubmitVote
 *
 * Returns a rendered component via a render prop.
 * SubmitVoteChildrenProps are passed to the render prop.
 *
 * @param {SubmitVoteProps} props
 * @returns {FunctionComponent}
 */
export default function SubmitVote(props: SubmitVoteProps) {
  const {
    onBeforeProcess,
    proposalIndex,
    onError,
    onProcess,
    onComplete,
    render,
  } = props;

  /**
   * State
   */

  const [error, setError] = useState<Error>();
  const [etherscanURL, setEtherscanURL] = useState<string>('');
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [voteCast, setVoteCast] = useState<number>(0);

  /**
   * Selectors
   */

  const web3Instance = useSelector(
    (state: StoreState) => state.blockchain.web3Instance
  );
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const VentureMoloch = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.VentureMoloch
  );
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );
  const contractMolochAddress = useSelector(
    (s: StoreState) => s.org?.contractMolochAddress
  );

  /**
   * External hooks
   */

  const {average: gasPrice} = useETHGasPrice();

  /**
   * Functions
   */

  function handleError(error: Error) {
    setError(error);
    setIsSubmitted(false);
    setIsSubmitting(false);

    onError && onError(error);
  }

  async function handleVotePrompt(voteEnum: Vote) {
    if (!web3Instance) return;

    // Activate "don't close window" warning
    const unsubscribeDontCloseWindow = dontCloseWindowWarning();

    const handleProcessingTx = (txHash: string) => {
      if (!txHash) return;

      setIsPromptOpen(false);
      setIsSubmitting(true);
      setEtherscanURL(`${ETHERSCAN_URLS[chainId]}/tx/${txHash}`);

      // Maybe call user `onProcess` and handle any `Error`.
      try {
        onProcess && onProcess(txHash);
      } catch (error) {
        handleError(error);
      }
    };

    try {
      if (!proposalIndex) {
        throw new Error('No proposal index was found.');
      }

      /**
       * Run user callback `onBeforeProcess`
       * @note We `await` just in case the value is a Promise.
       */
      onBeforeProcess && (await onBeforeProcess());

      const {methods} = (VentureMoloch && VentureMoloch.instance) || {};
      const vote = Number(voteEnum);

      setError(undefined);
      setEtherscanURL('');
      setIsPromptOpen(true);
      setIsSubmitted(false);
      setIsSubmitting(false);

      // Set submitted vote state
      setVoteCast(vote);

      const txArguments = {
        from: connectedAddress,
        to: contractMolochAddress,
        // set proposed gas price
        ...(gasPrice ? {gasPrice} : null),
      };

      const submitVoteArguments = [proposalIndex, vote];

      /**
       * submitVote
       *
       * @param {number} proposalIndex - Moloch proposal index
       * @param {number} vote - Submitted vote (1=YES 2=NO)
       */
      contractSend(
        'submitVote',
        methods,
        submitVoteArguments,
        txArguments,
        handleProcessingTx
      )
        .then(({txStatus, receipt, error}) => {
          if (txStatus === Web3TxStatus.FULFILLED) {
            if (!receipt) return;

            // Tx `receipt` resolved; tx went through.
            setIsSubmitted(true);
            setIsSubmitting(false);
            /**
             * Get `returnValues` from `receipt`.
             *
             * @see https://web3js.readthedocs.io/en/v1.2.7/web3-eth-contract.html#id37
             */
            const {
              SubmitVote: {returnValues},
            } = receipt.events;

            // Send `returnValues` in callback.
            onComplete && onComplete(returnValues);

            // Set final submitted vote state
            returnValues && setVoteCast(Number(returnValues.unitVote));

            // Tx `receipt` resolved; tx went through.
            setIsSubmitted(true);
            setIsSubmitting(false);
          }

          if (txStatus === Web3TxStatus.REJECTED) {
            if (!error) return;
            handleError(error);

            // If user closed modal (MetaMask error code 4001)
            // or via WalletConnect, which only provides a message and no code
            setIsPromptOpen(false);

            unsubscribeDontCloseWindow();
          }
        })
        .catch(({error}) => {
          handleError(error);

          // If user closed modal (MetaMask error code 4001)
          // or via WalletConnect, which only provides a message and no code
          setIsPromptOpen(false);

          unsubscribeDontCloseWindow();
        });

      unsubscribeDontCloseWindow();
    } catch (error) {
      handleError(error);

      setVoteCast(0);

      unsubscribeDontCloseWindow();
    }
  }

  return render({
    error,
    etherscanURL,
    isPromptOpen,
    isSubmitted,
    isSubmitting,
    openPrompt: handleVotePrompt,
    voteCast,
  });
}
