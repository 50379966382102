import React, {useState, useContext} from 'react';
import {useHistory, Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {getCurrentUser} from '../../store/actions';
import {FetchStatus} from '../../util/enums';
import {
  formatDecimal,
  formatNumber,
  getOpenLawAddressFromFormatted,
  getOrgText,
  stripTrailingZeroes,
} from '../../util/helpers';
import {MetaMaskRPCError, UserState, StoreState} from '../../util/types';
import {SaleModalContext} from './SaleModal';
import {UpdateMemberIdentity} from '../../components/actions';
import {useBackendURL} from '../../hooks';
import AgreementText from './AggreementText';
import CycleMessage from '../../components/common/CycleMessage';
import EasyApplyContribution from '../../components/contract/EasyApplyContribution';
import FadeIn from '../../components/common/FadeIn';
import Loader from '../../components/feedback/Loader';
import PersonAndAgreementSVG from '../../assets/svg/PersonAndAgreementSVG';
import PurchaseAmount from './PurchaseAmount';

import b from '../../assets/scss/modules/buttons.module.scss';
import c from '../../assets/scss/modules/checkbox.module.scss';
import s from '../../assets/scss/modules/sale.module.scss';
import sm from '../../assets/scss/modules/salemodal.module.scss';

type ConfirmViews = 'amount' | 'confirm' | 'legal' | 'username';

enum Fields {
  checkboxConfirm = 'checkboxConfirm',
}

function ErrorMessage(props: {error: Error}) {
  const {error} = props;

  return error && (error as MetaMaskRPCError).code !== 4001 ? (
    <>
      <p className="error-message org-error-message">
        Something went wrong while completing the transaction
      </p>
      <details>
        <summary
          className="error-message org-error-message"
          style={{cursor: 'pointer', outline: 'none'}}>
          Error details
        </summary>
        <p className="error-message org-error-message font-mono">
          <small>{error.message}</small>
        </p>
      </details>
    </>
  ) : null;
}

/**
 * SaleConfirmViews
 *
 * A content view that "navigates" between confirmation steps.
 * It is meant to be embedded by the <SaleModal />.
 *
 * It navigates through 2 confirmation "pages" and then
 * offers a final submit button connected to a "submit" callback.
 */
export default function SaleConfirmViews() {
  /**
   * State
   */

  const [agreementTitle, setAgreementTitle] = useState<string>();

  const [agreementParameters, setAgreementParameters] =
    useState<Record<string, any>>();

  const [username, setUsername] = useState<string>('');
  const [user, setUser] = useState<UserState>();

  const [confirmView, setConfirmView] = useState<ConfirmViews>('amount');

  const [checkboxConfirmChecked, setCheckboxConfirmChecked] =
    useState<boolean>(false);

  /**
   * Context
   */

  const {
    maxNewMembersLimitReached,
    preventModalClose,
    userInfo,
    saleInfo: {saleInfo},
  } = useContext(SaleModalContext);

  /**
   * Selectors
   */

  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const operatingAgreement = useSelector((s: StoreState) =>
    s.org ? s.org.operatingAgreement : ''
  );
  const serviceProviderAgreement = useSelector((s: StoreState) =>
    s.org ? s.org.serviceProviderAgreement : ''
  );
  const subscriptionAgreement = useSelector((s: StoreState) =>
    s.org ? s.org.subscriptionAgreement : ''
  );
  const subscriptionAgreementRegS = useSelector((s: StoreState) =>
    s.org ? s.org.subscriptionAgreementRegS : ''
  );

  /**
   * Their hooks
   */

  const backendURL = useBackendURL();
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Variables
   */

  // Use this value instead of a current geolocation value
  // as this is what they verified as.
  const {isUSInvestor} = userInfo;
  const isPersonEntity = userInfo.entityType === 'person';
  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');
  const orgLoaderTextColor = getText('OrgLoaderTextColor');
  const orgOnboardingPeriodText = getText('OrgOnboardingPeriodText');
  const orgVotingPeriodText = getText('OrgVotingPeriodText');
  const orgGracePeriodText = getText('OrgGracePeriodText');
  const orgGradientColorFrom = getText('OrgGradientColorFrom');
  const orgGradientColorTo = getText('OrgGradientColorTo');
  const orgServiceAgreementTitle = getText('OrgServiceAgreementTitle');
  const maxNewMembersLimitReachedError: string = `You cannot purchase units, because ${orgName} has reached the
  maximum allowed members at this time.`;

  /**
   * Functions
   */

  function handleCheckboxClicked(event: React.ChangeEvent<HTMLInputElement>) {
    const {checked} = event.target;

    setCheckboxConfirmChecked(checked);
  }

  function handleNavigate(view?: ConfirmViews) {
    return () => {
      view && setConfirmView(view);
    };
  }

  /**
   * handleProposalSubmitted
   *
   * Checks if member was created and navigates to the username view.
   */
  function handleProposalSubmitted() {
    // No more need to prevent modal from closing
    preventModalClose(false);

    // Poll every 2 seconds
    const getMemberIntervalId = setInterval(fetchMember, 2000);

    // Retry for up to 3 minutes
    setTimeout(() => {
      getMemberIntervalId && clearInterval(getMemberIntervalId);

      // Go to members page
      history.push('/members');
    }, 60000 * 3);

    async function fetchMember() {
      if (!backendURL) return;

      try {
        const userJSON = (await dispatch(
          getCurrentUser(userInfo.ethereumAddress.toLowerCase(), backendURL)
        )) as any;

        clearInterval(getMemberIntervalId);

        setUser(userJSON);

        // Show username view
        handleNavigate('username')();
      } catch (error) {}
    }
  }

  /**
   * Handle any final validation before the wallet prompt opens for `submitProposal`
   */
  function handleOnBeforeProcessSubmitProposal() {
    // Prevent modal from closing
    preventModalClose(true);

    /**
     * Check the max member count again before submit.
     *
     * If `true` it will change the view back to the first view
     * and the `maxNewMembersLimitReachedError` will display.
     *
     * It will also cause the wallet prompt Promise to reject
     * before opening the wallet, then exit and handle the error.
     */
    if (maxNewMembersLimitReached) {
      handleNavigate('amount')();
      throw new Error(maxNewMembersLimitReachedError);
    }
  }

  function handleOnErrorSubmitProposal() {
    // No more need to prevent modal from closing
    preventModalClose(false);
  }

  function previewOperatingAgreement() {
    const personData = {
      'Member Name': userInfo.personLegalName,
    };
    const nonPersonData = {
      'Entity Name': userInfo.companyLegalName || userInfo.trustLegalName,
      'Member Signatory':
        userInfo.companySignatoryLegalName || userInfo.trustSignatoryLegalName,
    };
    const parameters = {
      // Today's date
      'Effective Date': Date.now().toString(),
      'Member Signatory Email': userInfo.emailAddress,
      'Member Title': 'Member',
      'Is the signatory member an Entity?': (
        isPersonEntity === false
      ).toString(),

      ...(isPersonEntity ? personData : null),
      ...(isPersonEntity === false ? nonPersonData : null),
    };

    setAgreementTitle(operatingAgreement);
    setAgreementParameters(parameters);
  }

  function previewServiceProviderAgreement() {
    const personData = {
      'Member Signatory Email': userInfo.emailAddress,
      'Member Name': userInfo.personLegalName,
    };
    const nonPersonData = {
      'Entity Signatory Email': userInfo.emailAddress,
      'Entity Signatory Name':
        userInfo.companySignatoryLegalName || userInfo.trustSignatoryLegalName,
      'Entity Name': userInfo.companyLegalName || userInfo.trustLegalName,
    };
    const parameters = {
      Individual: isPersonEntity.toString(),
      'Effective Date': Date.now().toString(),

      ...(isPersonEntity ? personData : null),
      ...(isPersonEntity === false ? nonPersonData : null),
    };

    setAgreementTitle(serviceProviderAgreement);
    setAgreementParameters(parameters);
  }

  function previewSubscriptionAgreement() {
    const personData = {
      'Member Signatory Email': userInfo.emailAddress,
      'Member Name': userInfo.personLegalName,
      'Member Address': getOpenLawAddressFromFormatted(userInfo.address),
    };
    const nonPersonData = {
      'Entity Signatory Email': userInfo.emailAddress,
      'Entity Signatory Name':
        userInfo.companySignatoryLegalName || userInfo.trustSignatoryLegalName,
      'Entity Name': userInfo.companyLegalName || userInfo.trustLegalName,
      'Entity Address': getOpenLawAddressFromFormatted(userInfo.address),
    };
    const parameters = {
      Individual: isPersonEntity.toString(),
      ...(isPersonEntity ? personData : null),
      ...(isPersonEntity === false ? nonPersonData : null),
    };
    const title = isUSInvestor
      ? subscriptionAgreement
      : subscriptionAgreementRegS;

    setAgreementTitle(title);
    setAgreementParameters(parameters);
  }

  return (
    <PurchaseAmount
      render={({
        amountETH,
        amountETHInWEI,
        amountUnits,
        amountUnitsFormatted,
        amountUSD,
        canPurchase,
        EmojiSliderRendered,
        isFullySubscribed,
        percentAmount,
        readyStatus,
      }) => (
        <>
          {confirmView === 'amount' && !agreementTitle && (
            <FadeIn duration={150}>
              <div className="titlebar">
                <h2 className="titlebar__title org-titlebar__title">
                  Become a Member
                </h2>
              </div>

              {readyStatus === FetchStatus.PENDING && (
                <div
                  className={`${sm['confirm-instruction-text']} org-confirm-instruction-text text-center`}>
                  <Loader showAfterMs={200} />
                </div>
              )}

              {maxNewMembersLimitReached && (
                <p
                  className={`${sm['confirm-instruction-text']} org-confirm-instruction-text color-yellow text-center`}>
                  {maxNewMembersLimitReachedError}
                </p>
              )}

              {isFullySubscribed && readyStatus === FetchStatus.FULFILLED && (
                <p
                  className={`${sm['confirm-instruction-text']} org-confirm-instruction-text color-yellow text-center`}>
                  You cannot purchase units, because {orgName} is already full.
                </p>
              )}

              {!isFullySubscribed &&
                canPurchase === false &&
                readyStatus === FetchStatus.FULFILLED && (
                  <p
                    className={`${sm['confirm-instruction-text']} org-confirm-instruction-text color-yellow text-center`}>
                    You cannot purchase units. You have already purchased the
                    maximum number of units.
                  </p>
                )}

              {isFullySubscribed === false &&
                maxNewMembersLimitReached === false &&
                canPurchase &&
                readyStatus === FetchStatus.FULFILLED && (
                  <>
                    <p
                      className={`${sm['confirm-instruction-text']} org-confirm-instruction-text`}>
                      {getText('OrgSaleModalView1Intro', {
                        ethPerChunk: formatNumber(saleInfo.ethPerChunk),
                        maxShares: formatNumber(saleInfo.maxShares),
                        orgName,
                        percentOfTotalAuthorizedUnits: stripTrailingZeroes(
                          formatDecimal(
                            (saleInfo.sharesPerChunk /
                              saleInfo.totalAuthorizedUnits) *
                              100
                          )
                        ),
                        percentOfTotalShares: stripTrailingZeroes(
                          formatDecimal(
                            (saleInfo.sharesPerChunk / saleInfo.totalShares) *
                              100
                          )
                        ),
                        sharesPerChunk: formatNumber(saleInfo.sharesPerChunk),
                      })}
                    </p>

                    <section className={s['sale-section']}>
                      <div className={`${s['sale-amount']} org-sale-amount`}>
                        <div>{formatNumber(amountUnits)} units</div>

                        <span
                          className={`${s['sale-amount__unit-small']} org-sale-amount__unit-small`}>
                          {getText('OrgSaleModalView1SliderETH', {
                            amountETH: stripTrailingZeroes(
                              Number(amountETH).toFixed(2)
                            ),
                            percentAmount,
                          })}
                        </span>
                      </div>

                      {/* EMOJI SLIDER */}
                      {EmojiSliderRendered}

                      <span
                        className={`${s['sale-amount__unit-small']} org-sale-amount__unit-small`}>
                        ${formatNumber(amountUSD)}
                      </span>
                    </section>

                    <div className={sm['confirm-buttons']}>
                      <button
                        className={`${b['mini-modal-button']} org-mini-modal-button`}
                        onClick={handleNavigate('confirm')}>
                        Next
                      </button>
                    </div>
                  </>
                )}
            </FadeIn>
          )}

          {/* SUBMIT MEMBER PROPOSAL */}
          {confirmView === 'confirm' && !agreementTitle && (
            <FadeIn duration={150}>
              <div className="text-center">
                <div className="titlebar">
                  <h2 className="titlebar__title org-titlebar__title">
                    Final Confirmation
                  </h2>
                </div>

                <p
                  className="color-yellow org-notification info"
                  style={{marginTop: '4rem'}}>
                  It&apos;ll take <u>{orgOnboardingPeriodText}</u> for you to be
                  fully onboarded (the default voting + grace period).
                </p>

                <p>
                  {getText('OrgSaleModalView2Entitlement', {
                    amountETH: stripTrailingZeroes(
                      Number(amountETH).toFixed(2)
                    ),
                    orgName,
                    percentAmount,
                    sharesToPurchase: amountUnitsFormatted,
                  })}
                </p>

                <button
                  className={`${sm['read-terms-button']} org-read-terms-button`}
                  onClick={handleNavigate('legal')}>
                  <PersonAndAgreementSVG
                    fromGradient={orgGradientColorFrom}
                    toGradient={orgGradientColorTo}
                  />
                  <span>Additional Confirmations and Acknowledgements</span>
                </button>

                <EasyApplyContribution
                  onBeforeProcess={handleOnBeforeProcessSubmitProposal}
                  onError={handleOnErrorSubmitProposal}
                  onComplete={handleProposalSubmitted}
                  /**
                   * @note We are phasing out the direct creation of a sale in favour of Graph callbacks
                   *  in the server. Leaving for now in case we need to revert.
                   */
                  // onProcess={handleOnProcessSubmitProposal({
                  //   amountETH,
                  //   amountUSD,
                  //   percentAmount,
                  //   amountUnits
                  // })}
                  tributeAmountWEI={amountETHInWEI}
                  render={({
                    error,
                    etherscanURL,
                    isPromptOpen,
                    isSubmitted,
                    isSubmitting,
                    openPrompt,
                  }) => (
                    <>
                      {
                        <>
                          <input
                            aria-checked={checkboxConfirmChecked}
                            className={`${c['checkbox-input']} org-checkbox-input`}
                            disabled={
                              isSubmitting || isSubmitted || isPromptOpen
                            }
                            defaultChecked={checkboxConfirmChecked}
                            id={Fields.checkboxConfirm}
                            name={Fields.checkboxConfirm}
                            onChange={
                              isSubmitting || isSubmitted || isPromptOpen
                                ? () => {}
                                : handleCheckboxClicked
                            }
                            type="checkbox"
                          />

                          <label
                            className={`${sm['confirm-checkbox-label']} ${c['checkbox-label']} org-confirm-checkbox-label org-checkbox-label`}
                            // Not really correct for `aria-labelledby`, but testing-library yells.
                            aria-labelledby={Fields.checkboxConfirm}
                            htmlFor={Fields.checkboxConfirm}>
                            <span
                              className={`${c['checkbox-box']} org-checkbox-box`}></span>
                            <span>
                              I have read and agree to the terms of {orgName}'s{' '}
                              <button
                                className={`${b['underline-blue']} org-agreement-link`}
                                onClick={previewOperatingAgreement}
                                style={{margin: 0, padding: 0}}>
                                Operating Agreement
                              </button>
                              ,{' '}
                              <button
                                className={`${b['underline-blue']} org-agreement-link`}
                                onClick={previewSubscriptionAgreement}
                                style={{margin: 0, padding: 0}}>
                                Subscription Agreement
                              </button>{' '}
                              and{' '}
                              <button
                                className={`${b['underline-blue']} org-agreement-link`}
                                onClick={previewServiceProviderAgreement}
                                style={{margin: 0, padding: 0}}>
                                {orgServiceAgreementTitle}
                              </button>
                              .<br />
                              <small>
                                These agreements will be executed and sent to
                                you when you are fully onboarded.
                              </small>
                            </span>
                          </label>

                          <div className={sm['confirm-buttons']}>
                            <button
                              className={`${b['mini-modal-button']} org-mini-modal-button`}
                              disabled={
                                checkboxConfirmChecked !== true ||
                                isSubmitting ||
                                isSubmitted ||
                                isPromptOpen
                              }
                              onClick={
                                isSubmitted || isSubmitting || isPromptOpen
                                  ? () => {}
                                  : openPrompt
                              }>
                              {isPromptOpen ? (
                                <Loader
                                  text={'Preparing\u2026'}
                                  textProps={{
                                    style: {color: orgLoaderTextColor},
                                  }}
                                />
                              ) : !isPromptOpen &&
                                (isSubmitting || isSubmitted) ? (
                                <CycleMessage
                                  intervalMs={2000}
                                  messages={[
                                    'Submitting\u2026',
                                    'Working\u2026',
                                    'DAOing\u2026',
                                    'Getting Closer\u2026',
                                    'Dreaming of Eth2\u2026',
                                  ]}
                                  useFirstItemStart
                                  render={(message) => {
                                    return (
                                      <FadeIn duration={200} key={message}>
                                        {message}
                                      </FadeIn>
                                    );
                                  }}
                                />
                              ) : (
                                'Join'
                              )}
                            </button>
                          </div>

                          {etherscanURL && (
                            <p>
                              <small>
                                <a
                                  href={etherscanURL}
                                  rel="noopener noreferrer"
                                  target="_blank">
                                  (view progress)
                                </a>
                              </small>
                            </p>
                          )}
                        </>
                      }

                      {/* if error is not a MM window close/cancel code */}
                      {error && <ErrorMessage error={error} />}

                      <div className={`${sm['back']} org-sale-modal-back`}>
                        <button
                          className={`${sm['button-back']} org-sale-modal-button-back ${b.underline}`}
                          disabled={isSubmitting || isPromptOpen}
                          onClick={
                            !isSubmitting || !isPromptOpen
                              ? handleNavigate('amount')
                              : () => {}
                          }>
                          Back
                        </button>
                      </div>
                    </>
                  )}
                />
              </div>
            </FadeIn>
          )}

          {confirmView === 'legal' && (
            <FadeIn duration={150}>
              <div className="titlebar">
                <h2 className="titlebar__title org-titlebar__title">
                  Acknowledgements
                </h2>
              </div>

              <div style={{marginTop: '4rem'}}>
                {/* INTERNATIONAL INVESTOR (REG S) TERMS */}
                {!isUSInvestor && (
                  <ul className={sm['confirm-list']}>
                    <li>
                      You have read all of {orgName}'s legal documents and have
                      the requisite power, authority, and capacity to acquire
                      and hold the Units.
                    </li>
                    <li>
                      The Units have not been registered under the Securities
                      Act of 1933, as amended. To the extent that the Units are
                      deemed securities, you agree that the Units may not be
                      offered or sold within the U.S. or to, or for the account
                      or benefit of, U.S. persons except in accordance with
                      Regulation S or pursuant to an exemption from the
                      registration requirements of the Securities Act until one
                      year from this date and you will not engage in any hedging
                      transactions involving the Units unless in compliance with
                      the Securities Act.
                    </li>
                    <li>
                      That you nor your affiliates, nor any persons acting on
                      your behalf will engage in any directed selling efforts of
                      the Units.
                    </li>
                    <li>
                      {orgName}'s dapp may contain bugs, errors, or other
                      technical issues that impede the operation of {orgName}{' '}
                      and that you agree to pay fees to {orgName}'s service
                      provider(s), as set forth in the{' '}
                      {orgServiceAgreementTitle}.
                    </li>
                    <li>
                      The purchase of Units or investments made via {orgName}{' '}
                      may result in taxable events.
                    </li>
                    <li>
                      Members owe no fiduciary duties to one another and may,
                      for example, engage in competitive activities or
                      investments.
                    </li>
                    <li>
                      Investments made by {orgName} are inherently speculative
                      and can result in a complete loss of your contribution.
                    </li>
                  </ul>
                )}

                {/* US INVESTOR TERMS */}
                {isUSInvestor && (
                  <ul className={sm['confirm-list']}>
                    <li>
                      You have read all of {orgName}'s legal documents and have
                      the requisite power, authority, and capacity to acquire
                      and hold the Units and are an "accredited investor"
                      (within the meaning of Rule 501 under the Securities Act).
                    </li>
                    <li>
                      The Units have not been registered under the Securities
                      Act of 1933, as amended.
                    </li>
                    <li>
                      {orgName}'s dapp may contain bugs, errors, or other
                      technical issues that impede the operation of {orgName}{' '}
                      and that you agree to pay fees to {orgName}'s service
                      provider(s), as set forth in the{' '}
                      {orgServiceAgreementTitle}.
                    </li>
                    <li>
                      The purchase of Units or investments made via {orgName}{' '}
                      may result in taxable events.
                    </li>
                    <li>
                      Members owe no fiduciary duties to one another and may,
                      for example, engage in competitive activities or
                      investments.
                    </li>
                    <li>
                      Investments made by {orgName} are inherently speculative
                      and can result in a complete loss of your contribution.
                    </li>
                  </ul>
                )}
              </div>

              <button
                className={`${sm['button-back']} org-sale-modal-button-back ${b.underline}`}
                onClick={handleNavigate('confirm')}>
                Great, take me back
              </button>
            </FadeIn>
          )}

          {/**
           * UPDATE USERNAME
           *
           * User and member entries have been created during processing (or they already exist)
           * The user can optionally update their username if one does not already exist.
           */}
          {confirmView === 'username' && !agreementTitle && user && (
            <UpdateMemberIdentity
              onFetch={(currentIdentity) => {
                // Do not show if the user already exists and has set a username.
                if (currentIdentity && currentIdentity.username) {
                  history.push('/members');
                }
              }}
              onSuccess={() => history.push('/members')}
              render={({
                updateUserError,
                updateUserStatus,
                createUserUsername,
              }) => (
                <FadeIn duration={150}>
                  <div className="titlebar">
                    <h2 className="titlebar__title org-titlebar__title">
                      Welcome{' '}
                      <span
                        aria-label="Rocketship blasting off emoji."
                        role="img">
                        🚀
                      </span>
                    </h2>
                  </div>

                  <p
                    className={`${sm['confirm-instruction-text']} org-confirm-instruction-text`}>
                    If you want, choose a username so it's easier for users to
                    interact with you.
                  </p>

                  <label style={{display: 'block', margin: '1rem 0 1rem 0'}}>
                    <span className="hidden">Choose a username</span>
                    <input
                      onChange={(event) => setUsername(event.target.value)}
                      placeholder="e.g., crypt0K1tty"
                      type="text"
                    />
                  </label>

                  {/* SAVE USERNAME */}
                  <button
                    className={`${b['mini-modal-button']} org-mini-modal-button`}
                    disabled={
                      updateUserStatus === FetchStatus.PENDING ||
                      updateUserStatus === FetchStatus.FULFILLED
                    }
                    onClick={() =>
                      updateUserStatus !== FetchStatus.PENDING &&
                      updateUserStatus !== FetchStatus.FULFILLED &&
                      username &&
                      createUserUsername(username)
                    }
                    style={{marginTop: '1em'}}>
                    {updateUserStatus === FetchStatus.PENDING ? (
                      <Loader
                        text="Saving&hellip;"
                        textProps={{style: {color: 'inherit'}}}
                      />
                    ) : (
                      'Save Username'
                    )}
                  </button>

                  {/* SKIP : <Link /> to /members */}
                  <p>
                    <small>
                      <Link
                        className="org-sale-modal-link-button"
                        to="/members">
                        Do this later
                      </Link>
                    </small>
                  </p>

                  {/* UPDATE USER ERROR */}
                  {updateUserError && (
                    <p className="error-message org-error-message">
                      <small>{updateUserError.message}</small>
                    </p>
                  )}

                  <p className="color-yellow org-notification info">
                    <small>
                      Note: The underlying smart contracts need to process your
                      membership. It&rsquo;ll take{' '}
                      <u>{orgOnboardingPeriodText}</u> for you to be fully
                      onboarded (the default voting period) &mdash;{' '}
                      {orgVotingPeriodText} for the smart contract's voting
                      period and a grace period of {orgGracePeriodText} for
                      other members to &ldquo;rage quit&rdquo; based on your
                      membership.
                    </small>
                  </p>
                </FadeIn>
              )}
            />
          )}

          {/* OPENLAW AGREEMENT TEXT */}
          {agreementTitle && agreementParameters && (
            <FadeIn>
              <AgreementText
                parameters={agreementParameters}
                rootProps={{
                  className: `${sm['agreement-text-wrap']} org-agreement-text-wrap`,
                }}
                title={agreementTitle}
              />

              <button
                className={`${sm['button-back']} org-sale-modal-button-back ${b.underline}`}
                onClick={() => {
                  handleNavigate('confirm');
                  setAgreementTitle(undefined);
                }}>
                Go back
              </button>
            </FadeIn>
          )}
        </>
      )}
    />
  );
}
