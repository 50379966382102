import React, {useState, useContext, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {GovernanceType, ProposalType} from '../../../../util/enums';
import {StoreState, MetaMaskRPCError} from '../../../../util/types';
import {VoteContext} from '../MolochVoting';
import ErrorMessageWithDetails from '../../../../components/common/ErrorMessageWithDetails';
import Loader from '../../../../components/feedback/Loader';
import ProcessProposal from '../../../../components/contract/ProcessProposal';
import ProposalActionWhyDisabled from '../../ProposalDisabledReason';

import s from '../../../../assets/scss/modules/voting.module.scss';

type ProcessVotesProps = {
  onProcessed?: (processProposalReturnValues: Record<string, any>) => void;
  onProcess?: (txHash: string) => void;
  proposalType: ProposalType | GovernanceType;
};

export default function ProcessVotes(props: ProcessVotesProps) {
  const {onProcess, onProcessed, proposalType} = props;

  /**
   * Context
   */

  const {molochProposal, endingPeriod} = useContext(VoteContext);
  const {proposalIndex} = molochProposal;

  /**
   * Selectors
   */

  const molochConstants = useSelector(
    (state: StoreState) => state.blockchain.molochConstants
  );

  /**
   * State
   */

  const [isVotingExpired, setIsVotingExpired] = useState<boolean>(false);

  /**
   * Effects
   */

  // Check if voting period has expired & check proposal type using `details`
  useEffect(() => {
    if (endingPeriod && molochConstants) {
      const {summoningTime, periodDuration} = molochConstants;
      const now = Math.floor(
        (Math.floor(Date.now() / 1000) - Number(summoningTime)) /
          Number(periodDuration)
      );

      setIsVotingExpired(Number(now) > Number(endingPeriod));
    }
  }, [endingPeriod, molochConstants]);

  return (
    <>
      {isVotingExpired && (
        <>
          <div style={{marginTop: '3rem'}}>
            <p className={`${s['processing-text']}`}>Ready for processing</p>

            <ProposalActionWhyDisabled
              render={({
                canShowDisabledReason,
                isDisabled,
                openWhyDisabledModal,
                WhyDisabledModal,
              }) => (
                <>
                  <ProcessProposal
                    onComplete={onProcessed}
                    onProcess={onProcess}
                    proposalIndex={proposalIndex}
                    proposalType={proposalType}
                    render={({
                      error,
                      etherscanURL,
                      isPromptOpen,
                      isSubmitted,
                      isSubmitting,
                      openPrompt,
                    }) => (
                      <>
                        <button
                          className={s['process-btn']}
                          onClick={openPrompt}
                          disabled={isSubmitting || isSubmitted || isDisabled}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}>
                          {isPromptOpen ? (
                            <Loader text="Preparing&hellip;" />
                          ) : isSubmitting ? (
                            <Loader text="Processing&hellip;" />
                          ) : isSubmitted ? (
                            'Finishing up\u2026'
                          ) : (
                            'Process Proposal'
                          )}
                        </button>

                        {/* ETHERSCAN URL */}
                        {etherscanURL && (
                          <p className="text-center">
                            <small>
                              <a
                                href={etherscanURL}
                                rel="noopener noreferrer"
                                target="_blank">
                                (view{' '}
                                {isSubmitting ? 'progress' : 'transaction'})
                              </a>
                            </small>
                          </p>
                        )}

                        {/* if error is not a MM window close/cancel code */}
                        {error && (error as MetaMaskRPCError).code !== 4001 && (
                          <div className="text-center">
                            <ErrorMessageWithDetails
                              error={error}
                              renderText={() => (
                                <small>
                                  Something went wrong while processing your
                                  proposal.
                                </small>
                              )}
                            />
                          </div>
                        )}
                      </>
                    )}
                  />

                  {canShowDisabledReason && (
                    <div style={{paddingBottom: '1em'}}>
                      <span
                        className={s['why--tooltip']}
                        onClick={(
                          event: React.MouseEvent<HTMLAnchorElement>
                        ) => {
                          event.preventDefault();
                          openWhyDisabledModal();
                        }}>
                        Why is processing disabled?
                      </span>
                    </div>
                  )}

                  <WhyDisabledModal />
                </>
              )}
            />
          </div>
        </>
      )}
    </>
  );
}
