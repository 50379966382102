import {useSelector, useDispatch} from 'react-redux';

import {authServer} from '../../store/actions';
import {FetchStatus} from '../../util/enums';
import {getAccessToken} from '../../util/helpers';
import {StoreState} from '../../util/types';
import {useBackendURL} from '../../hooks';

type AuthenticateToServerProps = {
  /**
   * Ethereum address to use for authentication to the server.
   */
  addressToAuthenticate: string;
  onError?: (e: Error) => void;
  onProcess?: () => void;
  onSuccess?: () => void;
  render: (p: AuthenticateToServerRenderProps) => React.ReactElement | null;
};

type AuthenticateToServerRenderProps = {
  attemptAuthentication: () => void;
  authStatus: FetchStatus;
  isAuthenticated: boolean;
};

/**
 * AuthenticateToServer
 *
 * A render prop component that handles calling the action `authServer`
 * in order to get an access token for accessing the API.
 *
 * @returns {React.ReactElement} Renders any ReactElement via render prop.
 */
export default function AuthenticateToServer(props: AuthenticateToServerProps) {
  const {addressToAuthenticate, onError, onProcess, onSuccess, render} = props;

  // Attempt to get existing accessToken from localStorage
  const accessTokenLS = getAccessToken(addressToAuthenticate);

  /**
   * Selectors
   */

  // Access token in app state as a result of dispatching `authServer`.
  const accessToken = useSelector(
    (s: StoreState) => s.authServer && s.authServer.accessToken
  );
  const accessTokenStatus = useSelector(
    (s: StoreState) => s.authServer && s.authServer.status
  );
  const web3Instance = useSelector((s: StoreState) =>
    s.blockchain ? s.blockchain.web3Instance : undefined
  );

  /**
   * External Hooks
   */

  const backendURL = useBackendURL();
  const dispatch = useDispatch();

  /**
   * Functions
   */

  async function handleAttemptAuthentication() {
    try {
      if (!backendURL) {
        throw new Error('No backend URL was found.');
      }

      if (!web3Instance) {
        throw new Error('No Web3 instance was found.');
      }

      onProcess && onProcess();

      await dispatch(
        authServer(addressToAuthenticate, backendURL, web3Instance)
      );

      onSuccess && onSuccess();
    } catch (error) {
      onError && onError(error);
    }
  }

  return render({
    attemptAuthentication: handleAttemptAuthentication,
    authStatus: accessTokenStatus || FetchStatus.STANDBY,
    isAuthenticated: Boolean(accessTokenLS) || Boolean(accessToken),
  });
}
