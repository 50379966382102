import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import Web3 from 'web3';

import {CenterLogo} from '../../components/logo';
import {getOrgText} from '../../util/helpers';
import {StoreState} from '../../util/types';
import {useInvestmentProposalsParsedSponsored} from '../../hooks';
import InvestmentProposalsCards from '../proposals/InvestmentProposalsCards';
import TotalContributedAmount from '../sale/TotalContributedAmount';
import Wrap from '../../components/layout/Wrap';

import b from '../../assets/scss/modules/buttons.module.scss';
import s from '../../assets/scss/modules/interest.module.scss';

export default function GetStarted() {
  const orgDocsURL =
    useSelector((s: StoreState) => s.org && s.org.docsURL) ||
    'https://docs.thelao.io';
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  const getText = getOrgText(orgText);
  const orgInvestmentsText = getText('OrgInvestmentsText');

  /**
   * State
   */

  const [, setDeployedETHAmount] = useState<string>('0');

  /**
   * Our hooks
   */

  const {
    areInvestmentProposalsLoading,
    proposalEntriesPassed,
    filterInvestmentProposalsByViewable,
  } = useInvestmentProposalsParsedSponsored();

  /**
   * Their hooks
   */

  const history = useHistory();

  /**
   * Variables
   */

  // Get the last 6 passed proposals
  const passedProposals = filterInvestmentProposalsByViewable(
    proposalEntriesPassed
  )
    .map((p) => p[2])
    .filter((p) => p.proposalTarget === 'moloch')
    .filter((_, i) => i < 6);

  /**
   * Sums total ETH requested from proposals that have more "Yes" shares voted
   * than "No" shares voted after the voting period has ended (regardless of
   * whether the proposal has been processed).
   */
  useEffect(() => {
    try {
      if (!areInvestmentProposalsLoading && passedProposals) {
        const committedWeiAmounts = passedProposals.map((p) =>
          Web3.utils.toBN((p as Record<string, any>).paymentRequested)
        );
        const sum: string = committedWeiAmounts.reduce((a, b) => {
          return Web3.utils
            .toBN(a.toString())
            .add(Web3.utils.toBN(b.toString()));
        }, 0 as any);

        const totalCommittedWeiAmount = Web3.utils.toBN(sum);
        const totalCommittedETHAmount = Web3.utils.fromWei(
          totalCommittedWeiAmount,
          'ether'
        );

        setDeployedETHAmount(totalCommittedETHAmount);
      }
    } catch (error) {
      setDeployedETHAmount('0');
    }
  }, [areInvestmentProposalsLoading, passedProposals]);

  return (
    <Wrap id="get-started" className={`${s['wrap']}`}>
      <header className={s['eth-data-container']}>
        <CenterLogo />

        <TotalContributedAmount
          rootElementProps={{className: 'landing__contributed-amount'}}
        />
      </header>

      <div className={s['middle-links']}>
        <button
          className={`${b['primary']} ${s['middle-links__button']}`}
          onClick={() => {
            history.push('/contribute');
          }}>
          Become a member
        </button>
        <span className={s['middle-links__link']}>
          <a href={orgDocsURL} rel="noopener noreferrer" target="_blank">
            Learn More
          </a>
        </span>
      </div>

      {passedProposals.length > 0 && (
        <div className={s['recent-investments']}>
          <div className={s['recent-investments__header']}>
            <div className={s['recent-investments__title']}>Supporting</div>
            <Link
              className={s['recent-investments__link']}
              to={`/${orgInvestmentsText.toLowerCase()}`}>
              View All
            </Link>
          </div>
          <section
            className={
              'grid--fluid sections-grid-container org-sections-grid-container'
            }>
            <div
              className={`sections-grid__cards${
                passedProposals.length === 1
                  ? ' sections-grid__cards--center-one'
                  : ''
              }`}>
              {passedProposals && !areInvestmentProposalsLoading && (
                <InvestmentProposalsCards proposals={passedProposals} />
              )}
            </div>
          </section>
        </div>
      )}

      <div className={s['submit-proposal-container']}>
        <div className={s['submit-proposal']}>
          <span
            className={s['submit-proposal__image']}
            aria-label="Unicorn emoji"
            role="img">
            🦄
          </span>
          <div className={s['submit-proposal__title']}>
            Looking for funding?
          </div>
          <div className={s['submit-proposal__text']}>
            The LAO is a global group of Ethereum enthusiasts and experts
            supporting the work of Ethereum builders.
          </div>
          <button
            className={`${b['primary']} ${s['submit-proposal__button']}`}
            onClick={() => {
              history.push('/apply');
            }}>
            Submit a proposal
          </button>
        </div>
      </div>
    </Wrap>
  );
}
