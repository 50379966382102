import React, {useRef} from 'react';
import {matchSorter} from 'match-sorter';
import {useSelector} from 'react-redux';
import Downshift from 'downshift';

import {getOrgText} from '../../util/helpers';
import {StoreState} from '../../util/types';
import DownTriangleSVG from '../../assets/svg/DownTriangleSVG';
import XCloseSVG from '../../assets/svg/XCloseSVG';

import s from '../../assets/scss/modules/autocompleteselect.module.scss';

export const DEFAULT_KEY = '__AUTOCOMPLETESELECT_DEFAULT__';

interface IProps {
  itemReactKey: string;
  items: Array<any>;
  itemValueKey: string;
  onChange: (value: string) => any;
  onSelect: (value: any) => any;
  placeholder: string;
  userInputProps: {[key: string]: any};
}

export default function AutocompleteSelect(props: IProps) {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgAutocompleteInputBackgroundColor = getText(
    'OrgAutocompleteInputBackgroundColor'
  );
  const orgAutocompleteInputTriangleUpColor = getText(
    'OrgAutocompleteInputTriangleUpColor'
  );
  const orgAutocompleteInputTriangleDownColor = getText(
    'OrgAutocompleteInputTriangleDownColor'
  );
  const orgAutocompleteInputDeleteColor = getText(
    'OrgAutocompleteInputDeleteColor'
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const userInputValue =
    props.userInputProps && props.userInputProps.value
      ? props.userInputProps.value
      : '';

  /**
   * A default item is determined if the user provided a special key and text
   * to display before results show. Useful mainly for async requests.
   *
   * @see: `DEFAULT_KEY` in this file
   */
  const isItemDefault = (item: any) =>
    item && item[props.itemReactKey] === DEFAULT_KEY;

  const getItems = (inputValue: string | null) =>
    props.items &&
    props.items[0] &&
    props.items[0][props.itemReactKey] === DEFAULT_KEY
      ? // user has set a default (e.g. to show during async request)
        props.items
      : inputValue && props.items
      ? // we have values to sort
        matchSorter(props.items, inputValue, {
          /**
           * @see https://github.com/kentcdodds/match-sorter#keepdiacritics-boolean
           */
          keepDiacritics: true,
          threshold: matchSorter.rankings.NO_MATCH,
        })
      : (inputValue && !props.items) || (!inputValue && !props.items)
      ? []
      : // use all items as a fallback
        props.items;

  return (
    <Downshift
      initialInputValue={userInputValue}
      initialSelectedItem={userInputValue}
      itemToString={(i) => (i ? i[props.itemValueKey] : '')}
      onChange={props.onSelect}>
      {({
        clearSelection,
        getInputProps,
        getItemProps,
        getToggleButtonProps,
        inputValue,
        isOpen,
        selectedItem,
      }) => {
        const itemsDetermined = getItems(inputValue);

        // @note Do not let userInputProps' `value` pass to the `getInputProps()` or <input />
        //   as it messes things up internally for Downshift. Use `initialInputValue` prop.
        const {value: __do_not_use__, ...restUserInputProps} =
          props.userInputProps || {};

        return (
          <div>
            <div className={s['input-wrap']}>
              <input
                {...getInputProps({
                  ...restUserInputProps,
                  onChange: (event) => {
                    props.onChange(event.currentTarget.value);
                  },
                  placeholder: props.placeholder,
                  type: 'text',
                  style: {backgroundColor: orgAutocompleteInputBackgroundColor},
                })}
                ref={inputRef}
              />

              {selectedItem ? (
                <button
                  className={`${s.button} ${s['button-close']}`}
                  onClick={() => {
                    clearSelection();
                    inputRef.current && inputRef.current.focus();
                  }}
                  aria-label="clear selection">
                  <XCloseSVG fill={orgAutocompleteInputDeleteColor} />
                </button>
              ) : (
                <button
                  className={`${s.button} ${s['button-menu']}`}
                  {...getToggleButtonProps()}>
                  {isOpen ? (
                    <DownTriangleSVG
                      fill={orgAutocompleteInputTriangleUpColor}
                      style={{
                        transform: 'rotate(180deg)',
                      }}
                    />
                  ) : (
                    <DownTriangleSVG
                      fill={orgAutocompleteInputTriangleDownColor}
                    />
                  )}
                </button>
              )}
            </div>

            <div className={s['menu-wrap']}>
              <ul
                className={
                  isOpen && itemsDetermined.length > 0
                    ? `${s.menu} org-autocomplete-menu`
                    : s['menu--closed']
                }>
                {isOpen && itemsDetermined.length > 0
                  ? itemsDetermined.map((item, index) => (
                      <li
                        key={item[props.itemReactKey] || index}
                        {...getItemProps({
                          className: !isItemDefault(item)
                            ? `${s.item} org-autocomplete-item`
                            : `${s['item--default']} org-autocomplete-item--default`,
                          index,
                          // do not allow select of a default item
                          ...(!isItemDefault(item) ? {item} : {item: null}),
                        })}>
                        {item.address}
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          </div>
        );
      }}
    </Downshift>
  );
}
