import {
  AUTH_SERVER_ACCESS_TOKEN,
  AUTH_SERVER_HIDE_MODAL,
  AUTH_SERVER_SHOW_MODAL,
  AUTH_SERVER_STATUS,
} from './actions';
import {AuthServerState} from '../../util/types';

type AuthServerAction =
  | typeof AUTH_SERVER_ACCESS_TOKEN
  | typeof AUTH_SERVER_HIDE_MODAL
  | typeof AUTH_SERVER_SHOW_MODAL
  | typeof AUTH_SERVER_STATUS;

type AuthServerActionData = {
  type: AuthServerAction;
} & Partial<AuthServerState>;

const INITIAL_STATE = null;

export default function reducer(
  state: Partial<AuthServerState> = INITIAL_STATE,
  action: AuthServerActionData
) {
  const {type, ...payload} = action;

  switch (type) {
    case AUTH_SERVER_ACCESS_TOKEN:
      return authServerAccessToken(state, payload);
    case AUTH_SERVER_HIDE_MODAL:
      return authServerModal(state, payload);
    case AUTH_SERVER_SHOW_MODAL:
      return authServerModal(state, payload);
    case AUTH_SERVER_STATUS:
      return authServerStatus(state, payload);
    default:
      return state;
  }
}

function authServerAccessToken(
  state: Partial<AuthServerState>,
  accessToken: Partial<AuthServerState>
) {
  return {...state, ...accessToken};
}

function authServerStatus(
  state: Partial<AuthServerState>,
  status: Partial<AuthServerState>
) {
  return {...state, ...status};
}

function authServerModal(
  state: Partial<AuthServerState>,
  authServerModal: Partial<AuthServerState>
): Partial<AuthServerState> {
  return {
    ...state,
    ...authServerModal,
  };
}
