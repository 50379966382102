import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {StoreState} from '../util/types';
import {numberRangeArray} from '../util/helpers';
import {FetchStatus} from '../util/enums';

type ApprovedTokens = string[];

type ApprovedTokensData = {
  approvedTokens: ApprovedTokens;
  approvedTokensStatus: FetchStatus;
};

/**
 * useApprovedTokens
 *
 * Gets approved Moloch token balances for an address.
 *
 * @returns {ApprovedTokens}
 */
export default function useApprovedTokens(): ApprovedTokensData {
  const [approvedTokens, setApprovedTokens] = useState<ApprovedTokens>([]);
  const [tokenCount, setTokenCount] = useState<string>();
  const [approvedTokensStatus, setApprovedTokensStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );
  const VentureMoloch = useSelector(
    (s: StoreState) =>
      s.blockchain.contracts && s.blockchain.contracts.VentureMoloch
  );
  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );

  // Get token count
  useEffect(() => {
    try {
      if (!VentureMoloch || !connectedAddress) return;

      setApprovedTokensStatus(FetchStatus.PENDING);

      VentureMoloch.instance.methods
        .getTokenCount()
        .call({from: connectedAddress})
        .then(setTokenCount);
    } catch (error) {
      setTokenCount(undefined);
      setApprovedTokensStatus(FetchStatus.REJECTED);
    }
  }, [connectedAddress, VentureMoloch]);

  // Get and set approved token addresses
  useEffect(() => {
    if (!tokenCount || !VentureMoloch || !connectedAddress) return;

    const approvedTokensPromises = numberRangeArray(
      Number(tokenCount) - 1,
      0
    ).map(async (n) => {
      try {
        return await VentureMoloch.instance.methods
          .approvedTokens(n)
          .call({from: connectedAddress});
      } catch (error) {
        return;
      }
    });

    Promise.all(approvedTokensPromises)
      .then((t) => {
        const filtered = t.filter(Boolean);

        // If there's no approved tokens, no more work needs to be done.
        if (!filtered.length) {
          setApprovedTokensStatus(FetchStatus.FULFILLED);

          return;
        }

        setApprovedTokens(filtered);
      })
      .catch(() => setApprovedTokensStatus(FetchStatus.REJECTED));
  }, [tokenCount, VentureMoloch, connectedAddress]);

  return {approvedTokens, approvedTokensStatus};
}
