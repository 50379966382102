import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
// Web3 types
import {AbiItem} from 'web3-utils/types';

import {FetchStatus} from '../util/enums';
import {StoreState} from '../util/types';
import {TokenBalance} from './useMolochTokenBalances';
import ERC20 from '../truffle-contracts/ERC20.json';
import useCounter from './useCounter';

/**
 * Gets fresh balances. Can cause a loop if not used carefully.
 */
type GetBalancesCallback = () => void;

type ERC20BalancesData = {
  erc20Balances: TokenBalance[];
  erc20BalancesStatus: FetchStatus;
  refetchERC20Balances: GetBalancesCallback;
};

/**
 * useERC20Balances
 *
 * Gets the member's ERC20 token balances at the token address.
 * You may pass in any addresses for `approvedTokens`, but it's best to
 * use Moloch's approved tokens (i.e. `useApprovedTokens()`).
 *
 * @param {string} ethereumAddress of user to get balances for in Moloch
 * @param {string[]} approvedTokens i.e. `{ approvedTokens } = useApprovedTokens()` value.
 * @returns {ERC20Return}
 */
export default function useERC20Balances(
  ethereumAddress: string = '',
  approvedTokens: string[] = []
): ERC20BalancesData {
  const [erc20Balances, setERC20balances] = useState<TokenBalance[]>([]);
  const [erc20BalancesStatus, setERC20balancesStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );

  // Send dispatch to consumer as a callback that can "re-run" this hook.
  const [getBalancesCount, dispatch] = useCounter();
  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );
  const web3Instance = useSelector(
    (s: StoreState) => s.blockchain.web3Instance
  );

  useEffect(() => {
    if (!approvedTokens.length || !web3Instance || !connectedAddress) return;

    const tokenBalancePromises = approvedTokens.map(async (ta) => {
      try {
        const erc20 = new web3Instance.eth.Contract(ERC20 as AbiItem[], ta);

        setERC20balancesStatus(FetchStatus.PENDING);

        // Get token `name`, `symbol`, `decimals`
        // @note ERC20-compliant contracts are not required to include `name` and `symbol`
        const name: string =
          (await erc20.methods.name().call({from: connectedAddress})) || '';
        const symbol: string =
          (await erc20.methods.symbol().call({from: connectedAddress})) || '';
        const decimals: string =
          (await erc20.methods.decimals().call({from: connectedAddress})) || '';
        const balance: string =
          (await erc20.methods
            .balanceOf(ethereumAddress || connectedAddress)
            .call({from: connectedAddress})) || '';

        return {address: ta, balance, name, symbol, decimals};
      } catch (error) {
        throw error;
      }
    });

    Promise.all(tokenBalancePromises)
      .then((t) => {
        setERC20balances(t);
        setERC20balancesStatus(FetchStatus.FULFILLED);
      })
      .catch(() => {
        setERC20balances([]);
        setERC20balancesStatus(FetchStatus.REJECTED);
      });
  }, [
    ethereumAddress,
    approvedTokens,
    web3Instance,
    connectedAddress,
    getBalancesCount,
  ]);

  function incrementGetBalancesCount() {
    dispatch({type: 'increment'});
  }

  return {
    erc20Balances,
    erc20BalancesStatus,
    refetchERC20Balances: incrementGetBalancesCount,
  };
}
