import React, {useState, useEffect} from 'react';
import ReactModal from 'react-modal';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import Web3 from 'web3';
import AOS from 'aos';
import '../../../node_modules/aos/dist/aos.css';

import {
  useKYCCheck,
  useKYCContributionWhitelist,
  useInvestmentProposalsParsedSponsored,
} from '../../hooks';
import {CenterLogo} from '../../components/logo';
import {getOrgText} from '../../util/helpers';
import {StoreState} from '../../util/types';
import {useSales} from '../../hooks';
import {useWeb3Modal} from '../../components/web3/Web3ModalManager';
import DAOBalancesText from '../sale/DAOBalancesText';
import FadeIn from '../../components/common/FadeIn';
import HiThereLoader from '../../components/feedback/HiThereLoader';
import InvestmentProposalsCards from '../proposals/InvestmentProposalsCards';
import SaleModal from '../sale/SaleModal';
import TimesSVG from '../../assets/svg/TimesSVG';
import Web3ModalButton from '../../components/web3/Web3ModalButton';
import Wrap from '../../components/layout/Wrap';

import b from '../../assets/scss/modules/buttons.module.scss';
import s from '../../assets/scss/modules/interest.module.scss';
import m from '../../assets/scss/modules/modal.module.scss';
import sm from '../../assets/scss/modules/sale.module.scss';

/**
 * MEMOIZIED ASSET COMPONENTS - PREVENTS RE-RENDERING
 */
const orgInternalName = 'nft'; // @todo temp: hard-coding for now

const NFTDAOText = React.memo(() => {
  return (
    <div
      className="org-nft-tagline"
      data-aos="fade-up-left"
      data-aos-delay="150">
      ...an nft dao
    </div>
  );
});

const FlamingoImg = React.memo(() => {
  // @todo `orgInternalName` use state prop, but for now hard-code orgInternalName
  return (
    <div
      className="org-splash-flamingo"
      data-aos="fade-up-right"
      data-aos-delay="150">
      <img
        alt="Flamingo"
        src={`/orgs/${orgInternalName}/images/splash-flamingo.png`}
      />
    </div>
  );
});

const SkullImg = React.memo(() => {
  // @todo `orgInternalName` use state prop, but for now hard-code orgInternalName
  return (
    <div className="org-splash-skull" data-aos="fade-up" data-aos-delay="150">
      <img alt="Skull" src={`/orgs/${orgInternalName}/images/skull.png`} />
    </div>
  );
});

const DonutImg = React.memo(() => {
  // @todo `orgInternalName` use state prop, but for now hard-code orgInternalName
  return (
    <div className="org-splash-donut" data-aos="fade-up" data-aos-delay="150">
      <img alt="Donut" src={`/orgs/${orgInternalName}/images/donut.png`} />
    </div>
  );
});

const TearAndStarsImg = React.memo(() => {
  // @todo `orgInternalName` use state prop, but for now hard-code orgInternalName
  return (
    <div className="org-tears-stars">
      <img
        alt="Tear and stars"
        src={`/orgs/${orgInternalName}/images/tears-and-stars.png`}
      />
    </div>
  );
});

export default function GetStartedNFT() {
  /**
   * Selectors
   */

  const orgDocsURL =
    useSelector((s: StoreState) => s.org && s.org.docsURL) ||
    'https://docs.flamingodao.xyz';
  const orgFeatures = useSelector((s: StoreState) => s.org && s.org.features);
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  const {account, connected} = useWeb3Modal();
  const history = useHistory();

  /**
   * Our hooks
   */

  const {sales} = useSales();

  const {userKYCInfo} = useKYCCheck();

  const {kycIsContributionAllowed} = useKYCContributionWhitelist();

  const {
    areInvestmentProposalsLoading,
    proposalEntriesPassed,
    filterInvestmentProposalsByViewable,
  } = useInvestmentProposalsParsedSponsored();

  /**
   * State
   */

  const [showSaleModal, setShowSaleModal] = useState<boolean>(false);
  const [showPendingVerificationModal, setShowPendingVerificationModal] =
    useState<boolean>(false);

  /**
   * Effects
   */

  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 40,
      delay: 120,
      mirror: false,
      once: true,
    });
  }, []);

  /**
   * Variables
   */

  const deployedCapitalFeatureFlag = orgFeatures && orgFeatures.deployedCapital;
  const getText = getOrgText(orgText);
  const orgInvestmentsText = getText('OrgInvestmentsText');

  // Get the last 3 passed proposals
  const passedProposals = filterInvestmentProposalsByViewable(
    proposalEntriesPassed
  )
    .map((p) => p[2])
    .filter((_, i) => i < 3);

  // Get the total ETH requested from Moloch passed proposals
  const molochEthRequested = getMolochDeployedEthAmount(passedProposals);

  // Get the total ETH requested from snapshot passed proposals
  const snapshotEthRequested = getSnapshotDeployedEthAmount(passedProposals);

  // Get the total ETH requested from Moloch and snapshot passed proposals
  const totalEthRequested = (
    Number(molochEthRequested) + snapshotEthRequested
  ).toString();

  /**
   * Functions
   */

  /**
   * Sums total ETH requested from moloch passed proposals (after the voting
   * period has ended and regardless of whether the proposal has been processed)
   */
  function getMolochDeployedEthAmount(
    proposals: typeof passedProposals
  ): string {
    const committedWeiAmounts = proposals
      .filter((p) => p.proposalTarget === 'moloch')
      .map((proposal) =>
        Web3.utils.toBN((proposal as Record<string, any>).paymentRequested)
      );
    const sum: string = committedWeiAmounts.reduce((a, b) => {
      return Web3.utils.toBN(a.toString()).add(Web3.utils.toBN(b.toString()));
    }, 0 as any);
    const totalCommittedWeiAmount = Web3.utils.toBN(sum);

    return Web3.utils.fromWei(totalCommittedWeiAmount, 'ether');
  }

  /**
   * Sums total ETH requested from snapshot passed proposals (after the voting
   * period has ended)
   */
  function getSnapshotDeployedEthAmount(
    snapshotProposals: typeof passedProposals
  ): number {
    return snapshotProposals
      .filter((p) => p.proposalTarget === 'snapshot')
      .reduce(
        (acc, currentValue) => acc + Number(currentValue.amountRequested),
        0
      );
  }

  function handleModalClose() {
    showSaleModal && setShowSaleModal(false);
    showPendingVerificationModal && setShowPendingVerificationModal(false);
  }

  function DisabledReason() {
    return (
      <ReactModal
        ariaHideApp={false}
        className={`${m['modal-content-wide']}`}
        isOpen={showPendingVerificationModal}
        onRequestClose={handleModalClose}
        overlayClassName={`${m['modal-overlay']} org-modal-overlay`}
        role="dialog"
        style={
          {overlay: {zIndex: '99'}, content: {maxWidth: '32.5rem'}} as any
        }>
        <FadeIn>
          <div
            className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} org-modal`}>
            <div className={`${sm.sales} card`}>
              {/* MODEL CLOSE BUTTON */}
              <span
                className={`${b['modal-close']} org-modal-close`}
                onClick={handleModalClose}>
                <TimesSVG />
              </span>

              <p className={`text-center org-notification info`}>
                We&rsquo;re still verifying your information
              </p>
            </div>
          </div>
        </FadeIn>
      </ReactModal>
    );
  }

  return (
    <Wrap
      className={`${s['wrap']} ${
        deployedCapitalFeatureFlag ? 'deployed-capital' : ''
      }`}>
      <NFTDAOText />
      <FlamingoImg />

      <header className={s['eth-data-container']}>
        <CenterLogo />

        <div className="org-dao-data">
          <DAOBalancesText
            deployedETHAmount={
              deployedCapitalFeatureFlag ? totalEthRequested : undefined
            }
            sales={sales}
            showDeployedETHAmount={deployedCapitalFeatureFlag}
            showRemainingAmount={!deployedCapitalFeatureFlag}
          />
        </div>
      </header>

      <div className={`${s['middle-links']} org-middle-links`}>
        {account && connected ? (
          <button
            className={`${b['primary']} ${s['middle-links__button']} org-middle-links__button`}
            onClick={() => {
              !userKYCInfo || !Object.keys(userKYCInfo).length
                ? history.push('/verify-start')
                : userKYCInfo.isVerified &&
                  kycIsContributionAllowed(userKYCInfo.ethereumAddress)
                ? setShowSaleModal(true)
                : setShowPendingVerificationModal(true);
            }}>
            JOIN the HUNT
          </button>
        ) : (
          <Web3ModalButton customWalletText="JOIN the HUNT" />
        )}

        <span className={`${s['middle-links__link']} org-middle-links__link`}>
          {deployedCapitalFeatureFlag ? (
            <Link to={`/${orgInvestmentsText.toLowerCase()}`}>new finds</Link>
          ) : (
            <a href={orgDocsURL} rel="noopener noreferrer" target="_blank">
              Learn More
            </a>
          )}
        </span>
      </div>

      {deployedCapitalFeatureFlag && (
        <div className={`${s['recent-investments']} org-recent-investments`}>
          {areInvestmentProposalsLoading ? (
            <>
              <div style={{width: '3rem', margin: '0 auto'}}>
                <HiThereLoader />
              </div>

              <p className="text-center">Loading&hellip;</p>
            </>
          ) : (
            <section
              className={
                'grid--fluid sections-grid-container org-sections-grid-container'
              }>
              <div
                className={`sections-grid__cards${
                  passedProposals.length === 1
                    ? ' sections-grid__cards--center-one'
                    : ''
                }`}>
                <InvestmentProposalsCards proposals={passedProposals} />
              </div>
            </section>
          )}
        </div>
      )}

      <div
        className={`${s['submit-proposal-container']} org-submit-proposal-container`}>
        <div className={`${s['submit-proposal']} org-submit-proposal`}>
          <div
            className={`${s['submit-proposal__title']} org-submit-proposal__title`}>
            ...destiny mindshare
          </div>
          <div
            className={`${s['submit-proposal__text']} org-submit-proposal__text`}>
            NFTs evolve and ascribe value in the hands of artists, game makers,
            metaverse creators or dwellers, and DeFi at large. FLAMINGO aims to
            support, purchase, archive, collect, and potentially tokenize
            important pieces of this ecosystem.
          </div>
          <TearAndStarsImg />
        </div>
      </div>

      <SkullImg />
      <DonutImg />

      {showSaleModal && userKYCInfo?.isVerified && (
        <SaleModal
          onRequestClose={handleModalClose}
          sales={sales}
          show={showSaleModal}
          userInfo={userKYCInfo}
        />
      )}

      {showPendingVerificationModal && !userKYCInfo?.isVerified && (
        <DisabledReason />
      )}
    </Wrap>
  );
}
