import React from 'react';
import {useSelector} from 'react-redux';

import {useTotalAmountContributedMongo} from '../../hooks';

import {formatNumber, getOrgText} from '../../util/helpers';
import {Sale, StoreState} from '../../util/types';

type DAOBalancesTextProps = {
  deployedETHAmount?: string;
  sales: Sale[];
  showDeployedETHAmount: boolean;
  showRemainingAmount: boolean;
};

export default function DAOBalancesText(props: DAOBalancesTextProps) {
  const {
    deployedETHAmount,
    sales = [],
    showDeployedETHAmount,
    showRemainingAmount,
  } = props;

  const result = useTotalAmountContributedMongo();

  /**
   * Selectors
   */

  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  /**
   * Variables
   */

  const getText = getOrgText(orgText);
  const orgPercentRemainingText = getText('OrgDAOBalancesPercentRemaining');
  const orgETHAddedText = getText('OrgDAOBalancesETHAdded');
  const {amountContributed} = result;

  /**
   * Functions
   */

  function getTotalSales() {
    return sales
      .filter((s) => s.status === 'active')
      .reduce(
        (acc, currentValue) =>
          acc <= 0 ? 0 : acc - Number(currentValue.percentOfTotalShares),
        100
      );
  }

  return (
    <div className="org-dao-balances-container">
      {amountContributed && (
        <>
          <span className="org-dao-data-stat">
            <span className="org-data-stat">
              {formatNumber(Number(amountContributed).toFixed(2))}
            </span>{' '}
            {orgETHAddedText}
          </span>
        </>
      )}

      {showRemainingAmount && orgPercentRemainingText && (
        <span className="org-dao-data-stat">
          <span className="org-data-stat">{getTotalSales().toString()}%</span>{' '}
          {orgPercentRemainingText}
        </span>
      )}

      {showDeployedETHAmount && (
        <span className="org-dao-data-stat">
          {formatNumber(Number(deployedETHAmount).toFixed(2))} ETH Deployed
        </span>
      )}
    </div>
  );
}
