import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import ReactModal from 'react-modal';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

import {StoreState} from '../../util/types';
import {InternalNamesToMap} from '../../util/orgDomainMappings';

import TwitterSVG from '../../assets/svg/TwitterSVG';
import GitHubSVG from '../../assets/svg/GitHubSVG';
import TelegramSVG from '../../assets/svg/TelegramSVG';
import LinkedInSVG from '../../assets/svg/LinkedInSVG';
import TimesSVG from '../../assets/svg/TimesSVG';
import FadeIn from '../../components/common/FadeIn';

import s from '../../assets/scss/modules/mentorcard.module.scss';
import b from '../../assets/scss/modules/buttons.module.scss';
import i from '../../assets/scss/modules/index.module.scss';
import sm from '../../assets/scss/modules/sale.module.scss';
import m from '../../assets/scss/modules/modal.module.scss';

enum SocialMediaTypes {
  TWITTER = 'twitter',
  GITHUB = 'github',
  TELEGRAM = 'telegram',
  LINKEDIN = 'linkedin',
}

type SocialMediaLinks = {
  twitter?: string;
  telegram?: string;
  github?: string;
  linkedin?: string;
};

type MentorCardProps = {
  children: string | React.ReactNode;
  emoji: string;
  name: string;
  handle: string;
  socialMediaLinks?: SocialMediaLinks;
};

export default function MentorCard(props: MentorCardProps) {
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const orgFeatures = useSelector((s: StoreState) => s.org && s.org.features);
  const showMentorModal = orgFeatures && orgFeatures.showMentorModal;
  const bio: string | React.ReactNode = props.children;
  const [showMentorCard, setShowMentorCard] = useState(false);

  function renderSocialMediaURL(
    socialMediaType: keyof SocialMediaLinks | undefined,
    socialMediaURL: string | undefined
  ) {
    switch (socialMediaType) {
      case SocialMediaTypes.TWITTER:
        return (
          <a
            key={'twitter'}
            href={`${socialMediaURL}`}
            className={''}
            target="_blank"
            rel="noopener noreferrer">
            <TwitterSVG />
          </a>
        );
      case SocialMediaTypes.GITHUB:
        return (
          <a
            key={'github'}
            href={`${socialMediaURL}`}
            className={''}
            target="_blank"
            rel="noopener noreferrer">
            <GitHubSVG />
          </a>
        );
      case SocialMediaTypes.TELEGRAM:
        return (
          <a
            key={'telegram'}
            href={`${socialMediaURL}`}
            className={''}
            target="_blank"
            rel="noopener noreferrer">
            <TelegramSVG />
          </a>
        );
      case SocialMediaTypes.LINKEDIN:
        return (
          <a
            key={'linkedin'}
            href={`${socialMediaURL}`}
            className={''}
            target="_blank"
            rel="noopener noreferrer">
            <LinkedInSVG />
          </a>
        );
      default:
        break;
    }
  }

  function onRequestClose() {
    setShowMentorCard(false);
  }

  function handleShowMentorCard() {
    setShowMentorCard(true);
  }

  function renderCloseButton() {
    switch (orgInternalName) {
      case InternalNamesToMap.nft:
      case InternalNamesToMap.liquidity:
        return (
          <span className="org-modal-close" onClick={onRequestClose}>
            <TimesSVG />
          </span>
        );
      case InternalNamesToMap.thelao:
      default:
        return (
          <div className={s['close']}>
            <button className={`${b.underline}`} onClick={onRequestClose}>
              Close
            </button>
          </div>
        );
    }
  }

  return (
    <>
      <div
        className={`${s['mentor-card']} org-mentor-card ${
          showMentorModal ? 'org-mentor-card--is-clickable' : ''
        }`}
        onClick={showMentorModal ? handleShowMentorCard : () => {}}>
        <div className={`${s['mentor-contact']} org-mentor-contact`}>
          <span className={`${s['mentor-emoji']} org-mentor-emoji`} role="img">
            {props.emoji}
          </span>
          <div className={s['mentor-name-container']}>
            <span className={`${s['mentor-name']} org-mentor-name`}>
              {props.name}
            </span>
            <span className={`${s['mentor-handle']} org-mentor-handle`}>
              {props.handle}
            </span>
          </div>
        </div>
        {bio && (
          <div className={`${s['mentor-bio']} org-mentor-bio`}>
            <ResponsiveEllipsis
              text={bio}
              maxLine={4}
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </div>
        )}
        <div className={`${s['mentor-connect']} org-mentor-connect`}>
          {/* <button className={`${b['secondary']} org-secondary-button ${b['secondary--small']}`}>
            Make advisor
          </button> */}
          {props.socialMediaLinks && (
            <div
              key={'social-links'}
              className={`${i['social-media-wrapper']} org-social-media-wrapper ${s['mentor-social-media']}`}>
              {Object.entries(props.socialMediaLinks).map(([key, val]) =>
                renderSocialMediaURL(key as keyof SocialMediaLinks, val)
              )}
            </div>
          )}
        </div>
      </div>
      <ReactModal
        ariaHideApp={false}
        className={`${m['modal-content-wide']}`}
        isOpen={showMentorCard}
        onRequestClose={onRequestClose}
        overlayClassName={`${m['modal-overlay']} org-modal-overlay`}
        role="dialog"
        style={
          {overlay: {zIndex: '99'}, content: {maxWidth: '45.5rem'}} as any
        }>
        <FadeIn>
          <div
            className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} ${s.mentorModal} org-modal`}>
            <div className={`${sm.sales} card`}>
              {renderCloseButton()}
              <div className={`${s['mentor-contact']} org-mentor-contact`}>
                <span
                  className={`${s['mentor-emoji']} org-mentor-emoji`}
                  role="img">
                  {props.emoji}
                </span>
                <div className={s['mentor-name-container']}>
                  <span className={`${s['mentor-name']} org-mentor-name`}>
                    {props.name}
                  </span>
                  <span className={`${s['mentor-handle']} org-mentor-handle`}>
                    {props.handle}
                  </span>
                </div>
              </div>
              {bio && (
                <p className={`${s['mentor-bio']} org-mentor-bio`}>{bio}</p>
              )}
            </div>
          </div>
        </FadeIn>
      </ReactModal>
    </>
  );
}
