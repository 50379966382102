export const hasValue = (value: string) => (value !== '' ? true : false);

export const isEmailValid = (email: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

export const isEthAddressValid = (ethAddress: string) =>
  /^0x[a-fA-F0-9]{40}$/i.test(ethAddress);

/**
 * isNumberString
 *
 * Checks if a string is a valid `Number`.
 *
 * @param numberString A string that may, or may not, be a valid, parsed `Number` value.
 */
export const isNumberString = (numberString: string) =>
  Boolean(Number(numberString)) || Number(numberString) === 0;

/**
 * isTwitterUsername
 *
 * @see https://stackoverflow.com/a/8650024
 *
 * @param {string} username
 * @returns {boolean}
 */
export const isTwitterUsername = (username: string) =>
  /^@?(\w){1,15}$/.test(username);

export const isURLValid = (url: string) => {
  // protocoless for ease of use (doesn't validate https://, ftp:// etc.)
  const URLRegex =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

  return URLRegex.test(url);
};

/**
 * isDateStringValid
 *
 * @param {string} dateString - Input as string (e.g. 01-01-1970)
 * @param {RegExp} regex - Defaults to mm/dd/yyyy | mm-dd-yyyy, or combination of delimiters.
 * @return {boolean}
 */
export const isDateStringValid = (
  dateString: string,
  regex = /^([0-9]{2}[-/]){2}([0-9]{4})$/
) => {
  return new RegExp(regex).test(dateString);
};
