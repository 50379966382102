import {useState} from 'react';
import {useSelector} from 'react-redux';

import {
  getBalancerHubVersion,
  SNAPSHOT_CHOICES,
  SNAPSHOT_HUB_URL,
} from './helpers';
import {FetchStatus} from '../../util/enums';
import {ethPersonalSign} from '../../util/helpers';
import {useIsDefaultChain} from '../../hooks';
import {SnapshotGetFormValuesReturn} from './types';
import {StoreState} from '../../util/types';

type UseCreateSnapshotProposalReturn = {
  createSnapshotProposal: (
    fv: SnapshotGetFormValuesReturn,
    /**
     * To change the API submit address.
     * e.g. Submit to our server where we will then call the Snapshot Hub.
     */
    url?: string
  ) => () => Promise<string>;
  snapshotProposalSignStatus: FetchStatus;
  snapshotProposalSubmitError: Error | undefined;
  snapshotProposalSubmitStatus: FetchStatus;
  snapshotSubmitIsInProcessOrDone: boolean;
  snapshotProposalIPFSHash: string;
};

export default function useCreateSnapshotProposal(): UseCreateSnapshotProposalReturn {
  /**
   * Hooks
   */
  const {isDefaultChain, defaultChainError} = useIsDefaultChain();

  /**
   * State
   */

  const [IPFSHash, setIPFSHash] = useState<string>('');
  const [submitError, setSubmitError] = useState<Error>();
  const [submitStatus, setSubmitStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );
  const [signStatus, setSignStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );

  /**
   * Selectors
   */

  const userEthereumAddress = useSelector(
    (s: StoreState) => s.user.ethereumAddress
  );
  const connectedMember = useSelector((s: StoreState) => s.connectedMember);

  const snapshotToken = useSelector(
    (s: StoreState) => s.org && s.org.snapshotToken
  );

  const web3Instance = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.web3Instance
  );

  /**
   * Variables
   */

  const isInProcessOrDone =
    signStatus === FetchStatus.PENDING ||
    submitStatus === FetchStatus.PENDING ||
    (signStatus === FetchStatus.FULFILLED &&
      submitStatus === FetchStatus.FULFILLED);

  /**
   * Functions
   */

  /**
   * @note adminOrDelegateEthereumAddress()
   * Connected user may be a member (delegateKey) or admin, if admin checksum addr
   */
  function adminOrDelegateEthereumAddress(): string {
    if (connectedMember.delegateKey) {
      const {delegateKey} = connectedMember;
      return web3Instance?.utils.toChecksumAddress(delegateKey) || '';
    } else if (userEthereumAddress) {
      return web3Instance?.utils.toChecksumAddress(userEthereumAddress) || '';
    }
    return '';
  }

  async function getMessage(
    formValues: SnapshotGetFormValuesReturn
  ): Promise<string> {
    if (!web3Instance) {
      throw new Error('Please check if your wallet is connected.');
    }

    if (!snapshotToken) {
      throw new Error('No snapshot token was found.');
    }

    try {
      const hubVersion = await getBalancerHubVersion();
      // @see https://web3js.readthedocs.io/en/v1.2.11/web3-eth.html#getblock
      const snapshot = await (await web3Instance.eth.getBlock('latest')).number;

      const {body, name, voteLengthSeconds, metadata} = formValues;
      const nowTimestampSeconds: number = Date.now() / 1000;

      return JSON.stringify({
        payload: {
          name: name.trim(),
          body: body.trim(),
          choices: SNAPSHOT_CHOICES,
          // seconds number
          start: Math.ceil(nowTimestampSeconds),
          // moloch voting period (not including grace) as seconds number
          end: Math.ceil(nowTimestampSeconds + Number(voteLengthSeconds)),
          // we can use the latest block (not used by us, but required by API)
          snapshot,
          metadata,
        },
        // seconds string
        timestamp: nowTimestampSeconds.toFixed(),
        // required by API for generating unique key string for storage
        token: snapshotToken,
        type: 'proposal',
        // must match the Snapshot Hub's package.json version
        version: hubVersion,
      });
    } catch (error) {
      throw error;
    }
  }

  /**
   * handleCreateProposal
   *
   * Handles submitting the proposal to the Snapshot API.
   *
   * @returns {() => Promise<void>}
   */
  function handleCreateProposal(
    formValues: SnapshotGetFormValuesReturn,
    fetchURL?: string
  ) {
    return async (): Promise<string> => {
      try {
        if (!isDefaultChain) {
          throw new Error(defaultChainError);
        }

        const message = await getMessage(formValues);
        const signature = await handleEthSign(message);

        setSubmitStatus(FetchStatus.PENDING);

        const response = await fetch(
          fetchURL || `${SNAPSHOT_HUB_URL}/api/message`,
          {
            method: 'POST',
            body: JSON.stringify({
              address: adminOrDelegateEthereumAddress(),
              msg: message,
              sig: signature,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          const errorJSON = await response.json();
          throw new Error(JSON.stringify(errorJSON));
        }

        const {ipfsHash} = await response.json();

        setSubmitStatus(FetchStatus.FULFILLED);
        setIPFSHash(ipfsHash);

        return ipfsHash as string;
      } catch (error) {
        setSubmitError(error);
        setSubmitStatus(FetchStatus.REJECTED);

        return '';
      }
    };
  }

  async function handleEthSign(message: string) {
    try {
      if (!web3Instance) {
        throw new Error('Please check if your wallet is connected.');
      }

      setSignStatus(FetchStatus.PENDING);

      const signature = await ethPersonalSign(
        [message, adminOrDelegateEthereumAddress(), ''],
        web3Instance
      );

      setSignStatus(FetchStatus.FULFILLED);

      return signature;
    } catch (error) {
      setSignStatus(FetchStatus.REJECTED);

      throw error;
    }
  }

  return {
    createSnapshotProposal: handleCreateProposal,
    snapshotProposalIPFSHash: IPFSHash,
    snapshotProposalSignStatus: signStatus,
    snapshotProposalSubmitError: submitError,
    snapshotProposalSubmitStatus: submitStatus,
    snapshotSubmitIsInProcessOrDone: isInProcessOrDone,
  };
}
