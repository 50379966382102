import React, {useEffect, useState, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useForm, Controller} from 'react-hook-form';
import Editor from 'react-simple-code-editor';
import {highlight, languages} from 'prismjs/components/prism-core';

import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-markup';

import {BACKEND_URL} from '../../../util/config';
import {EmailData, StoreState} from '../../../util/types';
import {getAuthHeader} from '../../../util/helpers';
import {authServerShowModal} from '../../../store/actions';

import b from '../../../assets/scss/modules/buttons.module.scss';

export default function EmailTemplates() {
  const accessToken = useSelector(
    (s: StoreState) => s.authServer && s.authServer.accessToken
  );
  const orgId = useSelector((state: StoreState) => state.org && state.org.id);
  const {control, register, getValues, setValue, watch} = useForm();

  watch();

  const [fetchedEmails, setFetchedEmails] = useState<Array<EmailData>>([]);
  const [fetchSettings, setFetchSettings] = useState<boolean>(true);
  const [latestEmailCount, setLatestEmailCount] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  const getLatestEmailsCached = useCallback(getLatestEmails, [orgId]);
  const getEmailsCached = useCallback(getEmails, [orgId]);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchEmailSettings() {
      try {
        setFetchSettings(true);

        getLatestEmailsCached();

        const emailSettings = await getEmailsCached();

        setFetchedEmails(emailSettings);
        setFetchSettings(false);
      } catch (error) {
        // @todo: handle error
        console.error(error);
      }
    }

    if (orgId) {
      fetchEmailSettings();
    }
  }, [orgId, getEmailsCached, getLatestEmailsCached]);

  async function getEmails() {
    try {
      const response = await fetch(`${BACKEND_URL}/${orgId}/email`, {
        method: 'GET',
      });

      const emailSettings = await response.json();

      return emailSettings;
    } catch (error) {}
  }

  async function getLatestEmails() {
    try {
      // check for any new email templates
      const latest = await fetch(`${BACKEND_URL}/${orgId}/email/latest`, {
        method: 'GET',
      });
      const getLatest = await latest.json();
      setLatestEmailCount(getLatest.length);
    } catch (error) {
      // @todo: handle error
      console.error(error);
    }
  }

  async function handlePopulateEmails() {
    try {
      if (!accessToken) {
        throw new Error('No access token was found.');
      }

      setIsDisabled(true);

      await fetch(`${BACKEND_URL}/${orgId}/email/populate`, {
        method: 'POST',
        headers: {
          ...getAuthHeader(accessToken),
        },
      });

      // Get updated emails
      const emailSettings = await getEmails();

      setFetchedEmails(emailSettings);
      setIsDisabled(false);

      getLatestEmails();
    } catch (error) {
      // @todo: handle error
      console.error(error);
    }
  }

  function handleShowAuthServerModal() {
    // Show the auth modal and exit this process if the user has not authenticated with the server.
    if (!accessToken) {
      dispatch(authServerShowModal(true));
    }
  }

  async function handleUpdateEmailSetting() {
    try {
      if (!accessToken) {
        throw new Error('No access token was found.');
      }

      setIsDisabled(true);

      const nestedObjectValue = getValues({nest: true});

      for (let internalTitle in nestedObjectValue) {
        let diff = {...nestedObjectValue[internalTitle]};

        // find obj in original
        const original: any = fetchedEmails.find(
          (item) => item.internalTitle === internalTitle
        );

        // remove unchanged key/values, we only want changed items
        for (let key in diff) {
          if (diff[key as string] === original[key]) {
            delete diff[key];
          }
        }

        if (Object.entries(diff).length > 0) {
          const response = await fetch(`${BACKEND_URL}/${orgId}/email`, {
            method: 'PATCH',
            body: JSON.stringify({
              ...diff,
              internalTitle,
            }),
            headers: {
              'Content-Type': 'application/json',
              ...getAuthHeader(accessToken),
            },
          });

          const updateStatus = response.status;

          setIsSaved(updateStatus === 204 || updateStatus === 200);
        }
        setIsDisabled(false);
      }

      // update email state, for comparsion
      const emailSettings = await getEmails();

      setFetchedEmails(emailSettings);
    } catch (error) {
      // @todo: handle error
      console.error(error);
    }
  }

  function renderFields(emails: EmailData[]) {
    return emails.map((email: any) => renderSettingEditor(email));
  }

  function renderSettingEditor(setting: EmailData) {
    const {
      bcc,
      cc,
      contactEmail,
      html,
      internalTitle,
      previewText,
      title,
      uuid,
      from,
    } = setting;

    const htmlEditor = (
      <Editor
        ref={register}
        value={html}
        onValueChange={(code) => setValue(`${internalTitle}.html`, code)}
        highlight={(code) => highlight(code, languages.html)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 16,
        }}
        className="container__editor"
      />
    );

    return (
      <form key={`${uuid}${internalTitle}`}>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">
            {internalTitle.split('_').join(' ')}
          </h2>
        </div>
        <div>
          <label htmlFor="from">From:</label>
          <input
            type="text"
            name={`${internalTitle}.from`}
            defaultValue={from}
            ref={register}
          />
        </div>
        <div>
          <label htmlFor="cc">Cc:</label>
          <input type="text" name="cc" defaultValue={cc || ''} />
        </div>
        <div>
          <label htmlFor="bcc">Bcc:</label>
          <input
            type="text"
            name={`${internalTitle}.bcc`}
            defaultValue={bcc}
            ref={register}
          />
        </div>
        <div>
          <label htmlFor="contactEmail">Contact Email:</label>
          <input
            type="text"
            name={`${internalTitle}.contactEmail`}
            defaultValue={contactEmail}
            ref={register}
          />
        </div>
        <div>
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            name={`${internalTitle}.title`}
            defaultValue={title}
            ref={register}
          />
        </div>
        <div>
          <label htmlFor="previewText">Preview Text:</label>
          <input
            type="text"
            name={`${internalTitle}.previewText`}
            defaultValue={previewText}
            ref={register}
          />
        </div>
        <div>
          <label htmlFor="html">HTML:</label>
          <div className="container_editor_area">
            <Controller
              as={htmlEditor}
              name={`${internalTitle}.html`}
              control={control}
              defaultValue={html}
              onChange={([event]) => event.target.value}
            />
          </div>
        </div>
      </form>
    );
  }

  return (
    <div className="email-settings">
      {/** NO SETTINGS AVAILABLE - POPULATE */}
      {fetchSettings ? (
        'Loading'
      ) : (
        <>
          {/** DISPLAY GET LATEST BUTTON */}
          {latestEmailCount > 0 && fetchedEmails.length > 0 && (
            <div>
              <p>
                There {latestEmailCount > 1 ? 'are' : 'is'} {latestEmailCount}{' '}
                new email template(s), update to get the latest
              </p>
              <button
                className={`${b.primary} org-primary-button`}
                onClick={
                  accessToken ? handlePopulateEmails : handleShowAuthServerModal
                }>
                Update
              </button>
            </div>
          )}
          {/** DISPLAY EMAIL SETTINGS */}
          {!fetchSettings && fetchedEmails.length > 0 && (
            <div className="org-form-wrap">
              {renderFields(fetchedEmails)}
              <div
                style={{
                  textAlign: 'center',
                  margin: '4rem 1rem 1rem',
                  paddingBottom: '2rem solid black',
                }}>
                {isSaved && (
                  <>
                    <sup
                      className={`${b['email-settings-save-confirm']} org-email-settings-save-confirm`}>
                      Successfully saved!
                    </sup>
                    <br />
                  </>
                )}
                <button
                  className={`${b.primary} org-primary-button`}
                  type="button"
                  disabled={isDisabled}
                  // Check first if user is authenticated with the server
                  onClick={
                    accessToken
                      ? handleUpdateEmailSetting
                      : handleShowAuthServerModal
                  }>
                  Save
                </button>
              </div>
            </div>
          )}
          {/** DISPLAY POPULATE BUTTON */}
          {(!Array.isArray(fetchedEmails) || !fetchedEmails.length) && (
            <button
              className={`${b.primary} org-primary-button`}
              // Check first if user is authenticated with the server
              onClick={
                accessToken ? handlePopulateEmails : handleShowAuthServerModal
              }>
              Populate Emails
            </button>
          )}
        </>
      )}
    </div>
  );
}
