import React from 'react';
import {connect} from 'react-redux';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import {roddeh_i18n as I18nInstance} from 'roddeh-i18n';

import {StoreState} from '../../../util/types';
import {
  formatNumber,
  stripFormatNumber,
  getOrgText,
  getVotingPeriodFormatted,
} from '../../../util/helpers';
import {BACKEND_URL} from '../../../util/config';
import {
  hasValue,
  isEmailValid,
  isEthAddressValid,
  isURLValid,
  isNumberString,
  isTwitterUsername,
} from '../../../util/validation';
import {FetchStatus} from '../../../util/enums';
import EmojiToggle from '../../../components/feedback/EmojiToggle';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import FadeIn from '../../../components/common/FadeIn';
import FieldReveal, {FieldList} from '../../../components/common/FieldReveal';
import InputError from '../../../components/common/InputError';
import Loader from '../../../components/feedback/Loader';
import Wrap from '../../../components/layout/Wrap';

import b from '../../../assets/scss/modules/buttons.module.scss';
import fs from '../../../assets/scss/modules/formsteps.module.scss';
import i from '../../../assets/scss/modules/input.module.scss';
import rb from '../../../assets/scss/modules/radiobutton.module.scss';

enum FieldNameEnum {
  addressToFund = 'addressToFund',
  articlesIncorporationURL = 'articlesIncorporationURL',
  contactEmail = 'contactEmail',
  contactName = 'contactName',
  contactTitle = 'contactTitle',
  description = 'description',
  documentURLs = 'documentURLs',
  existingInvestor = 'existingInvestor',
  fundingAmountRequestedUSD = 'fundingAmountRequestedUSD',
  fundingNeeds = 'fundingNeeds',
  incorporatedAddress = 'incorporatedAddress',
  incorporatedName = 'incorporatedName',
  incorporation = 'incorporation',
  name = 'name',
  private = 'private',
  projectURL = 'projectURL',
  socialDiscordURL = 'socialDiscordURL',
  socialTelegramURL = 'socialTelegramURL',
  socialTwitterUsername = 'socialTwitterUsername',
}

type Values = {
  addressToFund: string;
  articlesIncorporationURL: string;
  contactEmail: string;
  contactName: string;
  contactTitle: string;
  description: string;
  documentURLs: string;
  existingInvestor: string;
  fundingAmountRequestedUSD: string;
  fundingNeeds: string;
  incorporatedAddress: string;
  incorporatedName: string;
  incorporation: string;
  name: string;
  /**
   * Boolean string
   */
  private: string;
  projectURL: string;
  socialDiscordURL: string;
  socialTelegramURL: string;
  socialTwitterUsername: string;
};

type MappedStateProps = {
  backendURL: string | undefined;
  connectedEthAddress?: string;
  orgText: typeof I18nInstance | null;
  orgDocsURL: string | null;
  orgInternalName: string | null;
  periodDuration: number | undefined;
  votingPeriodLength: number | undefined;
};

type CreateInvestmentProposalLAOProps = MappedStateProps & RouteComponentProps;

type State = {
  errors: {
    [N in FieldNameEnum]: string;
  };
  fieldsTouched: Array<FieldNameEnum>;
  submitError: Error | undefined;
  submitStatus: FetchStatus;
  values: Values;
};

/**
 * displayTransformers
 *
 * Formats a value before setting it in state.
 */
const displayTransformers: Partial<
  Record<FieldNameEnum, (value: any) => string>
> = {
  fundingAmountRequestedUSD: (value: string) => formatNumber(value.trim()),
};

const requestDataTransformers: Partial<
  Record<FieldNameEnum & Values, (value: any, allValues: Values) => any>
> = {
  // convert to lowerCase
  addressToFund: (value: string): string => value.toLowerCase(),
  // convert string URLs to Array
  documentURLs: (value: string): Array<string> =>
    value.split(/\n|\s/).filter((u) => u),
  // Convert string of 'Yes' or 'No' to boolean
  existingInvestor: (value: string): boolean => value.toLowerCase() === 'yes',
  // Strip number of comma formatting
  fundingAmountRequestedUSD: (value: string) =>
    Number(stripFormatNumber(value)),
  // Convert string of 'Yes' or 'No' to boolean
  incorporation: (value: string): boolean => value.toLowerCase() === 'yes',
  // Convert string of 'true' or 'false' to boolean
  private: (value: string): boolean => value.toLowerCase() === 'true',
  // If user typed "@", remove it.
  socialTwitterUsername: (value: string): string =>
    /^@/.test(value) ? value.slice(1).trim() : value.trim(),
};

const ERROR_EMAIL_INVALID = 'The email address is invalid.';
const ERROR_ETH_ADDRESS_INVALID = 'The ETH address is invalid.';
const ERROR_NUMBER_STRING_INVALID = 'Value should be a number.';
const ERROR_NUMBER_TOO_LOW = 'The amount should be at least 1.';
const ERROR_REQUIRED_FIELD = 'Members need this information.';
const ERROR_TWITTER_USERNAME = 'The Twitter username is not valid.';
const ERROR_URL_INVALID = 'The URL is invalid.';
const ERROR_URLS_INVALID = 'A URL is invalid.';

const handleValidateEmail = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const possibleHasValueError = hasValue(value) ? '' : ERROR_REQUIRED_FIELD;
  const possibleIsURLValidError = isEmailValid(value)
    ? ''
    : ERROR_EMAIL_INVALID;

  return {
    errorMessage: possibleHasValueError || possibleIsURLValidError,
    isError: (possibleHasValueError || possibleIsURLValidError).length > 0,
  };
};

const handleValidateEthAddress = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const possibleHasValueError = hasValue(value) ? '' : ERROR_REQUIRED_FIELD;
  const possibleEthAddressError = isEthAddressValid(value)
    ? ''
    : ERROR_ETH_ADDRESS_INVALID;

  return {
    errorMessage: possibleHasValueError || possibleEthAddressError,
    isError: (possibleHasValueError || possibleEthAddressError).length > 0,
  };
};

const handleValidateExists = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const possibleHasValueError = hasValue(value) ? '' : ERROR_REQUIRED_FIELD;

  return {
    errorMessage: possibleHasValueError,
    isError: possibleHasValueError.length > 0,
  };
};

const handleValidateFundingAmount = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const valueCleaned = stripFormatNumber(value.trim());
  const possibleNonNumberError = isNumberString(valueCleaned)
    ? ''
    : ERROR_NUMBER_STRING_INVALID;
  const possibleLowValueError =
    Number(valueCleaned) > 0 ? '' : ERROR_NUMBER_TOO_LOW;

  return {
    errorMessage: possibleNonNumberError || possibleLowValueError,
    isError: (possibleNonNumberError || possibleLowValueError).length > 0,
  };
};

const handleValidateTwitterUsername = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const possibleError =
    value === '' ? '' : isTwitterUsername(value) ? '' : ERROR_TWITTER_USERNAME;

  return {
    errorMessage: possibleError,
    isError: possibleError.length > 0,
  };
};

const handleValidateURL = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const possibleIsURLValidError =
    value === '' ? '' : isURLValid(value) ? '' : ERROR_URL_INVALID;

  return {
    errorMessage: possibleIsURLValidError,
    isError: possibleIsURLValidError.length > 0,
  };
};

const handleValidateURLMultiple = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const URLValues = value.split(/\n|\s/);
  const possibleHasValueError = hasValue(value) ? '' : ERROR_REQUIRED_FIELD;
  const validationResult = URLValues.filter((r) => r).every(isURLValid);
  const possibleIsURLValidError = validationResult ? '' : ERROR_URLS_INVALID;

  return {
    errorMessage: possibleHasValueError || possibleIsURLValidError,
    isError: (possibleHasValueError || possibleIsURLValidError).length > 0,
  };
};

/**
 * CreateInvestmentProposalLAO
 *
 * Submits an entry to the snapshot proposals table.
 * This data is then used to create an actual Snapshot Hub proposal
 * (this is the equivalent of sponsoring in Moloch).
 *
 * @todo Migrate to hooks like our other forms
 * @todo We should find a way to use form generation, instead of making a form for each DAO.
 * @todo If not made to a dynamic form, then at least migrate to use BaseSnapshotProposalForm.
 * @todo If not made to a dynamic form, then at least do not use progressive field reveal.
 *
 * @returns {JSX.Element}
 */
class CreateInvestmentProposalLAO extends React.Component<
  CreateInvestmentProposalLAOProps,
  State
> {
  fieldsDisplayed: {[N in FieldNameEnum]?: boolean} = {};
  proposalCorporateDocsRef: React.RefObject<HTMLInputElement> =
    React.createRef();
  nameRef: React.RefObject<HTMLInputElement> = React.createRef();

  /**
   * fieldList
   *
   * All fields in the form need to be registered inside the field list.
   *
   * @note Fields should be listed in the order they appear due to the "progressive reveal" of the fields.
   */
  fieldList: FieldList<FieldNameEnum> = [
    {
      fieldName: FieldNameEnum.name,
    },
    {
      fieldName: FieldNameEnum.projectURL,
    },
    {
      fieldName: FieldNameEnum.contactName,
    },
    {
      fieldName: FieldNameEnum.contactEmail,
    },
    {
      fieldName: FieldNameEnum.contactTitle,
    },
    {
      fieldName: FieldNameEnum.description,
    },
    {
      fieldName: FieldNameEnum.documentURLs,
    },
    {
      fieldName: FieldNameEnum.fundingAmountRequestedUSD,
    },
    {
      fieldName: FieldNameEnum.fundingNeeds,
    },
    // @note Comment out until we decide how to use Project incorporation info
    /* {
      fieldName: FieldNameEnum.incorporation
    },
    {
      fieldName: FieldNameEnum.incorporatedName,
      conditions: [(v: Record<string, any>) => v.incorporation === 'Yes']
    },
    {
      fieldName: FieldNameEnum.incorporatedAddress,
      conditions: [(v: Record<string, any>) => v.incorporation === 'Yes']
    },
    {
      fieldName: FieldNameEnum.articlesIncorporationURL,
      conditions: [(v: Record<string, any>) => v.incorporation === 'Yes']
    }, */
    {
      fieldName: FieldNameEnum.existingInvestor,
    },
    {
      fieldName: FieldNameEnum.socialDiscordURL,
      shouldIgnoreEmpty: () => true,
    },
    {
      fieldName: FieldNameEnum.socialTwitterUsername,
      shouldIgnoreEmpty: () => true,
    },
    {
      fieldName: FieldNameEnum.socialTelegramURL,
      shouldIgnoreEmpty: () => true,
    },
    {
      fieldName: FieldNameEnum.addressToFund,
    },
    {
      fieldName: FieldNameEnum.private,
    },
  ];

  state: State = {
    errors: {
      addressToFund: '',
      articlesIncorporationURL: '',
      contactEmail: '',
      contactName: '',
      contactTitle: '',
      description: '',
      documentURLs: '',
      existingInvestor: '',
      fundingAmountRequestedUSD: '',
      fundingNeeds: '',
      incorporatedAddress: '',
      incorporatedName: '',
      incorporation: '',
      name: '',
      private: '',
      projectURL: '',
      socialDiscordURL: '',
      socialTelegramURL: '',
      socialTwitterUsername: '',
    },
    submitError: undefined,
    fieldsTouched: [],
    submitStatus: FetchStatus.STANDBY,
    values: {
      addressToFund: this.props.connectedEthAddress || '',
      articlesIncorporationURL: '',
      contactEmail: '',
      contactName: '',
      contactTitle: '',
      description: '',
      documentURLs: '',
      existingInvestor: '',
      fundingAmountRequestedUSD: '',
      fundingNeeds: '',
      incorporatedAddress: '',
      incorporatedName: '',
      incorporation: '',
      name: '',
      private: 'false',
      projectURL: '',
      socialDiscordURL: '',
      socialTelegramURL: '',
      socialTwitterUsername: '',
    },
  };

  componentDidMount() {
    // Focus first input
    setTimeout(
      () => this.nameRef.current && this.nameRef.current.focus(),
      1000
    );
  }

  componentDidUpdate(prevProps: CreateInvestmentProposalLAOProps) {
    if (
      this.props.connectedEthAddress &&
      this.props.connectedEthAddress !== prevProps.connectedEthAddress
    ) {
      this.setState({
        values: {
          ...this.state.values,
          addressToFund: this.props.connectedEthAddress,
        },
      });
    }
  }

  getTransformedValues = () => {
    const {values} = this.state;
    const maybeTransformedValues = {...values} as {
      [key: string]: any;
    };
    const transformers = requestDataTransformers as {
      [key: string]: (v: any, values: Values) => any;
    };

    for (const k in maybeTransformedValues) {
      if (transformers[k]) {
        maybeTransformedValues[k] = transformers[k](
          maybeTransformedValues[k],
          values
        );
      }
    }

    return maybeTransformedValues;
  };

  handleAddressError = (error: string) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [FieldNameEnum.incorporatedAddress]: error,
      },
    });
  };

  handleChange =
    (
      fieldName: FieldNameEnum,
      validator: (value: string) => {errorMessage: string; isError: boolean}
    ) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {value} = event.currentTarget;
      const {errors, values} = this.state;
      const {errorMessage = ''} = validator(value);
      // Possibly transform the value for display
      const maybeDisplayTransformer = displayTransformers[fieldName]
        ? displayTransformers[fieldName]
        : undefined;
      const maybeTransformedValue = maybeDisplayTransformer
        ? maybeDisplayTransformer(value)
        : value;

      const fieldValuesObject = {
        values: {...values, [fieldName]: maybeTransformedValue},
      };

      event.persist();

      this.setState({
        ...fieldValuesObject,

        errors: {
          ...errors,

          [fieldName]: errorMessage,
        },
      });
    };

  handleChangeAddress =
    (
      fieldName: FieldNameEnum,
      validator: (value: string) => {errorMessage: string; isError: boolean}
    ) =>
    (value: string | React.ChangeEvent<HTMLInputElement>) => {
      const {errors, values} = this.state;
      const {errorMessage = ''} = validator(value as string);
      const fieldValuesObject = {values: {...values, [fieldName]: value}};

      this.setState({
        ...fieldValuesObject,

        errors: {
          ...errors,

          [fieldName]: errorMessage,
        },
      });
    };

  handleChangePrivacy = (fieldName: FieldNameEnum) => (value: boolean) => {
    const {values} = this.state;
    const fieldValuesObject = {
      /**
       * Coerce to string so the FieldReveal will render
       * We can change this to a boolean in the request data transformers.
       */
      values: {...values, [fieldName]: value.toString()},
    };

    this.setState({
      ...fieldValuesObject,
    });
  };

  handleFieldTouched = (fieldName: FieldNameEnum) => {
    this.setState({
      fieldsTouched: [...this.state.fieldsTouched, fieldName],
    });
  };

  handleSubmitProposal = async () => {
    const {values} = this.state;

    const maybeTransformedValues = {...values} as {
      [key: string]: any;
    };
    const transformers = requestDataTransformers as {
      [key: string]: (v: any, values: Values) => any;
    };

    for (const k in maybeTransformedValues) {
      if (transformers[k]) {
        maybeTransformedValues[k] = transformers[k](
          maybeTransformedValues[k],
          values
        );
      }
    }

    /**
     * Shape submission data
     *
     * @todo Not the most typesafe b/c of the transformed values possibly change the types,
     *   but doing our best - can figure out something later.
     */
    const {
      name,
      description,
      fundingAmountRequestedUSD,
      private: privacy,
      addressToFund,
      ...rest
    } = maybeTransformedValues as Values;

    const submissionData = {
      // Core submit values
      name,
      description,
      amountRequested: fundingAmountRequestedUSD,
      amountRequestedCurrency: 'USD',
      private: privacy,
      addressToFund,
      userEmailAddress: rest.contactEmail,
      userEthereumAddress: addressToFund,
      // Extra information
      metadata: {...rest},
    };

    // Submit to server
    try {
      // Maybe clear submitError
      this.state.submitError && this.setState({submitError: undefined});

      this.setState({submitStatus: FetchStatus.PENDING});

      const response = await fetch(
        `${this.props.backendURL}/proposals-investment`,
        {
          method: 'POST',
          body: JSON.stringify(submissionData),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        const {message} = await response.json();

        throw new Error(
          `${message || response.statusText}
            Error status: ${response.status}.`
        );
      }

      this.setState({submitStatus: FetchStatus.FULFILLED});

      const {uuid} = await response.json();

      // Navigate to proposal details view with state for any post-submission actions
      this.props.history.push(`/proposals/${uuid}`, {
        isNavigatingPostSubmission: true,
      });
    } catch (error) {
      this.setState({submitError: error, submitStatus: FetchStatus.REJECTED});
    }
  };

  maybeShowFieldError = (
    fieldName: FieldNameEnum,
    showBeforeTouched: boolean = false
  ): React.ReactNode => {
    const error = this.state.errors[fieldName];
    const touched = this.state.fieldsTouched.indexOf(fieldName) >= 0;

    if ((error && touched) || (error && showBeforeTouched)) {
      return (
        <FadeIn>
          <InputError className={fs['error']} error={error} />
        </FadeIn>
      );
    }

    return null;
  };

  render() {
    const {submitStatus, submitError} = this.state;
    const isSubmitting = submitStatus === FetchStatus.PENDING;
    const isSubmitted = submitStatus === FetchStatus.FULFILLED;

    const getText = getOrgText(this.props.orgText);
    const orgName = getText('OrgName');
    const orgApplyTitle = getText('OrgApplyTitle');
    const orgFundingAmountText = getText('OrgFundingAmountText');
    const orgSubmitProposalButtonText = getText('OrgSubmitProposalButtonText');
    const orgFundingAmountFieldPlaceholder = getText(
      'OrgFundingAmountFieldPlaceholder'
    );
    const {formatted: votingPeriodFormatted} = getVotingPeriodFormatted({
      votingPeriodLength: this.props.votingPeriodLength || 0,
      periodDuration: this.props.periodDuration || 0,
      pluralize: false,
    });

    // intro text depends on org; defaults to "The LAO" base text
    const introText = () => {
      return (
        <>
          <p style={{marginTop: 0}}>
            Apply for funding from {orgName}. Members provide{' '}
            {orgFundingAmountText} in support for projects (if not more).
            Funding is provided in Ether. If a member is interested in funding
            your project, they can put it up for a vote and, if more members
            vote to support the project than no, during a{' '}
            {votingPeriodFormatted} voting period, the investment will be
            committed. The process is a breeze and we will work with you to
            structure the investment and close the deal in a snap.
          </p>
          <p>
            To receive funding, {orgName} members will request that projects
            create a corporation in the U.S. Check out our{' '}
            <a
              href={this.props.orgDocsURL || ''}
              rel="noopener noreferrer"
              target="_blank">
              FAQs
            </a>
            .
          </p>
        </>
      );
    };

    return (
      <Wrap className={'section-wrapper'}>
        <FadeIn>
          <div className="titlebar">
            <h2 className="titlebar__title org-titlebar__title">
              {orgApplyTitle}
            </h2>
          </div>

          <div className="org-form-wrap">
            <div className={`${fs['content-wrap']}`}>
              <div
                className={`${fs['form-description']} org-form-description`}
                style={{paddingTop: 0}}>
                {introText()}
              </div>

              <FieldReveal
                fieldList={this.fieldList}
                fieldErrors={this.state.errors}
                fieldValues={this.state.values}
                render={({canRender, handleScrollToMiddle, isSubmitReady}) => (
                  <div className={`${fs['input-rows-wrap']}`}>
                    {/* PROJECT NAME */}
                    {canRender[FieldNameEnum.name] && (
                      <FadeIn>
                        <div className={`${fs['input-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Project Name
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <input
                              name={FieldNameEnum.name}
                              onBlur={() =>
                                this.handleFieldTouched(FieldNameEnum.name)
                              }
                              onChange={this.handleChange(
                                FieldNameEnum.name,
                                handleValidateExists
                              )}
                              onFocus={handleScrollToMiddle}
                              placeholder="What's your project called?"
                              ref={this.nameRef}
                              type="text"
                              value={this.state.values.name}
                            />
                            {this.maybeShowFieldError(FieldNameEnum.name)}
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* PROJECT URL */}
                    {canRender[FieldNameEnum.projectURL] && (
                      <FadeIn>
                        <div className={`${fs['input-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Project Website
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <input
                              name={FieldNameEnum.projectURL}
                              onBlur={() =>
                                this.handleFieldTouched(
                                  FieldNameEnum.projectURL
                                )
                              }
                              onChange={this.handleChange(
                                FieldNameEnum.projectURL,
                                handleValidateURL
                              )}
                              onFocus={handleScrollToMiddle}
                              placeholder="Where can members learn more?"
                              type="text"
                              value={this.state.values.projectURL}
                            />
                            {this.maybeShowFieldError(FieldNameEnum.projectURL)}
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* CONTACT NAME */}
                    {canRender[FieldNameEnum.contactName] && (
                      <FadeIn>
                        <div className={`${fs['input-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Name of Project Contact
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <input
                              name={FieldNameEnum.contactName}
                              onBlur={() =>
                                this.handleFieldTouched(
                                  FieldNameEnum.contactName
                                )
                              }
                              onChange={this.handleChange(
                                FieldNameEnum.contactName,
                                handleValidateExists
                              )}
                              onFocus={handleScrollToMiddle}
                              placeholder="What's your Name?"
                              type="text"
                              value={this.state.values.contactName}
                            />
                            {this.maybeShowFieldError(
                              FieldNameEnum.contactName
                            )}
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* CONTACT EMAIL */}
                    {canRender[FieldNameEnum.contactEmail] && (
                      <FadeIn>
                        <div className={`${fs['input-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Email for Contact
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <input
                              name={FieldNameEnum.contactEmail}
                              onBlur={() =>
                                this.handleFieldTouched(
                                  FieldNameEnum.contactEmail
                                )
                              }
                              onChange={this.handleChange(
                                FieldNameEnum.contactEmail,
                                handleValidateEmail
                              )}
                              onFocus={handleScrollToMiddle}
                              placeholder="What's an email address where we can reach you?"
                              type="text"
                              value={this.state.values.contactEmail}
                            />
                            {this.maybeShowFieldError(
                              FieldNameEnum.contactEmail
                            )}
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* CONTACT TITLE */}
                    {canRender[FieldNameEnum.contactTitle] && (
                      <FadeIn>
                        <div className={`${fs['input-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Title of Contact
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <input
                              name={FieldNameEnum.contactTitle}
                              onBlur={() =>
                                this.handleFieldTouched(
                                  FieldNameEnum.contactTitle
                                )
                              }
                              onChange={this.handleChange(
                                FieldNameEnum.contactTitle,
                                handleValidateExists
                              )}
                              onFocus={handleScrollToMiddle}
                              placeholder="e.g., CEO"
                              type="text"
                              value={this.state.values.contactTitle}
                            />
                            {this.maybeShowFieldError(
                              FieldNameEnum.contactTitle
                            )}
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* DESCRIPTION */}
                    {canRender[FieldNameEnum.description] && (
                      <FadeIn>
                        <div className={`${fs['textarea-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Description
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <textarea
                              name={FieldNameEnum.description}
                              onBlur={() =>
                                this.handleFieldTouched(
                                  FieldNameEnum.description
                                )
                              }
                              onChange={this.handleChange(
                                FieldNameEnum.description,
                                handleValidateExists
                              )}
                              onFocus={handleScrollToMiddle}
                              placeholder="Tell us about your project.  What's your best elevator pitch?"
                              value={this.state.values.description}
                            />
                            {this.maybeShowFieldError(
                              FieldNameEnum.description
                            )}

                            {/* Markdown Preview */}
                            {this.state.values.description && (
                              <details>
                                <summary
                                  className={`color-keylargo ${fs['snapshot-preview']} org-snapshot-preview`}
                                  style={{cursor: 'pointer', outline: 'none'}}>
                                  <small>Preview Markdown</small>
                                </summary>

                                <div style={{marginTop: '1em'}}>
                                  <Markdown>
                                    {this.state.values.description}
                                  </Markdown>
                                </div>
                              </details>
                            )}
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* PROJECT/SUPPORTING DOCUMENT URLs */}
                    {canRender[FieldNameEnum.documentURLs] && (
                      <FadeIn>
                        <div className={`${fs['textarea-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Pitch Deck, Whitepaper, or Other Information
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <textarea
                              name={FieldNameEnum.documentURLs}
                              onBlur={() =>
                                this.handleFieldTouched(
                                  FieldNameEnum.documentURLs
                                )
                              }
                              onChange={this.handleChange(
                                FieldNameEnum.documentURLs,
                                handleValidateURLMultiple
                              )}
                              onFocus={(event) => handleScrollToMiddle(event)}
                              placeholder={
                                'For our members to decide, we need more than just a description.  Please provide a link to your pitch deck, whitepaper, or any other information that you think would be relevant.\n\n' +
                                '(Please separate URLs with a new line, or a space.)'
                              }
                              value={this.state.values.documentURLs}
                            />

                            {this.maybeShowFieldError(
                              FieldNameEnum.documentURLs
                            )}
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* FUNDING AMOUNT */}
                    {canRender[FieldNameEnum.fundingAmountRequestedUSD] && (
                      <FadeIn>
                        <div className={`${fs['input-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Requested Funding Amount
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <div className={i['input-prefix__wrap']}>
                              <input
                                className={`${i['input-prefix--sm']}`}
                                name={FieldNameEnum.fundingAmountRequestedUSD}
                                onBlur={() =>
                                  this.handleFieldTouched(
                                    FieldNameEnum.fundingAmountRequestedUSD
                                  )
                                }
                                onChange={this.handleChange(
                                  FieldNameEnum.fundingAmountRequestedUSD,
                                  handleValidateFundingAmount
                                )}
                                onFocus={handleScrollToMiddle}
                                placeholder={orgFundingAmountFieldPlaceholder}
                                type="text"
                                // Formats number string to have commas for display.
                                value={
                                  this.state.values.fundingAmountRequestedUSD
                                }
                              />
                              <div
                                className={`${i['input-prefix__item--sm']} org-input-prefix__item--sm`}>
                                $
                              </div>
                            </div>
                            {this.maybeShowFieldError(
                              FieldNameEnum.fundingAmountRequestedUSD
                            )}

                            <p
                              className={`${fs['input-row-help']} org-input-row-help`}>
                              This will be considered in combination with your
                              company value, which {orgName}&rsquo;s members
                              will discuss with you.
                            </p>

                            <p
                              className={`${fs['input-row-help']} org-input-row-help org-input-row-help--alert color-sunny`}>
                              This amount cannot change once a member submits
                              and sponsors your proposal to the smart contract.
                            </p>
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* FUNDING NEEDS */}
                    {canRender[FieldNameEnum.fundingNeeds] && (
                      <FadeIn>
                        <div className={`${fs['textarea-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Use of Funds
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <textarea
                              name={FieldNameEnum.fundingNeeds}
                              onBlur={() =>
                                this.handleFieldTouched(
                                  FieldNameEnum.fundingNeeds
                                )
                              }
                              onChange={this.handleChange(
                                FieldNameEnum.fundingNeeds,
                                handleValidateExists
                              )}
                              onFocus={(event) => handleScrollToMiddle(event)}
                              placeholder="What will you use the funding for?"
                              value={this.state.values.fundingNeeds}
                            />

                            {this.maybeShowFieldError(
                              FieldNameEnum.fundingNeeds
                            )}

                            {/* Markdown Preview */}
                            {this.state.values.fundingNeeds && (
                              <details>
                                <summary
                                  className={`color-keylargo ${fs['snapshot-preview']} org-snapshot-preview`}
                                  style={{cursor: 'pointer', outline: 'none'}}>
                                  <small>Preview Markdown</small>
                                </summary>

                                <div style={{marginTop: '1em'}}>
                                  <Markdown>
                                    {this.state.values.fundingNeeds}
                                  </Markdown>
                                </div>
                              </details>
                            )}
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* INCORPORATION YES/NO */}
                    {/* @note Comment out until we decide how to use Project incorporation info */}
                    {/* {canRender[FieldNameEnum.incorporation] && (
                    <FadeIn>
                      <div className={`${fs['input-row']}`}>
                        <label className={`${fs['input-row-label']} org-input-row-label`}>
                          Legal Status
                        </label>

                        <div className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                          <p>
                            Have you already incorporated your business or
                            project?
                          </p>

                          <input
                            checked={
                              this.state.values[FieldNameEnum.incorporation] ===
                              'Yes'
                            }
                            className={`${rb['radio-yesno'] org-radio-yesno}`}
                            id={`${FieldNameEnum.incorporation}Yes`}
                            name={FieldNameEnum.incorporation}
                            onBlur={() =>
                              this.handleFieldTouched(
                                FieldNameEnum.incorporation
                              )
                            }
                            onChange={this.handleChange(
                              FieldNameEnum.incorporation,
                              handleValidateExists
                            )}
                            onFocus={handleScrollToMiddle}
                            type="radio"
                            value="Yes"
                          />
                          <label htmlFor={`${FieldNameEnum.incorporation}Yes`}>
                            Yes
                          </label>

                          <input
                            className={`${rb['radio-yesno'] org-radio-yesno}`}
                            checked={
                              this.state.values[FieldNameEnum.incorporation] ===
                              'No'
                            }
                            id={`${FieldNameEnum.incorporation}No`}
                            name={FieldNameEnum.incorporation}
                            onBlur={() =>
                              this.handleFieldTouched(
                                FieldNameEnum.incorporation
                              )
                            }
                            onChange={this.handleChange(
                              FieldNameEnum.incorporation,
                              handleValidateExists
                            )}
                            onFocus={handleScrollToMiddle}
                            type="radio"
                            value="No"
                          />
                          <label htmlFor={`${FieldNameEnum.incorporation}No`}>
                            No
                          </label>

                          {this.state.values.incorporation === 'No' && (
                            <p className={`${fs['input-row-help']} org-input-row-help`}>
                              We&rsquo;ll ask for this information, later. For
                              now, projects need to be incorporated to receive
                              funding.
                            </p>
                          )}
                        </div>
                      </div>
                    </FadeIn>
                  )} */}

                    {/* INCORPORATED NAME */}
                    {/* @note Comment out until we decide how to use Project incorporation info */}
                    {/* {canRender[FieldNameEnum.incorporatedName] && (
                    <FadeIn>
                      <div className={`${fs['input-row']}`}>
                        <label className={`${fs['input-row-label']} org-input-row-label`}>
                          Company&rsquo;s Legal Name
                        </label>
                        <div className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                          <input
                            name={FieldNameEnum.incorporatedName}
                            onBlur={() =>
                              this.handleFieldTouched(
                                FieldNameEnum.incorporatedName
                              )
                            }
                            onChange={this.handleChange(
                              FieldNameEnum.incorporatedName,
                              handleValidateExists
                            )}
                            onFocus={handleScrollToMiddle}
                            placeholder="DarkCrystal, Inc."
                            type="text"
                            value={this.state.values.incorporatedName}
                          />
                          {this.maybeShowFieldError(
                            FieldNameEnum.incorporatedName
                          )}
                        </div>
                      </div>
                    </FadeIn>
                  )} */}

                    {/* INCORPORATED ADDRESS */}
                    {/* @note Comment out until we decide how to use Project incorporation info */}
                    {/* {canRender[FieldNameEnum.incorporatedAddress] && (
                    <FadeIn>
                      <div className={`${fs['input-row']}`}>
                        <label className={`${fs['input-row-label']} org-input-row-label`}>
                          Company Address
                        </label>
                        <div className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                          <Address
                            name={FieldNameEnum.incorporatedAddress}
                            onAddressError={this.handleAddressError}
                            onBlur={() =>
                              this.handleFieldTouched(
                                FieldNameEnum.incorporatedAddress
                              )
                            }
                            onChange={this.handleChangeAddress(
                              FieldNameEnum.incorporatedAddress,
                              handleValidateExists
                            )}
                            style={{backgroundColor: 'black'}}
                            value={this.state.values.incorporatedAddress}
                          />
                          {this.maybeShowFieldError(
                            FieldNameEnum.incorporatedAddress,
                            true
                          )}
                        </div>
                      </div>
                    </FadeIn>
                  )} */}

                    {/* ARTICLES OF INCORPORATION URL */}
                    {/* @note Comment out until we decide how to use Project incorporation info */}
                    {/* {canRender[FieldNameEnum.articlesIncorporationURL] && (
                    <FadeIn>
                      <div className={`${fs['input-row']}`}>
                        <label className={`${fs['input-row-label']} org-input-row-label`}>
                          Link to
                        </label>
                        <div className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                          <input
                            name={FieldNameEnum.articlesIncorporationURL}
                            onBlur={() =>
                              this.handleFieldTouched(
                                FieldNameEnum.articlesIncorporationURL
                              )
                            }
                            onChange={this.handleChange(
                              FieldNameEnum.articlesIncorporationURL,
                              handleValidateURL
                            )}
                            onFocus={handleScrollToMiddle}
                            placeholder="Articles of Incorporation (e.g. a public Google Drive link)"
                            type="text"
                            value={this.state.values.articlesIncorporationURL}
                          />
                          {this.maybeShowFieldError(
                            FieldNameEnum.articlesIncorporationURL
                          )}
                        </div>
                      </div>
                    </FadeIn>
                  )} */}

                    {/* EXISTING INVESTORS YES/NO */}
                    {canRender[FieldNameEnum.existingInvestor] && (
                      <FadeIn>
                        <div className={`${fs['input-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Investment Status
                          </label>

                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <p className="org-input-row-text">
                              Does your project have any investors already?
                            </p>

                            <input
                              checked={
                                this.state.values[
                                  FieldNameEnum.existingInvestor
                                ] === 'Yes'
                              }
                              className={`${rb['radio-yesno']} org-radio-yesno`}
                              id={`${FieldNameEnum.existingInvestor}Yes`}
                              name={FieldNameEnum.existingInvestor}
                              onBlur={() =>
                                this.handleFieldTouched(
                                  FieldNameEnum.existingInvestor
                                )
                              }
                              onChange={this.handleChange(
                                FieldNameEnum.existingInvestor,
                                handleValidateExists
                              )}
                              onFocus={handleScrollToMiddle}
                              type="radio"
                              value="Yes"
                            />
                            <label
                              htmlFor={`${FieldNameEnum.existingInvestor}Yes`}>
                              Yes
                            </label>

                            <input
                              className={`${rb['radio-yesno']} org-radio-yesno`}
                              checked={
                                this.state.values[
                                  FieldNameEnum.existingInvestor
                                ] === 'No'
                              }
                              id={`${FieldNameEnum.existingInvestor}No`}
                              name={FieldNameEnum.existingInvestor}
                              onBlur={() =>
                                this.handleFieldTouched(
                                  FieldNameEnum.existingInvestor
                                )
                              }
                              onChange={this.handleChange(
                                FieldNameEnum.existingInvestor,
                                handleValidateExists
                              )}
                              onFocus={handleScrollToMiddle}
                              type="radio"
                              value="No"
                            />
                            <label
                              htmlFor={`${FieldNameEnum.existingInvestor}No`}>
                              No
                            </label>
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* SOCIAL: DISCORD */}
                    {canRender[FieldNameEnum.socialDiscordURL] && (
                      <FadeIn>
                        <div className={`${fs['input-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Discord URL
                            <br />
                            <small>(optional)</small>
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <input
                              name={FieldNameEnum.socialDiscordURL}
                              onBlur={() =>
                                this.handleFieldTouched(
                                  FieldNameEnum.socialDiscordURL
                                )
                              }
                              onChange={this.handleChange(
                                FieldNameEnum.socialDiscordURL,
                                handleValidateURL
                              )}
                              onFocus={handleScrollToMiddle}
                              placeholder="e.g. https://discord.gg/..."
                              type="text"
                              value={this.state.values.socialDiscordURL}
                            />

                            {this.maybeShowFieldError(
                              FieldNameEnum.socialDiscordURL
                            )}
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* SOCIAL: TWITTER */}
                    {canRender[FieldNameEnum.socialTwitterUsername] && (
                      <FadeIn>
                        <div className={`${fs['input-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Twitter handle
                            <br />
                            <small>(optional)</small>
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <div className={i['input-prefix__wrap']}>
                              <input
                                className={`${i['input-prefix--sm']}`}
                                name={FieldNameEnum.socialTwitterUsername}
                                onBlur={() =>
                                  this.handleFieldTouched(
                                    FieldNameEnum.socialTwitterUsername
                                  )
                                }
                                onChange={this.handleChange(
                                  FieldNameEnum.socialTwitterUsername,
                                  handleValidateTwitterUsername
                                )}
                                onFocus={handleScrollToMiddle}
                                type="text"
                                value={this.state.values.socialTwitterUsername}
                              />

                              <div
                                className={`${i['input-prefix__item--sm']} org-input-prefix__item--sm`}>
                                @
                              </div>
                            </div>

                            {this.maybeShowFieldError(
                              FieldNameEnum.socialTwitterUsername
                            )}
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* SOCIAL: TELEGRAM */}
                    {canRender[FieldNameEnum.socialTelegramURL] && (
                      <FadeIn>
                        <div className={`${fs['input-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Telegram URL
                            <br />
                            <small>(optional)</small>
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <input
                              name={FieldNameEnum.socialTelegramURL}
                              onBlur={() =>
                                this.handleFieldTouched(
                                  FieldNameEnum.socialTelegramURL
                                )
                              }
                              onChange={this.handleChange(
                                FieldNameEnum.socialTelegramURL,
                                handleValidateURL
                              )}
                              onFocus={handleScrollToMiddle}
                              placeholder="e.g. https://t.me/..."
                              type="text"
                              value={this.state.values.socialTelegramURL}
                            />

                            {this.maybeShowFieldError(
                              FieldNameEnum.socialTelegramURL
                            )}
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* ETH ADDRESS FOR RECEIVING PROJECT FUNDS */}
                    {canRender[FieldNameEnum.addressToFund] && (
                      <FadeIn>
                        <div className={`${fs['input-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Address to Fund
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <input
                              name={FieldNameEnum.addressToFund}
                              onBlur={() =>
                                this.handleFieldTouched(
                                  FieldNameEnum.addressToFund
                                )
                              }
                              onChange={this.handleChange(
                                FieldNameEnum.addressToFund,
                                handleValidateEthAddress
                              )}
                              onFocus={handleScrollToMiddle}
                              placeholder="ETH Address for project funding"
                              type="text"
                              value={this.state.values.addressToFund}
                            />

                            {this.maybeShowFieldError(
                              FieldNameEnum.addressToFund
                            )}

                            <p
                              className={`${fs['input-row-help']} org-input-row-help org-input-row-help--alert color-sunny`}>
                              If your proposal is accepted for funding, this is
                              your address which you will use to withdraw /
                              receive funds from {orgName}. If a member chooses
                              to submit this proposal as a Snapshot proposal
                              then this address is where the side-pocket will
                              send the funds.
                            </p>

                            <p
                              className={`${fs['input-row-help']} org-input-row-help org-input-row-help--alert color-sunny`}>
                              If this proposal is submitted by a member as a
                              Moloch proposal the address above cannot change.
                            </p>
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {canRender[FieldNameEnum.private] && (
                      <FadeIn>
                        <div className={`${fs['input-row']}`}>
                          <label
                            className={`${fs['input-row-label']} org-input-row-label`}>
                            Proposal Privacy
                          </label>
                          <div
                            className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                            <EmojiToggle
                              emojis={['😃', '🤫']}
                              isChecked={false}
                              onToggle={this.handleChangePrivacy(
                                FieldNameEnum.private
                              )}
                              text={['Public', 'Private']}
                            />

                            <p
                              className={`${fs['input-row-help']} org-input-row-help`}>
                              This will hide your proposal from view, except
                              for:
                              <br /> members and the "Address to Fund" above.
                            </p>
                          </div>
                        </div>
                      </FadeIn>
                    )}

                    {/* SUBMIT */}

                    {isSubmitReady && (
                      <FadeIn>
                        <button
                          className={`${b.primary} org-primary-button`}
                          onClick={
                            isSubmitting || isSubmitted
                              ? () => {}
                              : this.handleSubmitProposal
                          }
                          type="submit"
                          disabled={isSubmitting || isSubmitted}>
                          {isSubmitted ? (
                            <Loader text="Submitted!" />
                          ) : isSubmitting ? (
                            <Loader text="Submitting&hellip;" />
                          ) : (
                            orgSubmitProposalButtonText
                          )}
                        </button>

                        {submitError && (
                          <div className="text-center">
                            <ErrorMessageWithDetails
                              error={submitError}
                              renderText="Something went wrong while submitting your proposal."
                            />
                          </div>
                        )}
                      </FadeIn>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        </FadeIn>
      </Wrap>
    );
  }
}

const mapStateToProps = (state: StoreState): MappedStateProps => ({
  backendURL: state.org ? `${BACKEND_URL}/${state.org.id}` : undefined,
  connectedEthAddress: state.blockchain.connectedAddress,
  orgText: state.org && state.org.text,
  orgDocsURL: state.org && state.org.docsURL,
  orgInternalName: state.org && state.org.internalName,
  periodDuration:
    state.blockchain.molochConstants &&
    state.blockchain.molochConstants.periodDuration,
  votingPeriodLength:
    state.blockchain.molochConstants &&
    state.blockchain.molochConstants.votingPeriodLength,
});

export default withRouter<RouteComponentProps, any>(
  connect(mapStateToProps, null)(CreateInvestmentProposalLAO)
);
