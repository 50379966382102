import React, {useState, useCallback, useEffect} from 'react';
import {useQuery} from '@apollo/react-hooks';
import Web3 from 'web3';

import {FetchStatus, Web3TxStatus} from '../../util/enums';
import {GET_MEMBER_DETAILS} from '../../gql';
import {GQL_QUERY_POLLING_INTERVAL, ETHERSCAN_URLS} from '../../util/config';
import {StoreState, MetaMaskRPCError} from '../../util/types';
import {useApprovedTokens, useERC20Balances} from '../../hooks';
import {useSelector} from 'react-redux';
import ErrorMessageWithDetails from '../../components/common/ErrorMessageWithDetails';
import Loader from '../../components/feedback/Loader';
import Withdraw from '../../components/contract/Withdraw';
import Wrap from '../../components/layout/Wrap';

import b from '../../assets/scss/modules/buttons.module.scss';
import s from '../../assets/scss/modules/withdrawbalances.module.scss';

type UserTokenBalances = {
  tokenAddress: string;
  tokenBalance: string;
};

export default function WithdrawBalances() {
  const [restartedPolling, setRestartedPolling] = useState<boolean>(false);
  const [tokenBalances, setTokenBalances] = useState<Array<UserTokenBalances>>(
    []
  );

  /**
   * Selectors
   */

  const orgName = useSelector((s: StoreState) => s.org && s.org.name);
  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );
  const VentureMoloch = useSelector(
    (s: StoreState) =>
      s.blockchain.contracts && s.blockchain.contracts.VentureMoloch
  );
  const molochAddress = useSelector(
    (s: StoreState) =>
      s.blockchain.contracts &&
      s.blockchain.contracts.VentureMoloch.contractAddress
  );
  const defaultChain = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );

  /**
   * External hooks
   */

  const memberId = `${molochAddress && molochAddress.toLowerCase()}-member-${
    connectedAddress && connectedAddress.toLowerCase()
  }`;

  const {data, loading, error, startPolling, stopPolling} = useQuery(
    GET_MEMBER_DETAILS,
    {
      pollInterval: GQL_QUERY_POLLING_INTERVAL,
      variables: {
        id: memberId,
      },
    }
  );

  const {approvedTokens} = useApprovedTokens();
  // We use the chain to get the ERC20 details like `symbol`.
  const {erc20Balances} = useERC20Balances(connectedAddress, approvedTokens);

  const getUserTokenBalancesCached = useCallback(getUserTokenBalances, [
    VentureMoloch,
    approvedTokens,
    connectedAddress,
  ]);

  /**
   * Functions
   */

  const toETHFormatted = (wei: string) =>
    Number(Web3.utils.fromWei(wei, 'ether')).toFixed(2);

  const getTokenSymbol = (address: string) => {
    const maybeToken = erc20Balances.find(
      (ti) => (ti.address || '').toLowerCase() === address.toLowerCase()
    );

    return maybeToken ? maybeToken.symbol : '';
  };

  /**
   * Variables
   */

  const hasData = data && data.member ? true : false;

  // Build withdraw tokens map for <Withdraw />
  const withdrawals = approvedTokens.reduce((acc, next) => {
    // Set blank withdraw amounts as we will use `max = true`
    acc[next] = '';
    return acc;
  }, {} as Record<string, string>);

  // Stop polling if we received data
  if ((hasData && !restartedPolling) || !connectedAddress) {
    stopPolling();
  }

  useEffect(() => {
    getUserTokenBalancesCached();
  }, [getUserTokenBalancesCached]);

  /**
   * Functions
   */

  function getUserTokenBalances() {
    if (!VentureMoloch) return;

    if (approvedTokens) {
      approvedTokens.forEach((t: string) => {
        VentureMoloch.instance.methods
          .userTokenBalances(connectedAddress, t)
          .call()
          .then((b: string) => {
            setTokenBalances((prevState) => [
              ...prevState,
              {tokenAddress: t, tokenBalance: b},
            ]);
          });
      });
    }
  }

  function handleWithdrawProcess() {
    // start polling for balance changes
    startPolling(GQL_QUERY_POLLING_INTERVAL);
    setRestartedPolling(true);
  }

  function handleWithdrawSuccess() {
    // reset balances to initial state
    setTokenBalances([]);
    // stop polling for new balances after 3min.
    setTimeout(() => {
      stopPolling();
      setRestartedPolling(false);
    }, 180000);
  }

  // Render if no wallet is connected
  if (!connectedAddress) {
    return (
      <Wrap style={{padding: '0 1em'}}>
        <Title />

        <p className={`${s['alert-text']} text-center org-withdraw-alert-text`}>
          Please connect your wallet to withdraw your balances in {orgName}.
        </p>
      </Wrap>
    );
  }

  if (error) {
    return (
      <Wrap style={{padding: '0 1em'}}>
        <Title />

        <div className="text-center">
          <ErrorMessageWithDetails
            renderText={() => (
              <small>
                Something went wrong while getting the token balances.
              </small>
            )}
            error={error}
          />
        </div>
      </Wrap>
    );
  }

  // Render if wallet is connected
  return (
    <Wrap style={{padding: '0 1em'}}>
      <Title />

      {hasData && (
        <>
          <p className="text-center">
            Your balances available for withdrawal in {orgName}:
          </p>
          <div className={s['withdraw-balances']}>
            <ul
              className={`${s['withdraw-balances__list']} org-withdraw-balances__list`}>
              {tokenBalances.map((t: Record<string, any>) => (
                <li key={t.tokenAddress}>
                  {toETHFormatted(t.tokenBalance)}{' '}
                  <a
                    href={`${ETHERSCAN_URLS[defaultChain]}/address/${t.tokenAddress}`}
                    rel="noreferrer noopener"
                    target="_blank">
                    {getTokenSymbol(t.tokenAddress)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}

      <Withdraw
        withdrawals={withdrawals}
        max
        onProcess={handleWithdrawProcess}
        onSuccess={handleWithdrawSuccess}
        render={({
          etherscanURL,
          handleSubmit,
          submitError,
          submitReadyStatus,
          txStatus,
        }) => {
          const shouldDisableButton =
            loading ||
            !hasData ||
            submitReadyStatus !== FetchStatus.FULFILLED ||
            txStatus === Web3TxStatus.AWAITING_CONFIRM ||
            txStatus === Web3TxStatus.PENDING ||
            txStatus === Web3TxStatus.FULFILLED;

          return (
            <>
              <button
                className={`${b['mini-modal-button']} org-primary-button ${s['withdraw-button']}`}
                disabled={shouldDisableButton}
                onClick={shouldDisableButton ? () => {} : handleSubmit}>
                {txStatus === Web3TxStatus.AWAITING_CONFIRM ||
                txStatus === Web3TxStatus.PENDING ? (
                  <Loader
                    text={
                      txStatus === Web3TxStatus.AWAITING_CONFIRM
                        ? 'Preparing\u2026'
                        : 'Processing\u2026'
                    }
                    textProps={{style: {color: 'inherit'}}}
                  />
                ) : txStatus === Web3TxStatus.FULFILLED ? (
                  'Withdrawal Complete'
                ) : (
                  'Withdraw'
                )}
              </button>

              <p className="color-litesmoke text-center org-withdraw-alert-text">
                <small>All balances available will be withdrawn.</small>
              </p>

              {etherscanURL && (
                <p className="text-center">
                  <small>
                    <a
                      href={etherscanURL}
                      rel="noopener noreferrer"
                      target="_blank">
                      (view{' '}
                      {txStatus === Web3TxStatus.FULFILLED
                        ? 'transaction'
                        : 'progress'}
                      )
                    </a>
                  </small>
                </p>
              )}

              {/* Withdraw Error */}
              {submitError && (submitError as MetaMaskRPCError).code !== 4001 && (
                <div className="text-center">
                  <ErrorMessageWithDetails
                    renderText={() => (
                      <small>Something went wrong during the withdrawal.</small>
                    )}
                    error={submitError}
                  />
                </div>
              )}
            </>
          );
        }}
      />
    </Wrap>
  );
}

function Title() {
  return (
    <div className="titlebar">
      <h1 className="titlebar__title org-titlebar__title">Withdraw Balances</h1>
    </div>
  );
}
