import l from '../../assets/scss/modules/logo.module.scss';

interface LogoCC0Props {
  orgInternalName: string;
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoCC0(props: LogoCC0Props) {
  const classNameSizes = {
    cc0: props.size ? `${l[`cc0--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.cc0} ${classNameSizes.cc0} org-logo`}>
      <span>c0c0</span>
      <img
        alt="c0c0 DAO Logo"
        src={`/orgs/${props.orgInternalName}/images/logo_img.png`}
      />
    </div>
  );
}
