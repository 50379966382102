import {useState} from 'react';
import {useSelector} from 'react-redux';

import {dontCloseWindowWarning, contractSend} from '../../../../util/helpers';
import {StoreState, PaymentRequestType} from '../../../../util/types';
import {ETHERSCAN_URLS} from '../../../../util/config';
import {useETHGasPrice} from '../../../../hooks';
import {Web3TxStatus} from '../../../../util/enums';

type EasyApplyContributionRenderProps = {
  error?: Error;
  etherscanURL: string;
  isPromptOpen: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  openPrompt: (proposalUUID: string) => void;
};

type EasyApplyContributionProps = {
  onBeforeProcess?: () => void;
  onError?: (e: Error) => void;
  onProcess?: (txHash: string) => void;
  onComplete?: () => void;
  render: (p: EasyApplyContributionRenderProps) => React.ReactElement;
  /**
   * **SubmitProposalArguments**
   *
   * @param {string} proposerAddress - Address of the proposer
   * @param {string} amountOfWei - Amount of payment requested in WEI
   * @param {string} paymentRequestType - String made up of payment type (Project | SidePocket) and uuid
   */
  proposalArguments: SidePocketProposalArguments | undefined;
};

export type SidePocketProposalArguments = {
  // `addressToFund`
  addressToFund: string;
  // `paymentReqestedInWEI`
  paymentReqestedInWEI: string;
  // `paymentRequestType`
  paymentRequestType: PaymentRequestType;
};

/**
 * SidePocketEasyApply
 *
 * Allows interaction with the EasyApply smart contract's `_submitProposalLAO`.
 * The contract allows us to merge 3 separate smart contracts into one:
 *
 * 1. Wrap ETH to WETH
 * 2. Approve WETH
 * 3. Moloch `submitAndSponsorProjectProposal()`
 *
 * A render prop is used to allow for passing internal state
 * to parent/wrapping components.
 *
 * @see https://github.com/openlawteam/Lao-easy-apply
 *
 * @param {EasyApplyContributionProps} props
 * @returns Result of `render` prop.
 */
export default function SidePocketEasyApply(props: EasyApplyContributionProps) {
  const {
    onBeforeProcess,
    onError,
    onProcess,
    onComplete,
    render,
    proposalArguments,
  } = props;

  /**
   * State
   */

  const [error, setError] = useState<Error>();
  const [etherscanURL, setEtherscanURL] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /**
   * Selectors
   */
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );
  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );
  const easyApplyContract = useSelector(
    (s: StoreState) =>
      s.blockchain.contracts && s.blockchain.contracts.EasyApply
  );
  const easyApplyContractAddress = useSelector(
    (s: StoreState) => s.org && s.org.contractEasyApplyAddress
  );
  const web3Instance = useSelector(
    (s: StoreState) => s.blockchain.web3Instance
  );

  /**
   * External Hooks
   */

  const {average: gasPrice} = useETHGasPrice();

  /**
   * Functions
   */

  function handleError(error: Error) {
    setError(error);
    setIsSubmitted(false);
    setIsSubmitting(false);

    onError && onError(error);
  }

  async function handleEasyApplyWalletPrompt(proposalUUID: string) {
    if (
      !easyApplyContract ||
      !easyApplyContractAddress ||
      !proposalUUID ||
      !web3Instance
    )
      return;

    // Activate "don't close window" warning
    const unsubscribeDontCloseWindow = dontCloseWindowWarning();

    // callback for the processing transaction; receives txHash
    const handleProcessingTx = async (txHash: string) => {
      if (!txHash) return;

      setIsPromptOpen(false);
      setIsSubmitting(true);
      setEtherscanURL(`${ETHERSCAN_URLS[chainId]}/tx/${txHash}`);

      // Maybe call user `onProcess` and handle any `Error`.
      try {
        onProcess && onProcess(txHash);
      } catch (error) {
        handleError(error);
      }
    };

    try {
      /**
       * Run user callback `onBeforeProcess`
       * @note We `await` just in case the value is a Promise.
       */
      onBeforeProcess && (await onBeforeProcess());

      setError(undefined);
      setEtherscanURL('');
      setIsPromptOpen(true);
      setIsSubmitted(false);
      setIsSubmitting(false);

      const {paymentReqestedInWEI, addressToFund, paymentRequestType} =
        proposalArguments as SidePocketProposalArguments;

      const txArguments = {
        from: connectedAddress,
        to: easyApplyContractAddress,
        // set proposed gas price
        ...(gasPrice ? {gasPrice} : null),
      };

      const proposalDetails = `${paymentRequestType}::${proposalUUID}`;

      const submitAndSponsorProjectProposalArgs = [
        paymentReqestedInWEI,
        addressToFund,
        proposalDetails,
      ];

      /**
       * Easy Apply - submitAndSponsorProjectProposal
       */
      contractSend(
        'submitAndSponsorProjectProposal',
        easyApplyContract.instance.methods,
        submitAndSponsorProjectProposalArgs,
        txArguments,
        handleProcessingTx
      )
        .then(({txStatus, receipt, error}) => {
          if (txStatus === Web3TxStatus.FULFILLED) {
            if (!receipt) return;

            // Send `returnValues` in callback.
            onComplete && onComplete();

            // Tx `receipt` resolved; tx went through.
            setIsSubmitted(true);
            setIsSubmitting(false);

            unsubscribeDontCloseWindow();
          }

          if (txStatus === Web3TxStatus.REJECTED) {
            if (!error) return;
          }
        })
        .catch(({error}) => {
          handleError(error);
          // if user closed modal (MetaMask error code 4001)
          // or via WalletConnect, which only provides a message and no code
          setIsPromptOpen(false);

          unsubscribeDontCloseWindow();
        });

      // Tx `receipt` resolved; tx went through.
      setIsSubmitted(true);
      setIsSubmitting(false);

      unsubscribeDontCloseWindow();
    } catch (error) {
      setIsPromptOpen(false);

      handleError(error);

      unsubscribeDontCloseWindow();
    }
  }

  return render({
    error,
    etherscanURL,
    isPromptOpen,
    isSubmitted,
    isSubmitting,
    openPrompt: handleEasyApplyWalletPrompt,
  });
}
