import React, {useState} from 'react';

import {
  GetInvestmentProposalResponse,
  InvestmentProposalTarget,
} from '../../util/types';
import InvestmentProposalSelectTarget from './InvestmentProposalSelectTarget';
import MolochInvestmentProposalActions from './moloch/MolochInvestmentProposalActions';
import SnapshotInvestmentProposalActions from './snapshot/SnapshotInvestmentProposalActions';

type InvestmentProposalActionsProps = {
  defaultTarget?: InvestmentProposalTarget;
  /**
   * Provide a callback to run once an action completes.
   * e.g. Update the proposal from the server.
   */
  onActionComplete: (data?: {molochProposalId?: number}) => void;
  proposal: GetInvestmentProposalResponse;
};

export default function InvestmentProposalActions({
  defaultTarget = null,
  onActionComplete,
  proposal,
}: InvestmentProposalActionsProps): JSX.Element {
  const initialProposalTarget = defaultTarget || proposal.proposalTarget;

  /**
   * State
   */

  const [showPropsalTargetSelector, setShowPropsalTargetSelector] =
    useState<boolean>(initialProposalTarget === null);

  /**
   * handleUpdate
   *
   * A callback for when a proposal is submitted to a platform.
   *
   * @returns {void}
   */
  function handleUpdate() {
    /**
     * Set target select visibility in local state.
     *
     * @note This is important to set as in the case of Moloch we don't
     *   know when the server will set the `proposalTarget`
     *   as the server is polling TheGraph.
     */
    setShowPropsalTargetSelector(false);

    // Call parent callback (e.g. refetch proposal from DB)
    onActionComplete();
  }

  /**
   * handleUpdateMoloch
   *
   * A callback for when a proposal is submitted to a Moloch contract.
   *
   * @returns {void}
   */
  function handleUpdateMoloch(molochProposalId: number) {
    /**
     * Set target select visibility in local state.
     *
     * @note This is important to set as in the case of Moloch we don't
     *   know when the server will set the `proposalTarget`
     *   as the server is polling TheGraph.
     */
    setShowPropsalTargetSelector(false);

    // Call parent callback (e.g. refetch proposal from DB)
    onActionComplete({molochProposalId});
  }

  return (
    <InvestmentProposalSelectTarget
      defaultTarget={initialProposalTarget}
      showPropsalTargetSelector={showPropsalTargetSelector}
      renderActions={({selectedTargetOption}) => {
        switch (selectedTargetOption) {
          case 'moloch':
            return (
              <MolochInvestmentProposalActions
                onProposalId={handleUpdateMoloch}
                proposal={proposal}
              />
            );
          case 'snapshot':
            return (
              <SnapshotInvestmentProposalActions
                onActionComplete={handleUpdate}
                proposal={proposal}
              />
            );
          default:
            return null;
        }
      }}
    />
  );
}
