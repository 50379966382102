import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {dontCloseWindowWarning, contractSend} from '../../util/helpers';
import {ETHERSCAN_URLS} from '../../util/config';
import {StoreState} from '../../util/types';
import {GovernanceType, ProposalType, Web3TxStatus} from '../../util/enums';
import {useETHGasPrice} from '../../hooks';

type ProcessProposalFunction =
  | 'processProposal'
  | 'processGuildKickProposal'
  | 'processWhitelistProposal';

type ProcessProposalChildrenProps = {
  error?: Error;
  etherscanURL: string;
  isPromptOpen: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  openPrompt: () => void;
};

type ProcessProposalProps = {
  proposalIndex: number | undefined;
  /**
   * Determines which "process proposal" function will be called
   * The functions are: `processProposal`, `processGuildKickProposal`, `processWhitelistProposal`.
   *
   * A proposal can be one of 5 types: `payment`, `membership`, `general`, `guildkick`, or `whitelist` proposal.
   */
  proposalType?: ProposalType | GovernanceType;
  /**
   * Runs before wallet prompt opens; provides an
   * opportunity to run any validation.
   *
   * If an `Error` is thrown, the prompt will not open and
   * an `error` argument will be provided to the `render` prop.
   */
  onBeforeProcess?: () => void;
  onProcess?: (txHash: string) => void;
  onError?: (e: Error) => void;
  onComplete?: (eventReturnedValues: Record<string, any>) => void;
  render: (props: ProcessProposalChildrenProps) => React.ReactElement;
};

/**
 * ProcessProposal
 *
 * Returns a rendered component via a render prop.
 * ProcessProposalChildrenProps are passed to the render prop.
 *
 * @param {ProcessProposalProps} props
 * @returns {FunctionComponent}
 */
export default function ProcessProposal(props: ProcessProposalProps) {
  const {
    onBeforeProcess,
    onError,
    onProcess,
    onComplete,
    proposalIndex,
    proposalType = ProposalType.GENERAL,
    render,
  } = props;

  /**
   * Use these as the moloch proposal id and index could be -1 from the server.
   *
   * @todo Look into making the db entry `null` by default.
   */
  const proposalIndexSafe =
    proposalIndex !== undefined && proposalIndex !== -1
      ? proposalIndex
      : undefined;

  /**
   * State
   */

  const [error, setError] = useState<Error>();
  const [etherscanURL, setEtherscanURL] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [processProposalFunction, setProcessProposalFunction] =
    useState<ProcessProposalFunction>('processProposal');

  /**
   * Selectors
   */

  const web3Instance = useSelector(
    (state: StoreState) => state.blockchain.web3Instance
  );
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const VentureMoloch = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.VentureMoloch
  );
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );
  const contractMolochAddress = useSelector(
    (s: StoreState) => s.org?.contractMolochAddress
  );

  /**
   * External Hooks
   */

  const {average: gasPrice} = useETHGasPrice();

  /**
   * Effects
   */

  useEffect(() => {
    let functionName: ProcessProposalFunction = 'processProposal';

    if (props.proposalType === ProposalType.GUILDKICK) {
      functionName = 'processGuildKickProposal';
    } else if (props.proposalType === ProposalType.WHITELIST) {
      functionName = 'processWhitelistProposal';
    }

    setProcessProposalFunction(functionName);
  }, [props.proposalType]);

  /**
   * Functions
   */

  function getReturnValuesByType(
    type: ProposalType | GovernanceType,
    receipt: Record<string, any>
  ) {
    switch (type) {
      case ProposalType.GENERAL:
        return receipt.events.ProcessProposal.returnValues;
      case ProposalType.PAYMENT:
        return receipt.events.ProcessProposal.returnValues;
      case ProposalType.MEMBERSHIP:
        return receipt.events.ProcessProposal.returnValues;
      case ProposalType.GUILDKICK:
        return receipt.events.ProcessGuildKickProposal.returnValues;
      case ProposalType.WHITELIST:
        return receipt.events.ProcessWhitelistProposal.returnValues;
      default:
        return receipt.events.ProcessProposal.returnValues;
    }
  }

  function handleError(error: Error) {
    setError(error);
    setIsSubmitted(false);
    setIsSubmitting(false);

    onError && onError(error);
  }

  async function handleProcessPrompt() {
    if (!web3Instance) return;

    // activate "don't close window" warning
    const unsubscribeDontCloseWindow = dontCloseWindowWarning();

    // callback for the processing transaction; receives txHash
    const handleProcessingTx = (txHash: string) => {
      if (!txHash) return;

      setIsPromptOpen(false);
      setIsSubmitting(true);
      setEtherscanURL(`${ETHERSCAN_URLS[chainId]}/tx/${txHash}`);

      // Maybe call user `onProcess` and handle any `Error`.
      try {
        onProcess && onProcess(txHash);
      } catch (error) {
        handleError(error);
      }
    };

    try {
      if (!proposalIndexSafe) {
        throw new Error('No proposal index was found.');
      }

      const {methods} = (VentureMoloch && VentureMoloch.instance) || {};

      setError(undefined);
      setEtherscanURL('');
      setIsPromptOpen(true);
      setIsSubmitted(false);
      setIsSubmitting(false);

      /**
       * Run user callback `onBeforeProcess`
       * @note We `await` just in case the value is a Promise.
       */
      onBeforeProcess && (await onBeforeProcess());

      const txArguments = {
        from: connectedAddress,
        to: contractMolochAddress,
        // set proposed gas price
        ...(gasPrice ? {gasPrice} : null),
      };

      const processArgument = [proposalIndex];

      /**
       * processProposal
       *
       * @param {number} proposalIndex - Proposal index to process
       */
      // execute this one
      // once it's successful & it's a minion call, execute the executeAction call
      contractSend(
        processProposalFunction,
        methods,
        processArgument,
        txArguments,
        handleProcessingTx
      )
        .then(({txStatus, receipt, error}) => {
          if (txStatus === Web3TxStatus.FULFILLED) {
            if (!receipt) return;
            /**
             * Get `returnValues` from `receipt`.
             *
             * @see https://web3js.readthedocs.io/en/v1.2.7/web3-eth-contract.html#id37
             */
            const returnValues = getReturnValuesByType(proposalType, receipt);

            // Send `returnValues` in callback.
            onComplete && onComplete(returnValues);

            // Tx `receipt` resolved; tx went through.
            setIsSubmitted(true);
            setIsSubmitting(false);

            unsubscribeDontCloseWindow();
          }

          if (txStatus === Web3TxStatus.REJECTED) {
            if (!error) return;
            handleError(error);

            // if user closed modal (MetaMask error code 4001)
            // or via WalletConnect, which only provides a message and no code
            setIsPromptOpen(false);

            unsubscribeDontCloseWindow();
          }
        })
        .catch(({error}) => {
          handleError(error);

          // if user closed modal (MetaMask error code 4001)
          // or via WalletConnect, which only provides a message and no code
          setIsPromptOpen(false);

          unsubscribeDontCloseWindow();
        });
    } catch (error) {
      setIsPromptOpen(false);

      handleError(error);

      unsubscribeDontCloseWindow();
    }
  }

  return render({
    error,
    etherscanURL,
    isPromptOpen,
    isSubmitted,
    isSubmitting,
    openPrompt: handleProcessPrompt,
  });
}
