import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {
  InvestmentOrGovernanceProposal,
  SnapshotInvestmentProposalResponse,
} from './types';
import {
  SnapshotProposalSubType,
  SnapshotProposalType,
  SnapshotVote,
} from './enums';
import {
  GetInvestmentProposalResponseSnapshot,
  StoreState,
} from '../../util/types';
import {FetchStatus} from '../../util/enums';
import {getSnapshotIgnoreList, GOVERNANCE_PASS_PERCENTAGE} from './helpers';
import {useCounter, useIsAdmin} from '../../hooks';
import {useAbortController} from '../../hooks/useAbortController';

type SnapshotProposalsProps = {
  /**
   * @todo Fix types when proposals migrated. The following should work:
   *   `GetInvestmentProposalResponseSnapshot[] | SnapshotGovernanceProposalResponse[]`
   */
  proposalsAndVotes:
    | GetInvestmentProposalResponseSnapshot[]
    | InvestmentOrGovernanceProposal[];
  /**
   * The `SnapshotProposalType` type to filter by (e.g. "Governance").
   */
  type: SnapshotProposalType;
};

type SnapshotProposalsReturn = {
  snapshotProposalsFailed: InvestmentOrGovernanceProposal[];
  snapshotProposalsFailedAll: InvestmentOrGovernanceProposal[];
  snapshotProposalsPassed: InvestmentOrGovernanceProposal[];
  snapshotProposalsPassedAll: InvestmentOrGovernanceProposal[];
  snapshotProposalsVoting: InvestmentOrGovernanceProposal[];
  snapshotProposalsVotingAll: InvestmentOrGovernanceProposal[];
  snapshotNoProposalsFound: boolean;
  snapshotNoProposalsFoundAll: boolean;
  /**
   * Updates the sorting and filtering.
   *
   * e.g. After a vote ends, move the propsal to passed or failed.
   */
  updateSnapshotProposals: () => void;
};

/**
 * useSnapshotProposalsParsed
 *
 * A hook which will provide:
 *  - snapshotProposalsFailed: InvestmentOrGovernanceProposal[];
 *  - snapshotProposalsFailedAll: InvestmentOrGovernanceProposal[];
 *  - snapshotProposalsPassed: InvestmentOrGovernanceProposal[];
 *  - snapshotProposalsPassedAll: InvestmentOrGovernanceProposal[];
 *  - snapshotProposalsVoting: InvestmentOrGovernanceProposal[];
 *  - snapshotProposalsVotingAll: InvestmentOrGovernanceProposal[];
 *  - snapshotNoProposalsFound: boolean;
 *  - snapshotNoProposalsFoundAll: boolean;
 *  - updateSnapshotProposals: () => void;
 *
 * @param {SnapshotProposalsProps} props
 * @returns {SnapshotProposalsReturn}
 */
export default function useSnapshotProposalsParsed(
  props: SnapshotProposalsProps
): SnapshotProposalsReturn {
  const {proposalsAndVotes, type} = props;

  /**
   * State
   */

  const [snapshotProposalsFailed, setSnapshotProposalsFailed] = useState<
    InvestmentOrGovernanceProposal[]
  >([]);
  const [snapshotProposalsFailedAll, setSnapshotProposalsFailedAll] = useState<
    InvestmentOrGovernanceProposal[]
  >([]);
  const [snapshotProposalsPassed, setSnapshotProposalsPassed] = useState<
    InvestmentOrGovernanceProposal[]
  >([]);
  const [snapshotProposalsPassedAll, setSnapshotProposalsPassedAll] = useState<
    InvestmentOrGovernanceProposal[]
  >([]);
  const [snapshotProposalsVoting, setSnapshotProposalsVoting] = useState<
    InvestmentOrGovernanceProposal[]
  >([]);
  const [snapshotProposalsVotingAll, setSnapshotProposalsVotingAll] = useState<
    InvestmentOrGovernanceProposal[]
  >([]);

  const [ignoreList, setIgnoreList] = useState<string[]>([]);
  const [ignoreListStatus, setIgnoreListStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );

  /**
   * Selectors
   */

  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const isMember = useSelector((s: StoreState) =>
    s.connectedMember && s.connectedMember.isMemberActive ? true : false
  );
  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );

  /**
   * Our hooks
   */

  const isAdmin = useIsAdmin();
  const [updateCurrentProposals, dispatchUpdateCurrentProposals] = useCounter();
  const {abortController, isMountedRef} = useAbortController();

  /**
   * Variables
   */

  const snapshotNoProposalsFound =
    !snapshotProposalsVoting.length &&
    !snapshotProposalsPassed.length &&
    !snapshotProposalsFailed.length;

  const snapshotNoProposalsFoundAll =
    !snapshotProposalsVotingAll.length &&
    !snapshotProposalsPassedAll.length &&
    !snapshotProposalsFailedAll.length;

  /**
   * Cached callbacks
   */

  const filterIgnoredProposalsCached = useCallback(filterIgnoredProposals, [
    ignoreList,
  ]);
  const filterPrivateProposalsCached = useCallback(filterPrivateProposals, [
    connectedAddress,
    isAdmin,
    isMember,
    type,
  ]);
  const getProposalsFailedCached = useCallback(getProposalsFailed, [
    filterIgnoredProposalsCached,
    filterPrivateProposalsCached,
  ]);
  const getProposalsFailedAllCached = useCallback(getProposalsFailedAll, [
    filterIgnoredProposalsCached,
  ]);
  const getProposalsPassedCached = useCallback(getProposalsPassed, [
    filterIgnoredProposalsCached,
    filterPrivateProposalsCached,
  ]);
  const getProposalsPassedAllCached = useCallback(getProposalsPassedAll, [
    filterIgnoredProposalsCached,
  ]);
  const getProposalsVotingCached = useCallback(getProposalsVoting, [
    filterIgnoredProposalsCached,
    filterPrivateProposalsCached,
  ]);
  const getProposalsVotingAllCached = useCallback(getProposalsVotingAll, [
    filterIgnoredProposalsCached,
  ]);

  const getIgnoreListCached = useCallback(getIgnoreList, [
    abortController?.signal,
    isMountedRef,
    orgInternalName,
  ]);

  /**
   * Effects
   */

  // Set proposal cards into their categories
  useEffect(() => {
    if (!proposalsAndVotes) return;

    if (
      ignoreListStatus === FetchStatus.STANDBY ||
      ignoreListStatus === FetchStatus.PENDING
    ) {
      return;
    }

    setSnapshotProposalsFailed(getProposalsFailedCached(proposalsAndVotes));
    setSnapshotProposalsFailedAll(
      getProposalsFailedAllCached(proposalsAndVotes)
    );
    setSnapshotProposalsPassed(getProposalsPassedCached(proposalsAndVotes));
    setSnapshotProposalsPassedAll(
      getProposalsPassedAllCached(proposalsAndVotes)
    );
    setSnapshotProposalsVoting(getProposalsVotingCached(proposalsAndVotes));
    setSnapshotProposalsVotingAll(
      getProposalsVotingAllCached(proposalsAndVotes)
    );
  }, [
    getProposalsFailedCached,
    getProposalsFailedAllCached,
    getProposalsPassedCached,
    getProposalsPassedAllCached,
    getProposalsVotingCached,
    getProposalsVotingAllCached,
    proposalsAndVotes,
    updateCurrentProposals,
    ignoreList,
    ignoreListStatus,
  ]);

  // Get ignore list
  useEffect(() => {
    getIgnoreListCached();
  }, [getIgnoreListCached]);

  /**
   * Functions
   */

  async function getIgnoreList() {
    if (!orgInternalName) return;

    try {
      setIgnoreListStatus(FetchStatus.PENDING);

      const list = await getSnapshotIgnoreList(orgInternalName, {
        signal: abortController?.signal,
      });

      if (!isMountedRef.current) return;

      setIgnoreListStatus(FetchStatus.FULFILLED);
      setIgnoreList(list);
    } catch (error) {
      if (!isMountedRef.current) return;

      setIgnoreList([]);
      setIgnoreListStatus(FetchStatus.REJECTED);
    }
  }

  /**
   * getProposalsFailed
   *
   * @returns {InvestmentOrGovernanceProposal[]}
   */
  function getProposalsFailed(
    proposalsAndVotes: InvestmentOrGovernanceProposal[]
  ): InvestmentOrGovernanceProposal[] {
    return proposalsAndVotes
      .filter(filterIgnoredProposalsCached)
      .filter(filterDidProposalVoteEnd)
      .filter(filterDidProposalFail)
      .sort(sortASC)
      .filter(filterPrivateProposalsCached);
  }

  /**
   * getProposalsFailedAll
   *
   * includes private proposals
   *
   * @returns {InvestmentOrGovernanceProposal[]}
   */
  function getProposalsFailedAll(
    proposalsAndVotes: InvestmentOrGovernanceProposal[]
  ): InvestmentOrGovernanceProposal[] {
    return proposalsAndVotes
      .filter(filterIgnoredProposalsCached)
      .filter(filterDidProposalVoteEnd)
      .filter(filterDidProposalFail)
      .sort(sortASC);
  }

  /**
   * getProposalsPassed
   *
   * @returns {InvestmentOrGovernanceProposal[]}
   */
  function getProposalsPassed(
    proposalsAndVotes: InvestmentOrGovernanceProposal[]
  ): InvestmentOrGovernanceProposal[] {
    return proposalsAndVotes
      .filter(filterIgnoredProposalsCached)
      .filter(filterDidProposalVoteEnd)
      .filter(filterDidProposalPass)
      .sort(sortASC)
      .filter(filterPrivateProposalsCached);
  }

  /**
   * getProposalsPassedAll
   *
   * includes private proposals
   *
   * @returns {InvestmentOrGovernanceProposal[]}
   */
  function getProposalsPassedAll(
    proposalsAndVotes: InvestmentOrGovernanceProposal[]
  ): InvestmentOrGovernanceProposal[] {
    return proposalsAndVotes
      .filter(filterIgnoredProposalsCached)
      .filter(filterDidProposalVoteEnd)
      .filter(filterDidProposalPass)
      .sort(sortASC);
  }

  /**
   * getProposalsVoting
   *
   * @returns {InvestmentOrGovernanceProposal[]}
   */
  function getProposalsVoting(
    proposalsAndVotes: InvestmentOrGovernanceProposal[]
  ): InvestmentOrGovernanceProposal[] {
    return proposalsAndVotes
      .filter(filterIgnoredProposalsCached)
      .filter(filterIsProposalInVoting)
      .sort(sortDESC)
      .filter(filterPrivateProposalsCached);
  }

  /**
   * getProposalsVotingAll
   *
   * includes private proposals
   *
   * @returns {InvestmentOrGovernanceProposal[]}
   */
  function getProposalsVotingAll(
    proposalsAndVotes: InvestmentOrGovernanceProposal[]
  ): InvestmentOrGovernanceProposal[] {
    return proposalsAndVotes
      .filter(filterIgnoredProposalsCached)
      .filter(filterIsProposalInVoting)
      .sort(sortDESC);
  }

  function filterIgnoredProposals(
    proposalAndVotes: InvestmentOrGovernanceProposal
  ) {
    const proposal = proposalAndVotes.snapshotHubProposal;

    return (
      proposal !== null &&
      proposal !== undefined &&
      !ignoreList.includes(proposal.authorIpfsHash)
    );
  }

  function filterDidProposalVoteEnd(
    proposalAndVotes: InvestmentOrGovernanceProposal
  ) {
    const proposal = proposalAndVotes.snapshotHubProposal;

    return (
      proposal !== null &&
      proposal !== undefined &&
      proposal.msg.payload.end < Math.ceil(Date.now() / 1000)
    );
  }

  function filterIsProposalInVoting(
    proposalAndVotes: InvestmentOrGovernanceProposal
  ) {
    const proposal = proposalAndVotes.snapshotHubProposal;

    return (
      proposal !== null &&
      proposal !== undefined &&
      proposal.msg.payload.end > Math.ceil(Date.now() / 1000)
    );
  }

  function filterDidProposalPass(
    proposalAndVotes: InvestmentOrGovernanceProposal
  ): boolean {
    const votes = proposalAndVotes.snapshotHubVotes;
    const proposal = proposalAndVotes.snapshotHubProposal;
    const subType = proposal ? proposal.msg.payload.metadata.subType : '';

    const yesGreaterThanNo =
      votes !== null &&
      votes !== undefined &&
      votes.votesByChoice[SnapshotVote.Yes].voteShares >
        votes.votesByChoice[SnapshotVote.No].voteShares;

    const yesGreaterThanTheshold =
      votes !== null &&
      votes !== undefined &&
      votes.votesByChoice[SnapshotVote.Yes].votePercent >
        GOVERNANCE_PASS_PERCENTAGE;

    switch (subType) {
      case SnapshotProposalSubType.Governance:
        return yesGreaterThanTheshold;
      case SnapshotProposalSubType.General:
        return yesGreaterThanNo;
      /**
       * For the original few proposals which were Governance votes only.
       * e.g. LAO I's GIP002.
       */
      case undefined:
        return yesGreaterThanTheshold;
      default:
        return yesGreaterThanNo;
    }
  }

  function filterDidProposalFail(
    proposalAndVotes: InvestmentOrGovernanceProposal
  ): boolean {
    const votes = proposalAndVotes.snapshotHubVotes;
    const proposal = proposalAndVotes.snapshotHubProposal;
    const subType = proposal ? proposal.msg.payload.metadata.subType : '';

    const yesLessThanOrEqualNo =
      votes !== null &&
      votes !== undefined &&
      votes.votesByChoice[SnapshotVote.Yes].voteShares <=
        votes.votesByChoice[SnapshotVote.No].voteShares;
    const yesLessThanOrEqualTheshold =
      votes !== null &&
      votes !== undefined &&
      votes.votesByChoice[SnapshotVote.Yes].votePercent <=
        GOVERNANCE_PASS_PERCENTAGE;

    switch (subType) {
      case SnapshotProposalSubType.Governance:
        return yesLessThanOrEqualTheshold;
      case SnapshotProposalSubType.General:
        return yesLessThanOrEqualNo;
      /**
       * For the original few proposals which were Governance votes only.
       * e.g. LAO I's GIP002.
       */
      case undefined:
        return yesLessThanOrEqualTheshold;
      default:
        return yesLessThanOrEqualNo;
    }
  }

  function filterPrivateProposals(
    proposalAndVotes: InvestmentOrGovernanceProposal
  ) {
    // If a member is connected, or the `showPrivateProposals` prop is set, do nothing.
    if (isMember || isAdmin) return true;

    const proposal = proposalAndVotes.snapshotHubProposal;

    switch (type) {
      case SnapshotProposalType.Investment:
        /**
         * For investments we check the DB value `private` as we allow users to change it.
         *
         * @todo We can remove `proposalAndVotes.snapshotProposal` after the migration.
         */
        const proposalDB =
          (proposalAndVotes as SnapshotInvestmentProposalResponse)
            .snapshotProposal ||
          (proposalAndVotes as GetInvestmentProposalResponseSnapshot);

        // Check to see if the connected user is the one who created the proposal
        const {ethereumAddress = ''} = proposalDB?.user;

        const isSnapshotProposer =
          ethereumAddress && connectedAddress
            ? ethereumAddress.toLowerCase() === connectedAddress.toLowerCase()
            : false;

        return isSnapshotProposer || proposalDB.private !== true;
      case SnapshotProposalType.Governance:
        return (
          proposal !== null &&
          proposal !== undefined &&
          proposal.msg.payload.metadata.private !== true
        );
      default:
        return false;
    }
  }

  function sortASC(
    a: InvestmentOrGovernanceProposal,
    b: InvestmentOrGovernanceProposal
  ) {
    const proposalA = a.snapshotHubProposal;
    const proposalB = b.snapshotHubProposal;

    if (!proposalA || !proposalB) return 0;

    return proposalB.msg.payload.end - proposalA.msg.payload.end;
  }

  function sortDESC(
    a: InvestmentOrGovernanceProposal,
    b: InvestmentOrGovernanceProposal
  ) {
    const proposalA = a.snapshotHubProposal;
    const proposalB = b.snapshotHubProposal;

    if (!proposalA || !proposalB) return 0;

    return proposalA.msg.payload.end - proposalB.msg.payload.end;
  }

  return {
    snapshotProposalsFailed,
    snapshotProposalsFailedAll, // includes private proposals
    snapshotProposalsPassed,
    snapshotProposalsPassedAll, // includes private proposals
    snapshotProposalsVoting,
    snapshotProposalsVotingAll, // includes private proposals
    snapshotNoProposalsFound,
    snapshotNoProposalsFoundAll, // includes private proposals
    updateSnapshotProposals: () => {
      dispatchUpdateCurrentProposals({type: 'increment'});
    },
  };
}
