import {useDispatch, useSelector} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import React, {useEffect, useState, useCallback} from 'react';
import ReactModal from 'react-modal';

import VotingHistoryItem, {
  VotingHistoryItemProps,
} from '../../components/ui/VotingHistoryItem';
import Wrap from '../../components/layout/Wrap';
import {authServerShowModal} from '../../store/actions';
import {FetchStatus} from '../../util/enums';
import {GET_MEMBER_DETAILS} from '../../gql';
import {getOrgText, normalizeString} from '../../util/helpers';
import {GQL_QUERY_POLLING_INTERVAL} from '../../util/config';
import {isEmailValid} from '../../util/validation';
import {StoreState, Member, Proposal} from '../../util/types';
import {UpdateMemberIdentity} from '../../components/actions';
import {useBackendURL, usePageVisibility} from '../../hooks';
import {useQuery} from '@apollo/react-hooks';
import FadeIn from '../../components/common/FadeIn';
import Loader from '../../components/feedback/Loader';
import MemberInfo from './MemberInfo';
import TimesSVG from '../../assets/svg/TimesSVG';

import '../../assets/scss/modules/input.module.scss';
import m from '../../assets/scss/modules/memberdetails.module.scss';
import modal from '../../assets/scss/modules/modal.module.scss';
import sm from '../../assets/scss/modules/sale.module.scss';
import b from '../../assets/scss/modules/buttons.module.scss';

type State = {
  errorUpdateIdentity: string;
  isApprovedMember: boolean;
  isUpdateIdentityValid: boolean;
  memberDetails: Member | undefined;
  memberDetailsFetchError: string;
  processingApproval: boolean;
  showDelegationModal: boolean;
  showIdentityUpdateModal: boolean;
  userUpdatedEmail: string;
  userUpdatedUsername: string;
  votingWeight: string;
};

const INITIAL_STATE: State = {
  errorUpdateIdentity: '',
  isApprovedMember: false,
  isUpdateIdentityValid: true,
  memberDetails: undefined,
  memberDetailsFetchError: '',
  processingApproval: false,
  showDelegationModal: false,
  showIdentityUpdateModal: false,
  userUpdatedEmail: '',
  userUpdatedUsername: '',
  votingWeight: '\u2013' /* &ndash; */,
};

export default function MemberDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {ethereumAddress: ethereumAddressRouterParam = '' as string} =
    useParams<{ethereumAddress: string}>();

  /**
   * Use State
   */

  const [state, setState] = useState<State>(INITIAL_STATE);
  const [votingHistory, setVotingHistory] = useState<
    Array<VotingHistoryItemProps>
  >([]);
  const [votingHistoryFetchStatus, setVotingHistoryFetchStatus] =
    useState<FetchStatus>(FetchStatus.STANDBY);

  /**
   * Use selectors
   */

  const accessToken = useSelector((s: StoreState) =>
    s.authServer ? s.authServer.accessToken : ''
  );
  const connectedAddress = useSelector((s: StoreState) =>
    normalizeString(s.blockchain.connectedAddress || '')
  );
  const connectedMember = useSelector((s: StoreState) => s.connectedMember);
  const totalShares = useSelector(
    (s: StoreState) =>
      s.blockchain &&
      s.blockchain.molochConstants &&
      s.blockchain.molochConstants.totalShares
  );
  const molochAddress: string | undefined = useSelector(
    (s: StoreState) =>
      s.blockchain.contracts &&
      s.blockchain.contracts.VentureMoloch.contractAddress
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  /**
   * External hooks
   */

  const backendURL = useBackendURL();
  const isPageVisible = usePageVisibility();

  const memberId = `${molochAddress && normalizeString(molochAddress)}-member-${
    ethereumAddressRouterParam && normalizeString(ethereumAddressRouterParam)
  }`;

  const {
    data: memberGQLData,
    startPolling,
    stopPolling,
  } = useQuery(GET_MEMBER_DETAILS, {
    pollInterval: GQL_QUERY_POLLING_INTERVAL,
    variables: {
      id: memberId,
    },
  });

  /**
   * Variables
   */

  const getText = getOrgText(orgText);
  const orgHelpEmail = getText('OrgHelpEmail');
  const {memberDetails} = state;
  const memberAddressRouterParam = normalizeString(ethereumAddressRouterParam);
  const hasGQLMember = memberGQLData && memberGQLData.member ? true : false;

  const isCurrentMemberConnected: boolean =
    normalizeString(connectedAddress) ===
      normalizeString(memberAddressRouterParam) ||
    normalizeString(connectedMember?.memberAddress) ===
      normalizeString(memberAddressRouterParam)
      ? true
      : false;

  const memberVotingWeight = hasGQLMember
    ? (
        (Number(memberGQLData.member.shares) / Number(totalShares)) *
        100
      ).toFixed(2)
    : '';

  /**
   * Cached callbacks
   */

  const getProposalByMolochIdCached = useCallback(getProposalByMolochId, [
    backendURL,
  ]);

  const getVotingHistoryCached = useCallback(getVotingHistory, [
    getProposalByMolochIdCached,
    hasGQLMember,
    memberGQLData,
  ]);

  /**
   * Effects
   */

  useEffect(() => {
    if (isPageVisible) {
      startPolling(GQL_QUERY_POLLING_INTERVAL);
    } else {
      stopPolling();
    }
  }, [isPageVisible, startPolling, stopPolling]);

  // If the connected user is a member,
  // attempt to fetch details via our API about the member
  useEffect(() => {
    if (!backendURL) return;

    fetch(`${backendURL}/members/${memberAddressRouterParam}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status >= 400 && response.status < 500) {
          throw new Error('Member not found.');
        }

        if (response.status === 500) {
          throw new Error('Something went wrong while getting the member.');
        }

        return response.json();
      })
      .then((responseJSON) => {
        setState((s) => ({
          ...s,
          memberDetails: responseJSON,
        }));
      })
      .catch((error) => {
        setState((s) => ({...s, memberDetailsFetchError: error.message}));
      });
  }, [backendURL, memberAddressRouterParam]);

  useEffect(() => {
    // We need the backendURL inside getVotingHistoryCached for getProposalByMolochId
    if (!backendURL) return;
    if (votingHistoryFetchStatus === FetchStatus.PENDING) return;
    if (votingHistoryFetchStatus === FetchStatus.FULFILLED) return;

    if (
      !connectedMember.isMemberActive ||
      connectedMember.fetchStatus === FetchStatus.PENDING
    ) {
      setVotingHistory([]);

      return;
    }

    // Get voting history
    getVotingHistoryCached();
  }, [
    backendURL,
    connectedMember,
    votingHistoryFetchStatus,
    getVotingHistoryCached,
  ]);

  /**
   * Functions
   */

  function goBack(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    history.push('/members');
  }

  async function getProposalByMolochId(proposalId: string) {
    try {
      if (!backendURL) return;

      const proposalResponse = await fetch(
        `${backendURL}/proposals-investment/${proposalId}?isMolochId=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!proposalResponse.ok) return;

      const proposalJSON: Proposal = await proposalResponse.json();

      return proposalJSON;
    } catch (error) {
      throw error;
    }
  }

  async function getVotingHistory() {
    if (!hasGQLMember) return;

    try {
      setVotingHistoryFetchStatus(FetchStatus.PENDING);

      const votingHistory = memberGQLData.member.votes.map(
        async (vote: Record<string, any>) => {
          const proposal = await getProposalByMolochIdCached(
            vote.proposal.proposalId
          );

          // Bail this iteration if proposal was not found
          if (!proposal) return;

          const {uuid, name} = proposal;

          return {
            name,
            proposalId: Number(vote.proposal.proposalId),
            proposalIndex: vote.proposal.proposalIndex,
            proposalInternalId: uuid,
            voted: vote.uintVote,
            status: !vote.proposal.processed
              ? 'Pending'
              : vote.proposal.processed && vote.proposal.didPass
              ? 'Passed'
              : 'Failed',
          };
        }
      );

      votingHistory.forEach(async (v: VotingHistoryItemProps) => {
        const item = await v;

        if (!item) return;

        // Sort descending
        setVotingHistory((prev: VotingHistoryItemProps[]) =>
          [...prev, item].sort((a, b) => b.proposalIndex - a.proposalIndex)
        );
      });

      Promise.all(votingHistory).then(() =>
        setVotingHistoryFetchStatus(FetchStatus.FULFILLED)
      );
    } catch (error) {
      setVotingHistory([]);
      setVotingHistoryFetchStatus(FetchStatus.REJECTED);
    }
  }

  function handleAuthModalShow() {
    !accessToken && dispatch(authServerShowModal(true));
  }

  async function handleUpdateIdentitySuccess({
    emailAddress,
    username,
  }: Partial<{emailAddress: string; username: string}>) {
    setState((s) => ({
      ...s,
      memberDetails: {
        ...s.memberDetails,
        user: {
          ...(s.memberDetails ? s.memberDetails.user : null),
          ...(username ? {username} : null),
          ...(emailAddress ? {emailAddress} : null),
        },
      } as Member,
    }));

    // Close the modal
    handleShowIdentityUpdateModal(false);
  }

  function handleShowIdentityUpdateModal(shouldShow: boolean = true) {
    if (!accessToken && shouldShow) {
      handleAuthModalShow();

      return;
    }

    setState((s) => ({
      ...s,
      showIdentityUpdateModal: shouldShow,
    }));
  }

  function handleUpdateIdentityEmailBlur(
    event: React.FocusEvent<HTMLInputElement>
  ) {
    const isValid =
      event.currentTarget.value !== '' &&
      isEmailValid(event.currentTarget.value)
        ? true
        : event.currentTarget.value === ''
        ? true
        : false;

    setState((s) => ({
      ...s,
      errorUpdateIdentity: isValid ? '' : 'The email address is invalid.',
      isUpdateIdentityValid: isValid,
    }));
  }

  function handleUpdateIdentityInputValues(
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'email' | 'username'
  ) {
    const {value} = event.currentTarget;

    type === 'email'
      ? setState((s) => ({...s, userUpdatedEmail: value}))
      : setState((s) => ({...s, userUpdatedUsername: value}));
  }

  function handleProposalDetails(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();

    const {proposalId} = event.currentTarget.dataset;
    if (proposalId) {
      history.push(`/proposals/${proposalId}`);
    }
  }

  return (
    <FadeIn>
      <Wrap className={'section-wrapper'}>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">Member Info</h2>
          <button
            className="titlebar__action org-titlebar__action"
            onClick={goBack}>
            &larr;<span className="titlebar__action-text">View all</span>
          </button>
        </div>

        {/* MEMBER FETCH ERROR */}
        {state.memberDetailsFetchError ? (
          <p className={`${m.error} org-error-message`}>
            {state.memberDetailsFetchError}
          </p>
        ) : (
          <div>
            <section
              className={`${m['member-details-wrapper']} org-member-details-wrapper`}>
              <aside className={m['member-column']}>
                {/* MEMBER INFO (e.g. eth addy, etc.) */}
                <MemberInfo
                  canView={connectedMember.isMemberActive ? true : false}
                  canEdit={isCurrentMemberConnected}
                  ethereumAddress={memberAddressRouterParam}
                  shares={
                    hasGQLMember ? memberGQLData.member.shares : undefined
                  }
                  memberDetails={memberDetails}
                  onIdentityUpdateClicked={() => {
                    // discard event
                    handleShowIdentityUpdateModal();
                  }}
                  votingWeight={memberVotingWeight}
                />
              </aside>

              {/* MAIN CONTENT (e.g. voting history, etc.) */}
              <section className={m['voting-history-column']}>
                <div
                  className={`${m['voting-history-title']} org-voting-history-title`}>
                  Voting History
                </div>

                {votingHistoryFetchStatus === FetchStatus.PENDING && (
                  <Loader
                    style={{
                      height: 30,
                      width: 30,
                    }}
                  />
                )}

                {connectedMember.isMemberActive &&
                  votingHistory.length > 0 &&
                  votingHistory.map((h: VotingHistoryItemProps) => (
                    <VotingHistoryItem
                      key={h.proposalInternalId}
                      name={h.name || ''}
                      onClick={handleProposalDetails}
                      proposalId={h.proposalId}
                      proposalIndex={h.proposalIndex}
                      proposalInternalId={h.proposalInternalId}
                      status={h.status}
                      voted={h.voted}
                    />
                  ))}

                {connectedMember.isMemberActive &&
                  !votingHistory.length &&
                  (votingHistoryFetchStatus === FetchStatus.FULFILLED ||
                    votingHistoryFetchStatus === FetchStatus.REJECTED) && (
                    <div>
                      <p
                        className={`${m['no-voting-history']} org-no-voting-history`}>
                        Member has no voting history.
                      </p>
                    </div>
                  )}

                {(!connectedAddress ||
                  (!connectedMember.isMemberActive &&
                    connectedMember.fetchStatus === FetchStatus.FULFILLED &&
                    votingHistoryFetchStatus !== FetchStatus.PENDING)) && (
                  <div>
                    <p
                      key={'no-voting-history'}
                      className={`${m['no-voting-history']} org-no-voting-history`}>
                      Voting history will appear, once you connect your wallet
                      with a fully admitted member address. If you have any
                      questions, please ask for{' '}
                      <a
                        href={`mailto:${orgHelpEmail}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        help
                      </a>
                      .
                    </p>
                  </div>
                )}
              </section>
            </section>
          </div>
        )}
      </Wrap>

      {/* ACCOUNT UPDATE MODAL */}
      <ReactModal
        ariaHideApp={false}
        className={`${modal['modal-content-wide']}`}
        isOpen={state.showIdentityUpdateModal}
        onRequestClose={() => handleShowIdentityUpdateModal(false)}
        overlayClassName={`${modal['modal-overlay']} org-modal-overlay`}
        role="dialog"
        style={
          {
            overlay: {zIndex: '99'},
            content: {
              maxWidth: '32.5rem',
            },
          } as any
        }>
        <FadeIn>
          <div
            className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} org-modal`}>
            <div className={`${sm.sales} ${modal['modal-title']} card`}>
              {/* MODEL CLOSE BUTTON */}
              <span
                className={`${b['modal-close']} org-modal-close`}
                onClick={() => handleShowIdentityUpdateModal(false)}>
                <TimesSVG />
              </span>
              <div className="titlebar">
                <h2 className="titlebar__title org-titlebar__title">
                  Update your account
                </h2>
              </div>

              <p>
                <small>
                  Update your contact email and username. Your username is for
                  other members to identify you.
                </small>
              </p>

              <label className={m['update-field']}>
                <span className="hidden">New email</span>
                <input
                  onChange={(event) =>
                    handleUpdateIdentityInputValues(event, 'email')
                  }
                  onBlur={handleUpdateIdentityEmailBlur}
                  placeholder="New email"
                  type="text"
                />
              </label>

              <label className={m['update-field']}>
                <span className="hidden">New username</span>
                <input
                  onChange={(event) =>
                    handleUpdateIdentityInputValues(event, 'username')
                  }
                  placeholder="New username"
                  type="text"
                />
              </label>

              <UpdateMemberIdentity
                onSuccess={handleUpdateIdentitySuccess}
                render={({
                  updateUserError,
                  updateUserStatus,
                  updateUserIdentity,
                }) => (
                  <>
                    <button
                      className={`${b['mini-modal-button']} org-mini-modal-button`}
                      onClick={
                        updateUserStatus === FetchStatus.PENDING ||
                        state.errorUpdateIdentity
                          ? () => {}
                          : () =>
                              updateUserIdentity({
                                emailAddress: state.userUpdatedEmail,
                                username: state.userUpdatedUsername,
                              })
                      }>
                      {updateUserStatus === FetchStatus.PENDING ? (
                        <Loader />
                      ) : (
                        'Update'
                      )}
                    </button>

                    {(updateUserError || state.errorUpdateIdentity) && (
                      <p className="error-message org-error-message">
                        {state.errorUpdateIdentity ||
                          (updateUserError && updateUserError.message)}
                      </p>
                    )}
                  </>
                )}
              />
            </div>
          </div>
        </FadeIn>
      </ReactModal>
    </FadeIn>
  );
}
