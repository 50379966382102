import React from 'react';
import {useSelector} from 'react-redux';

import {FetchStatus, ProposalHeaderNames} from '../../util/enums';
import {getOrgText} from '../../util/helpers';
import {InternalNamesToMap} from '../../util/orgDomainMappings';
import {StoreState} from '../../util/types';
import FadeIn from '../../components/common/FadeIn';
import HiThereLoader from '../../components/feedback/HiThereLoader';
import InvestmentProposalsCards from './InvestmentProposalsCards';
import useInvestmentProposals from '../../hooks/useInvestmentProposals';
import Wrap from '../../components/layout/Wrap';

/**
 * InvestmentProposals
 *
 * Renders all non-sponsored investment proposals:
 *  - Proposals with no `proposalTarget` (`moloch` or `snapshot`) set.
 *  - Moloch unsponsored targets: Normal payment requests (including Minion)
 *
 * @returns {JSX.Element}
 */
export default function InvestmentProposals() {
  /**
   * Selectors
   */

  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  /**
   * Our hooks
   */

  const {investmentProposals, investmentProposalsStatus} =
    useInvestmentProposals({
      sponsored: false,
    });

  /**
   * Variables
   */

  const getText = getOrgText(orgText);
  const orgLoaderEmoji = getText('OrgLoaderEmoji');
  const orgProjectsText = getText('OrgProjectsText');

  /**
   * Merge the proposal data and sort by the sort data tuple
   * Date.getTime() number value in reverse chronological order.
   */
  const sortedProposals = investmentProposals.sort(
    (a, b) =>
      new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
  );

  const isLoadingProposals =
    investmentProposalsStatus === FetchStatus.STANDBY ||
    investmentProposalsStatus === FetchStatus.PENDING;

  // Return loading if the proposals are not done fetching.
  if (isLoadingProposals) {
    return (
      <Wrap className={'section-wrapper'}>
        <FadeIn>
          <div className="titlebar">
            <h2 className="titlebar__title org-titlebar__title">
              {orgProjectsText}
            </h2>
          </div>

          <div style={{width: '3rem', margin: '0 auto'}}>
            <HiThereLoader />
          </div>
          <p className="text-center">Loading&hellip;</p>
        </FadeIn>
      </Wrap>
    );
  }

  // Return if no proposals were found.
  if (!sortedProposals || !sortedProposals.length) {
    return (
      <Wrapper>
        <div className="no-data-container">
          <div className="emoji-container">
            <span role="img" aria-label="Emoji.">
              {orgLoaderEmoji}
            </span>
          </div>
          <p className="text-center org-bold">No proposals, yet.</p>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <section
        className={
          'grid--fluid sections-grid-container org-sections-grid-container'
        }>
        <div className="sections-grid__header org-sections-grid__header">
          {orgInternalName === InternalNamesToMap.thelao &&
            ProposalHeaderNames.REQUESTS}
        </div>

        <div className="sections-grid__cards">
          <InvestmentProposalsCards proposals={sortedProposals} />
        </div>
      </section>
    </Wrapper>
  );
}

function Wrapper(props: React.PropsWithChildren<React.ReactNode>) {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgProjectsText = getText('OrgProjectsText');

  return (
    <Wrap className={'section-wrapper'}>
      <FadeIn>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">
            {orgProjectsText}
          </h2>
        </div>

        {props.children}
      </FadeIn>
    </Wrap>
  );
}
