import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactModal from 'react-modal';

import UserSVG from '../../assets/svg/UserSVG';
import Checkbox from '../../components/common/Checkbox';
import Loader from '../../components/feedback/Loader';
import {StoreState} from '../../util/types';
import {formatEthereumAddress, getOrgText} from '../../util/helpers';
import {ETHERSCAN_URLS} from '../../util/config';
import {getConnectedMemberFromSmartContract} from '../../store/actions';
import FadeIn from '../../components/common/FadeIn';

import b from '../../assets/scss/modules/buttons.module.scss';
import m from '../../assets/scss/modules/modal.module.scss';
import mi from '../../assets/scss/modules/memberinfo.module.scss';
import sm from '../../assets/scss/modules/sale.module.scss';

type Steps = 'transferDelegation' | 'revokeDelegation';

type StateProps = {
  currentStep: Steps;
  confirmDelgation: boolean;
  showDelegationModal: boolean;
  disableConfirmDelgation: boolean;
  delegationStatus: string;
  userUpdatedEthereumAddress: string;
  isSubmittingText: string;
  isSubmitting: boolean;
  isDelegate: boolean;
  currentDelegation: string;
  errorMessage: string;
};

enum DelegationStatus {
  DELEGATE_VOTES = 'Delegate Votes',
  REVOKE_DELEGATION = 'Revoke Delegation',
}

enum DelegationStep {
  TRANSFER_DELEGATION = 'transferDelegation',
  REVOKE_DELEGATION = 'revokeDelegation',
}

const INITIAL_STATE: StateProps = {
  currentStep: DelegationStep.TRANSFER_DELEGATION,
  confirmDelgation: false,
  showDelegationModal: false,
  disableConfirmDelgation: false,
  delegationStatus: '',
  userUpdatedEthereumAddress: '',
  isSubmittingText: '',
  isSubmitting: false,
  isDelegate: false,
  currentDelegation: '',
  errorMessage: '',
};

type StepsType = {[S in Steps]: () => JSX.Element};

export default function Delegation() {
  const [state, setState] = useState<StateProps>(INITIAL_STATE);
  const [etherscanURL, setEtherscanURL] = useState<string>('');
  const [error, setError] = useState<Error>();

  const steps: StepsType = {
    transferDelegation: renderTransferDelegation,
    revokeDelegation: renderRevokeDelegation,
  };

  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );
  const VentureMoloch = useSelector(
    (s: StoreState) =>
      s.blockchain.contracts && s.blockchain.contracts.VentureMoloch
  );
  const memberAddress = useSelector(
    (s: StoreState) => s.connectedMember.memberAddress
  );
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgGradientColorFrom = getText('OrgGradientColorFrom');
  const orgGradientColorTo = getText('OrgGradientColorTo');

  const fetchDelegationInfoCached = useCallback(fetchDelegationInfo, [
    VentureMoloch,
    connectedAddress,
    memberAddress,
  ]);

  /**
   * External hooks
   */

  const dispatch = useDispatch();

  useEffect(() => {
    if (VentureMoloch) {
      fetchDelegationInfoCached();
    }
  }, [fetchDelegationInfoCached, VentureMoloch]);

  async function fetchDelegationInfo() {
    if (!VentureMoloch) return;

    try {
      setState((s) => ({
        ...s,
        disableConfirmDelgation: true,
      }));

      if (!connectedAddress) throw new Error('No user account found.');

      const members = await VentureMoloch.instance.methods
        .members(connectedAddress)
        .call({from: connectedAddress});

      setState((s) => ({
        ...s,
        currentStep:
          members.delegateKey.toLowerCase() === connectedAddress ||
          members.delegateKey.startsWith('0x00000')
            ? DelegationStep.TRANSFER_DELEGATION
            : DelegationStep.REVOKE_DELEGATION,
        currentDelegation: members.delegateKey.toLowerCase(),
        delegationStatus:
          members.delegateKey.toLowerCase() === connectedAddress ||
          members.delegateKey.startsWith('0x00000')
            ? DelegationStatus.DELEGATE_VOTES
            : DelegationStatus.REVOKE_DELEGATION,
        disableConfirmDelgation: false,
        isDelegate: memberAddress !== connectedAddress,
      }));
    } catch (e) {
      setState((s) => ({
        ...s,
        disableConfirmDelgation: false,
      }));
    }
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    setState((s) => ({
      ...s,
      confirmDelgation: !state.confirmDelgation,
    }));
  }

  function handleShowDelegationModal() {
    const shouldShow: boolean = true;

    setState((s) => ({
      ...s,
      showDelegationModal: shouldShow,
    }));
    setEtherscanURL('');
  }

  async function handleConfirmDelegation() {
    try {
      if (!connectedAddress) throw new Error('No user account found.');

      const {methods} = (VentureMoloch && VentureMoloch.instance) || {};

      const delegatedEthereumAddress =
        state.currentStep === DelegationStep.TRANSFER_DELEGATION
          ? state.userUpdatedEthereumAddress
          : connectedAddress;

      // show error message
      if (delegatedEthereumAddress.trim() === '') {
        setState((s) => ({
          ...s,
          errorMessage: 'Please enter a valid ethereum address',
          userUpdatedEthereumAddress: '',
          isSubmitting: false,
          disableConfirmDelgation: false,
        }));

        return;
      }

      setError(undefined);
      setEtherscanURL('');
      setState((s) => ({
        ...s,
        disableConfirmDelgation: true,
        isSubmitting: true,
        isSubmittingText: 'Confirming wallet\u2026',
        errorMessage: '',
      }));

      const methodArguments = [delegatedEthereumAddress.toLowerCase()];
      const txArguments = {
        from: connectedAddress,
      };

      // Let's test the transaction for any contract exceptions
      // and get the `gas` by estimating the gas limit of the transaction
      const gas = await methods['updateDelegateKey'](
        ...methodArguments
      ).estimateGas(txArguments);

      /**
       * updateDelegateKey
       *
       * @param {string} delegatedEthereumAddress - Delegate ethereum address
       */
      const receipt = await methods
        .updateDelegateKey(...methodArguments)
        .send({...txArguments, gas})
        .on('transactionHash', function (txHash: string) {
          setState((s) => ({
            ...s,
            isSubmittingText:
              state.currentStep === DelegationStep.TRANSFER_DELEGATION
                ? 'Delegation transaction processing\u2026'
                : 'Delegate revocation processing\u2026',
          }));
          setEtherscanURL(`${ETHERSCAN_URLS[chainId]}/tx/${txHash}`);
        })
        .on('error', (e: Error) => {
          if (e.toString().includes('invalid address')) {
            setState((s) => ({
              ...s,
              errorMessage: 'Please enter a valid ethereum address',
              userUpdatedEthereumAddress: '',
              isSubmitting: false,
              disableConfirmDelgation: false,
            }));
          } else {
            setState((s) => ({
              ...s,
              disableConfirmDelgation: false,
              isSubmitting: false,
              showDelegationModal: false,
              userUpdatedEthereumAddress: '',
            }));
          }
        });

      const {
        UpdateDelegateKey: {returnValues},
      } = receipt.events;

      const newDelegateKey = returnValues.newDelegateKey.toLowerCase();
      setState((s) => ({
        ...s,
        disableConfirmDelgation: false,
        isSubmitting: false,
        isSubmittingText: '',
        showDelegationModal: false,
        currentDelegation: newDelegateKey,
        delegationStatus:
          newDelegateKey === connectedAddress
            ? DelegationStatus.DELEGATE_VOTES
            : DelegationStatus.REVOKE_DELEGATION,
        currentStep:
          newDelegateKey === connectedAddress
            ? DelegationStep.TRANSFER_DELEGATION
            : DelegationStep.REVOKE_DELEGATION,
      }));

      dispatch(getConnectedMemberFromSmartContract());
    } catch (error) {
      setError(
        new Error(
          'Addresses that are existing delegates or members cannot be used.'
        )
      );
      setState((s) => ({
        ...s,
        userUpdatedEthereumAddress: '',
        isSubmitting: false,
        disableConfirmDelgation: false,
      }));
    }
  }

  function handleRequestClose() {
    setState((s) => ({
      ...s,
      showDelegationModal: false,
      errorMessage: '',
    }));
    setError(undefined);
  }

  function handleUpdateDelegateState(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const {value} = event.currentTarget;
    setState((s) => ({
      ...s,
      userUpdatedEthereumAddress: value,
    }));
  }

  function renderTransferDelegation() {
    return (
      <>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">Delegate</h2>
        </div>

        <p>Transfer your voting rights:</p>
        <p className={mi['arrow-down']}>&darr;</p>
        <p
          className={`${mi['error-message']} org-error-message`}
          style={{marginBottom: '15px'}}>
          {state.errorMessage || error?.message}
        </p>
        {/* <p className="error-message org-error-message">{}</p> */}

        <input
          onChange={(event) => handleUpdateDelegateState(event)}
          placeholder="Enter delegate ethereum address"
          type="text"
          style={{marginBottom: '1rem'}}
        />

        <Checkbox
          className={`${mi['delegation-checkbox']} org-delegation-checkbox`}
          id={'confirm'}
          label={`Confirm delegation to the above address. You can
          revoke this at any time from your profile.`}
          checked={state.confirmDelgation === true}
          disabled={state.disableConfirmDelgation}
          name={'confirm'}
          onChange={handleOnChange}
        />

        <button
          style={{marginTop: '1.5rem'}}
          className={`${b['mini-modal-button']} org-mini-modal-button`}
          disabled={state.isSubmitting || !state.confirmDelgation}
          onClick={
            !state.confirmDelgation
              ? () => {}
              : (event) => {
                  handleConfirmDelegation();
                }
          }>
          {state.isSubmitting ? (
            <Loader text={state.isSubmittingText} />
          ) : (
            'Confirm'
          )}
        </button>

        <p className="org-notification info" style={{padding: '0 1rem'}}>
          You&rsquo;ll be prompted in your applicable wallet.
        </p>

        {/* ETHERSCAN URL */}
        {etherscanURL && (
          <small>
            <a href={etherscanURL} rel="noopener noreferrer" target="_blank">
              (view progress)
            </a>
          </small>
        )}
      </>
    );
  }

  function renderRevokeDelegation() {
    return (
      <>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">
            Remove Delegation
          </h2>
        </div>

        <section className={mi['revoke-delegate']}>
          <p>
            <UserSVG
              fromGradient={orgGradientColorFrom}
              toGradient={orgGradientColorTo}
            />{' '}
            {formatEthereumAddress(state.currentDelegation, 12)}
          </p>
          <p className={mi['arrow-down']}>&darr;</p>
          <p>
            <UserSVG
              fromGradient={orgGradientColorFrom}
              toGradient={orgGradientColorTo}
            />{' '}
            Back to you
          </p>
        </section>

        <small
          className={`${mi['revoke-delegate-small']} org-revoke-delegate-small`}>
          You'll be able to resume voting from your account. You can delegate to
          any non-member ethereum adddress when you choose to.
        </small>

        <button
          className={`${b['mini-modal-button']} org-mini-modal-button`}
          onClick={handleConfirmDelegation}
          disabled={state.isSubmitting}>
          {state.isSubmitting ? (
            <Loader text={state.isSubmittingText} />
          ) : (
            'Confirm'
          )}
        </button>

        <p className="org-notification info" style={{padding: '0 1rem'}}>
          You&rsquo;ll be prompted in your applicable wallet.
        </p>

        {/* ETHERSCAN URL */}
        {etherscanURL && (
          <small>
            <a href={etherscanURL} rel="noopener noreferrer" target="_blank">
              (view progress)
            </a>
          </small>
        )}
      </>
    );
  }

  function renderCurrentStep() {
    return steps[state.currentStep]();
  }

  return (
    <>
      {state.delegationStatus !== '' && (
        <>
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            disabled={state.isDelegate || state.isSubmitting}
            className={`${mi.button} ${mi['action__button']} org-member-info-button`}
            onClick={handleShowDelegationModal}>
            <span
              style={{
                display: 'inline-block',
              }}>
              {state.isSubmitting && (
                <Loader
                  style={{
                    height: '10px',
                    marginRight: '10px',
                    width: '10px',
                  }}
                />
              )}
            </span>
            <span>{state.delegationStatus}</span>
          </button>

          {/* CURRENT DELEGATION */}
          {state.currentDelegation !== connectedAddress &&
            !state.currentDelegation.startsWith('0x00000') && (
              <>
                <br />
                <small
                  className={`${mi['voting-delegated']} org-voting-delegated`}>
                  Voting delegated to:{' '}
                  {formatEthereumAddress(state.currentDelegation)}
                </small>
              </>
            )}

          {/* ETHERSCAN URL */}
          {etherscanURL && state.isSubmitting && (
            <p>
              <small>
                <a
                  href={etherscanURL}
                  rel="noopener noreferrer"
                  target="_blank">
                  (view progress)
                </a>
              </small>
            </p>
          )}

          <div
            style={{
              textAlign: 'left',
              display: 'block',
              margin: '0 auto',
            }}>
            <p className="error-message org-error-message">{error?.message}</p>
          </div>
        </>
      )}

      <ReactModal
        role="dialog"
        isOpen={state.showDelegationModal}
        onRequestClose={handleRequestClose}
        style={{overlay: {zIndex: '99'}, content: {maxWidth: '32.5rem'}} as any}
        ariaHideApp={false}
        overlayClassName={`${m['modal-overlay']} org-modal-overlay`}
        className={`${m['modal-content-wide']}`}>
        <FadeIn>
          <div
            className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} org-modal`}>
            <div className={`${sm.sales} ${m['modal-title']} card`}>
              {renderCurrentStep()}
            </div>
          </div>
        </FadeIn>
      </ReactModal>
    </>
  );
}
