import React, {useState, useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import ReactModal from 'react-modal';

import {getOrgText, formatEthereumAddress} from '../../../../util/helpers';
import {ETHERSCAN_URLS} from '../../../../util/config';
import {StoreState} from '../../../../util/types';
import {Vote} from '../../../../util/enums';
import {VoteContext} from '../MolochVoting';
import FadeIn from '../../../../components/common/FadeIn';
import Loader from '../../../../components/feedback/Loader';
import ProposalActionWhyDisabled from '../../ProposalDisabledReason';
import VoteConfirmViews from './VoteConfirmViews';
import Delegation from '../../../members/Delegation';

import s from '../../../../assets/scss/modules/sale.module.scss';
import m from '../../../../assets/scss/modules/modal.module.scss';
import v from '../../../../assets/scss/modules/voting.module.scss';

export default function SubmitVoteYesNo() {
  const {
    getMemberProposalVote,
    setRefreshCurrentVotes,
    isVotingReady,
    vote,
    hasVoted,
    hasExpired,
  } = useContext(VoteContext);

  /**
   * Selectors
   */

  const connectedMember = useSelector((s: StoreState) => s.connectedMember);
  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.connectedAddress
  );
  const chainId = useSelector(
    (state: StoreState) => state.blockchain.defaultChain
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  const getText = getOrgText(orgText);
  const orgLoaderTextColor = getText('OrgLoaderTextColor');

  /**
   * State
   */

  const [etherscanURL, setEtherscanURL] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [initiateVote, setInitiateVote] = useState<Vote>();
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);
  const [shouldPreventModalClose, setShouldPreventModalClose] =
    useState<boolean>(false);

  /**
   * Variables
   */

  const disabledReasonVoting = !isVotingReady ? 'Voting is not ready.' : '';

  const isDelegatedMember =
    connectedMember.isMemberActive &&
    connectedMember.delegateKey.toLowerCase() !== connectedAddress;

  const delegationInfoText = isDelegatedMember
    ? `Your vote is currently delegated to ${formatEthereumAddress(
        connectedMember.delegateKey
      )}`
    : `You can optionally delegate your vote to another address`;

  /**
   * Effects
   */

  useEffect(() => {
    getMemberProposalVote();
  });

  useEffect(() => {
    if (hasVoted) {
      setIsSubmitting(false);
    }
  }, [hasVoted]);

  /**
   * Functions
   */

  function handleVoteModalClose() {
    getMemberProposalVote();
    setShouldShowModal(false);
    if (!etherscanURL) {
      setIsSubmitting(false);
    }
  }

  // @note handles onComplete
  function handleGetCurrentVotes() {
    getMemberProposalVote();
    setRefreshCurrentVotes(true);
    setIsSubmitting(false);
  }

  function preventModalClose(preventClose: boolean) {
    setShouldPreventModalClose(preventClose);
  }

  return (
    <>
      {!hasExpired && (
        <ProposalActionWhyDisabled
          otherReasons={[disabledReasonVoting]}
          render={({
            canShowDisabledReason,
            isDisabled,
            openWhyDisabledModal,
            WhyDisabledModal,
          }) => (
            <>
              <div
                className={v['voting-button-group']}
                key={'voting-button-group'}>
                <button
                  data-vote={Vote.Yes}
                  onClick={() => {
                    setIsSubmitting(true);
                    setShouldShowModal(true);
                    setInitiateVote(Vote.Yes);
                  }}
                  disabled={hasVoted || isDisabled || isSubmitting}
                  className={`${v['voting-button']} org-voting-button ${
                    vote === 1 && `${v['selected']} org-selected`
                  } ${hasVoted && vote === 1 && v['voted']}`}>
                  {isSubmitting && initiateVote === Vote.Yes ? (
                    <Loader
                      text={'Processing\u2026'}
                      textProps={{
                        style: {color: orgLoaderTextColor},
                      }}
                    />
                  ) : (
                    'Vote yes'
                  )}
                </button>

                <button
                  data-vote={Vote.No}
                  onClick={() => {
                    setIsSubmitting(true);
                    setShouldShowModal(true);
                    setInitiateVote(Vote.No);
                  }}
                  disabled={hasVoted || isDisabled || isSubmitting}
                  className={`${v['voting-button']} org-voting-button ${
                    vote === 2 && `${v['selected']} org-selected`
                  } ${hasVoted && vote === 2 && v['voted']}`}>
                  {isSubmitting && initiateVote === Vote.No ? (
                    <Loader
                      text={'Processing\u2026'}
                      textProps={{
                        style: {color: orgLoaderTextColor},
                      }}
                    />
                  ) : (
                    'Vote no'
                  )}
                </button>
              </div>

              {canShowDisabledReason && (
                <div style={{paddingBottom: '1em'}}>
                  <span
                    className={v['why--tooltip']}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                      event.preventDefault();
                      openWhyDisabledModal();
                    }}>
                    Why is voting disabled?
                  </span>
                </div>
              )}

              <WhyDisabledModal />

              {/* ETHERSCAN URL */}
              {etherscanURL && (
                <p className="text-center">
                  <small>
                    <a
                      href={etherscanURL}
                      rel="noopener noreferrer"
                      target="_blank">
                      {isSubmitting ? '(view progress)' : '(view transaction)'}
                    </a>
                  </small>
                </p>
              )}

              {/* DELEGATION BUTTON */}
              {(!isDisabled || !isSubmitting || !hasVoted) &&
                connectedMember.isMemberActive && (
                  <div className="org-delegate-voting-container">
                    <small
                      className={`${v['delegate-info-small']} text-center`}>
                      {delegationInfoText}
                    </small>
                    <Delegation />
                  </div>
                )}
            </>
          )}
        />
      )}

      {shouldShowModal && initiateVote && (
        <ReactModal
          key={'vote-modal'}
          ariaHideApp={false}
          className={`${m['modal-content-wide']}`}
          isOpen={shouldShowModal}
          onRequestClose={
            shouldPreventModalClose ? () => {} : handleVoteModalClose
          }
          overlayClassName={`${m['modal-overlay']} org-modal-overlay`}
          role="dialog"
          style={
            {overlay: {zIndex: '99'}, content: {maxWidth: '32.5rem'}} as any
          }>
          <FadeIn>
            <div className={`${s.wrap} ${s.gradient} ${s.modalWrap} org-modal`}>
              <div className={`${s.sales} ${m['modal-title']} card`}>
                {/* VOTE CONFIRM BUTTON & PAGES */}
                <VoteConfirmViews
                  getCurrentVotes={handleGetCurrentVotes}
                  initiateVote={initiateVote}
                  onRequestClose={handleVoteModalClose}
                  onProcessingVote={(txHash: string) => {
                    setEtherscanURL(`${ETHERSCAN_URLS[chainId]}/tx/${txHash}`);
                    setIsSubmitting(true);
                  }}
                  preventModalClose={preventModalClose}
                />
              </div>
            </div>
          </FadeIn>
        </ReactModal>
      )}
    </>
  );
}
