import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useQuery} from '@apollo/react-hooks';
import {StoreState} from '../../../util/types';
import {CheckStatus} from './index';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import {GET_MOLOCH} from '../../../gql';
// import {FetchStatus} from '../../../util/enums';

export default function SubgraphChecks() {
  // const {processReadyMap, setProcessReadyMapState} = useContext<
  //   HealthcheckContextValue
  // >(HealthcheckContext);

  const contractMolochAddress = useSelector(
    (s: StoreState) => s.org && s.org.contractMolochAddress
  );
  const graphURL = useSelector((s: StoreState) => s.org && s.org.graphURL);

  const [error, setError] = useState<Error>();
  const [isResponding, setIsResponding] = useState<boolean>(false);

  const getMoloch = useQuery(GET_MOLOCH, {
    variables: {id: contractMolochAddress},
  });

  const getSubgraphCallback = useCallback(getSubgraph, [getMoloch]);

  // @todo if all checks are done `processCompleteMap`, then send back to context

  useEffect(() => {
    if (!getMoloch.loading) {
      getSubgraphCallback();
    }
  }, [getMoloch.loading, getSubgraphCallback]);

  function getSubgraph() {
    try {
      if (!getMoloch.loading && getMoloch.data) {
        setIsResponding(true);
      } else {
        if (getMoloch.error) {
          throw new Error(getMoloch.error.message);
        }
      }
    } catch (error) {
      setError(error);
    }
  }

  return (
    <div>
      <h2>Subgraph</h2>{' '}
      <button onClick={() => getMoloch.refetch()}>Run checks</button>
      <p>
        <CheckStatus checkStatus={graphURL !== ''} /> Graph URL: {graphURL}
      </p>
      <p>
        <CheckStatus checkStatus={isResponding} /> Subgraph status:{' '}
        {String(isResponding)}
      </p>
      <ul>
        {getMoloch.data &&
          getMoloch.data.moloches.map((m: Record<string, any>) =>
            Object.entries(m).map(([key, val]) => (
              <li key={`moloches-${key}`}>
                {key}: {val}
              </li>
            ))
          )}
      </ul>
      <div
        style={{
          textAlign: 'center',
          maxWidth: 600,
          display: 'block',
          margin: '0 auto',
        }}>
        <ErrorMessageWithDetails error={error} renderText="" />
      </div>
    </div>
  );
}
