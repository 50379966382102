import React from 'react';

export default function CopySVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="177"
      height="205"
      viewBox="0 0 177 205"
      {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="bevel"
        strokeWidth="1">
        <g stroke="#939393" strokeWidth="16" transform="translate(16 8)">
          <rect width="129" height="158" x="-8" y="31" rx="15"></rect>
          <path d="M54 0h85.622C147.01 0 153 6.016 153 13.438V129"></path>
        </g>
      </g>
    </svg>
  );
}
