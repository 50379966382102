import React, {useState, useRef, useEffect, useCallback} from 'react';

/**
 * @todo Possibility to change the rendering logic func via prop?
 */

type Errors = Record<string, string>;
type Values = Record<string, any>;
type CanRender = Record<string, boolean>;
/**
 * @note Setting values type to `any` so user can set the type of argument.
 *   Could use a <T> generic on this type, but makes other code more complicated.
 */
type ConditionFunc = (values: any) => boolean;

export type FieldList<FieldName extends string = string> = {
  alwaysRender?: boolean;
  conditions?: ConditionFunc[];
  shouldIgnoreEmpty?: (values: Values) => boolean;
  shouldNotSubmitValue?: (values: Values) => boolean;
  fieldName: FieldName;
}[];

type FieldRevealProps = {
  /**
   * An object of errors with a key matching `FieldList.fieldName`
   * and a value as an error message string,
   */
  fieldErrors: Errors;
  /**
   * An object with multiple, possible keys which help to determine
   * the field's visibility. The key `fieldName` is required.
   *
   * `alwaysRender? {boolean}` - Always renders a field, if `true`.
   *
   * `conditions? {(v: Values) => boolean[]}` - Conditions for the field's display.
   *   An array of functions which must return a boolean.
   *
   * `shouldIgnoreEmpty? {(v: Values) => boolean}` - Should the field be exempt from checks
   *   if it has an empty value? A function which must return a boolean.
   *
   * `shouldNotSubmitValue?: (v: Values) => boolean` - Should the field be exempt from
   *   `isSubmitReady` checks?
   *
   * `fieldName: string | any` - The name of the field.
   */
  fieldList: FieldList;
  /**
   * An object of values with a key matching `FieldList.fieldName`
   * and a value as any value,
   */
  fieldValues: Values;
  /**
   * Calls a callback function with `FieldRevealRenderProps`
   * which should return a `ReactElement`.
   */
  render: (p: FieldRevealRenderProps) => React.ReactElement;
};

type FieldRevealRenderProps = {
  canRender: CanRender;
  handleScrollToMiddle: (
    e: React.ChangeEvent<HTMLElement> | React.FocusEvent<HTMLElement>
  ) => void;
  isSubmitReady: boolean;
};

/**
 * Logic for revealing form fields progressively as a form is completed.
 * Components are rendered via the `render` prop. The `render` prop is
 * passed `FieldRevealRenderProps`.
 *
 * @param {FieldRevealProps} props
 */
export default function FieldReveal(props: FieldRevealProps) {
  const [canRender, setCanRender] = useState<CanRender>({});
  const [isSubmitReady, setIsSubmitReady] = useState<boolean>(false);

  const fieldsDisplayed = useRef<CanRender>();

  const maybeRenderFieldCached = useCallback(maybeRenderField, []);
  const maybeSetIsSubmitReadyCached = useCallback(maybeSetIsSubmitReady, []);

  const {fieldErrors, fieldList, fieldValues} = props;

  useEffect(() => {
    const canRenderFields = fieldList.reduce((acc, next) => {
      acc[next.fieldName] = maybeRenderFieldCached(next.fieldName, props);

      return acc;
    }, {} as CanRender);

    setCanRender((state) => ({
      ...state,
      ...canRenderFields,
    }));
  }, [fieldErrors, fieldList, fieldValues, maybeRenderFieldCached, props]);

  useEffect(() => {
    const isReady = maybeSetIsSubmitReadyCached(props);

    setIsSubmitReady(isReady);
  }, [fieldErrors, fieldList, fieldValues, maybeSetIsSubmitReadyCached, props]);

  // Get which fields are displayed so we can reference
  function getFieldDisplayed(fieldName: string): boolean {
    return (
      fieldsDisplayed.current !== undefined &&
      fieldsDisplayed.current[fieldName]
    );
  }

  function handleScrollElementToMiddle(
    event: React.ChangeEvent<HTMLElement> | React.FocusEvent<HTMLElement>
  ) {
    /**
     * @note Safari will scroll element to top,
     *   because it does not support the option param, and neither does IE.
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView#Browser_compatibility
     */
    event.target.scrollIntoView({
      block: 'center',
    });
  }

  function maybeRenderField(
    fieldName: string,
    props: FieldRevealProps
  ): boolean {
    const {fieldErrors, fieldList, fieldValues} = props;
    // Only fields without conditions, or fields where conditions have passed.
    const fieldListFiltered = fieldList.filter(
      (f) =>
        !f.conditions ||
        (f.conditions && f.conditions.every((c) => c(fieldValues)))
    );
    const fieldFromList = fieldListFiltered.find(
      (f) => f.fieldName === fieldName
    );
    const fieldIndex = fieldListFiltered.findIndex(
      (f) => f.fieldName === fieldName
    );
    const firstError = fieldListFiltered.find(
      (f) =>
        Object.keys(fieldErrors).includes(f.fieldName) &&
        fieldErrors[f.fieldName]
    );
    const firstErrorIndex = firstError
      ? fieldListFiltered.indexOf(firstError)
      : -1;
    const previousField =
      fieldFromList !== undefined && fieldIndex === 0
        ? fieldFromList
        : fieldListFiltered[fieldIndex - 1];

    // Don't show if there's no fieldName in the filtered list.
    if (!fieldFromList) {
      setFieldDisplayed(fieldName, false);

      return false;
    }

    // If the field is the first in the list, always show.
    if (fieldIndex === 0) {
      setFieldDisplayed(fieldName, true);
      return true;
    }

    // If the field has a property `alwaysRender`, show it.
    if (fieldFromList && fieldFromList.alwaysRender) {
      setFieldDisplayed(fieldName, true);
      return true;
    }

    // Show this field if the previous field has `shouldIgnoreEmpty`,
    // has no errors, or has a value, and has been displayed.
    if (
      previousField.shouldIgnoreEmpty &&
      previousField.shouldIgnoreEmpty(fieldValues) &&
      getFieldDisplayed(previousField.fieldName) &&
      (!fieldErrors[previousField.fieldName] ||
        fieldValues[previousField.fieldName])
    ) {
      setFieldDisplayed(fieldName, true);
      return true;
    }

    // Check the previous field if it has no error, a values and has been displayed.
    if (
      !fieldErrors[previousField.fieldName] &&
      fieldValues[previousField.fieldName] &&
      getFieldDisplayed(previousField.fieldName)
    ) {
      setFieldDisplayed(fieldName, true);
      return true;
    }

    // Don't render fields after the immediately previous field which has an error, or no value.
    if (
      fieldIndex > 0 &&
      (fieldErrors[previousField.fieldName] ||
        !fieldValues[previousField.fieldName] ||
        !getFieldDisplayed(previousField.fieldName))
    ) {
      setFieldDisplayed(fieldName, false);
      return false;
    }

    // Don't render field after any field before with an error.
    if (firstErrorIndex !== -1 && fieldIndex > firstErrorIndex) {
      setFieldDisplayed(fieldName, false);
      return false;
    }

    // Default
    return false;
  }

  function maybeSetIsSubmitReady(props: FieldRevealProps): boolean {
    const {fieldErrors, fieldList, fieldValues} = props;

    // Only fields without conditions, or fields where conditions have passed.
    const fieldListFiltered = fieldList.filter(
      (f) =>
        !f.conditions ||
        (f.conditions && f.conditions.every((c) => c(fieldValues)))
    );
    const fieldListFilteredNames = fieldListFiltered.map((f) => f.fieldName);

    // Remove property of values which don't need to be truthy
    // (e.g. blank values allowed)
    const filteredValues = Object.keys(fieldValues).reduce((acc, next) => {
      if (fieldListFilteredNames.includes(next)) {
        acc[next] = fieldValues[next];
      }

      return acc;
    }, {} as Values);

    for (const f of fieldListFiltered) {
      if (f.shouldNotSubmitValue && f.shouldNotSubmitValue(fieldValues)) {
        delete filteredValues[f.fieldName];
      }
      // If the field is optional, and it does not have a value
      if (
        f.shouldIgnoreEmpty &&
        f.shouldIgnoreEmpty(fieldValues) &&
        !filteredValues[f.fieldName]
      ) {
        delete filteredValues[f.fieldName];
      }
    }

    // Remove property of errors which don't need to be truthy
    // (e.g. conditional field not displayed but has error)
    const filteredErrors = (Object.keys(fieldErrors) as string[]).reduce(
      (acc, next) => {
        if (fieldListFilteredNames.includes(next)) {
          acc[next] = fieldErrors[next];
        }

        return acc;
      },
      {} as Errors
    );

    for (const f of fieldListFiltered) {
      if (f.shouldNotSubmitValue && f.shouldNotSubmitValue(fieldValues)) {
        delete filteredErrors[f.fieldName];
      }
      // If the field is optional, and it does not have an error
      if (
        f.shouldIgnoreEmpty &&
        f.shouldIgnoreEmpty(fieldValues) &&
        !filteredErrors[f.fieldName]
      ) {
        delete filteredErrors[f.fieldName];
      }
    }

    const isReady =
      Object.values(filteredValues).every((v) => v) &&
      Object.values(filteredErrors).every((e) => !e);

    return isReady;
  }

  // Set which fields are displayed so we can reference
  function setFieldDisplayed(fieldName: string, isDisplayed: boolean) {
    fieldsDisplayed.current = {
      ...fieldsDisplayed.current,
      [fieldName]: isDisplayed,
    };
  }

  return props.render({
    canRender,
    handleScrollToMiddle: handleScrollElementToMiddle,
    isSubmitReady,
  });
}
