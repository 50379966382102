import {useEffect, useState, useCallback} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {OperationVariables} from '@apollo/client';

import {FetchStatus} from '../util/enums';
import {GET_ALL_CONTRIBUTING_MEMBERS} from '../gql';
import {GQL_QUERY_POLLING_INTERVAL} from '../util/config';
import {useAbortController} from './useAbortController';
import {useKYCBackendURL, usePageVisibility} from '.';

export type VerifedKYCMember = {
  ethereumAddress: string;
  isVerified: boolean;
  isWhitelisted: boolean;
};

type UseKYCContributorsReturn = {
  kycMembers: VerifedKYCMember[];
  kycMembersStatus: FetchStatus;
  molochGQLContributors: Record<string, any> | undefined;
  totalMembersCount: number;
  processedMembersCount: number;
};

/**
 * useKYCContributors
 *
 * Retrieves all the members addresses from The Graph to
 * cross-reference against the verifed members in KYC.
 *
 * @param {OperationVariables?} - Apollo GQL options for `useQuery`. e.g. override polling interval
 * @return {UseKYCContributorsReturn}
 */
export default function useKYCContributors(
  gqlOperationVariables?: OperationVariables
): UseKYCContributorsReturn {
  /**
   * GQL Query
   */

  const getAllContributingMembers = useQuery<Record<string, any>>(
    GET_ALL_CONTRIBUTING_MEMBERS,
    {
      pollInterval: GQL_QUERY_POLLING_INTERVAL,
      ...gqlOperationVariables,
    }
  );

  /**
   * State
   */

  const [totalMembersCount, setTotalMembersCount] = useState<number>(0);
  const [processedMembersCount, setProcessedMembersCount] = useState<number>(0);

  const [kycMembers, setKYCMembers] = useState<VerifedKYCMember[]>([]);
  const [kycMembersStatus, setKYCMembersStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );
  const [molochGQLContributors, setMolochGQLContributors] =
    useState<Record<string, any>>();

  /**
   * Our hooks
   */

  const kycBackendURL = useKYCBackendURL();
  const isPageVisible = usePageVisibility();
  const {abortController, isMountedRef} = useAbortController();

  /**
   * Cached callbacks
   */

  const fetchMembersKYCFromDBCached = useCallback(fetchKYCMembersFromDB, [
    abortController?.signal,
    isMountedRef,
    kycBackendURL,
  ]);

  useEffect(() => {
    if (isPageVisible) {
      getAllContributingMembers.startPolling(GQL_QUERY_POLLING_INTERVAL);
    } else {
      getAllContributingMembers.stopPolling();
    }
  }, [isPageVisible, getAllContributingMembers]);

  useEffect(() => {
    if (!kycBackendURL) return;

    try {
      let contributingAddressees = {};

      if (
        !getAllContributingMembers.loading &&
        getAllContributingMembers.data
      ) {
        // these contributing members are unique
        contributingAddressees =
          getAllContributingMembers.data &&
          (getAllContributingMembers.data.totalMembers.map(
            (contributor: any) => contributor.applicant
          ) as string[]);
      }

      // an object of `processedMembers` and `totalMembers`
      setMolochGQLContributors({
        ...getAllContributingMembers.data,
      });

      if (Object.entries(contributingAddressees).length > 0) {
        fetchMembersKYCFromDBCached(contributingAddressees as string[]);
      } else {
        // Don't block setting the status, if no contributing addresses
        // have been found
        setKYCMembersStatus(FetchStatus.FULFILLED);
      }
    } catch (error) {
      setMolochGQLContributors(undefined);
    }
  }, [getAllContributingMembers, fetchMembersKYCFromDBCached, kycBackendURL]);

  /**
   * We get the total number of unique members from the subgraph; submitted, sponsored, processed
   * and the total number of processed members from the subgraph, which we send to the KYC DB
   * to cross-reference againsted the verified KYC'd members.
   */
  useEffect(() => {
    if (FetchStatus.FULFILLED === kycMembersStatus && kycMembers.length) {
      let totalMemberCount: number = 0;
      let processedMemberCount: number = 0;

      // these are unique KYC members from the db
      kycMembers.forEach((kycMember) => {
        // these are unique `contributors` from the contract
        molochGQLContributors &&
          molochGQLContributors.totalMembers &&
          molochGQLContributors.totalMembers.filter(
            (member: Record<string, any>) =>
              kycMember.ethereumAddress.toLowerCase() ===
                member.applicant.toLowerCase() && totalMemberCount++
          );

        molochGQLContributors &&
          molochGQLContributors.processedMembers &&
          molochGQLContributors.processedMembers.filter(
            (member: Record<string, any>) =>
              kycMember.ethereumAddress.toLowerCase() ===
                member.applicant.toLowerCase() && processedMemberCount++
          );
      });

      setTotalMembersCount(totalMemberCount);
      setProcessedMembersCount(processedMemberCount);
    }
  }, [kycMembers, kycMembersStatus, molochGQLContributors]);

  async function fetchKYCMembersFromDB(contributingAddressees: string[]) {
    try {
      setKYCMembersStatus(FetchStatus.PENDING);

      const formData = new FormData();
      formData.append(
        'ethereumAddresses',
        JSON.stringify(contributingAddressees)
      );

      const response = await fetch(`${kycBackendURL}/kyc/verified`, {
        method: 'POST',
        body: formData,
        signal: abortController?.signal,
      });

      if (!response.ok) {
        throw new Error('Something went wrong while getting the members.');
      }

      if (!isMountedRef.current) {
        return;
      }

      setKYCMembersStatus(FetchStatus.FULFILLED);

      const members = await response.json();

      setKYCMembers(members);
    } catch (error) {
      if (!isMountedRef.current) {
        return;
      }

      setKYCMembersStatus(FetchStatus.REJECTED);
      setKYCMembers([]);
    }
  }

  return {
    kycMembers,
    kycMembersStatus,
    molochGQLContributors,
    totalMembersCount,
    processedMembersCount,
  };
}
