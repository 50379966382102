import {useEffect, useState, useCallback} from 'react';

import {SidePocketProposal} from '../util/types';
import {useBackendURL} from './';
import {FetchStatus} from '../util/enums';

/**
 * useSidePocketProposals
 *
 * @todo Refactor to include data from The Graph.
 *   Then all we would need from the database is anything
 *   we can't get from The Graph.
 */
export default function useSidePocketProposals() {
  const [sidePocketProposals, setSidePocketProposals] = useState<
    SidePocketProposal[]
  >([]);
  const [sidePocketProposalsStatus, setSidePocketProposalsStatus] =
    useState<FetchStatus>(FetchStatus.STANDBY);

  const backendURL = useBackendURL();

  const fetchProjectsFromDBCached = useCallback(fetchProjectsFromDB, [
    backendURL,
  ]);

  useEffect(() => {
    fetchProjectsFromDBCached();
  }, [fetchProjectsFromDBCached]);

  async function fetchProjectsFromDB() {
    try {
      if (!backendURL) return;

      setSidePocketProposalsStatus(FetchStatus.PENDING);

      const response = await fetch(`${backendURL}/sidepocket`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(
          'Something went wrong while getting the sidepocket proposals.'
        );
      }

      setSidePocketProposalsStatus(FetchStatus.FULFILLED);

      const projects = await response.json();

      setSidePocketProposals(projects);
    } catch (error) {
      setSidePocketProposalsStatus(FetchStatus.REJECTED);
      setSidePocketProposals([]);
    }
  }

  return {sidePocketProposals, sidePocketProposalsStatus};
}
