import React from 'react';
import {useSelector} from 'react-redux';

import {getOrgText} from '../../util/helpers';
import {StoreState} from '../../util/types';

import s from '../../assets/scss/modules/emojislider.module.scss';

type EmojiSliderProps = {
  handleChange: any;
  max: string;
  min: string;
  step?: string;
  value: string;
};

export default function EmojiSlider(props: EmojiSliderProps) {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  const getText = getOrgText(orgText);
  const orgGradientColorFrom = getText('OrgGradientColorFrom');
  const orgGradientColorTo = getText('OrgGradientColorTo');

  const emojiList: any = ['😐', '🙂', '😄', '🤑', '😍', '🤩', '🦑', '🐳', '👾'];
  const percent: number = (Number(props.value) / Number(props.max)) * 100;
  const selectedEmojiIndex =
    Math.floor(
      (Number(props.value) / emojiList.length / Number(props.step)) * 10
    ) - 1;

  function getPlacement() {
    return `${-0.004 * (Math.floor(percent / 100) * -1 + 104)}em`;
  }

  function getSliderBackground() {
    return `linear-gradient(to right, ${orgGradientColorFrom}, ${
      percent * -1 + 125
    }%, ${orgGradientColorTo})`;
  }

  return (
    <div
      className={`${s['emoji-slider-container']} org-emoji-slider-container`}>
      <input
        id="emoji-slider"
        type="range"
        min={props.min}
        max={props.max}
        value={props.value}
        className={`${s['emoji-slider']} org-emoji-slider`}
        onChange={props.handleChange}
        step={props.step}
      />

      <div className={s.outer}>
        <label
          style={{width: `${percent}%`, background: getSliderBackground()}}
          htmlFor="emoji-slider"
          aria-hidden="true"
          className={`${s.inner} org-emoji-slider-inner`}>
          <span style={{right: getPlacement()}}>
            {emojiList[selectedEmojiIndex] || emojiList[emojiList.length - 1]}
          </span>
        </label>
      </div>
    </div>
  );
}
