import React from 'react';
import {useSelector} from 'react-redux';
import Web3 from 'web3';

import {
  MetaMaskRPCError,
  StoreState,
  GetInvestmentProposalResponse,
} from '../../../util/types';
import {FetchStatus, InvestmentProposalCurrency} from '../../../util/enums';
import {getOrgText} from '../../../util/helpers';
import {InternalNamesToMap} from '../../../util/orgDomainMappings';
import {useIsPaymentRequestedOK} from '../../../hooks/useIsPaymentRequestedOK';
import {useUSDToWEI} from '../../../hooks/useUSDToWEI';
import DropSVG from '../../../assets/svg/DropSVG';
import EasyApplyProject from '../../../components/contract/EasyApplyProject';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import Loader from '../../../components/feedback/Loader';
import ProposalActionWhyDisabled from '../ProposalDisabledReason';
import StopwatchSVG from '../../../assets/svg/StopwatchSVG';

import s from '../../../assets/scss/modules/votingcard.module.scss';
import v from '../../../assets/scss/modules/voting.module.scss';

type MolochEasyApplyInvestmentProposalProps = {
  onProposalId?: (proposalId: number) => void;
  proposal: GetInvestmentProposalResponse;
};

/**
 * MolochEasyApplyInvestmentProposal
 *
 * This component wraps the EasyApplyProject component.
 */
export default function MolochEasyApplyInvestmentProposal(
  props: MolochEasyApplyInvestmentProposalProps
) {
  const {proposal} = props;

  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  /**
   * Our hooks
   */

  const {WEIFromUSD} = useUSDToWEI(
    proposal.amountRequestedCurrency === InvestmentProposalCurrency.USD
      ? Number(proposal.amountRequested)
      : 0
  );
  const WEIFromETH =
    proposal.amountRequestedCurrency === InvestmentProposalCurrency.ETH
      ? Web3.utils.toWei(proposal.amountRequested, 'ether')
      : '';

  const WEIForPayment = WEIFromUSD || WEIFromETH;

  const {isPaymentRequestedOK, isPaymentRequestedOKStatus} =
    useIsPaymentRequestedOK(WEIForPayment);

  /**
   * Variables
   */

  const getText = getOrgText(orgText);
  const orgLoaderTextColor = getText('OrgLoaderTextColor');
  const orgProjectText = getText('OrgProjectText');

  const disabledReasonPayment =
    isPaymentRequestedOKStatus === FetchStatus.FULFILLED &&
    isPaymentRequestedOK === false
      ? 'There is not enough funds available to meet the payment requested from the proposal.'
      : '';

  /**
   * Functions
   */

  function renderCountdownIcon(): JSX.Element {
    switch (orgInternalName) {
      case InternalNamesToMap.liquidity:
        return <DropSVG />;
      case InternalNamesToMap.nft:
      case InternalNamesToMap.thelao:
      default:
        return <StopwatchSVG />;
    }
  }

  return (
    <>
      <div
        className={`${s['row-w-icon']} ${s['countdown']} org-countdown org-countdown--pending`}>
        {renderCountdownIcon()}
        <span className={`${s['status']} org-status org-status--pending`}>
          Ready for sponsorship
        </span>
      </div>

      <p style={{fontSize: '.8rem', lineHeight: '1.75', marginTop: '2rem'}}>
        Members have the right to sponsor a {orgProjectText} for funding. Once
        sponsored, members will be able to vote on whether the {orgProjectText}{' '}
        should receive funding.
      </p>

      <ProposalActionWhyDisabled
        otherReasons={[disabledReasonPayment]}
        render={({
          canShowDisabledReason,
          isDisabled,
          openWhyDisabledModal,
          WhyDisabledModal,
        }) => (
          <>
            <EasyApplyProject
              applicantAddress={proposal.addressToFund}
              onProposalId={props.onProposalId}
              paymentRequestedWEI={WEIForPayment}
              proposalUUID={proposal.uuid}
              render={({
                error,
                etherscanURL,
                isPromptOpen,
                isSubmitted,
                isSubmitting,
                openPrompt,
              }) => (
                <>
                  <button
                    className={s['submit-proposal-btn']}
                    disabled={isDisabled || isSubmitting || isSubmitted}
                    onClick={
                      isDisabled || isSubmitting || isSubmitted
                        ? () => {}
                        : openPrompt
                    }
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '2rem',
                    }}>
                    {isPromptOpen ? (
                      <Loader
                        text={'Preparing\u2026'}
                        textProps={{
                          style: {color: orgLoaderTextColor},
                        }}
                      />
                    ) : !isPromptOpen && isSubmitting ? (
                      'Sponsoring\u2026'
                    ) : !isPromptOpen && isSubmitted ? (
                      'Sponsored!'
                    ) : (
                      'Sponsor Proposal'
                    )}
                  </button>

                  {etherscanURL && (
                    <p className={`${v['view-progress']} text-center`}>
                      <small>
                        <a
                          href={etherscanURL}
                          rel="noopener noreferrer"
                          target="_blank">
                          (view {isSubmitting ? 'progress' : 'transaction'})
                        </a>
                      </small>
                    </p>
                  )}

                  {/* if error is not a MM window close/cancel code */}
                  {error && (error as MetaMaskRPCError).code !== 4001 && (
                    <div className="text-center">
                      <ErrorMessageWithDetails
                        error={error}
                        renderText={() => (
                          <small>
                            Something went wrong while sponsoring the proposal.
                          </small>
                        )}
                      />
                    </div>
                  )}
                </>
              )}
            />

            {canShowDisabledReason && (
              <div style={{paddingBottom: '1em'}}>
                <span
                  className={v['why--tooltip']}
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    event.preventDefault();
                    openWhyDisabledModal();
                  }}>
                  Why is sponsoring disabled?
                </span>
              </div>
            )}

            <WhyDisabledModal />
          </>
        )}
      />
    </>
  );
}
