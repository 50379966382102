import React from 'react';

import {useKYCContributors} from '../../hooks';

import s from '../../assets/scss/modules/admin.module.scss';

export default function MemberKYCStatistics() {
  const {totalMembersCount, processedMembersCount} = useKYCContributors();

  return (
    <div className={s['admin-member-counts']}>
      <div>
        <span className={s['member-count']}>{totalMembersCount}</span>
        <span>Total Unique Members</span>
      </div>
      <div>
        <span className={s['member-count']}>{processedMembersCount}</span>
        <span>Total Processed Members</span>
      </div>
    </div>
  );
}
