import React, {useCallback, useEffect, useState} from 'react';
import {BACKEND_URL} from '../../../util/config';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';

import {CheckStatus} from './index';
/**
 * TODOs
 * - ping lao server
 */
export default function LAODBChecks() {
  const [isEndpointUP, setIsEndpointUP] = useState<boolean>(false);
  const [isDBUP, setIsDBUP] = useState<boolean>(false);
  const [dbError, setDBError] = useState<Error>();
  const [endpointError, setEndpointError] = useState<Error>();

  const checkLAOEndpointCached = useCallback(checkLAOEndpoint, []);
  const checkLAODatabaseCached = useCallback(checkLAODatabase, []);

  useEffect(() => {
    checkLAOEndpointCached();
    checkLAODatabaseCached();
  }, [checkLAOEndpointCached, checkLAODatabaseCached]);

  function reRunChecks() {
    // @todo loader
    checkLAOEndpointCached();
    checkLAODatabaseCached();
  }

  async function checkLAOEndpoint() {
    try {
      const response = await fetch(`${BACKEND_URL}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setIsEndpointUP(response.ok);
    } catch (error) {
      setEndpointError(error);
    }
  }

  async function checkLAODatabase() {
    try {
      const response = await fetch(`${BACKEND_URL}/db`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setIsDBUP(response.ok);
    } catch (error) {
      setDBError(error);
    }
  }

  return (
    <div>
      <h2>LAO Endpoint</h2>
      <button onClick={reRunChecks}>Run checks</button>
      <p>LAO Backend URL: {BACKEND_URL}</p>
      <p>LAO ENV VAR: BACKEND_URL</p>
      {/**
       * 1. Check LAO Endpoint
       */}
      <div>
        <p>
          <CheckStatus checkStatus={isEndpointUP} /> Endpoint is available:{' '}
          {String(isEndpointUP)}
        </p>
      </div>
      <div
        style={{
          textAlign: 'center',
          maxWidth: 600,
          display: 'block',
          margin: '0 auto',
        }}>
        <ErrorMessageWithDetails error={endpointError} renderText="" />
      </div>
      {/**
       * 2. Check LAO Database connection
       */}
      <div>
        <p>
          <CheckStatus checkStatus={isDBUP} /> Database Connection is UP:{' '}
          {String(isDBUP)}
        </p>
      </div>
      <div
        style={{
          textAlign: 'center',
          maxWidth: 600,
          display: 'block',
          margin: '0 auto',
        }}>
        <ErrorMessageWithDetails error={dbError} renderText="" />
      </div>
    </div>
  );
}
