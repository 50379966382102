import React from 'react';
import {useSelector} from 'react-redux';

import {
  SnapshotProposalSubType,
  SnapshotProposalType,
} from '../../../components/snapshot/enums';
import {
  GetInvestmentProposalResponse,
  MetaMaskRPCError,
  StoreState,
} from '../../../util/types';
import {FetchStatus} from '../../../util/enums';
import {getOrgText} from '../../../util/helpers';
import {InternalNamesToMap} from '../../../util/orgDomainMappings';
import {SnapshotGetFormValuesReturn} from '../../../components/snapshot/types';
import {useBackendURL} from '../../../hooks';
import DropSVG from '../../../assets/svg/DropSVG';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import Loader from '../../../components/feedback/Loader';
import ProposalActionWhyDisabled from '../ProposalDisabledReason';
import StopwatchSVG from '../../../assets/svg/StopwatchSVG';
import useCreateSnapshotProposal from '../../../components/snapshot/useCreateSnapshotProposal';

import s from '../../../assets/scss/modules/votingcard.module.scss';
import v from '../../../assets/scss/modules/voting.module.scss';

type CreateSnapshotInvestmentProposalProps = {
  onSnapshotCreated: (hash: string) => void;
  proposal: GetInvestmentProposalResponse;
};

/**
 * CreateSnapshotInvestmentProposal
 *
 * Provides a sponsorship UI for investment proposals.
 * It's not actually "sponsoring" in the Moloch sense.
 * It is creating the Snapshot Hub proposal and therefore
 * starting the vote.
 *
 * 1) Open a wallet prompt for signature.
 * 2) Submit signature & data to the Snapshot Hub.
 *
 * @param {CreateSnapshotInvestmentProposalProps} props
 */
export default function CreateSnapshotInvestmentProposal(
  props: CreateSnapshotInvestmentProposalProps
) {
  const {proposal, onSnapshotCreated} = props;

  /**
   * Selectors
   */

  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const molochVotingPeriodLength = useSelector(
    (s: StoreState) =>
      s.blockchain.molochConstants &&
      s.blockchain.molochConstants.votingPeriodLength
  );
  const molochPeriodDuration = useSelector(
    (s: StoreState) =>
      s.blockchain.molochConstants &&
      s.blockchain.molochConstants.periodDuration
  );
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  /**
   * Our hooks
   */

  const {
    createSnapshotProposal,
    snapshotProposalSignStatus,
    snapshotProposalSubmitStatus,
    snapshotProposalSubmitError,
  } = useCreateSnapshotProposal();

  const backendURL = useBackendURL();

  /**
   * Variables
   */

  const getText = getOrgText(orgText);
  const orgLoaderTextColor = getText('OrgLoaderTextColor');
  const orgProjectText = getText('OrgProjectText');

  const isInProcessOrDone =
    snapshotProposalSignStatus === FetchStatus.PENDING ||
    snapshotProposalSubmitStatus === FetchStatus.PENDING ||
    (snapshotProposalSignStatus === FetchStatus.FULFILLED &&
      snapshotProposalSubmitStatus === FetchStatus.FULFILLED);

  /**
   * Functions
   */

  function getValues(
    snapshotProposalDB: GetInvestmentProposalResponse
  ): SnapshotGetFormValuesReturn {
    const {
      addressToFund,
      name,
      description,
      private: privacy,
      uuid,
    } = snapshotProposalDB;
    // Use the default voting length from Moloch
    const voteLengthSeconds =
      (molochVotingPeriodLength || 0) * (molochPeriodDuration || 0);

    return {
      // @todo Change types as this doesn't get used by Snapshot Hub. It's only meant for DB.
      addressToFund,
      name,
      body: description,
      // This is not submitted directly but used to compute the voting `end` seconds.
      voteLengthSeconds,
      metadata: {
        /**
         * @note We use the value from the DB for investment snapshot proposals to determine privacy.
         * Even though we cannot change the actual Snapshot value once submitted,
         * we could rely upon it at some point.
         */
        private: privacy,
        subType: SnapshotProposalSubType.General,
        type: SnapshotProposalType.Investment,
        // Provide the UUID so we have a reference to the DB row.
        uuid,
      },
    };
  }

  async function handleSponsorSnapshotProposal() {
    try {
      const {uuid} = proposal;

      const ipfsHash = await createSnapshotProposal(
        getValues(proposal),
        `${backendURL}/proposals-investment/${uuid}/create-snapshot`
      )();

      ipfsHash && onSnapshotCreated(ipfsHash);
    } catch (error) {}
  }

  function renderCountdownIcon(): JSX.Element {
    switch (orgInternalName) {
      case InternalNamesToMap.liquidity:
        return <DropSVG />;
      case InternalNamesToMap.nft:
      case InternalNamesToMap.thelao:
      default:
        return <StopwatchSVG />;
    }
  }

  return (
    <>
      {/* SPONSOR PROPOSAL */}
      <div
        className={`${s['row-w-icon']} ${s['countdown']} org-countdown org-countdown--pending`}>
        {renderCountdownIcon()}

        <span className={`${s['status']} org-status org-status--pending`}>
          Ready for sponsorship
        </span>
      </div>

      <p style={{fontSize: '.8rem', lineHeight: '1.75', marginTop: '2rem'}}>
        Members have the right to sponsor a {orgProjectText} for funding. Once
        sponsored, members will be able to vote on whether the {orgProjectText}{' '}
        should receive funding.
      </p>

      <ProposalActionWhyDisabled
        render={({
          canShowDisabledReason,
          isDisabled,
          openWhyDisabledModal,
          WhyDisabledModal,
        }) => (
          <>
            {/* SUBMIT */}
            <button
              disabled={isDisabled || isInProcessOrDone}
              onClick={
                isDisabled || isInProcessOrDone
                  ? () => {}
                  : handleSponsorSnapshotProposal
              }
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
              type="submit">
              {snapshotProposalSignStatus === FetchStatus.PENDING ? (
                <Loader
                  text="Preparing&hellip;"
                  textProps={{
                    style: {color: orgLoaderTextColor},
                  }}
                />
              ) : snapshotProposalSubmitStatus === FetchStatus.PENDING ? (
                <Loader
                  text="Processing&hellip;"
                  textProps={{
                    style: {color: orgLoaderTextColor},
                  }}
                />
              ) : snapshotProposalSubmitStatus === FetchStatus.FULFILLED ? (
                'Sponsored!'
              ) : (
                'Sponsor Proposal'
              )}
            </button>

            {/* SUBMIT ERROR */}
            {snapshotProposalSubmitError &&
              (snapshotProposalSubmitError as MetaMaskRPCError).code !==
                4001 && (
                <div className="text-center">
                  <ErrorMessageWithDetails
                    renderText="Something went wrong while submitting the proposal."
                    error={snapshotProposalSubmitError}
                  />
                </div>
              )}

            {canShowDisabledReason && (
              <div style={{paddingBottom: '1em'}}>
                <span
                  className={v['why--tooltip']}
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    event.preventDefault();
                    openWhyDisabledModal();
                  }}>
                  Why is sponsoring disabled?
                </span>
              </div>
            )}

            <WhyDisabledModal />
          </>
        )}
      />
    </>
  );
}
