import React from 'react';

function IncomingSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28">
      <defs>
        <linearGradient id="a" x1="0%" x2="100%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#80D8FF"></stop>
          <stop offset="16%" stopColor="#88D1FF"></stop>
          <stop offset="41.3%" stopColor="#9FBEFE"></stop>
          <stop offset="72.5%" stopColor="#C4A0FD"></stop>
          <stop offset="100%" stopColor="#EA80FC"></stop>
        </linearGradient>
      </defs>
      <g fill="url(#a)" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path d="M14 0C6.282 0 .036 6.262.036 14S6.28 28 14 28c7.718 0 13.964-6.262 13.964-14S21.72 0 14 0zm0 25.813C7.488 25.813 2.217 20.529 2.217 14 2.217 7.471 7.487 2.187 14 2.187c6.512 0 11.783 5.284 11.783 11.813 0 6.529-5.27 11.813-11.783 11.813z"></path>
        <path
          d="M9.109 9.153l-1.732 1.74a1.44 1.44 0 01-2.043 0 1.454 1.454 0 01-.393-1.315H1.444C.647 9.578 0 8.928 0 8.127V1.45C0 .65.647 0 1.444 0c.798 0 1.444.65 1.444 1.451v5.224H4.94c-.092-.46.038-.958.394-1.315a1.44 1.44 0 012.042 0L9.11 7.1a1.46 1.46 0 010 2.053z"
          transform="translate(9.532 7.745)"></path>
      </g>
    </svg>
  );
}

export default IncomingSVG;
