import React, {useEffect, useState} from 'react';

import {chooseRandom, numberRangeArray} from '../../util/helpers';
import {
  ProjectIcon0SVG,
  ProjectIcon1SVG,
  ProjectIcon2SVG,
  ProjectIcon3SVG,
  ProjectIcon4SVG,
  ProjectIcon5SVG,
  ProjectIcon6SVG,
  ProjectIcon7SVG,
  ProjectIcon8SVG,
  ProjectIcon9SVG,
  ProjectIcon10SVG,
} from '../../assets/svg/ProjectIconSVG';

type ProjectIconProps = {
  proposalId?: number;
};

export default function ProjectDefaultIcon(
  props: ProjectIconProps
): JSX.Element {
  const {proposalId} = props;

  /**
   * State
   */

  const [projectDefaultIcon, setProjectDefaultIcon] = useState<JSX.Element>(
    <ProjectIcon10SVG />
  );

  /**
   * Effects
   */

  useEffect(() => {
    const selectedProjectIcon = getProjectSVG(
      typeof proposalId === 'number' && proposalId >= 0
        ? proposalId
        : /**
           * Choose a random number to get an index 0-9 because the
           * `proposalId` does not exist (not yet submitted to Moloch).
           *
           * @note We cannot guarantee that the icon chosen will not be same as the previous
           *   as we don't have an easily reliable way of knowing the previous `orderedProjectIndex`,
           *   or index chosen at random.
           */
          chooseRandom<number>(numberRangeArray(9, 0))
    );

    setProjectDefaultIcon(selectedProjectIcon);
  }, [proposalId]);

  /**
   * Functions
   */

  function getProjectSVG(id: number) {
    if (id > -1) {
      const lastDigit: string = id.toString().slice(-1);

      switch (lastDigit) {
        case '0':
          return <ProjectIcon0SVG />;
        case '1':
          return <ProjectIcon1SVG />;
        case '2':
          return <ProjectIcon2SVG />;
        case '3':
          return <ProjectIcon3SVG />;
        case '4':
          return <ProjectIcon4SVG />;
        case '5':
          return <ProjectIcon5SVG />;
        case '6':
          return <ProjectIcon6SVG />;
        case '7':
          return <ProjectIcon7SVG />;
        case '8':
          return <ProjectIcon8SVG />;
        case '9':
          return <ProjectIcon9SVG />;
        default:
          return <ProjectIcon10SVG />;
      }
    } else {
      return <ProjectIcon10SVG />;
    }
  }

  return projectDefaultIcon;
}
