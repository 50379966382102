import React from 'react';
import {useSelector} from 'react-redux';

import ProposalVoters from './vote/ProposalVoters';
import DropSVG from '../../../assets/svg/DropSVG';
import StopwatchSVG from '../../../assets/svg/StopwatchSVG';
import CircleCheckSVG from '../../../assets/svg/CircleCheckSVG';

import {Proposal, StoreState} from '../../../util/types';
import {InternalNamesToMap} from '../../../util/orgDomainMappings';

import s from '../../../assets/scss/modules/votingcard.module.scss';

type MolochInvestmentProposalFundedProps = {
  molochProposal: Proposal | Record<string, any>;
};

export default function MolochInvestmentProposalFunded({
  molochProposal,
}: MolochInvestmentProposalFundedProps) {
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  function renderCountdownIcon(): JSX.Element {
    switch (orgInternalName) {
      case InternalNamesToMap.liquidity:
        return <DropSVG />;
      case InternalNamesToMap.metaverse:
        return <CircleCheckSVG />;
      case InternalNamesToMap.nft:
      case InternalNamesToMap.thelao:
      default:
        return <StopwatchSVG />;
    }
  }

  return (
    <>
      <div
        className={`${s['row-w-icon']} ${s['countdown']} org-countdown org-countdown--success`}>
        {renderCountdownIcon()}
        <span className={`${s['status']} org-status`}>Funded</span>
      </div>

      <ProposalVoters proposal={molochProposal} />
    </>
  );
}
