import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

// import FakePieDAO from '../../../truffle-contracts/FakePieDAO.json';

import {useBackendURL} from '../../../hooks';

import {StoreState, MetaMaskRPCError} from '../../../util/types';
import {
  formatNumber,
  stripFormatNumber,
  getOrgText,
} from '../../../util/helpers';
import {hasValue, isNumberString, isURLValid} from '../../../util/validation';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import ETHPrice from '../../../components/contract/ETHPrice';
import FadeIn from '../../../components/common/FadeIn';
import Wrap from '../../../components/layout/Wrap';
import InputError from '../../../components/common/InputError';
import Loader from '../../../components/feedback/Loader';

import MinionProposeAction, {
  ProposeActionArguments,
} from '../../../components/contract/MinionProposeAction';

import b from '../../../assets/scss/modules/buttons.module.scss';
import fs from '../../../assets/scss/modules/formsteps.module.scss';
import i from '../../../assets/scss/modules/input.module.scss';

enum FieldNameEnum {
  addressToFund = 'addressToFund',
  contactEmail = 'contactEmail',
  contactName = 'contactName',
  contactTitle = 'contactTitle',
  description = 'description',
  documentURLs = 'documentURLs',
  fundingAmountRequestedUSD = 'fundingAmountRequestedUSD',
  minionActionAddress = 'minionActionAddress',
  minionActionData = 'minionActionData',
  name = 'name',
  projectURL = 'projectURL',
}

enum SubmissionStep {
  SAVE_PROPOSAL = 'saveProposal',
  SUBMIT_PROPOSAL = 'submitProposal',
}

type Steps = 'saveProposal' | 'submitProposal';

type Values = {
  addressToFund: string;
  contactEmail: string;
  contactName: string;
  contactTitle: string;
  description: string;
  documentURLs: string;
  fundingAmountRequestedUSD: string;
  minionActionAddress: string;
  minionActionData: string;
  name: string;
  projectURL: string;
};

type State = {
  errors: {
    [N in FieldNameEnum]: string;
  };
  errorSubmit: string;
  errorObjectSubmit: Error | undefined;
  fieldsTouched: Array<FieldNameEnum>;
  isReadyForSubmit: boolean;
  isSubmitting: boolean;
  isSubmittingText: string;
  isValidatingProposal: boolean;
  etherscanURL: string;
  molochDepositTokenAddress: string;
  projectId: string | null;
  projectUUID: string | null;
  values: Values;
};

const requestDataTransformers: Partial<
  Record<FieldNameEnum & Values, (value: any, allValues: Values) => any>
> = {
  // convert string URLs to Array
  documentURLs: (value: string): Array<string> =>
    value.split(/\n|\s/).filter((u) => u),
  // Strip number of comma formatting
  fundingAmountRequestedUSD: (value: string) =>
    Number(stripFormatNumber(value)),
};

const handleValidateURL = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const possibleIsURLValidError =
    value === '' ? '' : isURLValid(value) ? '' : ERROR_URL_INVALID;

  return {
    errorMessage: possibleIsURLValidError,
    isError: possibleIsURLValidError.length > 0,
  };
};

const handleValidateURLMultiple = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const URLValues = value.split(/\n|\s/);
  const possibleHasValueError = hasValue(value) ? '' : ERROR_REQUIRED_FIELD;
  const validationResult = URLValues.filter((r) => r).every(isURLValid);
  const possibleIsURLValidError = validationResult ? '' : ERROR_URLS_INVALID;

  return {
    errorMessage: possibleHasValueError || possibleIsURLValidError,
    isError: (possibleHasValueError || possibleIsURLValidError).length > 0,
  };
};

const handleValidateExists = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const possibleHasValueError = hasValue(value) ? '' : ERROR_REQUIRED_FIELD;

  return {
    errorMessage: possibleHasValueError,
    isError: possibleHasValueError.length > 0,
  };
};

const handleValidateFundingAmount = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const valueCleaned = stripFormatNumber(value.trim());
  const possibleNonNumberError = isNumberString(valueCleaned)
    ? ''
    : ERROR_NUMBER_STRING_INVALID;
  const possibleLowValueError =
    Number(valueCleaned) > 0 ? '' : ERROR_NUMBER_TOO_LOW;

  return {
    errorMessage: possibleNonNumberError || possibleLowValueError,
    isError: (possibleNonNumberError || possibleLowValueError).length > 0,
  };
};

/**
 * displayTransformers
 *
 * Formats a value before setting it in state.
 */
const displayTransformers: Partial<
  Record<FieldNameEnum, (value: any) => string>
> = {
  fundingAmountRequestedUSD: (value: string) => formatNumber(value.trim()),
};

const ERROR_REQUIRED_FIELD = 'Members need this information.';
const ERROR_NUMBER_TOO_LOW = 'The amount should be at least 1.';
const ERROR_NUMBER_STRING_INVALID = 'Value should be a number.';
const ERROR_URLS_INVALID = 'A URL is invalid.';
const ERROR_URL_INVALID = 'The URL is invalid.';

export default function MinionForm() {
  /**
   * Hooks state
   */

  const history = useHistory();
  const backendURL = useBackendURL();

  /**
   * Selectors
   */

  const web3Instance = useSelector(
    (state: StoreState) => state.blockchain && state.blockchain.web3Instance
  );
  const contractMinionAddress =
    useSelector(
      (state: StoreState) => state.org && state.org.contractMinionAddress
    ) || '';
  // const contractMinionActionAddress =
  //   useSelector(
  //     (state: StoreState) => state.org && state.org.contractMinionActionAddress
  //   ) || '';
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  const getText = getOrgText(orgText);
  const orgSubmitProposalButtonText = getText('OrgSubmitProposalButtonText');
  const orgFundingAmountFieldPlaceholder = getText(
    'OrgFundingAmountFieldPlaceholder'
  );
  const orgPrimaryContactEmail = getText('OrgPrimaryContactEmail');
  const orgName = getText('OrgName');

  const INITIAL_STATE: State = {
    errors: {
      addressToFund: '',
      contactEmail: '',
      contactName: '',
      contactTitle: '',
      description: '',
      documentURLs: '',
      fundingAmountRequestedUSD: '',
      minionActionAddress: '',
      minionActionData: '',
      name: '',
      projectURL: '',
    },
    errorSubmit: '',
    errorObjectSubmit: undefined,
    fieldsTouched: [],
    isReadyForSubmit: false,
    isSubmitting: false,
    isSubmittingText: '',
    isValidatingProposal: false,
    etherscanURL: '',
    molochDepositTokenAddress: '',
    projectId: null,
    projectUUID: null,
    values: {
      addressToFund: contractMinionAddress,
      contactEmail: orgPrimaryContactEmail || 'hello@openlaw.io',
      contactName: 'Openlaw',
      contactTitle: 'Openlaw operated minion action',
      description: '',
      documentURLs: '',
      fundingAmountRequestedUSD: '',
      minionActionAddress: '',
      minionActionData: '0x0',
      name: '',
      projectURL: '',
    },
  };

  const nameRef: React.RefObject<HTMLInputElement> = React.createRef();
  const [state, setState] = useState<State>(INITIAL_STATE);
  const [isSubmitReady, setIsSubmitReady] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<Steps>(
    SubmissionStep.SAVE_PROPOSAL
  );

  useEffect(() => {
    const maybeValues = Object.values(state.values).filter(
      (s) => s !== undefined
    );
    const isReady =
      maybeValues.every((v) => v) &&
      Object.values(state.errors).every((e) => !e);
    setIsSubmitReady(isReady);
  }, [state.values, state.errors]);

  /**
   * handleOnCompleteProposeAction
   *
   * @param {Record<string, any>} returnValues
   */
  async function handleOnCompleteProposeAction(
    returnValues: Record<string, any>
  ) {
    /**
     * @note We send the `?molochProposalId=<proposalId>` as we don't know when the server
     *   will update the information in the database.
     */
    state.projectId &&
      returnValues &&
      history.push(
        `/proposals/${state.projectUUID}?molochProposalId=${returnValues.proposalId}`
      );
  }

  async function handleOnBeforeProcessSubmitProposal() {
    const {values} = state;

    const maybeTransformedValues = {...values} as {
      [key: string]: any;
    };
    const transformers = requestDataTransformers as {
      [key: string]: (v: any, values: Values) => any;
    };

    for (const k in maybeTransformedValues) {
      if (transformers[k]) {
        maybeTransformedValues[k] = transformers[k](
          maybeTransformedValues[k],
          values
        );
      }
    }

    /**
     * Shape submission data
     *
     * @todo Not the most typesafe b/c of the transformed values possibly change the types,
     *   but doing our best - can figure out something later.
     */
    const {
      name,
      description,
      fundingAmountRequestedUSD,
      addressToFund,
      ...rest
    } = maybeTransformedValues as Values;

    const submissionData = {
      // Core submit values
      name,
      description,
      amountRequested: fundingAmountRequestedUSD,
      amountRequestedCurrency: 'USD',
      addressToFund,
      private: false,
      userEmailAddress: rest.contactEmail,
      userEthereumAddress: addressToFund,
      // Extra information
      metadata: {...rest},
    };

    // Submit to API for processing
    try {
      const response = await fetch(`${backendURL}/proposals-investment`, {
        method: 'POST',
        body: JSON.stringify(submissionData),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(
          `Something went wrong while submitting the form values to the server. Error status: ${response.status}.`
        );
      }

      const {id, uuid} = await response.json();

      setCurrentStep(SubmissionStep.SUBMIT_PROPOSAL);
      // Set proposal id for navigation after submission has made it to the smart contract
      setState((s) => ({
        ...s,
        projectId: id,
        projectUUID: uuid,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  function handleFieldTouched(fieldName: FieldNameEnum) {
    setState({
      ...state,
      fieldsTouched: [...state.fieldsTouched, fieldName],
    });
  }

  // function getFunctionInterface(
  //   contract: Record<string, any>,
  //   functionName: any
  // ) {
  //   return contract.abi.find(
  //     (elem: any) => elem.type === 'function' && elem.name === functionName
  //   );
  // }

  /**
   * getProposalArguments
   *
   * Gets the proposal arguments for <MinionProposeAction />.
   */
  function getProposalArguments(
    ethPrice: number | null | undefined
  ): ProposeActionArguments | undefined {
    const transformedValues = getTransformedValues();

    if (!web3Instance) return;
    if (!ethPrice) return;
    // if (!contractMinionActionAddress) return; // `applicant`

    /**
     * Sets the funding amount requested to the user-typed amount.
     *
     * @todo Find a way to use transformedValues in a typesafe way.
     */
    const fundingAmountRequested: number = Number(
      (transformedValues as Values).fundingAmountRequestedUSD
    );

    // Check to make sure we have a number
    if (isNaN(fundingAmountRequested)) return;

    try {
      /**
       * Set the amount to fund in WEI to send to Moloch
       */
      const amountOfWei = web3Instance.utils
        .toBN(fundingAmountRequested.toString())
        .mul(web3Instance.utils.toBN('1000000000000000000'))
        .div(web3Instance.utils.toBN(Math.trunc(ethPrice).toString()));

      /**
       * pieDao action data
       */
      // const amountOfDough = amountOfWei
      //   .mul(web3Instance.utils.toBN('100000'))
      //   .div(web3Instance.utils.toBN('105'));

      // const joinActionData = web3Instance.eth.abi.encodeFunctionCall(
      //   getFunctionInterface(FakePieDAO, 'createTokenRequest'),
      //   [
      //     '0x0000000000000000000000000000000000000000',
      //     amountOfWei.toString(),
      //     amountOfDough.toString(),
      //     'request from the LAO'
      //   ]
      // );

      const minionActionData: string = transformedValues.minionActionData;
      const minionActionAddress: string = transformedValues.minionActionAddress;

      return [
        /**
         * Minion action address `applicant`
         */
        minionActionAddress, // contractMinionActionAddress,
        /**
         * Requested amount in WEI
         */
        amountOfWei.toString(),
        /**
         * Minion action data
         */
        minionActionData,
        /**
         * Details
         */
        `Minion::${state.projectUUID}`,
      ] as ProposeActionArguments;
    } catch (error) {
      throw error;
    }
  }

  function maybeShowFieldError(
    fieldName: FieldNameEnum,
    showBeforeTouched: boolean = false
  ): React.ReactNode {
    const error = state.errors[fieldName];
    const touched = state.fieldsTouched.indexOf(fieldName) >= 0;

    if ((error && touched) || (error && showBeforeTouched)) {
      return (
        <FadeIn>
          <InputError className={fs['error']} error={error} />
        </FadeIn>
      );
    }

    return null;
  }

  function getTransformedValues() {
    const {values} = state;
    const maybeTransformedValues = {...values} as {
      [key: string]: any;
    };
    const transformers = requestDataTransformers as {
      [key: string]: (v: any, values: Values) => any;
    };

    for (const k in maybeTransformedValues) {
      if (transformers[k]) {
        maybeTransformedValues[k] = transformers[k](
          maybeTransformedValues[k],
          values
        );
      }
    }

    return maybeTransformedValues;
  }

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: FieldNameEnum,
    validator?: (value: string) => {errorMessage: string; isError: boolean}
  ) {
    const {value} = event.currentTarget;
    const {errors, values} = state;
    const {errorMessage = ''} = (validator && validator(value)) || ('' as any);
    // Possibly transform the value for display
    const maybeDisplayTransformer = displayTransformers[fieldName]
      ? displayTransformers[fieldName]
      : undefined;
    const maybeTransformedValue = maybeDisplayTransformer
      ? maybeDisplayTransformer(value)
      : value;
    const fieldValuesObject = {
      values: {...values, [fieldName]: maybeTransformedValue},
    };
    event.persist();
    setState({
      ...state,
      ...fieldValuesObject,
      errors: {
        ...errors,
        [fieldName]: errorMessage,
      },
    });
  }

  return (
    <Wrap className={'section-wrapper'}>
      <FadeIn>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">
            Minion Proposal
          </h2>
        </div>

        <div className="org-form-wrap">
          <div className={`${fs['content-wrap']}`}>
            <div
              className={`${fs['form-description']} org-form-description`}
              style={{paddingTop: 0}}>
              {/* <p style={{marginTop: 0}}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
            </div>
            <div className={`${fs['input-rows-wrap']}`}>
              {/* PROJECT NAME */}
              <FadeIn>
                <div className={`${fs['input-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Project Name
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <input
                      name={FieldNameEnum.name}
                      onBlur={() => handleFieldTouched(FieldNameEnum.name)}
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) =>
                        handleChange(
                          event,
                          FieldNameEnum.name,
                          handleValidateExists
                        )
                      }
                      placeholder="What's your project called?"
                      ref={nameRef}
                      type="text"
                      value={state.values.name}
                    />
                    {maybeShowFieldError(FieldNameEnum.name)}
                  </div>
                </div>
              </FadeIn>

              {/* PROJECT URL */}
              <FadeIn>
                <div className={`${fs['input-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Project Website
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <input
                      name={FieldNameEnum.projectURL}
                      onBlur={() =>
                        handleFieldTouched(FieldNameEnum.projectURL)
                      }
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) =>
                        handleChange(
                          event,
                          FieldNameEnum.projectURL,
                          handleValidateURL
                        )
                      }
                      placeholder="Where can members learn more?"
                      type="text"
                      value={state.values.projectURL}
                    />
                    {maybeShowFieldError(FieldNameEnum.projectURL)}
                  </div>
                </div>
              </FadeIn>

              {/* DESCRIPTION */}
              <FadeIn>
                <div className={`${fs['textarea-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Description
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <textarea
                      name={FieldNameEnum.description}
                      onBlur={() =>
                        handleFieldTouched(FieldNameEnum.description)
                      }
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) =>
                        handleChange(
                          event,
                          FieldNameEnum.description,
                          handleValidateExists
                        )
                      }
                      placeholder="Tell us about your project.  What's your best elevator pitch?"
                      value={state.values.description}
                    />
                    {maybeShowFieldError(FieldNameEnum.description)}
                  </div>
                </div>
              </FadeIn>

              {/* PROJECT/SUPPORTING DOCUMENT URLs */}
              <FadeIn>
                <div className={`${fs['textarea-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Pitch Deck, Whitepaper, or Other Information
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <textarea
                      name={FieldNameEnum.documentURLs}
                      onBlur={() =>
                        handleFieldTouched(FieldNameEnum.documentURLs)
                      }
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) =>
                        handleChange(
                          event,
                          FieldNameEnum.documentURLs,
                          handleValidateURLMultiple
                        )
                      }
                      placeholder={
                        'For our members to decide, we need more than just a description.  Please provide a link to your pitch deck, whitepaper, or any other information that you think would be relevant.\n\n' +
                        '(Please separate URLs with a new line, or a space.)'
                      }
                      value={state.values.documentURLs}
                    />

                    {maybeShowFieldError(FieldNameEnum.documentURLs)}
                  </div>
                </div>
              </FadeIn>

              {/* MINION ACTION ADDRESS */}
              <FadeIn>
                <div className={`${fs['input-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Minion Action Address
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <input
                      name={FieldNameEnum.minionActionAddress}
                      onBlur={() =>
                        handleFieldTouched(FieldNameEnum.minionActionAddress)
                      }
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) =>
                        handleChange(
                          event,
                          FieldNameEnum.minionActionAddress,
                          handleValidateExists
                        )
                      }
                      placeholder="What's the minion action address?"
                      type="text"
                      value={state.values.minionActionAddress}
                    />
                    {maybeShowFieldError(FieldNameEnum.minionActionAddress)}
                  </div>
                </div>
              </FadeIn>

              {/* MINION ACTION DATA */}
              <FadeIn>
                <div className={`${fs['textarea-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Minion Action Data
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <textarea
                      name={FieldNameEnum.minionActionData}
                      onBlur={() =>
                        handleFieldTouched(FieldNameEnum.minionActionData)
                      }
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => handleChange(event, FieldNameEnum.minionActionData)}
                      placeholder={`What's the minion action data? ie. 0x85fc7e2c0000000... \nOr, you can leave this field blank!`}
                      value={state.values.minionActionData}
                    />
                    {maybeShowFieldError(FieldNameEnum.minionActionData)}
                  </div>
                </div>
              </FadeIn>

              {/* FUNDING AMOUNT */}
              <FadeIn>
                <div className={`${fs['input-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Requested Funding Amount
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <div className={i['input-prefix__wrap']}>
                      <input
                        className={`${i['input-prefix--sm']}`}
                        name={FieldNameEnum.fundingAmountRequestedUSD}
                        onBlur={() =>
                          handleFieldTouched(
                            FieldNameEnum.fundingAmountRequestedUSD
                          )
                        }
                        onChange={(
                          event: React.ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) =>
                          handleChange(
                            event,
                            FieldNameEnum.fundingAmountRequestedUSD,
                            handleValidateFundingAmount
                          )
                        }
                        placeholder={orgFundingAmountFieldPlaceholder}
                        type="text"
                        // Formats number string to have commas for display.
                        value={state.values.fundingAmountRequestedUSD}
                      />
                      <div
                        className={`${i['input-prefix__item--sm']} org-input-prefix__item--sm`}>
                        $
                      </div>
                    </div>
                    {maybeShowFieldError(
                      FieldNameEnum.fundingAmountRequestedUSD
                    )}

                    <p className={`${fs['input-row-help']} org-input-row-help`}>
                      This will be considered in combination with your company
                      value, which {orgName} members will discuss with you.
                    </p>
                  </div>
                </div>
              </FadeIn>

              {/* SUBMIT */}
              {isSubmitReady && (
                <>
                  {/** 1. SAVE PROPOSAL TO DATABASE */}

                  {currentStep === SubmissionStep.SAVE_PROPOSAL && (
                    <FadeIn>
                      <div
                        style={{
                          margin: '0 auto',
                          textAlign: 'center',
                        }}>
                        <button
                          disabled={state.isSubmitting}
                          className={`${b.primary} org-primary-button`}
                          onClick={handleOnBeforeProcessSubmitProposal}>
                          {state.isSubmitting ? (
                            <Loader text="Processing&hellip;" />
                          ) : (
                            'STEP 1: Save Minion Proposal'
                          )}
                        </button>
                      </div>
                    </FadeIn>
                  )}

                  {/** 2. SUBMIT PROPOSAL */}

                  {currentStep === SubmissionStep.SUBMIT_PROPOSAL && (
                    <FadeIn>
                      <ETHPrice
                        render={(ethPrice) => (
                          <MinionProposeAction
                            onComplete={(returnValues: Record<string, any>) =>
                              handleOnCompleteProposeAction(returnValues)
                            }
                            proposalArguments={getProposalArguments(ethPrice)}
                            render={({
                              error,
                              etherscanURL,
                              isPromptOpen,
                              isSubmitted,
                              isSubmitting,
                              openPrompt,
                            }) => (
                              <>
                                <button
                                  className={`${b.primary} org-primary-button`}
                                  onClick={
                                    isSubmitting || isSubmitted || isPromptOpen
                                      ? () => {}
                                      : openPrompt
                                  }
                                  type="submit"
                                  disabled={
                                    isSubmitting || isSubmitted || isPromptOpen
                                  }>
                                  {state.isValidatingProposal ||
                                  isPromptOpen ? (
                                    <Loader text="Preparing&hellip;" />
                                  ) : isSubmitting ? (
                                    <Loader text="Processing&hellip;" />
                                  ) : (
                                    `STEP 2: ${orgSubmitProposalButtonText}`
                                  )}
                                </button>

                                {/* ETHERSCAN URL */}
                                {etherscanURL && (
                                  <p className="text-center">
                                    <small>
                                      <a
                                        href={etherscanURL}
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        (view progress)
                                      </a>
                                    </small>
                                  </p>
                                )}

                                {error &&
                                  (error as MetaMaskRPCError).code !== 4001 && (
                                    <div className="text-center">
                                      <ErrorMessageWithDetails
                                        error={error}
                                        renderText="Something went wrong while submitting your proposal"
                                      />
                                    </div>
                                  )}
                              </>
                            )}
                          />
                        )}
                      />
                    </FadeIn>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </FadeIn>
    </Wrap>
  );
}
