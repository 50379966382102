import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {formatNumber} from '../../../util/helpers';
import {StoreState, MetaMaskRPCError} from '../../../util/types';
import {InternalNamesToMap} from '../../../util/orgDomainMappings';

import {useIsAdmin} from '../../../hooks';

import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import Loader from '../../../components/feedback/Loader';
import ProposalVoters from './vote/ProposalVoters';
import MinionExecuteAction from '../../../components/contract/MinionExecuteAction';

import IncomingSVG from '../../../assets/svg/IncomingSVG';
import DropSVG from '../../../assets/svg/DropSVG';
import StopwatchSVG from '../../../assets/svg/StopwatchSVG';

import s from '../../../assets/scss/modules/votingcard.module.scss';

type MinionProposalProps = {
  molochProposal: Record<string, any>;
};

export default function MinionProposal({molochProposal}: MinionProposalProps) {
  const {paymentRequested, proposalId} = molochProposal;

  const isAdmin = useIsAdmin();
  /**
   * Selectors
   */
  const Minion = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.Minion
  );
  const web3Instance = useSelector(
    (state: StoreState) => state.blockchain.web3Instance
  );
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );

  /**
   * State
   */

  const [fundsRequested, setFundsRequested] = useState<string>('');
  const [isExecuted, setIsExecuted] = useState<boolean>();

  const isDisabled = !isAdmin;

  useEffect(() => {
    if (web3Instance) {
      const payment = web3Instance.utils.fromWei(paymentRequested);

      setFundsRequested(payment);
    }
  }, [paymentRequested, web3Instance]);

  useEffect(() => {
    try {
      if (!Minion) return;
      if (!proposalId) return;

      const {methods} = (Minion && Minion.instance) || {};

      methods
        .actions(proposalId)
        .call()
        .then(({executed}: Record<string, any>) => {
          setIsExecuted(executed);
        });
    } catch (error) {
      throw error;
    }
  }, [Minion, proposalId]);

  /**
   * Functions
   */

  /**
   * handleOnComplete
   *
   * @todo Refactor per https://github.com/openlawteam/lao/issues/674
   */
  function handleOnComplete({executor}: any) {
    if (executor) {
      setIsExecuted(true);
    }
  }

  function renderCountdownIcon(): JSX.Element {
    switch (orgInternalName) {
      case InternalNamesToMap.liquidity:
        return <DropSVG />;
      case InternalNamesToMap.nft:
      case InternalNamesToMap.thelao:
      default:
        return <StopwatchSVG />;
    }
  }

  return (
    <>
      <div className={`${s['row-w-icon']} ${s['countdown']} org-countdown`}>
        {renderCountdownIcon()}
        <span className={`${s['status']} org-status`}>
          {isExecuted ? 'Funded' : 'Approved for funding'}
        </span>
      </div>

      <ProposalVoters proposal={molochProposal} />

      <MinionExecuteAction
        onComplete={handleOnComplete}
        proposalId={proposalId}
        render={({
          error,
          etherscanURL,
          isPromptOpen,
          isSubmitted,
          isSubmitting,
          openPrompt,
        }) => (
          <>
            <button
              className={s['process-btn']}
              onClick={openPrompt}
              disabled={isSubmitting || isSubmitted || isDisabled || isExecuted}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
              {isPromptOpen ? (
                <Loader text="Preparing&hellip;" />
              ) : isSubmitting ? (
                <Loader text="Executing&hellip;" />
              ) : isSubmitted ? (
                'Finishing up\u2026'
              ) : isExecuted ? (
                'Executed'
              ) : (
                'Execute Action'
              )}
            </button>

            {/* ETHERSCAN URL */}
            {etherscanURL && (
              <p className="text-center">
                <small>
                  <a
                    href={etherscanURL}
                    rel="noopener noreferrer"
                    target="_blank">
                    {isSubmitting ? '(view progress)' : '(view transaction)'}
                  </a>
                </small>
              </p>
            )}

            {/* if error is not a MM window close/cancel code */}
            {error && (error as MetaMaskRPCError).code !== 4001 && (
              <div className="text-center">
                <ErrorMessageWithDetails
                  error={error}
                  renderText={() => (
                    <small>
                      Something went wrong while executing your proposal.
                    </small>
                  )}
                />
              </div>
            )}
          </>
        )}
      />

      {isExecuted && (
        <>
          <div className={s['row-w-icon']}>
            <IncomingSVG />
            <div>
              <span className={s['tokendetails__title']}>Disbursement</span>
              <span className={s['tokendetails__details']}>
                {formatNumber(Number(fundsRequested).toFixed(3))} WETH
              </span>
            </div>
          </div>

          <div className={s['row-w-icon']}></div>
        </>
      )}
    </>
  );
}
