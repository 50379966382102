import React, {useEffect, useState, useCallback} from 'react';
import {Controller, FormContext, useForm} from 'react-hook-form';

import {
  ACCEPTED_IMAGE_MIME_TYPES,
  ERROR_FILE_TOO_LARGE,
  ERROR_REQUIRED_FIELD,
  MAX_FILE_SIZE,
  PDF_MIME_TYPE,
} from './helper';
import {Forms, OnFormChangeCallback} from './types';
import {getValidationError, numberRangeArray} from '../../../util/helpers';
import {isEmailValid, isEthAddressValid} from '../../../util/validation';
import Address from '../../../components/common/Address';
import FileDropzone from '../../../components/common/FileDropzone';
import InputError from '../../../components/common/InputError';

import d from '../../../assets/scss/modules/filedropzone.module.scss';
import i from '../../../assets/scss/modules/input.module.scss';
import s from '../../../assets/scss/modules/memberverify.module.scss';

type IdentityBaseFormProps = {
  onFormChange: OnFormChangeCallback;
};

export enum Fields {
  address = 'address',
  dobDay = 'dobDay',
  dobMonth = 'dobMonth',
  dobYear = 'dobYear',
  emailAddress = 'emailAddress',
  ethereumAddress = 'ethereumAddress',
  idFile = 'idFile',
  idFileBack = 'idFileBack',
  occupation = 'occupation',
  passportOrIdNumber = 'passportOrIdNumber',
  phone = 'phone',
  socialSecurityNumber = 'socialSecurityNumber',
}

type Values = {
  address: string;
  dobDay: string;
  dobMonth: string;
  dobYear: string;
  emailAddress: string;
  ethereumAddress: string;
  idFile: File | null;
  idFileBack?: File | null;
  occupation: string;
  passportOrIdNumber: string;
  phone: string;
  socialSecurityNumber?: string;
};

type DerivedValues = {
  dateOfBirth: string;
};

export type IdentityBaseValues = Values & DerivedValues;

type Conditions = {
  [l in Fields]: boolean[];
};

// validation configuration for react-hook-form
const acceptedFileTypes = [...ACCEPTED_IMAGE_MIME_TYPES, PDF_MIME_TYPE];
const ruleRequired = {
  required: ERROR_REQUIRED_FIELD,
};
const fileImageValidate = {
  validate: (file: File) => {
    return !file
      ? ERROR_REQUIRED_FIELD
      : file.size > MAX_FILE_SIZE
      ? ERROR_FILE_TOO_LARGE
      : !acceptedFileTypes.includes(file.type)
      ? 'The image is not the correct type. Please provide a .png, .jpg, .jpeg file.'
      : true;
  },
};
const fileImageValidateOptional = {
  validate: (file: File) => {
    if (!file) return true;

    return file.size > MAX_FILE_SIZE
      ? ERROR_FILE_TOO_LARGE
      : !acceptedFileTypes.includes(file.type)
      ? 'The image is not the correct type. Please provide a .png, .jpg, .jpeg file.'
      : true;
  },
};
const ethAddressValidate = {
  validate: (value: string) => {
    return !value
      ? ERROR_REQUIRED_FIELD
      : !isEthAddressValid(value)
      ? 'Please provide a valid Ethereum address.'
      : true;
  },
};
const emailAddressValidate = {
  validate: (value: string) => {
    return !value
      ? ERROR_REQUIRED_FIELD
      : !isEmailValid(value)
      ? 'Please provide a valid email address.'
      : true;
  },
};
const validateConfig: Partial<Record<Fields, Record<string, any>>> = {
  address: ruleRequired,
  dobDay: ruleRequired,
  dobMonth: ruleRequired,
  emailAddress: emailAddressValidate,
  ethereumAddress: ethAddressValidate,
  idFile: fileImageValidate,
  idFileBack: fileImageValidateOptional,
  occupation: ruleRequired,
  passportOrIdNumber: ruleRequired,
  phone: {
    validate: (value: string) => {
      const sanitizedPhoneNumber = value.replace(/[.()\s\-+]/g, '');

      return (
        // required && can be parsed to number
        !Number(sanitizedPhoneNumber)
          ? 'Please provide a valid phone number.'
          : true
      );
    },
  },
  socialSecurityNumber: {
    ...ruleRequired,
    pattern: {
      message:
        'Please provide a complete, or dash-separated (XXX-XX-XXXX) 9-digit number.',
      value: /^\d{3}-\d{2}-\d{4}$|^\d{9}$/,
    },
  },
  dobYear: ruleRequired,
};

const getConditionsMap = (
  formValues: Record<string, any>
): Partial<Conditions> => ({
  [Fields.socialSecurityNumber]: [
    // if U.S. address
    /United States of America$|United States$|US$|USA$|U\.S\.$|U\.S\.A\.$/.test(
      formValues[Fields.address] as string
    ),
  ],
});

const dataTransformers: Partial<
  Record<
    keyof IdentityBaseValues,
    (value: any, allValues: IdentityBaseValues & DerivedValues) => any
  >
> = {
  // join Month, Day, Year (January 1, 1999)
  dateOfBirth: (_, values) => {
    const {dobDay, dobMonth, dobYear} = values;
    return dobDay && dobMonth && dobYear
      ? `${dobMonth} ${dobDay}, ${dobYear}`
      : '';
  },
};

/**
 * IdentityBaseForm
 *
 * A form that is used in every identification pathway: Person, Company, Trust.
 * NOTE: Keep this form generic as possible, as other forms compose it.
 */
export default function IdentityBaseForm(props: IdentityBaseFormProps) {
  // mainly to restore conditional fields
  // as the formValues will add/remove props based on if rendered
  const [values, setValues] = useState<Partial<IdentityBaseValues>>({});
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const {
    errors,
    formState,
    unregister,
    register,
    setError,
    setValue,
    triggerValidation,
    watch,
  } = form;

  // watch all values that change
  // see: https://react-hook-form.com/api/#watch
  const formValues = watch();

  /**
   * @note From the docs: "Read the formState before render to subscribe the form state through Proxy"
   * @see https://react-hook-form.com/api#formState
   */
  const {isValid} = formState;

  const conditionsMap = getConditionsMap(formValues);

  const renderConditionsToString = JSON.stringify(
    Object.keys(conditionsMap).map((f) => testRenderConditions(f as Fields))
  );

  const testRenderConditionsCached = useCallback(testRenderConditions, [
    conditionsMap,
  ]);

  // send parent the current form status
  useEffect(() => {
    const values = form.getValues() as IdentityBaseValues;

    // derive any data via transformers before sending to parent
    const derivedData = Object.keys(dataTransformers).reduce((acc, next) => {
      const key = next as keyof IdentityBaseValues;
      const possibleTransformer = dataTransformers[key];
      if (possibleTransformer) {
        acc[key] = possibleTransformer('', values);
      }
      return acc;
    }, {} as IdentityBaseValues);

    props.onFormChange({
      formName: Forms.baseIdentity,
      isValid,
      triggerValidation: form.triggerValidation,
      values: {...values, ...derivedData},
    });

    // clean up form values on unmount and set as valid
    return () => {
      props.onFormChange({
        formName: Forms.baseIdentity,
        isValid: true,
        triggerValidation: form.triggerValidation,
        values: {} as IdentityBaseValues,
      });
    };
  }, [form, isValid, props]);

  // re-validate if conditional is shown and has a value
  useEffect(() => {
    Object.keys(conditionsMap).forEach(async (f) => {
      const field = f as Fields;

      (await testRenderConditionsCached(field)) &&
        form.getValues()[field] &&
        form.triggerValidation(field, true);
    });
  }, [
    form,
    conditionsMap,
    renderConditionsToString,
    testRenderConditionsCached,
  ]);

  // mainly to restore conditional fields
  // as the formValues will add/remove props based on if rendered
  function handleValuesChange(key: keyof IdentityBaseValues) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;

      setValues({...values, [key]: value});
    };
  }

  function testRenderConditions(field: Fields) {
    return (conditionsMap[field] || []).every((c: boolean) => c);
  }

  return (
    <>
      <FormContext {...form}>
        {/* EMAIL ADDRESS */}
        <label>
          <span className={`${i['label--column']} org-label--column`}>
            Email Address
          </span>
          <input
            aria-describedby="error-emailAddress"
            aria-invalid={errors.emailAddress ? 'true' : 'false'}
            name={Fields.emailAddress}
            placeholder="morgan@darkcrystal.io"
            ref={
              validateConfig.emailAddress &&
              register(validateConfig.emailAddress)
            }
            type="text"
          />
          <InputError
            error={getValidationError(Fields.emailAddress, errors)}
            id="error-emailAddress"
          />
        </label>

        {/* ETHEREUM ADDRESS */}
        <label>
          <span className={`${i['label--column']} org-label--column`}>
            Ethereum Address (for Sale)
          </span>
          <input
            aria-describedby="error-ethereumAddress"
            aria-invalid={errors.ethereumAddress ? 'true' : 'false'}
            name={Fields.ethereumAddress}
            placeholder="0x..."
            ref={
              validateConfig.ethereumAddress &&
              register(validateConfig.ethereumAddress)
            }
            type="text"
          />
          <InputError
            error={getValidationError(Fields.ethereumAddress, errors)}
            id="error-ethereumAddress"
          />
        </label>

        {/* ADDRESS */}
        <label className={`${i['label--column']} org-label--column`}>
          <span id="label-address">Home Address</span>
          <Controller
            aria-describedby="error-address"
            aria-labelledby="label-address"
            aria-invalid={errors.address ? 'true' : 'false'}
            as={
              <Address
                onChange={() => {}}
                onAddressError={(error: string) =>
                  setError(Fields.address, 'network', error)
                }
                style={{backgroundColor: 'black'}}
              />
            }
            name={Fields.address}
            // I would think typically you don't return values
            // from an onChange handler, but it works.
            // see: https://react-hook-form.com/api/#Controller
            onChange={(v: Record<string, any>) => {
              // v[0] is the formatted address
              return v[0];
            }}
            rules={validateConfig.address}
          />
        </label>
        <InputError
          error={getValidationError(Fields.address, errors)}
          id="error-address"
        />

        {/* DOB MONTH */}
        <label className={`${i['label--column']} org-label--column`}>
          Date of Birth
        </label>
        <div className={`${s['flex-container']} ${s['inputs-row']}`}>
          <div>
            <select
              aria-describedby="error-month"
              aria-label={Fields.dobMonth}
              aria-invalid={errors.dobMonth ? 'true' : 'false'}
              name={Fields.dobMonth}
              ref={validateConfig.dobMonth && register(validateConfig.dobMonth)}
              className="org-select">
              <option value="">Month</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>
            <InputError
              error={getValidationError(Fields.dobMonth, errors)}
              id="error-month"
            />
          </div>

          {/* DOB DAY */}
          <div>
            <select
              aria-describedby="error-day"
              aria-invalid={errors.dobDay ? 'true' : 'false'}
              aria-label={Fields.dobDay}
              name={Fields.dobDay}
              ref={validateConfig.dobDay && register(validateConfig.dobDay)}
              className="org-select">
              <option value="">Day</option>
              {numberRangeArray(31, 1).map((d) => (
                <option key={d} value={d}>
                  {d}
                </option>
              ))}
            </select>
            <InputError
              error={getValidationError(Fields.dobDay, errors)}
              id="error-day"
            />
          </div>

          {/* DOB YEAR */}
          <div>
            <select
              aria-describedby="error-year"
              aria-invalid={errors.dobYear ? 'true' : 'false'}
              aria-label={Fields.dobYear}
              name={Fields.dobYear}
              ref={validateConfig.dobYear && register(validateConfig.dobYear)}
              className="org-select">
              <option value="">Year</option>
              {/* 18 years old */}
              {numberRangeArray(new Date().getFullYear() - 18, 1920)
                .map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))
                .reverse()}
            </select>
            <InputError
              error={getValidationError(Fields.dobYear, errors)}
              id="error-year"
            />
          </div>
        </div>

        {/* (US ONLY) SOCIAL SECURITY NUMBER */}
        {testRenderConditions(Fields.socialSecurityNumber) && (
          <>
            <label className={`${i['label--column']} org-label--column`}>
              <span>(U.S. ONLY) Social Security Number</span>

              <input
                aria-describedby="error-ssn"
                aria-invalid={errors.socialSecurityNumber ? 'true' : 'false'}
                defaultValue={values.socialSecurityNumber}
                name={Fields.socialSecurityNumber}
                onChange={handleValuesChange(Fields.socialSecurityNumber)}
                placeholder="XXX &ndash; XX &ndash; XXXX"
                ref={
                  validateConfig.socialSecurityNumber &&
                  register(validateConfig.socialSecurityNumber)
                }
                type="text"
              />
            </label>
            <InputError
              error={getValidationError(Fields.socialSecurityNumber, errors)}
              id="error-ssn"
            />
          </>
        )}

        {/* ID FILE UPLOAD */}
        <label
          id="id-scan-base-identity"
          className={`${i['label--column']} org-label--column`}>
          ID Scan
        </label>
        <small>
          A passport is highly recommended but other forms of
          government&ndash;issued color photo ID (such as a national identity
          card or driver&rsquo;s license) are also acceptable.
        </small>
        <Controller
          as={
            <FileDropzone
              acceptedTypes={acceptedFileTypes}
              aria-describedby="error-idFile"
              aria-invalid={errors.idFile ? 'true' : 'false'}
              aria-labelledby="id-scan-base-identity"
            />
          }
          // I would think typically you don't return values
          // from an onChange handler, but it works.
          // see: https://react-hook-form.com/api/#Controller
          onChange={([event]) => event.target.files[0]}
          name={Fields.idFile}
          rules={validateConfig.idFile}
        />
        <InputError
          error={getValidationError(Fields.idFile, errors)}
          id="error-idFile"
        />

        {/* ID (BACK) UPLOAD */}
        <label
          id="id-scan-back-base-identity"
          className={`${i['label--column']} org-label--column`}>
          Back of ID Scan (OPTIONAL)
        </label>
        <small>Not required if you uploaded a passport above.</small>
        <FileDropzone
          acceptedTypes={acceptedFileTypes}
          aria-describedby="error-idFileBack"
          aria-invalid={errors.idFileBack ? 'true' : 'false'}
          aria-labelledby="id-scan-back-base-identity"
          isFileClearable
          onChange={(event) => {
            // Custom react-hook-form register/unregister for optional file
            const {files} = event.target || {};
            const file = files && Array.from(files).flatMap((f) => f)[0];

            if (file) {
              register({name: Fields.idFileBack}, validateConfig.idFileBack);
              setValue(Fields.idFileBack, file);
              triggerValidation(Fields.idFileBack);

              return;
            }

            unregister(Fields.idFileBack);
          }}
          renderClearButton={(handleFileDialogCancel) => (
            <button
              className={d['file-remove-button']}
              onClick={handleFileDialogCancel}
              style={{
                color: 'white',
                fontFamily:
                  '"Lexend Exa", Oxygen, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
                alignSelf: 'flex-end',
              }}
              type="button">
              Remove
            </button>
          )}
        />
        {/* <Controller
          as={
            <FileDropzone
              // acceptedTypes={ACCEPTED_IMAGE_MIME_TYPES}
              aria-describedby="error-idFileBack"
              aria-invalid={errors.idFileBack ? 'true' : 'false'}
              isFileClearable
              onChange={([event]) => event.target.files[0]}
            />
          }
          // I would think typically you don't return values
          // from an onChange handler, but it works.
          // see: https://react-hook-form.com/api/#Controller
          onChange={([event]) => event.target.files[0]}
          name={Fields.idFileBack}
          rules={validateConfig.idFileBack}
        /> */}
        <InputError
          error={getValidationError(Fields.idFileBack, errors)}
          id="error-idFileBack"
        />

        {/* PASSPORT or ID NUMBER */}
        <label className={`${i['label--column']} org-label--column`}>
          <span>Passport or ID Number</span>
          <input
            aria-describedby="error-passportOrIdNumber"
            aria-invalid={errors.passportOrIdNumber ? 'true' : 'false'}
            name={Fields.passportOrIdNumber}
            ref={
              validateConfig.passportOrIdNumber &&
              register(validateConfig.passportOrIdNumber)
            }
            type="text"
          />
        </label>
        <InputError
          error={getValidationError(Fields.passportOrIdNumber, errors)}
          id="error-passportOrIdNumber"
        />

        {/* PHONE */}
        <label className={`${i['label--column']} org-label--column`}>
          <span>Phone Number</span>
          <input
            aria-describedby="error-phone"
            aria-invalid={errors.phone ? 'true' : 'false'}
            name={Fields.phone}
            placeholder="555-555-5555"
            ref={validateConfig.phone && register(validateConfig.phone)}
            type="text"
          />
        </label>
        <InputError
          error={getValidationError(Fields.phone, errors)}
          id="error-phone"
        />

        {/* OCCUPATION */}
        <label className={`${i['label--column']} org-label--column`}>
          <span>Occupation</span>
          <input
            aria-describedby="error-occupation"
            aria-invalid={errors.phone ? 'true' : 'false'}
            name={Fields.occupation}
            placeholder="e.g. Software Engineer"
            ref={
              validateConfig.occupation && register(validateConfig.occupation)
            }
            type="text"
          />
        </label>
        <InputError
          error={getValidationError(Fields.occupation, errors)}
          id="error-occupation"
        />
      </FormContext>
    </>
  );
}
