import {useSelector} from 'react-redux';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {Helmet} from 'react-helmet';

import {CHAIN_NAME, ETHERSCAN_URLS} from '../../util/config';
import {InternalNamesToMap} from '../../util/orgDomainMappings';
import {isTributeOrgName} from '../../util/helpers';
import {StoreState} from '../../util/types';
import {useIsAdmin} from '../../hooks';
import Wrap from '../../components/layout/Wrap';

import DeployContract from './DeployContract';
import Forms from './Forms';
import Healthcheck from './healthcheck';
import Members from './Members';
import ProcessingQueue from './ProcessingQueue';
import Projects from './Projects';
import Settings from './Settings';
import SnapshotOverview from './SnapshotOverview';

import 'react-tabs/style/react-tabs.css';
import '../../assets/scss/modules/admin.module.scss';

export default function Admin() {
  const VentureMoloch = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.VentureMoloch
  );
  const defaultChain = useSelector(
    (state: StoreState) => state.blockchain && state.blockchain.defaultChain
  );
  const internalName =
    useSelector((s: StoreState) => s.org && s.org.internalName) || '';

  const isAdmin = useIsAdmin();

  const etherscanURL = `${ETHERSCAN_URLS[defaultChain]}/address/${
    VentureMoloch && VentureMoloch.contractAddress
  }`;

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Wrap className={'section-wrapper admin'}>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">Admin</h2>
        </div>

        {isAdmin ? (
          <div className="card org-card" style={{padding: '2rem'}}>
            <summary>
              <p>
                Deployed Contract:{' '}
                <a
                  href={etherscanURL}
                  rel="noopener noreferrer"
                  target="_blank">
                  {VentureMoloch && VentureMoloch.contractAddress}
                </a>
              </p>
              {defaultChain && (
                <p>Connected Network: {CHAIN_NAME[defaultChain]}</p>
              )}
            </summary>

            <Tabs defaultIndex={1}>
              <TabList>
                {/**
                 * @important for Tribute DAOs we only need access to the settings
                 * tab and non-apolloClient dependent components
                 */}
                {!isTributeOrgName(internalName as InternalNamesToMap) && (
                  <>
                    <Tab>Contract</Tab>
                    <Tab>Members</Tab>
                    <Tab>Projects</Tab>
                    <Tab>Processing Queue</Tab>
                    <Tab>Snapshot Overview</Tab>
                    <Tab>Forms</Tab>
                    <Tab>Healthcheck</Tab>
                  </>
                )}
                <Tab>Settings</Tab>
              </TabList>

              {/**
               * @important for Tribute DAOs we only need access to the settings
               * tab and non-apolloClient dependent components
               */}
              {!isTributeOrgName(internalName as InternalNamesToMap) && (
                <>
                  <TabPanel>
                    <DeployContract />
                  </TabPanel>
                  <TabPanel>
                    <Members />
                  </TabPanel>
                  <TabPanel>
                    <Projects />
                  </TabPanel>
                  <TabPanel>
                    <ProcessingQueue />
                  </TabPanel>
                  <TabPanel>
                    <SnapshotOverview />
                  </TabPanel>
                  <TabPanel>
                    <Forms />
                  </TabPanel>
                  <TabPanel>
                    <Healthcheck />
                  </TabPanel>
                </>
              )}

              <TabPanel>
                <Settings />
              </TabPanel>
            </Tabs>
          </div>
        ) : (
          <p className="color-yellow text-center">
            Sorry, you cannot access this page.
          </p>
        )}
      </Wrap>
    </>
  );
}
