import React from 'react';

interface IProps {
  [key: string]: any;
  fill?: string;
}

export default function DownTriangle(props: IProps) {
  const {fill, ...restProps} = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
      width="20"
      height="12"
      viewBox="0 0 20 12">
      <path
        fill={fill || '#FFF'}
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M18.807.42a1.153 1.153 0 00-.852-.354H1.205C.879.066.597.183.36.419.13.638 0 .941 0 1.26c0 .327.118.609.354.844l8.388 8.382c.235.236.51.353.838.353.328 0 .603-.117.84-.353l8.387-8.388c.23-.23.354-.511.354-.839 0-.327-.125-.603-.36-.839h.006z"
        transform="translate(0 .5)"></path>
    </svg>
  );
}
