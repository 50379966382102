import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {StoreState} from '../../util/types';

type CanRagequitStates = {
  amountSharesLootOK: boolean;
  canRagequit: boolean;
  highestIndexYesVoteProcessed: boolean;
};

/**
 * useCanRagequit
 *
 * Verifies if a member can ragequit.
 * The states returned are for fine-grained control of UI.
 *
 * @note We're a bit more stingent in our logic than Moloch on our `canRagequit`
 *   state, as we need to know *before* we let a user attempt ragequitting.
 */
export function useCanRagequit(): CanRagequitStates {
  /**
   * State
   */

  const [canRagequit, setCanRagequit] = useState<boolean>(false);
  const [amountSharesLootOK, setAmountSharesLootOK] = useState<boolean>(false);
  const [highestIndexYesVoteProcessed, sethighestIndexYesVoteProcessed] =
    useState<boolean>(false);

  /**
   * Selectors
   */

  const VentureMoloch = useSelector(
    (s: StoreState) =>
      s.blockchain.contracts && s.blockchain.contracts.VentureMoloch
  );
  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );
  const connectedMember = useSelector((s: StoreState) => s.connectedMember);

  /**
   * Effects
   */

  useEffect(() => {
    if (!VentureMoloch || !connectedAddress || !connectedMember.exists) return;

    // Can only ragequit if the latest proposal you voted YES on has been processed
    VentureMoloch.instance.methods
      .canRagequit(connectedMember.highestIndexYesVote)
      .call({from: connectedAddress})
      .then((canRagequitResponse: boolean) => {
        // We add our own logic for our UI
        // (i.e. Moloch technically lets you ragequit with 0 shares and 0 loot, but makes no sense for us.)
        const existsAndSharesAndLoot =
          connectedMember.exists &&
          (Number(connectedMember.shares) || Number(connectedMember.loot)) > 0;

        setCanRagequit(canRagequitResponse && existsAndSharesAndLoot);
        setAmountSharesLootOK(
          Number(connectedMember.shares) > 0 || Number(connectedMember.loot) > 0
        );
        sethighestIndexYesVoteProcessed(canRagequitResponse);
      })
      .catch(() => {
        setCanRagequit(false);
      });
  }, [connectedMember, connectedAddress, VentureMoloch]);

  return {
    amountSharesLootOK,
    canRagequit,
    highestIndexYesVoteProcessed,
  };
}
