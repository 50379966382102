import React from 'react';

type UserSVGProps = {
  fromGradient?: string;
  toGradient?: string;
};

export default function UserSVG({
  fromGradient,
  toGradient,
  ...restProps
}: React.SVGAttributes<SVGElement> & UserSVGProps) {
  const ID_NAMESPACE = 'user';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="26"
      height="24"
      viewBox="0 0 26 24"
      {...restProps}>
      <defs>
        <path
          id={`${ID_NAMESPACE}-a`}
          d="M16.488.44c4.98 0 9.03 3.948 9.03 8.8 0 .972-.808 1.76-1.805 1.76H2.288C1.29 11 .482 10.212.482 9.24c0-4.852 4.05-8.8 9.03-8.8h6.976zM4.403 7.48h17.194c-.745-2.049-2.754-3.52-5.109-3.52H9.512c-2.355 0-4.364 1.471-5.109 3.52z"
        />
        <linearGradient
          id={`${ID_NAMESPACE}-b`}
          x1="8.506%"
          x2="8.506%"
          y1="23.179%"
          y2="82.748%">
          <stop offset="0%" stopColor={fromGradient || '#80D8FF'} />
          <stop offset="100%" stopColor={toGradient || '#E981FC'} />
        </linearGradient>
        <path
          id="d"
          d="M16.488.44c4.98 0 9.03 3.948 9.03 8.8 0 .972-.808 1.76-1.805 1.76H2.288C1.29 11 .482 10.212.482 9.24c0-4.852 4.05-8.8 9.03-8.8h6.976zM4.403 7.48h17.194c-.745-2.049-2.754-3.52-5.109-3.52H9.512c-2.355 0-4.364 1.471-5.109 3.52z"
        />
        <linearGradient
          id={`${ID_NAMESPACE}-e`}
          x1="8.531%"
          x2="11.654%"
          y1="23.486%"
          y2="23.486%">
          <stop offset="0%" stopColor={fromGradient || '#80D8FF'} />
          <stop offset="100%" stopColor={toGradient || '#E981FC'} />
        </linearGradient>
        <path
          id="g"
          d="M6 0c3.186 0 5.778 2.584 5.778 5.76 0 3.176-2.592 5.76-5.778 5.76-3.186 0-5.778-2.584-5.778-5.76C.222 2.584 2.814 0 6 0zm0 7.976A2.222 2.222 0 008.222 5.76a2.222 2.222 0 00-4.444 0c0 1.222.997 2.216 2.222 2.216z"
        />
        <linearGradient
          id={`${ID_NAMESPACE}-h`}
          x1="8.974%"
          x2="8.974%"
          y1="22.397%"
          y2="81.966%">
          <stop offset="0%" stopColor={fromGradient || '#80D8FF'} />
          <stop offset="100%" stopColor={toGradient || '#E981FC'} />
        </linearGradient>
        <path
          id={`${ID_NAMESPACE}-j`}
          d="M6 0c3.186 0 5.778 2.584 5.778 5.76 0 3.176-2.592 5.76-5.778 5.76-3.186 0-5.778-2.584-5.778-5.76C.222 2.584 2.814 0 6 0zm0 7.976A2.222 2.222 0 008.222 5.76a2.222 2.222 0 00-4.444 0c0 1.222.997 2.216 2.222 2.216z"
        />
        <linearGradient
          id={`${ID_NAMESPACE}-k`}
          x1="21.21%"
          x2="23.313%"
          y1="22.732%"
          y2="22.761%">
          <stop offset="0%" stopColor={fromGradient || '#80D8FF'} />
          <stop offset="100%" stopColor={toGradient || '#E981FC'} />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-122 -385) translate(122 385) translate(0 13)">
          <mask id={`${ID_NAMESPACE}-c`} fill="#fff">
            <use xlinkHref={`#${ID_NAMESPACE}-a`} />
          </mask>
          <path
            fill={`url(#${ID_NAMESPACE}-b)`}
            d="M-122 1321L1318 1321 1318 -398 -122 -398z"
            mask={`url(#${ID_NAMESPACE}-c)`}
          />
        </g>
        <g transform="translate(-122 -385) translate(122 385) translate(0 13)">
          <mask id={`${ID_NAMESPACE}-f`} fill="#fff">
            <use xlinkHref="#d" />
          </mask>
          <path
            fill={`url(#${ID_NAMESPACE}-e)`}
            d="M-122 1321L1318 1321 1318 -398 -122 -398z"
            mask={`url(#${ID_NAMESPACE}-f)`}
          />
        </g>
        <g>
          <g transform="translate(-122 -385) translate(122 385) translate(7)">
            <mask id={`${ID_NAMESPACE}-i`} fill="#fff">
              <use xlinkHref={`#${ID_NAMESPACE}-g`} />
            </mask>
            <path
              fill={`url(#${ID_NAMESPACE}-h)`}
              d="M-129 1334L1311 1334 1311 -385 -129 -385z"
              mask={`url(#${ID_NAMESPACE}-i)`}
            />
          </g>
          <g transform="translate(-122 -385) translate(122 385) translate(7)">
            <mask id={`${ID_NAMESPACE}-l`} fill="#fff">
              <use xlinkHref={`#${ID_NAMESPACE}-j`} />
            </mask>
            <path
              fill={`url(#${ID_NAMESPACE}-k)`}
              d="M-129 1334L1311 1334 1311 -385 -129 -385z"
              mask={`url(#${ID_NAMESPACE}-l)`}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
