import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {FetchStatus} from '../util/enums';
import {StoreState, UserKYCInfo} from '../util/types';
import useKYCBackendURL from './useKYCBackendURL';

/**
 * useKYCCheck
 *
 * Get non-pii kyc data of connected user if it exists
 *
 */
export default function useKYCCheck() {
  /**
   * Selectors
   */

  const walletAuthenticated = useSelector(
    (state: StoreState) => state.blockchain.walletAuthenticated
  );
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );

  /**
   * State
   */

  const [userKYCInfo, setUserKYCInfo] = useState<UserKYCInfo>();
  const [userKYCInfoStatus, setUserKYCInfoStatus] = useState<FetchStatus>(
    FetchStatus.STANDBY
  );

  /**
   * Our hooks
   */

  const kycBackendURL = useKYCBackendURL();

  /**
   * Effects
   */

  // Get non-pii kyc data of connected user if it exists
  useEffect(() => {
    if (walletAuthenticated && connectedAddress) {
      setUserKYCInfoStatus(FetchStatus.PENDING);

      fetch(`${kycBackendURL}/check/${connectedAddress}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (!response.ok) throw response;

          setUserKYCInfoStatus(FetchStatus.FULFILLED);
          return response.json();
        })
        .then(setUserKYCInfo)
        .catch(() => {
          setUserKYCInfoStatus(FetchStatus.REJECTED);
          setUserKYCInfo(undefined);
        });
    }
  }, [kycBackendURL, connectedAddress, walletAuthenticated]);

  return {userKYCInfo, userKYCInfoStatus};
}
