import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import ReactModal from 'react-modal';
import {useSelector} from 'react-redux';

import {US_AND_TERRITORIES_COUNTRY_CODES} from '../../../util/helpers';
import {StoreState} from '../../../util/types';
import {InternalNamesToMap} from '../../../util/orgDomainMappings';
import {useGeo} from '../../../hooks';
import FadeIn from '../../../components/common/FadeIn';
import TimesSVG from '../../../assets/svg/TimesSVG';

import mo from '../../../assets/scss/modules/modal.module.scss';
import b from '../../../assets/scss/modules/buttons.module.scss';
import sm from '../../../assets/scss/modules/sale.module.scss';

type GeoSuggestModalProps = {
  geo: ReturnType<typeof useGeo>;
};

type DisplayFlag = boolean;

// Sets a flag if displayed while the app is running; we don't want to bug the user.
let displayFlag: DisplayFlag = false;

export default function GeoSuggestModal(props: GeoSuggestModalProps) {
  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const [modalClosed, setModalClosed] = useState<boolean>(false);
  const history = useHistory();
  const {pathname} = useLocation();

  const {geo} = props;
  const {asn, country_code = '', emoji_flag = '', threat = {}} = geo;

  const isUsingProxy =
    Object.values(threat).some((t) => t === true) ||
    (asn && asn.type === 'hosting');
  const isInUS = US_AND_TERRITORIES_COUNTRY_CODES.includes(country_code);

  const shouldSuggest =
    (pathname === '/verify' && !isInUS) ||
    (pathname === '/verify/non-us' && isInUS);
  const suggestURL =
    shouldSuggest && pathname === '/verify' && !isInUS
      ? '/verify/non-us'
      : shouldSuggest && pathname === '/verify/non-us' && isInUS
      ? '/verify'
      : '';

  function handleGoToForm(url: string) {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      displayFlag = true;

      setModalClosed(true);

      history.push(url);
    };
  }

  function handleModalClose() {
    displayFlag = true;

    setModalClosed(true);
  }

  function renderCloseButton() {
    switch (orgInternalName) {
      case InternalNamesToMap.nft:
      case InternalNamesToMap.liquidity:
        return (
          <span className="org-modal-close" onClick={handleModalClose}>
            <TimesSVG />
          </span>
        );
      case InternalNamesToMap.thelao:
      default:
        return (
          <div className={mo['close']} style={{top: '.9rem'}}>
            <button className={`${b.underline}`} onClick={handleModalClose}>
              Close
            </button>
          </div>
        );
    }
  }

  // Render `null` if there's no geo data, or they're not using a proxy, or nothing to suggest.
  if (Object.keys(geo).length === 0 || (!isUsingProxy && !shouldSuggest)) {
    return null;
  }

  return (
    <ReactModal
      ariaHideApp={false}
      className={`${mo['modal-content-wide']}`}
      isOpen={displayFlag === false && modalClosed === false}
      onRequestClose={handleModalClose}
      overlayClassName={`${mo['modal-overlay']} org-modal-overlay`}
      role="dialog"
      style={{overlay: {zIndex: '99'}, content: {maxWidth: '32.5rem'}} as any}>
      <FadeIn>
        <div className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} org-modal`}>
          <div className={`${sm.sales} card`}>
            {renderCloseButton()}
            {/**
             * Header
             */}
            <div>
              <h2>
                Hi There{' '}
                <span aria-label="Smile emoji." role="img">
                  🙂
                </span>
              </h2>

              {/**
               * Proxy message
               */}
              {isUsingProxy && (
                <>
                  <p>
                    <span className="color-brightlime org-notification info">
                      We noticed you may be using a VPN, or a proxy.
                    </span>{' '}
                    We&rsquo;ll allow you to submit your application, but we may
                    have follow-up questions for verification.
                  </p>

                  {/**
                   * Display button only if `shouldSuggest` message is not active.
                   */}
                  {!shouldSuggest && (
                    <>
                      <br />

                      <button
                        className={`${b['mini-modal-button']} org-mini-modal-button`}
                        onClick={handleModalClose}>
                        Sounds good
                      </button>
                    </>
                  )}
                </>
              )}

              {/**
               * Location suggestion
               */}
              {shouldSuggest && (
                <>
                  <p>
                    <span className="color-brightlime org-notification info">
                      We {isUsingProxy ? 'also' : ''} noticed you&rsquo;re in{' '}
                      {emoji_flag}.
                    </span>{' '}
                    Would you rather apply as{' '}
                    {isInUS ? 'a US investor' : 'a non-US investor'}?
                  </p>

                  <br />

                  <button
                    className={`${b['mini-modal-button']} org-mini-modal-button`}
                    onClick={handleGoToForm(suggestURL)}>
                    I&rsquo;m a {isInUS ? 'US investor' : 'non-US investor'}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </FadeIn>
    </ReactModal>
  );
}
