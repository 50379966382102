import React from 'react';

import DiscordSVG from '../../../assets/svg/DiscordSVG';
import TelegramSVG from '../../../assets/svg/TelegramSVG';
import TwitterSVG from '../../../assets/svg/TwitterSVG';

import s from '../../../assets/scss/modules/proposaldetails.module.scss';

type ProposalSocialLinksProps = {
  discord: string;
  telegram: string;
  twitterUsername: string;
};

export default function ProposalSocialLinks(props: ProposalSocialLinksProps) {
  const {discord, telegram, twitterUsername} = props;
  const dataExists = discord || telegram || twitterUsername;

  return (
    <>
      {dataExists && (
        <div className={`${s['info-item']} org-info-item`}>
          <span>Social</span>

          <ul className={s['social-link-icons-list']}>
            {discord && (
              <li>
                <a
                  href={discord}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Discord invite link">
                  <span className="hidden">Discord</span>
                  <DiscordSVG width="22" height="22" />
                </a>
              </li>
            )}
            {twitterUsername && (
              <li>
                <a
                  href={`https://twitter.com/${twitterUsername}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Link to Twitter profile">
                  <span className="hidden">Twitter</span>
                  <TwitterSVG height="22" />
                </a>
              </li>
            )}
            {telegram && (
              <li>
                <a
                  href={telegram}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Telegram invite link">
                  <span className="hidden">Telegram</span>
                  <TelegramSVG height="22" />
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
}
