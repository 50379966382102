import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {FetchStatus} from '../util/enums';
import {KYCContributionWhitelistItem, StoreState} from '../util/types';
import {normalizeString} from '../util/helpers';
import useKYCBackendURL from './useKYCBackendURL';

type UseKYCContributionWhitelistReturn = {
  /**
   * A helper function to determine if a provided ethereum address
   * is found in the whitelist. If the whitelist is empty then the
   * function returns `true`, as no restrictions were set.
   */
  kycIsContributionAllowed: (ethereumAddress: string) => boolean;
  kycContributionWhitelist: KYCContributionWhitelistItem[];
  kycContributionWhitelistStatus: FetchStatus;
};

/**
 * useKYCContributionWhitelist
 *
 * Get the KYC contribution whitelist.
 *
 * @returns {KYCContributionWhitelistItem[]}
 */
export default function useKYCContributionWhitelist(): UseKYCContributionWhitelistReturn {
  /**
   * Selectors
   */

  const walletAuthenticated = useSelector(
    (state: StoreState) => state.blockchain.walletAuthenticated
  );
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );

  /**
   * State
   */

  const [kycContributionWhitelist, setKYCContributionWhitelist] = useState<
    KYCContributionWhitelistItem[]
  >([]);
  const [kycContributionWhitelistStatus, setKYCContributionWhitelistStatus] =
    useState<FetchStatus>(FetchStatus.STANDBY);

  /**
   * Our hooks
   */

  const kycBackendURL = useKYCBackendURL();

  /**
   * Cached callbacks
   */

  const kycIsContributionAllowedCached = useCallback(kycIsContributionAllowed, [
    kycContributionWhitelist,
  ]);

  /**
   * Effects
   */

  // Get the KYC contribution whitelist for the org
  useEffect(() => {
    if (walletAuthenticated && connectedAddress) {
      setKYCContributionWhitelistStatus(FetchStatus.PENDING);

      fetch(`${kycBackendURL}/whitelist`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (!response.ok) throw response;

          setKYCContributionWhitelistStatus(FetchStatus.FULFILLED);
          return response.json();
        })
        .then(setKYCContributionWhitelist)
        .catch(() => {
          setKYCContributionWhitelistStatus(FetchStatus.REJECTED);
          setKYCContributionWhitelist([]);
        });
    }
  }, [kycBackendURL, connectedAddress, walletAuthenticated]);

  /**
   * Functions
   */

  /**
   * kycIsContributionAllowed
   *
   * A helper function to determine if a provided ethereum address
   * is found in the whitelist. If the whitelist is empty then the
   * function returns `true`, as no restrictions were set.
   *
   * @param {string} ethereumAddress
   * @returns {boolean}
   */

  function kycIsContributionAllowed(ethereumAddress: string): boolean {
    if (!kycContributionWhitelist || !kycContributionWhitelist.length) {
      return true;
    }

    /**
     * The provided ethereum address matched a value in the whitelist for this org.
     */
    return kycContributionWhitelist.some(
      (w) =>
        normalizeString(w.ethereumAddress) === normalizeString(ethereumAddress)
    );
  }

  return {
    kycContributionWhitelist,
    kycContributionWhitelistStatus,
    kycIsContributionAllowed: kycIsContributionAllowedCached,
  };
}
