import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Web3 from 'web3';

import {
  getConnectedMemberFromSmartContract,
  initContractEasyApply,
  initContractMinion,
  initContractVentureMoloch,
  initContractWrapETH,
} from '../store/actions';

import {useWeb3Modal} from '../components/web3/Web3ModalManager';
import {StoreState, ReduxDispatch} from '../util/types';

/**
 * useInitContracts()
 *
 * Initates contracts used in the app
 */
export default function useInitContracts() {
  const {web3Instance, networkId} = useWeb3Modal();

  const defaultChain = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );

  const dispatch = useDispatch();
  const reduxDispatch = useDispatch<ReduxDispatch>();

  const initContractsCached = useCallback(initContracts, [
    dispatch,
    networkId,
    defaultChain,
    reduxDispatch,
    web3Instance,
  ]);

  /**
   * Init contracts
   *
   * If we are connected to the correct network; init contracts
   */
  function initContracts() {
    try {
      // only if connected to the default chain; init contracts
      if (networkId === defaultChain) {
        // init contracts
        reduxDispatch(initContractVentureMoloch(web3Instance as Web3))
          .then(() => dispatch(initContractWrapETH(web3Instance as Web3)))
          .then(() => dispatch(initContractMinion(web3Instance as Web3)))
          .then(() => dispatch(initContractEasyApply(web3Instance as Web3)))
          .then(() => dispatch(getConnectedMemberFromSmartContract()));
      }
    } catch (error) {
      // console.error(error);
    }
  }

  return {
    initContracts: initContractsCached,
  };
}
