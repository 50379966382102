import React from 'react';
import {useSelector} from 'react-redux';

import {getOrgText} from '../util/helpers';
import {StoreState} from '../util/types';

import DiscordSVG from '../../src/assets/svg/DiscordSVG';
import GitHubSVG from '../../src/assets/svg/GitHubSVG';
import HeadphonesSVG from '../../src/assets/svg/HeadphonesSVG';
import MediumSVG from '../../src/assets/svg/MediumSVG';
import TelegramSVG from '../../src/assets/svg/TelegramSVG';
import TwitterSVG from '../../src/assets/svg/TwitterSVG';

import s from '../assets/scss/modules/index.module.scss';

export default function SocialMedia() {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgTwitterURL = getText('OrgTwitterURL');
  const orgGithubURL = getText('OrgGithubURL');
  const orgMediumURL = getText('OrgMediumURL');
  const orgTelegramURL = getText('OrgTelegramURL');
  const orgDiscordURL = getText('OrgDiscordURL');
  const orgPodcastURL = getText('OrgPodcastURL');

  return (
    <div className={`${s['social-media-wrapper']} org-social-media-wrapper`}>
      {orgTwitterURL && (
        <a href={orgTwitterURL} target="_blank" rel="noopener noreferrer">
          <TwitterSVG />
        </a>
      )}
      {orgGithubURL && (
        <a href={orgGithubURL} target="_blank" rel="noopener noreferrer">
          <GitHubSVG />
        </a>
      )}
      {orgMediumURL && (
        <a href={orgMediumURL} target="_blank" rel="noopener noreferrer">
          <MediumSVG />
        </a>
      )}
      {orgTelegramURL && (
        <a href={orgTelegramURL} target="_blank" rel="noopener noreferrer">
          <TelegramSVG />
        </a>
      )}
      {orgDiscordURL && (
        <a href={orgDiscordURL} target="_blank" rel="noopener noreferrer">
          <DiscordSVG />
        </a>
      )}
      {orgPodcastURL && (
        <a href={orgPodcastURL} target="_blank" rel="noopener noreferrer">
          <HeadphonesSVG style={{transform: 'translateY(-4px)'}} />
        </a>
      )}
    </div>
  );
}
