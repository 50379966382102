import l from '../../assets/scss/modules/logo.module.scss';

interface LogoNFTProps {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function LogoNFT(props: LogoNFTProps) {
  const classNameSizes = {
    flamingo: props.size ? `${l[`flamingo--${props.size}`]}` : '',
  };

  return (
    <div className={`${l.flamingo} ${classNameSizes.flamingo}`}>Flamingo</div>
  );
}
