import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';

import InvestmentSnapshots from './snapshots/Investments';
import GovernanceSnapshots from './snapshots/Governance';

export default function SnapshotOverview() {
  return (
    <div>
      <h1>Snapshot Overview</h1>

      <Tabs forceRenderTabPanel defaultIndex={0}>
        <TabList>
          <Tab>Investments</Tab>
          <Tab>Governance</Tab>
        </TabList>

        <TabPanel>
          <InvestmentSnapshots />
        </TabPanel>
        <TabPanel>
          <GovernanceSnapshots />
        </TabPanel>
      </Tabs>
    </div>
  );
}
