import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {BACKEND_URL_KYC} from '../../../util/config';
import {
  formatNumber,
  getOrgText,
  stripTrailingZeroes,
} from '../../../util/helpers';
import {StoreState} from '../../../util/types';
import {useGeo} from '../../../hooks';
import GeoSuggestModal from './GeoSuggestModal';
import MemberVerifyContainer from './MemberVerifyContainer';

type VerifyStartNonUSProps = {
  geo: ReturnType<typeof useGeo>;
};

export default function VerifyStartNonUS(props: VerifyStartNonUSProps) {
  /**
   * Selectors
   */

  const orgId = useSelector((s: StoreState) => (s.org ? s.org.id : 0));
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const orgSaleSettings = useSelector(
    (s: StoreState) => s.org && s.org.saleSettings
  );

  /**
   * Variables
   */

  const {geo} = props;
  const maxMembers = orgSaleSettings ? orgSaleSettings.maxMembers : 0;
  const maxPercent = orgSaleSettings ? orgSaleSettings.maxPercent : 0;
  const minPercent = orgSaleSettings ? orgSaleSettings.minPercent : 0;
  const maxShares = orgSaleSettings ? orgSaleSettings.maxShares : 0;
  const minShares = orgSaleSettings ? orgSaleSettings.minShares : 0;
  const sharesPerPercent = orgSaleSettings
    ? orgSaleSettings.sharesPerPercent
    : 0;
  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');
  const contributionAmount = orgSaleSettings
    ? orgSaleSettings.ethPerPercent
    : 0;
  const maxContributionAmount = stripTrailingZeroes(
    (contributionAmount * maxPercent).toFixed(2)
  );
  const minContributionAmount = stripTrailingZeroes(
    (contributionAmount * minPercent).toFixed(2)
  );
  const orgVerifyFirstParagraphText = getText('OrgVerifyStartAboutIntro');
  const orgVerifySecondParagraphText = getText('OrgVerifyStartAboutNumbers', {
    contributionAmount: formatNumber(contributionAmount),
    maxContributionAmount: formatNumber(maxContributionAmount),
    minContributionAmount: formatNumber(minContributionAmount),
    maxMembers: maxMembers.toString(),
    maxPercent: maxPercent.toString(),
    maxShares: formatNumber(maxShares),
    minPercent: minPercent.toString(),
    minShares: formatNumber(minShares),
    sharesPerPercent: formatNumber(sharesPerPercent),
    orgName,
  });

  // Safer to make values strings ahead of time to conform to FormData API,
  // even though FormData will try to convert the values to strings.
  const formDataToAppend = {
    // Required by API
    geo: JSON.stringify({
      countryCode: props.geo.country_code || '',
      countryName: props.geo.country_name || '',
      threat:
        {
          ...props.geo.threat,
          // Spread-in any suspected datacentre ip's
          ...(props.geo.asn
            ? {hosting: props.geo.asn.type === 'hosting'}
            : null),
        } || {},
    }),
    // Required by API
    isUSInvestor: 'false',
    // Required by API
    orgId: orgId.toString(),
  };

  return (
    <>
      {/**
       * Maybe show modal if we should suggest another form (via geolocation)
       * and/or if they're using a VPN, or proxy service
       */}
      <GeoSuggestModal geo={geo} />

      <MemberVerifyContainer
        {...props}
        appendFormData={formDataToAppend}
        submitURL={(pathway) => `${BACKEND_URL_KYC}/kyc/${pathway}`}
        views={['entity', 'identity']}
        renderStartContent={() => (
          <div className="org-verify-start">
            <p className="color-brightlime text-center org-verify-subtitle">
              <span
                className="org-verify-start-emoji"
                aria-label="Globe emoji turned to Africa and Europe."
                role="img">
                🌍
              </span>{' '}
              For contributors outside the U.S.{' '}
              <span
                aria-label="Island emoji."
                role="img"
                className="org-verify-start-emoji">
                🏝
              </span>
            </p>

            {orgVerifyFirstParagraphText && (
              <p style={{whiteSpace: 'pre-wrap'}}>
                {orgVerifyFirstParagraphText}
              </p>
            )}
            <p>{orgVerifySecondParagraphText}</p>
            <p>Interested? Get accredited below.</p>

            <p className="org-bold">
              <small>
                Are you a U.S. investor?{' '}
                <Link to="/verify">Take me to the U.S. form.</Link>
              </small>
            </p>
          </div>
        )}
      />
    </>
  );
}
