import React, {useState, useEffect} from 'react';
import ReactModal from 'react-modal';
import {useSelector, useDispatch} from 'react-redux';

import {authServerAccessToken, authServerShowModal} from '../../store/actions';
import {FetchStatus} from '../../util/enums';
import {getAccessToken} from '../../util/helpers';
import {StoreState, MetaMaskRPCError} from '../../util/types';
import AuthenticateToServer from '../actions/AuthenticateToServer';
import ErrorMessageWithDetails from './ErrorMessageWithDetails';
import FadeIn from './FadeIn';
import TimesSVG from '../../assets/svg/TimesSVG';

import b from '../../assets/scss/modules/buttons.module.scss';
import m from '../../assets/scss/modules/modal.module.scss';
import sm from '../../assets/scss/modules/sale.module.scss';

export default function AuthServerModal() {
  /**
   * State
   */

  const [authError, setAuthError] = useState<Error>();

  /**
   * Selectors
   */

  const accessToken = useSelector(
    (s: StoreState) => s.authServer && s.authServer.accessToken
  );
  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.connectedAddress
  );
  const showAuthModal = useSelector(
    (s: StoreState) => s.authServer && s.authServer.showAuthModal
  );

  /**
   * External hooks
   */

  const dispatch = useDispatch();

  /**
   * Hooks
   */

  // Get and possibly set existing access token from localStorage
  useEffect(() => {
    const accessTokenLS = connectedAddress
      ? getAccessToken(connectedAddress)
      : '';

    accessTokenLS && dispatch(authServerAccessToken(accessTokenLS));
  }, [connectedAddress, dispatch]);

  /**
   * Functions
   */

  function handleModalClose() {
    dispatch(authServerShowModal(false));
  }

  // If there's no access token, and `showAuthModal` has been set to `true`, then show.
  const shouldShow = Boolean(accessToken) === false && Boolean(showAuthModal);

  return (
    <>
      {connectedAddress ? (
        <ReactModal
          role="dialog"
          isOpen={shouldShow}
          onRequestClose={handleModalClose}
          style={
            {
              overlay: {zIndex: '99'},
              content: {
                maxWidth: '32.5rem',
              },
            } as any
          }
          ariaHideApp={false}
          className={`${m['modal-content-wide']}`}
          overlayClassName={`${m['modal-overlay']} org-modal-overlay`}>
          <FadeIn>
            <div
              className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} org-modal`}>
              <div className={`${sm.sales} ${m['modal-title']} card`}>
                {/* MODEL CLOSE BUTTON */}
                <span
                  className={`${b['modal-close']} org-modal-close`}
                  onClick={handleModalClose}>
                  <TimesSVG />
                </span>
                <div className="titlebar">
                  <h2 className="titlebar__title org-titlebar__title">
                    Sign in
                  </h2>
                </div>

                <AuthenticateToServer
                  addressToAuthenticate={connectedAddress}
                  onError={setAuthError}
                  render={({attemptAuthentication, authStatus}) => (
                    <div>
                      <p>
                        To authenticate you to the service we&rsquo;ll ask that
                        you sign a message from your wallet.
                      </p>

                      <button
                        className={`${b['mini-modal-button']} org-mini-modal-button`}
                        onClick={attemptAuthentication}
                        style={{margin: '2rem 0'}}>
                        {authStatus === FetchStatus.PENDING
                          ? 'Signing in\u2026'
                          : 'Sign in'}
                      </button>

                      <p>
                        <small className="color-litesmoke org-notification info">
                          Note: If you are using a contract-based wallet they
                          generally do not support signing data.
                        </small>
                      </p>
                    </div>
                  )}
                />

                {/* Don't show error if standard RPC 4001 error (wallet close / reject) */}
                {authError && (authError as MetaMaskRPCError).code !== 4001 && (
                  <ErrorMessageWithDetails
                    error={authError}
                    renderText="There is an error."
                  />
                )}
              </div>
            </div>
          </FadeIn>
        </ReactModal>
      ) : null}
    </>
  );
}
