import React, {useContext, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Web3 from 'web3';

import {ETHERSCAN_URLS} from '../../../util/config';
import {formatNumber, getOrgText} from '../../../util/helpers';
import {Vote, ProposalType} from '../../../util/enums';
import {ProposalsContext, MemberExists, MemberWhitelisted} from '../Members';
import {QueueContext} from '../ProcessingQueue';
import {StoreState, MetaMaskRPCError, Proposal} from '../../../util/types';
import {useProposalPeriodInfo} from '../../../hooks';

import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import Loader from '../../../components/feedback/Loader';
import ProcessProposal from '../../../components/contract/ProcessProposal';
import SubmitVote from '../../../components/contract/SubmitVote';
import ProposalPeriod from '../../../components/ui/ProposalPeriod';

type ProcessingProposalProps = {
  isWhitelisted: boolean;
  isVerified: boolean;
  proposal: Record<string, any>;
};

type Votes = {
  yesVotes: number;
  noVotes: number;
};

export const ProcessingProposalHeader = (
  <thead>
    <tr>
      <th>KYCed</th>
      <th>Index</th>
      <th>Id</th>
      <th>Address</th>
      <th>Shares</th>
      <th>Tribute</th>
      <th>Yes</th>
      <th>No</th>
      <th>Type</th>
      <th>Status</th>
      <th></th>
    </tr>
  </thead>
);

export default function ProcessingProposal(props: ProcessingProposalProps) {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgLoaderTextColor = getText('OrgLoaderTextColor');

  const web3 = new Web3();
  const {moveProcessedProposal} = useContext(ProposalsContext);
  const {moveQueueProposal, sidePocketProposals, projectProposals} =
    useContext(QueueContext);
  const {isWhitelisted, isVerified, proposal} = props;

  const {
    applicant,
    proposalId,
    proposalIndex,
    sharesRequested,
    tributeOffered,
    details,
    yesShares,
    noShares,
  } = proposal;

  const projectsSafe = projectProposals || [];
  const sidePocketsSafe = sidePocketProposals || [];

  const VentureMoloch = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.VentureMoloch
  );
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );

  const proposalPeriodDates = useProposalPeriodInfo(proposal as Proposal);
  const [proposalType, setProposalType] = useState<ProposalType>(
    ProposalType.GENERAL
  );
  const [memberProposalVote, setMemberProposalVote] = useState<number>(0);
  const [votes, setVotes] = useState<Votes>();
  const [displayProcessButton, setDisplayProcessButton] =
    useState<boolean>(false);
  const [displayVoteButton, setDisplayVoteButton] = useState<boolean>(false);

  const projectExistInDB = projectsSafe.find(
    (item: any) => Number(proposalId) === Number(item.molochProposalId)
  );
  const sidePocketExistInDB = sidePocketsSafe.find(
    (item: any) => Number(proposalId) === Number(item.molochProposalId)
  );

  const proposalExistsInDB = projectExistInDB
    ? projectExistInDB
    : sidePocketExistInDB
    ? sidePocketExistInDB
    : null;

  const proposalRoute = projectExistInDB
    ? `/proposals/${projectExistInDB.uuid}`
    : sidePocketExistInDB
    ? `/guildbank-proposals/${sidePocketExistInDB.id}`
    : '';

  const tributeOfferedETH = web3.utils.fromWei(tributeOffered, 'ether');

  const etherscanWriteContractURL = `${ETHERSCAN_URLS[chainId]}/address/${VentureMoloch?.contractAddress}#writeContract`;

  useEffect(() => {
    const {endPeriod, gracePeriod, startPeriod} = proposalPeriodDates;
    if (!gracePeriod) return;
    if (!startPeriod) return;
    if (!endPeriod) return;

    const intervalId = setInterval(() => {
      const currentDate = new Date(Date.now());

      if (startPeriod < currentDate && endPeriod > currentDate) {
        setDisplayVoteButton(true);
      } else if (gracePeriod < currentDate) {
        setDisplayVoteButton(false);
        setDisplayProcessButton(true);

        clearInterval(intervalId);
      } else {
        setDisplayVoteButton(false);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [proposalPeriodDates]);

  useEffect(() => {
    async function getMemberVotes() {
      try {
        const {methods} = (VentureMoloch && VentureMoloch.instance) || {};

        setVotes({yesVotes: yesShares, noVotes: noShares});

        // get connected user vote, if exists
        const memberProposalVote = await methods
          .getMemberProposalVote(connectedAddress, proposalIndex)
          .call({from: connectedAddress}, (result: any) => {
            return result;
          });

        setMemberProposalVote(Number(memberProposalVote));
      } catch (error) {
        console.error(error);
      }
    }
    getMemberVotes();
  }, [VentureMoloch, proposalIndex, connectedAddress, yesShares, noShares]);

  // determine if proposal is a minion
  useEffect(() => {
    if (!details) return;

    // @note default `proposalType` is set to `general` for projects
    if (details.toLowerCase().startsWith(ProposalType.MINION)) {
      setProposalType(ProposalType.MINION);
    } else if (details.toLowerCase().startsWith(ProposalType.MEMBERSHIP)) {
      setProposalType(ProposalType.MEMBERSHIP);
    }
  }, [details]);

  async function voteSubmitted(
    returnedValues: Record<string, any> | undefined
  ) {
    if (!returnedValues) return;
    try {
      const {methods} = (VentureMoloch && VentureMoloch.instance) || {};
      const {yesVotes, noVotes} = await methods
        .proposals(proposalId)
        .call({from: connectedAddress}, (result: any) => {
          return result;
        });

      setVotes({yesVotes, noVotes});
    } catch (error) {}

    setMemberProposalVote(Number(returnedValues.uintVote));
  }

  return (
    <tr>
      <td>
        {isVerified && <MemberExists />}
        {isWhitelisted && <MemberWhitelisted />}
      </td>
      <td>{proposalIndex}</td>
      <td>{proposalId}</td>
      <td>{applicant}</td>
      <td>{formatNumber(sharesRequested)}</td>
      <td>{tributeOfferedETH}</td>
      <td>{votes?.yesVotes}</td>
      <td>{votes?.noVotes}</td>
      <td>
        {details} {projectExistInDB && projectExistInDB.id} <br />
        {projectExistInDB && projectExistInDB.name}
      </td>
      <td>
        <ProposalPeriod proposalPeriodInfo={proposalPeriodDates} />
      </td>
      <td>
        {/** SHOW PROCESS BUTTON  */}
        {displayProcessButton && (
          <>
            {proposalExistsInDB ? (
              <Link to={`${proposalRoute}`}>Process Proposal</Link>
            ) : (
              <ProcessProposal
                proposalType={proposalType}
                proposalIndex={proposalIndex}
                onComplete={(
                  returnedValues: Record<string, any> | undefined
                ) => {
                  moveProcessedProposal &&
                    moveProcessedProposal(returnedValues);
                  moveQueueProposal && moveQueueProposal(returnedValues);
                }}
                render={({
                  error,
                  etherscanURL,
                  isSubmitted,
                  isSubmitting,
                  openPrompt,
                  isPromptOpen,
                }) => (
                  <>
                    {
                      <>
                        {sharesRequested > 0 && (
                          <button
                            className={'org-admin-button'}
                            /**
                             * @note We are loose with the disabling of the button as to allow
                             *   the admin make an educated decision with the infomation provided.
                             */
                            disabled={isSubmitting || isSubmitted}
                            onClick={
                              isSubmitted || isSubmitting
                                ? () => {}
                                : openPrompt
                            }>
                            {isSubmitting ? (
                              <Loader
                                text={
                                  isPromptOpen
                                    ? 'Preparing\u2026'
                                    : 'Processing\u2026'
                                }
                                textProps={{
                                  style: {color: orgLoaderTextColor},
                                }}
                              />
                            ) : isSubmitted ? (
                              'Finishing up\u2026'
                            ) : (
                              'Process'
                            )}
                          </button>
                        )}

                        {etherscanURL && (
                          <p className="text-center">
                            <small>
                              <a
                                href={etherscanURL}
                                rel="noopener noreferrer"
                                target="_blank">
                                (view progress)
                              </a>
                            </small>
                          </p>
                        )}
                      </>
                    }

                    {/* if error is not a MM window close/cancel code */}
                    {error && (error as MetaMaskRPCError).code !== 4001 && (
                      <ErrorMessageWithDetails
                        error={error}
                        renderText="There is an error."
                      />
                    )}

                    {/* if the proposal doesn't exist in the db, process via etherscan */}
                    {!proposalExistsInDB && (
                      <>
                        <a href={etherscanWriteContractURL}>
                          Process Proposal via Etherscan
                        </a>
                      </>
                    )}
                  </>
                )}
              />
            )}
          </>
        )}
        {/** SHOW VOTE BUTTON  */}
        {displayVoteButton && (
          <SubmitVote
            proposalIndex={proposalIndex}
            onComplete={voteSubmitted}
            render={({
              error,
              etherscanURL,
              isSubmitted,
              isSubmitting,
              isPromptOpen,
              openPrompt,
              voteCast,
            }) => (
              <>
                {
                  <>
                    <button
                      className={
                        memberProposalVote === Number(Vote.Yes) ? 'voted' : ''
                      }
                      disabled={
                        isSubmitting ||
                        isSubmitted ||
                        memberProposalVote !== Number(Vote.Abstained)
                      }
                      onClick={() => {
                        const canSubmit =
                          isSubmitted ||
                          isSubmitting ||
                          memberProposalVote !== Number(Vote.Abstained);
                        !canSubmit && openPrompt(Vote.Yes);
                      }}>
                      {isSubmitting && voteCast === Number(Vote.Yes) ? (
                        <Loader
                          text={
                            isPromptOpen
                              ? 'Preparing\u2026'
                              : 'Processing\u2026'
                          }
                          textProps={{
                            style: {color: orgLoaderTextColor},
                          }}
                        />
                      ) : isSubmitted && voteCast === Number(Vote.Yes) ? (
                        'Finishing up\u2026'
                      ) : (
                        'Vote Yes'
                      )}
                    </button>
                    {/*
                    <button
                      className={
                        memberProposalVote === Number(Vote.No) ? 'voted' : ''
                      }
                      disabled={
                        isSubmitting ||
                        isSubmitted ||
                        memberProposalVote !== Number(Vote.Abstained)
                      }
                      onClick={() => {
                        const canSubmit =
                          isSubmitted ||
                          isSubmitting ||
                          memberProposalVote !== Number(Vote.Abstained);
                        !canSubmit && openPrompt(Vote.No);
                      }}>
                      {isSubmitting && voteCast === Number(Vote.No) ? (
                        <Loader
                          text={
                            isPromptOpen
                              ? 'Preparing\u2026'
                              : 'Processing\u2026'
                          }
                          textProps={{
                            style: {color: orgLoaderTextColor}
                          }}
                        />
                      ) : isSubmitted && voteCast === Number(Vote.No) ? (
                        'Finishing up\u2026'
                      ) : (
                        'Vote No'
                      )}
                    </button>
                      */}
                    {etherscanURL && (
                      <p className="text-center">
                        <small>
                          <a
                            href={etherscanURL}
                            rel="noopener noreferrer"
                            target="_blank">
                            (view progress)
                          </a>
                        </small>
                      </p>
                    )}
                  </>
                }

                {/* if error is not a MM window close/cancel code */}
                {error && (error as MetaMaskRPCError).code !== 4001 && (
                  <ErrorMessageWithDetails
                    error={error}
                    renderText="There is an error."
                  />
                )}
              </>
            )}
          />
        )}
      </td>
    </tr>
  );
}
