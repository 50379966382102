import l from '../../assets/scss/modules/logo.module.scss';

interface LogoProps {
  size?: 'small' | 'medium' | 'large' | '';
}

export default function Logo(props: LogoProps) {
  const classNameSizes = {
    the: props.size ? `${l[`the--${props.size}`]}` : '',
    lao: props.size ? `${l[`lao--${props.size}`]}` : '',
  };

  return (
    <>
      <div className={`${l.the} ${classNameSizes.the}`}>THE</div>
      <div className={`${l.lao} ${classNameSizes.lao}`}>LAO</div>
    </>
  );
}
