import React from 'react';
import {useLatestETHPrice} from '../../../hooks';

import {CheckStatus} from './index';

export default function ChainLinkContract() {
  const latestETHPrice = useLatestETHPrice();

  return (
    <div>
      <h2>Chainlink Contract</h2>
      <p>
        <CheckStatus checkStatus={typeof latestETHPrice !== 'undefined'} />{' '}
        Chainlink price feed available:{' '}
        {String(typeof latestETHPrice !== 'undefined')}
      </p>

      <p>
        <CheckStatus checkStatus={typeof latestETHPrice !== 'undefined'} />{' '}
        Current price of ETH as of {new Date(Date.now()).toString()}: $
        {latestETHPrice?.toFixed(2)}
      </p>
    </div>
  );
}
