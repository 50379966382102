import React, {useEffect, useCallback, useRef, useState} from 'react';
import {FormContext, useForm, Controller, useFieldArray} from 'react-hook-form';

import {CountriesEnum, UnitedStatesEnum} from '../../../util/enums';
import {
  ERROR_REQUIRED_FIELD,
  MAX_FILE_SIZE,
  ACCEPTED_DOC_MIME_TYPES,
  ERROR_FILE_TOO_LARGE,
} from './helper';
import {Forms, OnFormChangeCallback, OnFormChangeArgs} from './types';
import {
  getByObjectPath,
  getValidationError,
  numberRangeArray,
} from '../../../util/helpers';
import Address from '../../../components/common/Address';
import FileDropzone from '../../../components/common/FileDropzone';
import IdentityBaseForm from './IdentityBaseForm';
import InputError from '../../../components/common/InputError';
import XCloseSVG from '../../../assets/svg/XCloseSVG';

import b from '../../../assets/scss/modules/buttons.module.scss';
import i from '../../../assets/scss/modules/input.module.scss';
import s from '../../../assets/scss/modules/memberverify.module.scss';

enum Fields {
  companyAddress = 'companyAddress',
  companyIncDay = 'companyIncDay',
  companyIncLocationCountry = 'companyIncLocationCountry',
  companyIncLocationStateIntl = 'companyIncLocationStateIntl',
  companyIncLocationStateUS = 'companyIncLocationStateUS',
  companyIncMonth = 'companyIncMonth',
  companyIncYear = 'companyIncYear',
  companyShareholderOwnership = 'companyShareholderOwnership',
  companyShareholders = 'companyShareholders',
  companySignatoryLegalName = 'companySignatoryLegalName',
  companySignatoryTitle = 'companySignatoryTitle',
  companyType = 'companyType',
  taxIdNumber = 'taxIdNumber', // don't prefix with "company", keep generic for server
}

type Values = {
  [Fields.companyAddress]: string;
  [Fields.companyIncDay]: string;
  [Fields.companyIncLocationCountry]: CountriesEnum;
  [Fields.companyIncLocationStateIntl]: string;
  [Fields.companyIncLocationStateUS]: string;
  [Fields.companyIncMonth]: string;
  [Fields.companyIncYear]: string;
  [Fields.companyShareholderOwnership]: File;
  [Fields.companyShareholders]: {name: string; ssnOrPassportNumber: string}[];
  [Fields.companySignatoryLegalName]: string;
  [Fields.companySignatoryTitle]: string;
  [Fields.companyType]: string;
  [Fields.taxIdNumber]: string;
};

type DerivedValues = {
  companyIncDate: string;
};

export type IdentityCompanyValues = Values & DerivedValues;

type Conditions = {
  [l in Fields]: boolean[];
};

type IdentityCompanyFormProps = {
  onFormChange: OnFormChangeCallback;
};

// validation configuration for react-hook-form
const ruleRequired = {required: ERROR_REQUIRED_FIELD};
const filePDFValidate = {
  validate: (file: File) => {
    return !file
      ? ERROR_REQUIRED_FIELD
      : (file.size > MAX_FILE_SIZE ? true : false)
      ? ERROR_FILE_TOO_LARGE
      : !ACCEPTED_DOC_MIME_TYPES.includes(file.type)
      ? 'The file is not the correct type. Please provide a .pdf, .doc, .docx file.'
      : true;
  },
};
const taxIdNumberValidate = {
  validate: (numberString: string) => {
    return /^\d{2}-\d{7}$|^\d{9}$/.test(numberString) === false
      ? 'Please provide a valid tax ID number.'
      : true;
  },
};
const validateConfig: Partial<Record<Fields, Record<string, any>>> = {
  companyAddress: ruleRequired,
  companyIncDay: ruleRequired,
  companyIncLocationCountry: ruleRequired,
  companyIncLocationStateIntl: ruleRequired,
  companyIncLocationStateUS: ruleRequired,
  companyIncMonth: ruleRequired,
  companyIncYear: ruleRequired,
  companyShareholderOwnership: filePDFValidate,
  companyShareholders: ruleRequired,
  companySignatoryLegalName: ruleRequired,
  companySignatoryTitle: ruleRequired,
  companyType: ruleRequired,
  taxIdNumber: taxIdNumberValidate,
};

const getConditionsMap = (
  formValues: Record<string, any>
): Partial<Conditions> => ({
  [Fields.companyIncLocationStateUS]: [
    formValues[Fields.companyIncLocationCountry] === 'United States',
  ],
  [Fields.companyIncLocationStateIntl]: [
    formValues[Fields.companyIncLocationCountry] !== 'United States',
  ],
  [Fields.taxIdNumber]: [
    formValues[Fields.companyIncLocationCountry] === 'United States',
  ],
});

const dataTransformers: Partial<
  Record<
    keyof IdentityCompanyValues,
    (value: any, allValues: IdentityCompanyValues) => any
  >
> = {
  // join Month, Day, Year (January 1, 1999)
  companyIncDate: (_, values) => {
    const {companyIncMonth, companyIncDay, companyIncYear} = values;
    return companyIncMonth && companyIncDay && companyIncYear
      ? `${companyIncMonth} ${companyIncDay}, ${companyIncYear}`
      : '';
  },
};

export default React.memo(function IdentityCompanyForm(
  props: IdentityCompanyFormProps
) {
  const baseFormDataRef = useRef<OnFormChangeArgs>();
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const {control, errors, formState, register, setError, watch} = form;
  // watch all values that change
  // see: https://react-hook-form.com/api/#watch
  const formValues = watch({nest: true}) as Values;
  const [isBaseFormValid, setIsBaseFormValid] = useState<boolean>(false);
  const shareholdersFieldArray = useFieldArray({
    control,
    name: Fields.companyShareholders,
  });

  /**
   * @note From the docs: "Read the formState before render to subscribe the form state through Proxy"
   * @see https://react-hook-form.com/api#formState
   */
  const {isValid} = formState;

  const conditionsMap = getConditionsMap(formValues);

  const renderConditionsToString = JSON.stringify(
    Object.keys(conditionsMap).map((f) => ({
      [f]: testRenderConditions(f as Fields),
    }))
  );

  const handleBaseFormChange: OnFormChangeCallback = useCallback((formData) => {
    baseFormDataRef.current = formData;

    setIsBaseFormValid(formData.isValid);
  }, []);

  const testRenderConditionsCached = useCallback(testRenderConditions, [
    conditionsMap,
  ]);

  // Add an initial, required shareholder to the array of dynamic fields (add/remove)
  useEffect(() => {
    if (shareholdersFieldArray.fields.length) return;

    shareholdersFieldArray.append({
      name: Fields.companyShareholders,
    });
  }, [shareholdersFieldArray]);

  // send parent the current form status
  useEffect(() => {
    if (!baseFormDataRef.current) return;

    const values = form.getValues({nest: true}) as IdentityCompanyValues;

    // derive any data via transformers before sending to parent
    const derivedData = Object.keys(dataTransformers).reduce((acc, next) => {
      const key = next as keyof IdentityCompanyValues;
      const possibleTransformer = dataTransformers[key];
      if (possibleTransformer) {
        acc[key] = possibleTransformer('', values);
      }
      return acc;
    }, {} as IdentityCompanyValues);

    props.onFormChange({
      formName: Forms.companyIdentity,
      isValid: isValid && baseFormDataRef.current.isValid,
      triggerValidation: async () => {
        const validation = await form.triggerValidation();
        const baseValidation = baseFormDataRef.current
          ? await baseFormDataRef.current.triggerValidation()
          : false;

        return validation && baseValidation;
      },
      values: {...values, ...derivedData, ...baseFormDataRef.current.values},
    });
  }, [form, isBaseFormValid, isValid, props]);

  // re-validate if conditional is shown and has a value
  useEffect(() => {
    Object.keys(conditionsMap).forEach(async (f) => {
      const field = f as Fields;

      (await testRenderConditionsCached(field)) &&
        form.getValues()[field] &&
        form.triggerValidation(field, true);
    });
  }, [
    renderConditionsToString,
    form,
    testRenderConditionsCached,
    conditionsMap,
  ]);

  function testRenderConditions(field: Fields) {
    return (conditionsMap[field] || []).every((c: boolean) => c);
  }

  return (
    <>
      <header>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">Identity</h2>
        </div>
      </header>

      <FormContext {...form}>
        <div className="org-verify-form-wrap">
          <form
            className="org-verify-form"
            onSubmit={(e) => e.preventDefault()}>
            <h3
              className={`${s['form-section-heading']} org-form-section-heading`}>
              Company details
            </h3>

            {/* COMPANY TYPE */}
            <label className={`${i['label--column']} org-label--column`}>
              <span>Company Type</span>
              <select
                aria-describedby="error-companyType"
                aria-invalid={errors.companyType ? 'true' : 'false'}
                name={Fields.companyType}
                ref={
                  validateConfig.companyType &&
                  register(validateConfig.companyType)
                }
                className="org-select">
                <option value=""></option>
                <option value="Corporation">Corporation</option>
                <option value="LLC">LLC</option>
                <option value="S Corporation">S-Corporation</option>
                <option value="Partnership">Partnership</option>
                <option value="Estate">Estate</option>
                <option value="LLP">LLP</option>
                <option value="Exempt organization">Exempt organization</option>
                <option value="Foreign Entity">Foreign entity</option>
              </select>
            </label>
            <InputError
              error={getValidationError(Fields.companyType, errors)}
              id="error-companyType"
            />

            {/* INCORPORATION MONTH */}
            <label className={`${i['label--column']} org-label--column`}>
              Incorporation Date
            </label>
            <div className={`${s['flex-container']} ${s['inputs-row']}`}>
              <div>
                <select
                  aria-describedby="error-companyIncMonth"
                  aria-invalid={errors.companyIncMonth ? 'true' : 'false'}
                  name={Fields.companyIncMonth}
                  ref={
                    validateConfig.companyIncMonth &&
                    register(validateConfig.companyIncMonth)
                  }
                  className="org-select">
                  <option value="">Month</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
                <InputError
                  error={getValidationError(Fields.companyIncMonth, errors)}
                  id="error-companyIncMonth"
                />
              </div>

              {/* INCORPORATION DAY */}
              <div>
                <select
                  aria-describedby="error-companyIncDay"
                  aria-invalid={errors.companyIncDay ? 'true' : 'false'}
                  name={Fields.companyIncDay}
                  ref={
                    validateConfig.companyIncDay &&
                    register(validateConfig.companyIncDay)
                  }
                  className="org-select">
                  <option value="">Day</option>
                  {numberRangeArray(31, 1).map((d) => (
                    <option key={d} value={d}>
                      {d}
                    </option>
                  ))}
                </select>
                <InputError
                  error={getValidationError(Fields.companyIncDay, errors)}
                  id="error-companyIncDay"
                />
              </div>

              {/* INCORPORATION YEAR */}
              <div>
                <select
                  aria-describedby="error-companyIncYear"
                  aria-invalid={errors.companyIncYear ? 'true' : 'false'}
                  name={Fields.companyIncYear}
                  ref={
                    validateConfig.companyIncYear &&
                    register(validateConfig.companyIncYear)
                  }
                  className="org-select">
                  <option value="">Year</option>
                  {numberRangeArray(new Date().getFullYear(), 1920)
                    .map((y) => (
                      <option key={y} value={y}>
                        {y}
                      </option>
                    ))
                    .reverse()}
                </select>
                <InputError
                  error={getValidationError(Fields.companyIncYear, errors)}
                  id="error-companyIncYear"
                />
              </div>
            </div>

            {/* INCORPORATION LOCATION */}
            <label className={`${i['label--column']} org-label--column`}>
              Incorporation Location
            </label>
            <div className={`${s['flex-container']} ${s['inputs-row']}`}>
              <div>
                {/* INCORPORATION LOCATION: COUNTRY */}
                <select
                  aria-describedby="error-companyIncLocationCountry"
                  aria-invalid={
                    errors.companyIncLocationCountry ? 'true' : 'false'
                  }
                  name={Fields.companyIncLocationCountry}
                  ref={
                    validateConfig.companyIncLocationCountry &&
                    register(validateConfig.companyIncLocationCountry)
                  }
                  className="org-select">
                  <option value=""></option>
                  {Object.values(CountriesEnum).map((c) => (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  ))}
                </select>
                <InputError
                  error={getValidationError(
                    Fields.companyIncLocationCountry,
                    errors
                  )}
                  id="error-companyIncLocationCountry"
                />
              </div>

              {/* INCORPORATION LOCATION: STATE (U.S.) */}
              {testRenderConditions(Fields.companyIncLocationStateUS) && (
                <div>
                  <select
                    aria-describedby="error-companyIncLocationStateUS"
                    aria-invalid={
                      errors.companyIncLocationStateUS ? 'true' : 'false'
                    }
                    name={Fields.companyIncLocationStateUS}
                    ref={
                      validateConfig.companyIncLocationStateUS &&
                      register(validateConfig.companyIncLocationStateUS)
                    }
                    className="org-select">
                    <option value="">State/Region</option>
                    {Object.values(UnitedStatesEnum).map((s) => (
                      <option key={s} value={s}>
                        {s}
                      </option>
                    ))}
                  </select>
                  <InputError
                    error={getValidationError(
                      Fields.companyIncLocationStateUS,
                      errors
                    )}
                    id="error-companyIncLocationStateUS"
                  />
                </div>
              )}

              {/* INCORPORATION LOCATION: STATE (INTL.) */}
              {testRenderConditions(Fields.companyIncLocationStateIntl) && (
                <div>
                  <input
                    aria-describedby="error-companyIncLocationStateIntl"
                    aria-invalid={
                      errors.companyIncLocationStateIntl ? 'true' : 'false'
                    }
                    name={Fields.companyIncLocationStateIntl}
                    placeholder="State/Region"
                    ref={
                      validateConfig.companyIncLocationStateIntl &&
                      register(validateConfig.companyIncLocationStateIntl)
                    }
                    type="text"
                  />
                  <InputError
                    error={getValidationError(
                      Fields.companyIncLocationStateIntl,
                      errors
                    )}
                    id="error-companyIncLocationStateIntl"
                  />
                </div>
              )}
            </div>

            {/* COMPANY ADDRESS */}
            <label className={`${i['label--column']} org-label--column`}>
              <span>Company Address</span>
              <Controller
                aria-describedby="error-companyAddress"
                aria-invalid={errors.companyAddress ? 'true' : 'false'}
                as={
                  <Address
                    onChange={() => {}}
                    onAddressError={(error: string) =>
                      setError(Fields.companyAddress, 'network', error)
                    }
                    style={{backgroundColor: 'black'}}
                  />
                }
                name={Fields.companyAddress}
                // @note I would think typically you don't return values
                // from an onChange handler, but it works.
                // see: https://react-hook-form.com/api/#Controller
                onChange={(v: Record<string, any>) => {
                  // v[0] is the formatted address
                  return v[0];
                }}
                rules={validateConfig.companyAddress}
              />
            </label>
            <InputError
              error={getValidationError(Fields.companyAddress, errors)}
              id="error-companyAddress"
            />

            {/* (U.S. ONLY TAX ID NUMBER) */}
            {testRenderConditions(Fields.taxIdNumber) && (
              <>
                <label className={`${i['label--column']} org-label--column`}>
                  <span>(U.S. ONLY) Tax ID Number</span>
                  <input
                    aria-describedby="error-taxIdNumber"
                    aria-invalid={errors.taxIdNumber ? 'true' : 'false'}
                    name={Fields.taxIdNumber}
                    ref={
                      validateConfig.taxIdNumber &&
                      register(validateConfig.taxIdNumber)
                    }
                    type="text"
                  />
                </label>
                <InputError
                  error={getValidationError(Fields.taxIdNumber, errors)}
                  id="error-taxIdNumber"
                />
              </>
            )}

            <h3
              className={`${s['form-section-heading']} org-form-section-heading`}>
              Major shareholders
            </h3>

            {/* SHAREHOLDERS ADD/REMOVE */}
            <div>
              {/* DYNAMIC SHAREHOLDERS */}
              {shareholdersFieldArray.fields.map((field, fieldIndex) => (
                <div
                  className={`${s['flex-container']} ${s['inputs-row']}`}
                  key={field.id}>
                  {/* SHAREHOLDER NAME */}
                  <div className={s['inputs-row-item--w40']}>
                    <label
                      className={`${i['label--column']} org-label--column ${s['collection-label']}`}>
                      <span>Legal name</span>
                      <input
                        aria-describedby={`error-companyShareholdersName-${fieldIndex}`}
                        aria-invalid={
                          errors.companyShareholders ? 'true' : 'false'
                        }
                        name={`${Fields.companyShareholders}[${fieldIndex}].name`}
                        ref={
                          validateConfig.companyShareholders &&
                          register(validateConfig.companyShareholders)
                        }
                        type="text"
                      />
                    </label>
                    <InputError
                      error={getByObjectPath(
                        errors,
                        `${Fields.companyShareholders}[${fieldIndex}].name.message`,
                        ''
                      )}
                      id={`error-companyShareholdersName-${fieldIndex}`}
                    />
                  </div>
                  {/* SHAREHOLDER PASSPORT/SSN */}
                  <div>
                    <label
                      className={`${i['label--column']} org-label--column ${s['collection-label']}`}>
                      <span>Social Security or passport number</span>
                      <input
                        aria-describedby={`error-companyShareholdersIdNumber-${fieldIndex}`}
                        aria-invalid={
                          errors.companyShareholders ? 'true' : 'false'
                        }
                        name={`${Fields.companyShareholders}[${fieldIndex}].ssnOrPassportNumber`}
                        ref={
                          validateConfig.companyShareholders &&
                          register(validateConfig.companyShareholders)
                        }
                        type="text"
                      />
                    </label>

                    <InputError
                      error={getByObjectPath(
                        errors,
                        `${Fields.companyShareholders}[${fieldIndex}].ssnOrPassportNumber.message`,
                        ''
                      )}
                      id={`error-companyShareholdersIdNumber-${fieldIndex}`}
                    />
                  </div>

                  {/* COLLECTION ITEM REMOVE BUTTON */}
                  {fieldIndex > 0 && (
                    <button
                      className={s['collection-remove-button']}
                      onClick={(e) => {
                        e.preventDefault();
                        shareholdersFieldArray.remove(fieldIndex);
                      }}
                      type="button">
                      <XCloseSVG
                        aria-labelledby={`remove-shareholder-${fieldIndex}`}
                        fill="#ff6f6f" /* $color-brightsalmon */
                      />
                      <span
                        id={`remove-shareholder-${fieldIndex}`}
                        className="hidden">
                        Remove
                      </span>
                    </button>
                  )}
                </div>
              ))}

              {/* COLLECTION ITEM ADD BUTTON */}
              {/* Maximum 10 shareholders (including the static one) */}
              {shareholdersFieldArray.fields.length <= 9 && (
                <button
                  className={`${b['secondary']} org-secondary-button ${b['secondary--small']} ${s['collection-add-button']}`}
                  onClick={(e) => {
                    e.preventDefault();
                    shareholdersFieldArray.append({
                      name: Fields.companyShareholders,
                    });
                  }}
                  type="button">
                  Add another shareholder
                </button>
              )}
            </div>

            <h3
              className={`${s['form-section-heading']} org-form-section-heading`}>
              Shareholder Ownership
            </h3>

            {/* SHAREHOLDER OWNERSHIP UPLOAD */}
            <small>
              Upload a document in English language listing major shareholders
              and % ownership. This can be letter from a CPA, CFO, or attorney
              listing ownership, an official government document, or an company
              agreement or schedule listing investors.
            </small>
            <Controller
              as={
                <FileDropzone
                  acceptedTypes={ACCEPTED_DOC_MIME_TYPES}
                  aria-describedby="error-companyShareholderOwnership"
                  aria-invalid={
                    errors.companyShareholderOwnership ? 'true' : 'false'
                  }
                />
              }
              // I would think typically you don't return values
              // from an onChange handler, but it works.
              // see: https://react-hook-form.com/api/#Controller
              onChange={([event]) => event.target.files[0]}
              name={Fields.companyShareholderOwnership}
              rules={validateConfig.companyShareholderOwnership}
            />
            <InputError
              error={getValidationError(
                Fields.companyShareholderOwnership,
                errors
              )}
              id="error-companyShareholderOwnership"
            />

            {/* SIGATORY DETAILS */}

            <h3
              className={`${s['form-section-heading']} org-form-section-heading`}>
              Signatory details
            </h3>

            <label>
              <span className={`${i['label--column']} org-label--column`}>
                Legal Name
              </span>
              <input
                aria-describedby="error-companySignatoryLegalName"
                aria-invalid={
                  errors.companySignatoryLegalName ? 'true' : 'false'
                }
                name={Fields.companySignatoryLegalName}
                placeholder="Your First, Middle & Last Name"
                ref={
                  validateConfig.companySignatoryLegalName &&
                  register(validateConfig.companySignatoryLegalName)
                }
                type="text"
              />
              <InputError
                error={getValidationError(
                  Fields.companySignatoryLegalName,
                  errors
                )}
                id="error-companySignatoryLegalName"
              />
            </label>

            <label>
              <span className={`${i['label--column']} org-label--column`}>
                Title at company
              </span>
              <input
                aria-describedby="error-companySignatoryTitle"
                aria-invalid={errors.companySignatoryTitle ? 'true' : 'false'}
                name={Fields.companySignatoryTitle}
                placeholder="CEO"
                ref={
                  validateConfig.companySignatoryTitle &&
                  register(validateConfig.companySignatoryTitle)
                }
                type="text"
              />
              <InputError
                error={getValidationError(Fields.companySignatoryTitle, errors)}
                id="error-companySignatoryTitle"
              />
            </label>

            <IdentityBaseForm onFormChange={handleBaseFormChange} />
          </form>
        </div>
      </FormContext>
    </>
  );
});
