import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import FadeIn from '../../../components/common/FadeIn';
import Wrap from '../../../components/layout/Wrap';
import InputError from '../../../components/common/InputError';
import Loader from '../../../components/feedback/Loader';
import ErrorMessageWithDetails from '../../../components/common/ErrorMessageWithDetails';
import ETHPrice from '../../../components/contract/ETHPrice';
import SidePocketEasyApply, {
  SidePocketProposalArguments,
} from './contracts/SidePocketEasyApply';

import {authServerShowModal} from '../../../store/actions';
import {StoreState, MetaMaskRPCError, ReduxDispatch} from '../../../util/types';
import {PaymentRequestType} from '../../../util/enums';
import {
  formatNumber,
  stripFormatNumber,
  getAuthHeader,
  getOrgText,
} from '../../../util/helpers';
import {
  hasValue,
  isNumberString,
  isEthAddressValid,
} from '../../../util/validation';
import {BACKEND_URL} from '../../../util/config';

import b from '../../../assets/scss/modules/buttons.module.scss';
import fs from '../../../assets/scss/modules/formsteps.module.scss';
import i from '../../../assets/scss/modules/input.module.scss';

enum FieldNameEnum {
  fundingAmountRequestedUSD = 'fundingAmountRequestedUSD',
  addressToFund = 'addressToFund',
  description = 'description',
  name = 'name',
}

type Values = {
  fundingAmountRequestedUSD: string;
  addressToFund: string;
  description: string;
  name: string;
};

type State = {
  errors: {
    [N in FieldNameEnum]: string;
  };
  errorSubmit: string;
  errorObjectSubmit: Error | undefined;
  fieldsTouched: Array<FieldNameEnum>;
  isReadyForSubmit: boolean;
  isSubmitting: boolean;
  isSubmittingText: string;
  isValidatingProposal: boolean;
  etherscanURL: string;
  molochDepositTokenAddress: string;
  values: Values;
};

const handleValidateFundingAmount = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const valueCleaned = stripFormatNumber(value.trim());
  const possibleNonNumberError = isNumberString(valueCleaned)
    ? ''
    : ERROR_NUMBER_STRING_INVALID;
  const possibleLowValueError =
    Number(valueCleaned) > 0 ? '' : ERROR_NUMBER_TOO_LOW;

  return {
    errorMessage: possibleNonNumberError || possibleLowValueError,
    isError: (possibleNonNumberError || possibleLowValueError).length > 0,
  };
};

const handleValidateEthAddress = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const possibleHasValueError = hasValue(value) ? '' : ERROR_REQUIRED_FIELD;
  const possibleEthAddressError = isEthAddressValid(value)
    ? ''
    : ERROR_ETH_ADDRESS_INVALID;

  return {
    errorMessage: possibleHasValueError || possibleEthAddressError,
    isError: (possibleHasValueError || possibleEthAddressError).length > 0,
  };
};

const handleValidateExists = (
  value: string
): {errorMessage: string; isError: boolean} => {
  const possibleHasValueError = hasValue(value) ? '' : ERROR_REQUIRED_FIELD;

  return {
    errorMessage: possibleHasValueError,
    isError: possibleHasValueError.length > 0,
  };
};

/**
 * displayTransformers
 *
 * Formats a value before setting it in state.
 */
const displayTransformers: Partial<
  Record<FieldNameEnum, (value: any) => string>
> = {
  fundingAmountRequestedUSD: (value: string) => formatNumber(value.trim()),
};

const requestDataTransformers: Partial<
  Record<FieldNameEnum & Values, (value: any, allValues: Values) => any>
> = {
  // convert to lowerCase
  addressToFund: (value: string): string => value.toLowerCase(),
  // Strip number of comma formatting
  fundingAmountRequestedUSD: (value: string) =>
    Number(stripFormatNumber(value)),
};

const ERROR_REQUIRED_FIELD = 'Members need this information.';
const ERROR_NUMBER_TOO_LOW = 'The amount should be at least 1.';
const ERROR_NUMBER_STRING_INVALID = 'Value should be a number.';
const ERROR_ETH_ADDRESS_INVALID = 'The ETH address is invalid.';

export default function EasyApplySidePocketForm() {
  /**
   * Selectors
   */

  const accessToken = useSelector(
    (s: StoreState) => s.authServer && s.authServer.accessToken
  );
  const web3Instance = useSelector(
    (state: StoreState) => state.blockchain && state.blockchain.web3Instance
  );
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain && state.blockchain.connectedAddress
  );
  const orgId = useSelector((s: StoreState) => s.org && s.org.id);
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');

  const backendURL = orgId ? `${BACKEND_URL}/${orgId}` : undefined;
  const INITIAL_STATE: State = {
    errors: {
      fundingAmountRequestedUSD: '',
      addressToFund: '',
      description: '',
      name: '',
    },
    errorSubmit: '',
    errorObjectSubmit: undefined,
    fieldsTouched: [],
    isReadyForSubmit: false,
    isSubmitting: false,
    isSubmittingText: '',
    isValidatingProposal: false,
    etherscanURL: '',
    molochDepositTokenAddress: '',
    values: {
      fundingAmountRequestedUSD: '',
      addressToFund: connectedAddress || '',
      description: '',
      name: '',
    },
  };

  /**
   * State
   */

  const nameRef: React.RefObject<HTMLInputElement> = React.createRef();
  const [state, setState] = useState<State>(INITIAL_STATE);
  const [isSubmitReady, setIsSubmitReady] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [sidePocketId, setSidePocketId] = useState<string | null>(null);
  const [sidePocketUUID, setSidePocketUUID] = useState<string | null>(null);

  const history = useHistory();
  const dispatch = useDispatch<ReduxDispatch>();

  useEffect(() => {
    const isReady =
      Object.values(state.values).every((v) => v) &&
      Object.values(state.errors).every((e) => !e);
    setIsSubmitReady(isReady);
  }, [state.values, state.errors]);

  useEffect(() => {
    let mounted: boolean = true;

    if (mounted) {
      isComplete &&
        sidePocketId &&
        history.push(`/guildbank-proposals/${sidePocketId}`);
    }
    // cleanup
    return function cleanup() {
      mounted = false;
    };
  }, [isComplete, sidePocketId, history]);

  function maybeShowFieldError(
    fieldName: FieldNameEnum,
    showBeforeTouched: boolean = false
  ): React.ReactNode {
    const error = state.errors[fieldName];
    const touched = state.fieldsTouched.indexOf(fieldName) >= 0;

    if ((error && touched) || (error && showBeforeTouched)) {
      return (
        <FadeIn>
          <InputError className={fs['error']} error={error} />
        </FadeIn>
      );
    }

    return null;
  }

  function getTransformedValues() {
    const {values} = state;
    const maybeTransformedValues = {...values} as {
      [key: string]: any;
    };
    const transformers = requestDataTransformers as {
      [key: string]: (v: any, values: Values) => any;
    };

    for (const k in maybeTransformedValues) {
      if (transformers[k]) {
        maybeTransformedValues[k] = transformers[k](
          maybeTransformedValues[k],
          values
        );
      }
    }

    return maybeTransformedValues;
  }

  function handleFieldTouched(fieldName: FieldNameEnum) {
    setState((prevState) => ({
      ...prevState,
      fieldsTouched: [...prevState.fieldsTouched, fieldName],
    }));
  }

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: FieldNameEnum,
    validator: (value: string) => {errorMessage: string; isError: boolean}
  ) {
    const {value} = event.currentTarget;
    const {errors, values} = state;
    const {errorMessage = ''} = validator(value);
    // Possibly transform the value for display
    const maybeDisplayTransformer = displayTransformers[fieldName]
      ? displayTransformers[fieldName]
      : undefined;
    const maybeTransformedValue = maybeDisplayTransformer
      ? maybeDisplayTransformer(value)
      : value;
    const fieldValuesObject = {
      values: {...values, [fieldName]: maybeTransformedValue},
    };
    event.persist();
    setState((prevState) => ({
      ...prevState,
      ...fieldValuesObject,
      errors: {
        ...errors,
        [fieldName]: errorMessage,
      },
    }));
  }

  async function handleOnBeforeProcessSubmitProposal() {
    if (!accessToken) return;

    // Validate data against server before starting.
    // It could happen that the smart contract interaction passes,
    // but for some reason the API responds with a `BadRequest`, etc.
    try {
      setState((prevState) => ({
        ...prevState,
        isValidatingProposal: true,
      }));

      const maybeTransformedValues = getTransformedValues();
      const response = await fetch(`${backendURL}/sidepocket/validate`, {
        method: 'POST',
        body: JSON.stringify(maybeTransformedValues),
        headers: {
          ...getAuthHeader(accessToken),
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 400) {
        throw new Error('Some form values do not look correct.');
      }

      if (!response.ok) {
        throw new Error(
          'Something went wrong while validating the form values.'
        );
      }

      setState((prevState) => ({
        ...prevState,
        isValidatingProposal: false,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isValidatingProposal: false,
      }));
      throw error;
    }
  }

  async function handleOnProcessSubmitProposal() {
    if (!accessToken) return;
    if (isSaved) return;

    // Submit to sidepocket proposal for processing
    try {
      const response = await fetch(`${backendURL}/sidepocket`, {
        method: 'POST',
        body: JSON.stringify({
          ...getTransformedValues(),
        }),
        headers: {
          ...getAuthHeader(accessToken),
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(
          `Something went wrong while submitting the form values to the server. Error status: ${response.status}.`
        );
      }

      const {id, uuid} = await response.json();

      // Set saved state so, we don't save duplicate entries into the db.
      setIsSaved(true);

      // Set proposal id for navigation after submission has made it to the smart contract
      setSidePocketId(id);
      setSidePocketUUID(uuid);

      // Return UUID to suffix in `details`
      return uuid;
    } catch (error) {
      throw error;
    }
  }

  function gotoProposalPage() {
    setIsComplete(true);
  }

  function initiateEasyApplyTx(
    easyApplyPrompt: Function,
    proposalUUID: string
  ) {
    (proposalUUID || sidePocketUUID) &&
      easyApplyPrompt(proposalUUID || sidePocketUUID);
  }

  /**
   * getProposalArguments
   *
   * Gets the proposal arguments for <MinionProposeAction />.
   */
  function getProposalArguments(
    ethPrice: number | null | undefined
  ): SidePocketProposalArguments | undefined {
    const transformedValues = getTransformedValues();

    if (!ethPrice) return;
    if (!web3Instance) return;

    /**
     * Sets the funding amount requested to the user-typed amount.
     *
     * @todo Find a way to use transformedValues in a typesafe way.
     */
    const fundingAmountRequested: number = Number(
      (transformedValues as Values).fundingAmountRequestedUSD
    );

    // Check to make sure we have a number
    if (isNaN(fundingAmountRequested)) return;

    const paymentReqestedInWEI = web3Instance.utils.toWei(
      (fundingAmountRequested / ethPrice).toFixed(4),
      'ether'
    );

    try {
      return {
        addressToFund: state.values.addressToFund,
        paymentReqestedInWEI,
        paymentRequestType: PaymentRequestType.SIDEPOCKET,
      } as SidePocketProposalArguments;
    } catch (error) {
      throw error;
    }
  }

  function handleShowAuthServerModal() {
    // Show the auth modal and exit this process if the user has not authenticated with the server.
    if (!accessToken) {
      dispatch(authServerShowModal(true));
    }
  }

  return (
    <Wrap className={'section-wrapper'}>
      <FadeIn>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">
            Side-Pocket Proposal
          </h2>
          <p>
            This form uses an EasyApply contract. Upon submission the contract
            will <code>submitProposal</code> and <code>sponsorProposal</code>,
            if the tx is successful the page will redirect to the sidepocket
            proposal page.
          </p>
        </div>

        <div className="org-form-wrap">
          <div className={`${fs['content-wrap']}`}>
            <div className={`${fs['input-rows-wrap']}`}>
              {/* PROJECT NAME */}
              <FadeIn>
                <div className={`${fs['input-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Project Name
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <input
                      name={FieldNameEnum.name}
                      onBlur={() => handleFieldTouched(FieldNameEnum.name)}
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) =>
                        handleChange(
                          event,
                          FieldNameEnum.name,
                          handleValidateExists
                        )
                      }
                      placeholder="What's the side-pocket called?"
                      ref={nameRef}
                      type="text"
                      value={state.values.name}
                    />
                    {maybeShowFieldError(FieldNameEnum.name)}
                  </div>
                </div>
              </FadeIn>

              {/* DESCRIPTION */}
              <FadeIn>
                <div className={`${fs['textarea-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Description
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <textarea
                      name={FieldNameEnum.description}
                      onBlur={() =>
                        handleFieldTouched(FieldNameEnum.description)
                      }
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) =>
                        handleChange(
                          event,
                          FieldNameEnum.description,
                          handleValidateExists
                        )
                      }
                      placeholder="Provide some details on the side-pocket"
                      value={state.values.description}
                    />
                    {maybeShowFieldError(FieldNameEnum.description)}
                  </div>
                </div>
              </FadeIn>

              {/* ADDRESS TO FUND */}
              <FadeIn>
                <div className={`${fs['input-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Address to Fund
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <input
                      name={FieldNameEnum.addressToFund}
                      onBlur={() =>
                        handleFieldTouched(FieldNameEnum.addressToFund)
                      }
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) =>
                        handleChange(
                          event,
                          FieldNameEnum.addressToFund,
                          handleValidateEthAddress
                        )
                      }
                      placeholder="ETH address for side-pocket funding"
                      type="text"
                      value={state.values.addressToFund}
                    />

                    {maybeShowFieldError(FieldNameEnum.addressToFund)}

                    <p
                      className={`${fs['input-row-help']} org-input-row-help org-input-row-help--alert color-sunny`}>
                      If the proposal is accepted for funding, this is the
                      address which you will use to withdraw funds from{' '}
                      {orgName}.
                    </p>

                    <p
                      className={`${fs['input-row-help']} org-input-row-help org-input-row-help--alert color-sunny`}>
                      This address cannot change once the form is submitted to
                      the smart contract.
                    </p>
                  </div>
                </div>
              </FadeIn>

              {/* FUNDING AMOUNT */}
              <FadeIn>
                <div className={`${fs['input-row']}`}>
                  <label
                    className={`${fs['input-row-label']} org-input-row-label`}>
                    Requested Funding Amount
                  </label>
                  <div
                    className={`${fs['input-row-fieldwrap']} org-input-row-fieldwrap`}>
                    <div className={i['input-prefix__wrap']}>
                      <input
                        className={`${i['input-prefix--sm']}`}
                        name={FieldNameEnum.fundingAmountRequestedUSD}
                        onBlur={() =>
                          handleFieldTouched(
                            FieldNameEnum.fundingAmountRequestedUSD
                          )
                        }
                        onChange={(
                          event: React.ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) =>
                          handleChange(
                            event,
                            FieldNameEnum.fundingAmountRequestedUSD,
                            handleValidateFundingAmount
                          )
                        }
                        type="text"
                        placeholder="How much do you need?"
                        // Formats number string to have commas for display.
                        value={state.values.fundingAmountRequestedUSD}
                      />
                      <div
                        className={`${i['input-prefix__item--sm']} org-input-prefix__item--sm`}>
                        $
                      </div>
                    </div>
                    {maybeShowFieldError(
                      FieldNameEnum.fundingAmountRequestedUSD
                    )}

                    <p className={`${fs['input-row-help']} org-input-row-help`}>
                      This will be put up for a vote for {orgName} members to
                      approve, which {orgName} members will discuss with you.
                    </p>
                  </div>
                </div>
              </FadeIn>

              {/* SUBMIT */}
              {isSubmitReady && (
                <FadeIn>
                  <ETHPrice
                    render={(ethPrice) => (
                      <SidePocketEasyApply
                        onBeforeProcess={handleOnBeforeProcessSubmitProposal}
                        onComplete={gotoProposalPage}
                        proposalArguments={getProposalArguments(ethPrice)}
                        render={({
                          error,
                          etherscanURL,
                          isPromptOpen,
                          isSubmitted,
                          isSubmitting,
                          openPrompt,
                        }) => (
                          <>
                            <button
                              className={`${b.primary} org-primary-button`}
                              onClick={
                                isSubmitting || isSubmitted || isPromptOpen
                                  ? () => {}
                                  : async () => {
                                      if (accessToken) {
                                        let proposalUUID = '';
                                        if (!isSaved) {
                                          proposalUUID =
                                            await handleOnProcessSubmitProposal();
                                        }

                                        initiateEasyApplyTx(
                                          openPrompt,
                                          proposalUUID
                                        );
                                      } else {
                                        handleShowAuthServerModal();
                                      }
                                    }
                              }
                              type="submit"
                              disabled={
                                isSubmitting || isSubmitted || isPromptOpen
                              }>
                              {state.isValidatingProposal || isPromptOpen ? (
                                <Loader text="Preparing&hellip;" />
                              ) : isSubmitting ? (
                                <Loader text="Processing&hellip;" />
                              ) : (
                                'Submit'
                              )}
                            </button>

                            {/* ETHERSCAN URL */}
                            {etherscanURL && (
                              <p className="text-center">
                                <small>
                                  <a
                                    href={etherscanURL}
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    (view progress)
                                  </a>
                                </small>
                              </p>
                            )}

                            {error &&
                              (error as MetaMaskRPCError).code !== 4001 && (
                                <div className="text-center">
                                  <ErrorMessageWithDetails
                                    error={error}
                                    renderText="Something went wrong while submitting your proposal"
                                  />
                                </div>
                              )}
                          </>
                        )}
                      />
                    )}
                  />
                </FadeIn>
              )}
            </div>
          </div>
        </div>
      </FadeIn>
    </Wrap>
  );
}
