import React from 'react';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';

import {StoreState} from '../../util/types';
import {InternalNamesToMap} from '../../util/orgDomainMappings';
import {getOrgText} from '../../util/helpers';

import h from '../../assets/scss/modules/header.module.scss';

import Logo from './Logo';
import LogoAI from './LogoAI';
import LogoBlue from './LogoBlue';
import LogoBright from './LogoBright';
import LogoCC0 from './LogoCC0';
import LogoDarkhorse from './LogoDarkhorse';
import LogoDoge from './LogoDoge';
import LogoFashion from './LogoFashion';
import LogoGaming from './LogoGaming';
import LogoGlimmer from './LogoGlimmer';
import LogoKRPDM from './LogoKRPDM';
import LogoLiquidity from './LogoLiquidity';
import LogoLong from './LogoLong';
import LogoMetaverse from './LogoMetaverse';
import LogoMusic from './LogoMusic';
import LogoNFT from './LogoNFT';
import LogoNFTv2 from './LogoNFTv2';
import LogoPunk from './LogoPunk';
import LogoScience from './LogoScience';
import LogoSi from './LogoSi';
import LogoSpaceship from './LogoSpaceship';
import LogoTest from './LogoTest';
import LogoTestv2 from './LogoTestv2';
import LogoThelaov2 from './LogoThelaov2';
import LogoUnicorn from './LogoUnicorn';
import LogoUniversity from './LogoUniversity';
import LogoUniversityBC from './LogoUniversityBC';
import LogoUniversityBerkeley from './LogoUniversityBerkeley';
import LogoUniversityCambridge from './LogoUniversityCambridge';
import LogoUniversityColumbia from './LogoUniversityColumbia';
import LogoUniversityCornell from './LogoUniversityCornell';
import LogoUniversityFranklin from './LogoUniversityFranklin';
import LogoUniversityIllinois from './LogoUniversityIllinois';
import LogoUniversityImperial from './LogoUniversityImperial';
import LogoUniversityMichigan from './LogoUniversityMichigan';
import LogoUniversityNewYork from './LogoUniversityNewYork';
import LogoUniversityOregon from './LogoUniversityOregon';
import LogoUniversityPurdue from './LogoUniversityPurdue';
import LogoUniversityTexas from './LogoUniversityTexas';
import LogoUniversityVanderbilt from './LogoUniversityVanderbilt';
import LogoUniversityVirginia from './LogoUniversityVirginia';
import LogoUniversityVox from './LogoUniversityVox';
import LogoUniversityWaterloo from './LogoUniversityWaterloo';
import LogoUrbit from './LogoUrbit';
import LogoZK from './LogoZK';

interface LogoProps {
  size?: 'small' | 'medium' | 'large' | '';
}

// Render the location with or without a link depending on `location`
function RenderLogo(props: React.PropsWithChildren<any>) {
  const location = useLocation();
  const isIndexPath = location.pathname === '/';

  return isIndexPath ? props.children : <Link to="/">{props.children}</Link>;
}

/**
 * ModalLogo
 * This component is used for modal menu
 */
export function ModalLogo() {
  // @todo could move into a style hook, DRY code
  const internalName =
    useSelector((s: StoreState) => s.org && s.org.internalName) || '';

  const rotateStyle =
    internalName === InternalNamesToMap.thelao
      ? {width: '8rem', marginTop: '1rem'}
      : {};
  const shouldRotate =
    internalName &&
    [InternalNamesToMap.thelao].includes(internalName as InternalNamesToMap);

  return (
    <div
      className={`${h.title} org-modal-menu-title ${
        shouldRotate && h['title--rotate']
      }`}
      style={{...rotateStyle}}>
      <OrgLogo size={'small'} />
    </div>
  );
}

/**
 * LeftLogo
 * This component is used for main pages (exc splash/contribute)
 */
export function LeftLogo() {
  // @todo could move into a style hook, DRY code
  const internalName =
    useSelector((s: StoreState) => s.org && s.org.internalName) || '';

  const rotateStyle =
    internalName === InternalNamesToMap.thelao
      ? {width: '8rem', marginTop: '1rem'}
      : {};
  const shouldRotate =
    internalName &&
    [InternalNamesToMap.thelao].includes(internalName as InternalNamesToMap);

  return (
    <div
      className={`${h.title} ${
        shouldRotate && h['title--rotate']
      } org-header-title`}
      style={{...rotateStyle}}>
      <OrgLogo size={'medium'} />
    </div>
  );
}

/**
 * CenterLogo
 * This component is used for splash and contribute pages
 */
export function CenterLogo() {
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);
  const getText = getOrgText(orgText);
  const orgTagLine = getText('OrgTagLine');

  return (
    <div className={`${h['openlaw-logo']} org-openlaw-logo`}>
      <div
        className={`${h.title} ${h['title--center']} org-header-title--center`}>
        <RenderLogo>
          <OrgLogo size={'large'} />
        </RenderLogo>

        <h2 className={`${h['tag-line']} org-tag-line`}>{orgTagLine}</h2>
      </div>
    </div>
  );
}

/**
 * Default export OrgLogo
 * @param props size "small|medium|large|<empty>"
 */
export default function OrgLogo(props: LogoProps) {
  const org = useSelector((s: StoreState) => s.org);

  function renderOrgLogo(org: string | null) {
    if (!org) return;

    switch (org) {
      case InternalNamesToMap.ai:
        return <LogoAI {...props} />;
      case InternalNamesToMap.blue:
        return <LogoBlue {...props} />;
      case InternalNamesToMap.bright:
        return <LogoBright {...props} />;
      case InternalNamesToMap.cc0:
        return <LogoCC0 orgInternalName={org} {...props} />;
      case InternalNamesToMap.darkhorse:
        return <LogoDarkhorse orgInternalName={org} {...props} />;
      case InternalNamesToMap.doge:
        return <LogoDoge {...props} />;
      case InternalNamesToMap.fashion:
        return <LogoFashion {...props} />;
      case InternalNamesToMap.gaming:
        return <LogoGaming orgInternalName={org} {...props} />;
      case InternalNamesToMap.glimmer:
        return <LogoGlimmer {...props} />;
      case InternalNamesToMap.krpdm:
        return <LogoKRPDM {...props} />;
      case InternalNamesToMap.liquidity:
        return <LogoLiquidity orgInternalName={org} {...props} />;
      case InternalNamesToMap.long:
        return <LogoLong {...props} />;
      case InternalNamesToMap.metaverse:
        return <LogoMetaverse orgInternalName={org} {...props} />;
      case InternalNamesToMap.music:
        return <LogoMusic {...props} />;
      case InternalNamesToMap.nft:
        return <LogoNFT {...props} />;
      case InternalNamesToMap.nftv2:
        return <LogoNFTv2 {...props} />;
      case InternalNamesToMap.punk:
        return <LogoPunk {...props} />;
      case InternalNamesToMap.science:
        return <LogoScience {...props} />;
      case InternalNamesToMap.si:
        return <LogoSi orgInternalName={org} {...props} />;
      case InternalNamesToMap.spaceship:
        return <LogoSpaceship {...props} />;
      case InternalNamesToMap.test:
        return <LogoTest orgInternalName={org} {...props} />;
      case InternalNamesToMap.testv2:
        return <LogoTestv2 {...props} />;
      case InternalNamesToMap.thelaov2:
        return <LogoThelaov2 {...props} />;
      case InternalNamesToMap.unicorn:
        return <LogoUnicorn {...props} />;
      case InternalNamesToMap.university:
        return <LogoUniversity {...props} />;
      case InternalNamesToMap['university-bc']:
        return <LogoUniversityBC {...props} />;
      case InternalNamesToMap['university-berkeley']:
        return <LogoUniversityBerkeley {...props} />;
      case InternalNamesToMap['university-cambridge']:
        return <LogoUniversityCambridge {...props} />;
      case InternalNamesToMap['university-columbia']:
        return <LogoUniversityColumbia {...props} />;
      case InternalNamesToMap['university-cornell']:
        return <LogoUniversityCornell {...props} />;
      case InternalNamesToMap['university-franklin']:
        return <LogoUniversityFranklin {...props} />;
      case InternalNamesToMap['university-illinois']:
        return <LogoUniversityIllinois {...props} />;
      case InternalNamesToMap['university-imperial']:
        return <LogoUniversityImperial {...props} />;
      case InternalNamesToMap['university-michigan']:
        return <LogoUniversityMichigan {...props} />;
      case InternalNamesToMap['university-new-york']:
        return <LogoUniversityNewYork {...props} />;
      case InternalNamesToMap['university-oregon']:
        return <LogoUniversityOregon {...props} />;
      case InternalNamesToMap['university-purdue']:
        return <LogoUniversityPurdue {...props} />;
      case InternalNamesToMap['university-texas']:
        return <LogoUniversityTexas {...props} />;
      case InternalNamesToMap['university-vanderbilt']:
        return <LogoUniversityVanderbilt {...props} />;
      case InternalNamesToMap['university-virginia']:
        return <LogoUniversityVirginia {...props} />;
      case InternalNamesToMap['university-vox']:
        return <LogoUniversityVox {...props} />;
      case InternalNamesToMap['university-waterloo']:
        return <LogoUniversityWaterloo {...props} />;
      case InternalNamesToMap.urbit:
        return <LogoUrbit {...props} />;
      case InternalNamesToMap.zk:
        return <LogoZK {...props} />;
      case InternalNamesToMap.thelao:
      default:
        return <Logo {...props} />;
    }
  }

  return <>{renderOrgLogo(org && org.internalName)}</>;
}
