import {FetchStatus} from '../../util/enums';
import {UserState, StoreState} from '../../util/types';
import {getAuthHeader} from '../../util/helpers';

export const ACCOUNT_FETCH_STATUS = 'ACCOUNT_FETCH_STATUS';
export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';
export const FETCH_ACCOUNT_ERROR = 'FETCH_ACCOUNT_ERROR';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_CURRENT_ACCOUNT = 'FETCH_CURRENT_ACCOUNT';
export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const USER_UPDATE = 'USER_UPDATE';

/**
 * getCurrentUser
 *
 * @param {string} address
 * @param {string} backendURL - Required as an arg, as we don't know if the org has been fetched
 *   yet and we need the id to build the backend URL. Therefore we can't use `getState()` from redux thunk.
 */
export function getCurrentUser(address: string, backendURL: string) {
  return async function (dispatch: any) {
    dispatch({
      type: ACCOUNT_FETCH_STATUS,
      fetchStatus: FetchStatus.PENDING,
    });

    try {
      const response = await fetch(`${backendURL}/users/${address}`, {
        method: 'GET',
      });

      /**
       * Error response handling
       */

      if (response.status === 400) {
        throw new Error("Something doesn't look correct.");
      }

      if (response.status === 404) {
        throw new Error('User was not found.');
      }

      if (!response.ok) {
        throw new Error('Something went wrong while updating your account.');
      }

      const responseJSON = await response.json();

      dispatch({
        type: ACCOUNT_FETCH_STATUS,
        fetchStatus: FetchStatus.FULFILLED,
      });

      dispatch({
        type: FETCH_ACCOUNT_SUCCESS,
        user: responseJSON,
      });

      return responseJSON as UserState;
    } catch (error) {
      dispatch({
        type: ACCOUNT_FETCH_STATUS,
        fetchStatus: FetchStatus.REJECTED,
      });

      dispatch({
        type: FETCH_ACCOUNT_ERROR,
        user: {},
      });

      throw error;
    }
  };
}

/**
 * updateCurrentUser
 *
 * @param {Partial<UserState>} updates
 * @param {string} backendURL - Required as an arg, as we don't know if the org has been fetched
 *   yet and we need the id to build the backend URL. Therefore we can't use `getState()` from redux thunk.
 */
export function updateCurrentUser(
  ethereumAddress: string,
  updates: Partial<UserState>,
  backendURL: string
) {
  return async function (dispatch: any, getState: () => StoreState) {
    try {
      const currentState = getState();
      const accessToken = currentState.authServer
        ? currentState.authServer.accessToken
        : '';

      const response = await fetch(`${backendURL}/users/${ethereumAddress}`, {
        method: 'PATCH',
        body: JSON.stringify(updates),
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader(accessToken),
        },
      });

      if (response.status === 400) {
        throw new Error("Something doesn't look correct.");
      }

      if (response.status === 404) {
        throw new Error('User was not found.');
      }

      if (!response.ok) {
        throw new Error('Something went wrong while updating your account.');
      }

      const responseJSON = await response.json();

      dispatch(updateCurrentUserData(responseJSON));
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function resetCurrentUser() {
  return function (dispatch: any) {
    return dispatch({
      type: RESET_CURRENT_USER,
    });
  };
}

export function updateCurrentUserData(userData: Partial<UserState>) {
  return {
    type: USER_UPDATE,
    user: userData,
  };
}
