import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {dontCloseWindowWarning} from '../../util/helpers';
import {ETHERSCAN_URLS} from '../../util/config';
import {StoreState, MetaMaskRPCError} from '../../util/types';
import {useETHGasPrice} from '../../hooks';

type MinionExecuteActionChildrenProps = {
  error?: Error;
  etherscanURL: string;
  isPromptOpen: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  openPrompt: () => void;
};

type MinionExecuteActionProps = {
  proposalId: number | undefined;
  /**
   * Runs before wallet prompt opens; provides an
   * opportunity to run any validation.
   *
   * If an `Error` is thrown, the prompt will not open and
   * an `error` argument will be provided to the `render` prop.
   */
  onBeforeProcess?: () => void;
  onProcess?: (txHash: string) => void;
  onError?: (e: Error) => void;
  onComplete?: (eventReturnedValues: Record<string, any>) => void;
  render: (props: MinionExecuteActionChildrenProps) => React.ReactElement;
};

/**
 * MinionExecuteAction
 *
 * Returns a rendered component via a render prop.
 * MinionExecuteActionChildrenProps are passed to the render prop.
 *
 * @param {MinionExecuteActionProps} props
 * @returns {FunctionComponent}
 */
export default function MinionExecuteAction(props: MinionExecuteActionProps) {
  const {onBeforeProcess, onError, onProcess, onComplete, proposalId, render} =
    props;

  /**
   * Use these as the moloch proposal id and index could be -1 from the server.
   *
   * @todo Look into making the db entry `null` by default.
   */
  const proposalIdSafe =
    proposalId !== undefined && proposalId !== -1 ? proposalId : undefined;

  /**
   * State
   */

  const [error, setError] = useState<Error>();
  const [etherscanURL, setEtherscanURL] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /**
   * Selectors
   */

  const web3Instance = useSelector(
    (state: StoreState) => state.blockchain.web3Instance
  );
  const Minion = useSelector(
    (state: StoreState) =>
      state.blockchain.contracts && state.blockchain.contracts.Minion
  );
  const connectedAddress = useSelector(
    (state: StoreState) => state.blockchain.connectedAddress
  );
  const chainId = useSelector(
    (s: StoreState) => s.blockchain && s.blockchain.defaultChain
  );

  /**
   * External Hooks
   */

  const {average: gasPrice} = useETHGasPrice();

  /**
   * Functions
   */

  function handleError(error: Error) {
    setError(error);
    setIsSubmitted(false);
    setIsSubmitting(false);

    onError && onError(error);
  }

  async function handleProcessPrompt() {
    if (!web3Instance) return;

    // activate "don't close window" warning
    const unsubscribeDontCloseWindow = dontCloseWindowWarning();

    try {
      if (!proposalIdSafe) {
        throw new Error('No proposal id was found.');
      }

      if (!Minion) {
        throw new Error('No minion contract found.');
      }

      setError(undefined);
      setEtherscanURL('');
      setIsPromptOpen(true);
      setIsSubmitted(false);
      setIsSubmitting(false);

      const {methods} = (Minion && Minion.instance) || {};

      /**
       * Run user callback `onBeforeProcess`
       * @note We `await` just in case the value is a Promise.
       */
      onBeforeProcess && (await onBeforeProcess());

      /**
       * executeAction
       *
       * @param {number} proposalId - Proposal id to process
       */
      const receipt = await methods['executeAction'](proposalId)
        .send({
          from: connectedAddress,
          // set proposed gas price
          ...(gasPrice ? {gasPrice} : null),
        })
        .on('transactionHash', function (txHash: string) {
          setIsPromptOpen(false);
          setIsSubmitting(true);
          setEtherscanURL(`${ETHERSCAN_URLS[chainId]}/tx/${txHash}`);

          // Maybe call user `onProcess` and handle any `Error`.
          try {
            onProcess && onProcess(txHash);
          } catch (error) {
            handleError(error);
          }
        })
        .on('error', (error: Error | MetaMaskRPCError) => {
          handleError(error);

          // if user closed modal (MetaMask error code 4001)
          // or via WalletConnect, which only provides a message and no code
          setIsPromptOpen(false);

          unsubscribeDontCloseWindow();
        });

      /**
       * Get `returnValues` from `receipt`.
       *
       * @see https://web3js.readthedocs.io/en/v1.2.7/web3-eth-contract.html#id37
       */
      const {
        ActionExecuted: {returnValues},
      } = receipt.events;

      // Send `returnValues` in callback.
      onComplete && onComplete(returnValues);

      // Tx `receipt` resolved; tx went through.
      setIsSubmitted(false);
      setIsSubmitting(false);

      unsubscribeDontCloseWindow();
    } catch (error) {
      setIsPromptOpen(false);

      handleError(error);

      unsubscribeDontCloseWindow();
    }
  }

  return render({
    error,
    etherscanURL,
    isPromptOpen,
    isSubmitted,
    isSubmitting,
    openPrompt: handleProcessPrompt,
  });
}
