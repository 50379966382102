import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  GetInvestmentProposalResponse,
  ReduxDispatch,
  StoreState,
} from '../../util/types';
import {authServerShowModal} from '../../store/actions';
import {getOrgText} from '../../util/helpers';
import EmojiToggle from '../../components/feedback/EmojiToggle';
import FadeIn from '../../components/common/FadeIn';
import useUpdateInvestmentProposalPrivacy from '../../hooks/useUpdateInvestmentProposalPrivacy';

import s from '../../assets/scss/modules/proposaldetails.module.scss';

type InvestmentProposalPrivacyProps = {
  proposal: GetInvestmentProposalResponse;
};

export default function InvestmentProposalPrivacy(
  props: InvestmentProposalPrivacyProps
) {
  const {proposal} = props;

  /**
   * Selectors
   */

  const accessToken = useSelector(
    (s: StoreState) => s.authServer && s.authServer.accessToken
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  /**
   * Their hooks
   */

  const dispatch = useDispatch<ReduxDispatch>();

  /**
   * Our hooks
   */

  const {updateProposalPrivacy, privacyConfirmMessage} =
    useUpdateInvestmentProposalPrivacy(proposal.uuid);

  /**
   * Variables
   */

  const getText = getOrgText(orgText);
  const orgProjectText = getText('OrgProjectText');

  /**
   * Functions
   */

  function handleShowAuthServerModal() {
    // Show the auth modal and exit this process if the user has not authenticated with the server.
    if (!accessToken) {
      dispatch(authServerShowModal(true));
    }
  }

  return (
    <div className={s.privacy}>
      <p className={s.privacy__text}>
        Allow others to view your {orgProjectText.toLowerCase()}?
      </p>

      <div className={s.privacy__settings}>
        <div onClick={handleShowAuthServerModal}>
          <EmojiToggle
            emojis={['😃', '🤫']}
            isChecked={proposal.private}
            isDisabled={Boolean(accessToken) === false}
            onToggle={updateProposalPrivacy}
            text={['Public', 'Private']}
          />
        </div>

        {/* PRIVACY: "SAVED" MESSAGE */}
        {privacyConfirmMessage && (
          <FadeIn>
            <span
              className={`${s.privacy__confirmMessage} org-privacy__confirmMessage`}>
              {privacyConfirmMessage}
            </span>
          </FadeIn>
        )}
      </div>
    </div>
  );
}
