import React from 'react';
import {useSelector} from 'react-redux';

import {StoreState} from '../../util/types';
import {InternalNamesToMap} from '../../util/orgDomainMappings';
import ProjectDefaultIcon from './ProjectDefaultIcon';

import v from '../../assets/scss/modules/votinghistory.module.scss';

export type VotingHistoryItemProps = {
  key?: React.Key;
  name: string;
  proposalId: number;
  proposalIndex: number;
  proposalInternalId: number;
  status: string;
  voted: number;
};

type VotingHistoryItemPropsExtended = VotingHistoryItemProps & {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export default function VotingHistoryItem(
  props: VotingHistoryItemPropsExtended
) {
  const {name, proposalId, proposalInternalId, status} = props;

  const orgInternalName = useSelector(
    (s: StoreState) => s.org && s.org.internalName
  );
  const voted = props.voted === 1 ? 'Yes' : 'No';

  return (
    <section
      className={v.row}
      data-proposal-id={proposalInternalId}
      onClick={props.onClick}>
      <div className={v.proposalNameRow}>
        {orgInternalName === InternalNamesToMap.thelao ? (
          <ProjectDefaultIcon proposalId={proposalId} />
        ) : (
          <img
            alt="Proposal default icon"
            src={`/orgs/${orgInternalName}/images/proposal-default-icon.svg`}
          />
        )}
        <span className={v.proposalName}>{name}</span>
      </div>

      <ul className={`${v.memberVote} org-memberVote`}>
        <li
          className={`${v[status.toLowerCase()]} org-${status.toLowerCase()}`}>
          <span>{status}</span>
        </li>
        <li>
          Voted{' '}
          <span
            className={`${v[voted.toLowerCase()]} org-${voted.toLowerCase()}`}>
            {voted}
          </span>
        </li>
      </ul>
    </section>
  );
}
