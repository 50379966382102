import React from 'react';

export default function FileSVG(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="31px"
      height="40px"
      viewBox="0 0 31 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <title>File</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="File" fill="currentColor">
          <path
            d="M23.3333333,15 L6.66666667,15 C6.29866667,15 6,15.2235 6,15.5 C6,15.7765 6.29866667,16 6.66666667,16 L23.3333333,16 C23.7013333,16 24,15.7765 24,15.5 C24,15.2235 23.7013333,15 23.3333333,15 Z"
            id="Path"></path>
          <path
            d="M6.66666667,11 L13.3333333,11 C13.7013333,11 14,10.7765 14,10.5 C14,10.2235 13.7013333,10 13.3333333,10 L6.66666667,10 C6.29866667,10 6,10.2235 6,10.5 C6,10.7765 6.29866667,11 6.66666667,11 Z"
            id="Path"></path>
          <path
            d="M23.3333333,20 L6.66666667,20 C6.29866667,20 6,20.2235 6,20.5 C6,20.7765 6.29866667,21 6.66666667,21 L23.3333333,21 C23.7013333,21 24,20.7765 24,20.5 C24,20.2235 23.7013333,20 23.3333333,20 Z"
            id="Path"></path>
          <path
            d="M23.3333333,26 L6.66666667,26 C6.29866667,26 6,26.2235 6,26.5 C6,26.7765 6.29866667,27 6.66666667,27 L23.3333333,27 C23.7013333,27 24,26.7765 24,26.5 C24,26.2235 23.7013333,26 23.3333333,26 Z"
            id="Path"></path>
          <path
            d="M23.3333333,31 L6.66666667,31 C6.29866667,31 6,31.2235 6,31.5 C6,31.7765 6.29866667,32 6.66666667,32 L23.3333333,32 C23.7013333,32 24,31.7765 24,31.5 C24,31.2235 23.7013333,31 23.3333333,31 Z"
            id="Path"></path>
          <path
            d="M21.3794468,0 L0,0 L0,40 L31,40 L31,9.724 L21.3794468,0 Z M21.7659574,2.276 L28.7482128,9.33333333 L21.7659574,9.33333333 L21.7659574,2.276 Z M1.31914894,38.6666667 L1.31914894,1.33333333 L20.4468085,1.33333333 L20.4468085,10.6666667 L29.6808511,10.6666667 L29.6808511,38.6666667 L1.31914894,38.6666667 Z"
            id="Shape"
            fillRule="nonzero"></path>
        </g>
      </g>
    </svg>
  );
}
