import React from 'react';
import {useSelector} from 'react-redux';

import {StoreState} from '../../util/types';

import TwitterSVG from '../../assets/svg/TwitterSVG';
import GitHubSVG from '../../assets/svg/GitHubSVG';
import TelegramSVG from '../../assets/svg/TelegramSVG';
import LinkedInSVG from '../../assets/svg/LinkedInSVG';

import s from '../../assets/scss/modules/mentorcard.module.scss';
import i from '../../assets/scss/modules/index.module.scss';

enum SocialMediaTypes {
  TWITTER = 'twitter',
  GITHUB = 'github',
  TELEGRAM = 'telegram',
  LINKEDIN = 'linkedin',
}

type SocialMediaLinks = {
  twitter?: string;
  telegram?: string;
  github?: string;
  linkedin?: string;
};

type MentorCardAltProps = {
  children: string | React.ReactNode;
  name: string;
  handle: string; // unused in this component for now
  socialMediaLinks?: SocialMediaLinks;
};

// Alternate Mentor Card to better handle (1) fewer social links (likely only
// twitter), (2) no emoji, and (3) short bio for each mentor that won't require
// showing more in a modal
export default function MentorCardAlt(props: MentorCardAltProps) {
  const orgFeatures = useSelector((s: StoreState) => s.org && s.org.features);
  const showMentorModal = orgFeatures && orgFeatures.showMentorModal;
  const bio: string | React.ReactNode = props.children;

  function renderSocialMediaURL(
    socialMediaType: keyof SocialMediaLinks | undefined,
    socialMediaURL: string | undefined
  ) {
    switch (socialMediaType) {
      case SocialMediaTypes.TWITTER:
        return (
          <a
            key={'twitter'}
            href={`${socialMediaURL}`}
            className={''}
            target="_blank"
            rel="noopener noreferrer">
            <TwitterSVG />
          </a>
        );
      case SocialMediaTypes.GITHUB:
        return (
          <a
            key={'github'}
            href={`${socialMediaURL}`}
            className={''}
            target="_blank"
            rel="noopener noreferrer">
            <GitHubSVG />
          </a>
        );
      case SocialMediaTypes.TELEGRAM:
        return (
          <a
            key={'telegram'}
            href={`${socialMediaURL}`}
            className={''}
            target="_blank"
            rel="noopener noreferrer">
            <TelegramSVG />
          </a>
        );
      case SocialMediaTypes.LINKEDIN:
        return (
          <a
            key={'linkedin'}
            href={`${socialMediaURL}`}
            className={''}
            target="_blank"
            rel="noopener noreferrer">
            <LinkedInSVG />
          </a>
        );
      default:
        break;
    }
  }

  return (
    <div
      className={`${s['mentor-card']} ${
        s['mentor-card--alt']
      } org-mentor-card ${
        showMentorModal ? 'org-mentor-card--is-clickable' : ''
      }`}>
      <div className={`${s['mentor-contact']} org-mentor-contact`}>
        {props.socialMediaLinks && (
          <div
            key={'social-links'}
            className={`${i['social-media-wrapper']} org-social-media-wrapper ${s['mentor-social-media']}`}>
            {Object.entries(props.socialMediaLinks).map(([key, val]) =>
              renderSocialMediaURL(key as keyof SocialMediaLinks, val)
            )}
          </div>
        )}
        <div className={s['mentor-name-container']}>
          <span className={`${s['mentor-name']} org-mentor-name`}>
            {props.name}
          </span>
        </div>
      </div>
      {bio && <div className={`${s['mentor-bio']} org-mentor-bio`}>{bio}</div>}
    </div>
  );
}
