import React from 'react';

import FundsProofBaseForm from './FundsProofBaseForm';
import {OnFormChangeCallback, Forms} from './types';

type FundsProofPersonFormProps = {
  onFormChange: OnFormChangeCallback;
};

export default React.memo(function FundsProofPersonForm(
  props: FundsProofPersonFormProps
) {
  return (
    <>
      <header>
        <div className="titlebar">
          <h2 className="titlebar__title org-titlebar__title">Accreditation</h2>
        </div>
      </header>

      <div className="org-verify-form-wrap">
        <form className="org-verify-form" onSubmit={(e) => e.preventDefault()}>
          <FundsProofBaseForm
            onFormChange={(formData) => {
              props.onFormChange({
                ...formData,
                formName: Forms.personFundsProof,
              });
            }}
          />
        </form>
      </div>
    </>
  );
});
