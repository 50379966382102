import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactModal from 'react-modal';

import {FetchStatus, Web3TxStatus} from '../../util/enums';
import {formatNumber, getOrgText} from '../../util/helpers';
import {getConnectedMemberFromSmartContract} from '../../store/actions';
import {MetaMaskRPCError, StoreState} from '../../util/types';
import {useApprovedTokens, useBackendURL} from '../../hooks';
import ErrorMessageWithDetails from '../../components/common/ErrorMessageWithDetails';
import FadeIn from '../../components/common/FadeIn';
import Loader from '../../components/feedback/Loader';
import Ragequit from './Ragequit';
import UnwrapWETH from '../../components/contract/UnwrapWETH';
import Withdraw, {
  OnProcessOrSuccessData as WithdrawOnProcessOrSuccessData,
} from '../../components/contract/Withdraw';

import i from '../../assets/scss/modules/input.module.scss';
import m from '../../assets/scss/modules/modal.module.scss';
import sm from '../../assets/scss/modules/sale.module.scss';
import b from '../../assets/scss/modules/buttons.module.scss';

export type RagequitFlowStep = 'ragequit' | 'withdraw' | 'unwrapWETH';

type RagequitFlowProps = {
  shouldOpen: boolean;
  onClose: () => void;
  onStepSuccess: (s: RagequitFlowStep) => void;
};

const INITIAL_STEP: RagequitFlowStep = 'ragequit';

export default function RagequitFlow(props: RagequitFlowProps) {
  const {onClose, onStepSuccess, shouldOpen} = props;

  /**
   * State
   */

  const [view, setView] = useState<RagequitFlowStep>(INITIAL_STEP);
  const [doNotCloseModal, setDoNotCloseModal] = useState<boolean>(false);

  /**
   * Selectors
   */

  const dispatch = useDispatch();
  const connectedAddress = useSelector(
    (s: StoreState) => s.blockchain.connectedAddress
  );
  const orgText = useSelector((s: StoreState) => s.org && s.org.text);

  /**
   * Custom hooks
   */

  // Use approved tokens to set `withdrawals`
  const {approvedTokens} = useApprovedTokens();
  const backendURL = useBackendURL();

  /**
   * Variables
   */

  // Build withdraw tokens map for <Withdraw />
  // @note this is so we don't need to wait around for the
  //  tokenBalances inside <Withdraw /> if we provide nothing.
  const withdrawals = approvedTokens.reduce((acc, next) => {
    // Set blank withdraw amounts as we will use `max = true`
    acc[next] = '';
    return acc;
  }, {} as Record<string, string>);

  const getText = getOrgText(orgText);
  const orgName = getText('OrgName');

  /**
   * Functions
   */

  function handlePreventCloseModal() {
    setDoNotCloseModal(true);
  }

  function handleResetCloseModal() {
    setDoNotCloseModal(false);
  }

  function handleRagequitSuccess() {
    onStepSuccess('ragequit');
    setView('withdraw');
    handleResetCloseModal();
    // Update connected member after their shares have decreased
    dispatch(getConnectedMemberFromSmartContract());
  }

  function handleUnwrapSuccess() {
    onStepSuccess('unwrapWETH');
    handleResetCloseModal();
    // Set view back to beginning just in case.
    setView(INITIAL_STEP);
    // Since this is the last step, call onClose automatically
    // (i.e. not triggered by a user click).
    onClose();
  }

  function handleWithdrawProcess({
    tokenWithdrawals,
    txHash,
  }: WithdrawOnProcessOrSuccessData) {
    if (!backendURL) {
      throw new Error('No backend URL was found.');
    }

    // Send to backend to handle post-withdraw actions
    fetch(`${backendURL}/members/${connectedAddress}/withdraw`, {
      method: 'POST',
      body: JSON.stringify({
        tokenWithdrawals,
        txHash,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  function handleWithdrawSuccess() {
    onStepSuccess('withdraw');
    setView('unwrapWETH');
    handleResetCloseModal();
  }

  if (!shouldOpen) return null;

  return (
    <ReactModal
      role="dialog"
      isOpen={shouldOpen}
      onRequestClose={doNotCloseModal ? () => {} : onClose}
      style={
        {
          overlay: {zIndex: '99'},
          content: {
            maxWidth: '32.5rem',
          },
        } as any
      }
      ariaHideApp={false}
      overlayClassName={`${m['modal-overlay']} org-modal-overlay`}
      className={`${m['modal-content-wide']}`}>
      <>
        {/* RAGEQUIT */}

        {view === 'ragequit' && (
          <FadeIn>
            <Ragequit
              onError={handleResetCloseModal}
              onProcess={handlePreventCloseModal}
              onSuccess={handleRagequitSuccess}
              render={({
                etherscanURL,
                handleLootChange,
                handleSharesChange,
                handleSubmit,
                isSubmitDone,
                isSubmitting,
                loot,
                lootToBurn,
                lootToBurnDisplay,
                shares,
                sharesToBurn,
                sharesToBurnDisplay,
                submitError,
              }) => (
                <div
                  className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} org-modal`}>
                  <div className={`${sm.sales} ${m['modal-title']} card`}>
                    <div className="titlebar">
                      <h2 className="titlebar__title org-titlebar__title">
                        Rage Quit
                      </h2>
                    </div>

                    <section style={{marginBottom: '2rem', textAlign: 'left'}}>
                      <p>
                        <small>
                          You can opt to &ldquo;rage quit&rdquo; {orgName}{' '}
                          partially or completely.
                        </small>
                      </p>
                      <p>
                        <small>
                          We&rsquo;ll continue to maintain any investments and
                          make any applicable distributions, when voted on by
                          the members.
                        </small>
                      </p>
                      <p>
                        <small>
                          After ragequitting you can get back any undeployed
                          portion of contribution (in ETH) along with your pro
                          rata right to any tokens from investments (assuming
                          those tokens had no transfer restrictions placed on
                          them).
                        </small>
                      </p>

                      {Number(shares) > 0 && (
                        <label
                          className={`${i['label--column']} org-label--column`}>
                          <span>Tokens to Ragequit</span>
                          <input
                            placeholder={
                              shares
                                ? `${formatNumber(shares)} token${
                                    Number(shares) === 1 ? '' : 's'
                                  } available`
                                : ''
                            }
                            type="text"
                            onChange={handleSharesChange}
                            value={sharesToBurnDisplay || ''}
                          />
                        </label>
                      )}

                      {Number(loot) > 0 && (
                        <label
                          className={`${i['label--column']} org-label--column`}>
                          <span>Non-voting loot to ragequit</span>
                          <input
                            placeholder={
                              loot
                                ? `${formatNumber(
                                    loot
                                  )} non-voting loot available`
                                : ''
                            }
                            type="text"
                            onChange={handleLootChange}
                            value={lootToBurnDisplay || ''}
                          />
                        </label>
                      )}
                    </section>

                    <button
                      className={`${b['mini-modal-button']} org-mini-modal-button`}
                      disabled={
                        isSubmitting ||
                        isSubmitDone ||
                        (!sharesToBurn && !lootToBurn)
                      }
                      onClick={
                        isSubmitting ||
                        isSubmitDone ||
                        (!sharesToBurn && !lootToBurn)
                          ? () => {}
                          : handleSubmit
                      }>
                      {isSubmitting ? (
                        <Loader
                          text="Processing&hellip;"
                          textProps={{style: {color: 'inherit'}}}
                        />
                      ) : (
                        'Start'
                      )}
                    </button>

                    {etherscanURL && (
                      <p>
                        <small>
                          <a
                            href={etherscanURL}
                            rel="noopener noreferrer"
                            target="_blank">
                            (view progress)
                          </a>
                        </small>
                      </p>
                    )}

                    {/* RAGEQUIT ERROR */}
                    {/* 4001 code is a EIP 1193 error code */}
                    {/* @see: https://eips.ethereum.org/EIPS/eip-1193 */}
                    {submitError &&
                      (submitError as MetaMaskRPCError).code !== 4001 && (
                        <ErrorMessageWithDetails
                          renderText={() => (
                            <small>
                              Something went wrong while ragequitting.
                            </small>
                          )}
                          error={submitError}
                        />
                      )}
                  </div>
                </div>
              )}
            />
          </FadeIn>
        )}

        {/* WITHDRAW */}

        {view === 'withdraw' && (
          <FadeIn>
            <Withdraw
              withdrawals={withdrawals}
              max
              onError={handleResetCloseModal}
              onProcess={handleWithdrawProcess}
              onStart={handlePreventCloseModal}
              onSuccess={handleWithdrawSuccess}
              render={({
                etherscanURL,
                handleSubmit,
                submitError,
                submitReadyStatus,
                txStatus,
              }) => (
                <div
                  className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} org-modal`}>
                  <div className={`${sm.sales} ${m['modal-title']} card`}>
                    <div className="titlebar">
                      <h2 className="titlebar__title org-titlebar__title">
                        Withdraw tokens
                      </h2>
                    </div>

                    <section style={{marginBottom: '2rem', textAlign: 'left'}}>
                      <p>
                        <small>
                          You can withdraw your earned token amounts from{' '}
                          {orgName}.
                        </small>
                      </p>
                      <p>
                        <small>
                          You can access your withdrawn tokens at their token
                          address. Your ETH in {orgName} can be transferred back
                          to your account in the next step.
                        </small>
                      </p>
                    </section>

                    <button
                      className={`${b['mini-modal-button']} org-mini-modal-button`}
                      disabled={
                        submitReadyStatus !== FetchStatus.FULFILLED ||
                        txStatus === Web3TxStatus.AWAITING_CONFIRM ||
                        txStatus === Web3TxStatus.PENDING ||
                        txStatus === Web3TxStatus.FULFILLED
                      }
                      onClick={
                        submitReadyStatus !== FetchStatus.FULFILLED ||
                        txStatus === Web3TxStatus.AWAITING_CONFIRM ||
                        txStatus === Web3TxStatus.PENDING ||
                        txStatus === Web3TxStatus.FULFILLED
                          ? () => {}
                          : handleSubmit
                      }>
                      {txStatus === Web3TxStatus.AWAITING_CONFIRM ||
                      txStatus === Web3TxStatus.PENDING ? (
                        <Loader
                          text={
                            txStatus === Web3TxStatus.AWAITING_CONFIRM
                              ? 'Preparing\u2026'
                              : 'Processing\u2026'
                          }
                          textProps={{style: {color: 'inherit'}}}
                        />
                      ) : (
                        'Withdraw'
                      )}
                    </button>

                    {etherscanURL && (
                      <p>
                        <small>
                          <a
                            href={etherscanURL}
                            rel="noopener noreferrer"
                            target="_blank">
                            (view progress)
                          </a>
                        </small>
                      </p>
                    )}

                    {/* WITHDRAW ERROR */}
                    {/* 4001 code is a EIP 1193 error code */}
                    {/* @see: https://eips.ethereum.org/EIPS/eip-1193 */}
                    {submitError &&
                      (submitError as MetaMaskRPCError).code !== 4001 && (
                        <ErrorMessageWithDetails
                          renderText={() => (
                            <small>
                              Something went wrong during the withdrawal.
                            </small>
                          )}
                          error={submitError}
                        />
                      )}

                    {/* SET WITHDRAW AMOUNTS PER TOKEN */}
                    {/* @note Keep this example for now - may need it */}

                    {/* {tokenBalances &&
                    tokenBalances.map(t => (
                      <label className={`${i['label--column']} org-label--column`} key={t.address}>
                        <span>{t.symbol || t.address}</span>
                        <div className={i['input-suffix__wrap']}>
                          <input
                            className={`${i['input-suffix--sm']}`}
                            placeholder={`${Number(
                              web3Instance.utils.fromWei(t.balance)
                            ).toFixed(2)} ${t.symbol} available`}
                            onChange={handleChange(t)}
                            type="number"
                            // @todo pass controlled `inputValue` from render function props
                            value={inputValue}
                          />
                          <div className={`${i['input-suffix__item--sm']} org-input-suffix__item--sm`}>%</div>
                        </div>

                        <p>
                          <small>
                            {Number(
                              web3Instance.utils.fromWei(t.balance)
                            ).toFixed(2)}
                          </small>
                        </p>
                      </label>
                    ))} */}
                  </div>
                </div>
              )}
            />
          </FadeIn>
        )}

        {/* UNWRAP WETH */}

        {view === 'unwrapWETH' && (
          <FadeIn>
            <UnwrapWETH
              onError={handleResetCloseModal}
              onProcess={handlePreventCloseModal}
              onSuccess={handleUnwrapSuccess}
              render={({
                balance,
                error,
                etherscanURL,
                handleSubmit,
                txStatus,
              }) => (
                <div
                  className={`${sm.wrap} ${sm.gradient} ${sm.modalWrap} org-modal`}>
                  <div className={`${sm.sales} ${m['modal-title']} card`}>
                    <div className="titlebar">
                      <h2 className="titlebar__title org-titlebar__title">
                        Convert to ETH
                      </h2>
                    </div>

                    <section style={{marginBottom: '2rem', textAlign: 'left'}}>
                      <p>
                        <small>
                          You can optionally convert your earned{' '}
                          <span className="font-mono">WETH</span> to{' '}
                          <span className="font-mono">ETH</span>. The amount
                          will be sent to your address.
                        </small>
                      </p>
                    </section>

                    <button
                      className={`${b['mini-modal-button']} org-mini-modal-button`}
                      disabled={
                        Number(balance) === 0 ||
                        txStatus === Web3TxStatus.AWAITING_CONFIRM ||
                        txStatus === Web3TxStatus.PENDING ||
                        txStatus === Web3TxStatus.FULFILLED
                      }
                      onClick={
                        Number(balance) === 0 ||
                        txStatus === Web3TxStatus.AWAITING_CONFIRM ||
                        txStatus === Web3TxStatus.PENDING ||
                        txStatus === Web3TxStatus.FULFILLED
                          ? () => {}
                          : handleSubmit
                      }>
                      {txStatus === Web3TxStatus.AWAITING_CONFIRM ||
                      txStatus === Web3TxStatus.PENDING ? (
                        <Loader
                          text={
                            txStatus === Web3TxStatus.AWAITING_CONFIRM
                              ? 'Preparing\u2026'
                              : 'Processing\u2026'
                          }
                          textProps={{style: {color: 'inherit'}}}
                        />
                      ) : (
                        'Convert'
                      )}
                    </button>

                    {etherscanURL && (
                      <p>
                        <small>
                          <a
                            href={etherscanURL}
                            rel="noopener noreferrer"
                            target="_blank">
                            (view progress)
                          </a>
                        </small>
                      </p>
                    )}

                    {/* RAGEQUIT ERROR */}
                    {/* 4001 code is a EIP 1193 error code */}
                    {/* @see: https://eips.ethereum.org/EIPS/eip-1193 */}
                    {error && (error as MetaMaskRPCError).code !== 4001 && (
                      <ErrorMessageWithDetails
                        renderText={() => (
                          <small>Something went wrong during converting.</small>
                        )}
                        error={error}
                      />
                    )}
                  </div>
                </div>
              )}
            />
          </FadeIn>
        )}
      </>
    </ReactModal>
  );
}
